import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedOnlinePlannerPlansRoute extends SearchRoute {
  @service session;
  @service intl;
  modelType = 'v2/OnlinePlannerSession';
  routeRegex = /authenticated.online-planner-plans/;
  params;

  queryParams = {
    page: {
      refreshModel: true
    },
    query: {
      replace: true,
      refreshModel: true
    },
    archived: {
      refreshModel: true
    },
    config_id: {
      refreshModel: true
    },
    submitted: {
      refreshModel: true
    },
    status: {
      refreshModel: true
    },
    type: {
      refreshModel: true
    },
    sort_by: {
      refreshModel: true,
    },
    order: {
      refreshModel: true,
    }
  }

  async model(params) {
    this.params = params;
    if (!params.config_id || params.config_id === 'All Planners') delete this.params.config_id;
    if (!params.submitted === 'true' || !params.submitted === 'false') delete this.params.submitted;

    const search = {
      clientId: this.session.currentClient.id,
      page: params.page || this.defaultPage,
      query: params.query || this.defaultQuery,
      include:
        'online-planner-session-payments,online-planner-user,online-planner-config,kase,online-planner-config.location-online-planner-configs,online-planner-config.location-online-planner-configs.location,online-planner-config.general-price-list',
      archived: params.archived || this.defaultArchived,
      type: params.type,
      sort_by: params.sort_by,
      status: params.status,
      order: params.order,
      verified: true
    };

    if (this.params.config_id) search.config_id = this.params.config_id;
    if (this.params.submitted === 'true' || this.params.submitted === 'false') search.submitted = this.params.submitted;

    return this.store.query(this.modelType, search);
  }

  setupController(controller) {
    super.setupController(...arguments);
    const planners = this.store.findAll('v2/online-planner-config', {
      reload: true,
      include: [
        'location-online-planner-configs.location',
        'location-online-planner-configs',
        'logo',
        'general-price-list'
      ].join(',')
    });
    const locations = this.store.findAll('v2/location');
    controller.set('planners', planners);
    controller.set('planning_for', this.params.planning_for);
    controller.set('locations', locations);
    controller.set('config_id', this.params.config_id);
    controller.set('status', this.params.status);
    controller.set('type', this.params.type);
    controller.set('sort_by', this.params.sort_by);
    controller.set('order', this.params.order);
    controller.set('search', this.params.query);
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
