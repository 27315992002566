import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import SelectBox from './select-box';

export default class FormStaffSelector extends SelectBox {
  @service staff;

  layoutName = 'components/form/select-box';
  placeholder = 'All Staff Members';
  staffList = 'active';

  get options() {
    return get(this, `staff.${this.staffList}`);
  }
}
