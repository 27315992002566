import Controller from '@ember/controller';
import config from 'crakn/config/environment';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AdminOnlinePlannerOverviewController extends Controller {
  @service api;
  @service browser;
  @service flashes;
  @service intl;
  @service router;
  @service store;
  @service session;
  @service googleFonts;
  @service fontSettings;

  @tracked changesets;
  @tracked dataChanged = false;
  @tracked model;
  @tracked showValidation = false;
  @tracked addNewLocationConfig = false;
  @tracked locationConfigBeingAdded;
  @tracked taxLocations = this.locations;

  constructor() {
    super(...arguments);
    this.changesets = {};
    this.googleFonts.fetchFonts();
  }

  get systemFonts() {
    return this.fontSettings.systemFonts;
  }

  get fontTypes() {
    return this.fontSettings.fontTypes;
  }

  get headerFontValue() {
    if (!this.changesets || !this.changesets.headerFont) {
      return null;
    }
    return this.changesets.headerFont.headerFont || this.fontTypes.find(font => font.id === this.changesets.fontType.fontType)?.default;
  }

  get bodyFontValue() {
    if (!this.changesets || !this.changesets.bodyFont) {
      return null;
    }
    return this.changesets.bodyFont.bodyFont || this.fontTypes.find(font => font.id === this.changesets.fontType.fontType)?.default;
  }

  // standard and maximum (max, also) are protected enum values in Rails
  get logoSizes() {
    return [
      { id: 'small', name: 'Standard' },
      { id: 'medium', name: 'Large' },
      { id: 'large', name: 'Maximum' }
    ];
  }

  get logoAlignments() {
    return [
      { id: 'left', name: 'Left' },
      { id: 'center', name: 'Center' }
    ];
  }

  get pagesNotSortable() {
    return ['welcome', 'overview-questions', 'review-summary'];
  }

  get defaultPlannerCheckboxDisabled() {
    return this.model?.locationOnlinePlannerConfigs.length > 0 || (this.model?.get('defaultExists') && !this.model?.get('default'));
  }

  get locationOptions() {
    if (this.model.get('generalPriceList.id')) {
      const locationConfigIds = this.model.locationOnlinePlannerConfigs
        .filter((config) => !config.get('isNew'))
        .map((config) => {
          return config.get('location.id');
        });

      const filteredLocations = this.locations.filter((location) => {
        return !locationConfigIds.includes(location.id);
      });

      const options = filteredLocations.map((location) => {
        return { value: location.id, name: location.name };
      });

      return options;
    } else {
      return [];
    }
  }

  resetDefaultFonts() {
    const fontType = this.changesets.fontType.fontType;
    const defaultFonts = this.fontTypes.find(font => font.id === fontType);

    if (defaultFonts) {
      this.changesets.headerFont.headerFont = defaultFonts.default;
      this.changesets.bodyFont.bodyFont = defaultFonts.default;
    }
  }

  resetDropOpacity(element) {
    if (!element) {
      return;
    }

    element?.style?.removeProperty('opacity');
  }

  @action
  changeFontType(newFontType) {
    this.changesets.fontType.fontType = newFontType.target.value;
    this.resetDefaultFonts();
  }

  @action
  validate() {
    if (!this.model.name) {
      this.showValidation = true;
    }
  }

  showSaveError(errorKey) {
    switch (errorKey) {
      case 'name':
        this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.errors.name'));
        break;
      case 'urlPrefix':
        this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.errors.urlPrefix'));
        break;
      case 'primaryColor':
        this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.validation.primaryColor'));
        break;
      case 'secondaryColor':
        this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.validation.secondaryColor'));
        break;
      case 'country':
        this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.validation.country'));
        break;
      default:
        this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.errors.pageName'));
    }
  }

  @action
  onDestroy() {
    this.dataChanged = false;
    this.changesets = undefined;
  }

  @action
  dontAllowPaymentCollectionBeforeAbout() {
    const aboutIndex = this.model.config.pages.findIndex(page => page.name === 'vital-statistics');
    const paymentCollectionIndex = this.model.config.pages.findIndex(page => page.name === 'payment-collection');

    if (aboutIndex > 0 && paymentCollectionIndex > 0) {
      if (aboutIndex > paymentCollectionIndex) {
        const paymentCollection = this.model.config.pages[paymentCollectionIndex];

        this.model.config.pages.replace(paymentCollectionIndex, 1);
        this.model.config.pages.replace(aboutIndex, 0, [paymentCollection]);
      }
    }
  }

  @action
  onDataChanged(e) {
    this.resetDropOpacity(e?.target);
    this.dataChanged = true;

    this.dontAllowPaymentCollectionBeforeAbout();
  }

  @action
  async save() {
    this.showValidation = false;

    Object.keys(this.changesets).forEach(async (key) => {
      await this.changesets[key].validate();
    });

    const invalid = Object.keys(this.changesets).find(key => this.changesets[key].isValid === false );

    if (invalid) {
      this.showValidation = true;
      this.showSaveError(invalid);
      return;
    }

    Object.keys(this.changesets).forEach(async (key) => {
      await this.changesets[key].execute();
      await this.changesets[key].save();
    });

    this.model.config.theme = {
      primaryColor: this.changesets.primaryColor.primaryColor,
      secondaryColor: this.changesets.secondaryColor.secondaryColor,
      country: this.changesets.country.country,
      fontType: this.changesets.fontType.fontType,
      headerFont: this.changesets.headerFont.headerFont,
      bodyFont: this.changesets.bodyFont.bodyFont
    }

    this.defaultCountryValues();

    this.model.save()
    .then(()=>{
      this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.edit.overview.save.success'));
      this.dataChanged = false;
    }, ()=> {
      this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.save.error'));
    });

  }

  @action
  setAddNewLocationConfig() {
    this.addNewLocationConfig = true;

    const client = this.store.findRecord('v2/client', this.session.currentClient.id)
    this.locationConfigBeingAdded = this.store.createRecord('v2/location-online-planner-config', {
      onlinePlannerConfig: this.model,
      client
    });
  }

  @action
  removeTempLocationConfig() {
    this.addNewLocationConfig = false;
    this.locationConfigBeingAdded?.destroyRecord();
    this.locationConfigBeingAdded = undefined;
  }

  @action
  addNewLocationOnlinePlanner(locationConfig, locationId) {
    const location = this.locations.filter(location => location.id === locationId)[0];
    locationConfig.set('location', location);
    locationConfig.save()
      .then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.edit.overview.save.success'));
      this.addNewLocationConfig = false;
      this.locationConfigBeingAdded = undefined;
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.save.error'));
      })
  }

  @action
  setLocationConfigDelete(locationConfig) {
    this.locationConfigForDeletion = locationConfig;
  }

  @action
  confirmLocationConfigDelete(locationConfig) {
    locationConfig.destroyRecord()
      .then(() => {
        this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.edit.overview.locations.delete.success'));
        this.locationConfigForDeletion = null;
      }).catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.locations.save.error'));
      })
  }

  @action
  onLogoError(_res) {
    this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.headerLogo.actions.create.error'))
  }

  @action
  destroyLogo(logo) {
    logo.destroyRecord()
      .then(() => {
        this.api.json.post(`v2/online_planner_configs/${this.model.id}/remove_logo`)
        this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.edit.overview.headerLogo.actions.archive.success'));
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.headerLogo.actions.archive.error'));
      });
  }

  @action
  logoSuccess(response) {
    if (response) {
      this.store.push(response);
      this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.edit.overview.headerLogo.actions.create.success'));
    }
  }

  @action
  createLogo() {
    return new Promise((resolve, reject) => {
      this.store.createRecord('v2/logo')
        .save()
        .then(logo => { resolve(logo) })
        .catch(() => reject());
    });
  }

  @action
  copyPlannerUrl() {
    if (!this.changesets.urlPrefix.urlPrefix) {
      return;
    }
    const url = `${config.onlinePlannerUrl}${this.changesets.urlPrefix.urlPrefix}`
    navigator.clipboard.writeText(url);
    this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.edit.overview.save.copySuccess'));
  }

  @action
  updateOnlinePlannerLogo(logo, response) {
    this.model.logo = logo;
    this.logoSuccess(response);
  }

  @action
  trimColor(key) {
    set(this, `changesets.${key}.${key}`, this.changesets[key][key].trim());
  }

  @action
  setSystemFont() {
    set(this, 'changesets.headerFont.headerFont', 'System Sans:sans-serif');
    set(this, 'changesets.bodyFont.bodyFont', 'System Sans:sans-serif');
  }

  @action
  async removeGpl() {
    await this.api.json.post(`v2/online_planner_configs/${this.model.id}/remove_gpl`);
    this.locations = [];
    this.send('refreshModel');
  }

  @action
  async changeGeneralPriceList(gplId) {
    this.removeTempLocationConfig();
    if (isNaN(parseFloat(gplId))) {
      await this.removeDefaultTaxLocation();
      await this.removeGpl();
    } else {
      const gpl = this.store.peekRecord('v2/general-price-list', gplId);
      this.model.generalPriceList = gpl;
      await this.removeDefaultTaxLocation();
      await this.model.save();
      this.addNewLocationConfig = false;
      const locations = await this.store.query('v2/location', {
        gpl_id: gplId,
        archived: false
      });
      this.locations = locations;
      this.taxLocations = locations;
    }
  }

  @action
  async removeDefaultTaxLocation() {
    await this.api.json.post(`v2/online_planner_configs/${this.model.id}/remove_default_tax_location`);
  }

  @action
  async changeDefaultTaxLocation(locationId) {
    if (isNaN(parseFloat(locationId))) {
      await this.removeDefaultTaxLocation();
      this.model.set('defaultTaxLocation', null);
    } else {
      const location = this.taxLocations.find(taxLocation => taxLocation.id === locationId);
      this.model.set('defaultTaxLocation', location);
      await this.model.save();
    }
  }

  @action
  updateDefaultConfig(config) {
    this.model.default = config;
    this.model.save();
  }

  defaultCountryValues() {
    const pages = this.model?.config?.pages
      ?.map((page) => {
        return [page, page?.pages?.flat()]?.flat();
      })
      ?.flat();
    const sections = pages
      ?.map((page) => {
        return page?.sections?.flat();
      })
      ?.flat();
    let elements = sections
      ?.map((section) => {
        return section?.content?.flat();
      })
      ?.flat();
    const peopleFormElements = pages
      ?.find((x) => x?.name === 'people')
      ?.forms?.person?.sections?.map((x) => x?.content?.flat())
      ?.flat();
    elements?.push(peopleFormElements);
    elements = elements?.flat();
    const countryEl = elements?.filter((x) => x?.name?.includes('country'));
    countryEl?.forEach((el) => {
      el.value = this.changesets.country.country;
    });
  }
}
