import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminTributePayPaymentsAccountsRoute extends SearchRoute {
  @service store;

  queryParams = {
    include_archived: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    query: {
      refreshModel: true
    }
  };

  model(params) {
    return this.store.query('integration-service', {
      name: 'tribute_pay_account',
      location_based: true,
      per_page: 10,
      ...params });
  }
}
