import Model, { hasMany, attr } from '@ember-data/model';
import { hasFullAddress } from 'crakn/utils/address-helpers';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: validator('presence', true),
});

export default class PlaceModel extends Model.extend(Validations) {
  @attr('boolean', { defaultValue: false }) isVendor;
  
  @attr('moment-datetime') archivedAt;

  @attr('phone') faxNumber;
  @attr('phone') phoneNumber;
  @attr('string') placeType;

  @attr('string', { defaultValue: 'US' }) country;
  @attr('string') city;
  @attr('string') county;
  @attr('string') email;
  @attr('string') line1;
  @attr('string') line2;
  @attr('string') name;
  @attr('string') notes;
  @attr('string') state;
  @attr('string') websiteUrl;
  @attr('string') zipCode;

  @hasMany('rooms', { async: true }) rooms;

  get typeaheadName() {
    if (this.city) {
      return `${this.name} (${this.city})`;
    } else {
      return this.name;
    }
  }

  get singleLineAddress() {
    return [this.line1, this.line2, this.city, this.state, this.zipCode]
      .filter(Boolean)
      .join('+');
  }

  get firstLineAddress() {
    if (!this.line1 && !this.line2) {
      return null;
    }

    return [this.line1, this.line2].filter(Boolean).join(', ');
  }

  get secondLineAddress() {
    if (!this.city && !this.state && !this.zipCode) {
      return null;
    } 

    const secondLine = [this.state, this.zipCode].filter(Boolean).join(' ');

    if (this.city && secondLine) {
      return `${this.city}, ${secondLine}`;
    } else {
      return this.city || secondLine;
    }
  }

  get hasFullAddress() {
    return hasFullAddress(this);
  }
}
