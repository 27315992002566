import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import env from 'crakn/config/environment';

export default class AuthenticatedOnlinePlannerPlansEditController extends Controller {
  @service api;
  @service flashes;
  @service session;
  @service router;
  @service intl;
  @tracked askConvert = false;
  @tracked askLocation = false;
  @tracked emberSession;
  @tracked planningForSelf;
  @tracked uploadedDocuments = [];
  @tracked selectedLocationID;

  constructor() {
    super(...arguments);
    this.env = env;
  }

  get country() {
    return this.model?.data?.theme?.country;
  }

  findPerson = (contactKey) =>
    this.peoplePage.forms.person.data.people.find(
      (person) =>
        person[Object.keys(person).find((key) => key.includes(contactKey))] ===
        true
    );

  mapPerson = (person) => {
    if (!person) return;

    return {
      email: person[Object.keys(person).find((key) => key.includes('email'))],
      relationship:
        person[Object.keys(person).find((key) => key.includes('relationship'))],
      phone:
        person[
          Object.keys(person).find((key) => key.includes('primary_phone'))
        ],
      fullName: this.getFullName(person)
    };
  };

  getFullName = (person) => {
    const capitalize = (s) => {
      return s && s[0].toUpperCase() + s.slice(1);
    };
    const prefix = capitalize(
      person[Object.keys(person).find((key) => key.includes('name_prefix'))]
    );
    const first = capitalize(
      person[Object.keys(person).find((key) => key.includes('first_name'))]
    );
    const middle = capitalize(
      person[Object.keys(person).find((key) => key.includes('middle_name'))]
    );
    const last = capitalize(
      person[Object.keys(person).find((key) => key.includes('last_name'))]
    );
    const suffix = capitalize(
      person[Object.keys(person).find((key) => key.includes('name_suffix'))]
    );
    const name = [prefix, first, middle, last, suffix]
      .filter(Boolean)
      .join(' ');
    return name;
  };

  findNotHiddenPages = (pages) => {
    let result = [];

    pages.forEach((page) => {
      if (!page.hide) {
        result.push(page);
      }

      if (page.pages && !page.hide) {
        result = result.concat(this.findNotHiddenPages(page.pages));
      }
    });

    return result;
  };

  get locationsAllowed() {
    const gplId = this.model?.get('onlinePlannerConfig.generalPriceList.id');
    const configLocations = this.model?.get(
      'onlinePlannerConfig.locationOnlinePlannerConfigs'
    );
    const locationIds = configLocations
      ? configLocations.map((x) => x.get('location.id'))
      : [];
    const hasLocations = configLocations?.length > 0;
    const hasGpl = gplId > 0;

    if (hasLocations) {
      return this.locations.filter((location) =>
        locationIds.includes(location.id)
      );
    }

    if (hasGpl) {
      return this.locations.filter(
        (location) => location.generalPriceListId == gplId
      );
    }

    return this.locations;
  }

  get pagesNotHidden() {
    return this.findNotHiddenPages(this.emberSession.pages);
  }

  get hideSelections() {
    return (
      this.model.onlinePlannerSessionProducts.length === 0 &&
      this.model.onlinePlannerSessionPackages.length === 0
    );
  }

  get pageSections() {
    const skipPages = [
      'welcome',
      'options',
      'products',
      'packages',
      'review-summary'
    ];

    const skip = this.pagesNotHidden.filter(
      (page) => !skipPages.includes(page.name)
    );

    return skip.filter(
      (page) =>
        page.parent._internalModel.modelName ===
        'v2/online-planner-session-fragment'
    );
  }

  get peoplePage() {
    return this.emberSession.pages
      .find((x) => x.name === 'family-and-friends')
      .pages.find((x) => x.name === 'people');
  }

  get primary() {
    const person = this.findPerson('primary_contact');
    return this.mapPerson(person);
  }

  get nextOfKin() {
    const person = this.findPerson('next_of_kin');
    return this.mapPerson(person);
  }

  get otherPeople() {
    const otherPeople = this.peoplePage.forms.person.data.people.filter(
      (person) =>
        person[
          Object.keys(person).find((key) => key.includes('primary_contact'))
        ] !== true &&
        person[
          Object.keys(person).find((key) => key.includes('next_of_kin'))
        ] !== true
    );

    return [...otherPeople].map((person) => {
      return this.mapPerson(person);
    });
  }

  get numberOfPageSections() {
    return this.pageSections.length - 1;
  }

  get selfPlan() {
    const oq = this.emberSession?.pages?.find(x => x.name === 'overview-questions');
    const pf = oq?.pages?.find(x => x.name === 'planning-for');
    const element = pf?.fields?.find(x => x.name === 'plan_for');
    return element?.value === 'self';
  }

  resetConvertToCaseParams() {
    this.askConvert = false;
    this.askLocation = false;
    this.selectedLocationID = undefined;
  }

  @action
  onAskLocation() {
    this.askConvert = false;
    this.askLocation = true;
  }

  @action
  onConvertToCase() {
    const selectedLocationID = this.selectedLocationID;
    this.resetConvertToCaseParams();
    if (!selectedLocationID) return;

    this.api.json
      .post(`v2/online_planner_sessions/${this.model.id}/promote_to_kase`, {
        body: {
          location_id: selectedLocationID
        }
      })
      .then((resp) => {
        if (resp.ok) {
          this.flashes.addSuccess(
            this.intl.t('authenticated.online-planner-plans.promote.success')
          );
          this.send('refreshModel');
        } else {
          this.flashes.addError(
            this.intl.t('authenticated.online-planner-plans.promote.error')
          );
        }
      })
      .catch(() => {
        this.flashes.addError(
          this.intl.t('authenticated.online-planner-plans.promote.error')
        );
      });
  }

  @action
  printPlan() {
    window.print();
  }
}
