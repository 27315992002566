import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminCustomFieldEditRoute extends Route {
  @service session;

  model(params) {
    return this.store.findRecord('v2/custom-field-config', params.custom_field_id);
  }

  setupController(controller, model) {
    controller.set('customFieldConfig', model);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);
    this.store.unloadAll('v2/custom-field');

    if (isExiting) {
      controller.setProperties({
        customFieldConfig: null
      });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
