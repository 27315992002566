import Model, { belongsTo, attr } from '@ember-data/model';
import {cleanPrice} from 'crakn/utils/data-helpers'

export default class PriceChangeModel extends Model {
  @attr('price') newPrice;
  @attr('price') previousPrice;
  @attr('moment-datetime') createdAt;

  @belongsTo('product') product;

  get totalChange() {
    return cleanPrice((this.newPrice - this.previousPrice), 2)
  }
}
