import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default class AuthenticatedDashboardRoute extends Route {
  @service session;
  layout = 'fullscreen';

  queryParams = {
    organization_location_id: {
      refreshModel: true
    }
  }

  beforeModel(params) {
    if (params?.to?.queryParams?.acceptNewClient) {
      // reload the page after login to refresh accessible clients
      window.location.replace('/#/dashboard');
    }
  }

  model(params) {
    const userId = this.session.currentUser.id;
    const { organization_location_id } = params;
    return RSVP.hash({
      userKases: this.store.query('kase', {
        per_page: 5,
        user_id: userId,
        status: 'active',
        dashboard: true,
        organization_location_id
      }),
      userEvents: this.store.query('event', {
        per_page: 5,
        user_id: userId,
        filter_future: true,
        dashboard: true,
        filter_kase_status: true,
        organization_location_id
      }),
      pastDueKases: this.store.query('overdueKase', {
        per_page: 5,
        past_due: true,
        dashboard: true,
        organization_location_id
      }),
      kaseTaskReports: this.store.query('kaseTaskReport', {
        organization_location_id
      }),
      chainOfCustodyKases: this.store.query('v2/kase', {
        hasChainOfCustody: true,
        status: ['in_progress', 'preneed', 'pending'],
        perPage: 5,
        organization_location_id,
        include: [
          'custody-audits.end-place.address',
          'custody-audits.end-room',
          'custody-audits.kase',
          'deceased'
        ].join(',')
      })
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    const perPage = 5;

    controller.setProperties({
      kasePage: 1,
      eventPage: 1,
      perPage
    });
  }
}
