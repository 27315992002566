import Controller from '@ember/controller';
import { action, setProperties } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';


export default class AuthenticatedAdminOnlinePlannerEditNotificationSettingsController extends Controller {
  @service flashes;
  @service intl;
  @service store;
  @service session;

  @tracked isEditingNewRecipient = false;
  @tracked page = 1;
  @tracked selectedRecipient;
  @tracked showDialog = false;

  queryParams = ['query', 'page'];

  isEditingNewRecipient = false;
  page = 1;
  selectedRecipient = null;

  @action
  resetFilters() {
    setProperties(this, {
      query: ''
    });
  }

  @action
  searchStaffMember(input) {
    return this.store.query('v2/user', {
      client_id: this.session.currentClient.id,
      query: input,
      excluded_olp_config_id: this.onlinePlannerConfig.id
    });
  }

  @action
  cancelAddingRecipient() {
    setProperties(this, {
      selectedRecipient: null,
      isEditingNewRecipient: false
    });
  }

  @action
  saveNewRecipient() {
    const newUserNotification = this.store.createRecord('v2/user-notification', {
      userNotificationType: 'online_planner',
      user: this.selectedRecipient,
    });

    newUserNotification.save().then((userNotification) => {
      return this.store.createRecord('v2/online-planner-config-user-notification', {
        onlinePlannerConfig: this.onlinePlannerConfig,
        userNotification
      }).save();
    }).then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.edit.notification-settings.alerts.create.success'));
      this.cancelAddingRecipient();
      this.refresh();
    }).catch(() => {
      this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.notification-settings.alerts.create.error'));
    });
  }

  @action
  deleteRecipient(userNotification) {
    userNotification.destroyRecord().then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.edit.notification-settings.alerts.delete.success'));

      this.refresh();
    }).catch(() => {
      this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.notification-settings.alerts.delete.error'));
    });
  }

  @action
  refresh() {
    this.send('refreshModel');
  }
}
