import { and } from '@ember/object/computed';
import { get, computed } from '@ember/object';
import { run } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';

export default Service.extend({
  model: null,
  contactRelationshipName: null,
  isContactRelationshipDisabled: null,

  store: service(),

  isRelationshipNameEnabled: and('model.kase.id', 'model.contact.id'),

  getKaseAndRelationship() {
    const kaseId = get(this, 'model.kase.id');
    const contactId = get(this, 'model.contact.id');

    if (!kaseId) {
      return;
    }
    this.store.findRecord('kase', kaseId).then(kase => {
      const contactRelationship = contactId
        ? get(kase, 'kaseContacts').findBy('personId', contactId)
        : null;

      this.setProperties({
        kase,
        contactRelationshipName: contactRelationship
          ? get(contactRelationship, 'relationshipName')
          : null,
        isContactRelationshipDisabled: !!contactRelationship
      });
    });
  },

  modelDidUpdate: computed(
    'getKaseAndRelationship',
    'model.{contract.id,kase.id}',
    function() {
      run.once(this, this.getKaseAndRelationship);
    }
  )
});
