import { pluralize, singularize } from 'ember-inflector';
import moment from 'moment';

export const sections = (content, sectionKeys) => {
  const normalized = normalize(content);
  return sectionKeys.map(section => {
    const path = section.entity.split('.');
    const model = path.reduce((acc, nextPath) => {
      if (acc && acc.relationships && acc.relationships[nextPath]) {
        return acc.relationships[nextPath];
      }
      return null;
    }, normalized);
    return {
      ...section,
      content: keyValues(section, model, section.entity)
    };
  });
};

const keyValues = (section, model, path) => {
  if (model === null) {
    return [];
  }
  let arr = Object.keys(model.attributes)
    .map(attributeKey => {
      const attrPath = `${path}.${toCamel(attributeKey)}`;
      if (
        (model.attributes[attributeKey] &&
          ((section.only || []).includes(`${path}.*`) ||
            (section.only || []).includes(attrPath)) &&
          (!(section.exclude || []).includes(`${path}.*`) &&
            !(section.exclude || []).includes(attrPath))) ||
        !section.only
      ) {
        return keyValueForAttribute(
          model,
          attributeKey,
          path,
          section.names || {}
        );
      }
      return null;
    })
    .filter(el => el !== null);
  if (model.relationships && Object.keys(model.relationships).length > 0) {
    const relationshipKeys = Object.keys(model.relationships);
    relationshipKeys.forEach(key => {
      if (model.relationships[key]) {
        arr = arr.concat(
          keyValues(section, model.relationships[key], `${path}.${key}`)
        );
      }
    });
  }
  return arr;
};

const keyValueForAttribute = (model, attribute, path, mapping) => {
  const value = model.attributes[attribute];
  const normalizedValue = attribute.includes('date') ? moment(value) : value;
  return {
    id: model.id,
    type: model.type,
    path,
    key: mapping[`${path}.${attribute}`] || normalizeSnakeCase(attribute),
    attrName: toCamel(attribute),
    value: normalizedValue
  };
};

export const normalize = content => {
  let data = content.data;
  if (data === undefined || data === null) {
    return null;
  }
  const included = content.included || [];
  if (Array.isArray(data)) {
    return data.map(datum => {
      return {
        id: datum.id || datum['local:id'],
        type: singularize(datum.type),
        attributes: datum.attributes,
        relationships: normalizeEntity(datum, included)
      };
    });
  }
  data = {
    id: data.id || data['local:id'],
    type: singularize(data.type),
    attributes: data.attributes,
    relationships: normalizeEntity(data, included)
  };
  return data;
};

const normalizeEntity = (data, included) => {
  const relationships = data.relationships || {};
  return Object.keys(relationships)
    .map(relationshipKey => {
      const relationshipObject = relationships[relationshipKey].data || {};
      if (relationshipObject.type) {
        relationshipObject.type = pluralize(relationshipObject.type);
      }
      if (Array.isArray(relationshipObject)) {
        return {
          [toCamel(relationshipKey)]: relationshipObject.map(datum => {
            return findRelationshipEntity(datum, included);
          })
        };
      }
      return {
        [toCamel(relationshipKey)]: findRelationshipEntity(
          relationshipObject,
          included
        )
      };
    })
    .reduce((acc, obj) => {
      Object.keys(obj).forEach(key => {
        acc[key] = obj[key];
      });
      return acc;
    }, {});
};

const findRelationshipEntity = (relationshipObject, included) => {
  const relationshipId = relationshipObject.id || relationshipObject['local:id'];
  const relationshipType = (relationshipObject.type || '').replace(/_/g, '-');
  if (relationshipId && relationshipType) {
    return findEntity(relationshipId, relationshipType, included);
  } else {
    return null;
  }
};

const findEntity = (id, type, included) => {
  const entity = included.find(
    object =>
      (String(id) === String(object.id) ||
        String(id) === String(object['local:id'])) &&
      type === object.type
  );
  if (!entity) {
    return null;
  }
  return {
    id: entity.id || entity['local:id'],
    type: singularize(entity.type),
    attributes: entity.attributes,
    relationships: normalizeEntity(entity, included)
  };
};

const normalizeSnakeCase = str => {
  return str.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
};

const toCamel = s => {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};
