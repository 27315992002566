import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TukiosIntegrationRoute extends Route {
  @service store;

  model() {
    return this.store.query('integrationService', {
      name: 24, // tukios
      not_category: 'accounting',
      per_page: 1000
    });
  }
}
