import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { alias, sort } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

export default class AdminFinancialConfigurationsEditAccountsController extends SearchController {
  @tracked archived = false;
  @tracked page = 1;
  @tracked per_page = 10;
  
  queryParams = [
    'archived',
    'page',
    'per_page',
    'query',
  ];
  sorting = ['name:asc'];

  @alias('model') accounts;

  @tracked chartOfAccounts = null;

  @sort('accounts', 'sorting') sortedAccounts;
  
  get chartOfAccountsId() {
    return this.chartOfAccounts.get('id');
  }

  @action
  save() {
    this.newAccount.then(account => {
      account.save().then(() => {
        this.send('resetAccount');
      });
    });
  }

  @action
  toggleArchived() {
    this.set('archived', !this.archived);
  }

  @action
  clearSearch() {
    this.clearQuery();
    this.chartOfAccounts = null;
  }
}
