import { sort } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';

export default class GroupService extends Service {
  @service store;
  groups = null;

  get all() {
    if (this.groups == null) {
      this.groups = this.store.query('group', { per_page: 1000 });
    }

    return this.groups;
  }

  unloadAll() {
    this.groups = null;
  }

  groupNameSort = ['name:asc'];
  @sort('all', 'groupNameSort') allSorted;
}
