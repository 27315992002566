import Route from '@ember/routing/route';
import lookupValidator from 'ember-changeset-validations';
import { action } from '@ember/object';
import { Changeset } from 'ember-changeset';
import { inject as service } from '@ember/service';
import { validatePresence, validateFormat } from 'ember-changeset-validations/validators';
import validateUrlCharacters from 'crakn/validators/url-safe-characters';

export default class AdminOnlinePlannerOverviewRoute extends Route {
  @service session;
  @service intl;

  async model() {
    await this.unloadPlanner();
    return this.modelFor('authenticated.admin.online-planner.edit');
  }

  async setupController(controller, model) {
    const changesets = this.setupChangesets(model);
    const fontName = changesets.fontType.fontType === 'system' ? 'System Sans' : 'Open Sans';

    changesets.fontType.fontType = changesets.fontType.fontType || 'system';
    changesets.headerFont.headerFont = changesets.headerFont.headerFont || fontName;
    changesets.bodyFont.bodyFont = changesets.bodyFont.bodyFont || fontName;

    const logos = await this.store.query('v2/logo', {
      perPage: 2000,
      archived: false
    });

    const gpls = await this.store.query('v2/general-price-list', {
      perPage: 2000,
      archived: false
    });

    const locations = await this.store.query('v2/location', {
      gpl_id: model?.get('generalPriceList.id'),
      archived: false,
      perPage: 2000
    });

    controller.setProperties({
      changesets,
      gpls,
      locations,
      taxLocations: locations,
      logos,
      model
    });
  }

  setupChangesets(model) {
    if (!model.config.theme?.country) model.config.theme.country = 'US';
    const changesets = this.createNavigationChangesets(model.config);
    changesets.name = this.createChangeset(model, 'name', this.intl.t('authenticated.admin.online-planner.edit.overview.validation.name'));
    changesets.urlPrefix = this.createPrefixChangeset(model, 'urlPrefix', this.intl.t('authenticated.admin.online-planner.edit.overview.validation.url'));
    changesets.primaryColor = this.createColorChangeset(model.config.theme, 'primaryColor', this.intl.t('authenticated.admin.online-planner.edit.overview.validation.primaryColor'));
    changesets.secondaryColor = this.createColorChangeset(model.config.theme, 'secondaryColor', this.intl.t('authenticated.admin.online-planner.edit.overview.validation.secondaryColor'));
    changesets.country = this.createCountryChangeset(model.config.theme, 'country', this.intl.t('authenticated.admin.online-planner.edit.overview.validation.country'));
    changesets.fontType = this.createFontChangeset(model.config.theme, 'fontType', 'Invalid font type');
    changesets.headerFont = this.createFontChangeset(model.config.theme, 'headerFont', 'Invalid font');
    changesets.bodyFont = this.createFontChangeset(model.config.theme, 'bodyFont', 'Invalid font');

    return changesets;
  }

  createCountryChangeset(trackingObject, propertyName, message) {
    const validation =  {
      [propertyName]: [
        validatePresence({presence: true, message: message}),
      ]
    };

    return Changeset(trackingObject, lookupValidator(validation), validation);
  }

  createNavigationChangesets(plannerconfig, returnObject = {}) {
    plannerconfig?.pages?.forEach(page => {
      returnObject[`${page.name}`] = this.createChangeset(page, 'title');
      if (page?.pages?.length > 0) {
        this.createNavigationChangesets(page, returnObject)
      }
    });

    return returnObject;
  }

  createColorChangeset(trackingObject, propertyName, message) {
    const validation = {
      [propertyName]: validateFormat({
        regex: /^#?([a-fA-F0-9]{3}|[a-fA-F0-9]{6})$/,
        allowBlank: true,
        message: message
      }) };

    return Changeset(trackingObject, lookupValidator(validation), validation);
  }
  createFontChangeset(trackingObject, propertyName, message) {
    const validation = {
      [propertyName]: [
        validatePresence({ presence: true, message: message }),
      ]
    };

    return Changeset(trackingObject, lookupValidator(validation), validation);
  }

  createPrefixChangeset(trackingObject, propertyName, message) {
    const validation =  {
      [propertyName]: [
        validatePresence({presence: true, message: message}),
        validateUrlCharacters({message: this.intl.t('authenticated.admin.online-planner.edit.overview.validation.prefixCheck')})
      ]
    };

    return Changeset(trackingObject, lookupValidator(validation), validation);
  }

  createChangeset(trackingObject, propertyName, message = undefined) {
    const validation = {
      [propertyName]: validatePresence({
        presence: true,
        message: message ? message : this.intl.t('authenticated.admin.online-planner.edit.overview.validation.pageName')
      }) };

    return Changeset(trackingObject, lookupValidator(validation), validation);
  }

  @action
  willTransition(transition) {
    // we will need to refactor this to compare the object and see if there are changes.
    const dirty = Object.keys(this.controller.changesets).find(key => this.controller.changesets[key].isDirty === true );
    const leave = 'You have unsaved changes are you sure you want to leave?';
    const ask = (dirty || this.controller.dataChanged) ? true : false;

    if (ask && !confirm(leave)) {
      transition.abort();
      return;
    }

    Object.keys(this.controller.changesets).forEach(key => {
      this.controller.changesets[key].rollback();
    });

    this.currentModel.reload();
  }

  async unloadPlanner() {
    await this.store.peekAll('v2/online-planner-content').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-dropdown-option').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-json').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-page').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-section').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-session').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-user').map(field => field.unloadRecord());
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
