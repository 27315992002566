import Controller from '@ember/controller';
import { computed } from '@ember/object';
import { tracked } from '@glimmer/tracking'

export default class AuthenticatedYellowReportController extends Controller {
  @tracked locationId = '';

  @computed('model', 'locationId')
  get filteredKaseTaskReports() {
    let filteredKaseTaskReports = this.model;
    if (this.locationId) {
      const locationId = this.locationId;
      filteredKaseTaskReports = filteredKaseTaskReports.filter(function(
        kaseTaskReport
      ) {
        return kaseTaskReport.get('kase').get('location').get('id') === locationId;
      });
    }

    return filteredKaseTaskReports;
  }

  printedDate = new Date();
}
