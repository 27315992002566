import Transform from '@ember-data/serializer/transform';

import {
  isValidPhone,
  cleanPhone,
  formatPhone
} from 'crakn/utils/data-helpers';

export default Transform.extend({
  deserialize(serialized) {
    return isValidPhone(serialized) ? formatPhone(serialized) : serialized;
  },

  serialize(deserialized) {
    return isValidPhone(deserialized) ? cleanPhone(deserialized) : deserialized;
  }
});
