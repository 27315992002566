import Component from '@ember/component';
import { computed, set, get } from '@ember/object';

export default Component.extend({
  classNames: ['crakn-task-list-edit'],
  taskList: null,
  showDialog: false,
  showTasks: false,
  toggleTasksTooltip: computed('showTasks', function() {
    const showTasks = get(this, 'showTasks');
    if (showTasks) {
      return 'Close List';
    } else {
      return 'Expand List';
    }
  }),

  actions: {
    updateOrderAndSave(order) {
      this.taskList.order = order;
      this.sendAction('save', this.get('taskList'));
    },

    save() {
      this.sendAction('save', this.get('taskList'));
    },

    delete() {
      this.sendAction('delete', this.get('taskList'));
    },

    toggleTasks() {
      const showTasks = get(this, 'showTasks');
      set(this, 'showTasks', !showTasks);
      this.sendAction('toggleTasksDisplay');
    }
  }
});
