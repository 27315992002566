import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class DomanicareSetupController extends Controller {
  @tracked email = null;
  @service flashes;

  @action
  addNew() {
    this.store
      .createRecord('integrationService', {
        name: 'domanicare',
        email: this.email
      })
      .save()
      .then(() => {
        this.flashes.addSuccess('Email added successfully.');
        this.email = null;
        this.send('refreshModel');
      })
      .catch(() => {
        this.flashes.addError('Something went wrong adding the integration.');
      });
  }

  @action
  removeIntegration(id) {
    const integration = this.store.peekRecord('integrationService', id);

    if (!integration) {
      return;
    }

    integration
    .destroyRecord()
    .then(() => {
      this.flashes.addSuccess('Email removed successfully.');
      this.send('refreshModel');
    })
    .catch(() => {
      this.flashes.addError('There was a problem removing the email address.');
    })
  }
}
