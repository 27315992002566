import Controller from '@ember/controller';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { empty, bool } from '@ember/object/computed';
import { run, bind } from '@ember/runloop';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminUsersEditOverview extends Controller {
  @service api;
  @service session;
  @service intl;

  @empty('model.email') isSendEmailDisabled;
  @bool('session.currentUser.canEditUsers') canEditUsers;

  get canAccessCrakn() {
    return this.model.get('permissions') > 0;
  }

  get canEditPermissionSlider() {
    if (
      this.model.get('isSuperuser') &&
      !this.session.currentUser.get('isSuperuser')
    ) {
      return false;
    } else {
      return true;
    }
  }

  get maximumPermission() {
    if (
      this.session.currentUser.get('permissions') >= 4 &&
      this.session.currentUser.get('isSuperuser')
    ) {
      return 5;
    } else if (this.session.currentUser.get('permissions') >= 4) {
      return 4;
    } else if (this.session.currentUser.get('permissions') === 3) {
      return 2;
    } else {
      return 0;
    }
  }

  get canPostContractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice
      ? 'Can Post Invoice/Add-on?'
      : 'Can Post Contract/Add-on?';
  }

  get canUnpostContractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice
      ? 'Can Unpost Invoice/Add-on?'
      : 'Can Unpost Contract/Add-on?';
  }

  handleSaveSuccess() {
    this.send('flashSuccess', 'Yay! You saved a user.');
  }

  handleSaveError(error) {
    let message = this.intl.t(
      'authenticated.admin.users.edit.overview.save.error'
    );
    const errors = error?.errors;
    const foundError = Array.isArray(errors) ? [...errors]?.shift(): {};
    const details = foundError?.detail || '';

    if (
      details.includes('You cannot save this user without an email address')
    ) {
      message = this.intl.t(
        'authenticated.admin.users.edit.overview.save.noEmail' // need email address
      );
    } else if (details.includes('sso_user_exists')) {
      message = this.intl.t(
        'authenticated.admin.users.edit.overview.save.matchingEmail' // email used in sso
      );
    } else if (details.includes('has already been taken') && foundError?.source?.pointer === '/data/attributes/credential.email') {
      message = this.intl.t(
        'authenticated.admin.users.edit.overview.save.emailExists' // 'email used in tms
      );
    }

    this.send('flashError', message);
  }

  didSendEmail(response) {
    this.send('flashSuccess', response.parsedJson.success);
  }

  didNotSendEmail(response) {
    this.send('flashError', response.parsedJson.error);
  }

  processEmail() {
    this.api.json
      .post('users/send_password_setup_email', {
        body: { email: this.model.get('email') }
      })
      .then((response) => {
        if (response.ok) {
          this.didSendEmail(response);
        } else {
          this.didNotSendEmail(response);
        }
      });
  }

  @action
  save() {
    const user = this.user;

    user.validate().then(({ validations }) => {
      if (validations.isValid) {
        set(this, 'showValidation', false);
        this.model.save().then(() => {
          this.userRoles
            .save()
            .then(
              run.bind(this, 'handleSaveSuccess'),
              run.bind(this, 'handleSaveError')
            );
        }).catch(bind(this, 'handleSaveError'));
      } else {
        set(this, 'showValidation', true);
      }
    });
  }

  @action
  emailUser() {
    const user = this.user;

    if (user.hasDirtyAttributes) {
      user.validate().then(({ validations }) => {
        if (validations.isValid) {
          set(this, 'showValidation', false);
          user
            .save()
            .then(bind(this, 'processEmail'), bind(this, 'handleSaveError'));
        } else {
          set(this, 'showValidation', true);
        }
      });
    } else {
      this.processEmail();
    }
  }
}
