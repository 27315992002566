import Route from '@ember/routing/route';
import { run } from '@ember/runloop';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default class MiscSalesEditAdjustmentsRoute extends Route {
  @service session;

  model() {
    const params = this.paramsFor('authenticated.misc-sales.edit');
    const clientInfoId = this.session.session.get('authenticated.client.client_info_id');
    const gain = this.session.currentClient.can_view_adjustment_gains ? null : false;

    return RSVP.hash({
      selection: this.store.findRecord('selection', params.selection_id),
      clientInfo: this.store.findRecord('clientInfo', clientInfoId),

      newAdjustment: this.store.createRecord('adjustment', {
        enteredBy: this.session.currentUser,
        adjustedBy: this.session.currentUser,
        gain
      })
    }).then(model => {
      return RSVP.hash({
        ...model,

        adjustmentTypes: this.store.query('adjustmentType', {
          archived: false,
          gpl_id: model.selection.get('location.generalPriceList.id'),
          per_page: 1000,
          can_view_gain: gain
        })
      });
    });
  }

  setupController(controller, models) {
    super.setupController(...arguments);
    controller.setProperties(models);
  }

  handleAdjustmentSaveSuccess() {
    this.refresh();
    this.send('flashSuccess', 'Adjustment successfully saved!');
  }

  handleAdjustmentRemoveSuccess() {
    this.refresh();
    this.send('flashSuccess', 'Adjustment successfully removed.');
  }

  handleError() {
    this.send(
      'flashError',
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
    );
  }

  @action
  cancelNewAdjustment() {
    this.refresh();
  }

  @action
  saveNewAdjustment(newAdjustment) {
    newAdjustment.setProperties({
      selection: this.currentModel.selection,
      enteredBy: this.session.currentUser
    });

    newAdjustment
      .save()
      .then(
        run.bind(this, 'handleAdjustmentSaveSuccess'),
        run.bind(this, 'handleError')
      );
  }

  @action
  removeAdjustment(adjustment) {
    adjustment
      .destroyRecord()
      .then(
        run.bind(this, 'handleAdjustmentRemoveSuccess'),
        run.bind(this, 'handleError')
      );
  }

  @action
  setAdjustmentType(adjustment, adjustmentTypeId) {
    const adjustmentType = this.currentModel.adjustmentTypes.findBy(
      'id',
      adjustmentTypeId
    );
    adjustment.set('adjustmentType', adjustmentType);
  }
}
