import { cleanPrice, formatFloat } from 'crakn/utils/data-helpers';
import { helper as buildHelper } from '@ember/component/helper';

export function formatPrice(args) {
  if ((!args && !args[0]) || args[0] === null || args[0] === undefined) {
    return '';
  }

  const price = args[0];
  const cleanFloatPrice = formatFloat(cleanPrice(price));
  return cleanFloatPrice;
}

export default buildHelper(formatPrice);
