import SelectBox from './select-box';
import { months } from 'crakn/utils/data-helpers';

export default SelectBox.extend({
  layoutName: 'components/form/select-box',
  disabled: false,
  placeholder: 'Month',
  options: months,
  sortBy: null
});
