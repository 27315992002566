import Route from '@ember/routing/route';

export default class AuthenticatedChainOfCustodyDetailsRoute extends Route {
  queryParams = {
    page: {
      refreshModel: true
    }
  };

  model(params) {
    return this.store.query('v2/kase', {
      hasChainOfCustody: true,
      status: ['in_progress', 'preneed', 'pending'],
      per_page: 10,
      page: params.page,
      include: 'custody-audits.end-place.address,\
custody-audits.end-room,\
custody-audits.entered-by,\
custody-audits.start-place.address,\
custody-audits.start-room,\
deceased,\
personal-effects,\
lead-director-staff-member'
    });
  }
}
