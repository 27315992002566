import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class PayNowSuccessController extends Controller {
  @service intl;
  @service browser;

  successType; // Set up in the route.

  get mainText() {
    return this.successType === 'successful-payment' ?
    this.intl.t('pay-now.success.mainText.successfulPayment', { funeralHomeName: this.model.funeral_home_name}) :
    this.intl.t('pay-now.success.mainText.balancePaid');
  }

  get bannerText() {
    return this.successType === 'successful-payment' ?
    this.intl.t('pay-now.success.banners.successfulPayment') :
    this.intl.t('pay-now.success.banners.balancePaid');
  }

  get pageTitle() {
    return this.successType === 'successful-payment' ?
    this.intl.t('pay-now.success.pageTitle.successfulPayment') :
    this.intl.t('pay-now.success.pageTitle.balancePaid');
  }
}
