import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { or } from '@ember/object/computed';
import { computed } from '@ember/object';
import moment from 'moment';
import CustomAction from 'crakn/mixins/custom-action';

export default Model.extend(CustomAction, {
  archivedAt: attr('moment-datetime'),
  inquiryType: attr('string', { defaultValue: 'General' }),
  inquiryNotes: hasMany('inquiryNote', { async: true }),
  createdAt: attr('moment-datetime', { defaultValue: () => moment() }),
  status: attr('string'),

  events: hasMany('events', { async: true }),

  kase: belongsTo('kase'),
  contact: belongsTo('person'),
  createdByUser: belongsTo('user'),
  location: belongsTo('location'),
  inquiryStaffMember: belongsTo('user', { async: true }),

  iconType: computed('inquiryType', function() {
    let iconType = null;

    switch (this.inquiryType) {
      case 'Facility':
        iconType = 'md-icon';
        break;

      default:
        iconType = 'ci-icon';
        break;
    }

    return iconType;
  }),

  hasContents: or(
    'notes',
    'contact.isTruthy',
    'kase.isTruthy',
    'inquiryStaffMember.isTruthy'
  ),

  icon: computed('inquiryType', function() {
    let icon = null;

    switch (this.inquiryType) {
      case 'General':
        icon = 'comment-general';
        break;

      case 'First Call':
        icon = 'comment-first';
        break;

      case 'Preneed':
        icon = 'comment-pulse';
        break;

      case 'Facility':
        icon = 'store';
        break;
    }

    return icon;
  }),

  notify(userId) {
    return this.customAction('notify', 'POST', { userId });
  }
});
