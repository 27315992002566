import SearchRoute from 'crakn/routes/search';

export default class AuthenticatedPlacesIndexRoute extends SearchRoute {
  queryParams = {
    query: {
      replace: true,
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
    per_page: {
      refreshModel: true,
    },
    sort_by: {
      refreshModel: true,
    },
    archived: {
      type: 'boolean',
      refreshModel: true,
    },
    vendor: {
      type: 'boolean',
      refreshModel: true
    },
    order: {
      refreshModel: true,
    },
  };

  modelType= 'v2/place';

  setControllerDefaults(controller, { query = {} }) {
    super.setControllerDefaults(...arguments);
    controller.setProperties({vendor: query.vendor});
  }
}
