import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking'
import config from 'crakn/config/environment';
import { run } from '@ember/runloop';

export default class AuthenticatedCraknFormsIndexController extends Controller {
  queryParams = ['page', 'query'];

  @tracked page = 1;
  @tracked query = null;

  @action
  downloadTest(blankFormId) {
    const a = document.createElement('a');
    a.href = `${config.host}/api/blank_forms/${blankFormId}/download_test`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  updateQuery(value) {
    this.query = value;
    this.page = 1;
  }

  @action
  queryDidChange(value) {
    // Debounce to keep API queries to a minimum
    run.debounce(this, this.updateQuery, value, 50, true);
  }

  @action
  clearSearch() {
    this.query = null;
  }
}
