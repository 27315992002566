import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedAdminManageImportController extends Controller {
  @service api;
  @service flashes;
  @service session;

  handleSuccess(subject) {
    return this.flashes.addSuccess(
      `Yay! You successfully imported some ${subject}.`
    );
  }

  handleError(response) {
    const responseContentType = response.headers.get('content-type');
    if (responseContentType.includes('json')) {
      response.json().then(json => {
        if (json && json.error) {
          return this.flashes.addError(
            `${json.error}`
          );
        } else {
          this.showBasicError();
        }
      });
    } else {
      this.showBasicError();
    }
  }

  showBasicError() {
    return this.flashes.addError(
      'An error occurred.'
    );
  }

  @action
  importPeople() {
    const fileSelect = document.getElementById('people-import');

    if (fileSelect.files && fileSelect.files.length == 1) {
      const formData = new FormData();
      formData.append('people', fileSelect.files[0]);

      this.api.post(
        'import/people',
        {
          method: 'POST',
          body: formData
        }
      )
      .then(async response => {
        fileSelect.value = '';

        if (response.ok) {
          this.handleSuccess('people');
        } else {
          this.handleError(response);
        }
      });
    }
  }

  @action
  importPlaces() {
    const fileSelect = document.getElementById('places-import');

    if (fileSelect.files && fileSelect.files.length == 1) {
      const formData = new FormData();
      formData.append('places', fileSelect.files[0]);

      this.api.post(
        'import/places',
        {
          method: 'POST',
          body: formData
        }
      )
      .then(async response => {
        fileSelect.value = '';

        if (response.ok) {
          this.handleSuccess('places');
        } else {
          this.handleError(response);
        }
      });
    }
  }

  @action
  importTasks() {
    const fileSelect = document.getElementById('tasks-import');

    if (fileSelect.files && fileSelect.files.length == 1) {
      const formData = new FormData();
      formData.append('file', fileSelect.files[0]);

      this.api.post(
        'tasks/import',
        {
          method: 'POST',
          body: formData
        }
      )
      .then(async response => {
        fileSelect.value = '';

        if (response.ok) {
          this.handleSuccess('tasks');
        } else {
          this.handleError(response);
        }
      });
    }
  }
}
