import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default Component.extend({
  classNameBindings: ['qaClassName'],

  qaClassName: computed('note.id', {
    get() {
      return `qa-kase-notes-${get(this, 'note.id')}`;
    }
  }),

  showValidation: false,

  expandNotes: true,
  store: service(),
  session: service(),
  intl: service(),

  currentUserId: readOnly('session.currentUser.id'),
  author: computed('note.user.name', function() {
    if (!this.get('note.user.name')) {
      return this.intl.t('appTitle.full');
    } else {
      return `${this.get('note.user.name')}`;
    }
  }),

  editor: computed('note.editedByUser.name', function() {
    if (!this.get('note.editedByUser.name')) {
      return '';
    } else {
      return `${this.get('note.editedByUser.name')}`;
    }
  }),

  userCanEdit: computed(
    'currentUserId',
    'note.user.id',
    'session.currentUser.isAdmin',
    function() {
      return (
        this.get('note.user.id') == this.currentUserId ||
        this.get('session.currentUser.isAdmin')
      );
    }
  ),

  kase: null,
  noteMode: null,

  note: null,

  isEditable: false,
  isEdited: computed('note.editedByUser.id', function() {
    const editor = get(this, 'note.editedByUser.id');
    if (isPresent(editor)) {
      return true;
    } else {
      return false;
    }
  }),

  actions: {
    showEdit() {
      set(this, 'isEditable', true);
    },
    hideEdit() {
      this.note.rollbackAttributes();
      set(this, 'isEditable', false);
    },
    saveEdit(note) {
      note.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          note.save().then(set(this, 'isEditable', false));
        } else {
          set(this, 'showValidation', true);
        }
      });
    },
    deleteNote(note) {
      note.deleteRecord();
      note.save();
    },
    toggleExpand() {
      this.toggleProperty('expandNotes');
    }
  }
});
