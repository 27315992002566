import Route from '@ember/routing/route';

export default Route.extend({
  queryParams: {
    contact: {
      refreshModel: true
    },
    kase: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    sort_by: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    },
    organization_location_id: {
      refreshModel: true
    },
    inquiry_staff_member: {
      refreshModel: true
    }
  },

  model(params) {
    return this.store.query('inquiry', params);
  },

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = !['authenticated.inquiries.index', 'authenticated.inquiries.edit'].includes(
        transition.targetName
      );

      if (resetParams) {
        controller.setProperties({
          page: 1,
          kase: '',
          contact: '',
          sort_by: null,
          archived: false,
          order: 'ASC',
          addMode: false,
          organization_location_id: null,
          inquiry_staff_member: null
        });
      }
    }
  }
});
