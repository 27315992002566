import Model, { belongsTo, attr } from '@ember-data/model';
import { cleanPrice } from 'crakn/utils/data-helpers';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: validator('presence', true),
  quantity: validator('presence', true),
  price: validator('number', {
    allowBlank: false,
    allowNone: false,
    allowString: true
  }),
  cost: validator('number', {
    allowBlank: true,
    allowString: true,
  }),
  'productCategory.id': validator('presence', true),
  'invoiceCategory.id': validator('presence', true),
});

export default class SelectionCustomProductModel extends Model.extend(Validations) {
  @attr('string') name;
  @attr('price') price;
  @attr('price') cost;
  @attr('boolean', { defaultValue: false }) isExportCogs;
  @attr('number', { defaultValue: 1 }) quantity;
  @attr('string') notes;
  @attr('boolean') isTaxed;
  @attr('boolean') stateTax;
  @attr('boolean') localTax;
  @attr('boolean') exported;
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') updatedAt;

  /** Additional info */
  @attr('string') manufacturer;
  @attr('string') model;
  @attr('string') material;
  @attr('string') interior;

  @belongsTo('invoiceCategory', { async: true }) invoiceCategory;
  @belongsTo('productCategory', { async: true }) productCategory;
  @belongsTo('v2/statementCategory', { async: true }) statementCategory;
  @belongsTo('statementSubcategory', { async: false }) statementSubcategory;
  @belongsTo('selection', { async: true, polymorphic: true }) selection;

  get multipliedPrice() {
    const price = cleanPrice(this.price);

    return cleanPrice(this.quantity * price);
  }
}
