import Serializer from './application';
import { toCamelCase } from 'crakn/utils/data-helpers';

export default class V2OnlinePlannerSessionSerializer extends Serializer {
  serialize(snapshot, _options) {
    const json = super.serialize(...arguments);
    const changed = toCamelCase(snapshot.record.data);
    json.data.attributes.data = changed;
    return json;
  }
}
