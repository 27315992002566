import { sort } from '@ember/object/computed';
import Controller from '@ember/controller';
import { set, computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';

const Validations = buildValidations({
  'newDiscount.name': validator('presence', true),
  'newDiscount.amount': validator('number', {
    allowString: true,
    gt: 0,
    message: 'Must be greater than zero',
    positive: true
  }),
  'newDiscount.collectedAt': validator('presence', {
    presence: true,
    message:'Date can\'t be empty or invalid'
  }),
  'newDiscount.collectedBy.id': validator('presence', true),
  'newDiscount.discountType.id': validator('presence', true)
});

export default Controller.extend(Validations, {
  flashes: service(),
  intl: service(),
  session: service(),
  staff: service(),

  newDiscount: null,
  disabled: false,

  selection: computed('selection_id', 'selections', function() {
    return this.selections.findBy('id', this.selection_id);
  }),

  sortedDiscounts: sort('selection.discounts', function(a, b) {
    const collectedDiff = b.get('collectedAt') - a.get('collectedAt');
    const enteredDiff = b.get('createdAt') - a.get('createdAt');
    return collectedDiff || enteredDiff;
  }),

  otherNonTaxDiscounts: computed(
    'selection.{nonTaxDiscountsTotal,packageDiscounts}',
    function() {
      return (
        get(this, 'selection.nonTaxDiscountsTotal') -
        get(this, 'selection.packageDiscount')
      );
    }
  ),

  showValidation: false,

  attachSelection() {
    this.set('newDiscount.selection', this.selection);
  },

  actions: {
    setCollectedBy(value) {
      const staffMember = get(this, 'staff.active').findBy('id', value);
      set(this, 'newDiscount.collectedBy', staffMember);
    },

    addDiscount() {
      if (dateInCoaLockedPeriod(this.selection, this.session, this.newDiscount.get('collectedAt'))) {
        this.flashes.addError(this.intl.t('authenticated.kases.manage.financials.discounts.errors.postingPeriodAdd'));
        return;
      }

      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          this.attachSelection();
          set(this, 'showValidation', false);
          this.send('saveNewDiscount', this.newDiscount);
        } else {
          set(this, 'showValidation', true);
        }
      });
    }
  }
});
