import SearchController from 'crakn/controllers/search';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class AuthenticatedPaymentsAdjustmentsController extends SearchController {
  perPage = 10;
  queryParams = ['sort_by', 'order', 'query', 'organization_location_id', 'starts_at', 'ends_at', 'page', 'per_page'];
  @tracked sortBy = null;
  @tracked order = 'ASC';

  @tracked query;
  @tracked organization_location_id;
  @tracked starts_at = moment().subtract(30, 'day').toISOString();
  @tracked ends_at = moment().toISOString();

  @action
  setDate(attr, value) {
    if (value) {
      set(this, attr, value.toISOString());
    } else {
      set(this, attr, null);
    }
  }
}
