import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';
export default class AuthenticatedPaymentsAdjustmentsRoute extends SearchRoute {
  @service store;

  queryParams = {
    query: {
      refreshModel: true
    },
    sort_by: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    },
    organization_location_id: {
      refreshModel: true
    },
    starts_at: {
      refreshModel: true
    },
    ends_at: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true,
    }
  }

  modelType = 'v2/adjustment';
  routeRegex = /authenticated.payments.adjustments/;

  model(params = {}) {
    return this.store.query(this.modelType, params);
  }
}
