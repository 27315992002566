import Controller from '@ember/controller';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default class AdminFinancialConfigurationsEditAdjustmentsController extends Controller {
  @service flashes;

  @alias('model') adjustmentTypes;

  accounts = null;
  chartOfAccounts = null;
  showValidation = false;

  @action
  addAdjustmentType() {
    this.set('showValidation', false);
    
    const adjustmentType = this.store.createRecord('adjustmentType', {
      chartOfAccounts: this.chartOfAccounts
    });
    
    this.model.pushObject(adjustmentType);
  }
  
  @action
  rollbackAdjustmentType(type) {
    type.rollbackAttributes();
    this.send('refreshModel');
  }

  @action
  deleteAdjustmentType(type) {
    if (type.get('isNew')) {
      type.destroyRecord();
      this.model.removeObject(type);
      this.flashes.addSuccess('Record has been removed!');
    } else {
      type.set('archivedAt', moment());
      type
        .save()
        .then(() => {
          this.flashes.addSuccess('Record has been removed!');
          this.send('refreshModel');
        })
        .catch(() => {
          this.flashes.addError('There was an error removing this record.');
        });
    }
  }

  @action
  saveAdjustmentType(type) {
    type.validate().then(({ validations }) => {
      const errors = validations.get('errors');

      if (errors.length > 0) {
        this.set('showValidation', true);
        const errorOutput = errors.map((m) => m.message).join(', ')
        this.flashes.addError(errorOutput);
        this.send('refreshModel');
      } else {
        this.set('showValidation', false);

        type
          .save()
          .then(() => {
            this.flashes.addSuccess('Adjustment type successfully saved!');
          })
          .catch(() => {
            this.flashes.addError(
              'Sorry, something went wrong. Please try again.'
            );
          });
      }
    });
  }

  @action
  setAccount(type, accountId) {
    type.account = this.store.peekRecord('account', accountId);
  }
}
