import { readOnly, reads } from '@ember/object/computed';
import Component from '@ember/component';
import { get, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { validator, buildValidations } from 'ember-cp-validations';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';

const Validations = buildValidations({
  refundAmount: [
    validator('presence', {
      presence: true
    }),
    validator('number', {
      allowString: true,
      lte: reads('model.payment.effectiveAmount'),
      message: reads('model.refundAmountErrorMessage'),
      positive: true,
    })
  ]
});

export default Component.extend(Validations, {
  classNames: ['list__item'],
  tagName: 'li',
  api: service(),
  flashes: service(),
  session: service(),
  intl: service(),
  store: service(),

  payment: null,
  auditLogAction: null,
  isExportDetailsOpen: false,
  isFetchingPayment: false,
  showRefundField: false,
  refundAmount: null,

  showValidation: false,
  isAddRefundButtonDisabled: false,

  get paymentProcessorStatus() {
    switch (this.payment.paymentProcessorStatus) {
      case 'tentative':
      case 'successful':
        return this.intl.t('components.list.payment-summary.information.paymentProcessorStatus.statuses.successful')
      case 'initiated':
        return this.intl.t('components.list.payment-summary.information.paymentProcessorStatus.statuses.initiated')
      case 'pending':
        return this.intl.t('components.list.payment-summary.information.paymentProcessorStatus.statuses.pending')
      case 'awaiting_client_feedback':
        return this.intl.t('components.list.payment-summary.information.paymentProcessorStatus.statuses.awaitingClientFeedback')
      case 'awaiting_internal_feedback':
        return this.intl.t('components.list.payment-summary.information.paymentProcessorStatus.statuses.awaitingInternalFeedback')
      case 'failed':
        return this.intl.t('components.list.payment-summary.information.paymentProcessorStatus.statuses.failed')
      default:
        return this.payment.paymentProcessorStatus
    }
  },

  get refundAmountErrorMessage() {
    return this.intl.t('components.list.payment-summary.errors.refund.amount');
  },

  isNotSettled: computed('payment.transnationalStatus', function () {
    if (get(this, 'payment.transnationalStatus') != 'Complete') {
      return true;
    }
  }),

  idKey: readOnly('payment.id'),

  uuidUrl: computed('payment.receiptUuid', {
    get() {
      const receiptUuid = get(this, 'payment.receiptUuid');
      return `#/generated_document/${receiptUuid}`;
    }
  }),

  paymentStatus: computed('payment.transnationalStatus', function () {
    switch (get(this, 'payment.transnationalStatus')) {
      case 'Complete':
        return 'complete';
      case 'Pending Settlement':
        return 'pending-settlement';
      case 'In Progress':
        return 'in-progress';
      case 'Abandoned':
        return 'abandoned';
      case 'Failed':
        return 'failed';
      default:
        return 'unknown';
    }
  }),

  getTransnationalStatus(payment) {
    const transactionId = payment.transactionId;
    const locationId = this.selection.get('location.id');

    return this.api.json.post(
      'payments/transaction_status', {
      body: {
        transactionId,
        location_id: locationId
      }
    }
    ).then(response => {
      if (response.ok) {
        return response.parsedJson;
      }
    });
  },

  saveTransnationalPaymentDetails(payment, transnationalStatus) {
    const transaction = transnationalStatus.transaction;

    payment.setProperties({
      transnationalStatus: transaction.condition,
      transnationalAuthorizationCode: transaction.authorization_code,
      transnationalDateString: transaction.action.date,
      transnationalResponseText: transaction.action.response_text,
      cardType: transaction.cc_type
    });

    payment.save().then(() => {
      this.flashes.addSuccess('Transaction status updated');
      payment.set('paymentLoading', false);
    });
  },

  actions: {
    addRefund() {
      set(this, 'isAddRefundButtonDisabled', true);
      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          const refund = this.store.createRecord('refund', {
            name: get(this, 'payment.payer'),
            person: get(this, 'payment.person'),
            amount: get(this, 'refundAmount'),
            collectedBy: get(this, 'session.currentUser'),
            enteredBy: get(this, 'session.currentUser'),
            payment: get(this, 'payment'),
            selection: get(this, 'selection')
          });

          refund.save().then(() => {
            set(this, 'isAddRefundButtonDisabled', false);
            this.flashes.addSuccess(this.intl.t('components.list.payment-summary.successes.refund.creation'));

            set(this, 'showRefundField', false);
            set(this, 'refundAmount', null);

            this.sendAction('onUpdate');
          }).catch(() => {
            set(this, 'isAddRefundButtonDisabled', false);
            this.flashes.addError(this.intl.t('components.list.payment-summary.errors.refund.creation'));
            refund.destroyRecord();
          });
        } else {
          set(this, 'isAddRefundButtonDisabled', false);
          set(this, 'showValidation', true);
        }
      })
    },

    cancelRefund() {
      set(this, 'showRefundField', false);
      set(this, 'refundAmount', null);
      set(this, 'showValidation', false);
    },

    fetchPayment() {
      set(this, 'isFetchingPayment', true);
      this.store.findRecord('payment', get(this, 'payment.id')).then((payment) => {
        set(this, 'payment', payment);
        set(this, 'isFetchingPayment', false);
      });
    },

    showDeleteDialog(payment) {
      if (dateInCoaLockedPeriod(this.selection, this.session, payment.get('collectedAt'))) {
        this.flashes.addError(this.intl.t('components.list.payment-summary.errors.paymentPostingPeriod'));
        return;
      }
      set(this, 'showDialog', true);
    },

    removePayment(payment) {
      this.attrs.onRemove(payment);
    },

    printPaymentReceipt(payment) {
      this.api.json
        .get(
          'print_payment_receipt',
          {
            params: {
              payment_id: get(payment, 'id'),
              receipt_uuid: get(payment, 'receiptUuid')
            }
          }
        )
        .then(response => {
          if (!response.ok) {
            this.send('flashError', 'Oops, something went wrong! Please try again.');
          }
        });
    },

    printRefundReceipt(refund) {
      this.api.json.get('print_refund_receipt', {
        params: {
          refund_id: get(refund, 'id'),
          receipt_uuid: get(refund, 'receiptUuid'),
        }
      }).then((response) => {
        if (!response.ok) {
          this.send('flashError', 'Oops, something went wrong! Please try again.');
        }
      })
    },

    getRefundReceiptUrl(refund) {
      return `#/generated_document/${refund.receiptUuid}`;
    },

    clearPending() {
      this.payment.markedPending = false;
      this.payment
        .save()
        .then(() => {
          this.sendAction('onUpdate');
          this.flashes.addSuccess('Payment marked not pending')
        });
    },

    async setupTransnationalStatus(payment) {
      payment.set('paymentLoading', true);

      const delayIncrement = 3000; // 3s
      let nextDelay = delayIncrement;
      let attemptsRemaining = 3;
      let paymentCompleted;

      do {
        const transnationalStatus =
          await this.getTransnationalStatus(payment);

        const action_or_actions = transnationalStatus?.transaction?.action;
        let action;

        if (Array.isArray(action_or_actions)) {
          action = action_or_actions[0];
        } else {
          action = action_or_actions;
        }

        paymentCompleted = !isEmpty(action?.date);

        if (paymentCompleted) {
          this.saveTransnationalPaymentDetails(payment, transnationalStatus)
        } else {
          attemptsRemaining = attemptsRemaining - 1;

          if (attemptsRemaining) {
            // Sleep <nextDelay> seconds
            await new Promise(resolve => setTimeout(resolve, nextDelay));

            nextDelay = nextDelay + delayIncrement;
          } else {
            payment.set('paymentLoading', false);
            this.flashes.addError(this.intl.t('components.list.payment-summary.errors.paymentStatus'));
          }
        }
      } while (!paymentCompleted && attemptsRemaining);
    },
    async resendToBeaconfp() {
      await this.api.json.post(`payments/${this.payment.id}/resend_to_beaconfp`, {});
    }
  }
});
