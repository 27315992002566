import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AdminManageCaseNumberingRoute extends Route {
  @service session;
  
  model() {
    const clientInfoId = this.get('session.session.authenticated.client.client_info_id');

    if (clientInfoId) {
      return this.store.findRecord('clientInfo', clientInfoId);
    }

    return this.store
      .createRecord('clientInfo')
      .save()
      .then(result => {
        this.set('session.currentClient.client_info_id', result.id);

        return result;
      });
  }
}
