import Route from '@ember/routing/route';
import { set, get } from '@ember/object';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import config from 'crakn/config/environment';
import moment from 'moment';

export default class AuthenticatedWhiteboardRoute extends Route {
  @service activeInquiry;
  @service store;

  layout = 'whiteboard'
  isFullscreenActive = true

  queryParams = {
    page: {
      refreshModel: true
    },
    organization_location_id: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    has_events: {
      refreshModel: true
    },
    sort_by: {
      refreshModel: true
    },
    whiteboard_starts_at: {
      refreshModel: true
    },
    whiteboard_ends_at: {
      refreshModel: true
    },
    status: {
      refreshModel: true
    },
    user_id: {
      refreshModel: true
    },
    show_pending: {
      refreshModel: true
    }
  }

  model(params) {
    return this.store.query('kase', params);
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    if (config.environment !== 'test') {
      if (!get(controller, 'refreshMetrinome')) {
        controller.startRefreshMetrinome();
      }
  
      if (!get(controller, 'paginateMetrinome')) {
        controller.startPaginateMetrinome();
      }
    }

    const organizationLocationId = controller.organization_location_id;

    if (organizationLocationId) {
      const modelType = organizationLocationId.includes('O') ? 'organization' : 'location';

      controller.set('organizationOrLocation', this.store.findRecord(modelType, organizationLocationId.substring(1)))
    }

    if (!get(controller, 'refreshThreshold')) {
      controller.set(
        'refreshThreshold',
        moment()
          .add(30, 'minutes')
          .valueOf()
      );
    }

    set(this, 'activeInquiry.model', null);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      run.cancel(get(controller, 'refreshMetrinome'));
      run.cancel(get(controller, 'paginateMetrinome'));

      controller.set('refreshThreshold', null);
    }
  }
}
