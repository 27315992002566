import Transform from '@ember-data/serializer/transform';
import moment from 'moment';

export default Transform.extend({
  deserialize(serialized) {
    const date = moment(serialized);
    return new Date(date.year(), date.month(), date.date());
  },

  serialize(deserialized) {
    const date = moment(deserialized);
    return date.format('YYYY-MM-DD');
  }
});
