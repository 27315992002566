import Adapter from './application';

export default Adapter.extend({
  // TODO: clean this up when MiscSales get refactored into the selection object
  buildURL(_modelName, _id, snapshot, requestType) {
    let url = this._super(...arguments);

    if (snapshot == null) {
      return url;
    }

    const adapterOptions = snapshot.adapterOptions;
    let selectionType = null;
    if (
      typeof adapterOptions == 'object' &&
      'selectionType' in adapterOptions
    ) {
      selectionType = adapterOptions.selectionType;
    }

    if (requestType == 'createRecord' && selectionType != null) {
      url += `?selectionType=${selectionType}`;
    }
    return url;
  }
});
