import { get } from '@ember/object';
import ApplicationAdapter from 'crakn/adapters/application';

export default ApplicationAdapter.extend({
  findRecord(store, type, id, snapshot) {
    if (snapshot.adapterOptions) {
      const token = get(snapshot.adapterOptions, 'token');
      const url = this.buildURL(type, token, snapshot, 'findRecord');

      return this.ajax(url, 'GET').then(response => {
        store.pushPayload(response);
        return store.peekRecord('esignature', response.esignature.id);
      });
    } else {
      return this._super(...arguments);
    }
  }
});
