import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { validStatusTypes } from 'crakn/utils/status-helpers';

export default class CraknPillsComponent extends Component {
  @service store;

  @tracked isAdvanced = false;
  @tracked isEditing = false;
  @tracked statusTypes = [];

  queryStatusTypes() {
    let statusTypes = validStatusTypes;

    this.store
        .query('selection', {
          kase_id: this.args.kase.id,
          type: 'KaseSelection'
        })
        .then(selections => {
          selections.forEach(selection => {
            if (selection && selection.get('posted')) {
              statusTypes = statusTypes
                .rejectBy('id', 'preneed')
                .rejectBy('id', 'lost')
                .rejectBy('id', 'archived');
            }
          });

          const nextStatus = this.args.kase.nextAvailableStatus;

          if (nextStatus && !this.isAdvanced) {
            statusTypes = statusTypes.filter(t => t.id === nextStatus);
          }

          this.statusTypes = statusTypes;
        });
  }

  get currentStatus() {
    return validStatusTypes.find(t => t.id === this.args.kase.status);
  }

  @action
  toggleAdvanced() {
    this.queryStatusTypes();
    this.isAdvanced = !this.isAdvanced;
  }

  @action
  toggleEditing() {
    this.queryStatusTypes();
    this.isAdvanced = this.args.kase.nextAvailableStatus === null;
    this.isEditing = !this.isEditing;
  }

  @action
  onUpdateStatus(status) {
    this.isEditing = false;
    this.isAdvanced = false;

    this.args.updateStatus(this.args.kase, status);
  }
}
