import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesManageIntegrationsTributeVideoRoute extends Route {
  @service api;
  layout = 'custom-aside';

  model() {
    const integrationsModel = this.modelFor('authenticated.kases.manage.integrations');

    return this.store.queryRecord('integrationExport', {
      kase_id: integrationsModel.get('id'),
      type: 'tribute_video',
      most_recent_export: true
    });
  }

  setupController(controller) {
    super.setupController(...arguments);

    const integrationsModel = this.modelFor('authenticated.kases.manage.integrations');

    controller.set('kase', integrationsModel);
  }
  
  @action
  refreshModel() {
    this.refresh();
  }

  @action
  willTransition() {
    this.controller.set('albumId', null);
    this.controller.set('tributeVideoExport', null);
    this.controller.set('tributeVideoUrl', null);
  }
}
