import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AsdIntegrationRoute extends Route {
  @service session;
  @service store;

  model() {
    return this.store.query('integrationService', {
      name: 0, // asd
      not_category: 'accounting',
      archived: false
    });
  }

  async setupController(controller) {
    super.setupController(...arguments);
    controller.set('locations', this.store.findAll('v2/location'));
    controller.set(
      'locationMetas',
      await this.store.query('v2/locationMeta', {
        source: 'asd',
        clientId: this.session.currentClient.id
      })
    );
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
