import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';

export default class KasesCreateRoute extends SearchRoute {
  @service session;

  queryParams = {
    query: {
      refreshModel: true
    },
    birthDate: {
      refreshModel: true
    },
    deathDate: {
      refreshModel: true
    }
  };

  modelType = 'kase';
  routeRegex = /authenticated.kases/;

  model(params) {
    return this.store.query(this.modelType, {
      query: params.query,
      birthDate: params.birthDate,
      deathDate: params.deathDate
  })}

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('kases', model);
  }

  resetController(controller, isExiting, transition) {
    if (isExiting || transition) {
      controller.setProperties({
        query: null,
        birthDate: null,
        deathDate: null
      });
    }
  }
}
