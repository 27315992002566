import Route from '@ember/routing/route';

export default Route.extend({
  queryParams: {
    page: {
      refreshModel: true
    },
    query: {
      refreshModel: true
    }
  },

  model(params) {
    return this.store.query('generalPriceList', params);
  },

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams =
        'authenticated.admin.manage.general-price-lists.index' === transition.targetName;

      if (resetParams) {
        controller.setProperties({
          page: 1,
          query: null
        });
      }
    }
  }
});
