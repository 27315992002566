import { helper as buildHelper } from '@ember/component/helper';
import { formatDateTime } from 'crakn/utils/date-helpers';

export function craknDateTime(args) {
  if (args[0]) {
    return formatDateTime(...args);
  } else {
    return '';
  }
}

export default buildHelper(craknDateTime);
