import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

const Validations = buildValidations({
  'newProductCategory.name': validator('presence', true)
});

export default class AdminGeneralPriceListsEditProductCategoriesCategoriesController extends Controller.extend(
  Validations
) {
  @service flashes;
  @service store;

  @tracked showValidations = false;

  @action
  setAccount(productCategory, accountId) {
    const account = this.store.peekRecord('v2/account', accountId);
    productCategory.set('account', account);
  }

  @action
  revertRecord() {
    this.send('refreshModel');
  }

  @action
  removeProductCategory(productCategory) {
    productCategory.set('archivedAt', moment());
    productCategory.set('name', `${productCategory.get('name')} (Archived)`);

    productCategory
      .save()
      .then(() => {
        this.flashes.addSuccess('Product category has been archived!');
        this.send('refreshModel');
      })
      .catch(() =>
        this.flashes.addError('Something went wrong! Please try again.')
      );
  }

  @action
  saveNewProductCategory() {
    this.showValidations = false;

    this.validate().then(({ validations }) => {
      if (validations.get('isValid')) {
        this.showValidations = false;
        this.newProductCategory
          .save()
          .then(() => {
            this.flashes.addSuccess('Product category saved!');
            this.send('refreshModel');
            this.newProductCategory = this.store.createRecord(
              'v2/productCategory',
              { generalPriceList: this.generalPriceList }
            );
          })
          .catch(() => {
            this.flashes.addError('Something went wrong. Please try again.');
          });
      } else {
        this.showValidations = true;
      }
    });
  }

  @action
  saveProductCategory(productCategory) {
    productCategory
      .save()
      .then(() => {
        this.flashes.addSuccess('Product category updated!');
      })
      .catch(() => {
        this.flashes.addError('Something went wrong, please try again.');
      });
  }
}
