import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Toolbelt from 'crakn/mixins/component-toolbelt';

export default Component.extend(Toolbelt, {
  classNames: ['list__content'],

  session: service(),
  showValidation: false,
  model: null,
  generalPriceListId: null,
  collectsTax: false,

  showStatementCategory: computed(
    'statementType',
    'session.currentClient.{can_use_custom_sfgs,can_view_statement_categories}',
    function() {
      const customStatement = this.statementType === 'Custom';
      const customSFGS = get(this, 'session.currentClient.can_use_custom_sfgs');
      const customStatementCategories = get(this, 'session.currentClient.can_use_custom_statement_categories');
      return customStatement || (customSFGS && customStatementCategories);
    }
  ),

  actions: {
    cancelCustomProduct() {
      const model = this.model;

      if (model) {
        model.rollbackAttributes();
      }

      if (this.attrs.cancelCustomProduct) {
        this.attrs.cancelCustomProduct(model);
      }
    },

    saveCustomProduct() {
      const selectionCustomProduct = this.model;

      selectionCustomProduct.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          this.attrs.saveCustomProduct(selectionCustomProduct);
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    defaultTax() {
      this.toggleProperty('model.isTaxed');
      if (get(this, 'model.isTaxed') == true) {
        set(this, 'model.stateTax', true);
        set(this, 'model.localTax', true);
      } else if (get(this, 'model.isTaxed') == false) {
        set(this, 'model.stateTax', false);
        set(this, 'model.localTax', false);
      }
    }
  }
});
