import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2OlpDocumentModel extends Model {
  @attr('string') extension;
  @attr('string') mediaType;
  @attr('string') name;
  @attr('string') s3Key;
  @attr('string') size;

  @belongsTo('v2/client', { async: true }) client;
  @belongsTo('v2/online-planner-config', { async: true }) onlinePlannerConfig;
  @belongsTo('v2/blankForm', { async: true }) blankForm;
}
