import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminCustomFieldsRoute extends SearchRoute {
  @service session;

  queryParams = {
    page: {
      refreshModel: true
    },
    location_id: {
      refreshModel: true
    }
  }

  modelType = 'v2/custom-field-config';
  routeRegex = /authenticated.admin.manage.custom-fields/;
  queryIncluded = 'location.address';

  setupController(controller, model) {
    controller.set('customFieldConfigs', model)
  }

  @action
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('location_id', null);
      controller.set('page', 1);
    }
  }
}
