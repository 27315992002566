import Route from '@ember/routing/route';

export default class CraknClientsEditSfgsConfigRoute extends Route {
  model() {
    const clientId = this.modelFor('authenticated.crakn.clients.edit').get('id');

    return this.store.findRecord(
      'v2/client', clientId, {
        include: 'contract-templates'
      });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const systemSfgsTemplates = this.store.query('v2/systemSfgsTemplate', {});

    controller.set('systemSfgsTemplates', systemSfgsTemplates);
    controller.set('contractTemplates', model.get('contractTemplates'));
    controller.set('client', model);
  }
}
