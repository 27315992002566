import { filter } from '@ember/object/computed';
import { computed } from '@ember/object';
import SelectionExporter from './selection-exporter';
import { inject as service } from '@ember/service';

export default SelectionExporter.extend({
  store: service(),

  hasSelections: computed('chartOfAccountsId', 'selections', function() {
    const chartOfAccountsId = this.chartOfAccountsId;
    return this.selections.then((selections) => {
      if (
        chartOfAccountsId == null ||
        selections == null ||
        selections.length <= 0
      ) {
        return false;
      }
      return true;
    });
  }),
  selections: computed('chartOfAccountsId', 'page', function() {
    const chartOfAccountsId = this.chartOfAccountsId;
    if (chartOfAccountsId == null) {
      return Promise.resolve([]);
    }

    return this.store.query('v2/selection', {
      chartOfAccountsId,
      perPage: 5,
      type: 'MiscSelection',
      page: this.page,
      include: 'purchaser',
      exportable: true
    });
  }),
  exportableSelections: filter('selections', function(selection) {
    return (
      selection.get('hasUnexportedAccounting') && !selection.get('doNotExport')
    );
  })
});
