import { set, get } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default Route.extend({
  api: service(),
  layout: 'custom-aside',

  model() {
    const model = this.modelFor('authenticated.kases.manage.integrations');

    return RSVP.hash({
      model,
      kase: model,
      funeraloneExport: this.store.queryRecord('integrationExport', {
        kase_id: model.id,
        type: 'funeralone',
        most_recent_export: true
      })
    });
  },

  setupController(controller, model, transition) {
    this._super(controller, model, transition);
    set(
      controller,
      'events',
      this.store.query('event', { kase_id: get(model, 'kase.id') })
    );
  },

  actions: {
    funeralone(e) {
      e.target.disabled = true; // avoid double-click issues
      const kase_id = this.currentModel.kase.id;

      this.api.json.put(
        'funeral_one/kases',
        { body: { id: kase_id } }
      ).then(response => {
        if (response.ok) {
          this.store.pushPayload('kase', response.parsedJson);
          this.refresh();
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false; // avoid double-click issues
        }
      });
    }
  }
});
