import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class SettingsCalendarRoute extends Route {
  @service flashes;

  model() {
    return this.modelFor('authenticated.settings');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set(
      'secureToken',
      this.store.queryRecord('secureToken', { user_id: model.id })
    );
  }
}
