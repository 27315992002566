import { bind } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking'
import config from 'crakn/config/environment';

/* eslint-disable */
const emailValidationRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable */

export default class LoginController extends Controller {
  @tracked email;
  @tracked password;
  @tracked error;
  @tracked loginSuccess = false;
  @tracked emailValidated = false;
  @tracked showEmailValidation = false;
  @tracked showPasswordValidation = false;
  @tracked showUserMigrationResult = false;
  @tracked migrationResultSuccess = false;
  @tracked migrationResultNoPassword = false;
  @tracked verifyAccount = false;
  @tracked migratedWithPw = false;
  @tracked migrationFailedReason = null;


  @service browser;
  @service intl;
  @service router;
  @service session;
  @service api;

  handleLoginSuccess() {
    this.error = null;
    this.loginSuccess = true;
  }

  handleLoginError() {
    this.error = this.intl.t('login.form.messages.invalidCredentials');
    this.resetLogin();
  }



  resetLogin() {
    this.email = null;
    this.password = null;
    this.emailValidated = false;
    setTimeout(()=>{
      document.getElementById('login-email').focus();
    })
  }



  validateEmail() {
    if(this.emailMessage) {
      return false;
    } else {
      return true;
    }
  }

  validatePassword() {
    if(this.passwordMessage) {
      return false;
    } else {
      return true;
    }
  }

  @computed('email')
  get emailMessage() {
    if (this.email && emailValidationRegEx.test(this.email)) {
      return null;
    } else {
      return (this.intl.t('login.form.email.error'));
    }
  }

  @computed('password')
  get passwordMessage() {
    if (this.password) {
      return null;
    } else {
      return (this.intl.t('login.form.password.error'));
    }
  }

  @action
  login(event) {
    event.preventDefault(); // Stop form redirect
    const authenticator = 'authenticator:application';
    const credentials = { email: this.email, password: this.password };

    const isPasswordValid = this.validatePassword();

    if (isPasswordValid) {
      this.showPasswordValidation = false;
      this.session
        .authenticate(authenticator, credentials).then(() => {
          bind(this, 'handleLoginSuccess')
        }).catch(error => {
          console.log(error);
          switch (error) {
            case 'user_migrated': // strong password
              this.showUserMigrationResult = true;
              this.migrationResultSuccess = true;
              this.migrationResultNoPassword = false;
              this.verifyAccount = false;
              this.migratedWithPw = true;
              break;
            case 'user_migrated_without_password': // weak password
              this.showUserMigrationResult = true;
              this.migrationResultSuccess = true
              this.migrationResultNoPassword = true;
              this.verifyAccount = false;
              this.migratedWithPw = false;
              break;
            case 'user_not_migrated':
              this.showUserMigrationResult = true;
              this.migrationResultSuccess = false;
              this.migrationResultNoPassword = false;
              this.migrationFailedReason = this.intl.t('login.migrationResult.failed');
              this.verifyAccount = false;
              this.migratedWithPw = false;
              break;
            case 'user_not_migrated_existed':
              this.showUserMigrationResult = true;
              this.migrationResultSuccess = false;
              this.migrationResultNoPassword = false;
              this.migrationFailedReason = this.intl.t('login.migrationResult.failedUserExisted');
              this.verifyAccount = true;
              this.migratedWithPw = false;
              break;
            default:
              this.showUserMigrationResult = false;
              this.handleLoginError();
              break;
          }

        });
    } else {
      this.showPasswordValidation = true;
    }
  }

  @action cancelLogin() {
    this.resetLogin();
  }

  @action forgotPassword() {
    this.resetLogin();
    location.href = '#/forgot-password';
  }

  @action
  async submitEmail() {
    const isValid = this.validateEmail();
    this.error = null;
    if(isValid) {

      const redirect = config.url;

      const response = await this.api.json.get('v2/sso/login_uri', { params: { email: this.email, redirect_uri: redirect } });
      const ssoUrl = response?.parsedJson?.uri;

      if (ssoUrl) {
        history.pushState({}, '', redirect);
        window.open(ssoUrl,'_self')
      } else {
        this.showEmailValidation = false;
        this.emailValidated = true;
        setTimeout(()=>{
          document.getElementById('login-password').focus();
        }, 100)
      }

    } else {
      this.showEmailValidation = true;
      this.emailValidated = false;
    }
  }

  @action
  emailKeyDown(event) {
    if(event.keyCode === 13) {
      event.preventDefault();
      this.submitEmail();
      return false;
    }
  }

  @action
  migrationContinue() {

    this.password = null;
    this.emailValidated = false;
    this.error = null;
    if (this.migrationResultSuccess){
      this.submitEmail();
    } else {
      this.showUserMigrationResult = false;
    }
  }
}
