import Model, { belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

const Validations = buildValidations({
  content: validator('presence', true),
});

export default class NoteModel extends Model.extend(Validations) {
  @attr('string') content;
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;
  @attr('moment-datetime', { defaultValue: () => moment() }) updatedAt;
  @attr('boolean') requiresFollowUp;

  @belongsTo('user', { async: true }) editedByUser;
  @belongsTo('user', { async: true }) user;
  @belongsTo('kase', { async: true }) kase;
}
