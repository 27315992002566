import SearchController from 'crakn/controllers/search';
import { set, action } from '@ember/object';
import { run } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service'

export default class AuthenticatedPlacesIndexController extends SearchController {
  queryParams = ['query', 'page', 'perPage', 'archived', 'vendor'];
  
  @service session;

  @tracked filter='all';
  @tracked vendor;

  @action
  changeFilter(value) {
    if (value == 'vendors') {
      this.vendor = true;
    } else {
      this.vendor = null;
    }
  }

  @action
  renderFilter() {
    if (this.vendor == 'true') {
      this.filter = 'vendors'
    }
  }
  
  @action
  addNew() {
    this.transitionToRoute('authenticated.places.new').then(
      run.bind(this, function (newRoute) {
        set(newRoute, 'controller.model.name', this.query);
      })
    );
  }
}
