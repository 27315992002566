import SearchController from 'crakn/controllers/search';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class EsignaturesController extends SearchController {
  @service api;
  @service flashes;

  @tracked has_pending_esignatures = true;
  @tracked kase_query = null;
  @tracked signer_query = null;
  @tracked location_id = null;

  queryParams = [
    'has_pending_esignatures',
    'kase_query',
    'signer_query',
    'location_id'
  ];

  @action
  updateKaseQuery(value) {
    this.kase_query = value;
  }

  @action
  updateSignerQuery(value) {
    if (value === '') {
      this.signer_query = null;
    } else {
      this.signer_query = value;
    }
  }

  @action
  updateLocation(value) {
    this.location_id = value;
  }

  @action
  clearQueryParamValues() {
    this.clearQuery();
    this.kase_query = null;
    this.signer_query = null;
    this.location_id = null;
  }
}
