import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"p--md--t strong\" {{did-insert this.refreshMaintenanceModes}}>\n  {{#each this.tmsMaintenanceSettings as |setting|}}\n    {{#if (eq setting.value true)}}\n      <div class=\"red\">\n        {{ setting.description }}\n      </div>\n    {{/if}}\n  {{/each}}\n</div>", {"contents":"<div class=\"p--md--t strong\" {{did-insert this.refreshMaintenanceModes}}>\n  {{#each this.tmsMaintenanceSettings as |setting|}}\n    {{#if (eq setting.value true)}}\n      <div class=\"red\">\n        {{ setting.description }}\n      </div>\n    {{/if}}\n  {{/each}}\n</div>","moduleName":"crakn/components/g/integrations/integrations-maintenance.hbs","parseOptions":{"srcName":"crakn/components/g/integrations/integrations-maintenance.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class GIntegrationsMaintenance extends Component {
  @service store;
  @service tmsSettings;
  @service session;

  get tmsMaintenanceSettings(){
    return this.tmsSettings.allSettings.filter((setting) =>{
      const key = setting.key;
      const isMaintenanceMode = key.includes('_maintenance_mode');
      const integration = key.replace('_maintenance_mode', '');
      const canViewIntegration = this.session.currentClient['can_view_' + integration];
      return isMaintenanceMode && canViewIntegration;
    });
  }

  @action
  async refreshMaintenanceModes(){
    await this.tmsSettings.loadSettings();
  }
}
