import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedOnlinePlannerNotificationSettingsRoute extends Route {
  @service store;
  @service session;

  queryParams = {
    query: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    }
  }

  model(params) {
    const onlinePlannerConfig = this.modelFor('authenticated.admin.online-planner.edit');
    return this.store.query('v2/user-notification', {
      client_id: this.session.currentClient.id,
      user_notification_type: 'online_planner',
      online_planner_config_id: onlinePlannerConfig.get('id'),
      ...params
    })
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('onlinePlannerConfig', this.modelFor('authenticated.admin.online-planner.edit'));
  }

  async resetController(controller, isExiting) {
    if (isExiting) {
      const notificationsToDestroy = controller.model.filter(
        userNotification => userNotification.onlinePlannerConfigUserNotifications.length <= 0
      );
      
      await Promise.all(notificationsToDestroy.map(userNotification => userNotification.destroyRecord()));

      controller.setProperties({
        isEditingNewRecipient: false,
        page: 1,
        query: '',
      });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
