import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CraknClientsRoute extends Route {
  @service session;
  @service router;

  beforeModel() {
    if (this.session.currentUser.isSuperuser) {
      return true;
    }

    // Unauthorized users are redirected to the dashboard
    this.router.transitionTo('authenticated.dashboard');
  }
}
