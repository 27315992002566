import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { alias } from '@ember/object/computed';
import { hasFullAddress } from 'crakn/utils/address-helpers';
import { validator, buildValidations } from 'ember-cp-validations';
import { inject as service } from '@ember/service';

const Validations = buildValidations({
  name: validator('presence', true),
  email: validator('format', {
    type: 'email',
    allowNonTld: false,
    allowBlank: true,
  }),
});

const ValidationModel = Model.extend(Validations);

export default class V2PlaceModel extends ValidationModel {
  @service api;

  @attr('boolean', { defaultValue: false }) isVendor;

  @attr('moment-datetime') archivedAt;

  @attr('phone') faxNumber;
  @attr('phone') phoneNumber;
  
  @attr('string') email;
  @attr('string') notes;
  @attr('string') websiteUrl;
  @attr('string') placeType;

  @alias('address.city') city;
  @alias('address.country') country;
  @alias('address.county') county;
  @alias('address.line1') line1;
  @alias('address.line2') line2;
  @alias('address.name') name;
  @alias('address.state') state;
  @alias('address.zipCode') zipCode;

  @belongsTo('v2/address', { async: true }) address;

  @hasMany('v2/room', { async: true }) rooms;

  get typeaheadName() {
    if (!this.address.get('city')) {
      return this.name;
    }

    return `${this.name} (${this.address.get('city')})`;
  }

  get singleLineAddress() {
    return [this.line1, this.line2, this.city, this.state, this.zipCode]
      .filter(Boolean)
      .join('+');
  }

  get addressLines() {
    return [this.line1, this.line2].filter(Boolean).join(', ');
  }

  get cityStateZip() {
    if (!this.city && !this.state && !this.zipCode) {
      return null;
    }

    const cityState = [this.city, this.state].filter(Boolean).join(', ');

    if (!this.state) {
      return [cityState, this.zipCode].filter(Boolean).join(', ');
    } else {
      return [cityState, this.zipCode].filter(Boolean).join(' ');
    }
  }

  get hasFullAddress() {
    return hasFullAddress(this);
  }

  replace(substitutePlace) {
    return new Promise((resolve, reject) => {
      const param = substitutePlace
        ? `?substitute_id=${substitutePlace.id}`
        : '';
      return this.api.json
        .delete(`v2/places/${this.id}/replace${param}`)
        .then((response) => (response.ok ? resolve() : reject()));
    });
  }
}
