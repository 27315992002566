import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default class PreneedModel extends Model {
  @attr('string') arrangementType;
  @attr('string') insuranceCompany;
  @attr('string') insuranceCurrentFaceValue;
  @attr('string') insuranceInitialFaceValue;
  @attr('string') policyNumber;
  @attr('string') propertyLocation;
  @attr('string') referralSource;
  @attr('string') trustCompany;
  @attr('string') trustInitialInvestment;
  @attr('string') trustNumber;

  @attr('boolean') hasCemeteryProperty;
  @attr('boolean') hasInsurance;
  @attr('boolean') hasTravelPlan;
  @attr('boolean') hasTrust;
  @attr('boolean') imminent;

  @belongsTo('kase', { async: true }) kase;

  @hasMany('insurancePolicies') insurancePolicies;
  @hasMany('trusts') trusts;
}
