export default {
  'Anniversary A': {
    url: 'https://a.crakncdn.com/uploads/best_wishes_template_images/Anniversary_A.jpg',
  },
  'Anniversary B': {
    url: 'https://a.crakncdn.com/uploads/best_wishes_template_images/Anniversary_B.jpg',
  },
  'Birthday A': {
    url: 'https://a.crakncdn.com/uploads/best_wishes_template_images/Birthday_A.jpg',
  },
  'Birthday B': {
    url: 'https://a.crakncdn.com/uploads/best_wishes_template_images/Birthday_B.jpg',
  },
  'Condolences A': {
    url: 'https://a.crakncdn.com/uploads/best_wishes_template_images/Condolences_A.jpg',
  },
  'Condolences B': {
    url: 'https://a.crakncdn.com/uploads/best_wishes_template_images/Condolences_B.jpg',
  },
  'Holiday A': {
    url: 'https://a.crakncdn.com/uploads/best_wishes_template_images/Holiday_A.jpg',
  },
  'Holiday B': {
    url: 'https://a.crakncdn.com/uploads/best_wishes_template_images/Holiday_B.jpg',
  }
}
