import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  session: service(),
  layout: 'custom-aside',
  titleToken(model) {
    return model.get('deceased').get('name');
  },

  model(params) {
    return this.store.findRecord('kase', params.kase_id);
  },

  get defaultContractName() {
    return this.session.currentClient.relabel_contract_to_invoice ? 
    `Invoice` : `Contract`;
  },
  checkSelectionsClicked: false,
  actions: {
    checkSelections() {
      if(this.checkSelectionsClicked) {
        return false;
      }

      this.checkSelectionsClicked = true;

      const kase = this.currentModel;
      const kaseFinancial = get(kase, 'kaseFinancial');

      if (get(kaseFinancial, 'hasNoSelections')) {
        this.store
          .createRecord('selection', {
            name: this.defaultContractName,
            kaseFinancial,
            type: 'KaseSelection'
          })
          .save()
          .then(contract => {
            set(kaseFinancial, 'hasNoSelections', false);
            this.transitionTo('authenticated.kases.manage.financials.selection', {
              queryParams: { selection_id: get(contract, 'id') }
            });
            this.checkSelectionsClicked = false;
          })
          .catch(() => {
            this.checkSelectionsClicked = false;
            this.flashes.addError(
              'Something went wrong. If errors continue, please contact support@tributetms.com.'
            );
          });
      } else {
        this.transitionTo('authenticated.kases.manage.financials.overview');
        this.checkSelectionsClicked = false;
      }
    }
  }
});
