import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2OnlinePlannerProductCategoryModel extends Model {
  @attr('moment-datetime') archivedAt;
  @attr('string') name;
  @attr('string') description;
  @attr('string') icon;
  @attr('string') categoryId;
  @attr('string', { defaultValue: 'all'}) dispositionCategory;
  @attr('number') productCount;

  @belongsTo('v2/client', { async: true }) client;
  @belongsTo('v2/online-planner-config', { async: true }) onlinePlannerConfig;
  @belongsTo('v2/product-category', { async: true }) productCategory;
  @belongsTo('v2/user', { async: true }) archivedByUser;
}
