import Model, { attr } from '@ember-data/model';

export default class TributeWebsiteThemeModel extends Model {
  @attr('string') city;
  @attr('string', { defaultValue: 'USA' }) country;
  @attr('string') county;
  @attr('string') line1;
  @attr('string') line2;
  @attr('string') name;
  @attr('string') state;
  @attr('string') zipCode;
}
