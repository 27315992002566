import Component from '@ember/component';
import { computed } from '@ember/object';

const MdIconComponent = Component.extend({
  classNames: ['md-icon'],
  classNameBindings: ['sizeClass', 'spin:md-icon--spin'],
  tagName: 'i',

  spin: false,
  icon: null,
  size: null,

  sizeClass: computed('size', function() {
    const size = this.size;

    return size ? `md-icon--${size}` : '';
  })
});

export default MdIconComponent;
