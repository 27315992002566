import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { set, get } from '@ember/object';
import Toolbelt from 'crakn/mixins/component-toolbelt';

export default Component.extend(Toolbelt, {
  classNames: ['list__item', 'list__item--wrap'],
  classNameBindings: ['record.isDefault:list__item--disabled'],
  tagName: 'li',

  idKey: readOnly('record.id'),

  record: null,
  isEditing: false,
  showDialog: false,
  sendComponentAction: false,

  actions: {
    sendSaveRecord() {
      const record = this.record;
      set(this, 'isEditing', false);
      this.sendAction('saveNewRecord', get(record, 'id'));
    },

    /**
     * Save the record
     * @return {Undefined}
     */
    saveRecord() {
      const record = this.record;

      if (get(record, 'isDefault')) {
        return;
      }

      this.attrs.saveRecord(get(record, 'id')).then(() => {
        set(this, 'isEditing', false);
      });
    },

    /**
     * Revert the record
     * @return {Undefined}
     */
    revertRecord() {
      this.record.rollbackAttributes();
      set(this, 'isEditing', false);
    }
  }
});
