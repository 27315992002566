import Route from '@ember/routing/route';
import { get } from '@ember/object';
import RSVP from 'rsvp';

export default class KasesManageTaskRoute extends Route {
    model() {
      const kase = this.modelFor('authenticated.kases.manage');

      return RSVP.hash({
        kase,
        tasks: this.store.query('kaseTask', { kase_id: get(kase, 'id') }),
        filters: {
          all: (() => true),
          open: ((task) => task.status == 'yes' && !task.complete),
          complete: ((task) => task.complete),
          enabled: ((task) => task.status == 'yes'),
          disabled: ((task) => task.status == 'no'),
          assigned: ((task) => task.status != null)
        }
      });
    }

    setupController(controller, model) {
      super.setupController(...arguments);

      controller.setProperties({
        kase: model.kase
      });
    }

    afterModel(model, transition) {
      if (transition.to.localName == 'index') {
        if (model.tasks.filterBy('status', 'yes').length > 0 ){
          this.replaceWith('authenticated.kases.manage.tasks.open');
        } else {
          this.replaceWith('authenticated.kases.manage.tasks.all');
        }
      }
    }
}
