import Component from '@ember/component';

export default Component.extend({
  classNameBindings: ['qaClass'],

  qaClass: null,
  actionUrl: null,
  cc_number: null,
  cc_name: null,
  cc_exp: null,
  cvv: null,

  cardSelectors: {
    numberInput: 'input[name="cc_number"]',
    expiryInput: 'input[name="cc_exp"]',
    cvcInput: 'input[name="cvv"]',
    nameInput: 'input[name="cc_name"]'
  }
});
