import Route from '@ember/routing/route';

export default Route.extend({
  layout: 'custom-aside',

  queryParams: {
    page: {
      refreshModel: true,
    },
  },

  model(params) {
    const kaseId = this.modelFor('authenticated.kases.manage').get('id');

    return this.store.query('kaseContact', {
      kase: kaseId,
      page: params.page,
      per_page: 100
    });
  },

  setupController(controller) {
    this._super(...arguments);
    controller.set('kase', this.modelFor('authenticated.kases.manage'));
  },

  actions: {
    willTransition(transition) {
      if (
        this.controller.get('isEditMode') &&
        !confirm('You have unsaved changes. Are you sure?')
      ) {
        transition.abort();
      } else {
        this.controller._teardownContactEdit(true);
        return true;
      }
    }
  }
});
