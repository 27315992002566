import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2PreneedCategoryModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('moment-datetime') archivedAt;

  @belongsTo('v2/generalPriceList') generalPriceList;

  @hasMany('v2/productCategory') productCategories;
}
