import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AdminObituaryGeneratorRoute extends Route {
  @service session;

  model() {
    const clientInfoId = this.session.get('session.authenticated.client.client_info_id')

    if (clientInfoId) {
      return this.store.findRecord('clientInfo', clientInfoId);
    }

    return this.store
      .createRecord('clientInfo')
      .save()
      .then((result) => {
        this.session.currentClient.client_info_id = this.result.id;

        return result;
      });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.setProperties({
      setText: model.obituaryTemplateFormatted
    });
  }
}
