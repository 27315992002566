import Controller from '@ember/controller';
import { action, set } from '@ember/object'
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
	'newStatementCategory.name': validator('presence', true)
});

export default class AdminGeneralPriceListsEditStatementCategoriesController extends Controller.extend(Validations) {
  @service flashes;
  @service intl;

  queryParams = ['page'];

  @tracked showValidations = false;
  @tracked newStatementCategory; // set up in the route
  @tracked generalPriceList; // set up in the route

  @action
  addStatementCategory() {
    this.validate().then(({ validations }) => {
      if (!validations.isValid) {
        set(this, 'showValidations', true);
        return;
      }

      set(this, 'showValidations', false);
      set(this.newStatementCategory, 'generalPriceList', this.generalPriceList);
      this.newStatementCategory.save().then(() => {
        this.flashes.addSuccess(this.intl.t('authenticated.admin.general-price-lists.edit.messages.save.success'));
        this.send('refreshModel');
      }).catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.general-price-lists.edit.messages.save.catch'));
      });
    });
  }
}
