import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  controllerName: 'authenticated.contacts.edit',
  templateName: 'authenticated.contacts.edit',

  session: service(),

  model(params) {
    return this.store.createRecord('v2/person', {
      firstName: params.firstName,
      lastName: params.lastName,
      country: this.session.currentClient.operating_country || 'US'
    });
  }
});
