import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';

export default class AdjustmentSummaryComponent extends Component {
  @service session;
  @service intl;
  @service flashes;
  @service api;

  @tracked showDialog = false;

  get idKey() {
    return this.args.adjustment.id;
  }

  get productName() {
    return this.args.adjustment.selectionProduct?.get('name');
  }

  get customProductName() {
    return this.args.adjustment.selectionCustomProduct?.get('name');
  }

  get selectionPackageProductName() {
    return this.args.adjustment.get('selectionPackageSlotProduct')?.get('product')?.get('name');
  }
  
  get adjustmentTypeName() {
    return this.args.adjustment?.get('adjustmentType')?.get('name');
  }

  get adjustmentName() {
    if (!isEmpty(this.productName)) {
      return this.productName;
    }
    
    if (!isEmpty(this.customProductName)) {
      return this.customProductName;
    }
    
    return this.selectionPackageProductName;
  }

  get adjustmentTitle() {
    return this.args.adjustment.lineItemAdjustment
      ? `Line Item - ${this.adjustmentName}`
      : this.adjustmentTypeName;
  }

  @action
  setDialog() {
    if (dateInCoaLockedPeriod(this.args.adjustment.selection, this.session, this.args.adjustment.collectedAt)) {
      this.flashes.addError(this.intl.t('components.list.adjustment-summary.errors.postingPeriod'));
      return;
    }

    set(this, 'showDialog', true);
  }

  @action
  removeAdjustment(adjustment) {
    this.args.onRemove(adjustment);
  }

  @action
  async resendToBeaconfp() {
    await this.api.json.post(`adjustments/${this.args.adjustment.id}/resend_to_beaconfp`, {});
  }
}
