import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['quick-add-task', 'well'],
  store: service(),
  taskList: null,

  init() {
    this._super(...arguments);

    this.set('taskList', this.get('store').createRecord('task-list'));
  },

  actions: {
    saveTaskList() {
      this.sendAction('onSave', this.get('taskList'));
    },

    cancelTaskList() {
      this.get('taskList').rollbackAttributes();
      this.sendAction('onCancel');
    }
  }
});
