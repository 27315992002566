import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import SelectBox from './select-box';
import states from 'crakn/utils/us/states';

export default SelectBox.extend({
  session: service(),

  value: null,
  layoutName: 'components/form/select-box',
  disabled: false,
  placeholder: 'State',
  options: states,

  didInsertElement() {
    this._super(...arguments);
    const value = this.value;
    const defaultState = this.get('session.currentUser.defaultState');

    if (isEmpty(value) && !isEmpty(defaultState)) {
      this.set('value', defaultState);
    }
  }
});
