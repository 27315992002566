import Controller from '@ember/controller';
import { computed, action } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class KasesIntegrationsTukiosController extends Controller {
  @service api;
  @service flashes;
  @tracked events = null;
  @tracked tukiosTheme = null;

  @computed('model.tukiosExport.updatedAt')
  get formattedTukiosExportedAt() {
    return this.model.tukiosExport.updatedAt.format('LLL');
  }

  @computed('model.kase.deceased.profileDeathDate')
  get invalidDeathDate() {
    return this.model.kase.deceased.get('profileDeathDate') > moment();
  }

  @action
  setTukiosTheme(theme) {
    this.tukiosTheme = theme;
  }

  @action
  tukios(e) {
    e.target.disabled = true; // avoid double-click issues
    const kase_id = this.model.kase.id;

    this.api.json
      .post('tukios/obituaries', {
        body: { id: kase_id, tukios_theme: this.tukiosTheme },
      })
      .then((response) => {
        if (response.ok) {
          this.store.pushPayload('kase', response.parsedJson);
          this.flashes.addSuccess('Case successfully exported!');
          this.send('refreshModel');
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.flashes.addError(
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false; // avoid double-click issues
        }
      });
  }
}
