import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SettingsRoute extends Route {
  @service session;
  @service jwt

  model() {
    return this.session.currentUser;
  }

  resetController(controller, isExiting) {
    super.resetController.apply(this, arguments);
    if (isExiting) {
      controller.model.rollbackAttributes();
    }
  }

  setupController(controller, _model, _transition) {
    super.setupController(...arguments);
    const isSso = this.jwt.isSso();
    controller.set('isSso', isSso);
  }

}
