import Model, { attr, hasMany } from '@ember-data/model';
import { equal } from '@ember/object/computed';

export default class V2ChartOfAccountsModel extends Model {
  @attr('string') accountingIntegrationServiceId;
  @attr('string') accountingIntegrationServiceName;
  @attr('string') name;
  @attr('string') operatingCountry;
  @attr('string') qbdFilePath;

  @attr('string', { defaultValue: 'deceased' }) customerExportNameSrc;
  @attr('string', { defaultValue: 'deceased' }) customerExportAddressSrc;
  @attr('boolean', { defaultValue: true }) collectTax;
  @attr('boolean') exportPayments;
  @attr('boolean', { defaultValue: false }) sfgsShowInterestsLineItem;
  @attr('boolean', { defaultValue: false }) soaUseExpandingInterests;
  @attr('boolean', { defaultValue: false }) trackLineItemClasses;
  @attr('boolean', { defaultValue: true }) showDeceasedName;
  @attr('boolean') validConfiguration;

  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') updatedAt;
  @attr('moment-datetime') latestPostingPeriodLockedAt;

  @equal('accountingIntegrationServiceName', 'quick_books') isQBD;
  @equal('accountingIntegrationServiceName', 'quick_books_online') isQBO;
  @equal('accountingIntegrationServiceName', 'quick_books_iif') isIIF;
  @equal('accountingIntegrationServiceName', 'sage50') isSage50;

  @hasMany('v2/generalPriceList', { async: true }) generalPriceLists;
  @hasMany('v2/specialTax', { async: true }) specialTaxes;
  @hasMany('v2/interestType', { async: true }) interestTypes;
}
