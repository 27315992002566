import Controller from '@ember/controller';
import { set, observer } from '@ember/object';
import moment from 'moment';

export default Controller.extend({
  queryParams: [
    'contact',
    'kase',
    'page',
    'sort_by',
    'archived',
    'order',
    'organization_location_id',
    'inquiry_staff_member'
  ],

  page: 1,
  kase: '',
  contact: '',
  sort_by: null,
  archived: false,
  order: 'ASC',
  addMode: false,
  organization_location_id: null,
  inquiry_staff_member: null,
  filterPerson: null,
  filterKase: null,

  _resetPage: observer('contact', 'kase', 'organization_location_id', 'archived', function() {
    set(this, 'page', 1);
  }),

  refreshModel() {
    this.send('refreshModel');
  },

  actions: {
    typeaheadSearch(value) {
      return this.store
                  .query('person', {
                    query: value
                  });
    },

    toggleArchived(inquiry) {
      if (inquiry.get('archivedAt')) {
        inquiry.set('archivedAt', null);
      } else {
        inquiry.set('archivedAt', moment());
      }

      inquiry.save().then(() => {
        this.refreshModel();
      });
    },

    sortBy(column) {
      // Normalize column name for comparison
      let descending = this.descending;
      column = column.decamelize();

      if (column !== this.sort_by) {
        descending = false;
      } else {
        descending = !descending;
      }

      set(this, 'sort_by', column);
      set(this, 'descending', descending);
    },

    selectContact(person) {
      if (person === undefined) {
        return;
      }

      set(this, 'contact', person ? person.get('id') : '');
      set(this, 'filterPerson', person);
    },

    selectKase(kase) {
      if (kase) {
        set(this, 'kase', kase.get('id'));
        set(this, 'filterKase', kase.get('deceased'));
      } else {
        set(this, 'kase', null);
        set(this, 'filterKase', null);
      }
    },

    updateStaffFilter(staffMemberId) {
      if (staffMemberId === undefined) {
        return;
      }

      set(this, 'inquiry_staff_member', staffMemberId);
    }
  }
});
