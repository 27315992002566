import Controller from '@ember/controller';
import { get, set, action } from '@ember/object';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  password: [
    validator('presence', true),
    validator('length', {
      min: 6,
      message: 'Your password must be at least 6 characters',
    }),
  ],
  passwordConfirmation: validator('confirmation', {
    on: 'password',
    message: 'Passwords do not match',
  }),
});

export default class SettingsPasswordController extends Controller.extend(
  Validations
) {
  password = null;
  passwordConfirmation = null;
  showValidation = false;

  @action
  savePassword(event) {
    event.preventDefault();
    if (event.submitter) {
      event.submitter.disabled = true;
    }
    this.validate().then(({ validations }) => {
      if (get(validations, 'isValid')) {
        set(this, 'showValidation', false);
        this.model
          .updatePassword(this.password)
          .then(() => {
            this.send(
              'flashSuccess',
              'You successfully updated your password!'
            );
            this.transitionToRoute('authenticated.settings.info');
          })
          .catch(() => {
            this.send('flashError', 'There was an error saving your password.');
          });
      } else {
        set(this, 'showValidation', true);
      }
    });
    if (event.submitter) {
      event.submitter.disabled = false;
    }
    return false;
  }
}
