import { set, get } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default Route.extend({
  api: service(),
  layout: 'custom-aside',

  model() {
    const model = this.modelFor('authenticated.kases.manage.integrations');

    return RSVP.hash({
      model,
      kase: model,
      messengerExport: this.store.queryRecord('integrationExport', {
        kase_id: model.id,
        type: 'messenger',
        most_recent_export: true,
        status: 'successful'
      })
    });
  },

  setupController(controller, model, transition) {
    this._super(controller, model, transition);
    set(
      controller,
      'events',
      this.store.query('event', { kase_id: get(model, 'kase.id') })
    );
  },

  actions: {
    messenger(e) {
      e.target.disabled = true; // avoid double-click issues
      const kase_id = get(this, 'controller.model.kase.id');

      this.api.json.put(
        'messenger/kases',
        { body: { id: kase_id } }
      ).then(response => {
        if (response.ok) {
          this.store.pushPayload('kase', response.parsedJson);
          // set(this, 'events', get(this, 'store').query('event', { kase_id: get(this, 'model.id') }));
          this.refresh();
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false; // avoid double-click issues
        }
      });
    },

    avatar(e) {
      e.target.disabled = true; // avoid double-click issues
      const kase_id = this.currentModel.kase.id;

      this.api.json.put(
        'messenger/avatar',
        { body: { id: kase_id } }
      ).then(response => {
        if (response.ok) {
          this.store.pushPayload('kase.avatar', response.parsedJson);
          this.send('flashSuccess', 'Profile Picture successfully exported!'); // Not the best validation, but will fix once rebase with integration_export updates
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false; // avoid double-click issues
        }
      });
    }
  }
});
