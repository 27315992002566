import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import SelectBox from './select-box';
import regions from 'crakn/utils/uk-regions';

export default SelectBox.extend({
  session: service(),

  value: null,
  layoutName: 'components/form/select-box',
  disabled: false,
  placeholder: 'Region',
  options: regions,

  didInsertElement() {
    this._super(...arguments);
    const value = this.value;
    // TODO: API changes to make the word 'state' more generic will need to be done later.
    const defaultRegion = this.get('session.currentUser.defaultState');

    if (isEmpty(value) && !isEmpty(defaultRegion)) {
      this.set('value', defaultRegion);
    }
  }
});
