import { alias, readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

const Validations = buildValidations({
  deceasedFirstName: validator('presence', true),
  deceasedLastName: validator('presence', true)
});

export default Component.extend(Validations, {
  store: service(),
  names: service(),
  session: service(),

  kase: null,
  newKaseStatus: 'pending',

  showValidation: false,

  deceased: readOnly('kase.deceased'),

  deceasedFirstName: alias('kase.deceased.firstName'),
  deceasedLastName: alias('kase.deceased.lastName'),

  actions: {
    setDate(attr, value) {
      set(this, `kase.deceased.${attr}`, moment(value));
    },

    createNewKase(value) {
      const splitNames = this.names.splitNameIntoFirstAndLastName(value);
      const deceased = this.store.createRecord('deceased', splitNames);
      const kase = this.store.createRecord('kase', { deceased });

      // Clear existing kase to show new deceased form
      this.attrs.onSelectKase(kase);
    },

    cancelNewKase() {
      get(this, 'kase.deceased.content').rollbackAttributes();
      get(this, 'kase.content').rollbackAttributes();

      this.attrs.onSelectKase(null);
    },

    saveNewKase() {
      const kase = this.kase;

      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          set(kase, 'status', this.newKaseStatus);
          this.attrs.saveKase(kase);
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    selectKase(person) {
      if (person === undefined) {
        return this.attrs.onSelectKase(null);
      }

      const kaseId = person ? get(person, 'kaseId') : null;

      if (kaseId) {
        this.store.findRecord('kase', kaseId).then(kase => {
          this.attrs.onSelectKase(kase);
        });
      } else {
        this.store.findRecord('deceased', get(person, 'id')).then(deceased => {
          const kase = this.store.createRecord('kase', { deceased });

          // Clear existing kase to show new deceased form
          this.attrs.onSelectKase(kase);
        });
      }

      return person.typeaheadName;
    }
  }
});
