import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service'
import { preventFormDefault } from 'ember-cli-prevent-form-default';
import { tracked } from '@glimmer/tracking';
import PersonSmartEntry from 'crakn/mixins/person-smart-entry';

export default class KasesEditPersonalController extends Controller.extend(PersonSmartEntry) {
  @service flashes;
  @service router;

  @tracked showValidation = false;

  kase = null;

  smartLocationSources = {
    'Last Permanent Address': 'model',
    'Responsible Party Address': 'kase.purchaser',
    'Informant Address': 'kase.informant'
  };

  smartEntryPersonMeta = {
    mother: {
      key: 'kase.mother',
      label: 'Mother',
      srcOptions: ['informant', 'purchaser']
    },
    father: {
      key: 'kase.father',
      label: 'Father',
      srcOptions: ['informant', 'purchaser']
    },
    spouse: {
      key: 'kase.spouse',
      label: 'Spouse',
      srcOptions: ['informant', 'purchaser']
    },
    informant: {
      key: 'kase.informant',
      label: 'Informant',
      srcOptions: ['mother', 'father', 'spouse', 'purchaser']
    },
    purchaser: {
      key: 'kase.purchaser',
      label: 'Responsible Party'
    }
  };

  handleSaveSuccess() {
    this.flashes.addSuccess('Great job! You updated the personal information');
    this.router.transitionTo('authenticated.kases.manage.edit.disposition');
  }

  handleSaveError() {
    this.flashes.addError('Oops! There was an error saving the personal information.');
  }

  // This method is duplicated on `controllers/kases/manage/edit/deceased`
  copyAddress(srcLocationType, destLocationType) {
    const sources = this.smartLocationSources;
    const destName = sources[destLocationType];
    const srcName = sources[srcLocationType];

    const fields = [
      { name: 'addressLine1', podName: 'placeOfDeathLine1' },
      { name: 'addressLine2', podName: 'placeOfDeathLine2' },
      { name: 'city', podName: 'placeOfDeathCity' },
      { name: 'county', podName: 'placeOfDeathCounty' },
      { name: 'state', podName: 'placeOfDeathState' },
      { name: 'zipCode', podName: 'placeOfDeathZipCode' },
      { name: 'country', podName: 'placeOfDeathCountry' }
    ];

    const keyFor = n => (n === 'Place of Death' ? 'podName' : 'name');
    const destKey = keyFor(destLocationType);
    const srcKey = keyFor(srcLocationType);

    let destStr, srcStr;

    fields.forEach(field => {
      destStr = `${destName}.${field[destKey]}`;
      srcStr = `${srcName}.${field[srcKey]}`;
      this.set(destStr, this.get(srcStr));
    });
  }

  @action
  @preventFormDefault
  savePersonal() {
    const deceased = this.model;

    deceased.validate().then(({ validations }) => {
      if (validations.isValid) {
        this.showValidation = false;
        deceased.save().then(() => {
          this.kase.save()
          .then(() => this.handleSaveSuccess())
          .catch(() => this.handleSaveError());
        });
      } else {
        this.showValidation = true;
      }
    });
  }

  // This method is duplicated on `controllers/kases/manage/edit/deceased.js
  @action
  requestCopyAddress(srcLocationType, destLocationType) {
    this.copyAddress(srcLocationType, destLocationType);
  }

  @action
  saveContact(contactType) {
    this.kase
      .get(contactType)
      .then(contact => {
        return new Promise(function (resolve) {
          resolve(contact);
        });
      })
      .then(contact => {
        contact.save().then(() => {
          this.kase.save();
        });
      });
  }

  @action
  removeContact(role, personId) {
    const kasePerson = this.kase.get('kasePeople').find(function (kasePerson) {
      return (
        kasePerson.get('person.id') === personId &&
        kasePerson.role === role.replace(/-/, '_')
      );
    });
    if (kasePerson) {
      kasePerson.destroyRecord();
    }
  }
}
