import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedCalendarIndexRoute extends Route {
  @tracked queryParams = {
    organization_location_id: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    full_only: {
      refreshModel: true
    },
    user_id: {
      refreshModel: true
    },
    filter_kase_status: {
      refreshModel: true
    },
    start_date: {
      refreshModel: true
    },
    end_date: {
      refreshModel: true
    },
  };

  model(params) {
    return this.store.query('event', params);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        organization_location_id: null
      });
    }
  }
}
