import Model, { belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { bool, not } from '@ember/object/computed';
import moment from 'moment';

const Validations = buildValidations({
  amount: validator('presence', true),
  'adjustmentType.id': validator('presence', {
    presence: true,
    disabled: not('model.lineItemAdjustment'),
  }),
  gain: validator('presence', {
    presence: true,
    disabled: bool('model.lineItemAdjustment'),
  }),
  createdAt: validator('presence', true),
  adjustmentLineItemId: validator('presence', {
    presence: true,
    disabled: not('model.lineItemAdjustment'),
  }),
});

export default class V2AdjustmentModel extends Model.extend(Validations) {
  @attr('price') amount;
  @attr('string') lineItemName;
  @attr('string') kaseName;

  @attr('boolean', { defaultValue: false }) lineItemAdjustment;

  @attr('moment-datetime', { defaultValue: () => moment() }) collectedAt;
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;

  @attr('number', { defaultValue: null }) adjustmentLineItemId;

  @belongsTo('v2/adjustmentType', { async: true }) adjustmentType;
  @belongsTo('v2/user', { async: true }) adjustedBy;
  @belongsTo('v2/selection', { polymorphic: true }) selection;
  @belongsTo('v2/selectionCustomProduct', { async: true }) selectionCustomProduct;
  @belongsTo('v2/selectionPackageProduct', { async: true }) selectionPackageProduct;
  @belongsTo('v2/selectionProduct', { async: true }) selectionProduct;
}
