import Controller from '@ember/controller';
import { imageExtensions } from 'crakn/utils/data-helpers';
import { set, get, action } from '@ember/object';

export default class SettingsInfoController extends Controller {
  get imageExtensions() {
    return imageExtensions;
  }

  handlePhoneNumberError() {
    this.send('flashError', 'Please enter a valid ten digit phone number.');
  }

  handleEmailError() {
    this.send('flashError', 'Please enter a valid email address.');
  }

  @action
  saveUser(event) {
    const user = this.model;

    event.preventDefault();
    if (event.submitter) {
      event.submitter.disabled = true;
    }

    user.validate().then(({ validations }) => {
      if (get(validations, 'isValid')) {
        set(this, 'showValidation', false);
        user
          .save()
          .then(() => {
            this.send(
              'flashSuccess',
              'You successfully updated your settings!'
            );
          })
          .catch(() => {
            this.send(
              'flashError',
              'There was an error saving your preferences.'
            );
          });
      } else {
        set(this, 'showValidation', true);
      }
    });
    if (event.submitter) {
      event.submitter.disabled = false;
    }
    return false;
  }

  @action
  addImage(response) {
    set(this, 'model.imageUrl', response.url);
  }

  @action
  removeImage() {
    set(this, 'model.imageUrl', null);
  }
}
