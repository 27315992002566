import Model, { belongsTo, attr } from '@ember-data/model';

export default class EsignatureModel extends Model {
  @attr('string') status;
  @attr('array') signatureFieldCoords; /** [{ fieldX: , fieldY: }, ...] */
  @attr('array') signatureFieldNames;
  @attr('string') signature;
  @attr('string') previewUrl;
  @attr('string') token;
  @attr('string') locationName; /** For branding on signing/signed views */
  @attr('string') documentHtml; /** For contacts only */
  @attr('moment-datetime') createdAt;

  @belongsTo('person') signer;
  @belongsTo('user') requester;
  @belongsTo('esignedDocument', { polymorphic: true }) esignedDocument;

  get signatureFieldNamesStr() {
    return this.signatureFieldNames.join(', ');
  }
}
