import Model, { belongsTo, attr } from '@ember-data/model';
import { fullName } from 'crakn/utils/people-helpers';

export default class KaseTaskReportModel extends Model {
  @attr('string') openTasks;
  @attr('string') closedTasks;
  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') namePrefix;
  @attr('string') middleName;
  @attr('string') nameSuffix;
  @attr('string') nickname;
  @attr('string') maidenName;
  @attr('string') customId;

  @belongsTo('kase', { async: true }) kase;

  get name() {
    return fullName(this);
  }
}
