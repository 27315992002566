import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Route.extend({
  flashes: service(),
  singleClickEvent: false,

  model() {
    return RSVP.hash({
      selection: this.store.createRecord('selection', {
        type: 'MiscSelection'
      })
    });
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.setProperties(model);
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('showValidation', false);
    }
  },

  actions: {
    saveContact(contactType) {
      const selection = this.currentModel.selection;
      selection
        .get(`${contactType}.content`)
        .save()
        .then(() => {
          this.send('flashSuccess', 'The purchaser has been saved!');
        });
    },

    createSale() {
      const controller = this.controller;
      const selection = this.currentModel.selection;
      set(controller, 'singleClickEvent', true); // new double click fix
      selection
        .save()
        .then(selection => {
          set(this, 'currentModel.selection', selection);
          set(selection, 'savedChanges', true);
          set(controller, 'singleClickEvent', false); // new double click fix

          this.send('flashSuccess', 'Misc Sale has successfully been created!');
          this.transitionTo('authenticated.misc-sales.edit.details', selection.id);
        })
        .catch(() => {
          set(controller, 'singleClickEvent', false); // new double click fix
          this.send(
            'flashError',
            'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
          );
        });
    }
  }
});
