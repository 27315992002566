import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class DebugRoute extends Route {
  @service api;

  layout = 'fullscreen';
  isFullscreenActive = true;

  model() {
    return this.api.json
        .get('debug')
        .then(response => {
          if (!response.ok) {
            this.send('flashError', 'Error getting debug info');
          } else {
            return response.parsedJson;
          }
        });
  }
}
