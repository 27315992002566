import { next } from '@ember/runloop';
import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * ## Arguments
 * - **path** - Route path.
 * - **label** - Main text for the nav item.
 * - **icon** - Name of the icon to display.
 * - **model** - *optional* Model of the route the nav item is routing to.
 * - **selector** - *optional* Class or ID CSS selector to link to.
 * - **modifierClass** - *optional* Classes to add to the nav item.
 * - **iconType** - *optional* Class for icon set selection.
 */
export default class CardNavItem extends Component {
  get iconType() {
    return this.args.iconType ? this.args.iconType : 'md-icon';
  }

  @action
  selectorClicked() {
    // Run next to allow for link handler to transition route if needed
    next(() => {
      document.querySelector(this.args.selector).scrollIntoView();
      window.scrollBy(0, -72);
    });
  }
}
