import Component from '@ember/component';

export default Component.extend({
  kase: null,

  actions: {
    deleteDraft() {
      this.sendAction('revertDraft');
    }
  }
});
