import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { not } from '@ember/object/computed';
import Component from '@ember/component';
import moment from 'moment';

export default Component.extend({
  store: service(),
  model: null,
  isLoaded: false,
  notLoaded: not('isLoaded'),
  isLoading: false,
  isOpen: false,
  notOpen: not('isOpen'),
  user: null,

  get formattedUpdatedAtTime() {
    const time = this.model.updatedAt;

    if (time !== null) {
      return moment(time).format('MMMM Do YYYY, h:mm a');
    } else {
      return null;
    }
  },


  loadImport() {
    set(this, 'isLoading', true);
    const model = get(this, 'model');
    const store = get(this, 'store');

    store
      .findRecord('v2/family-portal-invite', get(model, 'id'), {
        include: 'recipient,integration-import',
        reload: true
      })
      .then(() => {
        set(this, 'isLoading', false);
        set(this, 'isLoaded', true);
      });
  },

  actions: {
    refresh() {
      this.loadImport();
    },

    toggleDrawer() {
      set(this, 'isOpen', !get(this, 'isOpen'));
      if (!get(this, 'isLoaded') || get(this, 'isLoading')) {
        this.loadImport();
      }
    },

    remove(model) {
      this.sendAction('remove', model);
    },

    copyKeyValuesToKase(hashes) {
      this.sendAction(
        'copyKeyValuesToKase',
        hashes,
        get(this, 'model.integrationImport.rawImport')
      );
    }
  }
});
