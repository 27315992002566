import Component from '@ember/component';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  didReceiveAttrs() {
    this._super(...arguments);
    this.store
      .query('package', {
        general_price_list_id: this.generalPriceListId,
        archived: false,
        per_page: 500
      })
      .then(packages => {
        set(this, 'availablePackages', packages);
      });
  },

  store: service(),

  generalPriceListId: null,

  availablePackages: null
});
