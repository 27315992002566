import Model, { belongsTo, hasMany, attr } from '@ember-data/model';

export default class ProductCategoryModel extends Model {
  @belongsTo('client') client;

  @attr('string') name;
  @attr('number') customOrder;
  @attr('string') description;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') archivedAt;

  @belongsTo('generalPriceList') generalPriceList;
  @belongsTo('account') account;
  @belongsTo('account') cogsAccount;
  @belongsTo('account') assetAccount;
  @belongsTo('v2/preneedCategory') preneedCategory;

  @hasMany('product', { async: true }) products;
}
