import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { v4 as uuidv4 } from 'uuid';

export default class AdminGeneralPriceListsEditPricingImportsRoute extends SearchRoute {
  @service api;
  @tracked export_uuid;
  @service store;
  @service router;
  @service productCategories;
  modelType = 'v2/integrationImport';
  routeRegex = /authenticated.admin.general-price-listts.edit.pricing-imports/;

  cont;

  queryParams = {
    query: {
      replace: true,
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    }
  };

  model(params) {
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');
    return this.store.query(this.modelType, {
      query: params.query || this.defaultQuery,
      page: params.page || this.defaultPage,
      perPage: params.perPage || this.defaultPerPage,
      archived: params.archived || this.defaultArchived,
      gpl_id: gpl.id,
      order: 'DESC',
      type: 'gpl_import'
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    this.cont = controller;
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');
    controller.set('gpl', gpl);
    this.export_uuid = uuidv4();
    this.cont.set('export_uuid', this.export_uuid)
  }

  @action
  refreshModel() {
    this.refresh();
  }

  @action
  generateReport(reportLink) {
    this.api.json
      .get(reportLink)
      .then(response => {
        if (!response.ok) {
          this.send('flashError', 'Oops, something went wrong! Please try again.');
        }
        this.export_uuid = uuidv4();
        this.cont.set('export_uuid', this.export_uuid);
      });
  }
}
