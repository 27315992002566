import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  api: service(),
  layout: 'fullscreen',
  isFullscreenActive: true,

  resetController(controller, isExiting) {
    this._super.apply(this, arguments);
    if (isExiting) {
      controller.setProperties({
        password: null,
        error: null
      });
    }
  },

  setupController(controller) {
    this._super.apply(this, arguments);

    this.api.json.get(
      'password_resets',
      { params: { token: controller.token } }
    ).then(() => {
      controller.set('validToken', true);
    })
  }
});
