import Controller from '@ember/controller';
import { computed, get, set } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default Controller.extend({
  api: service(),

  formattedMerrickExportedAt: computed('merrickExport.updatedAt', function() {
    return moment(get(this, 'merrickExport.updatedAt')).format('LLL');
  }),

  instructionsOptions: [
    'Use picture as is',
    'Use KM portrait style background',
    'This is a reprint portrait order'
  ],

  frameOptions: [
    'None',
    'Black',
    'Mahogany',
    'Honey (Gold)',
    'Vintage (Silver)'
  ],

  registerBookOptions: [
    'None',
    'Standard Lined, Pre-printed Pages',
    'Laser Pages with Headings Only'
  ],

  relativeFriendsBookOptions: [
    'None',
    'Standard Lines Only',
    'Name and Address Lines'
  ],

  shippingMethodOptions: [
    'Ground',
    '2nd Day',
    'Next Day',
    'Next Day Early Delivery',
    'Saturday Delivery'
  ],

  productList: [
    { code: 'MCP1', description: '16x20 Canvas Print ($79)' },
    { code: 'MCP2', description: '11x14 Canvas Print ($76)' },
    { code: 'MCP3', description: '8x10 Canvas Print ($72)' },
    {
      code: 'MCP1 COMBO',
      description: '16x20 Canvas Print and Matching Canvas Register Book ($139)'
    },
    {
      code: 'MCP2 COMBO',
      description: '11x14 Canvas Print and Matching Canvas Register Book ($136)'
    },
    {
      code: 'MCP3 COMBO',
      description: '8x10 Canvas Print and Matching Canvas Register Book ($131)'
    },
    {
      code: 'MCP99',
      description: 'Personalized Canvas Register Book Only ($63)'
    },
    {
      code: 'MCP99GB',
      description:
        'Gift Box (Canvas Register Book, 50 Ack. Cards, 100 Folders) ($109)'
    },
    {
      code: 'MCP1GB',
      description: '16x20 Canvas Print and Canvas Register Book Gift Box ($182)'
    },
    {
      code: 'MCP2GB',
      description: '11x14 Canvas Print and Canvas Register Gift Box ($179)'
    },
    {
      code: 'MCP3GB',
      description: '8x10 Canvas Print and Canvas Register Book Gift Box ($174)'
    }
  ],

  actions: {
    submitRequest(e) {
      e.target.disabled = true; // avoid double-click issues

      this.api.json
          .post(
            'merrick/requests',
            {
              body: {
                kaseId: get(this, 'kase.id'),
                merrickParams: { ...this.merrickParams }
              }
            }
          )
          .then(response => {
            if (response.ok) {
              this.send('refresh');
            } else {
              this.send(
                'flashError',
                'Oops, something went wrong! Please try again.'
              );
            }
            e.target.disabled = false;
          });
    },

    updateRealNeededBy() {
      set(this, 'merrickParams.neededBy', this.neededBy.toString());
    }
  }
});
