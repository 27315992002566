import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n    class=\"popper-popup {{@class}}\"\n    id={{@popupID}}\n    style={{this.minWidth}}\n    {{did-insert @setup}}>\n  {{! template-lint-disable attribute-indentation }}\n  {{yield (\n    hash\n    Item=(component \"g/crakn/menu/popup-item\"\n            class=@class\n            closeMenu=@closeMenu\n            disabled=@disabled\n            hide=@hide\n            href=@href\n            icon=@icon\n            iconClass=@iconClass\n            iconPosition=@iconPosition\n            onClick=@onClick\n            shouldCloseMenu=@shouldCloseMenu\n            target=@target\n          )\n    Divider=(component \"g/crakn/menu/popup-item-divider\")\n  )}}\n</div>\n", {"contents":"<div\n    class=\"popper-popup {{@class}}\"\n    id={{@popupID}}\n    style={{this.minWidth}}\n    {{did-insert @setup}}>\n  {{! template-lint-disable attribute-indentation }}\n  {{yield (\n    hash\n    Item=(component \"g/crakn/menu/popup-item\"\n            class=@class\n            closeMenu=@closeMenu\n            disabled=@disabled\n            hide=@hide\n            href=@href\n            icon=@icon\n            iconClass=@iconClass\n            iconPosition=@iconPosition\n            onClick=@onClick\n            shouldCloseMenu=@shouldCloseMenu\n            target=@target\n          )\n    Divider=(component \"g/crakn/menu/popup-item-divider\")\n  )}}\n</div>\n","moduleName":"crakn/components/g/crakn/menu/popup.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/menu/popup.hbs"}});
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

export default class GCraknMenuPopup extends Component {
  get minWidth() {
    return this.args.minWidth ? htmlSafe(`min-width: ${this.args.minWidth};`) : null;
  }
}
