import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AdminUsersEditFeatureConfigController extends Controller {
  @service api;
  @service intl;
  @service userConfig;
  @service flashes;
  @service session;

  @tracked searchValue;

  handleSaveSuccess() {
    this.flashes.addSuccess(
      this.intl.t(
        'authenticated.admin.users.edit.feature-configuration.actions.savingUser.successMessage'
      )
    );
  }

  handleSaveError() {
    this.flashes.addError(
      this.intl.t(
        'authenticated.admin.users.edit.feature-configuration.actions.savingUser.errorMessage'
      )
    );
  }

  @action
  save() {
    if (this.user.hasDirtyAttributes) {
      this.user
        .save()
        .then(() => this.handleSaveSuccess())
        .catch(() => this.handleSaveError());
    }
  }

  @action
  search(value) {
    this.userConfig.searchFeatures(value);
  }

  @action
  filter(category) {
    this.userConfig.filterFeatures(category);
  }
}
