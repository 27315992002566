import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { preventFormDefault } from 'ember-cli-prevent-form-default';
import { tracked } from '@glimmer/tracking';
import { usMilitaryHeadstoneEmblems } from 'crakn/utils/us/military-headstone-emblems';
import { militaryBranches } from 'crakn/utils/us/military-branches';
import { wars } from 'crakn/utils/us/wars';
import Controller from '@ember/controller';
import moment from 'moment';

export default class VeteranInfoController extends Controller {
  @service clientRouting;
  @service flashes;
  @service intl;
  @service router;
  @service session;

  @tracked disposition;
  @tracked militaryBranch;
  @tracked openUsServiceRecords = [];

  get showVeteranInfo() {
    return this.session.currentClient.can_view_veteran_info_section &&
      this.v2Kase.isVeteran
      ? true
      : false;
  }

  get militaryBranches() {
    return militaryBranches;
  }

  get usMilitaryHeadstoneEmblems() {
    return usMilitaryHeadstoneEmblems;
  }

  get warOptions() {
    return wars;
  }

  headstoneMaterialTypes = [
    'Bronze Niche',
    'Flat Bronze',
    'Flat Granite',
    'Small Flat Granite',
    'Upright Granite',
    'Upright Marble'
  ];

  nextRoute = this.clientRouting.manageRouteTransition(
    'authenticated.kases.manage.edit.veteran-info'
  );

  handleSaveSuccess() {
    this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.veteran-info.alertMessages.updateCaseSuccess'));
    this.router.transitionTo(this.nextRoute);
  }

  @action
  setWarsFoughtIn(usServiceRecord, war) {
    usServiceRecord.set('warsFought', war);
  }

  @action
  setMilitaryBranch(usServiceRecord, branch) {
    if (usServiceRecord.militaryBranch != branch) {
      usServiceRecord.militaryRank = null;
    }

    usServiceRecord.set('militaryBranch', branch);
  }

  @action
  setMilitaryRank(usServiceRecord, rank) {
    usServiceRecord.set('militaryRank', rank);
  }

  @action
  setHonorsPlace(v2Place) {
    this.model.set('honorsPlace', v2Place);
  }

  @action
  removeHonorsPlace() {
    this.model.set('honorsPlace', null);
  }

  @action
  setHeadstoneOrderedDate(attr, value) {
    this.model.set(attr, moment(value));
  }

  @action
  addUsServiceRecord() {
    const usServiceRecord = this.store.createRecord('v2/us-service-record', {
      veteranInfo: this.model
    });

    this.setOpenUsServiceRecord(usServiceRecord);
  }

  @action
  saveUsServiceRecord(usServiceRecord) {
    usServiceRecord
      .save()
      .then(() => {
        this.removeOpenUsServiceRecord(usServiceRecord);
        this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.veteran-info.alertMessages.saveRecordSuccess'));
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.veteran-info.alertMessages.saveError'));
      });
  }

  @action
  setOpenUsServiceRecord(usServiceRecord) {
    const newOpenItems = this.openUsServiceRecords.concat(usServiceRecord);
    this.openUsServiceRecords = newOpenItems;
  }

  @action
  removeOpenUsServiceRecord(usServiceRecord) {
    this.openUsServiceRecords = this.openUsServiceRecords.filter(
      (usServiceRecords) => {
        return usServiceRecords != usServiceRecord;
      }
    );
  }

  @action
  rollbackUsServiceRecord(usServiceRecord) {
    if (usServiceRecord.isNew) {
      this.removeOpenUsServiceRecord(usServiceRecord);
      usServiceRecord.rollbackAttributes();
    } else {
      this.removeOpenUsServiceRecord(usServiceRecord);
      usServiceRecord.rollbackAttributes();
      usServiceRecord.reload();
    }
  }

  @action
  deleteUsServiceRecord(usServiceRecord) {
    if (usServiceRecord.isNew) {
      this.removeOpenUsServiceRecord(usServiceRecord);
      usServiceRecord.rollbackAttributes();
    } else {
      usServiceRecord
        .destroyRecord()
        .then(() => {
          this.removeOpenUsServiceRecord(usServiceRecord);
          this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.veteran-info.alertMessages.removeRecordSuccess'));
        })
        .catch(() => {
          this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.veteran-info.alertMessages.saveError'));
        });
    }
  }

  // This is here for the isVeteran checkbox
  saveIsVeteran() {
    if (this.v2Kase.hasDirtyAttributes) {
      this.v2Kase.save();
    }
  }

  // This is here for the isHeadstoneDesired checkbox
  saveIsHeadstoneDesired() {
    if (this.disposition.hasDirtyAttributes) {
      this.disposition.save();
    }
  }

  @action
  save(successMessage) {
    const veteranInfo = this.model;

    this.saveIsHeadstoneDesired();
    this.saveIsVeteran();

    veteranInfo.save().then(() => {
      if (successMessage) {
        this.handleSaveSuccess();
      }
    });
  }

  @action
  @preventFormDefault
  saveVeteran() {
    this.save(true);
  }
}
