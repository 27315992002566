import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { placeTypes } from 'crakn/utils/place-types';

export default class AuthenticatedPlacesEditController extends Controller {
  @service flashes;
  @service session;
  @service router;
  @service location;

  @tracked showValidation = false;
  @tracked roomName = null;  
  @tracked canViewHomeUnderHospice = false;

  get placeTypeOptions() {
    if (this.canViewHomeUnderHospice) {
      return placeTypes;
    } else {
      return placeTypes.filter(pt => pt.value !== 'home_under_hospice');
    }
  }

  queryParams = ['redirectPath', 'redirectId'];

  handleSaveSuccess(place) {
    this.flashes.addSuccess('Great work! You saved a place.');

    // TODO: Remove findRecord once V1 is replaced
    if (this.redirectPath && this.redirectId) {
      this.store.findRecord('place', place.id).then(() => {
				this.router.transitionTo(this.redirectPath, this.redirectId);
			});
    } else {
      this.router.transitionTo('authenticated.places');
    }
  }

  @action
  save() {
    const place = this.model;

    place.validate().then(({ validations }) => {
      if (validations.isValid) {
        this.showValidation = false;
        place
          .save()
          .then(() => {
            this.handleSaveSuccess(place);
          })
          .catch(() => {
            this.flashes.addError(
              'Oops! There was an error saving this place.'
            );
          });
      } else {
        this.showValidation = true;
      }
    });
  }

  @action
  addRoom() {
    const newRoom = this.store.createRecord('v2/room', {
      name: this.roomName,
      place: this.model
    });
    newRoom
      .save()
      .then(() => {
        this.flashes.addSuccess('Great work! You added a room.');
        this.roomName = null;
      })
      .catch(() => {
        this.flashes.addError('Oops! There was an error adding this room.');
      });
  }

  @action
  removeRoom(room) {
    room
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess('This room has now been removed.');
        this.send('refreshModel');
      })
      .catch(() => {
        this.flashes.addError('Oops! There was an error removing this room.');
      });
  }

  @action
  setAddress(address) {
    this.model.address.setProperties(address);
  }
}
