import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"tile\">\n  <h3 class=\"tile__header tile__header--chain-of-custody\">\n    Chain of Custody\n\n    <div class=\"d--xs--line pull--r\">\n      <LinkTo\n          @route=\"authenticated.chain-of-custody-details\"\n          class=\"btn btn--chain-of-custody\">\n        More Details\n      </LinkTo>\n    </div>\n  </h3>\n\n  {{#each @chainOfCustodyKases as |kase|}}\n    <G::ChainOfCustody::Widget::Item @kase={{kase}}/>\n  {{/each}}\n\n  {{#if (gt @chainOfCustodyKases.meta.total @chainOfCustodyKases.length)}}\n    <div class=\"tile__footer\">\n      <button\n          class=\"btn btn--link btn--block\"\n          type=\"button\"\n          {{on \"click\" this.loadMore}}>\n        Load More\n      </button>\n    </div>\n  {{/if}}\n</div>\n", {"contents":"<div class=\"tile\">\n  <h3 class=\"tile__header tile__header--chain-of-custody\">\n    Chain of Custody\n\n    <div class=\"d--xs--line pull--r\">\n      <LinkTo\n          @route=\"authenticated.chain-of-custody-details\"\n          class=\"btn btn--chain-of-custody\">\n        More Details\n      </LinkTo>\n    </div>\n  </h3>\n\n  {{#each @chainOfCustodyKases as |kase|}}\n    <G::ChainOfCustody::Widget::Item @kase={{kase}}/>\n  {{/each}}\n\n  {{#if (gt @chainOfCustodyKases.meta.total @chainOfCustodyKases.length)}}\n    <div class=\"tile__footer\">\n      <button\n          class=\"btn btn--link btn--block\"\n          type=\"button\"\n          {{on \"click\" this.loadMore}}>\n        Load More\n      </button>\n    </div>\n  {{/if}}\n</div>\n","moduleName":"crakn/components/g/chain-of-custody/widget.hbs","parseOptions":{"srcName":"crakn/components/g/chain-of-custody/widget.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ChainOfCustodyWidgetComponent extends Component {
  @service session;
  @service store;

  page = 1;
  perPage = 5;

  get auditsIndexBasedLength() {
    return this.args.chainOfCustodyKases.length - 1;
  }

  @action
  loadMore() {
    this.page++;

    this.store.query('v2/kase', {
      hasChainOfCustody: true,
      status: ['in_progress', 'preneed', 'pending'],
      include: 'custody-audits.end-place.address,custody-audits.end-room,deceased',
      perPage: this.perPage,
      page: this.page
    }).then(results => {
      this.args.chainOfCustodyKases.content.pushObjects(results.content);
    })
  }
}