import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button\n    ...attributes\n    type=\"button\"\n    {{on \"click\" this.openFilepicker}}\n    {{did-insert this.setup}}>\n  {{yield}}\n</button>\n<G::Crakn::FileUpload::FileInput\n    @acceptedFileTypes={{@acceptedFileTypes}}\n    @id={{this.buttonId}}\n    @queue={{@queue}}\n    @singleFile={{this.singleFile}}/>\n", {"contents":"<button\n    ...attributes\n    type=\"button\"\n    {{on \"click\" this.openFilepicker}}\n    {{did-insert this.setup}}>\n  {{yield}}\n</button>\n<G::Crakn::FileUpload::FileInput\n    @acceptedFileTypes={{@acceptedFileTypes}}\n    @id={{this.buttonId}}\n    @queue={{@queue}}\n    @singleFile={{this.singleFile}}/>\n","moduleName":"crakn/components/g/crakn/file-upload/button.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/file-upload/button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';

export default class GCraknFileUploadButton extends Component {
  input = null;

  get buttonId() {
    return this.args.id || 'file-input-button-' + guidFor(this);
  }

  get singleFile() {
    return this.args.singleFile ? true : false;
  }

  @action
  setup() {
    this.input = document.getElementById(this.buttonId);
  }

  @action
  openFilepicker() {
    this.input.click();
  }
}
