import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class BestWishesSetupController extends Controller {
  integrationName = 'bestWishes';
  @tracked addMode = false;

  get canAdd() {
    if (this.model.logos.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  @action
  refreshRoute() {
    this.send('refreshModel');
    this.addMode = false;
  }

  @action
  toggleAddMode() {
    this.toggleProperty('addMode');
  }
}
