import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { set, get, computed } from '@ember/object';

export default Component.extend({
  classNames: ['list__item', 'list__item--wrap'],
  classNameBindings: ['qaClassName'],
  tagName: 'li',

  showDialog: false,

  browser: service(),

  isEditing: false,

  qaClassName: computed('document.id', {
    get() {
      return `qa-document-${get(this, 'document.id')}`;
    }
  }),

  actions: {
    toggleEdit() {
      if (!this.toggleProperty('isEditing')) {
        this.document.save();
      }
    },

    cancelChanges() {
      set(this, 'isEditing', false);
      this.document.rollbackAttributes();
    },

    deleteDocument() {
      this.document.destroyRecord();
    },

    saveDocument() {
      this.document.save();
    }
  }
});
