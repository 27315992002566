import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2SalesTaxRateModel extends Model {
  @attr('moment-datetime') startsAt;
  @attr('moment-datetime') endsAt;

  @attr('number') rate;

  @attr('string') taxType;

  @belongsTo('v2/location') location;
}