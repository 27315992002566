import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class FrontRunnerIntegrationRoute extends Route {
  model() {
    return this.store.query('integrationService', {
      name: 29, // frontrunner
      not_category: 'accounting',
      per_page: 1000
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
