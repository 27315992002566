import { alias } from '@ember/object/computed';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import SelectBox from './select-box';

export default SelectBox.extend({
  layoutName: 'components/form/select-box',

  invoiceCategories: service(),

  placeholder: 'Invoice Category',

  options: alias('invoiceCategories.allSorted'),

  didRender() {
    this._super(...arguments);
    const selection = this.selection;
    if (selection == null || selection == undefined) {
      set(this, 'hasValue', false);
    } else {
      const sel = get(selection, 'content') || selection;
      set(this, 'hasValue', true);
      set(this, 'value', sel.id)
    }
  }
});
