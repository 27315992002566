import { action, computed } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking'

export default class AuthenticatedCraknFormsNewController extends Controller {
  @service api;
  @service flashes;
  @service router;
  @tracked showValidation = false;

  @action
  createBlankForm(e) {
    e.preventDefault();
    e.target.disabled = true; // avoid double-click issues

    const isValid = this.validate();

    if (isValid) {
      this.showValidation = false;
      this.postNewForm();
    } else {
      e.target.disabled = false; // avoid double-click issues
      this.showValidation = true;
    }
  }

  @computed('form.name')
  get formNameMessage() {
    if (this.form && this.form.name) {
      return null;
    } else {
      return 'This field can\'t be blank';
    }
  }

  @computed('formFile')
  get formFileMessage() {
    if (this.formFile) {
      return null;
    } else {
      return 'This field can\'t be blank';
    }
  }

  validate() {
    if (this.formNameMessage || this.formFileMessage) {
      return false;
    } else {
      return true;
    }
  }

  postNewForm() {
    const formData = new FormData();
    formData.append('blank_form[name]', this.form.name);
    formData.append('blank_form[searchable]', this.form.searchable);
    formData.append('form_file', this.formFile);
    formData.append('blank_form[kase_contact_dropdown]',this.form.kaseContactDropdown);
    formData.append('blank_form[event_dropdown]', this.form.eventDropdown);
    formData.append('blank_form[location_dropdown]', this.form.locationDropdown);
    formData.append('blank_form[inquiry_dropdown]', this.form.inquiryDropdown);

    const that = this;

    this.api
        .post(
          'blank_forms',
          {
            body: formData
          }
        )
        .then(async response => {
          const json = await response.json();

          if (response.ok) {
            that.flashes.addSuccess(
              'Successfully created form.'
            );
            that.router.transitionTo('authenticated.crakn.manage.forms.edit', json.blank_form.id);
          } else {
            let msg;
            if (json && json.errors) {
              msg = json.errors.join(', ');
            } else {
              msg = 'An error occurred.';
            }

            that.flashes.addError(
              msg
            );
          }
        });
  }
}
