import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KasesManageDocumentsAlbumsRoute extends Route {
  @service session;

  model() {
    return this.modelFor('authenticated.kases.manage');
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const v2Kase = this.store.findRecord('v2/kase', model.get('id'));
    const v2User = this.store.findRecord(
      'v2/user',
      this.session.currentUser.id
    );

    controller.set('kase', v2Kase);
    controller.set('user', v2User);
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
