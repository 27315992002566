import Controller from '@ember/controller'
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking'

export default class AdminLocationsEditVehiclesController extends Controller {
  @service flashes;
  @service store;

  @controller('authenticated/admin/locations/edit') edit;

  @tracked addingVehicle;
  @tracked selectWrapper;

  @action
  selectAddVehicle(vehicleId) {
    this.addingVehicle = this.store.findRecord('v2/vehicle', vehicleId);
  }
  
  @action
  async addVehicle() {
    const vehicleId = this.addingVehicle.get('id');
    const vehicles = await this.model.vehicles;

    if (vehicles.map((v) => v.get('id')).includes(vehicleId)){
      this.flashes.addError(`Vehicle is already assigned to ${this.model.get('address.name')}`);
      return;
    }

    vehicles.pushObject(await this.addingVehicle);

    this.selectWrapper.querySelector('select').selectedIndex = 0;
    this.addingVehicle = null;
  }

  @action
  async removeVehicle(vehicleId) {
    const vehicles = await this.model.vehicles;

    const vehicle = await this.store.findRecord('v2/vehicle', vehicleId);
    vehicles.removeObject(vehicle);
  }

  @action
  save(event) {
    this.edit.save(event);
  }
}