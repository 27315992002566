import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'crakn/config/environment';
import { tracked } from '@glimmer/tracking'

export default class SettingsController extends Controller {
  @service api;
  @service jwt;
  @service flashes;
  authApi = `${config.ssoAuthnUrl}`;

  @tracked isSso = null;

  @action
  async onChangePasswordClick() {
    // setup env var for sso api routes....
    console.log(this.isSso);

    const url = `${config.url}` //  /#/settings/info`

    const updateUrlResp = await this.api.json.get('v2/sso/update_password_uri', { params: { email: this.email, redirect_uri: url } })
    .catch((_e) => {
      this.flashes.addError('An issue has occurred.  Please contact support');
      return;
    });

    if (updateUrlResp?.parsedJson?.error) {
      this.flashes.addError(updateUrlResp.parsedJson.error);
      return;
    }

    window.open(updateUrlResp.parsedJson.url,'_self');
  }
}
