import Controller from '@ember/controller';
import moment from 'moment';
import { action } from '@ember/object';
import { filterBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class KaseIntegrationFrazerController extends Controller {
  @filterBy('kase.events', 'exportToFrazer', true) frazerEvents;
  @filterBy('frazerEvents', 'frazerExportable', true) frazerExportableEvents;
  @filterBy('frazerEvents', 'frazerExportable', false) frazerInvalidEvents;

  @service api;
  @service flashes;

  frazerExport = null;
  kase = null;
  validationErrors = [];

  get formattedFrazerExportedAt() {
    return moment(this.frazerExport.get('updatedAt')).format('LLL');
  }

  get frazerWarning() {
    if (this.frazerInvalidEvents.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  get invalidDeathDate() {
    const dod = this.kase.get('deceased.profileDeathDate');
    return !dod ? true : dod > moment();
  }

  get isExportable() {
    if (this.frazerInvalidEvents.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  get showValidations() {
    const errors = [];
    const deceased = this.kase.get('deceased');
    const firstName = deceased.get('firstName');
    const lastName = deceased.get('lastName');
    const gender = deceased.get('gender');

    if (this.nullOrBlank(firstName)) {
      errors.push('Case must have a first name.');
    }

    if (this.nullOrBlank(lastName)) {
      errors.push('Case must have a last name.');
    }

    if (this.nullOrBlank(gender)) {
      errors.push('Case must have a gender.');
    }

    if (this.invalidDeathDate) {
      errors.push('Case must have a valid date of death.');
    }

    this.set('validationErrors', errors);

    return errors.length > 0;
  }

  handleError() {
    this.flashes.addError('Oops, something went wrong! Please try again.');
  }

  nullOrBlank(string) {
    return !string || string === '' ? true : false;
  }

  @action
  frazer(e) {
    e.target.disabled = true; // avoid double-click issues
    const kase_id = this.kase.id;

    this.api.json
      .put('frazer/kases', { body: { id: kase_id } })
      .then((response) => {
        if (!response.ok) {
          this.handleError();
          return;
        }

        this.flashes.addSuccess('Export Successful!');
        this.store.pushPayload('kase', response.parsedJson);
        this.send('refreshModel');
        e.target.disabled = false; // avoid double-click issues
      })
      .catch(() => {
        this.handleError();
        e.target.disabled = false; // avoid double-click issues
      });
  }
}
