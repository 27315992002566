import Model, { belongsTo, attr } from '@ember-data/model';
import { relativeSize } from 'crakn/utils/data-helpers';
import config from 'crakn/config/environment';
import moment from 'moment';

export default class V2PhotoModel extends Model {
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('string') description;
  @attr('string') extension;
  @attr('string') key;
  @attr('string') mediaType;
  @attr('string') name;
  @attr('number') size;
  @attr('string') url;

  @belongsTo('v2/user') createdByUser;
  @belongsTo('v2/kase') kase;

  get file() {
    return `${config.host}/api/v2/photos/${this.id}/download`;
  }

  get createdAtUnix() {
    return this.createdAt ? this.createdAt.unix() : moment().unix();
  }

  get relativeSize() {
    return relativeSize(this.size);
  }

  get icon() {
    let type;

    if (!this.mediaType) {
      return undefined;
    } else {
      type = 'image';
    }

    return `file-${type}`;
  }
}
