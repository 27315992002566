import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const Validations = buildValidations({
  'model.name': validator('presence', true),
  'model.invoiceCategoryId': validator('presence', true),
});

export default class AuthenticatedAdminGeneralPriceListsEditPackagesNewController extends Controller.extend(Validations) {
  @service flashes;
  @service intl;
  @service invoiceCategories;
  @service router;
  @service session;

  @tracked showValidations = false;

  @action
  save() {
    this.validate().then(({ validations }) => {
      if (validations.get('isValid')) {
        set(this, 'showValidations', false);
        this.model.save().then((newPackage) => {
          this.flashes.addSuccess(
            this.intl.t('authenticated.admin.general-price-lists.edit.packages.new.alerts.save.success')
          );

          this.router.transitionTo('authenticated.admin.general-price-lists.edit.packages.edit', newPackage.id);
        }).catch(() => {
          this.flashes.addError(
            this.intl.t('authenticated.admin.general-price-lists.edit.packages.new.alerts.save.error')
          );
        })
      } else {
        set(this, 'showValidations', true);
      }
    });
  }

  @action
  cancel() {
    this.model.rollbackAttributes();
    this.router.transitionTo('authenticated.admin.general-price-lists.edit.packages');
  }
}
