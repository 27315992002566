import Controller from '@ember/controller';
import { computed, set, get } from '@ember/object';
import moment from 'moment';

export default Controller.extend({
  enrollFamilyLink: null,
  survivorNextOfKin: null,
  survivorInformant: null,
  survivor: null,

  informant: null,
  nextOfKin: null,

  showSurvivor: computed('informant.content', 'nextOfKin.content', function() {
    return this.informant.content != null || this.nextOfKin.content != null;
  }),

  formattedAftercareExportedAt: computed('model.aftercareExport', function() {
    return moment(get(this, 'model.aftercareExport.updatedAt')).format('LLL');
  }),

  invalidDeathDate: computed(
    'model.kase.deceased.profileDeathDate',
    function() {
      return get(this, 'model.kase.deceased.profileDeathDate') > moment();
    }
  ),

  actions: {
    survivorCheck(value) {
      switch (value) {
        case 'toggleNextOfKin':
          if (this.survivor != null) {
            this.toggleProperty('survivorNextOfKin');
            set(this, 'survivor', null);
          } else {
            this.toggleProperty('survivorNextOfKin');
            set(this, 'survivor', 'next_of_kin');
            set(this, 'survivorInformant', false);
          }
          break;

        case 'toggleInformant':
          if (this.survivor != null) {
            this.toggleProperty('survivorInformant');
            set(this, 'survivor', null);
          } else {
            this.toggleProperty('survivorInformant');
            set(this, 'survivor', 'informant');
            set(this, 'survivorNextOfKin', false);
          }
          break;
      }
    }
  }
});
