import { filter } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  tagName: 'ul',
  classNames: ['list', 'list--bordered'],
  staffMemberActionIcon: 'close',

  eventStaffMembers: filter('content', function(eventStaffMember) {
    return eventStaffMember.get('status') !== 'declined';
  }),

  actions: {
    eventStaffMemberAction(eventStaffMember) {
      this.sendAction('eventStaffMemberAction', eventStaffMember);
    }
  }
});
