import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AdminCustomFieldsController extends SearchController {
  @service flashes;
  @service router;
  @service location;
  @service intl;

  queryParams = ['location_id', 'page'];

  @tracked location_id = null;
  @tracked page = 1;

  get createNewDisabled() {
    const customFieldConfigLocationIds = this.customFieldConfigs.map((x) => x.get('location.id'));
    const allLocationIds = this.location.all.map((location) => location.id);
    const globalLocations = customFieldConfigLocationIds.includes(undefined) ? 1 : 0;

    return customFieldConfigLocationIds.length === allLocationIds.length + globalLocations;
  }

  @action
  addNewCustomField() {
    this.router.transitionTo('authenticated.admin.manage.custom-fields.new');
  }

  @action
  selectLocation(locationId) {
    if (locationId === this.intl.t('authenticated.admin.custom-fields.locationSelect.placeholderLabel')) {
      this.location_id = null
    } else {
      this.location_id = locationId
    }

    this.page =  1;
  }
}
