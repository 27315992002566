import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),
  session: service(),

  actions: {
    selectKase(person) {
      if (person === undefined || person === null) {
        return this.onSelect(null);
      }

      this.store.findRecord('kase', person.kaseId).then(kase => {
        this.onSelect(kase);
      });
    }
  }
});
