import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AdminFinancialConfigurationsEditInterestsController extends Controller {
  @service store;
  @service flashes;

  @tracked newRecord;
  @tracked showValidation = false;

  accounts; // set in route
  chartOfAccounts; // set in route

  @action
  create() {
    this.newRecord = this.store.createRecord('v2/interestType', {
      chartOfAccounts: this.chartOfAccounts,
    });
  }

  @action
  save(interestType) {
    interestType.validate().then(({ validations }) => {
      const errors = validations.get('errors');

      if (errors.length > 0) {
        this.set('showValidation', true);
        let errorOutput = errors.map((m) => m.message).join(', ')
        this.flashes.addError(errorOutput);
        this.send('refreshModel');

        if (interestType == this.newRecord) {
          this.newRecord = null;
        }
      } else {
        this.set('showValidation', false);

        interestType
          .save()
          .then(() => {
            this.send('refreshModel');
            this.flashes.addSuccess('Interest Type successfully saved!');

            if (interestType == this.newRecord) {
              this.newRecord = null;
            }
          })
          .catch(() => {
            this.flashes.addError('An error has occured');
          });
      }
    });
  }

  @action
  cancel(interestType) {
    interestType.rollbackAttributes();

    if (interestType == this.newRecord) {
      this.newRecord = null;
    }
  }

  @action
  delete(interestType) {
    interestType
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess('Interest Type successfully removed!');
      })
      .catch(() => {
        this.flashes.addError('An error has occured');
      });
  }

  @action
  setAccount(record, accountId) {
    record.account = this.store.peekRecord('v2/account', accountId);
  }
}
