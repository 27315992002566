import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import Validations from 'crakn/validations/authenticated/admin/data/poems-prayers/poem';

export default class AuthenticatedAdminDataPoemsAndPrayersRoute extends SearchRoute {
  @service session;

  modelType = 'v2/poem';
  routeRegex = /authenticated.admin.data.poems-and-prayers/;

  async setupController(controller, model) {
    const client = await this.store.findRecord('v2/client', this.session.currentClient.id);

    const poems = model.map((poem) => {
      return Changeset(
        poem,
        lookupValidator(Validations),
        Validations
      );
    })

    controller.setProperties({
      client,
      poems,
      meta: model.meta
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
