import Route from '@ember/routing/route';

export default class AdminFinancialConfigurationsEditOverviewRoute extends Route {
  model() {
    return this.modelFor('authenticated.admin.financial-configurations.edit');
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const store = this.store;
    controller.set(
      'accounts',
      store.query('account', {
        per_page: 0,
        chart_of_accounts_id: model.get('id')
      })
    );
    controller.set(
      'taxItems',
      store.query('taxItem', {
        per_page: 0,
        chart_of_accounts_id: model.get('id')
      })
    );
  }
}
