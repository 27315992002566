import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class KasesOverview extends Route {
  @service session;
  layout = 'custom-aside';

  model(params) {
    return this.store.findRecord('kase', params.kase_id);
  }

  async setupController(controller, model) {
    super.setupController(controller, model);
    const kase = model;
    const kaseId = kase.id;
    const clientInfoId = this.session.currentClient.client_info_id;

    const kaseContacts = await model.get('kaseContacts');
    const events = await model.get('events');
    
    let pendingEsignatures = [];

    if (this.session.currentClient.can_view_form_in_tfe && this.session.currentClient.can_use_tfe_esign) {
      pendingEsignatures = await this.store.query('tfe/generated-document', {
        archived: false,
        current_client: true,
        include: 'signers',
        kase_id: kaseId,
        signing_status: 'pending',
        sort_order: 'name'
      });
    }

    controller.setProperties({
      events,
      staffMembers: await this.store.query('user', { kase_id: kaseId }),
      serviceInfo: await this.store.queryRecord('serviceInfo', { kase_id: kaseId }),
      inquiries: await this.store.query('inquiry', {
        kase: kaseId,
        archived: false,
        per_page: 100
      }),
      kase,
      kaseContacts,
      clientInfo: await this.store.findRecord('clientInfo', clientInfoId),
      pendingEsignatures
    });

    this.store
      .query('v2/cremation-authorization', { kase_id: kaseId })
      .then(cremAuths => {
        return cremAuths && cremAuths.length
          ? Promise.resolve(cremAuths.firstObject)
          : Promise.reject(null);
      })
      .then(cremAuth => {
        return this.store
          .query('esigned-cremation-authorization', {
            cremation_authorization_id: cremAuth.id
          });
      })
      .then(auths => {
        const esignatures = auths
          .map(auth => auth.esignature)
          .filter(esignature => esignature.status == 'requested');

        controller.set('pendingCremAuthEsignatures', esignatures);
      })
      .catch((_e) => {
        controller.set('pendingCremAuthEsignatures', []);
      });
  }
  
  resetController(controller, isExiting, transition) {
    if (isExiting || transition) {
      controller.setProperties({
        events: null,
        staffMembers: null,
        serviceInfo: null,
        inquiries: null,
        kase: null,
        kaseContacts: null,
        clientInfo: null,
        pendingEsignatures: null
      });
    }
  }

  @action
  save() {
    this.currentModel.save();
  }
}
