import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import SearchRoute from 'crakn/routes/search';

export default class AuthenticatedAdminManageArAdjustmentssRoute extends SearchRoute {
  @service session;
  @service router;

  beforeModel() {
    super.beforeModel(...arguments);
    if (this.session.currentUser.isSuperuser) return true;

    this.router.transitionTo('authenticated.dashboard');
  }

  queryParams = {
    page: {
      refreshModel: true,
    },
    per_page: {
      refreshModel: true,
    },

  };

  modelType= 'v2/ar-writeoff';

  routeRegex = /authenticated.admin.manage.ar-adjustments/;

  model(params) {
    return this.store.query(this.modelType, {
      query: params.query,
      page: params.page,
      per_page: params.per_page,
  })}

  setupController(controller, model, { to, from }) {
    super.setupController(controller, model, { to, from });
    controller.set('addingAdjustment', false);
    controller.set('showConfirmation', false);
    controller.set('revertWriteoffId', null);
    controller.set('needsRefresh', model.filter(x => x.status === 'Revert Queued' || x.status === 'Queued').length > 0);

    if (this.controller.needsRefresh) {
      const wait = 15000;
      setTimeout(() => {
        this.controller.triggerRefreshTimer();
      }, wait);
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
