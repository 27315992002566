import Route from '@ember/routing/route';
import { run } from '@ember/runloop';
import config from 'crakn/config/environment';
import { inject as service } from '@ember/service';

export default Route.extend({
  queryParams: {
    page: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    type: {
      refreshModel: true
    }
  },

  session: service(),

  model(params) {
    return this.store.query('integrationImport', {
      page: params.page,
      per_page: params.per_page,
      type: params.type
    });
  },

  actions: {
    import() {
      const fileSelect = document.getElementById('ohio-edrs');
      const headers = {};

      if (localStorage.getItem('sso_token') === null) {
        headers['X-CSRF-Token'] = this.session.data.authenticated.token;
      } else {
        headers['Authorization'] = localStorage.getItem('sso_token');
      }

      if (fileSelect.files && fileSelect.files.length == 1) {
        const formData = new FormData();
        formData.append('edrs', fileSelect.files[0]);

        fetch(
          `${config.host}/api/ohio_edrs_import`,
          {
            method: 'POST',
            body: formData,
            credentials: 'include',
            headers: headers
          }
        )
        .then(response => {
          if (response.ok) {
            this.store.pushPayload('model', response);
            this.send(
              'flashSuccess',
              'Yay! You successfully imported the case(s).'
            );
          } else {
            this.send(
              'flashError',
              'Oops! There was an error importing the case(s).'
            );
          }

          run.later(() => {
            this.refresh();
          }, 2000);
        })
      }
    }
  }
});
