import Route from '@ember/routing/route';

export default class CraknClientsEditOverviewRoute extends Route {
  model() {
    return this.modelFor('authenticated.crakn.clients.edit');
  }

  createClientNote(client) {
    return this.store.createRecord('clientNote', {
      client
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    this.store
      .queryRecord('clientNote', { client_id: model.id })
      .then((result) =>
        result.id
          ? controller.set('clientNote', result)
          : controller.set('clientNote', this.createClientNote(model))
      );

    controller.set('client', model);
  }
}
