import Route from '@ember/routing/route';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KasesEventsEditRoute extends Route {
  @service calendarDay;
  @service intl;

  model(params) {
    return this.store.findRecord('event', params.event_id, { reload: true });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const location = model.get('location');
    if (location == null || location == undefined) {
      model.kase.location.then((location) => {
        set(model, 'location', location);
      });
    }
  }

  afterModel(model) {
    const startsAt = model.get('startsAt');
    if (startsAt === null) {
      return;
    }
    this.calendarDay.fetchDate(model.get('startsAt'));
  }

  @action
  willTransition(transition) {
    const model = this.currentModel;
    const modelIsDirty =
      !transition.to.queryParams.noDirtyCheck &&
      (model.get('hasDirtyAttributes') ||
        (model.get('eventStaffMembers').content &&
          model
            .get('eventStaffMembers')
            .filterBy('hasDirtyAttributes', true)
            .get('length')) ||
        (model.get('eventAttendees').content &&
          model
            .get('eventAttendees')
            .filterBy('hasDirtyAttributes', true)
            .get('length')));

    if (modelIsDirty && !confirm(this.intl.t('authenticated.kases.events.edit.messages.unsavedChanges'))) {
      transition.abort();
    } else {
      return true;
    }
  }
}
