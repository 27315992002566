import { get } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default Route.extend({
  api: service(),
  layout: 'custom-aside',

  model() {
    const model = this.modelFor('authenticated.kases.manage.integrations');

    return RSVP.hash({
      model,
      kase: model,
      aftercareExport: this.store.queryRecord('integrationExport', {
        kase_id: get(model, 'id'),
        type: 'aftercare'
      })
    });
  },

  setupController(controller, model, transition) {
    controller.set('nextOfKin', get(model, 'kase.nextOfKin'));
    controller.set('informant', get(model, 'kase.informant'));

    this._super(controller, model, transition);
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('enrollFamilyLink', null);
      controller.set('survivorNextOfKin', null);
      controller.set('survivorInformant', null);
      controller.set('survivor', null);
      controller.set('informant', null);
      controller.set('nextOfKin', null);
    }
  },

  actions: {
    addDecedent(e) {
      e.target.disabled = true; // avoid double-click issues
      const kaseId = get(this, 'controller.model.kase.id');
      const survivor = get(this, 'controller.survivor');

      this.api.json.post(
        'aftercare/add_decedent',
        { body: { id: kaseId, survivor } }
      ).then(response => {
        if (response.ok) {
          this.store.pushPayload('kase', response.parsedJson);
          this.send('flashSuccess', 'Success!');
          this.refresh();
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false; // avoid double-click issues
        }
      });
    },

    enrollFamily() {
      const kaseId = get(this, 'controller.model.kase.id');

      this.api.json.get(
        'aftercare/enroll_family',
        { params: { id: kaseId } }
      ).then(response => {
        if (response.ok) {
          this.controller.setProperties({
            enrollFamilyLink: response.parsedJson.enroll_family_link
          });
          this.refresh();
        } else {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
        }
      });
    }
  }
});
