import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class KasesManageDocumentsSecureRoute extends Route {
  model() {
    const kase = this.modelFor('authenticated.kases.manage');

    return this.store.query('document', {
      kase_id: kase.get('id'),
      per_page: 5000
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('kase', this.modelFor('authenticated.kases.manage'));
    controller.set('documents', model)
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
