import Serializer from './application';

export default class V2LocationMetaSerializer extends Serializer {
  modelNameFromPayloadKey(key) {
    if (key === 'location-meta') {
      return 'v2/location-meta';
    } else if (key === 'location') {
      return 'v2/location';
    }

    return super.modelNameFromPayloadKey(...arguments);
  }
}
