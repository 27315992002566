import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  reportLink: computed('uuid', {
    get() {
      const params = new URLSearchParams({
        uuid: this.uuid
      }).toString();
      return `/report/inventory_quantity_report?${params}`;
    }
  }),

  fileUrl: computed('uuid', function() {
    return `#/generated_document/${this.uuid}`;
  })
});
