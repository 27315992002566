import { alias } from '@ember/object/computed';
import { computed, get } from '@ember/object';
import LocationSelector2 from '../location-selector2';

export default LocationSelector2.extend({
  layoutName: 'components/location-selector2',
  showPlaceholder: false,

  // generalPriceListLocked allows changing locations that share the same
  // GPL after an item is added to a financial selection
  generalPriceListLocked: false,
  locationLocked: false,
  archived: true,
  disabled: alias('locationLocked'),

  generalPriceList: alias('selectedOption.generalPriceList'),

  options: computed(
    'archived',
    'generalPriceList',
    'generalPriceListLocked',
    'location.{allSorted,archivedSorted}',
    function() {
      const locations = this._super(...arguments);

      if (this.generalPriceListLocked) {
        return locations.filter(location => {
          const generalPriceList = this.generalPriceList;
          return (
            !generalPriceList ||
            get(location, 'generalPriceList.id') === get(generalPriceList, 'id')
          );
        });
      } else {
        return locations;
      }
    }
  ),
});
