import Route from '@ember/routing/route';

export default class KasesManageEditFamilyPortalRoute extends Route {
  layout = 'custom-aside';

  model() {
    return this.modelFor('authenticated.kases.manage');
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        showValidation: false
      });
    }
  }

  async setupController(controller, model) {
    controller.set('model', model);
    this.setFamilyPortalInvites();
    await this.setV2Kase();
  }

  setFamilyPortalInvites() {
    this.controller.set(
      'familyPortalInvites',
      this.store.query('v2/family-portal-invite', {
        kase_id: this.modelFor('authenticated.kases.manage').get('id'),
        include: 'recipient'
      })
    );
  }

  async setV2Kase() {
    const kaseId = this.modelFor('authenticated.kases.manage').get('id');
    const v2Kase = await this.store.findRecord('v2/kase', kaseId);
    this.controller.set('v2Kase', v2Kase);
  }
}
