import { readOnly, equal } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Toolbelt from 'crakn/mixins/component-toolbelt';

export default Component.extend(Toolbelt, {
  classNames: ['card', 'card--grey', 'm--sm--t'],

  store: service(),

  label: 'Inquiry History',
  person: null,

  activeInquiriesMeta: readOnly('activeInquiries.content.meta'),

  activeInquiries: computed(
    'activeInquiryPage',
    'activeInquiryPerPage',
    function() {
      return this.store.query('inquiry', {
        contact: get(this, 'person.id'),
        per_page: this.activeInquiryPerPage,
        page: this.activeInquiryPage,
        sort_by: 'created_at',
        archived: false
      });
    }
  ),

  activeInquiryPerPage: 1,
  activeInquiryPage: 1,
  isNotesVisible: false,

  didReceiveAttrs() {
    this._super(...arguments);

    // This will force the activeInquiries to update one way or another
    if (this.activeInquiryPage > 1) {
      set(this, 'activeInquiryPage', 1);
    } else {
      this.notifyPropertyChange('activeInquiryPage');
    }
  },

  isFirstInquiry: equal('activeInquiriesMeta.page', 1),

  isLastInquiry: computed('activeInquiriesMeta.page', function() {
    const meta = this.activeInquiriesMeta;

    return meta ? get(meta, 'page') === get(meta, 'pages') : true;
  }),

  actions: {
    navigateInquiry(direction) {
      set(this, 'isNotesVisible', false);

      if (direction === 'next') {
        this.incrementProperty('activeInquiryPage');
      } else if (direction === 'prev') {
        this.decrementProperty('activeInquiryPage');
      }
    },

    toggleNotes() {
      this.toggleProperty('isNotesVisible');
    }
  }
});
