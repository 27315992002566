import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<p>\n  <a\n      href={{this.privacyPolicyURL}}\n      target=\"_blank\"\n      rel=\"noopener noreferrer\">\n    Privacy Policy\n  </a>\n  |\n  <a\n      href={{this.termsOfUseURL}}\n      target=\"_blank\"\n      rel=\"noopener noreferrer\">\n    Terms of Use\n  </a>\n</p>\n<span class=\"d--block p--sm--t emp\">\n  <p>The following information may be sent to your website:</p>\n  <ul>\n    <li>Deceased name</li>\n    <li>Deceased vital statistics</li>\n    <li>Case Images</li>\n    <li>Case Events</li>\n    <li>Obituary</li>\n    <li>Memorial Contributions</li>\n  </ul>\n</span>\n", {"contents":"<p>\n  <a\n      href={{this.privacyPolicyURL}}\n      target=\"_blank\"\n      rel=\"noopener noreferrer\">\n    Privacy Policy\n  </a>\n  |\n  <a\n      href={{this.termsOfUseURL}}\n      target=\"_blank\"\n      rel=\"noopener noreferrer\">\n    Terms of Use\n  </a>\n</p>\n<span class=\"d--block p--sm--t emp\">\n  <p>The following information may be sent to your website:</p>\n  <ul>\n    <li>Deceased name</li>\n    <li>Deceased vital statistics</li>\n    <li>Case Images</li>\n    <li>Case Events</li>\n    <li>Obituary</li>\n    <li>Memorial Contributions</li>\n  </ul>\n</span>\n","moduleName":"crakn/components/g/admin/integrations/disclaimers/tribute-website.hbs","parseOptions":{"srcName":"crakn/components/g/admin/integrations/disclaimers/tribute-website.hbs"}});
import Component from '@glimmer/component';

export default class GTributeWebsiteDisclaimerComponent extends Component {
  get privacyPolicyURL() {
    switch (this.args.displayName) {
      case 'CFS':
        return 'https://www.runcfs.com/privacy-policy';
      case 'Frazer':
        return 'https://www.frazerconsultants.com/privacy-policy';
      case 'FrontRunner':
        return 'https://www.frontrunnerpro.com/privacy-policy';
      case 'Funeral Tech':
        return 'https://www.funeraltech.com/privacy-policy';
      case 'SRS':
        return 'https://www.srscomputing.com/privacy-policy';
      case 'Tribute Website':
        return 'https://www.tributetech.com/privacy-policy';
      default:
        return 'https://www.tributetech.com/privacy-policy';
    }
  }

  get termsOfUseURL() {
    switch (this.args.displayName) {
      case 'CFS':
        return 'https://www.runcfs.com/terms-of-use';
      case 'Frazer':
        return 'https://www.frazerconsultants.com/terms-of-use';
      case 'FrontRunner':
        return 'https://www.frontrunnerpro.com/terms-of-use';
      case 'Funeral Tech':
        return 'https://www.funeraltech.com/terms-of-use';
      case 'SRS':
        return 'https://www.srscomputing.com/terms-of-use';
      case 'Tribute Website':
        return 'https://www.tributetech.com/terms-of-use';
      default:
        return 'https://www.tributetech.com/terms-of-use';
    }
  }
}
