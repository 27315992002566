import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class KasesManageEditPreparationsRoute extends Route {
  layout = 'custom-aside';

  model() {
    return this.modelFor('authenticated.kases.manage');
  }
  
  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('kase', model);
    controller.set(
      'newKaseStaffMember',
      this.store.createRecord('kaseStaffMember', {newKaseStaffMemberId: null})
    );
    controller.set(
      'newEmbalmingStaffMember',
      this.store.createRecord('kaseStaffMember', {newEmbalmingStaffMemberId: null})
    );
    controller.setProperties(model);
  }

  @action
  willTransition() {
    this.modelFor('authenticated.kases.manage').save();
  }
}
