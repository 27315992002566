// import RESTSerializer from '@ember-data/serializer/rest';
import { ActiveModelSerializer } from 'active-model-adapter';

export default class TfeBase extends ActiveModelSerializer {
  // Add tfe to the payload model name if it is not already there
  modelNameFromPayloadKey() {
    const modelName = super.modelNameFromPayloadKey(...arguments);

    if (new RegExp('^tfe/.+').test(modelName)) {
      return modelName;
    } else {
      return `tfe/${modelName}`;
    }
  }
}
