import Model, { hasMany, attr } from '@ember-data/model';

export default class InventoryModel extends Model {
  @attr('number', { defaultValue: 0 }) quantity;
  @attr('string') vendorNotes;
  @attr('number', { defaultValue: 0 }) beginningBalanceQuantity;
  @attr('boolean', { defaultValue: false }) initialQuantitySet;
  @attr('moment-datetime') beginningBalanceDate;
  @attr('number') totalCost;
  @attr('moment-datetime') archivedAt;
  @attr('string') name;
  @attr('number') price;
  @attr('number') cost;

  @hasMany('inventoryTransaction', { async: true }) inventoryTransactions;
}
