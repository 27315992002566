import { run, cancel } from '@ember/runloop';
import config from '../config/environment';
import EmberObject from '@ember/object';
import Service from '@ember/service';

const isTesting = config.environment === 'test';

// We change the duration on tests so that we don't have to wait for the flash timeouts to resolve each time
const FLASH_MESSAGE_DURATION = isTesting ? 60 : 3000; // ms
const TRANSITION_OUT_DURATION = isTesting ? 20 : 1000; // ms

export default class FlashesService extends Service {
  messages = [];

  addSuccess(message) {
    return this.add({ message, level: 'success' });
  }

  addError(message) {
    message =
      message ||
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.';

    return this.add({ message, level: 'error' });
  }

  addInfo(message) {
    return this.add({ message, level: 'info' });
  }

  add(options = {}) {
    const message = EmberObject.create(options);

    message.timer = run.later(() => {
      this.remove(message);
    }, FLASH_MESSAGE_DURATION)

    this.messages.unshiftObject(message);

    return message;
  }

  remove(flashMessage) {
    flashMessage.isClosing = true;

    run.later(() => {
      if (!this.messages) {
        return;
      } // Ember tests fail without this

      this.messages.removeObject(flashMessage);

      run.cancel(flashMessage.timer);
    }, TRANSITION_OUT_DURATION);
  }

  clear() {
    this.messages.forEach(message => {
      cancel(message.timer);
    });
    this.messages.clear();
  }
}
