// This is no longer used: the file is being kept unless it needs to be used again ~ 08/26/2020

import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    return this.modelFor('authenticated.kases.manage.chain-of-custody');
  },

  setupController(controller, models) {
    this._super(...arguments);
    controller.setProperties(models);
  }
});