import Model, { belongsTo, attr } from '@ember-data/model';

export default class KaseTaskModel extends Model {
  @attr('boolean') complete;
  @attr('string') initials;
  @attr('string') label;
  @attr('string') list;
  @attr('string') notes;
  @attr('string') status;
  @attr('string') updatedAt;

  @belongsTo('kase', { async: true }) kase;
}
