import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: 'li',
  classNames: ['list__item'],
  classNameBindings: ['qaClass'],
  flashes: service(),

  qaClass: computed('model.id', function() {
    return `qa-admin-organization-${this.model.id}`;
  }),

  showDialog: false,

  actions: {
    removeOrganization() {
      this.attrs
        .removeOrganization(this.model)
        .then(() => {
          this.flashes.addSuccess('Organization successfully deleted.');
        })
        .catch(() => {
          this.flashes.addError('Something went wrong. Please try again.');
        });
    }
  }
});
