import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AdminGeneralPriceListsEditProductsController extends SearchController {
  @service flashes;
  @service invoiceCategories;

  @tracked archived = false;
  @tracked category_id = null;
  @tracked invoice_id = null;

  /**
 * New Product object that is passed in to the `add-edit-product` component
 * @type {Object}
 */
  @tracked newProduct = null;

  generalPriceList = null;
  gplProductCategories = null;
  general_price_list_id = null;
  chartOfAccounts = null;

  queryParams = ['sort_by', 'order', 'general_price_list_id', 'invoice_id', 'category_id'];
  sort_by = null;
  order = 'ASC';

  @alias('invoiceCategories.allSorted') invoiceOptions;

  /**
 * Read only computed specifying whether or not the user as edit access
 * @return {Boolean} Whether or not user has edit access
 */
  @readOnly('session.currentUser.canEditProducts') canEditProducts;

  /**
   * Returns a filtered model based off `archivedAt`
   *
   * This is in part to help tests, but also to give an immediate response (Intead of waiting for the API) when
   * the archive/unarchive buttons are clicked. It is a little redundant, but provides amore immediate front end
   * experience.
   *
   * @return {Array} Filtered model
   */
  get products() {
    const archived = this.archived;

    return this.model.filter(function (item) {
      return (item.archivedAt === null) !== archived;
    });
  }

  /**
   * New `statementCategories` object that is passed in to the `add-edit-product` component
   * @type {Object}
   */
  statementCategories = null;

  /**
   * New `statementSubcategories` object that is passed in to the `add-edit-product` component
   * @type {Object}
   */
  statementSubcategories = null;

  /**
   * Creates a blank `newProduct` for `add-edit-product` when the 'Add Product' button is clicked.
   * Send 'statementCategories' to be used in the `conditional-select-box`.
   * Send 'statementSubcategories' to be used in the `conditional-select-box`.
   * Also resets the query back to an empty string
   */
  @action
  addProduct() {
    this.newProduct =
      this.store.createRecord('product', {
        name: this.query,
        generalPriceList: this.generalPriceList
      })
    this.query = null;
  }

  /**
   * Destroys the `newProduct` when cancel is hit within `add-edit-product`
   * @return {Object} Newly destroyed product
   */
  @action
  cancelProduct() {
    if (this.newProduct.hasDirtyAttributes) {
      this.newProduct.rollbackAttributes();
    }
    this.newProduct = null;
  }

  /**
   * Saves `newProduct` when save is hit within `add-edit-product`
   * @return {Object} Newly saved product
   */
  @action
  saveProduct() {
    return this.newProduct.save().then(result => {
      // Create new product with productCategory, invoiceCategory, and isTaxed from previous product
      this.newProduct =
        this.store.createRecord('product', {
          productCategory: result.productCategory,
          invoiceCategory: result.invoiceCategory,
          statementCategory: result.statementCategory,
          statementSubcategory: result.statementSubcategory,
          generalPriceList: result.generalPriceList,
          isTaxed: result.isTaxed,
          stateTax: result.stateTax,
          localTax: result.localTax
        })

      this.send('refreshModel');
    });
  }

  /**
   * Updats the sorting property in the table
   * @param  {String} sortProperty Name of property to sort by
   * @return {Undefined}
   */
  @action
  sortBy(sortProperty) {
    if (this.sort_by === sortProperty) {
      this.order = this.order === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.sort_by = sortProperty;
      this.order = 'ASC';
    }
  }

  @action
  resetPagination() {
    this.page = 1;
  }

  @action
  setProductCategory(categoryId) {
    this.category_id = categoryId;
    this.resetPagination();
  }

  @action
  setInvoiceCategory(invoiceId) {
    this.invoice_id = invoiceId;
    this.resetPagination();
  }
}
