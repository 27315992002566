import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @kase.id}}\n  <div class=\"add-edit__title m--0--t\">\n    <h4 class=\"m--lg--t\">\n      <LinkTo\n          class=\"strong\"\n          @route={{this.session.currentUser.kaseEditRoute}}\n          @model={{@kase.id}}>\n        {{@kase.deceased.name}}\n      </LinkTo>\n    </h4>\n  </div>\n{{else}}\n  <G::Crakn::Autocomplete::BatchPayments::Kase\n      @placeholder={{if @placeholder @placeholder \"Name\"}}\n      @value={{@kase.deceased.typeaheadName}}\n      @onSelect={{this.selectKase}}\n      @disabled={{this.args.disabled}}\n      @onDirtyFocusOut={{@onSelect}}\n      @locationId={{@locationId}}/>\n{{/if}}\n", {"contents":"{{#if @kase.id}}\n  <div class=\"add-edit__title m--0--t\">\n    <h4 class=\"m--lg--t\">\n      <LinkTo\n          class=\"strong\"\n          @route={{this.session.currentUser.kaseEditRoute}}\n          @model={{@kase.id}}>\n        {{@kase.deceased.name}}\n      </LinkTo>\n    </h4>\n  </div>\n{{else}}\n  <G::Crakn::Autocomplete::BatchPayments::Kase\n      @placeholder={{if @placeholder @placeholder \"Name\"}}\n      @value={{@kase.deceased.typeaheadName}}\n      @onSelect={{this.selectKase}}\n      @disabled={{this.args.disabled}}\n      @onDirtyFocusOut={{@onSelect}}\n      @locationId={{@locationId}}/>\n{{/if}}\n","moduleName":"crakn/components/g/crakn/autocomplete/batch-payments/kase-typeahead.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/autocomplete/batch-payments/kase-typeahead.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class GCraknAutocompleteBatchPaymentsKaseTypeahead extends Component {
  @service store;
  @service session;

  @action
  async selectKase(person) {
    if (person === undefined || person === null) {
      return this.args.onSelect(null);
    }

    await this.store.findRecord('v2/kase', person.kaseId, { include: 'deceased,kase-financial'}).then(kase => {
      this.args.onSelect(kase);
    });
  }
}
