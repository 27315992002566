import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { inject as service } from '@ember/service';
import { set, get } from '@ember/object';
import moment from 'moment';

export default Route.extend({
  api: service(),
  flashes: service(),
  queryParams: {
    redirectPath: {
      refreshModel: true
    }
  },

  model(params) {
    return hash({
      selection: this.store.findRecord('selection', params.selection_id)
    });
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.setProperties(model);
  },

  showMiscSaleUpdatedMessage() {
    this.flashes.addSuccess(
      'Misc Sale has been updated!'
    );
  },

  actions: {
    generateStatement(selection) {
      set(selection, 'statementLastGenerated', moment());
      selection.save();

      this.api.json.get(
        'statement_of_accounts',
        {
          params: {
            selection_id: get(selection, 'id'),
            uuid: get(selection, 'uuid')
          }
        }
      ).then(response => {
        if (!response.ok) {
          this.flashes.addError();
        }
      });
    },

    addItem(item) {
      if (!item || !get(item, 'id')) {
        return;
      }

      const selection = this.currentModel.selection;
      // Check if item is a product
      if (get(item, 'productCategory.id') || get(item, 'product_category_id')) {
        // Fetch full product
        this.store.findRecord('product', get(item, 'id')).then(product => {
          this.store
            .createRecord('selectionProduct', {
              price: get(item, 'price'),
              manufacturer: get(item, 'manufacturer'),
              model: get(item, 'model'),
              material: get(item, 'material'),
              interior: get(item, 'interior'),
              quantity: 1,
              product,
              selection
            })
            .save()
            .then(selectionProduct => {
              this.handleSaveSuccess();
              this.flashes.addSuccess(
                `"${get(selectionProduct, 'product.name')}" added to misc sale!`
              );
            });
        });

        // Or package
      } else {
        // Fetch full package
        this.store.findRecord('package', get(item, 'id')).then(pkg => {
          // Create new selectionPackage
          this.store
            .createRecord('selectionPackage', {
              pricingAmount: get(pkg, 'pricingAmount'),
              pricingMethod: get(pkg, 'pricingMethod'),
              products: get(pkg, 'products.content'),
              quantity: 1,
              package: pkg,
              selection
            })
            .save()
            .then(result => {
              this.handleSaveSuccess();
              this.flashes.addSuccess(
                `"${get(result, 'package.name')}" added to misc sale!`
              );
            })
            .catch(() => {
              this.flashes.addError();
            });
        });
      }
    },

    saveSelection(post = false, detailsController) {
      const selection = this.currentModel.selection;

      if (post) {
        if (
          confirm(
            'Once a sale is posted, you can no longer edit product selections/details. Are you sure?'
          )
        ) {
          set(selection, 'posted', true);
        } else {
          return;
        }
      }

      selection
        .save()
        .then(selection => {
          detailsController.originalIsBadDebt = selection.isBadDebt;
          this.flashes.addSuccess(
            `Misc Sale has successfully been ${post ? 'posted' : 'saved'}!`
          );
          this.transitionTo('authenticated.misc-sales.edit.details', selection.get('id'));
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    selectPurchaser(purchaser) {
      const selection = this.currentModel.selection;
      set(selection, 'purchaser', purchaser);

      if (purchaser && !purchaser.get('isNew')) {
        selection
          .save()
          .then(() => {
            this.showMiscSaleUpdatedMessage();
          })
          .catch(() => {
            this.flashes.addError();
          });
      }
    },

    saveContact(contactType) {
      const selection = this.currentModel.selection;
      selection
        .get(`${contactType}.content`)
        .save()
        .then(() => {
          selection.save().then(() => {
            this.showMiscSaleUpdatedMessage();
          });
        });
    },

    removeItem(item) {
      return item
        .destroyRecord()
        .then(() => {
          this.handleSaveSuccess();
          this.showMiscSaleUpdatedMessage();
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    removePackageProduct(packageRecord, product) {
      if (!packageRecord || !product) {
        return;
      }

      get(packageRecord, 'products').removeObject(product);
      this.handleSaveSuccess();
    },

    saveCustomProduct(customProduct) {
      if (!customProduct) {
        return;
      }

      set(customProduct, 'selection', this.currentModel.selection);

      return customProduct
        .save()
        .then(() => {
          this.handleSaveSuccess();
          this.flashes.addSuccess(
            'Custom product successfully saved!'
          );
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    saveItem(item) {
      return item
        .save()
        .then(() => {
          this.handleSaveSuccess();
          this.showMiscSaleUpdatedMessage();
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    edit_unpost(detailController, callback) {
      const selection = this.currentModel.selection;
      set(selection, 'posted', false);
      selection.save().then(() => {
        if(callback) {
          callback(detailController);
        }
        this.flashes.addSuccess(
          'Misc Sale has successfully been unposted'
        );
        this.handleSaveSuccess();
      });
    }
  },

  handleSaveSuccess() {
    this.refresh();
  }
});
