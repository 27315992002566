import Adapter from './application';
import { BuildURLMixin } from '@ember-data/adapter';

export default class BlankFormAdapter extends Adapter.extend(BuildURLMixin) {
  findRecord(store, type, id, snapshot) {
    const url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
    const urlObj = new URL(url, window.location.origin);
    const params = urlObj.searchParams;

    if (snapshot.adapterOptions && snapshot.adapterOptions.can_view_form_in_tfe !== undefined) {
      params.set('can_view_form_in_tfe', snapshot.adapterOptions.can_view_form_in_tfe);
    }

    urlObj.search = params.toString();

    return this.ajax(urlObj.toString(), 'GET');
  }
}
