import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import { set, get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import Bugsnag from '@bugsnag/js';

export default Route.extend({
  api: service(),
  intl: service(),

  queryParams: {
    token: {
      refreshModel: false,
      replace: true,
      as: 'token-id'
    }
  },

  model(params) {
    const model = this.modelFor('authenticated.kases.manage.financials.payments');

    return RSVP.hash({
      kase: get(model, 'kase'),
      selection: this.store.findRecord('selection', params.selection_id),
      clientInfo: get(model, 'clientInfo')
    });
  },

  afterModel(models, transition) {
    const token = transition.to.queryParams['token-id'];

    if (token) {
      const payments = get(models, 'selection.payments');

      if (get(payments, 'length') > 0) {
        const pendingPayment = payments
          .filterBy('processorPending', true)
          .get('firstObject');
        this.confirmPayment(token, pendingPayment, get(models, 'kase.location.id'));
      }
    }
  },

  setupController(controller, models, transition) {
    this._super(...arguments);
    // Sets properties on parent route
    this.send('setDefaultPaymentPurchaser', get(models, 'selection.purchaser'));
    this.send('setSelection', get(models, 'selection'));
    controller.setProperties(models);
    if (transition.to.queryParams.success === 'false') {
      this.controller.flashes.addError(transition.to.queryParams.message);
    }
  },

  confirmPayment(token, pendingPayment, locationId) {
    if (token != '' && get(pendingPayment, 'processorPending')) {
      this.api.json.post(
        'payments/confirm_payment',
        { body: { token, location_id: locationId } }
      ).then(response => {
        if (response.ok) {
          set(this, 'controller.token', null);
          pendingPayment.set('processorPending', false);
          pendingPayment.save().then(payment => {
            set(payment, 'paymentLoading', true);
            this.setupTransnationalStatus(payment, locationId);
            this.send('flashSuccess', 'Payment processed correctly');
          });
        } else {
          set(this, 'controller.token', null);
          pendingPayment.set('processorPending', false);
          pendingPayment.save().then(payment => {
            set(payment, 'paymentLoading', true);
            this.setupTransnationalStatus(payment, locationId);
            Bugsnag.notify(response.parsedJson, function (event) {
              event.addMetadata('tempmeta', { source: 'confirmPayment()' })
            });

            this.send('flashError', 'Unable to process payment');
          });
        }
      });
    }
  },

  async setupTransnationalStatus(payment, locationId) {
    const delayIncrement = 2000; // 2s
    let nextDelay = delayIncrement;
    let attemptsRemaining = 5;
    let paymentCompleted;

    do {
      // Sleep <nextDelay> seconds
      await new Promise(resolve => setTimeout(resolve, nextDelay));

      const transnationalStatus =
        await this.getTransnationalStatus(payment, locationId);

      const action_or_actions = transnationalStatus?.transaction?.action;
      let action;

      if (Array.isArray(action_or_actions)) {
        action = action_or_actions[0];
      } else {
        action = action_or_actions;
      }

      paymentCompleted = !isEmpty(action?.date);

      if (paymentCompleted) {
        this.saveTransnationalPaymentDetails(payment, transnationalStatus)
      } else {
        attemptsRemaining = attemptsRemaining - 1;
        nextDelay = nextDelay + delayIncrement;

        if (!attemptsRemaining) {
          payment.set('paymentLoading', false);
          this.send('flashError', this.intl.t('authenticated.kases.manage.financials.payments.edit.errors.paymentStatus'));
        }
      }
    } while (!paymentCompleted && attemptsRemaining);
  },

  getTransnationalStatus(payment, locationId) {
    const transactionId = get(payment, 'transactionId');

    return this.api.json.post(
      'payments/transaction_status',
      {
        body: {
          transactionId,
          location_id: locationId
        }
      }
    ).then(response => {
      if (response.ok) {
        return response.parsedJson;
      }
    });
  },

  saveTransnationalPaymentDetails(payment, transnationalStatus) {
    const transaction = transnationalStatus.transaction;

    payment.setProperties({
      transnationalStatus: transaction.condition,
      transnationalAuthorizationCode: transaction.authorization_code,
      transnationalDateString: transaction.action.date,
      transnationalResponseText: transaction.action.response_text,
      cardType: transaction.cc_type
    });

    payment.save().then(() => {
      payment.set('paymentLoading', false);
      this.refresh();
      this.send('refreshKaseFinancial');
    });
  },

  actions: {
    refreshModel() {
      this.refresh();
    }
  }
});
