import moment from 'moment';
import { computed } from '@ember/object';
import Component from '@ember/component'

const addressKeys = [
  'Address Line1',
  'Address Line2',
  'Line1',
  'Line2',
  'City',
  'County',
  'Zip Code',
  'State',
  'Country'
];

const nameKeys = [
  'Name Prefix',
  'First Name',
  'Middle Name',
  'Last Name',
  'Name Suffix',
  'Maiden Name'
];

const contactKeys = ['Email', 'Phone Primary'];

export default Component.extend({
  title: null,
  keyValues: [],
  disabled: false,
  emptyKeyValues: computed.equal('keyValues.length', 0),
  addressKeyValues: computed('keyValues', function() {
    return this.filteredKeyValuesForKeys(addressKeys, this.keyValues);
  }),
  nameKeyValues: computed('keyValues', function() {
    return this.filteredKeyValuesForKeys(nameKeys, this.keyValues);
  }),
  contactKeyValues: computed('keyValues', function() {
    return this.filteredKeyValuesForKeys(contactKeys, this.keyValues);
  }),
  dateKeyValues: computed('keyValues', function() {
    const filteredKeyValues = this.keyValues.filter(keyValuePair => {
      return moment.isMoment(keyValuePair.value);
    });
    if (filteredKeyValues.length === 0) {
      return null;
    }
    return this.sortedKeyValuesByKey(filteredKeyValues);
  }),
  sortedKeyValues: computed('keyValues', function() {
    const excludedKeys = nameKeys.concat(contactKeys).concat(addressKeys);
    const filteredKeyValues = this.keyValues.filter(keyValuePair => {
      return (
        !excludedKeys.includes(keyValuePair.key) &&
        !moment.isMoment(keyValuePair.value)
      );
    });
    if (filteredKeyValues.length === 0) {
      return null;
    }
    return this.sortedKeyValuesByKey(filteredKeyValues);
  }),
  sections: computed(
    'sortedKeyValues',
    'dateKeyValues',
    'contactKeyValues',
    'nameKeyValues',
    'addressKeyValues',
    function() {
      return [
        {
          title: 'Name',
          content: this.get('nameKeyValues')
        },
        {
          title: 'Address',
          content: this.get('addressKeyValues')
        },
        {
          title: 'Contact',
          content: this.get('contactKeyValues')
        },
        {
          title: 'Dates',
          content: this.get('dateKeyValues')
        },
        {
          title: 'Other',
          content: this.get('sortedKeyValues')
        }
      ];
    }
  ),

  sortedKeyValuesByKey(keyValues) {
    return keyValues.sort((a, b) => {
      if (a.key > b.key) {
        return 1;
      } else if (a.key < b.key) {
        return -1;
      }
      return 0;
    });
  },

  filteredKeyValuesForKeys(keys, keyValues) {
    const filteredKeyValues = keyValues.filter(keyValuePair => {
      return keys.includes(keyValuePair.key);
    });
    if (filteredKeyValues.length === 0) {
      return null;
    }
    return filteredKeyValues.sort((a, b) => {
      const aIdx = keys.indexOf(a.key);
      const bIdx = keys.indexOf(b.key);
      if (aIdx > bIdx) {
        return 1;
      } else if (aIdx < bIdx) {
        return -1;
      }
      return 0;
    });
  },

  actions: {
    copyKeyValueToKase(hash) {
      this.sendAction('copyKeyValuesToKase', [hash]);
    },
    copySectionToKase(hashes) {
      this.sendAction('copyKeyValuesToKase', hashes);
    }
  }
});
