import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking'

export default class LoginRoute extends Route {
  @service api;
  @service session;
  @service router;
  @service jwt;

  layout = 'fullscreen';
  @tracked isFullscreenActive = true;
  @tracked code;
  @tracked state;

  beforeModel(params) {
    this.code = params.to.queryParams.code;
    this.state = params.to.queryParams.state;

    if (this.session.isAuthenticated) {
      if (params?.to?.queryParams?.acceptNewClient) {
        this.router.transitionTo('authenticated.dashboard', {
          queryParams: {
            acceptNewClient: params.to.queryParams.acceptNewClient
          }
        });
      } else {
        this.router.transitionTo('authenticated.dashboard');
      }
    }
  }

  async model() {
    const hasState = this.state && this.state != '';
    const hasCode = this.code && this.code != '';

    if (!hasState || !hasCode) return;

    const ssoTokenResp = await this.jwt.getSsoToken(this.code, this.state);

    if (!ssoTokenResp?.ok) {
      this.router.transitionTo('login');
      return;
    } else {
      const options = this.jwt.calcSsoTokenOptions(ssoTokenResp);
      this.jwt.setSsoOptions(options);
      
      const authenticator = 'authenticator:application';
      const credentials = { access_token: this.jwt.token };
      await this.session.authenticate(authenticator, credentials);
    }
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      controller.setProperties({
        email: null,
        password: null,
        error: null
      });
    }
  }

  setupController(controller, _model, _transition) {
    super.setupController(...arguments);
    controller.set('loginSuccess', false);
  }
}
