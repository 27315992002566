import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AdditionalInfoController extends Controller {
  @service flashes;
  @service session;

  @tracked sageIntacctJournalCode;
  @tracked glEntryCode;
  @tracked locationCode;
  @tracked locations;
  @tracked locationId;
  @tracked locationConfiguration = [];
  @tracked sageIntacctEditingDisabled = false;

  @action
  fetchLocations() {
    const { store, model, locationConfiguration } = this;

    store.query('location', {
      chart_of_accounts_id: model.id,
      archived: false,
      per_page: 80,
    }).then((locations) => {
      const locationIds = locationConfiguration.map(loc => loc.locationId);
      const filteredLocations = locations.filter(loc => 
        locationConfiguration.length === 0 || !locationIds.includes(loc.id)
      );

      const sortedLocations = filteredLocations.toArray().sort((location1, location2) => 
        location1.name.localeCompare(location2.name)
      );

      set(this, 'locations', sortedLocations);
    });
  }

  @computed('model', 'model.accountingIntegrationServiceName')
  get accountingIntegrationServicePresent() {
    if (this.model.accountingIntegrationServiceName != null &&
        !(this.model.changedAttributes().accountingIntegrationServiceName &&
          typeof this.model.changedAttributes().accountingIntegrationServiceName[1] === 'string')) {
      return true;
    } else {
      return false;
    }
  }

  accountingIntegrationServiceOptions = [
    { key: 'csv_accounting', value: 'CSV' },
    { key: 'johnson_accounting', value: 'Johnson Accounting' },
    { key: 'quick_books', value: 'Quick Books' },
    { key: 'quick_books_iif', value: 'QuickBooks IIF' },
    { key: 'quick_books_online', value: 'Quick Books Online' },
    { key: 'sage_fifty', value: 'Sage 50' },
    { key: 'sage_intacct', value: 'Sage Intacct' }
  ];

  @action
  validate() {
    const model = this.model;
    if (this.accountingIntegrationServicePresent) {
      this.send('save');
    } else if (model.changedAttributes().accountingIntegrationServiceName && model.changedAttributes().accountingIntegrationServiceName[1] != null) {
      const agreed = confirm(
        'After saving, you will NOT be able to change the integration type for this Financial Configuration. Are you sure?'
      );
      if (agreed) {
        this.accountingIntegrationServicePresent = true;
        this.send('save');
      } else {
        model.accountingIntegrationServiceName = null;
      }
    } else {
      this.send('save');
    }
  }

  @action
  save() {
    if (this.model.accountingIntegrationServiceName === 'sage_intacct') {
      this.model.set('locationConfiguration', this.locationConfiguration);
    }
    this.model
    .save()
    .then(() => {
      this.flashes.addSuccess('Settings successfully saved!');
      set(this, 'sageIntacctEditingDisabled', true);
      set(this, 'sageIntacctEditingDisabled', false);
    })
    .catch(() => {
      this.flashes.addError('Sorry, something went wrong. Please try again.');
    });
  }

  @action
  cancel() {
    this.locationCode = null;
    this.locationId = null;
    this.glEntryCode = null;
    this.sageIntacctJournalCode = null;
    this.locationConfiguration = this.model.locationConfiguration || [];

    if (this.model.hasDirtyAttributes) {
      this.model.rollbackAttributes();
    }
  }

  @action
  setAccountingIntegrationServiceName(accountingIntegrationServiceKey) {
    const model = this.model;

    if (accountingIntegrationServiceKey) {
      model.accountingIntegrationServiceName = accountingIntegrationServiceKey;
    } else {
      model.accountingIntegrationServiceName = null;
    }
  }

  @action
  setOperatingCountry(value) {
    this.model.operatingCountry = value;
  }

  @action
  addlocationConfiguration() {
    const locationConfig = {
      locationId: this.locationId,
      locationName: this.store.peekRecord('location', this.locationId).name,
      glEntryCode: this.glEntryCode,
      locationCode: this.locationCode,
    }
    
    set(this, 'locationConfiguration', [...this.locationConfiguration, locationConfig]);
    set(this, 'locations', this.locations.filter((loc) => loc.id !== this.locationId));
    this.locationCode = null;
    this.locationId = null;
    this.glEntryCode = null;
  }
  
}
