import Model, { belongsTo, attr } from '@ember-data/model';
import { startCase } from 'lodash-es/string';
import { camelize } from '@ember/string';

export default class KasePersonModel extends Model {
  @attr('string') role;
  @attr('string') relationship;

  @belongsTo('kase', { inverse: 'kasePeople', async: true }) kase;
  @belongsTo('person', { async: true }) person;

  get formattedRole() {
    return startCase(camelize(this.role));
  }
}
