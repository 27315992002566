import { reads } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, set, get } from '@ember/object';
import config from 'crakn/config/environment';
import { validator, buildValidations } from 'ember-cp-validations';

/* Note on `esignedDocuments.signers`:
 * It is an object added on-the-fly to keep track of the selected signers until
 * the signature requests are submitted. Once the requests have been submitted,
 * the signers should be accessed through the esignedDocument's esignatures.
 */

const Validations = buildValidations({
  anySignerSelected: validator('inclusion', {
    in: [true],
    message: 'Select at least one signer'
  }),
  allSignersHaveEmails: validator('inclusion', {
    in: [true],
    message: 'All contacts must have an email'
  })
});

export default Component.extend(Validations, {
  classNames: ['list__item', 'list__item--wrap'],
  tagName: 'li',
  store: service(),

  showValidation: false,
  _observer: 0, // easily observable change

  anySignerSelected: computed('esignedDocument.signers', function() {
    const signers = get(this, 'esignedDocument.signers') || {};
    return !!Object.values(signers).compact().length;
  }),

  allSignersHaveEmails: computed(
    'esignedDocument.signers',
    '_observer',
    function() {
      const signers = get(this, 'esignedDocument.signers') || {};
      return Object.values(signers)
        .compact()
        .getEach('email')
        .every(Boolean);
    }
  ),

  signerList: computed('esignedDocument.esignatures.signer', function() {
    const signers = get(this, 'esignedDocument.esignatures').getEach('signer');
    return signers
      .map(s => `${s.get('firstName')} ${s.get('lastName')}`.trim())
      .join(', ');
  }),

  formDownloadUrl: computed('esignedDocument.id', function() {
    return `${
      config.host
    }/api/esigned_forms/${get(this, 'esignedDocument.id')}/download`;
  }),

  documentType: reads('esignedDocument.constructor.modelName'),

  statusClass: computed('esignedDocument.esignatureStatus', function() {
    switch (get(this, 'esignedDocument.esignatureStatus')) {
      case 'pending':
        return 'yellow';
      case 'complete':
        return 'green';
      case 'declined':
        return 'red';
      default:
        return '';
    }
  }),

  actions: {
    selectSigner(fieldName, esignedDocument, contact) {
      if (!esignedDocument.get('signers')) {
        esignedDocument.set('signers', {});
      }

      esignedDocument.set(`signers.${fieldName}`, contact);
      esignedDocument.notifyPropertyChange('signers');
    },

    removeSigner(fieldName, esignedDocument) {
      if (esignedDocument.get('signers')) {
        esignedDocument.set(`signers.${fieldName}`, null);
        esignedDocument.notifyPropertyChange('signers');
      }
    },

    save(esignedDocument) {
      this.validate().then(({ validations }) => {
        const errors = validations.get('errors');

        if (errors.length > 0) {
          set(this, 'showValidation', true);
        } else {
          set(this, 'showValidation', false);
          const signers = esignedDocument.get('signers');

          this.ensureEsignedDocumentSaved(esignedDocument).then(
            esignedDocument => {
              const esignatures = this.esignaturesFromSignersObj(
                signers,
                esignedDocument
              );

              const savePromises = esignatures.map(esignature =>
                esignature.save()
              );

              Promise.all(savePromises).then(() => {
                this.reload();
              });
            }
          );
        }
      });
    },

    cancelEditing(esignedDocument) {
      if (esignedDocument.get('isNew')) {
        this.store.unloadRecord(esignedDocument);
      } else {
        esignedDocument.set('signers', {});
      }

      this.showEdit(null);
    },

    removeEsignature(esignature) {
      esignature.destroyRecord().then(this.reload);
    },

    saveContact(fieldName) {
      get(this, `esignedDocument.signers.${fieldName}`).save();
      set(this, '_observer', this._observer + 1);
    }
  },

  ensureEsignedDocumentSaved(esignedDocument) {
    if (esignedDocument.get('isNew')) {
      // Saving `esignedDocument` directly doesn't work for some reason. The
      // resulting, supposedly saved, model doesn't have an id, shows as
      // `isDirty`, and as `isNew`. ... Ask Ember, 'cause I can't explain it
      let newDocument;
      if (esignedDocument.constructor.modelName === 'esigned-form') {
        newDocument = this.store.createRecord('esignedForm', {
          kaseForm: esignedDocument.get('kaseForm'),
          remainingSignatureFieldNames: esignedDocument.get(
            'remainingSignatureFieldNames'
          )
        });
      } else {
        newDocument = this.store.createRecord('esignedContract', {
          selection: esignedDocument.get('selection'),
          contractTemplate: esignedDocument.get('contractTemplate'),
          remainingSignatureFieldNames: esignedDocument.get(
            'remainingSignatureFieldNames'
          )
        });
      }
      esignedDocument.rollbackAttributes();

      return newDocument.save();
    } else {
      return Promise.resolve(esignedDocument);
    }
  },

  esignaturesFromSignersObj(signers, esignedDocument) {
    const esignatures = {};

    Object.keys(signers).forEach(fieldName => {
      if (signers[fieldName]) {
        const signerId = signers[fieldName].get('id');

        if (esignatures[signerId]) {
          esignatures[signerId].get('signatureFieldNames').push(fieldName);
        } else {
          esignatures[signerId] = this.store.createRecord('esignature', {
            signatureFieldNames: [fieldName],
            signer: signers[fieldName],
            esignedDocument
          });
        }
      }
    });

    return Object.values(esignatures);
  }
});
