import Mixin from '@ember/object/mixin';
import { isEqual } from 'lodash-es/lang';

const mapById = function(member) {
  return member.id;
};

export default Mixin.create({
  changedRelationships() {
    // Ids are always undefined until initialized
    if (this.get('_internalModel.dataHasInitialized') === false) {
      return;
    }

    const relationships = {};

    this.eachRelationship((name, meta) => {
      const basePath = `_internalModel.__recordData._relationships.initializedRelationships.${name}`;
      const hasCanonical =
        this.get(`${basePath}.canonicalMembers.list.length`) > 0;

      if (meta.kind === 'belongsTo') {
        const newId = this.get(`${name}.id`);

        let initialId;
        if (hasCanonical) {
          const firstObject = this.get(`${basePath}.canonicalMembers.list`)[0];
          if (firstObject) {
            initialId = firstObject.id;
          }
        }

        if (initialId !== newId) {
          relationships[name] = [initialId, newId];
        }
      } else if (meta.kind === 'hasMany') {
        const newIds = this.get(`${basePath}.members.list`).map(mapById);

        let initialIds;
        if (hasCanonical) {
          initialIds = this.get(`${basePath}.canonicalMembers.list`).map(
            mapById
          );
        } else {
          initialIds = [];
        }

        if (!isEqual(initialIds, newIds)) {
          relationships[name] = [initialIds, newIds];
        }
      }
    });

    return relationships;
  }
});
