import Controller from '@ember/controller';
import { get, computed } from '@ember/object';
import moment from 'moment';

export default class CemsitesController extends Controller {
  @computed('model.cemsitesExport.updatedAt')
  get formattedCemsitesExportedAt() {
    return moment(this.model.cemsitesExport.updatedAt).format('LLL');
  }

  @computed('model.kase.deceased.profileDeathDate')
  get invalidDeathDate() {
    // eslint-disable-next-line ember/no-get
    return get(this, 'model.kase.deceased.profileDeathDate') > moment();
  }
}