import GCraknFileUploadComponent from '../crakn/file-upload';

export default class GCraknFileUploadThemesComponent extends GCraknFileUploadComponent {
  layoutName = 'components/g/crakn/file-upload';

  constructor(owner, args) {
    super(owner, args);
  }

  validateFile(file) {
    const passedParentValidation = super.validateFile(file);
    if (!passedParentValidation) {
      return;
    }

    if (this.args.nameValidationCheck) {
      const nameIsvalid = this.args.nameValidationCheck(this.args.customThemeName);
      if (!nameIsvalid) {
        this.notify(
          'error',
          'Filename can only container letters, numbers, and underscores.'
        );
        return false;
      }
    }

    return true;
  }

}
