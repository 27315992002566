import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AccessibleClientsService extends Service {
  @service api;
  @service store;
  @service session;

  @tracked clients = null;
  @tracked recentClients = null;

  get all() {
    if (this.clients === null) {
      this.getAccessibleClients();
    }

    return this.clients
  }

  get recentlyAccessedClients() {
    if (this.recentClients === null) {
      this.getRecentClients();
    }

    return this.recentClients
  }

  unloadAll() {
    this.clients = null;
  }

  async getAccessibleClients() {
    if (this.session.currentClient) {
      const resp = await this.api.json.get('v2/clients/accessible_clients')
      if (resp.ok && resp.parsedJson?.clients?.length > 1) {
        const filteredClients = resp.parsedJson.clients.filter(c => c.id !== this.session.currentClient.id);
        this.clients = filteredClients;
      }
    }
  }

  async getRecentClients() {
    if (this.session.currentClient) {
      const resp = await this.api.json.get('v2/clients/recent_accessible_clients')
      if (resp.ok && resp.parsedJson?.clients?.length > 1) {
        const filteredClients = resp.parsedJson.clients.filter(c => c.id !== this.session.currentClient.id);
        this.recentClients = filteredClients;
      }
    }
  }
}
