import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class FloridaCineratorReport extends Component {
  @tracked model = null;
  @tracked timeFrame = null;
  @tracked organizationLocationId = null;

  get formattedTimeFrame() {
    const timeFrame = this.timeFrame;
    if (typeof timeFrame === 'string') {
      return timeFrame;
    } else if (timeFrame === null) {
      return null;
    } else {
      return timeFrame.toISOString();
    }
  }
  
  get reportLink() {
    const params = new URLSearchParams({
      organization_location_id: this.organizationLocationId,
      year_month: this.formattedTimeFrame,
      uuid: this.args.uuid
    }).toString();
    return `/report/florida_cinerator_report?${params}`;
  }

  get fileUrl() {
    return `#/generated_document/${this.args.uuid}`;
  }

  @action
  generateReport() {
    this.args.onGenerateReport(this.reportLink);
  }

  @action
  handleTimeFrameChange(newTimeFrame) {
    this.timeFrame = newTimeFrame;
  }
}
