import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'account.id': validator('presence', {
    presence: true,
    message: 'The Account field can\'t be blank'
  }),
  'name': validator('presence', {
    presence: true,
    message: 'The Name field can\'t be blank'
  }),
});

export default class V2InterestTypeModel extends Model.extend(Validations) {
  @attr('string') name;
  @attr('string') netsuiteId;

  @belongsTo('v2/account') account;
  @belongsTo('v2/chartOfAccounts') chartOfAccounts;
}
