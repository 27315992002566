import Controller from '@ember/controller';
import dispositionMethods from 'crakn/utils/disposition-methods';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminDataCaseTagsController extends Controller {
  @service store;
  @service flashes;

  @tracked newCaseTagName;
  @tracked locations = [];
  @tracked page = 1;

  queryParams = ['page'];

  get dispositionMethods() {
    return dispositionMethods;
  }

  @action
  create() {
    if (!this.newCaseTagName) {
      return 
    }
    
    const record = this.store.createRecord('v2/tag', { name: this.newCaseTagName } )
    this.newCaseTagName = null;

    record.save()
          .then(() => {
            this.flashes.addSuccess('Tag successfully saved!');
            this.send('refreshModel');
          });
  }

  @action 
  save(record) {
    record.save()
          .then(() => {
            this.flashes.addSuccess('Tag successfully saved!');
            this.send('refreshModel');
          })
          .catch(() => {
            this.error();
          });
  }

  @action 
  delete(record) {
    record.destroyRecord()
          .then(() => {
            this.flashes.addSuccess('Tag successfully destroyed!');
            this.send('refreshModel');
          })
          .catch(() => {
            this.error();
          });
  }

  @action 
  cancel(record) {
    record.rollbackAttributes();
  }

  @action 
  error() {
    this.flashes.addError('An error occured while saving.');
  }
}
