import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking'

export default class AdminFormsController extends Controller {
  @service api;
  @service flashes;
  @service intl;
  @service session;

  @tracked formQuery = '';

  queryParams = ['page'];
  page = 1;
  formSorting = ['name:asc'];
  @sort('model', 'formSorting') forms;

  @action
  search(query) {
    return this.store.query('blankForm', { query })
  }

  @action
  remove(form) {
    const clientName = this.session.currentClient.name;

    this.api.json
        .delete(
          `blank_forms/${form.id}/remove_client_from_form`,
          {
            body: { client_name: clientName }
          }
        )
        .then(response => {
          if (response.ok) {
            this.flashes.addSuccess(this.intl.t('authenticated.admin.manage.forms.messages.success.delete'));
            this.send('refreshModel');
          } else {
            this.flashes.addError(this.intl.t('authenticated.admin.manage.forms.messages.error'));
          }
        });
  }

  @action
  add(form) {
    const clientId = this.session.currentClient.id;

    if (this.forms.includes(form)) {
      return;
    }

    this.api.json
        .post(
          `blank_forms/${form.id}/add_form_to_client`,
          {
            body: { client_id: clientId }
          }
        )
        .then(response => {
          if (response.ok) {
            this.flashes.addSuccess(this.intl.t('authenticated.admin.manage.forms.messages.success.save'));
            this.formQuery = '';
            this.send('refreshModel');
          } else {
            this.flashes.addError(this.intl.t('authenticated.admin.manage.forms.messages.error'));
          }
        });
  }
}
