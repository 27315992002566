import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminGeneralPriceListsEditPriceListDatesController extends Controller {
  @service flashes;

  @action
  save() {
    this.model.save().then(() => {
      this.flashes.addSuccess('Success! The dates have been saved.')
    }).catch(() => {
      this.flashes.addError('Oops! There was an issue when attempting to save the dates.')
    });
  }

  @action
  cancel() {
    this.model.rollbackAttributes();
  }
}
