import Model, { attr, belongsTo } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: validator('presence', true),
});

export default class V2PersonalEffectModel extends Model.extend(Validations) {
  @attr('string') name;
  @attr('string') description;
  @attr('moment-datetime') archived_at;

  @belongsTo('v2/kase', { async: true }) kase;
}
