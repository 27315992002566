import { hasMany, belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import BasePackage from 'crakn/models/base-package';
import { cleanPrice } from 'crakn/utils/data-helpers';

const Validations = buildValidations({
  'invoiceCategoryId.id': validator('presence', true),
});

export default class V2SelectionPackageModel extends BasePackage.extend(
  Validations
) {
  @attr('array') customCategoryFlexibleItems;
  @attr('array') itemProducts;

  @attr('boolean') exported;
  @attr('boolean') hasFlexibleItemPackageSlots;
  @attr('boolean') selectionsRequired;

  @attr('moment-datetime') createdAt;

  @attr('number', { defaultValue: 1 }) quantity;

  @attr('price') discount;
  @attr('price') subTotal;
  @attr('price') total;

  @attr('string') notes;

  @belongsTo('invoiceCategory', { async: true }) invoiceCategoryId;
  @belongsTo('v2/package', { async: true }) package;
  @belongsTo('v2/selection', { async: true, polymorphic: true }) selection;

  @hasMany('v2/product') products;
  @hasMany('v2/selection-package-slot') selectionPackageSlots;
  @hasMany('v2/selection-package-slot-product') selectionPackageSlotProducts;

  get multipliedTotal() {
    return cleanPrice(this.quantity * this.total);
  }

  get hasFlexibleProductsSelected() {
    return this.customCategoryFlexibleItems.every((item) => {
      return item.products.length > 0;
    });
  }
}
