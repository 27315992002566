import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex\">\n  <div class=\"c--xs--12 text--r\">\n    <button\n        data-test=\"add-new-integration\"\n        type=\"button\"\n        class=\"print-kase btn btn--link strong\"\n        {{on \"click\" this.toggleAddMode}}>\n      + {{t \"authenticated.admin.integrations.asd.buttons.linkASDAccount\"}}\n    </button>\n  </div>\n</div>\n\n{{#liquid-if this.addMode}}\n  <div class=\"c--xs--12\">\n    <G::Crakn::Integrations::Asd::LinkAccount\n        @client={{@client}}\n        @existingIntegration={{@model}}\n        @refreshModel={{@refreshModel}}/>\n  </div>\n{{/liquid-if}}\n\n{{#each @model as |integrationService|}}\n  <G::Crakn::Integrations::Asd::IntegrationServiceInformation\n      @model={{integrationService}}\n      @refreshModel={{@refreshModel}}\n      @locations={{@locations}}\n      @locationMetas={{@locationMetas}}/>\n  <hr>\n{{/each}}\n", {"contents":"<div class=\"flex\">\n  <div class=\"c--xs--12 text--r\">\n    <button\n        data-test=\"add-new-integration\"\n        type=\"button\"\n        class=\"print-kase btn btn--link strong\"\n        {{on \"click\" this.toggleAddMode}}>\n      + {{t \"authenticated.admin.integrations.asd.buttons.linkASDAccount\"}}\n    </button>\n  </div>\n</div>\n\n{{#liquid-if this.addMode}}\n  <div class=\"c--xs--12\">\n    <G::Crakn::Integrations::Asd::LinkAccount\n        @client={{@client}}\n        @existingIntegration={{@model}}\n        @refreshModel={{@refreshModel}}/>\n  </div>\n{{/liquid-if}}\n\n{{#each @model as |integrationService|}}\n  <G::Crakn::Integrations::Asd::IntegrationServiceInformation\n      @model={{integrationService}}\n      @refreshModel={{@refreshModel}}\n      @locations={{@locations}}\n      @locationMetas={{@locationMetas}}/>\n  <hr>\n{{/each}}\n","moduleName":"crakn/components/g/crakn/integrations/asd/main-setup.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/integrations/asd/main-setup.hbs"}});
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class GCraknIntegrationsAsdMainSetupComponent extends Component {
  @tracked addMode = false;

  @action
  toggleAddMode() {
    set(this, 'addMode', !this.addMode);
  }
}
