import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CraknClientsEditController extends Controller {
  @service flashes;
  @service location;
  @service router;

  handleSaveSuccess() {
    this.flashes.addSuccess('Client saved!');
  }

  handleSaveError() {
    this.flashes.addError('Oops! There was an error saving this client.');
  }

  @action
  save() {
    if (this.client.hasDirtyAttributes) {
      this.client
        .save()
        .then(() => this.handleSaveSuccess())
        .catch(() => this.handleSaveError());
    }
  }

  @action
  cancelEditClient() {
    if (this.client.hasDirtyAttributes) {
      this.client.rollbackAttributes();
    }
  }
}
