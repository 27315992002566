import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';

export default class ClientRoutingService extends Service {
  @service session;

  transitionArray = [
    'authenticated.kases.manage.edit.info',
    'authenticated.kases.manage.edit.referral-info',
    'authenticated.kases.manage.edit.preneed',
    'authenticated.kases.manage.edit.deceased',
    'authenticated.kases.manage.edit.transportation',
    'authenticated.kases.manage.edit.preparations',
    'authenticated.kases.manage.edit.disposition',
    'authenticated.kases.manage.edit.family-and-friends',
    'authenticated.kases.manage.edit.family-portal',
    'authenticated.kases.manage.edit.veteran-info',
    'authenticated.kases.manage.edit.events',
    'authenticated.kases.manage.edit.service-info',
    'authenticated.kases.manage.edit.inquiries',
    'authenticated.kases.manage.documents',
    'authenticated.kases.manage.edit.printables',
    'authenticated.kases.manage.edit.obituary'
  ];

  @alias(
    'session.currentClient.can_view_preneed_section'
  ) canViewPreneedSection;

  @alias(
    'session.currentClient.can_view_referral_info_section'
  ) canViewReferralInfoSection;

  @alias(
    'session.currentClient.can_view_transportation_section'
  ) canViewTransportationSection;

  @alias(
    'session.currentClient.can_view_preparations_section'
  ) canViewPreparationsSection;

  @alias('session.currentClient.can_view_family_portal') canViewFamilyPortal;

  @alias(
    'session.currentClient.can_view_veteran_info_section'
  ) canViewVeteranInfoSection;

  @alias('session.currentClient.can_view_service_info_section')
  canViewServiceInfoSection;

  @alias('session.currentClient.can_view_inquiries_section')
  canViewInquiriesSection;

  @alias('session.currentClient.can_view_documents_section')
  canViewDocumentsSection;

  @alias('session.currentClient.can_view_printables_section')
  canViewPrintablesSection;

  @alias('session.currentClient.can_view_obituary_section')
  canViewObituarySection;

  @computed(
    'transitionArray',
    'canViewPreneedSection',
    'canViewReferralInfoSection',
    'canViewTransportationSection',
    'canViewPreparationsSection',
    'canViewFamilyPortal',
    'canViewVeteranInfoSection',
    'canViewServiceInfoSection',
    'canViewInquiriesSection',
    'canViewDocumentsSection',
    'canViewPrintablesSection',
    'canViewObituarySection')
  get clientAllowedArray() {
    const transitionArray = this.transitionArray;

    if (!this.canViewPreneedSection) {
      const preneedValue = transitionArray.findIndex(
        name => name === 'authenticated.kases.manage.edit.preneed'
      );
      transitionArray.splice(preneedValue, 1);
    }

    if (!this.canViewReferralInfoSection) {
      const referralValue = transitionArray.findIndex(
        name => name === 'authenticated.kases.manage.edit.referral-info'
      );
      transitionArray.splice(referralValue, 1);
    }

    if (!this.canViewTransportationSection) {
      const transportationValue = transitionArray.findIndex(
        name => name === 'authenticated.kases.manage.edit.transportation'
      );
      transitionArray.splice(transportationValue, 1);
    }

    if (!this.canViewPreparationsSection) {
      const preparationsValue = transitionArray.findIndex(
        name => name === 'authenticated.kases.manage.edit.preparations'
      );
      transitionArray.splice(preparationsValue, 1);
    }

    if (!this.canViewVeteranInfoSection) {
      const veteranInfoValue = transitionArray.findIndex(
        name => name === 'authenticated.kases.manage.edit.veteran-info'
      );
      transitionArray.splice(veteranInfoValue, 1);
    }

    if (!this.canViewFamilyPortal) {
      const familyPortalValue = transitionArray.findIndex(
        name => name === 'authenticated.kases.manage.edit.family-portal'
      );
      transitionArray.splice(familyPortalValue, 1);
    }

    if (!this.canViewServiceInfoSection) {
      const serviceInfoValue = transitionArray.findIndex(
        (name) => name === 'authenticated.kases.manage.edit.service-info'
      );
      transitionArray.splice(serviceInfoValue, 1);
    }

    if (!this.canViewInquiriesSection) {
      const inquiriesValue = transitionArray.findIndex(
        (name) => name === 'authenticated.kases.manage.edit.inquiries'
      );
      transitionArray.splice(inquiriesValue, 1);
    }

    if (!this.canViewDocumentsSection) {
      const documentsValue = transitionArray.findIndex(
        (name) => name === 'authenticated.kases.manage.edit.documents'
      );
      transitionArray.splice(documentsValue, 1);
    }

    if (!this.canViewPrintablesSection) {
      const envelopesValue = transitionArray.findIndex(
        (name) => name === 'authenticated.kases.manage.edit.printables'
      );
      transitionArray.splice(envelopesValue, 1);
    }

    if (!this.canViewObituarySection) {
      const obituaryValue = transitionArray.findIndex(
        (name) => name === 'authenticated.kases.manage.edit.obituary'
      );
      transitionArray.splice(obituaryValue, 1);
    }

    return transitionArray;
  }

  manageRouteTransition(value) {
    const clientRoutes = this.clientAllowedArray;
    const currentRoute = clientRoutes.findIndex(
      clientRouteValue => clientRouteValue === value
    );

    return clientRoutes[currentRoute + 1];
  }
}
