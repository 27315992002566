import Model, { belongsTo, attr } from '@ember-data/model';
import {cleanPrice} from 'crakn/utils/data-helpers'

export default class CostChangeModel extends Model {
  @attr('cost') newCost;
  @attr('cost') previousCost;
  @attr('moment-datetime') createdAt;

  @belongsTo('product') product;

  get totalChange() {
    return cleanPrice((this.newCost - this.previousCost), 2)
  }
}
