import { sort } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';

export default class ProductCategoriesService extends Service {
  @service store;
  productCategories = null;

  get all() {
    if (this.productCategories == null) {
      this.productCategories = this.store.query('productCategory', {
        archived: false,
        per_page: 1000
      });
    }

    return this.productCategories;
  }

  unloadAll() {
    this.productCategories = null;
  }

  getProductCategories(gplId) {
    return this.store.query('productCategory', {
      general_price_list_id: gplId,
      archived: false,
      per_page: 1000
    });
  }

  productCategoryNameSort = ['name:asc'];
  @sort('all', 'productCategoryNameSort') allSorted;
}
