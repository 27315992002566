import Model, { attr, belongsTo } from '@ember-data/model';

const defaultRevisionMetadata = () => {
  return {
    esignEnabled: false,
    eventDropdown: false,
    inquiryDropdown: false,
    kaseContactDropdown: false,
    locationDropdown: false
  }
}

export default class TfeLatestRevisionModel extends Model {
  @attr('object', { defaultValue: () => defaultRevisionMetadata() }) metadata;
  @attr('string') status;
  @attr('boolean', { defaultValue: false }) esignable;

  @belongsTo('tfe/document') document;
}
