import { readOnly } from '@ember/object/computed';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import moment from 'moment';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  endPlace: validator('presence', true),
  startPlace: {
    validators: [validator('presence', true)],
    disabled: readOnly('model.startPlaceIsDecedentsPlaceOfDeath'),
  },
});

export default class V2CustodyAuditModel extends Model.extend(Validations) {
  @attr('string') notes;
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;
  @attr('boolean', { defaultValue: false }) startPlaceIsDecedentsPlaceOfDeath;
  @attr('boolean', { defaultValue: true }) isManualEntry;

  @belongsTo('v2/kase', { async: true }) kase;
  @belongsTo('v2/user', { async: true }) enteredBy;
  @belongsTo('v2/place', { async: true }) startPlace;
  @belongsTo('v2/room', { async: true }) startRoom;
  @belongsTo('v2/place', { async: true }) endPlace;
  @belongsTo('v2/room', { async: true }) endRoom;

  @hasMany('v2/personalEffect') personalEffects;
}
