import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Autocomplete::Dynamic\n    {{! template-lint-disable attribute-indentation }}\n    @dataTest=\"batch-payment-kase-select\"\n    @placeholder={{@placeholder}}\n    @debounce={{@debounce}}\n    @value={{@value}}\n    @disabled={{@disabled}}\n    @icon={{@icon}}\n    @onSearch={{this.search}}\n    @onSelect={{@onSelect}}\n    @onInput={{noop}}\n    @onIconClick={{@onIconClick}}\n    @onDirtyFocusOut={{@onDirtyFocusOut}}\n    as |item|>\n  <div>\n    <div class=\"light m--xs--b\">Case</div>\n    <div class=\"tt-suggestion__title\">{{item.typeaheadName}}</div>\n    {{#if item.email}}\n      <div>{{item.email}}</div>\n    {{/if}}\n\n    {{#if item.phonePrimary}}\n      <div>{{item.phonePrimary}}</div>\n    {{/if}}\n  </div>\n</G::Crakn::Autocomplete::Dynamic>\n", {"contents":"<G::Crakn::Autocomplete::Dynamic\n    {{! template-lint-disable attribute-indentation }}\n    @dataTest=\"batch-payment-kase-select\"\n    @placeholder={{@placeholder}}\n    @debounce={{@debounce}}\n    @value={{@value}}\n    @disabled={{@disabled}}\n    @icon={{@icon}}\n    @onSearch={{this.search}}\n    @onSelect={{@onSelect}}\n    @onInput={{noop}}\n    @onIconClick={{@onIconClick}}\n    @onDirtyFocusOut={{@onDirtyFocusOut}}\n    as |item|>\n  <div>\n    <div class=\"light m--xs--b\">Case</div>\n    <div class=\"tt-suggestion__title\">{{item.typeaheadName}}</div>\n    {{#if item.email}}\n      <div>{{item.email}}</div>\n    {{/if}}\n\n    {{#if item.phonePrimary}}\n      <div>{{item.phonePrimary}}</div>\n    {{/if}}\n  </div>\n</G::Crakn::Autocomplete::Dynamic>\n","moduleName":"crakn/components/g/crakn/autocomplete/batch-payments/kase.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/autocomplete/batch-payments/kase.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object'
import { inject as service } from '@ember/service';

/**
 * An autocomplete input that draws from deceased to find a kase
 * 
 * ## Arguments
 * - **placeholder** - *optional* the label for the autocomplete
 * - **value** - *optional* *default empty* the input value
 * - **disabled** - *optional* *default false* boolean for disabling the input
 * - **icon** - *optional* the icon to display in the right of the autocoomplete.  will not display
 *              without args.onIconClick
 * - **debounce** - *optional* *default 300* the delay in milliseconds between finish typing and the 
 *                  search function occuring
 * - **queryParams** - *optional* *default {}* additional params to send along with the 
 *                     query to the server
 * - **onSelect** - the function to run when an item in the dropdown is selected.  If this 
 * 									function returns a value, it will be assigned to this.value
 * - **onIconClick** - *optional* the function to run when the icon is clicked.  will not work without
 *                    args.icon
 * - **onDirtyFocusOut** - *optional* the function to run when the input has been interacted with 
 * 										(meaning the autocomplete is open), and the user clicked outside the provided 
 * 										dropdown
 * 
 * ## Useage
 * 
 * ### Non-Strict Mode
 * This Autocomplete only allow strict mode
 * 
 * ### Strict Mode
 * Anything typed into the input will be ignored.  Only values selected in the dropdown will be 
 * considered valid.  Navigating away from the input with an invalid value will revert the value to the 
 * last valid value or the original value
 * 
 * ```handlebars
 * <G::Crakn::Autocomplete::Kase
 * 		 @placeholder="Select A Case"
 *     @value={{model.kase.deceased.typeaheadName}}
 * 		 @onSelect={{set model "kase"}}
 * 		 @onDirtyFocusOut={{this.toggleCreateNew}} />
 * 
 * ```
 * 
 * @class GCraknAutocompleteKase
 * @public
 */

export default class GCraknAutocompleteBatchPaymentKase extends Component {
  /**
   * Ember data store
   * @private
   */
  @service store;

  /**
   * The additional parameters used on the query
   * @type {object}
   */
  get queryParams() {
    return this.args.queryParams || {};
  }

	/**
	 * The function used to search the optimized list.
	 * 
	 * @param {*} val - the search query
	 */
	@action
	search(query) {
    return this.store
               .query('v2/person', {
                 per_page: 5,
                 ...this.queryParams,
                 has_kase_balance: true,
                 has_kase: true,
                 kase_query: query,
                 location_id: this.args.locationId
               });
	}
}
