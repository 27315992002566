import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KasesEditReferralInfoRoute extends Route {
  @service clientRouting;
  layout = 'custom-aside';

  model() {
    const kase = this.modelFor('authenticated.kases.manage');

    return this.store
      .queryRecord('referralInfo', { kase_id: kase.get('id') })
      .then(result => {
        return result || this.store.createRecord('referralInfo', { kase });
      });
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('kase', this.modelFor('authenticated.kases.manage'));
  }

  @action
  willTransition() {
    this.currentModel.save();
  }
}
