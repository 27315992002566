import Component from '@glimmer/component';
import {action} from '@ember/object';

export default class CheckDuplicateContactComponent extends Component {
  @action
  deleteAndReplace(person) {
    if (this.args.deleteAndReplace) {
      this.args.deleteAndReplace(person);
    }
  }
}
