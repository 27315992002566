import { inject as service } from '@ember/service';
import Route from '@ember/routing/route'

export default class AuthenticatedAdminManageLogosRoute extends Route {
  @service session;
  @service router;

  beforeModel() {
    if (this.session.currentUser.isSuperuser) {
      return true;
    }
    // Non-Superusers are redirected to the dashboard
    this.router.transitionTo('authenticated.dashboard');
  }

  model() {
    const clientInfoId = this.session.currentClient.client_info_id;

    return this.store.findRecord('v2/clientInfo', clientInfoId, {
      include: 'default-crem-auth-header,default-sfgs-logo,default-receipt-logo,default-soa-logo'
    });
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.set('logos', this.store.findAll('v2/logo'));
  }
}
