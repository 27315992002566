import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Component.extend({
  didReceiveAttrs() {
    this._super(...arguments);

    const kase = this.kase;

    this.setProperties({
      selection: this.store.queryRecord('selection', {
        kase_id: get(kase, 'id'),
        is_contract: true,
        type: 'KaseSelection'
      }),
      kase
    });
  },

  tagName: 'aside',
  classNames: ['container__aside'],
  selection: null,
  clientInfo: null,
  kase: null,

  session: service(),
  store: service()
});
