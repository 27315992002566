import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';
import { tracked } from '@glimmer/tracking';

const Validations = buildValidations({
  'model.name': validator('presence', true),
});

export default class AdminVehiclesEditController extends Controller.extend(Validations) {
  @service flashes;
  @service store;

  @tracked showValidations = false;
  @tracked newLocation = null;

  @action
  handleError(message = 'Oh No! There was an error.') {
    this.flashes.addError(message);
  }

  @action
  handleSuccess() {
    this.flashes.addSuccess('Successfully saved!');
  }

  @action
  save() {
    this.validate().then(() => {
      if (this.validations.isValid) {
        this.showValidations = false;
        this.model
          .save()
          .then(() => {
            this.handleSuccess();
            this.transitionToRoute('authenticated.admin.manage.vehicles');
          })
          .catch(() => {
            this.handleError();
          });
      } else {
        this.showValidations = true;
      }
    });
  }

  @action
  setLocation(locationId) {
    if (locationId == '') {
      return (this.newLocation = null);
    }

    this.newLocation = this.store.peekRecord('location', locationId);
  }

  @action
  async addLocation() {
    const usedLocations = await Promise.all(
      this.model.get('locationVehicles').map((lv) => lv.location)
    );

    if (usedLocations.includes(this.newLocation)) {
      return this.handleError('Whoops! That vehicle is already assigned to this location.');
    } else {
      this.store
          .createRecord('locationVehicle', { vehicle: this.model, location: this.newLocation })
          .save()
          .then(this.handleSuccess)
          .catch(this.handleError);
    }
  }

  @action
  removeLocation(locationVehicle) {
    locationVehicle
      .destroyRecord()
      .then(this.handleSuccess)
      .catch(this.handleError);
  }
  
  @action
  cancelEdit() {
    if (this.model.hasDirtyAttributes) {
      this.model.rollbackAttributes();
    }
    this.transitionToRoute('authenticated.admin.manage.vehicles');
  }
}
