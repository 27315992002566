import Route from '@ember/routing/route';
// import moment from 'moment';
import { inject as service } from '@ember/service';

export default class AuthenticatedPaymentsPayoutsRoute extends Route {
  @service api;
  @service router;
  @service store;

  queryParams = {
    integration_service_id: {
      refreshModel: true
    },
    min_amount: {
      refreshModel: true
    },
    max_amount: {
      refreshModel: true
    },
    end_date: {
      refreshModel: true
    },
    start_date: {
      refreshModel: true
    },
    sort_by: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    }
  }

  async beforeModel() {
    const tpAccounts = await this.store.query('integration-service', {
      name: 'tribute_pay_account',
      location_based: true
    });

    if (tpAccounts.length <= 0) {
      this.router.transitionTo('authenticated.payments.transactions');
    }
  }

  model(params) {
    return this.api.json.get('tribute_pay/v1/fetch_payouts', { params }).then((response) => {
      return response.parsedJson;
    })
  }
  
  setupController(controller) {
    super.setupController(...arguments);

    this.store.query('integration-service', {
      name: 'tribute_pay_account',
      location_based: true,
      include_archived: false
    }).then((tpAccounts) => {
      controller.set('tpAccounts', tpAccounts);
    })
  }
}
