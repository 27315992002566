import Component from '@ember/component';
import { computed } from '@ember/object';
import FormInput from 'crakn/mixins/form-input';
import { dasherize } from '@ember/string';

export default Component.extend(FormInput, {
  classNames: ['input'],
  classNameBindings: ['hasValue:input--has-value', 'styleClass'],

  type: 'text',
  style: 'standard',
  disabled: false,
  focusOut: () => {},
  onenter: () => {},
  inputType: null,
  leftIcon: null,
  rightIcon: null,
  inputField: null,
  autofocus: false,

  didInsertElement() {
    if (this.autofocus) {
      this.element.querySelector('input').focus();
    }
  },

  hasValue: computed('value', function() {
    const value = this.value;

    return !!value || value === 0;
  }),

  styleClass: computed('style', function() {
    const style = this.style;

    return `input--${style}`;
  }),

  qaClass: computed('placeholder', function() {
    const placeholder = this.placeholder;

    return dasherize(`qa-${placeholder}`);
  }),

  init() {
    this._super(...arguments);

    const inputField = 'one-way-input';
    let inputType = this.type;
    let leftIcon, rightIcon;

    switch (this.type) {
      case 'percent':
        inputType = 'tel';
        rightIcon = 'percent';
        break;
    }

    this.setProperties({
      leftIcon,
      rightIcon,
      inputField,
      inputType
    });
  }
});
