import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
export default class AdminFinancialConfigurationsEditBankAccountsNewController extends Controller {

    /**
     * These properties need to be modified in CB-10357 to correspond with 
     * actual record attributes once 'Add New Bank Account - POST API' is completed.
     */
    @tracked bankAccountName;
    @tracked bankAccountNumber;
    @tracked bankName;
    @tracked bankRoutingNumber;
    @tracked generalLedgerAccountId;

    @action
    changeGLAccount(GLAccountId) {
        set(this, 'generalLedgerAccountId', GLAccountId)
    }
}

