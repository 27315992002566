import Route from '@ember/routing/route'

export default class AdminLocationsEditSpecialTaxesRoute extends Route {
  model() {
    return this.modelFor('authenticated.admin.locations.edit');
  }

  setupController(_controller, model) {
    super.setupController(...arguments);

    this.store.query('v2/specialTax', { chartOfAccountsId: model.get('generalPriceList.chartOfAccounts.id') });
  }
}