import Controller from '@ember/controller';
import { action, setProperties } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
export default class AuthenticatedAdminTributePayNotificationSettingsController extends Controller {
  @service flashes;
  @service intl;
  @service store;
  @service session;

  queryParams = ['query, organization_location_id, page'];

  @tracked isEditingNewRecipient = false;
  @tracked page = 1;
  @tracked selectedRecipient;

  @action
  searchStaffMember(input) {
    return this.store.query('v2/user', {
      client_id: this.session.currentClient.id,
      query: input,
      user_notifications: false
    });
  }

  @action
  cancelAddingRecipient() {
    setProperties(this, {
      selectedRecipient: null,
      isEditingNewRecipient: false
    });
  }

  @action
  saveNewRecipient() {
    const newUserNotification = this.store.createRecord('v2/user-notification', {
      userNotificationType: 'payment',
      user: this.selectedRecipient,
      client: this.session.currentClient
    });

    newUserNotification.save().then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.admin.tribute-pay.notification-settings.alerts.userNotification.create.success'));

      this.cancelAddingRecipient();

      this.refresh();
    }).catch(() => {
      this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay.notification-settings.alerts.userNotification.create.error'));
    })
  }

  @action
  resetFilters() {
    setProperties(this, {
      organization_location_id: '',
      query: ''
    });
  }

  @action
  refresh() {
    this.send('refreshModel');
  }
}
