import Route from '@ember/routing/route';
import RSVP from 'rsvp';

export default class AuthenticatedCraknClientsNewRoute extends Route {
  controllerName = 'authenticated.crakn.clients.edit';
  templateName = 'authenticated.crakn.clients.edit';

  model(params) {
    return RSVP.hash({
      client: this.store.createRecord('client', {
        name: params.name
      }),
      reportConfig: this.store.createRecord('reportConfig', {}),
      clientNote: this.store.createRecord('clientNote', {})
    });
  }

  setupController(controller, models) {
    super.setupController(...arguments);
    this.store.createRecord('v2/contractTemplate', { client: models.client, displayName: 'Landscape', templateName: 'Landscape' });
    const validationConfig = this.store.createRecord('v2/selectionValidationsConfig', { client: models.client });
    controller.set('selectionValidationsConfig', validationConfig)
    controller.setProperties(models);
  }
}
