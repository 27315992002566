import ApplicationAdapter from 'crakn/adapters/application';
export default ApplicationAdapter.extend({
  people(model, params) {
    const url = `${this.buildURL('kase', model.get('id'))}/people`;
    return this.ajax(url, 'GET', { data: params });
  },

  identify(model) {
    const url = `${this.buildURL('kase', model.id)}/identify`;
    return this.ajax(url, 'PUT');
  }
});
