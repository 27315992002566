import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class KasesManageEditCustomFieldsController extends Controller {
  @service intl;
  @service flashes;

  get disableEdit() {
    if (this.session.currentUser.permissions <= 1 ) {
      return true;
    } else {
      return false;
    }
  }

  @action
  save() {
    this.kaseCustomFields
      .save()
      .then(() =>
        this.flashes.addSuccess(
          this.intl.t(
            'authenticated.kases.manage.edit.custom-fields.actions.save'
          )
        )
      )
      .catch(() =>
        this.flashes.addError(
          this.intl.t(
            'authenticated.kases.manage.edit.custom-fields.actions.error'
          )
        )
      );
  }
}
