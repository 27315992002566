import Component from '@ember/component';
import { bind } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';

export default Component.extend({
  classNames: ['quick-add-user', 'well'],
  store: service(),
  flashes: service(),
  staff: service(),
  intl: service(),

  user: null,
  query: null,
  showValidation: false,

  didReceiveAttrs() {
    set(this, 'user', this.store.createRecord('user'));
  },

  handleSaveSuccess() {
    const message = this.user.get('ssoInviteUuid') ? 'ssoUserExists' : 'success';
    this.flashes.addSuccess(this.intl.t(`authenticated.admin.manage.users.index.save.${message}`));
    this.staff.unloadAll();
    this.sendAction('refreshModel');
    this.sendAction('afterSave');
    set(this, 'query', null);
  },

  handleSaveError(error) {
    let message = this.intl.t('authenticated.admin.users.edit.overview.save.error');
    const errors = error?.errors;
    const foundError = Array.isArray(errors) ? [...errors]?.shift(): {};
    const details = foundError?.detail || '';

    if (details.includes('You cannot save this user without an email address')) {
      message = this.intl.t('authenticated.admin.users.edit.overview.save.noEmail') // need email
    } else if (details.includes('sso_user_exists')) {
      message = this.intl.t('authenticated.admin.users.edit.overview.save.matchingEmail') // email in sso
    } else if (details.includes('has already been taken') && foundError?.source?.pointer === '/data/attributes/credential.email') {
      message = this.intl.t(
        'authenticated.admin.users.edit.overview.save.emailExists' // 'email used in tms
      );
    }

    this.flashes.addError(message);
  },

  actions: {
    saveUser() {
      const user = this.user;

      user.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          this.user
            .save()
            .then(
              bind(this, 'handleSaveSuccess'),
              bind(this, 'handleSaveError')
            );
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    cancelUser() {
      this.user.rollbackAttributes();
      this.sendAction('onCancel');
    }
  }
});
