import Controller from '@ember/controller';
import { action } from '@ember/object';


export default class AuthenticatedCraknGlobalSettingsIndexController extends Controller {

  @action
  settingValueChange(setting, value){
    setting.set('value', value)
    setting.save();
  }


}
