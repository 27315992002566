import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Toolbelt from 'crakn/mixins/component-toolbelt';

export default Component.extend(Toolbelt, {
  showValidation: false,
  classNames: ['list__item', 'list__item--wrap'],
  tagName: 'li',
  session: service(),

  idKey: readOnly('model.id'),

  isEditing: false,
  model: null,

  isPriceDisabled: computed('product', 'session.currentClient.has_discretionary_items', function() {
    const product = get(this, 'model.product');

    if (!get(product, 'isDiscretionary') && this.session.currentClient.has_discretionary_items) {
      return true;
    }
    if (get(this, 'session.currentUser.canEditSelectionPrices')) {
      return false;
    }
    if (get(product, 'isCustomizable')) {
      return false;
    }
    if (get(product, 'invoiceCategory.name') === 'Cash Advance') {
      return false;
    }
    return true;
  }),

  actions: {
    removeProduct() {
      this.attrs.removeProduct(this.model, 'products');
    },

    revertProduct() {
      set(this, 'isEditing', false);
      this.attrs.revertProduct(this.model);
    },

    saveProduct() {
      const selectionProduct = this.model;

      selectionProduct.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          set(this, 'isEditing', false);
          this.attrs.saveProduct(selectionProduct);
        } else {
          set(this, 'showValidation', true);
        }
      });
    },
    togglePullFromInventory(checked) {
      this.attrs.togglePullFromInventory(this.model, checked);
    },
    togglePullFromBeaconfpInventory(checked) {
      this.attrs.togglePullFromBeaconfpInventory(this.model, checked);
    }
  }
});
