import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RSVP from 'rsvp';

export default class AuthenticatedCraknFormsIndexRoute extends Route {
  @service store;
  @service session;
  @service router;

  queryParams = {
    page: {
      refreshModel: true
    },
    query: {
      refreshModel: true
    }
  };

  beforeModel() {
    if (this.session.currentClient.can_view_form_in_tfe) {
      this.router.transitionTo('authenticated.crakn.manage.forms.tfe');
    }
  }

  model(params) {
    return RSVP.hash({
      blankForms: this.store.query('blankForm', {
        only_searchable: false,
        page: params.page,
        query: params.query
      })
    });
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.setProperties({
        query: null
      });
    }
  }
}
