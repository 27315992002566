import Controller from '@ember/controller';
import { set, get } from '@ember/object';
import { readOnly, alias } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  purchaserId: validator('presence', true),
  locationId: validator('presence', true),
  gplId: validator('presence', true),
  date: validator('presence', true)
});
export default Controller.extend(Validations, {
  purchaserId: readOnly('selection.purchaser.id'),
  locationId: readOnly('selection.location.id'),
  gplId: readOnly('selection.location.generalPriceList.id'),
  date: alias('selection.contractDate'),
  singleClickEvent: false, // new double click fix

  errors: null,
  showValidation: false,

  actions: {
    validateAndCreateSale() {
      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          this.send('createSale');
        } else {
          set(this, 'showValidation', true);
        }
      });
    }
  }
});
