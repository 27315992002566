import { inject as service } from '@ember/service';
import { set, get, computed } from '@ember/object';
import SelectBox from './select-box';

export default SelectBox.extend({
  layoutName: 'components/form/organization-location-selector',

  location: service(),
  organization: service(),

  placeholder: 'Organization or Location(s)',
  placeholderLabel: 'All Locations',

  locationOptions: computed('location.allSorted', function() {
    return get(this, 'location.allSorted').map(this.recordIdPrefixer('L'));
  }),

  organizationOptions: computed('organization.allSorted', function() {
    return get(this, 'organization.allSorted').map(this.recordIdPrefixer('O'));
  }),

  recordIdPrefixer(prefix) {
    return record => {
      record.set('code', `${prefix}${record.id}`);
      return record;
    };
  },

  getSelection() {
    const key = this.key;
    let value = this.value;
    const firstCharacter = value.charAt();
    const organizationOptions = this.organizationOptions;
    const locationOptions = this.locationOptions;
    let selection = '';

    value = value.slice(1);
    if (firstCharacter === 'O') {
      selection = organizationOptions.findBy(key, value);
    } else if (firstCharacter === 'L') {
      selection = locationOptions.findBy(key, value);
    }

    set(this, 'selection', selection);

    const name = get(this, 'selection.name') || '';
    this.sendAction('updateFilter', name);
  }
});
