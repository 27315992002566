import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';

export default class HeaderNavComponent extends Component {
  @service accessibleClients;
  @service adminNavigation;
  @service api;
  @service browser;
  @service intl;
  @service session;
  @service sidebar;

  get noBranding() {
    return this.session.get('currentClient.logo_branding') === 'no_logo';
  }

  get userImage() {
    const userImageUrl = this.session.get('currentUser.imageUrl');
    const imageStyle = `background-image: url(${userImageUrl})`;

    return htmlSafe(imageStyle);
  }

  get userMenuItems() {
    const items = [];
    
    if (this.session.get('currentUser.isSuperuser')) {
      items.push({
        icon: 'verified_user',
        label: this.intl.t('appTitle.acronym'),
        path: 'authenticated.crakn.manage.clients'
      });
    }

    if (this.session.get('currentUser.isAdmin')) {
      items.push({
        icon: 'vpn_key',
        label: this.intl.t('components.header-nav.userMenu.admin'),
        path: this.adminNavigation.firstAvailableAdminRoute
      });
    }

    items.push({
      icon: 'settings',
      label: this.intl.t('components.header-nav.userMenu.settings'),
      path: 'authenticated.settings.info'
    });

    items.push({
      icon: 'exit_to_app',
      label: this.intl.t('components.header-nav.userMenu.logOut'),
      path: 'logout'
    });

    return items;
  }
}
