import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    return this.modelFor('authenticated.kases.manage.chain-of-custody');
  },

  setupController(controller) {
    this._super(...arguments);
    const kase = this.modelFor('authenticated.kases.manage.chain-of-custody');
    const newPersonalEffect = this.store.createRecord('v2/personal-effect', {
      kase
    });
    controller.set('newPersonalEffect', newPersonalEffect);
  }
});
