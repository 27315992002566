import Model, { belongsTo, attr } from '@ember-data/model';
import moment from 'moment';

export default class InquiryNoteModel extends Model {
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;
  @attr('string') content;
  @attr('moment-datetime', { defaultValue: () => moment() }) updatedAt;

  @belongsTo('inquiry', { async: true }) inquiry;
  @belongsTo('user', { async: true }) user;
}
