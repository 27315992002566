import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class KasesManageDocumentsAlbumsEditRoute extends Route {
  @service session;

  model(params) {
    return this.store.findRecord('v2/album', params.album_id, {
      include: 'photos,cover-photo'
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const kase = this.modelFor('authenticated.kases.manage');
    const photos = this.store.query('v2/photo', {
      kase_id: kase.get('id'),
      per_page: 5000,
      reload: true
    });
    const v2Kase = this.store.findRecord('v2/kase', kase.get('id'));
    const v2User = this.store.findRecord(
      'v2/user',
      this.session.currentUser.id
    );

    controller.set('album', model);
    controller.set('kase', v2Kase);
    controller.set('photos', photos);
    controller.set('user', v2User);
  }
}
