import Model, { hasMany, attr } from '@ember-data/model';

export default class PurchaseReasonModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') archivedAt;

  @hasMany('product', { async: true }) products;
}
