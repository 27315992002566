import { sort } from '@ember/object/computed';
import Controller from '@ember/controller';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import InquiryActions from 'crakn/mixins/inquiry-actions';

export default Controller.extend(InquiryActions, {
  names: service(),
  store: service(),
  staff: service(),
  flashes: service(),

  activeInquiry: service(),

  isAddingContact: false,

  isInquiryRoute: true,

  locationSorting: ['name:asc'],
  sortedAllLocations: sort('allLocations', 'locationSorting'),

  isInquiryLockedTrigger: null,

  isInquiryLocked: computed('isInquiryLockedTrigger', function() {
    let isLocked = 0;

    if (get(this, 'model.events').findBy('isEditing', true)) {
      isLocked++;
    }

    return isLocked;
  }),

  statusOptions: [
    'New',
    'In Progress',
    'Urgent',
    'Closed',
    'Waiting for Information',
    'Outgoing'
  ],

  actions: {
    addNewEvent() {
      this.send(
        'selectEvent',
        this.store.createRecord('event', {
          inquiry: this.model
        })
      );
    },

    assignStaffMember(inquiryStaffMemberId) {
      get(this, 'staff.all').then(allStaff => {
        const user = allStaff.findBy('id', inquiryStaffMemberId);

        if (!user) {
          set(this, 'model.inquiryStaffMember', null);
        } else {
          set(this, 'model.inquiryStaffMember', user);
        }
      });
    },

    deleteEvent(event) {
      event.destroyRecord().then(() => {
        get(this, 'model.events').removeObject(event);
        this.notifyPropertyChange('isInquiryLockedTrigger');
      });
    },

    cancelEvent(event) {
      set(event, 'isEditing', false);
      this.notifyPropertyChange('isInquiryLockedTrigger');
    },

    saveEvent(event) {
      set(event, 'isEditing', undefined);
      this.notifyPropertyChange('isInquiryLockedTrigger');
    },

    selectEvent(event) {
      set(event, 'isEditing', true);
      this.notifyPropertyChange('isInquiryLockedTrigger');
    },

    openEventModal(event) {
      let currentEvent = get(this, 'model.events').findBy('isEditing', true);
      currentEvent = currentEvent ? get(currentEvent, 'id') : null;

      set(
        event,
        'isCurrentEvent',
        get(event, 'isNew') || get(event, 'id') === currentEvent
      );
      this.send('showModal', 'g/whiteboard/modal/event-details', event);
    },

    submit() {
      this.activeInquiry.save();
    },

    locationSelected(locationId) {
      set(this, 'model.locationId', locationId);
    },

    updateContact(contact) {
      this._moveContactAttributesToModel(contact);
    }
  }
});
