import { fullName, phoneNumber } from 'crakn/utils/people-helpers';
import { get } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';
import Model, { belongsTo, attr } from '@ember-data/model';

const Validations = buildValidations({
  personFirstName: validator('presence', {
    message: 'First or Last Name must be provided.',
    presence: true,
    dependentKeys: ['model.personLastName'],
    disabled: notEmpty('model.personLastName'),
  }),
  personLastName: validator('presence', {
    message: 'First or Last Name must be provided.',
    presence: true,
    dependentKeys: ['model.personFirstName'],
    disabled: notEmpty('model.personFirstName'),
  }),
  relationshipName: validator('presence', {
    presence: true,
    ignoreBlank: true,
  }),
});

export default class KaseContactModel extends Model.extend(Validations) {
  @attr('string') personId;
  @attr('string') personFirstName;
  @attr('string') personMiddleName;
  @attr('string') personLastName;
  @attr('string') personMaidenName;
  @attr('string') personNameSuffix;
  @attr('string') personEmail;
  @attr('string') personOccupation;
  @attr('phone') personPhonePrimary;
  @attr('phone') personPhoneSecondary;
  @attr('boolean') personDeceased;
  @attr('boolean') personHasExistingKase;
  @attr('string') kaseId;
  @attr('moment-datetime') personBirthDate;
  @attr('string') personContactAge;
  @attr('string') personPrintedName;
  @attr('string') personSpouseFirstName;
  @attr('string') personSpouseMiddleName;
  @attr('string') personSpouseLastName;
  @attr('string') personSpouseMaidenName;
  @attr('number') personYearsInOccupation;
  @attr('number') personYearsInCounty;
  @attr('string') relationshipName;

  @belongsTo('kase', { inverse: 'kaseContacts', async: true }) kase;

  get isPrimaryContact() {
    return this.kase.get('primaryContact').get('id') === this.id;
  }

  get isNextOfKin() {
    return this.kase.get('nextOfKin').get('id') === this.id;
  }

  get personName() {
    return fullName(this, 'person');
  }

  get personDisplayPhoneNumber() {
    return phoneNumber(this, 'person');
  }

  selectPerson(person) {
    // If you are removing get from this function test the consumers for proper behavior
    // Especially saveInquiry in app/mixins/inquiry-actions.js
    this.personId = get(person, 'id');
    this.personFirstName = get(person, 'firstName');
    this.personMiddleName = get(person, 'middleName');
    this.personLastName = get(person, 'lastName');
    this.personMaidenName = get(person, 'maidenName');
    this.personNameSuffix = get(person, 'nameSuffix');
    this.personEmail = get(person, 'email');
    this.personPhonePrimary = get(person, 'phonePrimary');
    this.personPhoneSecondary = get(person, 'phoneSecondary');
    this.personDeceased = get(person, 'deceased');
    this.personHasExistingKase = get(person, 'hasExistingKase');
    this.personPrintedName = get(person, 'printedName');
    this.personBirthDate = get(person, 'birthDate');
    this.personContactAge = get(person, 'contactAge');
    this.personSpouseFirstName = get(person, 'spouseFirstName');
    this.personSpouseMiddleName = get(person, 'spouseMiddleName');
    this.personSpouseLastName = get(person, 'spouseLastName');
    this.personSpouseMaidenName = get(person, 'spouseMaidenName');
  }
}
