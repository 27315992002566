import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class WebsitesController extends Controller {
  @service api;
  @service flashes;
  @tracked search = '';

  get filteredSites() {
    return this.model?.filter(x => x?.website_url?.toLowerCase().includes(this.search.toLowerCase()));
  }

  @action
  clearSearch() {
    this.search = '';
  }

  @action
  async launchWebsiteManager(id, e) {
    e.target.disabled = true; // double click
    const newTab = window.open('', '_blank')
    const resp = await this.api.json.get(`tribute_website/login/${id}`);

    if (!resp.ok) {
      e.target.disabled = false; // double click
      newTab.close();
      this.flashes.addError(
        'There was a problem logging into your website. If this continues, please contact support.'
      );

      return;
    }

    const login = resp.parsedJson;
    newTab.location = `${login.url}`;
    e.target.disabled = false; // double click
  }
}
