import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import moment from 'moment';
import { tracked } from '@glimmer/tracking';

export default class ArAgingReportComponent extends Component {
  @tracked asOfDate = moment().toISOString();
  @tracked includeCreditBalances = false;
  @tracked organizationLocationId = null;
  @tracked reportKase = null;
  @tracked funeralDirectorId = '';
  @tracked showAdditionalDateFilters = false;
  @tracked startsAt = moment().subtract(30, 'day').toISOString();
  @tracked endsAt = moment().toISOString();
  @tracked selectionType = 'cases_and_misc_sales';
  @tracked isBadDebt = 'both';
  @tracked contactType = 'purchaser';

  get reportLink() {
    const params = new URLSearchParams({
      as_of_date: this.asOfDate,
      starts_at: this.showAdditionalDateFilters ? this.startsAt : null,
      ends_at: this.showAdditionalDateFilters ? this.endsAt : null,
      organization_location_id: this.organizationLocationId,
      kase_ids: [this.reportKase?.id],
      uuid: this.args.uuid,
      include_credit_balances: this.args.includeCreditBalances,
      funeral_director_id: this.funeralDirectorId,
      selection_type: this.selectionType,
      is_bad_debt: this.isBadDebt,
      contact_type: this.contactType,
    }).toString();
    return `/report/ar_aging_by_location_and_case?${params}`;
  }

  get fileUrl() {
    const params = new URLSearchParams({
      bigReport: true
    }).toString();

    return `#/generated_document/${this.args.uuid}?${params}`;
  }

  @action
  selectKase(kase) {
    if (kase === undefined) {
      return;
    }
    set(this, 'reportKase', kase);
  }

  @action
  setDate(attr, value) {
    set(this, attr, value);
  }

  @action
  clearKase() {
    set(this, 'reportKase', null);
  }

  @action
  setSelectionType(value) {
    set(this, 'selectionType', value);
  }

  @action
  setIsBadDebt(value) {
    set(this, 'isBadDebt', value);
  }

  @action
  setContactType(value) {
    set(this, 'contactType', value);
  }
}
