import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { cleanPrice } from 'crakn/utils/data-helpers';
import moment from 'moment';

export default class AuthenticatedBatchPaymentsNewController extends Controller {
  @service flashes;
  @service location;
  @service router;
  @service store;
  @service v2Staff;
  @service session;
  @service intl;

  @tracked altPayer = false;
  @tracked collectedById;
  @tracked changeset;
  @tracked isChangesetPaymentsEmpty = true;
  @tracked isSubmittingDisabled = true;
  @tracked remainingBatchPaymentAmount;
  @tracked showValidations = false;
  @tracked splitPayments = {};
  @tracked totalSplitPayments = {};
  @tracked locationId;
  @tracked batchPaymentLocation;

  activeStaff = this.v2Staff.all;

  @computed('batchPaymentLocation')
  get paymentTypes() {
    return this.store.query('v2/paymentType', {
      archived: false,
      gpl_id: this.batchPaymentLocation.get('generalPriceListId'),
      is_tribute_pay: false,
    })
  }

  get isRemainingBatchPaymentAmountBelowZero() {
    return this.remainingBatchPaymentAmount < 0 ? true : false;
  }

  @computed('changeset.error.payer.validation')
  get addEditContactError() {
    return this.changeset.error.payer.validation;
  }

  @action
  clearAll() {
    this.altPayer = false;
    this.collectedById = undefined;
    this.isChangesetPaymentsEmpty = true;
    this.isSubmittingDisabled = true;
    this.remainingBatchPaymentAmount = undefined;
    this.showValidations = false;
    this.splitPayments = {};
    this.totalSplitPayments = {};
    this.locationId = undefined;
    this.batchPaymentLocation = undefined;
    this.changeset.rollback();
    this.changeset.payments.forEach((payment) => payment.rollbackAttributes());
  }

  @action
  togglePayer() {
    this.changeset.payer = undefined;
    this.altPayer = !this.altPayer;

    if (this.changeset.person) {
      this.changeset.person = undefined;
    }
  }

  @action
  savePerson() {
    this.changeset.person.save();
  }

  @action
  setPerson(selectedPerson) {
    if (selectedPerson) {
      if (selectedPerson.get('id')) {
        this.store.findRecord('v2/person', selectedPerson.get('id')).then((person) => {
          this.changeset.set('person', person);
          this.changeset.set('payer', person.get('name'));
        });
      } else {
        this.changeset.set('person', selectedPerson);
      }
    } else {
      this.changeset.person = undefined;
    }
  }

  @action
  setPaymentType(id) {
    if (id !== '') {
      this.changeset.paymentType = this.store.peekRecord('v2/paymentType', id);
    } else {
      this.changeset.paymentType = undefined;
    }
  }

  @action
  setCollectedBy(id) {
    const collectedBy = this.v2Staff.all.findBy('id', id);
    this.changeset.collectedBy = collectedBy;
    this.collectedById = id;
  }

  @action
  setBatchPaymentAmount(amount) {
    if (amount) {
      const cleanAmount = cleanPrice(amount);
      this.changeset.amount = cleanAmount;

      if (this.totalSplitPayments) {
        this.setRemainingBatchPaymentAmount(this.splitPayment);
      } else {
        this.remainingBatchPaymentAmount = cleanAmount;
      }
    }
  }

  @action
  setRemainingBatchPaymentAmount() {
    if (!this.changeset.amount) {
      this.changeset.amount = 0;
    }

    const splitPaymentAmounts = Object.values(this.totalSplitPayments).reduce((sum, current) => sum + cleanPrice(current.amount), 0);

    this.remainingBatchPaymentAmount = this.changeset.amount - cleanPrice(splitPaymentAmounts);
  }

  @action
  addSplitPayment(payment) {
    if (!this.changeset.payments) {
      this.changeset.payments = [payment];
    } else {
      this.changeset.payments.pushObject(payment);
    }

    this.isChangesetPaymentsEmpty = false;
  }

  @action
  setLocation(id) {
    if (id !== '') {
      this.locationId = id;
      this.store.findRecord('v2/location', id).then((location) => this.batchPaymentLocation = location);

      this.collectedById = this.session.currentUser.id;
      this.setCollectedBy(this.collectedById);
    } else {
      this.locationId = undefined;
      this.batchPaymentLocation = undefined;
      this.changeset.location = undefined
    }
  }

  @action
  async submitBatchPayment(e) {
    e.target.disabled = true; // avoid double-click issues
    if (this.changeset.get('person') && !this.changeset.payer) {
      await this.store.findRecord('v2/person', this.changeset.get('person.id')).then((person) => {
        this.changeset.set('person', person);
        this.changeset.set('payer', person.get('name'));
      });
    }

    this.changeset.validate().then(() => {
      if (this.changeset.isValid) {
        if (this.changeset.isBatchPayment) {
          if (this.remainingBatchPaymentAmount !== 0) {
            this.flashes.addError(this.intl.t('authenticated.payments.batch-payments.errors.missingInfo'));
            e.target.disabled = false; // avoid double-click issues
            return;
          }

          this.changeset.payments.forEach((payment) => {
            payment.checkNumber = this.changeset.checkNumber;
            payment.collectedBy = this.changeset.collectedBy;
            payment.enteredBy = this.changeset.enteredBy;
            payment.collectedAt = this.changeset.collectedAt;
            payment.notes = this.changeset.notes;
            payment.payer = this.changeset.payer;
            payment.paymentType = this.changeset.paymentType;
            payment.processorPending = this.changeset.processorPending;
            payment.person = this.changeset.person;
          });
        }

        this.showValidations = false;
        this.changeset.location = this.batchPaymentLocation;

        this.changeset.save().then(() => {
          this.flashes.addSuccess('Batch Payment successfully created!');
          e.target.disabled = false; // avoid double-click issues
          this.router.transitionTo('authenticated.payments.batch-payments');
        })
        .catch(() => {
          this.flashes.addError('Something went wrong.');
          e.target.disabled = false; // avoid double-click issues
        });
      } else {
        this.showValidations = true;

        this.flashes.addError('Make sure all of the information is correct and/or inputted');
        e.target.disabled = false; // avoid double-click issues
      }
    });
  }

  @action
  setCollectedAt(value) {
    if (value) {
      this.changeset.collectedAt = moment(value);
    }
  }

  @action
  addSplitPayments(splitPayment) {
    const selectionId = splitPayment.selection.id;
    if (this.splitPayments[selectionId]) {
      this.splitPayments[selectionId].amount = cleanPrice(splitPayment.amount);
      this.totalSplitPayments[selectionId].amount = cleanPrice(splitPayment.amount);
    } else {
      this.splitPayments[selectionId] = splitPayment;
      this.totalSplitPayments[selectionId] = splitPayment;
    }
  }

  @action
  clearSplitPayments() {
    this.splitPayments = {};
  }

  @action
  cancel() {
    this.router.transitionTo('authenticated.payments.batch-payments');
  }
}
