import Route from '@ember/routing/route';

export default class AccountingExportsIndexRoute extends Route {
  queryParams = {
    page: {
      refreshModel: true
    }
  };
  model(params) {
    return this.store.query('v2/accounting-export', {
      ...params,
      include: 'kases,kases.deceased,selections,exported-by'
    });
  }
}
