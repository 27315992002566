import Model, { belongsTo, attr } from '@ember-data/model';

export default class IntegrationExportModel extends Model {
  @attr('array') bestWishesCardsData;
  @attr('boolean') cfsIntegrationCanOverrideAvatar;
  @attr('moment-datetime') updatedAt;
  @attr('string') bestWishesCardsRecipient;
  @attr('string') tributePrintOrderStatus;
  @attr('string') integrationServiceId;
  @attr('string') serviceName;
  @attr('string', { defaultValue: 'pending' }) status;
  @attr('string') tributeWebsiteExportResponse;
  @attr('string') tributeVideoStatus;
  @attr('string') tributeVideoDownloadUrl;
  @attr('string') websiteName;

  @belongsTo('client') client;
  @belongsTo('kase') kase;
}
