import Model, { attr } from '@ember-data/model';
import { or } from '@ember/object/computed';

export default class OverdueKaseModel extends Model {
  @attr('string') customId;
  @attr('string') name;
  @attr('moment-date') birthDate;
  @attr('moment-date') deathDate;

  @or('birthDate', 'deathDate') isBirthOrDeathPresent;
}
