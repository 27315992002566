import Component from '@ember/component';
import { computed, set } from '@ember/object';
import moment from 'moment';

export default Component.extend({
  startsAt: moment()
    .subtract(30, 'day')
    .toISOString(),
  endsAt: moment().toISOString(),

  organization_location_id: null,
  postedOnly: false,

  reportLink: computed(
    'startsAt',
    'endsAt',
    'organization_location_id',
    'uuid',
    'postedOnly',
    {
      get() {
        const params = new URLSearchParams({
          organization_location_id: this.organization_location_id,
          starts_at: this.startsAt,
          ends_at: this.endsAt,
          uuid: this.uuid,
          posted_only: this.postedOnly
        }).toString();
        return `/report/case_revenue_overview.xlsx?${params}`;
      }
    }
  ),

  fileUrl: computed('uuid', function() {
    const params = new URLSearchParams({
      bigReport: true
    }).toString();

    return `#/generated_document/${this.uuid}?${params}`;
  }),

  actions: {
    setDate(attr, value) {
      set(this, attr, value);
    }
  }
});
