import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';

export default class AuthenticatedKasesManageEditKaseLinkRoute extends SearchRoute {
  layout = 'custom-aside';
  modelType = 'v2/kase-link';

  model(params) {
    const kase = this.modelFor('authenticated.kases.manage.edit');
    return this.store.query(this.modelType, {
      query: params.query || this.defaultQuery,
      page: params.page || this.defaultPage,
      perPage: params.perPage || this.defaultPerPage,
      archived: params.archived || this.defaultArchived,
      kaseId: kase.get('id'),
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }

  async setupController(controller) {
    super.setupController(...arguments);
    controller.set(
      'kase',
      await this.store.findRecord(
        'v2/kase',
        this.modelFor('authenticated.kases.manage').get('id')
      )
    );
  }
}
