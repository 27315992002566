import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedAdminIntegrationsTributeWebsiteController extends Controller {
  @tracked isLoading = true;

  displayNameOptions = [
    'CFS',
    'FrontRunner',
    'Funeral Tech',
    'Tribute Website',
  ];

  @action
  refresh() {
    this.send('refreshModel');
  }
}
