import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { debounce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

/**
 * A base controller to include a searchable index
 * 
 * ## Usage
 * This controller is meant to be used in conjunction with the Search route (app/routes/search).
 * In your template use `this.search` to display the current search query and `this.queryDidChange(x)` to update it.
 * 
 * ### Basic
 * The basic usage of the controller allows for automatic querying including `query`, `page`, `perPage`, and `archived`* 
 *
 * ```
 * import SearchController from 'crakn/controllers/search
 * 
 * export default class ExampleController extends SearchController {
 * }
 * ```
 * 
 * ### Additional query params
 * 
 * The query params are overridable in child class, but you will need to include the previous params as well.
 * 
 * **NOTE** You will also have to include these in your route if you want automatic query updates.
 * 
 * ```
 * import SearchController from 'crakn/controllers/search
 *  
 * export default class ExampleController extends Controller {
 *   queryParams = ['query', 'page', 'perPage', 'archived', 'locationId'];
 * }
 * ```
 */

export default class SearchController extends Controller {
  queryParams = ['query', 'page', 'perPage', 'archived'];

  /**
	 * the internal query state used to populate calls
	 * @type {string}
   * @private
	 */
  internalQuery = null

	/**
	 * the number to use for param `page` in calls
	 * 
	 * Unless you know what you are doing, do not override in controller.
	 * Set defaultPage in router instead.
	 * @type {number}
   * @public
	 */
  @tracked page;

	/**
	 * the number to use for param `perPage` in calls
	 * 
	 * Unless you know what you are doing, do not override in controller.
	 * Set defaultPerPage in router instead.
	 * @type {number}
   * @public
	 */
  @tracked perPage;

	/**
	 * if param `archived` should be true or false in calls
	 * 
	 * Unless you know what you are doing, do not override in controller.
	 * Set defaultArchived in router instead.
	 * @type {boolean}
   * @public
	 */
   @tracked archived;

  /**
	 * the string to use for param `query` in calls
	 * 
	 * Unless you know what you are doing, do not override in controller.
	 * Set defaultQuery in router instead.
	 * @type {string}
   * @public
	 */
  @tracked query;

	/**
	 * the delay before a query gets applied
	 * @type {number}
   * @private
	 */
  @tracked debounce = 300;

  /**
   * reference to internalQuery that is used by the template.  Defaults to query if internalQuery is null
   * to handle redirects that include a `query` queryParam.
   * @type {string}
   * @public
   */
  @computed('internalQuery')
  get search() {
    return this.internalQuery;
  }

  /**
   * Updates the query value after debounce and resets page number
   * @return {Undefined}
   * @public
   */
  updateQuery() {
    set(this, 'query', this.internalQuery);
    this.page = 1;
  }

  /**
   * Updates the query value after a debounce
   * @param  {String} value Value to update `query` to
   * @return {Undefined}
   * @public
   */
  @action
  queryDidChange(value) {
    set(this, 'internalQuery', value);

    debounce(
      this,
      this.updateQuery,
      this.debounce,
      false
    );
  }

  /**
   * Clears the current search query
   * @returns {Undefined}
   * @public
   */
  @action
  clearQuery() {
    set(this, 'internalQuery', '');
    this.updateQuery();
  }

  /**
   * Toggles the archived value and resets page number
   * @param  {Boolean} value Whether or not archived is selected
   * @return {Undefined}
   * @public
   */
  @action
  archivedDidChange(value) {
    this.archived = value;
    this.page = 1;
  }
}