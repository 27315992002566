import Route from '@ember/routing/route';

export default Route.extend({
  setupController(controller, model) {
    this._super(...arguments);
    controller.setProperties(model);
    controller.set('originalIsBadDebt', model.selection.isBadDebt);
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('selection', null);
      controller.set('showValidation', false);
    }
  },

  actions: {
    willTransition(transition) {
      const selection = this.controller.selection;
      if (selection.get('hasDirtyAttributes')) {
        if (confirm('You have unsaved changes. Are you sure?')) {
          selection.rollbackAttributes();
          return true;
        } else {
          transition.abort();
        }
      }
    }
  }
});
