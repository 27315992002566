import { equal } from '@ember/object/computed';
import Model, { belongsTo, attr } from '@ember-data/model';

export default class V2IntegrationExportModel extends Model {
  @attr() content;
  @attr('moment-datetime') createdAt;
  @attr('string') description;
  @attr('string') status;
  @attr('moment-datetime') updatedAt;

  @belongsTo('v2/accountingExport', { async: true }) accountingExport;
  @belongsTo('v2/kase', { async: true }) kase;
  @belongsTo('v2/selection', { async: true }) selection;

  @equal('status', 'successful') successful;
}
