import { readOnly, equal } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Toolbelt from 'crakn/mixins/component-toolbelt';

export default Component.extend(Toolbelt, {
  classNames: ['card', 'card--grey', 'm--sm--t'],
  store: service(),

  label: 'Scheduled Events',
  kase: null,

  activeEventsMeta: readOnly('activeEvents.content.meta'),

  activeEvents: computed('activeEventPage', 'activeEventPerPage', function() {
    return this.store.query('event', {
      kase_id: get(this, 'kase.id'),
      per_page: this.activeEventPerPage,
      page: this.activeEventPage
    });
  }),

  activeEventPerPage: 1,
  activeEventPage: 1,

  didReceiveAttrs() {
    this._super(...arguments);

    // This will force the activeEvents to update one way or another
    if (this.activeEventPage > 1) {
      set(this, 'activeEventPage', 1);
    } else {
      this.notifyPropertyChange('activeEventPage');
    }
  },

  isFirstEvent: equal('activeEventsMeta.page', 1),

  isLastEvent: computed('activeEventsMeta.page', function() {
    const meta = this.activeEventsMeta;

    return meta ? get(meta, 'page') === get(meta, 'pages') : true;
  }),

  actions: {
    navigateEvent(direction) {
      set(this, 'isNotesVisible', false);

      if (direction === 'next') {
        this.incrementProperty('activeEventPage');
      } else if (direction === 'prev') {
        this.decrementProperty('activeEventPage');
      }
    }
  }
});
