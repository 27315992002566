import Service, { inject as service } from '@ember/service';
import {
  clonePlanner,
  validatePlanner
} from '@tributetech/ember-jsonb';

export default class OnlinePlannerService extends Service {
  @service store;

  findOrCreateEmberPlanner(configID) {
    const emberPlannerConfigs = this.store.peekAll('v2/online-planner-json');
    const emberPlanner = emberPlannerConfigs.find(
      (x) => x.plannerConfigId === configID
    );
    return emberPlanner ? emberPlanner : this._createEmberPlanner(configID);
  }

  savePlanner(configID) {
    const emberPlanner = this.findOrCreateEmberPlanner(configID);
    const validate = this.validateEmberPlanner(configID);

    if (!validate.isValid) {
      return new Promise((_resolve, reject) => {
        reject(validate);
      });
    }

    const pojo = clonePlanner(emberPlanner);
    const planner = this.getPlannerConfig(configID);
    planner.set('config', pojo);
    return planner.save();
  }

  validateEmberPlanner(configID) {
    const emberPlanner = this.findOrCreateEmberPlanner(configID);
    const errors = validatePlanner(emberPlanner);
    return errors;
  }

  getPlannerConfig(configID) {
    return this.store.peekRecord('v2/online-planner-config', configID);
  }

  getCustomFieldIdsUsed(configID) {
    const getPages = (pages) => {
      let ids = [];

      pages.forEach((page) => {
        const customSection = page.sections.find(x => x.isCustom === true);

        customSection?.content?.forEach((content) => {
          ids.push(content?.name?.split('.')[1]);
        });

        if (page.pages) {
          ids = ids.concat(getPages(page.pages));
        }
      });

      return ids;
    }

    const emberPlanner = this.findOrCreateEmberPlanner(configID);
    return getPages(emberPlanner.pages);
  }

  mapTmsCustomFieldToPlannerField(customField){
    const plannerfield = {
      type: customField.type === 'boolean' ? 'checkbox' : customField.type,
      style: 'small',
      label: customField.text,
      name: `custom-field.${customField.id}`,
      value: ""
    }

    if (customField.type === 'select' && customField?.options?.length) {
      plannerfield.options = [...customField.options];
    }

    return plannerfield;
  }

  async unloadFragments() {
    await this.store.peekAll('v2/online-planner-content').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-dropdown-option').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-json').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-page').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-section').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-session').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-user').map(field => field.unloadRecord());
    await this.store.peekAll('v2/online-planner-session-fragment').map(field => field.unloadRecord());
  }

  // Private
  // You should never have to call the functions below
  _createEmberPlanner(configID) {
    const planner = this.getPlannerConfig(configID);
    return this.store.createRecord('v2/online-planner-json', {
      version: planner.config.version,
      pages: planner.config.pages,
      plannerConfigId: planner.id,
      theme: planner.config.theme
    });
  }
}
