import Model, { belongsTo, attr } from '@ember-data/model';

export default class V2SelectionValidationsConfigModel extends Model {
  @attr('boolean', { defaultValue: false }) canUseKaseTagValidation;
  @attr('boolean', { defaultValue: false }) canUseStaffMemberValidation;
  @attr('boolean', { defaultValue: false }) canUseDispositionValidation;
  @attr('boolean', { defaultValue: false }) canUsePurchaserValidation;
  @attr('boolean', { defaultValue: false }) canUsePurchaserRelationshipValidation;
  @attr('boolean', { defaultValue: false }) canUsePostingPeriodValidation;

  @belongsTo('v2/client', { async: true }) client;
}
