import Component from '@ember/component';
import { get, computed } from '@ember/object';

export default Component.extend({
  classNames: ['btn', 'pills__btn'],
  classNameBindings: ['btnColor', 'qaClass'],
  tagName: 'button',

  btnColor: computed('activeStatus', function() {
    const statusValue = get(this, 'status.id');
    return `btn--${this.activeStatus === statusValue ? statusValue : 'light'}`;
  }),

  qaClass: computed('status.value', function() {
    return `qa-status-${get(this, 'status.id')}`;
  }),

  click() {
    this.sendAction('updateStatus', get(this, 'status.id'));
  },

  status: null,

  activeStatus: null
});
