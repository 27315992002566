import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KasesManageChainOfCustodyAuditLog extends Controller {
  @service flashes;
  @service store;
  @tracked isEditing = false;
  @tracked newCustodyAudit;
  @tracked showValidation = false;

  get kase() {
    return this.model;
  }

  get custodyAudits() {
    return this.kase.custodyAudits.filter(ca => {
      return !ca.isNew || ca.isSaving;
    }).sort((a, b) => b.createdAt - a.createdAt);
  }

  handleSaveSuccess() {
    this.send('flashSuccess', 'Log was successfully submitted.');
  }

  handleSaveError() {
    this.send(
      'flashError',
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com'
    );
  }

  resetCustodyAudit() {
    this.set(
      'newCustodyAudit',
      this.store.createRecord('v2/custody-audit', {
        kase: this.kase
      })
    );
  }

  @action
  createCustodyAudit(custodyAudit) {
    this.send('saveCustodyAudit', custodyAudit);
    this.resetCustodyAudit();
  }

  @action
  saveCustodyAudit(custodyAudit) {
    custodyAudit
      .save({ adapterOptions: { include: 'start-place.address,start-room,end-place.address,end-room' } })
      .then(() => {
        this.handleSaveSuccess();
      })
      .catch(() => {
        this.handleSaveError();
      });
  }

  @action
  revertCustodyAudit(custodyAudit) {
    custodyAudit.rollbackAttributes();
  }
}
