export default [
  {
    name: 'Eastern Cape',
    id: 'ZA-EC'
  },
  {
    name: 'Free State',
    id: 'ZA-FS'
  },
  {
    name: 'Gauteng',
    id: 'ZA-GP'
  },
  {
    name: 'KwaZulu-Natal',
    id: 'ZA-KSN'
  },
  {
    name: 'Limpopo',
    id: 'ZA-LP'
  },
  {
    name: 'Mpumalanga',
    id: 'ZA-MP'
  },
  {
    name: 'Northern Cape',
    id: 'ZA-NC'
  },
  {
    name: 'North-West',
    id: 'ZA-NW'
  },
  {
    name: 'Western Cape',
    id: 'ZA-WC'
  }
];
