import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  maximumAmount: validator('presence', true)
});

export default class V2CollectionAttemptModel extends Model.extend(Validations) {
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') expiredAt;
  @attr('number') maximumAmount;
  @attr('number') referenceId;
  @attr('string') notes;
  @attr('string') referenceType;
  @attr('string') source;
  @attr('boolean') internalExpirationTest;

  @belongsTo('v2/payment-type') paymentType;

  @hasMany('v2/person') people;
}
