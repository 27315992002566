import Route from '@ember/routing/route';

export default class CemsitesIndexRoute extends Route {
  setupController(controller, model) {
    controller.set('model', model);
    this.setPlaceIntegrationServices();
  }

  setPlaceIntegrationServices() {
    this.controller.set(
      'placeIntegrationServices',
      this.store.query('v2/place-integration-service', {
        integration_name: 'cemsites',
        archived: false,
        include: 'place,place.address,integration-service'
      })
    );
  }
}
