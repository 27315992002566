import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class KasesEditCustomFieldsRoute extends Route {
  @service intl;
  layout = 'custom-aside';

  async model() {
    const kase = this.modelFor('authenticated.kases.manage');
    const v2Kase = await this.store.findRecord('v2/kase', kase.id);
    await this.unloadAllCustomFields();

    return this.store
      .query('v2/kaseCustomField', { kase_id: kase.id })
      .then((result) => {
        return (
          result.firstObject ||
          this.store
            .createRecord('v2/kaseCustomField', { kase: v2Kase })
            .save()
            .then(() => {
              // The model must be reloaded after createRecord otherwise
              // the v2/custom-fields will not be included in the Ember-Data store
              this.refresh();
            })
            .catch(() => {
              return this.intl.t(
                'authenticated.kases.manage.edit.custom-fields.actions.create.error'
              );
            })
        );
      });
  }

  async unloadAllCustomFields() {
    await this.store.peekAll('v2/customField').map(field => field.unloadRecord());
    await this.store.peekAll('v2/kase-custom-field').map(field => field.unloadRecord());
  }

  setupController(controller, model) {
    const kase = this.modelFor('authenticated.kases.manage');

    if (model?.customFields?.recordData?.modelName === 'v2/kase-custom-field') {
      controller.setProperties({
        kase: kase,
        kaseCustomFields: model,
        customFields: model.customFields
      });
    } else {
      controller.set('kaseCustomFieldError', model);
    }
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      controller.setProperties({
        kaseCustomFieldError: null,
        kase: null,
        kaseCustomFields: null,
        customFields: null
      });
    }
  }
}
