import Mixin from '@ember/object/mixin';
import { set, get } from '@ember/object';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default Mixin.create({
  activeInquiry: service(),
  session: service(),

  isInquiryRoute: false,

  redirectInquiry(modelIsNew, inquiry) {
    if (this.isInquiryRoute) {
      const kaseId = inquiry ? get(inquiry, 'kase.id') : null;

      if (modelIsNew && kaseId) {
        this.transitionToRoute(
          get(this, 'session.currentUser.kaseEditRoute'),
          kaseId
        );
      } else {
        window.history.back();
      }
    }
  },

  deactivateInquiry() {
    const activeInquiry = get(this, 'activeInquiry.model');
    const contact = get(activeInquiry, 'contact');
    const kase = get(activeInquiry, 'kase');
    const deceased = get(kase, 'deceased');

    // Clear any relationships on the inquiry
    if (contact && get(contact, 'isNew')) {
      contact.get('content').rollbackAttributes();
    }
    if (kase && get(kase, 'isNew')) {
      kase.get('content').rollbackAttributes();
    }
    if (deceased && get(deceased, 'isNew')) {
      deceased.get('content').rollbackAttributes();
    }

    set(this, 'activeInquiry.model', null);
    set(this, 'activeInquiry.contactRelationshipName', null);
    set(this, 'model', null);
    this.send('refreshModel');
    this.redirectInquiry();
    if (!get(activeInquiry, 'id')) {
      activeInquiry.destroyRecord();
    }
  },

  saveAndDeactivate(modelIsNew) {
    const activeInquiry = this.activeInquiry;
    const model = get(activeInquiry, 'model');
    let contact = get(model, 'contact');
    let kase = get(model, 'kase');
    let deceased = get(kase, 'deceased');

    // If there is no first or last on an unsaved contact
    if (
      contact &&
      get(contact, 'isNew') &&
      !get(contact, 'firstName') &&
      !get(contact, 'lastName')
    ) {
      // Destroy it
      get(contact, 'content').rollbackAttributes();
      set(model, 'contact', null);
      contact = null;
    }

    // If there is no first and last name on an unsaved deceased
    if (
      deceased &&
      get(deceased, 'isNew') &&
      !get(deceased, 'firstName') &&
      !get(deceased, 'lastName')
    ) {
      // Destroy them
      get(deceased, 'content').rollbackAttributes();
      get(kase, 'content').rollbackAttributes();
      set(model, 'kase', null);
      deceased = null;
      kase = null;
    }

    // Save inquiry, contact, and kase
    RSVP.all([
      model.save(),
      contact && get(contact, 'isNew') ? get(contact, 'content').save() : null,
      kase && get(kase, 'isNew') ? get(kase, 'content').save() : null
    ]).then(result => {
      get(model, 'events').forEach(function(event) {
        event.save();
      });
      // Save the kase.deceased
      if (deceased && get(deceased, 'isNew')) {
        get(deceased, 'content')
          .save()
          .then(result => {
            if (result[2]) {
              result[2].set('deceased', result);
            }
          });
      }
      set(activeInquiry, 'model', null);
      set(activeInquiry, 'contactRelationshipName', null);
      this.redirectInquiry(modelIsNew, result[0]);
    });
  },

  actions: {
    saveInquiry(e) {
      e.target.disabled = true; // avoid double-click issues
      const activeInquiry = this.activeInquiry;
      const model = get(activeInquiry, 'model');
      const modelIsNew = get(model, 'isNew');
      const contactRelationshipName = get(
        activeInquiry,
        'contactRelationshipName'
      );

      // If the inquiry is empty, don't save it
      const hasEvents = get(model, 'events.length') > 0;
      if (!get(model, 'hasContents') && !hasEvents) {
        return this.deactivateInquiry();
      }

      // This checks to see if we need to save a kase contact on the Inquiry
      // This only runs if a contact and kase are selected and a relationship is entered
      if (
        !get(activeInquiry, 'isContactRelationshipDisabled') &&
        contactRelationshipName
      ) {
        const kaseContact = this.store.createRecord('kaseContact', {
          kase: get(model, 'kase'),
          relationshipName: contactRelationshipName
        });

        kaseContact.selectPerson(get(model, 'contact'));

        kaseContact.save().then(() => {
          this.saveAndDeactivate(modelIsNew);
        });
      } else {
        this.saveAndDeactivate(modelIsNew);
      }
    },

    cancelInquiry() {
      const inquiry = get(this, 'activeInquiry.model');

      // Inquiry location is set on creation, making it dirty by default
      // This checks if there is more than one dirty attribute
      if (Object.keys(inquiry.changedAttributes() || {}).length > 1) {
        if (confirm('Are you sure?')) {
          this.deactivateInquiry();
        }
      } else {
        this.deactivateInquiry();
      }
    }
  }
});
