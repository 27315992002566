import Inflector from 'ember-inflector';

export function initialize(/* application */) {
  const inflector = Inflector.inflector;

  inflector.irregular('chartOfAccounts', 'chartsOfAccounts');
  inflector.irregular('v2/chartOfAccounts', 'v2/chartsOfAccounts');
  inflector.irregular('chart-of-accounts', 'charts-of-accounts');
  inflector.irregular('v2/chart-of-accounts', 'v2/charts-of-accounts');

  inflector.irregular('specialTax', 'specialTaxes');
  inflector.irregular('v2/specialTax', 'v2/specialTaxes');
  inflector.irregular('special-tax', 'special-taxes');
  inflector.irregular('v2/special-tax', 'v2/special-taxes');
}

export default {
  name: 'custom-inflector-rules',
  initialize
};
