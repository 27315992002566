import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),

  beforeModel(transition) {
    if (transition.to.name == 'authenticated.admin.financial-configurations.edit.index') {
      this.transitionTo('authenticated.admin.financial-configurations.edit.overview');
    }
  }
});
