const militaryBranches = [
  {
    label: 'Air Force and Air Force Reserve',
    value: 'air_force_and_air_force_reserve'
  },
  {
    label: 'Army and Army Reserve',
    value: 'army_and_army_reserve'
  },
  {
    label: 'Coast Guard and Coast Guard Reserve',
    value: 'coast_guard_and_coast_guard_reserve'
  },
  {
    label: 'Marine Corps and Marine Corps Reserve',
    value: 'marine_corps_and_marine_corps_reserve'
  },
  {
    label: 'Merchant Marines',
    value: 'merchant_marines'
  },
  {
    label: 'National Guard',
    value: 'national_guard'
  },
  {
    label: 'Navy and Navy Reserve',
    value: 'navy_and_navy_reserve'
  },
  {
    label: 'Space Force',
    value: 'space_force'
  },
  {
    label: 'Other Branch',
    value: 'other_branch'
  }
]
export { militaryBranches };
