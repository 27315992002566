import Model, { belongsTo, attr } from '@ember-data/model';

export default class EsignatureActionModel extends Model {
  @attr('moment-datetime') createdAt;
  @attr('string') description;
  @attr('string') ipAddress;
  @attr('string') type;

  @belongsTo('esignature') esignature;
  @belongsTo('esignedDocument', { polymorphic: true }) esignedDocument;
}
