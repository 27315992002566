import Component from '@ember/component';
import { bind } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default Component.extend({
  flashes: service(),
  taskList: null,
  saveTaskList: null,
  deleteTaskList: null,
  saveTask: null,
  deleteTask: null,
  showTasks: false,
  addMode: false,

  actions: {
    toggleTasksDisplay() {
      this.toggleProperty('showTasks');
    },

    toggleAddMode() {
      this.toggleProperty('addMode');
    },

    saveNewTask(task) {
      const taskList = this.get('taskList');
      task.set('list', taskList);
      task.save().then(() => {
        this.handleSaveSuccess(task);
        this.send('toggleAddMode');
      }, bind(this, 'handleError'));
    }
  },

  handleSaveSuccess() {
    this.get('flashes').addSuccess('Task saved!');
  },

  handleError() {
    this.get('flashes').addError(
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
    );
  }
});
