import Route from '@ember/routing/route'

export default class AdminLocationsEditGeneralPriceListRoute extends Route {
  model() {
    return this.modelFor('authenticated.admin.locations.edit');
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    model.get('generalPriceList')
         .then((gpl) => {
            if (gpl) {
              controller.set('gplSwitchDisabled', true);
            } else {
              controller.set('gplSwitchDisabled', false);
              controller.set('generalPriceLists', this.store.query('v2/generalPriceList', { per_page: 0 }));
            }
         })
         .catch(() => {
           controller.set('gplSwitchDisabled', true);
         })
  }
}
