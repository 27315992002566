import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AdminFinancialConfigurationsNewRoute extends Route {
  model(params) {
    return this.store.createRecord('chart-of-accounts', {
      name: params.name
    });
  }

  @action
  willTransition() {
    this.currentModel.rollbackAttributes();
  }
}
