import { helper as buildHelper } from '@ember/component/helper';
import { formatTime } from 'crakn/utils/date-helpers';

export function craknTimeRange(array, options) {
  const times = [];
  if (options && (options.startTime || options.endTime)) {
    times.push(options.startTime ? formatTime(options.startTime) : 'N/A');
    times.push(options.endTime ? formatTime(options.endTime) : 'N/A');
  }
  return times.join(' - ');
}

export default buildHelper(craknTimeRange);
