import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class TfeSignerModel extends Model {
  @service intl;
  @attr('array', { defaultValue: () => [] }) roles;

  @attr('moment-datetime') updatedAt;

  @attr('string') email;
  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') signatureRequestedBy;
  @attr('string') status;

  @belongsTo('tfe/generated-document', { async: true }) generatedDocument;

  @hasMany('tfe/signatureHistory', { async: false }) signatureHistories;

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  get statusColor() {
    let color;
    if(this.status === 'completed') {
      color = 'green';
    } else if(this.status === 'declined' || this.status === 'canceled') {
      color = 'red';
    } else {
      color = 'yellow';
    }
    return color;
  }

  get formattedRoles() {
    const roleList = []

    this.roles.forEach((role) => {
      switch(role) {
        case 'beneficiary':
          roleList.push(this.intl.t('model.tfe.signer.role.beneficiary'));
          break;
        case 'executor':
          roleList.push(this.intl.t('model.tfe.signer.role.executor'));
          break;
        case 'funeral_director':
          roleList.push(this.intl.t('model.tfe.signer.role.funeralDirector'));
          break;
        case 'informant':
          roleList.push(this.intl.t('model.tfe.signer.role.informant'));
          break;
        case 'next_of_kin':
          roleList.push(this.intl.t('model.tfe.signer.role.nextOfKin'));
          break;
        case 'primary_contact':
          roleList.push(this.intl.t('model.tfe.signer.role.primaryContact'));
          break;
        case 'purchaser':
          roleList.push(this.intl.t('model.tfe.signer.role.purchaser'));
          break;
      }
    });
    
    return roleList.join(', ');
  }

  get formattedStatus() {
    switch(this.status) {
      case 'canceled':
        return this.intl.t('model.tfe.signer.status.canceled');
      case 'completed':
        return this.intl.t('model.tfe.signer.status.completed');
      case 'declined':
        return this.intl.t('model.tfe.signer.status.declined');
      case 'pending':
        return this.intl.t('model.tfe.signer.status.pending');
      default:
        return this.intl.t('model.tfe.signer.status.pending');
    }
  }
}
