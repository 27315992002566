import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import Validations from 'crakn/validations/authenticated/admin/data/poems-prayers/poem';

export default class AuthenticatedAdminDataPoemsAndPrayersController extends SearchController {
  @service flashes;
  @service intl;
  @service store;

  queryParams = ['page', 'per_page', 'query'];

  @tracked query = null;
  @tracked page = 1;
  @tracked newPoem = null;

  @action
  create() {
    const poem = this.store.createRecord('v2/poem', {
      name: this.query,
      client: this.client
    });

    this.newPoem = Changeset(poem, lookupValidator(Validations), Validations);
    this.query = null;
  }

  @action
  save(record) {
    const isNew = record.get('isNew');

    record.validate().then(() => {
      if (record.isValid) {
        record
          .save()
          .then(() => {
            this.flashes.addSuccess(
              this.intl.t('authenticated.admin.data.poems-and-prayers.actions.save.success')
            );
            this.send('refreshModel');
            if (isNew) {
              this.newPoem = null;
            }
          })
          .catch(() => {
            this.error();
          });
      }
    });
  }

  @action
  delete(record) {
    record.data
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess(
          this.intl.t('authenticated.admin.data.poems-and-prayers.actions.delete')
        );
        this.send('refreshModel');
      })
      .catch(() => {
        this.error();
      });
  }

  @action
  cancel(record) {
    record.rollback();
  }

  @action
  cancelNewPoem() {
    this.newPoem = null;
  }

  @action
  error() {
    this.flashes.addError(
      this.intl.t('authenticated.admin.data.poems-and-prayers.actions.save.error')
    );
  }
}
