import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'transactionFee.name': validator('presence', true),
  'transactionFee.account.id': validator('presence', true)
});
export default class AuthenticatedAdminTributePayPaymentsAccountsEditController extends Controller.extend(Validations) {
  @service flashes;
  @service intl;
  @service router;

  chartsOfAccounts; // Set in the route.
  transactionFee; // Set in the route.

  @tracked chartOfAccountsId;
  @tracked showChartOfAccountsConfirmDialog = false;
  @tracked isChartOfAccountsLocked; // Set in the route.
  @tracked locationIntegrationServicesToDelete = [];
  @tracked newLocationIntegrationServices = [];
  @tracked newPaymentTypes = [];
  @tracked paymentTypesToDelete = [];
  @tracked glAccounts;
  @tracked showValidations = false;

  saveRequests(array, modelType, action) {
    if (array.length > 0) {
      return array.map((item) => {
        return new Promise((resolve) => {
          item.save().then(() => {
            resolve()
          }).catch(() => {
            if (modelType === 'Payment Type') {
              if (action === 'Save') {
                this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.edit.alerts.paymentTypes.saving.error'))
              } else if (action === 'Delete') {
                this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.edit.alerts.paymentTypes.deleting.error'));
              }
            } else if (modelType === 'Location Integration Service') {
              if (action === 'Save') {
                this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.edit.alerts.locations.saving.error'));
              } else if (action === 'Delete') {
                this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.edit.alerts.locations.deleting.error'));
              }
            }
          });
        });
      });
    } else {
      return [new Promise((resolve) => resolve())];
    }
  }

  @action
  setTransactionFeeAccount(id) {
    const glAccount = this.store.peekRecord('account', id);
    const coa = this.store.peekRecord('chartOfAccounts', this.chartOfAccountsId);

    set(this.transactionFee, 'chartOfAccounts', coa);
    set(this.transactionFee, 'account', glAccount);
  }

  @action
  cancel() {
    this.router.transitionTo('authenticated.admin.tribute-pay-payments.accounts.index');
  }

  @action
  save() {
    set(this, 'showValidations', false);

    this.validate().then(({validations}) => {
      if (validations.isValid) {
        this.transactionFee.save().then(() => {
          Promise.all(this.saveRequests(this.newPaymentTypes, 'Payment Type', 'Save')).then(() => {
            set(this, 'newPaymentTypes', []);

            Promise.all(this.saveRequests(this.paymentTypesToDelete, 'Payment Type', 'Delete')).then(() => {
              set(this, 'paymentTypesToDelete', []);

              Promise.all(this.saveRequests(this.newLocationIntegrationServices, 'Location Integration Service', 'Save')).then(() => {
                set(this, 'newLocationIntegrationServices', []);

                Promise.all(this.saveRequests(this.locationIntegrationServicesToDelete, 'Location Integration Service', 'Delete')).then(() => {
                  set(this, 'locationIntegrationServicesToDelete', []);

                  this.model.save().then(() => {
                    this.flashes.addSuccess(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.edit.alerts.tributePayAccount.saving.success'));

                    this.router.transitionTo('authenticated.admin.tribute-pay-payments.accounts.index');
                  }).catch(() => {
                    this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.edit.alerts.tributePayAccount.saving.error'));
                  });
                });
              })
            });
          });
        }).catch(() => {
          this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.edit.alerts.transactionFee.saving.error'))
        });

      } else {
        set(this, 'showValidations', true);
        console.log(validations)
      }
    })

  }

  @action
  enroll() {
    if (this.model.editAccountUrl) {
      window.open(this.model.editAccountUrl, '_blank');
    } else if (this.model.onboardingUrl) {
      window.open(this.model.onboardingUrl, '_blank');
    } else {
      window.open(this.model.tpsAgreementUrl, '_blank');
    }
  }

  @action
  setChartOfAccounts() {
    set(this, 'showChartOfAccountsConfirmDialog', false);
    set(this.model, 'chartOfAccountsId', this.chartOfAccountsId);

    this.model.save().then(() => {
      set(this, 'isChartOfAccountsLocked', true);

      const coa = this.store.peekRecord('chartOfAccounts', this.chartOfAccountsId);
      set(this.transactionFee, 'chartOfAccounts', coa);

      this.flashes.addSuccess(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.edit.alerts.settingChartOfAccounts.saving.success'));
    }).catch(() => {
      this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.edit.alerts.settingChartOfAccounts.saving.error'));
    })
  }

  @action
  setNewPaymentTypes(array) {
    set(this, 'newPaymentTypes', array);
  }

  @action
  setNewLocationIntegrationServices(array) {
    set(this, 'newLocationIntegrationServices', array);
  }

  @action
  fetchGLAccounts() {
    this.store.query('account', {
      per_page: 5000,
      chart_of_accounts_id: this.chartOfAccountsId,
    }).then((accounts) => {
      set(this, 'glAccounts', accounts);
    })
  }
}
