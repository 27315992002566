import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminTributePayPaymentsAccountsEditRoute extends Route {
  @service store;

  model(params) {
    return this.store.queryRecord('integration-service', { id: params.id });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    if (model.chartOfAccountsId) {
      controller.set('isChartOfAccountsLocked', true);
      controller.set('chartOfAccountsId', model.chartOfAccountsId);
    } else {
      controller.set('isChartOfAccountsLocked', false);
    }

    this.store.query('chartOfAccounts', { per_page: 1000 }).then((coas) => {
      controller.set('chartsOfAccounts', coas);
    });

    this.store.query('adjustmentType', {
      archived: false,
      integration_service_id: model.id
    }).then((adjustmentType) => {
      let transactionFee;

      if (adjustmentType.length > 0) {
        transactionFee = adjustmentType.firstObject;
      } else {
        transactionFee = this.store.createRecord('adjustmentType', {
          gain: true,
          isTributePayFee: true,
          integrationServiceId: model.id
        });
      }

      controller.set('transactionFee', transactionFee);
    })
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      if (controller.get('model.hasDirtyAttributes')) {
        controller.get('model').rollbackAttributes();
      }

      if (controller.get('newPaymentTypes').length > 0) {
        controller.get('newPaymentTypes').forEach((pt) => {
          pt.destroyRecord();
        });

        controller.set('newPaymentTypes', []);
      }

      if (controller.get('paymentTypesToDelete').length > 0) {
        controller.get('paymentTypesToDelete').forEach((pt) => {
          pt.rollbackAttributes();
        });

        controller.set('paymentTypesToDelete', []);
      }

      if (controller.get('newLocationIntegrationServices').length > 0) {
        controller.get('newLocationIntegrationServices').forEach((lis) => {
          lis.destroyRecord();
        });

        controller.set('newLocationIntegrationServices', []);
      }

      if (controller.get('transactionFee.hasDirtyAttributes')) {
        controller.get('transactionFee').rollbackAttributes();
      }

      if (controller.get('transactionFee.isNew')) {
        controller.get('transactionFee').destroyRecord();
      }

      if (controller.get('locationIntegrationServicesToDelete').length > 0) {
        controller.get('locationIntegrationServicesToDelete').forEach((lis) => {
          lis.rollbackAttributes();
        });

        controller.set('locationIntegrationServicesToDelete', []);
      }

      controller.set('isChartOfAccountsLocked', false);
      controller.set('chartOfAccountsId', null);
    }
  }
}
