import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Form::InputField\n    @placeholder={{this.placeholder}}\n    @disabled={{this.disabled}}\n    @value={{this.display}}\n    @update={{fn this.setDisplay}}>\n  <button\n      type=\"button\"\n      class=\"input__text--right btn btn--link light {{unless this.canReveal \"hidden\"}}\"\n      {{on \"click\" this.reveal}}>\n    <MdIcon @icon=\"visibility\" />\n  </button>\n</Form::InputField>\n", {"contents":"<Form::InputField\n    @placeholder={{this.placeholder}}\n    @disabled={{this.disabled}}\n    @value={{this.display}}\n    @update={{fn this.setDisplay}}>\n  <button\n      type=\"button\"\n      class=\"input__text--right btn btn--link light {{unless this.canReveal \"hidden\"}}\"\n      {{on \"click\" this.reveal}}>\n    <MdIcon @icon=\"visibility\" />\n  </button>\n</Form::InputField>\n","moduleName":"crakn/components/g/crakn/field/person-government-identifier.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/field/person-government-identifier.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class PersonGovernmentIdentifierComponent extends Component {
  @service api;
  @service flashes;
  @service session;
  @service intl;

  get placeholder() {
    if (this.session.currentClient.operating_country === 'CA') {
      return this.intl.t('components.g.kases.create.kase.input.socialInsuranceNumber.label');
    }

    return this.intl.t('components.g.kases.create.kase.input.socialSecurityNumber.label');
  }

  get display() {
    return this.args.person.personGovernmentIdentifier || this.paddedFragment;
  }

  get disabled() {
    return this.session.currentUser.permissions <= 1 || this.canReveal;
  }

  get paddedFragment() {
    if (!this.args.person.personGovernmentIdentifierFragment) {
      return null;
    }

    if (this.session.currentClient.operating_country === 'CA') {
      return `•••-•••-${this.args.person.personGovernmentIdentifierFragment}`;
    }

    return `•••-••-${this.args.person.personGovernmentIdentifierFragment}`;
  }

  get canReveal() {
    return (
      this.session.currentUser.permissions > 1 &&
      this.args.person.personGovernmentIdentifierFragment &&
      !this.args.person.personGovernmentIdentifier
    );
  }

  handleFetchSuccess(response) {
    this.args.person.personGovernmentIdentifier = response.person_government_identifier.full;
    this.args.person.personGovernmentIdentifierFragment = '';
  }

  willDestroy() {
    if (this.args.person?.personGovernmentIdentifier) {
      const fullIdentifier = this.args.person.personGovernmentIdentifier;
      this.args.person.personGovernmentIdentifierFragment = fullIdentifier.slice(-4);
      this.args.person.personGovernmentIdentifier = null;
    }
    super.willDestroy();
  }

  @action
  reveal() {
    const endpoint = `/people/${this.args.person.id}/person_government_identifier`;

    this.api.json
        .get(endpoint)
        .then((response) => {
          if (response.ok) {
            this.handleFetchSuccess(response.parsedJson);
          }
        });
  }

  @action
  setDisplay(value) {
    value = isEmpty(value) ? null : value;
    this.args.person.personGovernmentIdentifierFragment = null;
    this.args.person.personGovernmentIdentifier = value;
  }
}
