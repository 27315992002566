import Route from '@ember/routing/route';
import Controller from '@ember/controller';
import TextField from '@ember/component/text-field';
import LinkComponent from '@ember/routing/link-component';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';

export function initialize(_application) {
  Route.reopen({
    browser: service(),

    scroll: true,
    layout: 'default',
    isFullscreenActive: false,

    activate(_controller, _model) {
      this._super(...arguments);

      const application = this.controllerFor('application');

      set(application, 'isDropdownOpen', false);

      if (get(this, 'browser.isMobileWidth')) {
        set(application, 'sidebar.isSidebarOpen', false);
      }

      if (this.scroll) {
        window.scroll(0, 0);
      } else {
        set(this, 'scroll', true);
      }
    },

    refresh(_controller, _model) {
      set(this, 'scroll', false);
      this._super.apply(this, arguments);
    },

    setupController(_controller, _model, _transition) {
      this._super(...arguments);

      const application = this.controllerFor('application');
      const isFullscreenActive = this.isFullscreenActive;

      application.set('layout', `layouts/${this.layout || 'default'}`);
      application.set('isFullscreenActive', isFullscreenActive);
    }
  });

  Controller.reopen({
    /**
     * Inject `session` service since it is used throughout app for permissions
     */
    session: service('session')
  });

  TextField.reopen({
    autocomplete: 'off'
  });

  LinkComponent.reopen({
    attributeBindings: ['data-tt']
  });
}

export default {
  name: 'crakn-ember-init',
  initialize
};
