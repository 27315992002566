import { set, get, computed } from '@ember/object';
import SelectBox from 'crakn/components/form/select-box';

export default SelectBox.extend({
  placeholder: 'Taxable / Non-Taxable',
  value: computed('model', {
    get() {
      const model = this.model;
      return get(model, 'taxable') === false ? 'nontax' : 'tax';
    }
  }),

  actions: {
    setModel(value) {
      const model = this.model;
      set(model, 'taxable', value === 'tax' ? true : false);
    }
  }
});
