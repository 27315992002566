import Model, { belongsTo, attr } from '@ember-data/model';
import money from 'money-math';

export default class InventoryTransactionModel extends Model {
  @attr('string') note;
  @attr('price') price;
  @attr('price') cost;
  @attr('string') changeType;
  @attr('number') quantity;
  @attr('number') quantityRemaining;
  @attr('boolean') isAdjustment;
  @attr('moment-datetime') createdAt;
  @attr('string') receiveType;

  @belongsTo('price-change') priceChange;
  @belongsTo('inventory') inventory;

  get totalPrice() {
    return (parseFloat(money.floatToAmount(this.price)) * parseInt(this.quantity)).toFixed(2);
  }

  get displayQuantity() {
    if (this.changeType === 'Less') {
      return `-${this.quantity}`;
    } else {
      return this.quantity;
    }
  }
}
