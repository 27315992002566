import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['quick-add-task', 'well'],
  store: service(),
  task: null,

  init() {
    this._super(...arguments);

    this.set('task', this.store.createRecord('task'));
  },

  actions: {
    saveTask() {
      this.sendAction('onSave', this.task);
    },

    cancelTask() {
      this.task.rollbackAttributes();
      this.sendAction('onCancel');
    }
  }
});
