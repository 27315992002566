import { sections } from './jsonapi-normalizer';

export default content => sections(content, sectionKeys);

const sectionKeys = [
  {
    entity: 'kase',
    title: 'Deceased\'s Information',
    disabled: false,
    only: [
      'kase.deceased.*',
      'kase.disposition.dispositionMethod',
      'kase.deceased.birthplace.city',
      'kase.deceased.profile.placeOfDeath.city',
      'kase.deceased.profile.deathDate'
    ],
    exclude: [], // For show purposes only
    names: {
      'kase.deceased.profile.placeOfDeath.city': 'City of Death',
      'kase.deceased.birthplace.city': 'City of Birth',
      'kase.disposition.dispositionMethod': 'Interested In'
    }
  },
  {
    entity: 'kase',
    title: 'Spouse\'s Information',
    disabled: false,
    only: ['kase.spouse.*', 'kase.marriageDate'],
    exclude: ['kase.spouse.birthDate', 'kase.spouse.deathDate']
  },
  {
    entity: 'kase.veteranInfo',
    title: 'Veteran Information',
    disabled: false
  },
  {
    entity: 'kase.disposition.place',
    title: 'Cemetery Information',
    disabled: false
  },
  {
    entity: 'recipient',
    title: 'Filer\'s Information',
    disabled: false,
    only: [
      'recipient.firstName',
      'recipient.lastName',
      'recipient.phonePrimary',
      'recipient.email'
    ]
  }
];
