import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Autocomplete::Base\n    {{! template-lint-disable attribute-indentation }}\n    @autocomplete={{@autocomplete}}\n    @dataTest={{@dataTest}}\n    @debounce={{this.debounce}}\n    @disabled={{@disabled}}\n    @icon={{@icon}}\n    @onDirtyFocusOut={{@onDirtyFocusOut}}\n    @onFocusOut={{@onFocusOut}}\n    @onIconClick={{@onIconClick}}\n    @onInput={{this.input}}\n    @onSearch={{this.search}}\n    @onSelect={{this.select}}\n    @placeholder={{@placeholder}}\n    @results={{this.results}}\n    @value={{this.value}}\n    @zIndex={{@zIndex}}\n    as |item|>\n  {{#if (has-block)}}\n    {{yield item}}\n  {{else}}\n    {{item}}\n  {{/if}}\n\n</G::Crakn::Autocomplete::Base>\n", {"contents":"<G::Crakn::Autocomplete::Base\n    {{! template-lint-disable attribute-indentation }}\n    @autocomplete={{@autocomplete}}\n    @dataTest={{@dataTest}}\n    @debounce={{this.debounce}}\n    @disabled={{@disabled}}\n    @icon={{@icon}}\n    @onDirtyFocusOut={{@onDirtyFocusOut}}\n    @onFocusOut={{@onFocusOut}}\n    @onIconClick={{@onIconClick}}\n    @onInput={{this.input}}\n    @onSearch={{this.search}}\n    @onSelect={{this.select}}\n    @placeholder={{@placeholder}}\n    @results={{this.results}}\n    @value={{this.value}}\n    @zIndex={{@zIndex}}\n    as |item|>\n  {{#if (has-block)}}\n    {{yield item}}\n  {{else}}\n    {{item}}\n  {{/if}}\n\n</G::Crakn::Autocomplete::Base>\n","moduleName":"crakn/components/g/crakn/autocomplete/dynamic.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/autocomplete/dynamic.hbs"}});
import AutocompleteBase from './base'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
// Observers are REQUIRED for this until Ember Use and Resources are complete
// eslint-disable-next-line ember/no-observers
import { addObserver, removeObserver } from '@ember/object/observers';

/**
 * An autocomplete input that uses the args.onSearch to get an array of results to display
 * 
 * ## Arguments
 * - **placeholder** - *optional* the label for the autocomplete
 * - **value** - *optional* *default empty* the input value
 * - **disabled** - *optional* *default false* boolean for disabling the input
 * - **icon** - *optional* the icon to display in the right of the autocoomplete.  will not display
 *              without args.onIconClick
 * - **debounce** - *optional* *default 300* the delay in milliseconds between finish typing and the 
 *                  search function occuring
 * - **onSearch** - this function to run when the input is typed in.  this function needs to
 * 									return an array of results to display in the dropdown.
 * - **onSelect** - the function to run when an item in the dropdown is selected.  If this 
 * 									function returns a value, it will be assigned to this.value
 * - **onInput** - *optional* the function to run when the input is typed into. if this is not provided 
 * 									the autocomplete is considered strict and will revert any value that that wasn't 
 * 									click on the in dropdown back to the last clicked value or the original value
 * - **onIconClick** - *optional* the function to run when the icon is clicked.  will not work without
 *                    args.icon
 * - **onDirtyFocusOut** - *optional* the function to run when the input has been interacted with 
 * 										(meaning the autocomplete is open), and the user clicked outside the provided 
 * 										dropdown
 * - **onFocusOut** - *optional* the function to run when the input has been interacted with 
 * 										(meaning the autocomplete is open), and the user clicked outside the provided 
 * 										dropdown
 * 
 * ## Useage
 * 
 * ### Non-Strict Mode
 * Anything typed into the input will be sent up through the onInput function, which 
 * should ultimately save the value
 * 
 * #### NOTE
 * Dynamic Autocomplete is often used to associate a relationship.  In Non-Strict mode make sure you are
 * properly setting the relationship up in args.onInput as it can throw errors if the wrong
 * type is set.  This is not an issue for simple fields like Strings
 * 
 * ```handlebars
 * 
 * <G::Crakn::Autocomplete::Dynamic
 * 		 @placeholder="Event Name"
 * 		 @onSearch={{this.eventNameSearch}}
 * 		 @onSelect={{set this "model.name"}}
 * 		 @onInput={{set this "model.name"}}/>
 * ```
 * 
 * ### Strict Mode
 * Anything typed into the input will be ignored.  Only values selected in the dropdown will be 
 * considered valid.  Navigating away from the input with an invalid value will revert the value to the 
 * last valid value or the original value
 * 
 * ```handlebars
 * <G::Crakn::Autocomplete::Dynamic
 * 		 @placeholder="Case"
 * 		 @onSearch={{this.typeaheadSearch}}
 * 		 @onSelect={{this.setKase}}
 * 		 @onDirtyFocusOut={{this.toggleCreateKase}} />
 * ```
 * 
 * 
 * @class GCraknDynamicAutocomplete
 * @extends GCraknAutocompleteBase
 * @public
 */

export default class GCraknDynamicAutocomplete extends AutocompleteBase {
	/**
	 * The results of a search to be displayed
	 * @type {string[]}
	 */
	@tracked results = [];

	/**
	 * The query used to search as well as the display in the input
	 * @type {string}
	 */
	@tracked value;

	/**
	 * A cached value to reapply on focusout if there is no onInput function supplied
	 * @type {string}
	 */
	cachedValue;

	get searcherMode() {
		if (this.args.searcherMode === undefined || this.args.searcherMode === null) {
			return false;
		}

		return this.args.searcherMode;
	}

	/**
	 * The debounce delay or default debounce delay
	 * @type {number}
	 */
  get debounce() {
    return this.args.debounce || 100;
  }

	/**
	 * Creats an instance of the Component
	 * 
	 * ## Functional Explaination
	 * Caches a local value for args.value rather than directly operating on the argument, 
	 * making this one-way bound
	 * Creates an observer to keep the interval value synced with the args.value.  **NOTE: This WILL**
	 * **BE REMOVED ONCE EMBER USE AND RESOURCES IS COMPLETE**
	 * 
	 * @param {*} owner - supplied via Emberjs
	 * @param {*} args  - The arguments passed to the component. supplied via Emberjs
	 */	
	constructor(owner, args) {
		super(owner, args);

		this.value = this.args.value;
		addObserver(this, 'args.value', this, this.observeValue)
	}

	/**
	 * Tears down the Component
	 * 
	 * ## Functional Explaination
	 * Removes all observers created
	 */
  willDestroy() {
		removeObserver(this, 'args.value', this, this.observeValue)
	}

	/**
	 * This function will assign the current value to that of the args.value
	 */
	@action
	observeValue() {
		this.value = this.args.value;
	}

  /**
	 * A cached value to compare to the result so we only display the last search result
   * Only used if this.args.useLastSeachText == true
	 * @type {string}
	 */
  lastSearchText = null;

	/**
	 * This function calls out to args.onSearch and assigned this.results to what the call returns
	 * 
	 * @param {*} val - the search query
	 */
	@action
	async search() {
    this.lastSearchText = this.args.useLastSearchText ? this.value : undefined;

    const results = await this.args.onSearch(this.value);
    
    if (this.args.useLastSearchText) {
      const query = results?.query?.kase_query || results?.query?.query;

      const isLastQuery = query === this.lastSearchText;
      
      if (isLastQuery) this.results = results;
    } else {
     this.results = results;
    }
	}

	/**
	 * The function that runs when a dropdown item has been selected.  It calls out to args.onSelect 
	 * and if onSelect returns a value, will assign this.value to that. Otherwise it will set value
	 * to undefined. Note that this response WILL NOT change whatever is bound to args.value.
	 * 
	 * This function also clears the results so none can be displayed.
	 * This function also clears cachedValue.
	 * 
	 * @param {object} item - the item to assign value to
	 */
	@action
	select(item) {
		this.results = [];
		const value = this.args.onSelect(item);

		if (value) {
			const isPromise = Boolean(value && typeof value.then === 'function');
			if (isPromise) {
				value.then((v) => { this.value = v; });
			} else {
				this.value = value;
			}
		}
  }
	
	/**
	 * The function that runs when the input is typed into
	 * 
	 * This function stores a cache of the original value
	 * 
	 * @param {string} val - the item to assign value to
	 */
	@action
	input(val) {
		if (!this.args.onInput && !this.cachedValue) {
			this.cachedValue = this.value;
		}

		this.value = val;

		if (this.args.onInput) {
			this.args.onInput(val);
		}
	}

	/**
	 * The function that runs when the autocomplete loses focus.  It is used to restore cache if there is one present.
	 */	
	@action
	focusOut() {
		if (this.cachedValue && !this.searcherMode) {
			this.value = this.cachedValue;
		}
	}
}
