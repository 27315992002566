import SearchController from 'crakn/controllers/search';
import { sort } from '@ember/object/computed';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class CraknOnlinePlannerUsersController extends SearchController {
  sorting = ['name:asc'];
  @sort('model', 'sorting') sortedContent;
  @service flashes;
  @service api;
  @service intl;
  @service router;
  @tracked pendingAddProducts = [];
  @tracked pendingRemoveProducts = [];

  @computed('pendingAddProducts', 'existingProducts', 'pendingRemoveProducts', 'model')
  get checkedProducts() {
    return [...new Set([].concat(this.pendingAddProducts, this.existingProducts))];
  }

  get existingProducts() {
    return this.model.onlinePlannerProducts
      .toArray()
      .filter((x) => !x.archivedAt)
      .map((olpProduct) => olpProduct.get('product.id'))
      .filter((x) => !this.pendingRemoveProducts.includes(x));
  }

  @action
  selectAllOnPage() {
    const allProducts = this.model.products.toArray();
    const allSelected = allProducts.every((x) => this.checkedProducts.includes(x.id));

    if (allSelected) {
      allProducts.forEach((product) => this.removeOlpProduct(product));
    } else {
      allProducts.forEach((product) => {
        if (!this.checkedProducts.includes(product.id)) {
          this.addOlpProduct(product)
        }
      });
    }
  }

  @action
  deselectAllOnPage() {
    const allProducts = this.model.products.toArray();

    allProducts.forEach((product) => this.removeOlpProduct(product));
  }

  @action
  addOlpProduct(product) {
    if (this.pendingRemoveProducts.includes(product.id)) {
      this.pendingRemoveProducts = [...this.pendingRemoveProducts.filter(x => x !== product.id)];
    } else {
      const found = this.pendingAddProducts.find((x) => x === product.id);
      if (found) return;

      this.pendingAddProducts = [...this.pendingAddProducts, product.id];
    }
  }

  @action
  removeOlpProduct(product) {
    const found = this.pendingAddProducts.find((x) => x === product.id);

    if (found) {
      this.pendingAddProducts = [...this.pendingAddProducts.filter(x => x !== product.id)];
    } else {
      this.pendingRemoveProducts = [...this.pendingRemoveProducts, product.id];
    }
  }

  @action
  async addAllProductsFromCategory() {
    try {
      await this.checkApiResponse(this.api.json.post('v2/online_planner_products/bulk_add_by_category', {
        body: {
          online_planner_config_id: this.configId,
          product_category_id: this.olpProductCategory.categoryId
        }
      }));
      this.pendingAddProducts = [];
      this.pendingRemoveProducts = [];
      this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.config.edit-category.saveSuccess'));
      this.send('refreshModel');
    } catch (error) {
      this.flashes.addError(error.message);
    }
  }

  @action
  async removeAllProductsFromCategory() {
    try {
      await this.checkApiResponse(this.api.json.post('v2/online_planner_products/bulk_remove_by_category', {
        body: {
          online_planner_config_id: this.configId,
          product_category_id: this.olpProductCategory.categoryId
        }
      }));
      this.pendingAddProducts = [];
      this.pendingRemoveProducts = [];
      this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.config.edit-category.saveSuccess'));
      this.send('refreshModel');
    } catch (error) {
      this.flashes.addError(error.message);
    }
  }

  @action
  async save() {
    const bulkAddBody = {
      online_planner_config_id: this.configId,
      product_ids: this.pendingAddProducts
    };

    const bulkRemoveBody = {
      online_planner_config_id: this.configId,
      product_ids: this.pendingRemoveProducts
    };

    try {
      await this.checkApiResponse(this.api.json.post('v2/online_planner_products/bulk_add', { body: bulkAddBody }));
      await this.checkApiResponse(this.api.json.post('v2/online_planner_products/bulk_remove', { body: bulkRemoveBody }));
      this.flashes.addSuccess(
        this.intl.t('authenticated.admin.online-planner.config.edit-category.saveSuccess')
      );
      this.send('refreshModel');
    } catch (error) {
      this.flashes.addError(error.message);
    }
  }

  async checkApiResponse(responsePromise) {
    const response = await responsePromise;

    if (!response.ok) {
      throw new Error(this.intl.t('authenticated.admin.online-planner.config.edit-category.saveError'));
    }

    return response;
  }

  @action
  navBack() {
    this.router.transitionTo(`/admin/online-planner/${this.configId}/edit/page/selections`);
  }
}
