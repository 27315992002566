import Controller from '@ember/controller';
import Search from 'crakn/mixins/search';

export default Controller.extend(Search, {
  actions: {
    new() {
      this.transitionToRoute('authenticated.admin.manage.general-price-lists.new');
    }
  }
});
