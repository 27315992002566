import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import SelectBox from './select-box';
import provinces from 'crakn/utils/za-provinces';

export default SelectBox.extend({
  session: service(),

  value: null,
  layoutName: 'components/form/select-box',
  disabled: false,
  placeholder: 'Province',
  options: provinces,

  didInsertElement() {
    this._super(...arguments);
    const value = this.value;
    // TODO: API changes to make the word 'state' more generic will need to be done later.
    const defaultProvince = this.get('session.currentUser.defaultState');

    if (isEmpty(value) && !isEmpty(defaultProvince)) {
      this.set('value', defaultProvince);
    }
  }
});
