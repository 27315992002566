import { set, get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),

  model(params) {
    if (params.chartOfAccountsId) {
      return this.store
        .findRecord('v2/chartOfAccounts', params.chartOfAccountsId)
        .then(result => {
          return this.store.createRecord('v2/accounting-export', {
            chartOfAccounts: result
          });
        });
    } else {
      return this.store.createRecord('v2/accounting-export');
    }
  },

  setupController(controller, model) {
    this._super(...arguments);
    const store = this.store;

    store
      .query('v2/chart-of-accounts', {
        'valid-configuration': true
      })
      .then(results => {
        set(controller, 'chartsOfAccounts', results);
        if (get(controller, 'chartOfAccountsId')) {
          store
            .findRecord(
              'v2/chartOfAccounts',
              get(controller, 'chartOfAccountsId')
            )
            .then(result => {
              set(model, 'chartOfAccounts', result);
            });
        }
      });
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('showComponent', false);
    }
  }
});
