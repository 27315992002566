import Model, { belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'account.id': validator('presence', {
    presence: true,
    message: 'The Account field can\'t be blank'
  }),
  'name': validator('presence', {
    presence: true,
    message: 'The Name field can\'t be blank'
  }),
});

export default class AdjustmentTypeModel extends Model.extend(Validations) {
  @attr('moment-datetime', { defaultValue: null }) archivedAt;
  @attr('boolean', { defaultValue: true }) gain;
  @attr('string') integrationServiceId;
  @attr('boolean', { defaultValue: false }) isTributePayFee;
  @attr('string') name;
  @attr('string') netsuiteId;

  @belongsTo('account') account;
  @belongsTo('chart-of-accounts') chartOfAccounts;
}
