import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  clientName: validator('presence', true),
  firstName: validator('presence', true),
  lastName: validator('presence', true),
  email: validator('presence', true),
  password: [validator('presence', true), validator('length', { min: 5 })],
});

export default class DemoClientRequestModel extends Model.extend(Validations) {
  @attr('string') clientName;
  @attr('string') firstName;
  @attr('string') lastName;
  @attr('string') email;
  @attr('string') password;
  @attr('number') expiresIn;
}
