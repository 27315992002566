import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class KasesManageEditDocumentsRoute extends Route {
  @service router;

  redirect() {
    this.router.transitionTo('authenticated.kases.manage.documents.secure-documents');
  }
}
