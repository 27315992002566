import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Field::Input\n    @inputId={{this.inputId}}\n    @value={{this.value}}\n    @placeholder={{@placeholder}}\n    @disabled={{@disabled}}\n    @leftIcon={{@leftIcon}}\n    @leftIconClass={{@leftIconClass}}\n    @rightIcon={{@rightIcon}}\n    @type=\"text\"\n    @min={{@min}}\n    @max={{@max}}\n    @validationMessages={{@validationMessages}}\n    @showValidations={{@showValidations}}\n    @onFocus={{this.focus}}\n    @onBlur={{this.blur}}\n    @onKeyUp={{fn this.onKeyPress}}\n    @onKeyDown={{fn this.onKeyPress}}\n    @useNativeInput={{true}}\n    @dataTest={{@dataTest}}\n    ...attributes\n    {{did-insert this.setup}}/>\n", {"contents":"<G::Crakn::Field::Input\n    @inputId={{this.inputId}}\n    @value={{this.value}}\n    @placeholder={{@placeholder}}\n    @disabled={{@disabled}}\n    @leftIcon={{@leftIcon}}\n    @leftIconClass={{@leftIconClass}}\n    @rightIcon={{@rightIcon}}\n    @type=\"text\"\n    @min={{@min}}\n    @max={{@max}}\n    @validationMessages={{@validationMessages}}\n    @showValidations={{@showValidations}}\n    @onFocus={{this.focus}}\n    @onBlur={{this.blur}}\n    @onKeyUp={{fn this.onKeyPress}}\n    @onKeyDown={{fn this.onKeyPress}}\n    @useNativeInput={{true}}\n    @dataTest={{@dataTest}}\n    ...attributes\n    {{did-insert this.setup}}/>\n","moduleName":"crakn/components/g/crakn/field/masked-input.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/field/masked-input.hbs"}});
import Component from '@glimmer/component';
import IMask from 'imask';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';
import { omitBy } from 'lodash-es/object'
import { isNil } from 'lodash-es/lang'

/**
 * A masked material input.
 * 
 * This component must use either onFocus or onBlur for iMask to work correctly on Safari - see https://github.com/uNmAnNeR/imaskjs/issues/324
 * 
 * ## Arguments
 * - **value** - the value of the input
 * - **type** - *optional* the html type of the input
 * - **mask** - *optional* an optional mask to apply to the input
 * - **disabled** - *optional* *default false* boolean for disabling the input
 * - **maxlength** - *optional* the maximum number of characters the input can be
 * - **max** - *optional* only used when the type is `number`.  the maximum the number is allowed to be
 * - **min** - *optional* only used when the type is `number`.  the minimum the number is allowed to be
 * - **validationMessages** - *optional* an optional single string or array of string to display as error messages
 * - **showValidations** - *optional* *default: true* a boolean whether or not to display validation messages 
 * - **onBlur** - the function to fire on the `blur` javascript event
 * - **onFocus** - *optional* the function to fire on the `focus` javascript event
 */

export default class GCraknFieldInputComponent extends Component {
  inputId = 'field-mask-input-' + guidFor(this);
  maskedElement = null;

  get value() {
    if ((this.args.value || this.args.value === 0) && this.maskedElement) {
      return IMask.pipe(this.args.value.toString(), this.maskedElement.masked.currentMask);
    } else {
      return this.args.value === 0 ? this.args.value.toString() : this.args.value;
    }
  }

  get mask() {
    switch (this.args.mask) {
      case 'age':
        return [
          { 
            id: 'number',
            mask: '000000',
            min: 0,
          },
        ]
      case 'phone':
          return [
            { 
              id: 'us-simple',
              mask: '000-0000'
            },
            { 
              id: 'us-full',
              mask: '(000) 000-0000'
            },
            { 
              id: 'fallback',
              mask: '000000000000000'
            }
          ]
      case 'price':
          return [
            {mask: '' },
            {
              mask: Number,
              normalizeZeros: true,
              padFractionalZeros: true,
              radix: '.',
              scale: 2,
              thousandsSeparator: ','
            }
          ]
      case 'quantity':
        return [
          { 
            mask: Number,
            max: 9999999,
            min: 0,
            normalizeZeros: true,
            scale: 0,
            thousandsSeparator: ''
          },
        ]
      default:
        return null;
    }
  }
  
  get dispatch() {
    let dispatch;
    switch (this.args.mask) {
      case 'phone':
        dispatch = (appended, dynamic) => {
          const length = (dynamic.unmaskedValue + appended).length;
          const compiledMasks = dynamic.compiledMasks;

          // Example of how to observe the number and change the format.
          if ( length <= 4 || length > 10 ) {
            return compiledMasks.find((m) => m.id == 'fallback');
          } else if (length > 4 && length <= 7) {
            return compiledMasks.find((m) => m.id == 'us-simple');
          } else {
            return compiledMasks.find((m) => m.id == 'us-full');
          }
        }
    }

    return dispatch
  }

  shouldPreventKeypress(keyCode, key) {
    const keyIsZero = (keyCode === 48 || key === '0');
    const valueIsZeroOrLess = Number(this.maskedElement.unmaskedValue) <= 0;
    return this.args.mask === 'quantity' && (keyIsZero && valueIsZeroOrLess);
  }

  @action
  setup() {
    const options = omitBy({ mask: this.mask, dispatch: this.dispatch }, isNil);
    this.maskedElement = IMask(document.getElementById(this.inputId), options);
  }

  @action
  onKeyPress(event) {
    if (this.shouldPreventKeypress(event.keyCode, event.Key)) {
      event.preventDefault();
    }
  }

  @action
  focus(event) {
    this.maskedElement.updateValue();
    if (this.args.onFocus) {
      this.args.onFocus(this.maskedElement.unmaskedValue, event);
    }
  }

  @action
  blur(event) {
    if (this.args.onBlur) {
      this.args.onBlur(this.maskedElement.unmaskedValue, event);
    }
  }
}
