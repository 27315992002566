import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2CremationAuthorizationModel extends Model {
  @attr('string', { defaultValue: 'agent' }) identificationMethod;
  @attr('string') implantsDescription;
  @attr('string') otherDeliveryDescription;
  @attr('string') personalPropertyDescription;
  @attr('string', { defaultValue: 'discard' }) personalPropertyTreatment;
  @attr('string') radioactivesDescription;
  @attr('string') remainsApportionment;
  @attr('string', { defaultValue: 'funeralhome' }) remainsDeliverer;
  @attr('string', { defaultValue: 'cemetery' }) remainsDeliveryMethod;
  @attr('string') returnImplantsDescription;
  @attr('string') uuid; /** For eSign */
  
  @attr('boolean', { defaultValue: true }) disposeImplants;
  @attr('boolean', { defaultValue: false }) hasImplants;
  @attr('boolean', { defaultValue: false }) hasRadioactives;
  @attr('boolean', { defaultValue: false }) removeImplants;
  
  @attr('number') daysToHoldRemains;

  @belongsTo('v2/kase') kase;

  @hasMany('v2/event') events;

  /** `async: false` keeps ember from trying to GET individual records, since
   * that endpoint doesn't exist. It was trying to get records after they
   * were unloaded manually, which causes the unloaded record to show up on
   * the screen during the request. Might not be necessary in V2. */
  @hasMany('esigned-cremation-authorization', { async: false }) esignedCremationAuthorizations;
}
