import Controller from '@ember/controller';
import config from 'crakn/config/environment';

export default class ReportsController extends Controller {
  get canViewNoReports() {
    if (
      this.get('model.canViewArAging') === false &&
      this.get('model.canViewFamilyFriends') === false &&
      this.get('model.canViewMonthlyDeceased') === false &&
      this.get('model.canViewDomanicare') === false &&
      this.get('model.canViewDetailedTaxableItems') === false &&
      this.get('model.canViewMerchandiseProfit') === false &&
      this.get('model.canViewSalesTax') === false &&
      this.get('model.canViewTennesseeFuneralDirectors') === false &&
      this.get('model.canViewTennesseeCrematories') === false &&
      this.get('model.canViewInventoryQuantity') === false &&
      this.get('model.canViewInventoryAsOf') === false &&
      this.get('model.canViewPaymentReport') === false &&
      this.get('model.canViewNextOfKinReport') === false &&
      this.get('model.canViewPreneedFundingReport') === false &&
      this.get('model.canViewDecedentReport') === false &&
      this.get('model.canViewPrecoaDecedentReport') === false &&
      this.get('model.canViewCaseRevenueOverviewReport') === false &&
      this.get('model.canViewReferralInfoReport') === false &&
      this.get('model.canViewBatchPrintSoasWithBalance') === false &&
      this.get('model.canViewPurchaserReport') === false &&
      this.get('model.canViewCovidReport') === false &&
      this.get('model.canViewFloridaMonthlyReport') === false &&
      this.get('model.canViewDetailedItemsReport') === false &&
      this.get('model.canViewFloridaCineratorReport') === false &&
      this.get('model.canViewBeaconMonthlyReconciliationReport') === false &&
      this.get('model.canViewArAgingByLocationAndCase') === false &&
      this.get('model.canViewCaseCountByCaseTagReport') === false
    ) {
      return true;
    } else {
      return false;
    }
  }

  get isEnvironmentEnterpriseOrLocalhost() {
    return config.host.includes('enterprise') || config.host.includes('localhost');
  }
}
