import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2OnlinePlannerSessionModel extends Model {
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') updatedAt;
  @attr('moment-datetime') submittedAt;
  @attr('moment-datetime') archivedAt;
  @attr('boolean') submitted;
  @attr('object') data;
  @attr('string') kaseId;
  @attr('string') planningFor;
  @attr('string') status;
  @attr('string') uuid;
  @attr('price') balance;
  @attr('price') discountsTotal;
  @attr('price') shippingTotal;
  @attr('price') paymentsTotal;
  @attr('price') salesTax;
  @attr('price') subtotal;
  @attr('price') subtotalAfterDiscounts;
  @attr('price') total;

  @belongsTo('v2/client', { async: true }) client;
  @belongsTo('v2/online-planner-user', { async: true }) onlinePlannerUser;
  @belongsTo('v2/online-planner-config', { async: true }) onlinePlannerConfig;
  @belongsTo('v2/kase', { async: true }) kase;
  @hasMany('v2/online-planner-session-product', { async: true })
  onlinePlannerSessionProducts;
  @hasMany('v2/online-planner-session-package', { async: true })
  onlinePlannerSessionPackages;
  @hasMany('v2/online-planner-session-payments', { async: true })
  onlinePlannerSessionPayments;


  get planType() {
    const overview = this.data?.pages?.find(x => x.name === 'overview-questions');
    const planTypePage = overview?.pages?.find(x => x.name === 'plan-type');
    const fields = planTypePage?.sections
    ?.map((section) => {
      return section.content.filter((element) => {
        return ['input', 'select', 'date', 'checkbox', 'text-area', 'radio'].includes(
          element.type
        );
      });
    })
    .flat();

    const field = fields?.find(x => x.name === 'plan.type');

    switch (field?.value) {
      case 'future':
        return 'Preneed';
      case 'immediate':
        return 'At Need';
      default:
        return '';
    }
  }

  get hasPayment() {
    return this.onlinePlannerSessionPayments?.filter(x => x.processorPending === false)?.length > 0;
  }
}
