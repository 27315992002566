import { notEmpty } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import FormInput from 'crakn/mixins/form-input';
import { isEmpty } from '@ember/utils';
import { dasherize } from '@ember/string';
import moment from 'moment';

export default Component.extend(FormInput, {
  classNames: ['input', 'input--standard'],
  classNameBindings: ['hasValue:input--has-value', 'styleClass'],

  hasValue: notEmpty('value'),

  browser: service(),

  value: null,
  placeholder: null,
  theme: null,
  disabled: false,
  yearRange: '1900,currentYear',
  format: 'MMM D, YYYY',
  firstDay: 0,
  style: 'standard',
  required: false,

  pikaDayValue: computed('value', function() {
    const value = this.value;
    return isEmpty(value) ? null : value.toString();
  }),

  pikadayClass: computed('placeholder', 'qaClass', function() {
    return `${this.qaClass} input__field`;
  }),

  styleClass: computed('style', function() {
    return `input--${this.style}`;
  }),

  qaClass: computed('placeholder', function() {
    return dasherize(`qa-${this.placeholder}`);
  }),

  actions: {
    onSelection(value) {
      // `moment(null)` is NaN. This makes sure you can delete the date in the
      // UI, and the resulting value will be `null`, not `NaN`.
      const momentValue = value === null ? value : moment(value);

      set(this, 'value', momentValue);
      set(this, 'pikaDayValue', momentValue);
      this.sendAction('onSelection', momentValue);
    }
  }
});
