import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AdminOnlinePlannerPageController extends Controller {
  @service flashes;
  @service intl;
  @service onlinePlanner;
  @tracked page = undefined;
  @tracked customFields = undefined;
  @tracked plannerErrors = undefined;
  @tracked showValidation = false;

  defaultValuesAreValid() {
    const hasDefaultValue = (pageName, fieldName) => {
      const emberPlanner = this.onlinePlanner.findOrCreateEmberPlanner(this.model.id);
      const overviewPage = emberPlanner.pages.find(x => x.name === 'overview-questions');
      const page = overviewPage.pages.find(page => page.name === pageName);
      if (!page.hide && !overviewPage.hide) return true; // if the page is not hidden, then it's valid
      const field = page.fields.find(field => field.name === fieldName);
      return field.value ? true : false;
    }

    const typeValid = hasDefaultValue('plan-type', 'plan.type');
    const dispositionValid = hasDefaultValue('disposition-type', 'disposition.type');
    const planningForValid = hasDefaultValue('planning-for', 'plan_for');

    return typeValid && dispositionValid && planningForValid;
  }

  @action
  async save() {
    const valid = this.defaultValuesAreValid();
    if (!valid) {
      this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.page.fields.defaultValueField.error'));
      return;
    }

    this.onlinePlanner.savePlanner(this.model.id)
    .then(()=>{
      this.showValidation = false;
      this.plannerErrors = undefined;
      this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.edit.overview.save.success'));
      this.dataChanged = false;
    }, (e)=> {
      this.plannerErrors = e;
      this.showValidation = true;
      this.flashes.addError(this.intl.t('authenticated.admin.online-planner.edit.overview.save.error'));
    });
  }
}
