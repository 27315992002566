import { registerWarnHandler } from '@ember/debug';

const COAWARNING = /Encountered "charts_of_accounts" in payload, but no model was found for model name "charts-of-account" \(resolved model name using/;

export function initialize() {
  registerWarnHandler((message, options, next) => {
    if (COAWARNING.test(message)) {
      return;
    } else {
      next(message, options);
    }
  });
}

export default { initialize };
