import { dasherize } from '@ember/string';

export const idClassBinding = 'idClass';

/**
  * The function below will require that you define a property on the
  * component that identifies the ID of said component's model.
  * The recommended definition is as follows: */ 
      //@readOnly('path.to.id') idKey;
 /**
  * A function that builds an ID class name that can be used when testing.
  * 
  * @param {number} idKey The ID of the component's model.
  * @returns {string} The ID class name.
  */
export const idClass = (idKey) => idKey ? `id-${dasherize(idKey)}` : null;