import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class CraknClientsEditRoute extends Route {
  model(params) {
    return this.store.findRecord('client', params.client_id);
  }
  
  setupController(controller, model) {
    super.setupController(...arguments);

    controller.set('client', model);
  }

  @action
  refreshModel() {
    this.refresh();
  }

  @action
  willTransition(transition) {
    if (this.currentModel.get('hasDirtyAttributes')) {
      if (confirm('You have unsaved changes. Are you sure?')) {
        this.currentModel.rollbackAttributes();
        return true;
      } else {
        transition.abort();
      }
    }
  }
}
