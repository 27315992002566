import { computed, get } from '@ember/object';
import Session from 'ember-simple-auth/services/session';

export function initialize(application) {
  const store = application.lookup('service:store');

  Session.reopen({
    currentUser: computed('data.authenticated.user.id', function() {
      const id = get(this, 'data.authenticated.user.id');
      if (id) {
        store.pushPayload('user', {
          user: get(this, 'data.authenticated.user')
        });

        return store.peekRecord('user', id);
      }
    }),

    currentClient: computed('data.authenticated.client.id', function() {
      return get(this, 'data.authenticated.client');
    })
  });
}

export default {
  name: 'crakn-simple-auth',
  initialize
};
