import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class CustodyAuditCreate extends Component {
  @tracked showValidation = false;
  @tracked isOpen = false;
  @tracked custodyAudit;
  @tracked kasePersonalEffects;
  @tracked startRooms = [];
  @tracked endRooms = [];

  @service store

  constructor(owner, args) {
    super(...arguments);
    this.custodyAudit = args.custodyAudit;
    this.kasePersonalEffects = this.args.kase.personalEffects.filter(pe => pe.id);
  }

  get startPlaceIsDecedentsPlaceOfDeath() {
    return this.custodyAudit.startPlaceIsDecedentsPlaceOfDeath;
  }

  get kase() {
    return this.args.kase;
  }

  async setPlace(place, key) {
    if (place === null) {
      this.custodyAudit.set(key, null);
      return;
    }
    this.custodyAudit.set(key, await this.store.findRecord('v2/place', place.id));
  }

  async findRoomsFromPlace(placeId) {
    return await this.store.query('v2/room', { place_id: placeId, per_page: 0 });
  }

  @action
  async createCustodyAudit() {
    const {validations} = await this.custodyAudit.validate();
    const valid = validations.get('isValid');
    this.showValidation = !valid;
    if (valid) {
      if (!this.args.onCreate) {
        return;
      }
      this.args.onCreate(this.custodyAudit);
      this.startRooms = [];
      this.endRooms = [];
      this.toggleOpen();
    }
  }

  @action
  revertCustodyAudit() {
    if (!this.args.onCancel) {
      return;
    }

    this.args.onCancel(this.custodyAudit);
  }

  @action
  toggleOpen() {
    const newValue = !this.isOpen;
    this.isOpen = newValue;
    this.args.onToggle(newValue);
  }

  @action
  togglePersonalEffect(personalEffect) {
    const exists = this.custodyAudit.personalEffects.includes(personalEffect);
    if (exists) {
      this.custodyAudit.personalEffects.removeObject(personalEffect);
    } else {
      this.custodyAudit.personalEffects.addObject(personalEffect);
    }
  }

  @action
  setStartRoom(roomId) {
    if (roomId === '') {
      this.custodyAudit.startRoom = null;
      return;
    }
    this.custodyAudit.startRoom = this.startRooms.findBy('id', roomId);
  }

  @action
  setEndRoom(roomId) {
    if (roomId === '') {
      this.custodyAudit.endRoom = null;
      return;
    }
    this.custodyAudit.endRoom = this.endRooms.findBy('id', roomId);
  }

  @action
  async setStartPlace(place) {
    this.setPlace(place, 'startPlace');
    this.startRooms = place === null ? [] : await this.findRoomsFromPlace(place.id);
  }

  @action
  async setEndPlace(place) {
    this.setPlace(place, 'endPlace');
    this.endRooms = place === null ? [] : await this.findRoomsFromPlace(place.id);
  }
}
