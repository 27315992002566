import SearchRoute from 'crakn/routes/search';

export default class AuthenticatedContactsIndexRoute extends SearchRoute {
  queryParams = {
    query: {
      replace: true,
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
    per_page: {
      refreshModel: true,
    },
    sort_by: {
      refreshModel: true,
    },
    archived: {
      type: 'boolean',
      refreshModel: true,
    },
    order: {
      refreshModel: true,
    },
  };

  modelType = 'v2/person';

  model(params = {}) {
    return super.model({ ...params, deceased: false });
  }

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = ![
        'authenticated.contacts.index',
        'authenticated.contacts.edit',
      ].includes(transition.targetName);

      if (resetParams) {
        controller.setProperties({
          page: 1,
          query: null,
          sortBy: null,
          archived: false,
          order: 'ASC',
        });
      }
    }
  }
}
