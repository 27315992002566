import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesIndexRoute extends SearchRoute {
  @service session;

  queryParams = {
    query: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    status: {
      refreshModel: true
    },
    user_id: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    organization_location_id: {
      refreshModel: true
    },
    date_created: {
      refreshModel: true
    }
  };
  defaultPerPage = 15;
  modelType = 'kase';
  routeRegex = /authenticated.kases/;

  model(params) {
    if (!params.status) {
      params.status = this.session.currentUser.kaseFilterStatus;
    }

    return super.model(params);
  }

  setControllerDefaults(controller, { query = {} } = {}) {
    super.setControllerDefaults(...arguments);

    controller.setProperties({
      status: query.status || null,
      user_id: query.user_id || null,
      organization_location_id: query.organization_location_id || null,
      date_created: query.date_created || null
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
