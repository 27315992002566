import { readOnly, sort, or, not } from '@ember/object/computed';
import Component from '@ember/component';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  noteText: validator('presence', true)
});

export default Component.extend(Validations, {
  classNames: ['qa-kase-notes'],
  expandNotes: true,
  store: service(),
  session: service(),
  currentUserId: readOnly('session.currentUser.id'),

  kase: null,
  noteMode: null,

  didInsertElement() {
    this.fetchNotes();
  },

  fetchNotes() {
    this.store.query('note', { kase_id: get(this, 'kase.id') }).then(notes => {
      set(this, 'notes', notes);
    });
  },

  sortedNotes: sort('notes.@each.updatedAt', function(a, b) {
    if (a.get('updatedAt') > b.get('updatedAt')) {
      return -1;
    } else if (a.get('updatedAt') < b.get('updatedAt')) {
      return 1;
    }

    return 0;
  }),

  noteText: null,
  requiresFollowUp: false,

  isEditable: false,

  isEditing: or('isEditable', 'isEditingNote'),

  isEditingDisabled: not('isEditing'),

  resetText() {
    set(this, 'noteText', '');
    set(this, 'requiresFollowUp', false);
  },

  showValidation: false,

  actions: {
    addNote() {
      const noteText = this.noteText;
      const kase = this.kase;
      const currentUserId = this.currentUserId;
      const requiresFollowUp = this.requiresFollowUp;

      // using component validation instead of model due to new records
      // automatically updating Notes array on create
      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          this.store.findRecord('user', currentUserId).then(currentUser => {
            const newNote = this.store.createRecord('note', {
              content: noteText,
              user: currentUser,
              requiresFollowUp,
              kase
            });

            newNote.save().then(() => {
              this.resetText();
              this.fetchNotes();
            });
          });
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    toggleEdit(id) {
      const notes = this.notes;

      const note = notes.find(x => x.id === id);
      set(this, 'noteText', get(note, 'content'));

      if (this.isEditing) {
        this.attrs.saveKase();
      }

      if (!this.isEditable) {
        this.toggleProperty('isEditingNote');
      }
    },

    toggleExpand() {
      this.toggleProperty('expandNotes');
    }
  }
});
