import Controller from '@ember/controller';
import { get } from '@ember/object';

export default Controller.extend({
  handleSaveSuccess() {
    this.send(
      'flashSuccess',
      'Yay! You saved an event. We\'re so proud of you right now.'
    );
    this.transitionToRoute('authenticated.inquiries.edit', get(this, 'model.inquiry'), {
      queryParams: { noDirtyCheck: true }
    });
  },

  handleSaveError() {
    this.send('flashError', 'Sorry, but there was an error saving this event.');
  },

  actions: {
    deleteEvent(event) {
      const inquiry = event.get('inquiry');

      event
        .destroyRecord()
        .then(() => {
          this.send('flashSuccess', 'Bam! You just deleted your event.');
          this.transitionToRoute('authenticated.inquiries.edit', inquiry);
        })
        .catch(() => {
          this.send(
            'flashError',
            'Whoa! Something went wrong, let\'s try that again.'
          );
        });
    },

    cancelEvent(event) {
      this.transitionToRoute('authenticated.inquires.edit', event.get('inquiry'));
    },

    saveEvent() {
      this.handleSaveSuccess();
    },

    saveAndAddAnotherEvent() {
      this.send('flashSuccess', 'Yay! You saved an event.');
      this.store
        .createRecord('event', { inquiry: get(this, 'model.inquiry') })
        .save()
        .then(newEvent => {
          this.transitionToRoute('authenticated.inquiries.events.edit', newEvent);
        });
    },

    openEventModal(event) {
      event.set(
        'isCurrentEvent',
        event.get('isNew') || event.get('id') === get(this, 'model.id')
      );
      this.send('showModal', 'g/whiteboard/modal/event-details', event);
    }
  }
});
