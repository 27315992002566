import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from './config/environment';
import Bugsnag from '@bugsnag/js';
import Ember from 'ember';

// `config.environment` isn't the environment. It's the type of environment;
// 'development' for local, 'production' for staging/uat/production.
// TODO: Make `config.environment` contain the environment
//   (`config.deployTarget` currently serves that role), and add
//   `config.buildType` or something for build type (local/not).
if (config.environment === 'production') {
  Bugsnag.start({
    apiKey: config.bugsnagApiKey,
    appVersion: config.APP.version,
    enabledReleaseStages: ['production', 'uat', 'staging', 'sandbox'],
    releaseStage: config.deployTarget
  });

  Ember.onerror = error => {
    Bugsnag.notify(error);
    console.error.apply(console, error);

    // Necessary for tests
    if (Ember.testing) {
      throw error;
    }
  };
}

// Add app version to Ember Inspector
Ember.libraries.register(config.APP.name, config.APP.version);

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
