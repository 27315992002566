import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class KasesManageEditBlankFormsController extends SearchController {
  @service flashes;

  @tracked page = 1;

  queryParams = [
    'page',
    'query',
    'archived',
    'category',
    'favorited',
    'current_client',
    'kase_id',
    'sort_by'
  ]

  archived = false;
  category = null;
  current_client = true;
  favorited = null;
  kase_id = null;
  query = null;
  sort_by = 'favorited';

  handleFillSuccess() {
    this.flashes.addSuccess('Filling process has begun!');
  }

  handleError() {
    this.flashes.addError('Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.');
  }

  @action
  fillForm(blankFormId, kaseContactId, locationId, eventId, inquiryId) {
    this.store
      .createRecord('kaseForm', {
        blankFormId,
        kaseId: this.kase_id,
        kaseContactId,
        locationId,
        eventId,
        inquiryId
      })
      .save()
      .then(() => {
        this.handleFillSuccess();
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        this.kase.hasKaseForms = true;
      });
  }

  @action
  openIntercom() {
    window.Intercom('show');
  }

  @action
  saveKaseIfNecessary() {
    if (this.model.get('hasDirtyAttributes')) {
      this.model
        .save()
        .then()
        .catch(() => {
          this.handleError();
        });
    }
  }
}
