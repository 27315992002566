import Component from '@ember/component';
import { computed } from '@ember/object';

const MdIconComponent = Component.extend({
  classNames: ['ci-icon'],
  classNameBindings: ['iconClass', 'sizeClass', 'spin:ci-icon--spin'],
  tagName: 'i',

  icon: null,
  size: null,
  spin: false,

  iconClass: computed('icon', function() {
    const icon = this.icon;

    return icon ? `ci-icon--${icon}` : '';
  }),

  sizeClass: computed('size', function() {
    const size = this.size;

    return size ? `ci-icon--${size}` : '';
  })
});

export default MdIconComponent;
