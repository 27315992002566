import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class IntegrationsRoute extends Route {
  @service flashes;
  @service session;
  @service router;

  beforeModel() {
    if (this.session.currentUser.isSuperuser) {
      return true;
    }
    // Non-Superusers are redirected to the dashboard
    this.router.transitionTo('authenticated.dashboard');
  }

  model() {
    const clientInfoId = this.session.currentClient.client_info_id;
    const store = this.store;

    return RSVP.hash({
      clientInfo: store.findRecord('clientInfo', clientInfoId)
    });
  }

  @action
  save() {
    this.currentModel.clientInfo
      .save()
      .then(() => {
        this.flashes.addSuccess('Your Info has been saved!');
      })
      .catch(error => {
        if (error.errors[0]['detail']) {
          this.flashes.addError(error.errors[0]['detail']);
        } else {
          this.flashes.addError('Oops, something went wrong! Please try again.');
        }
      });
  }
}
