import Controller from '@ember/controller';
import { action } from '@ember/object';
export default class FuneralDecisionsSetupController extends Controller {
  showUsernameField = true;
  showSecretField = true;
  secretPlaceholder = 'API Key';
  usernamePlaceholder = 'Account ID';

  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
