import Model, { attr } from '@ember-data/model';

export default class LocationIntegrationServiceModel extends Model {
  @attr('string') locationName;
  @attr('string') locationId;
  @attr('string') integrationServiceId;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') updatedAt;
  @attr('moment-datetime') archivedAt;

  @attr() data;
}
