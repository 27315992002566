import Model, { hasMany, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: validator('presence', true),
});

export default class OrganizationModel extends Model.extend(Validations) {
  @attr('string') name;
  @attr('string') clientId;

  @hasMany('organizationLocation', { async: true }) organizationLocations;
  @hasMany('v2/locations', { async: true }) locations;
}
