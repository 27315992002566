import { computed, set } from '@ember/object';
import moment from 'moment';
import Service, { inject as service } from '@ember/service';

export default Service.extend({
  store: service(),

  date: moment(),
  startHour: 0,
  endHour: 24,

  fetchDate(date) {
    set(this, 'date', moment(date || new Date()));
    const query = { start_date: this.date.format('YYYY-MM-DD') };
    return this.store.query('event', query);
  },

  events: computed('date', 'allEvents.@each.startsAt', function() {
    return this.allEvents.filter(event => {
      return (
        event.get('startsAt') && event.get('startsAt').isSame(this.date, 'day')
      );
    });
  }),

  allEvents: computed(function() {
    return this.store.peekAll('event');
  }),

  fetchPrevious() {
    this.fetchDate(this.date.subtract(1, 'day'));
  },

  fetchNext() {
    this.fetchDate(this.date.add(1, 'day'));
  }
});
