import Model, { attr, belongsTo } from '@ember-data/model';
import fpSections from '../../utils/family-portal-integration-import-helper';
import { normalize } from '../../utils/jsonapi-normalizer';

export default class V2IntegrationImportModel extends Model {
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') updatedAt;
  @attr('string') serviceName;
  @attr('string') uuid;
  @attr() content;
  @attr() data;

  @belongsTo('v2/integrationService', { async: true }) integrationService;

  get rawImport() {
    return normalize(this.content);
  }

  get importSections() {
    switch (this.serviceName) {
      case 'family_portal':
        return fpSections(this.content);
      default:
        return null;
    }
  }
}
