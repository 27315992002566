import { sort } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';

export default class OrganizationService extends Service {
  @service store;
  organizations = null;

  get all() {
    if (this.organizations == null) {
      this.organizations = this.store.query('organization', { per_page: 1000 });
    }

    return this.organizations;
  }

  unloadAll() {
    this.organizations = null;
  }

  organizationNameSort = ['name:asc'];
  @sort('all', 'organizationNameSort') allSorted;
}
