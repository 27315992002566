import { validateLength, validateNumber, validatePresence } from 'ember-changeset-validations/validators'
import validateSometimes from 'ember-changeset-conditional-validations/validators/sometimes';

export default {
  amount: [
    validatePresence(true),
    validateNumber({ gt: 0 }),
  ],
  collectedBy: validatePresence(true),
  payer: validatePresence(true),
  payments: validateSometimes(
    validateLength({ min: 1, message: 'Add at least one payment.' }),
    function() {
      return this.get('isBatchPayment')
    }),
  collectedAt: validatePresence(true),
  paymentType: validatePresence(true),
}
