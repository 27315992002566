import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class AuthenticatedAdminTributePayEmailTemplatesRoute extends Route {
  @service store;
  @service intl;

  queryParams = {
    page: {
      refreshModel: true
    }
  };

  model(params) {
    return this.store.query('v2/email-template', {
      purpose: 'payment',
      per_page: 10,
      ...params
    }).then((emailTemplates) => {
      if (emailTemplates && emailTemplates.length > 0) {
        return emailTemplates;
      } else {
        const newEmailTemplate = this.store.createRecord('v2/email-template', {
          purpose: 'payment',
          name: this.intl.t('authenticated.admin.tribute-pay.email-templates.defaultData.email.name'),
          subject: this.intl.t('authenticated.admin.tribute-pay.email-templates.defaultData.email.subject'),
          body: this.intl.t('authenticated.admin.tribute-pay.email-templates.defaultData.email.body')
        })
        return newEmailTemplate.save().then(() => {
          return [newEmailTemplate];
        })
      }
    })
  }
}
