import moment from 'moment';

const dateFormats = [
  'MM-DD-YYYY',
  'MM/DD/YY',
  'M-D-YYYY',
  'M-D-YY',
  'M/D/YYYY',
  'M/D/YY',
  'M/D',
  'M-D',
  'MMM D, YYYY',
  'MMM DD, YYYY',
  'YYYY/MM/DD',
  'YYYY-MM-DD'
];

const timeFormats = ['h:mm', 'h'];

const amPmFormats = ['a', 'A', ' a', ' A'];

const parseFormats = (function() {
  const formats = [];

  dateFormats.forEach(function(dateFormat) {
    formats.push(`${dateFormat}`);
    timeFormats.forEach(function(timeFormat) {
      amPmFormats.forEach(function(amPmFormat) {
        formats.push(`${dateFormat} ${timeFormat}${amPmFormat}`);
      });
    });
  });

  return formats;
})();

export function parseDate(date) {
  return moment(date, parseFormats);
}

export function formatDate(momentDate, standardFormat) {
  if (momentDate === 'now') {
    return moment().format('MMM D, YYYY');
  }

  if (standardFormat === true) {
    return momentDate.format('MMM D, YYYY');
  }

  return momentDate.calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'dddd, MMM D, YYYY'
  });
}

export function formatDateTime(momentDate, standardFormat) {
  if (momentDate === 'now') {
    return moment().format('MMM D, YYYY [at] h:mm A');
  }

  if (standardFormat === true) {
    return momentDate.format('MMM D, YYYY [at] h:mm A');
  }

  return momentDate.calendar(null, {
    sameDay: '[Today at] h:mm A',
    nextDay: '[Tomorrow at] h:mm A',
    nextWeek: 'dddd [at] h:mma',
    lastDay: '[Yesterday at] h:mm A',
    lastWeek: '[Last] dddd [at] h:mm A',
    sameElse: 'MMM D, YYYY [at] h:mm A'
  });
}

export function formatTime(momentDate) {
  const timeFormat = momentDate.minutes() ? 'h:mma' : 'ha';
  return momentDate.format(timeFormat);
}

export function formatDateRange(startDate, endDate) {
  const isStartDateValid = startDate && startDate.isValid();
  const isEndDateValid = endDate && endDate.isValid();

  if (isStartDateValid || isEndDateValid) {
    return `${isStartDateValid ? standardDate(startDate) : 'N/A'} - ${
      isEndDateValid ? standardDate(endDate) : 'N/A'
    }`;
  } else {
    return '';
  }
}

export function standardDate(momentDate) {
  if (!moment.isMoment(momentDate)) {
    momentDate = moment(momentDate / 1000, 'X');
  }

  return momentDate.format('MMM D, YYYY');
}

// each block must implement startsAt and endsAt
export function overlaps(block1, block2) {
  return (
    block1.get('startsAt').toDate() < block2.get('endsAt').toDate() &&
    block2.get('startsAt').toDate() < block1.get('endsAt').toDate()
  );
}
