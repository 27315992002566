import Serializer from './application';

export default class V2AccountingExportSerializer extends Serializer {
  normalize(_, hash) {
    const ret = super.normalize(...arguments);
    ret.data.attributes.downloadUrl = hash.links['download-url'];

    return ret;
  }

  serialize() {
    const payload = super.serialize(...arguments);
    delete payload.data.attributes.downloadUrl;

    return payload;
  }
}
