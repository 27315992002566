import { run } from '@ember/runloop';
import RSVP from 'rsvp';
import { set, get } from '@ember/object';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Route.extend({
  queryParams: {
    selection_id: {
      refreshModel: true
    }
  },
  session: service(),

  model(params) {
    const model = this.modelFor('authenticated.kases.manage.financials');

    return RSVP.hash({
      selectionParams: params,
      kase: get(model, 'kase'),
      kaseFinancial: get(model, 'kaseFinancial'),
      selection: params.selection_id
        ? this.store.findRecord('selection', params.selection_id)
        : '',
      selections: get(model, 'selections'),
      clientInfo: get(model, 'clientInfo'),
      newRefund: this.store.createRecord('refund', {
        enteredBy: get(this, 'session.currentUser'),
        collectedBy: get(this, 'session.currentUser')
      })
    }).then(models => {
      // If selection_id is null display the selection with oldest positive balance
      const selectionParams = get(models, 'selectionParams');
      if (isEmpty(get(selectionParams, 'selection_id'))) {
        const oldestBalanceSelection = get(models, 'selections').filter(
          selection => {
            return get(selection, 'balance') > 0;
          }
        );

        if (isEmpty(oldestBalanceSelection)) {
          set(models, 'selection', get(models, 'selections.firstObject'));
        } else {
          set(models, 'selection', get(oldestBalanceSelection, 'firstObject'));
        }
      }

      return models;
    });
  },

  setupController(controller, models) {
    this._super(...arguments);

    // Selection_id is set here to handle setting routes to selection with oldest positive balance if empty
    if (get(models, 'selection')) {
      controller.set('selection_id', get(models, 'selection.id'));
    }

    controller.setProperties(models);

    set(models, 'newRefund.person', controller.get('selection.purchaser'));
    set(models, 'newRefund.refundTo', controller.get('selection.purchaser.name'));
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('selection_id', null);
      controller.set('showValidation', false);
    }
  },

  handleRefundSaveSuccess() {
    this.refresh();
    this.send('refreshKaseFinancial');
    this.send('flashSuccess', 'Refund successfully saved!');
  },

  handleRefundRemoveSuccess() {
    this.refresh();
    this.send('refreshKaseFinancial');
    this.send('flashSuccess', 'Refund successfully removed.');
  },

  handleError() {
    this.send(
      'flashError',
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
    );
  },

  actions: {
    cancelNewRefund() {
      if (confirm('This refund will be cancelled. Are you sure?')) {
        set(this.controller, 'showValidation', false);
        set(this.controller, 'isRefundToNewPerson', undefined);
        this.refresh();
      }
    },

    saveNewRefund(newRefund) {
      newRefund.setProperties({
        enteredBy: get(this, 'session.currentUser'),
      });

      newRefund
        .save()
        .then(
          run.bind(this, 'handleRefundSaveSuccess'),
          run.bind(this, 'handleError')
        );
    },

    removeRefund(refund) {
      refund
        .destroyRecord()
        .then(
          run.bind(this, 'handleRefundRemoveSuccess'),
          run.bind(this, 'handleError')
        );
    }
  }
});
