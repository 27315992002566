import Mixin from '@ember/object/mixin';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';

export default Mixin.create({
  classNameBindings: ['idClass'],

  /**
   * This is used in components to identify the id of the component's model.
   * The recommended use is to define this property within your component
   * using, `computed.readOnly('path.to.id')`. This will automatically add an
   * id class that looks like `id-5`.
   * @type {Integer}
   */
  idKey: null,

  /**
   * Computed property that builds out the id class name that can be used for
   * reference of testing
   * @param  {Integer} 'idKey' Integer that is observed for changes
   * @return {String} Class name to be added to component
   */
  idClass: computed('idKey', function() {
    const idKey = this.idKey;

    return idKey ? `id-${dasherize(idKey)}` : null;
  })

  /**
   * Within this init, a script is ran that fetches the component name, and
   * creates a kebab case string from it that is then added to the component
   * as a class name
   * @return {Undefined}
   */

  // this breaks in ember 2.12

  // init() {
  //   this._super(...arguments);

  //   // Auto add the component name as a class to the element
  //   let componentName = this._debugContainerKey;
  //   componentName = componentName.split(':').pop();
  //   componentName = componentName.replace('/', '-');

  //   get(this, 'classNames').push(componentName);
  // }
});
