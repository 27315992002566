import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Field::Select\n    @placeholder={{this.placeholder}}\n    @placeholderLabel={{@placeholderLabel}}\n    @placeholderValue={{@placeholderValue}}\n    @disabled={{@disabled}}\n    @value={{@value}}\n    @onChange={{@onChange}}\n    @dataTest={{@dataTest}}\n    ...attributes>\n  {{!-- template-lint-disable attribute-indentation --}}\n  {{#each\n      (if @hideArchived this.location.archived this.location.allSorted) as |location|}}\n    <option\n        value={{location.id}}\n        selected={{or (eq @selectedOption.id location.id) (eq @selectedValue location.id)}}>\n      {{location.name}}\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n", {"contents":"<G::Crakn::Field::Select\n    @placeholder={{this.placeholder}}\n    @placeholderLabel={{@placeholderLabel}}\n    @placeholderValue={{@placeholderValue}}\n    @disabled={{@disabled}}\n    @value={{@value}}\n    @onChange={{@onChange}}\n    @dataTest={{@dataTest}}\n    ...attributes>\n  {{!-- template-lint-disable attribute-indentation --}}\n  {{#each\n      (if @hideArchived this.location.archived this.location.allSorted) as |location|}}\n    <option\n        value={{location.id}}\n        selected={{or (eq @selectedOption.id location.id) (eq @selectedValue location.id)}}>\n      {{location.name}}\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n","moduleName":"crakn/components/g/crakn/selector/location.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/selector/location.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class GCraknSelectorLocationComponent extends Component {
  @service location;

  get placeholder() {
    return this.args.placeholder || 'Location';
  }
}
