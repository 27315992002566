import Route from '@ember/routing/route';

export default Route.extend({
  layout: 'fullscreen',
  isFullscreenActive: true,

  resetController(controller, isExiting) {
    this._super.apply(this, arguments);
    if (isExiting) {
      controller.setProperties({
        email: null,
        isEnteringEmail: true
      });
    }
  },

  setupController(_controller, _model, _transition) {
    this._super.apply(this, arguments);
  }
});
