import SearchRoute from 'crakn/routes/search';

export default class AuthenticatedInventoryRoute extends SearchRoute {
  queryParams = {
    query: {
      replace: true,
      refreshModel: true
    },
    location_id: {
      refreshModel: true
    },
    product_id: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    }
  }

  modelType = 'v2/inventory';
  routeRegex = /authenticated.inventory/;

  model(params = {}) {
    return super.model({ ...params });
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.set('locations', this.store.findAll('v2/location'));
    controller.set('products', this.store.findAll('product'));
  }
}
