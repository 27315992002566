import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2OnlinePlannerPackage extends Model {
  @attr('boolean') hidePackageValue;

  @attr('moment-datetime') archivedAt;
  @attr('string') name;
  @attr('string') description;
  @attr('string') discountedTotal;
  @attr('string', { defaultValue: 'all'}) dispositionCategory;
  @attr('string') icon;
  @attr('string') total;
 
  @belongsTo('v2/user', { async: true }) archivedByUser;
  @belongsTo('v2/client', { async: true }) client;
  @belongsTo('v2/online-planner-config', { async: true }) onlinePlannerConfig;
  @belongsTo('v2/package', { async: true }) package;
}
