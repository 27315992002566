import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { action } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default class AuthenticatedInventoryEditRoute extends Route {
  @service api;

  model(params) {
    return RSVP.hash({
      inventory: this.store.findRecord('inventory', params.inventory_id),
      inventoryTransactions: this.store.query('inventory-transaction', {
        inventory_id: params.inventory_id,
        per_page: 10000
      }),
      statementCategories: this.store.findAll('statementCategory'),
      statementSubcategories: this.store.findAll('statementSubcategory'),
      newInventoryTransaction: this.store.createRecord(
        'inventory-transaction',
        { inventory_id: params.inventory_id, createdAt: moment() }
      )
    });
  }

  setupController(controller, models) {
    super.setupController(...arguments);
    controller.setProperties(models);

    this.api.json.get(
      `inventories/${models.inventory.get('id')}/products`
    ).then(response => {
      controller.set('products', response.parsedJson.products);
    });
  }

  willTransition(transition) {
    const model = this.currentModel;

    if (model.inventory.get('hasDirtyAttributes')) {
      if (!confirm('You have unsaved changes. Are you sure?')) {
        transition.abort();
      } else {
        model.inventory.rollbackAttributes();
        return true;
      }
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
