import Model, {attr} from '@ember-data/model';
import moment from 'moment';


export default class V2TransactionModel extends Model {
  @attr('boolean') isBatchPayment;
  @attr('boolean') isTributePay;

  @attr('moment-datetime', { defaultValue: () => moment() }) collectedAt;
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;

  @attr('object') customFieldOne;
  @attr('object') customFieldTwo;

  @attr('price') amount; 
  @attr('price') effectiveAmount; 
  @attr('price') paymentFee; 

  @attr('string', { defaultValue: 'US' }) payerCountry; 
  @attr('string') name;
  @attr('string') notes;
  @attr('string') parsedStatus; 
  @attr('string') payer;
  @attr('string') payerAddressLine1; 
  @attr('string') payerAddressLine2;
  @attr('string') payerCity;
  @attr('string') payerEmail;
  @attr('string') payerPhonePrimary;
  @attr('string') payerState;
  @attr('string') payerZipCode;
  @attr('string') paymentBy;
  @attr('string') paymentFor;
  @attr('string') paymentForFirstName;
  @attr('string') paymentForLastName;
  @attr('string') paymentProcessorStatus;
  @attr('string') paymentTypeName;
  @attr('string') referenceType;
  @attr('string') saleId;
  @attr('string') selectionId;
  @attr('string') tpsChargeId;
}
