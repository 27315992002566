import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import SearchController from 'crakn/controllers/search';

export default class AuthenticatedInventoryIndexController extends SearchController {
  queryParams = [
    'query',
    'location_id',
    'product_id',
    'archived'
  ];

  @tracked archived = false;
  @tracked product_id;
  @tracked location_id;

  /**
   * Updats the sorting property in the table
   * @param  {String} sortProperty Name of property to sort by
   * @return {Undefined}
   */
  @action
  sortBy(sortProperty) {
    if (this.sort_by === sortProperty) {
      this.order = this.order === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.sort_by = sortProperty;
      this.order = 'ASC';
    }
  }

  @action
  setProductId(product) {
    this.product_id = product.id;
  }

  @action
  productSearch(query) {
    this.product_id = '';

    return this.store
               .query('product', {
                 query,
                 ...this.queryParams
               });
  }
}
