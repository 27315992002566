import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'model.subject': validator('presence', true),
  'bodyRaw': validator('presence', {
    presence: true,
    ignoreBlank: true
  })
})

export default class AuthenticatedAdminTributePayEmailTemplatesEditController extends Controller.extend(Validations) {
  @service flashes;
  @service intl;
  @service router;

  @tracked bodyRaw;
  @tracked showValidations = false;

  @action
  close() {
    this.router.transitionTo('authenticated.admin.tribute-pay.email-templates.index');
  }

  @action
  save() {
    set(this, 'showValidations', false);

    this.validate().then(({ validations }) => {
      if (validations.isValid) {
        this.model.save().then(() => {
          this.flashes.addSuccess(this.intl.t('authenticated.admin.tribute-pay.email-templates.edit.alerts.save.success'));
        }).catch(() => {
          this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay.email-templates.edit.alerts.save.error'));
        })
      } else {
        set(this, 'showValidations', true);
      }
    })
  }

  @action
  setBody(text) {
    set(this, 'bodyRaw', text.raw.replace(/\s/g,''));
    set(this.model, 'body', text.html)
  }
}
