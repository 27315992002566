import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2UserNotificationModel extends Model {
  @attr('string') userNotificationType;

  @belongsTo('v2/user', { async: true }) user;
  @belongsTo('v2/client', { async: true }) client;

  @hasMany('v2/location', { async: true }) locations;
  @hasMany('v2/online-planner-config', { async: true }) onlinePlannerConfigs;
  @hasMany('v2/online-planner-config-user-notification', { async: true }) onlinePlannerConfigUserNotifications;
  @hasMany('v2/user-notification-location', { async: true }) userNotificationLocations;
}
