import OneWayInputComponent from 'ember-one-way-controls/components/one-way-input';

export function initialize() {
  OneWayInputComponent.reopen({
    classNames: ['input__field']
  });
}

export default {
  name: 'one-way-controls',
  initialize
};
