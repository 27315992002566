import Route from '@ember/routing/route'
import { inject as service } from '@ember/service';

export default class AdminUsersEditRoute extends Route {
  @service session;
  @service router;

  model(params) {
    return this.store.findRecord('user', params.user_id);
  }

  beforeModel(transition) {
    if (transition.to.name == 'authenticated.admin.user.edit.index') {
      this.router.transitionTo('authenticated.admin.user.edit.overview');
    }
  }
}
