import { sortBy } from 'lodash-es/collection';

export function sortKaseContact(kaseContacts) {
  if (kaseContacts === undefined || kaseContacts === null) {
    return null;
  }

  return sortBy(kaseContacts.toArray(), contact => {
    let ret = '';
    ret += contact.get('isPrimaryContact') ? '0' : '1';
    ret += contact.get('isNextOfKin') ? '0' : '1';
    if (contact.get('personLastName')) {
      ret += ` ${contact.get('personLastName').toLowerCase()}`;
    }
    if (contact.get('personFirstName')) {
      ret += ` ${contact.get('personFirstName').toLowerCase()}`;
    }
    return ret;
  });
}
