import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Field::Select\n    @placeholder={{@placeholder}}\n    @placeholderLabel={{t \"components.g.crakn.selector.account.placeholderLabel\"}}\n    @placeholderValue=\"\"\n    @dataTest={{@dataTest}}\n    @onChange={{fn this.selectAccount}}\n    @showValidations={{@showValidations}}\n    @validationMessages={{@validationMessages}}\n    class=\"c--xs--12\">\n  {{#each @options as |account|}}\n    <option value={{account.id}} selected={{eq @value.id account.id}}>\n      {{#if account.generalLedgerId}}\n        {{account.generalLedgerId}} - {{account.name}}\n      {{else}}\n        {{account.name}}\n      {{/if}}\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n", {"contents":"<G::Crakn::Field::Select\n    @placeholder={{@placeholder}}\n    @placeholderLabel={{t \"components.g.crakn.selector.account.placeholderLabel\"}}\n    @placeholderValue=\"\"\n    @dataTest={{@dataTest}}\n    @onChange={{fn this.selectAccount}}\n    @showValidations={{@showValidations}}\n    @validationMessages={{@validationMessages}}\n    class=\"c--xs--12\">\n  {{#each @options as |account|}}\n    <option value={{account.id}} selected={{eq @value.id account.id}}>\n      {{#if account.generalLedgerId}}\n        {{account.generalLedgerId}} - {{account.name}}\n      {{else}}\n        {{account.name}}\n      {{/if}}\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n","moduleName":"crakn/components/g/crakn/selector/account.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/selector/account.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class GCraknSelectorAccount extends Component {
  @action
  selectAccount(accountId) {
    const selectedAccount = this.args.options.findBy('id', accountId);
    this.args.onChange(selectedAccount);
  }
}
