import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import moment from 'moment';
import Validations from 'crakn/validations/authenticated/batch-payments/new';

export default class AuthenticatedPaymentsBatchPaymentsEditRoute extends Route {
  @service v2Staff;

  model(params) {
    return this.store.findRecord('v2/generalPayment', params.id);
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('changeset', Changeset(model, lookupValidator(Validations), Validations));

    const totalSplitPayments = {};
    model.payments.forEach((payment) => {
      totalSplitPayments[payment.id] = {amount: payment.amount};
    });
    const collectedBy = this.v2Staff.all.findBy('id', model.collectedById);
    const isAltPayer = model.payer && !model.person.get('id');

    controller.set('totalSplitPayments', totalSplitPayments);
    controller.set('collectedById', model.collectedById);
    controller.set('changeset.payments', model.payments);
    controller.set('changeset.collectedAt', moment(model.collectedAt));
    controller.set('changeset.collectedBy', collectedBy);
    controller.set('changeset.paymentType', model.paymentType);
    controller.set('changeset.amount', model.amount);
    controller.set('changeset.payer', model.payer);
    controller.set('changeset.person', model.person);
    controller.set('changeset.location', model.location);
    controller.set('changeset.isBatchPayment', model.isBatchPayment);
    controller.set('changeset.checkNumber', model.checkNumber);
    controller.set('changeset.notes', model.notes);
    controller.set('altPayer', isAltPayer)
  }
}
