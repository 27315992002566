import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AdminManageGeneralPriceListsNewRoute extends Route {
  model(params) {
    return this.store.createRecord('general-price-list', {
      name: params.name
    });
  }

  setupController(controller, model) {
    controller.set('gpl', model);
    const chartsOfAccounts = this.store.query('chartOfAccounts', {
      per_page: 1000
    });
    controller.set('chartsOfAccounts', chartsOfAccounts);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('showValidation', false);
      controller.set('chartId', null);
    }
  }

  @action
  willTransition() {
    this.currentModel.rollbackAttributes();
  }
}
