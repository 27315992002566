import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedPlacesNewRoute extends Route {
  controllerName = 'authenticated.places.edit';
  templateName = 'authenticated.places.edit';

  @service session;

  model(params) {
    return this.store.createRecord('v2/place', {
      address: this.store.createRecord('v2/address', {
        name: params.name,
        country: this.session.currentClient.operating_country || 'US'
      }),
    });
  }
}
