import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import moment from 'moment';

export default Route.extend({
  queryParams: {
    page: {
      refreshModel: true
    },
    starts_at: {
      refreshModel: true
    },
    ends_at: {
      refreshModel: true
    },
    status: {
      refreshModel: true,
      replace: true
    },
    organization_location_id: {
      refreshModel: true
    },
    purchaser_id: {
      refreshModel: true
    }
  },

  model(params) {
    return RSVP.hash({
      selections: this.store.query('selection', params)
    });
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.setProperties(model);
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('purchaser_id', null);
      controller.set('purchaser', null);
      controller.set('status', null);
      controller.set('organization_location_id', null);
      controller.set(
        'starts_at',
        moment()
          .subtract(30, 'day')
          .toISOString()
      );
      controller.set('ends_at', moment().toISOString());
    }
  }
});
