import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';

export default class AdminGeneralPriceListsEditPackagesIndexRoute extends SearchRoute {
  queryParams = {
    query: {
      replace: true,
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    },
    general_price_list_id: {
      refreshModel: true
    },
    sort_by: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    },
    invoice_id: {
      refreshModel: true
    }
  }

  modelType = 'package';
  routeRegex = /authenticated.admin.general-price-lists.packages/;

  model(params = {}) {
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');

    return this.store.query(this.modelType, {
      query: params.query || this.defaultQuery,
      page: params.page || this.defaultPage,
      perPage: params.perPage,
      archived: params.archived || this.defaultArchived,
      general_price_list_id: gpl.id,
      sortBy: params.sortBy,
      order: params.order,
      invoice_id: params.invoice_id
    });
  }

  setupController(controller, model) {
    super.setControllerDefaults(...arguments);
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');

    controller.set('generalPriceList', gpl);
    controller.set('packages', model);
  }

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = ![
        'authenticated.admin.general-price-lists.packages.index',
        'authenticated.admin.general-price-lists.packages.edit',
      ].includes(transition.targetName);

      const newPackage = this.controller.newPackage;

      if (newPackage) {
        newPackage.destroyRecord();
        this.controller.newPackage = null;
      }

      if (resetParams) {
        controller.setProperties({
          page: 1,
          query: null,
          sortBy: null,
          archived: false,
          order: 'ASC'
        });
      }
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
