import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default class TukiosV2SetupController extends Controller {
  showSecretField = true;
  secretPlaceholder = 'API Key';
  showKeyField = true;
  keyPlaceholder = 'fhid';
  @tracked tukiosFuneralHomeBranches = null;
  @tracked showFuneralHomeBranchesResults = false;
  @tracked tukiosOrgId = null;
  @tracked tukiosAuthToken = null;
  @service api;
  @service flashes;

  handleAddSuccess() {
    this.flashes.addSuccess('Integration Added to location.');
    this.craknLocationId = null;
    this.send('refreshModel');
  }

  handleAddError(error) {
    this.flashes.addError(error);
  }

  @computed('tukiosFuneralHomeBranches', 'showFuneralHomeBranchesResults')
  get canViewTukiosFuneralHomeBranches() {
    if (this.tukiosFuneralHomeBranches && this.showFuneralHomeBranchesResults) {
      return true;
    }
    return false;
  }

  requestTukiosFuneralHomeBranches() {
    this.api.json
      .get(`tukios_v2/organizations/${this.tukiosOrgId}`, {
        params: { token: this.tukiosAuthToken }
      })
      .then((response) => {
        this.tukiosFuneralHomeBranches = response.parsedJson.branches;
        this.showFuneralHomeBranchesResults = true;
      });
  }

  @action
  requestTukiosOrganization() {
    this.api.json
      .get('tukios_v2/organizations', {
        params: { token: this.tukiosAuthToken }
      })
      .then((response) => {
        this.tukiosOrgId = response.parsedJson.data.firstObject.id;
      })
      .then(() => {
        this.requestTukiosFuneralHomeBranches();
      })
  }

  @action
  linkFuneralHomeBranch(tukiosFuneralHomeBranchId, tukiosFuneralHomeBranchName) {
    this.store
      .createRecord('integrationService', {
        name: 'tukios_v2',
        key: this.tukiosOrgId,
        secret: this.tukiosAuthToken,
        tukios_funeral_home_location_id: tukiosFuneralHomeBranchId,
        tukios_funeral_home_location_name: tukiosFuneralHomeBranchName
      })
      .save()
      .then((response) => {
        const locationId = this.craknLocationId;
        const integrationServiceId = response.id;
        if (locationId && integrationServiceId) {
          this.store
          .createRecord('locationIntegrationService', {
            locationId,
            integrationServiceId
          })
          .save()
          .then(() => {
            this.handleAddSuccess();
          })
          .catch((error) => {
            if (error.errors && error.errors[0]['detail']) {
              this.handleAddError(error.errors[0]['detail']);
            } else {
              this.handleAddError('Something went wrong adding the location.');
            }
          });
        }
      });
  }

  @action
  close() {
    this.tukiosFuneralHomeBranches = null;
    this.showFuneralHomeBranchesResults = false;
    this.craknLocationId = null;
    this.tukiosOrgId = null;
    this.tukiosAuthToken = null;
  }

  @action
  onWillDestroy() {
    this.tukiosOrgId = null;
    this.tukiosAuthToken = null;
  }
}
