import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import Validations from 'crakn/validations/authenticated/admin/general-price-lists/edit/preneed-categories';

export default class AdminGeneralPriceListsEditPreneedCategoriesRoute extends Route {
  model() {
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');
    
    return this.store.query('v2/preneedCategory', {
      archived: false,
      gpl_id: gpl.id
    });
  }

  setupController(controller) {
    super.setupController(...arguments);

    const v1GPL = this.modelFor('authenticated.admin.general-price-lists.edit');
    const generalPriceList = this.store.findRecord('v2/generalPriceList', v1GPL.id);
    const newPreneedCategory = this.store.createRecord('v2/preneedCategory');
    
    controller.set('generalPriceList', generalPriceList);
    controller.set('changeset', Changeset(newPreneedCategory, lookupValidator(Validations), Validations))
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
