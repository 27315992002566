import Component from '@ember/component';
import FormInput from 'crakn/mixins/form-input';

export default Component.extend(FormInput, {
  classNames: ['input input--standard'],
  classNameBindings: ['hasValue:input--has-value', 'styleClass'],

  hasValue: null,
  placeholder: 'Select One',
  disabled: false,

  style: 'standard',

  actions: {
    handleChange(targetValue) {
      this.sendAction('onChange', targetValue);
    }
  }
});
