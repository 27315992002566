import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { placeTypes } from 'crakn/utils/place-types';

export default Component.extend({
  session: service(),
  location: service(),

  errors: null,
  showValidation: false,
  canViewHomeUnderHospice: false,

  validations: {
    'model.name': { presence: true }
  },

  isSingleColumn: false,

  country: computed('model.country', function() {
		const place = this.model;
		const clientCountry = this.session.currentClient.operating_country;
		if (get(place, 'isNew')) {
			if (clientCountry) {
				return clientCountry;
			} else {
				return 'US';
			}
		} else {
			return place.country;
		}
	}),

  placeTypeOptions: computed('canViewHomeUnderHospice', function() {
    if (this.canViewHomeUnderHospice) {
      return placeTypes;
    }

    return placeTypes.filter(pt => pt.value !== 'home_under_hospice');
  }),

  actions: {
    setCanViewHomeUnderHospice() {
      this.location.all.then((locations) => {
        set(this, 'canViewHomeUnderHospice', locations.filterBy('state', 'MI').length > 0);
      })
    },

    save() {
      const place = this.model;

      place.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(place, 'country', this.country);
          set(this, 'showValidation', false);
          this.sendAction('onSave');
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    cancel() {
      this.sendAction('onCancel');
    }
  }
});
