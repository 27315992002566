import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class SettingsPasswordRoute extends Route {
  @service flashes;

  model() {
    return this.modelFor('authenticated.settings');
  }

  resetController(controller, isExiting) {
    super.resetController.apply(this, arguments);
    if (isExiting) {
      controller.setProperties({
        password: null,
        passwordConfirmation: null,
        showValidation: false
      });
    }
  }
}
