import Model, { belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import { bool, not } from '@ember/object/computed';
import moment from 'moment';

const Validations = buildValidations({
  amount: validator('presence', true),
  'adjustmentType.id': validator('presence', {
    presence: true,
    disabled: not('model.lineItemAdjustment'),
  }),
  gain: validator('presence', {
    presence: true,
    disabled: bool('model.lineItemAdjustment'),
  }),
  createdAt: validator('presence', true),
  adjustmentLineItemId: validator('presence', {
    presence: true,
    disabled: not('model.lineItemAdjustment'),
  }),
});

export default class AdjustmentModel extends Model.extend(Validations) {
  @attr('string') coaIntegrationName;
  @attr('string') exportedAt;
  @attr('string') notes;
  @attr('string') netsuiteId;

  @attr('price') amount;
  @attr('price') amountWithTax;
  @attr('price') totalTax;
  @attr('string') lineItemName;
  @attr('string') kaseName;

  @attr('boolean') exported;
  @attr('boolean', { defaultValue: true }) gain;
  @attr('boolean', { defaultValue: false }) includeItemTax;
  @attr('boolean', { defaultValue: false }) lineItemAdjustment;

  @attr('moment-datetime', { defaultValue: () => moment() }) collectedAt;
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;

  @attr('number', { defaultValue: null }) adjustmentLineItemId;

  @belongsTo('user', { async: true }) adjustedBy;
  @belongsTo('adjustmentType') adjustmentType;
  @belongsTo('user', { async: true }) enteredBy;
  @belongsTo('user', { async: true }) exportedBy;
  @belongsTo('selection', { polymorphic: true }) selection;
  @belongsTo('selectionCustomProduct', { async: true }) selectionCustomProduct;
  @belongsTo('v2/selectionPackageSlotProduct', { async: true }) selectionPackageSlotProduct;
  @belongsTo('selectionProduct', { async: true }) selectionProduct;
}
