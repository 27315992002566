import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class AuthenticatedAdminImportRoute extends Route {
  @service session;
  @service router;

  beforeModel() {
    if (this.session.currentUser.isSuperuser) {
      return true;
    }
    // Non-Superusers are redirected to the dashboard
    this.router.transitionTo('authenticated.dashboard');
  }
}
