import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Field::Select\n    @placeholder={{this.placeholder}}\n    @onChange={{this.setGain}}\n    @value={{this.gain}}>\n  {{#if this.session.currentClient.can_view_adjustment_gains}}\n    <option selected={{eq this.gain \"Gain\"}}>Gain</option>\n  {{/if}}\n  <option selected={{eq this.gain \"Loss\"}}>Loss</option>\n</G::Crakn::Field::Select>\n", {"contents":"<G::Crakn::Field::Select\n    @placeholder={{this.placeholder}}\n    @onChange={{this.setGain}}\n    @value={{this.gain}}>\n  {{#if this.session.currentClient.can_view_adjustment_gains}}\n    <option selected={{eq this.gain \"Gain\"}}>Gain</option>\n  {{/if}}\n  <option selected={{eq this.gain \"Loss\"}}>Loss</option>\n</G::Crakn::Field::Select>\n","moduleName":"crakn/components/g/crakn/selector/adjustment-gain.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/selector/adjustment-gain.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdjustmentGainSelectorComponent extends Component {
  @service session;

  get gain() {
    return this.args.adjustment.gain ? 'Gain' : 'Loss';
  }
  
  get placeholder() {
    return this.session.currentClient.can_view_adjustment_gains ? 'Gain/Loss' : 'Loss';
  }
  
  @action
  setGain(value) {
    this.args.adjustment.set('gain', value === 'Gain' ? true : false);
  }
}
