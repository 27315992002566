import Component from '@ember/component';
import { action, set } from '@ember/object';
import { dasherize } from '@ember/string';
import { months } from 'crakn/utils/data-helpers';
import moment from 'moment';
import { tracked } from '@glimmer/tracking'

export default class CraknYearMonthPicker extends Component {

  @tracked placeholer = null;
  startYear = null;
  endYear = null;
  @tracked month = null;
  @tracked year = null;
  showNullOptions = true;

  qaClass() {
    const placeholder = this.placeholder;

    return dasherize(`qa-${placeholder}-container`);
  }

  @action handleMonthChange(value) {
    this.month = value;
    this.setValue();
  }

  @action handleYearChange(value) {
    this.year = value;
    this.setValue();
  }

  setValue() {
    const month = this.month || moment().month();
    const year = this.year || moment().year();

    this.year = year.toString();
    this.month = months[month];

    this.sendAction('update', moment()
                              .year(year)
                              .month(month)
                              .date(1))
  }

  set value(value) {
    if (value === null) {
      return value;
    }

    const month = value.month();
    const year = value.year();

    set(this, 'year', year.toString());
    set(this, 'month', months[month]);

    return value;
  }
}
