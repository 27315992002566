import Controller from '@ember/controller';
import { get, computed } from '@ember/object';
import moment from 'moment';

export default Controller.extend({
  formattedBassMollettExportedAt: computed(
    'model.bassMollettExport',
    function() {
      return moment(get(this, 'model.bassMollettExport.updatedAt')).format(
        'LLL'
      );
    }
  ),

  invalidDeathDate: computed(
    'model.kase.deceased.profileDeathDate',
    function() {
      return get(this, 'model.kase.deceased.profileDeathDate') > moment();
    }
  )
});
