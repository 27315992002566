import Route from '@ember/routing/route'

export default class AdminLocationsEditLogosRoute extends Route {
  model() {
    return this.modelFor('authenticated.admin.locations.edit');
  }

  setupController(controller) {
    super.setupController(...arguments);

    controller.set('logos', this.store.findAll('v2/logo'));
  }
}