
import Serializer from './application';

export default Serializer.extend({
  modelNameFromPayloadKey(key) {
    if (key == 'chart-of-accounts') {
      return 'v2/chart-of-accounts';
    }

    return this._super(...arguments);
  }
});
