import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import Toolbelt from 'crakn/mixins/component-toolbelt';

export default Component.extend(Toolbelt, {
  classNames: ['list__item', 'list__item--wrap'],
  tagName: 'li',

  idKey: readOnly('invoiceCategory.id'),

  invoiceCategory: null,
  invoiceCategoryData: null,
  selection: null,
  isOpen: false,

  invoiceCategoryProducts: computed('invoiceCategoryData', function() {
    return this.invoiceCategoryData.objectAt(get(this, 'invoiceCategory.id'));
  }),

  invoiceCategoryTotal: computed(
    'idKey',
    'selection.{cashAdvanceTotal,merchandiseTotal,serviceTotal,specialChargeTotal}',
    function() {
      switch (this.idKey) {
        case '1':
          return get(this, 'selection.serviceTotal');
        case '2':
          return get(this, 'selection.merchandiseTotal');
        case '3':
          return get(this, 'selection.cashAdvanceTotal');
        case '4':
          return get(this, 'selection.specialChargeTotal');
      }
    }
  ),

  actions: {
    toggleOpen() {
      this.toggleProperty('isOpen');
    }
  }
});
