import { computed } from '@ember/object';
import SelectBox from './select-box';
import moment from 'moment';
import { range } from 'lodash-es/util';

export default SelectBox.extend({
  layoutName: 'components/form/select-box',
  disabled: false,
  startYear: moment().year(),
  endYear: moment().year(),
  placeholder: 'Year',
  options: computed('startYear', 'endYear', function() {
    const startYear = this.startYear;
    const endYear = this.endYear + 1;
    return range(startYear, endYear).map(String);
  }),
  sortBy: null
});
