import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  timeFrame: null,
  organization_location_id: null,

  formattedTimeFrame: computed('timeFrame', {
    get() {
      const timeFrame = this.timeFrame;
      if (typeof timeFrame === 'string') {
        return timeFrame;
      } else if (timeFrame === null) {
        return null;
      } else {
        return timeFrame.toISOString();
      }
    }
  }),

  reportLink: computed(
    'formattedTimeFrame',
    'organization_location_id',
    'uuid',
    {
      get() {
        const params = new URLSearchParams({
          year_month: this.formattedTimeFrame,
          organization_location_id: this.organization_location_id,
          uuid: this.uuid
        }).toString();
        return `/report/monthly_deceased?${params}`;
      }
    }
  ),

  fileUrl: computed('uuid', function() {
    return `#/generated_document/${this.uuid}`;
  }),

  actions: {
    handleTimeFrameChange(newTimeFrame) {
      this.set('timeFrame', newTimeFrame);
    }
  }
});
