import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class PrecoaSetupController extends Controller {
  showNewContactDateField = true;
  showClientPermissionField = true;

  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
