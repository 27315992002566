import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';

export default class KasesManageEditBlankFormsRoute extends SearchRoute {
  @service session;
  @service router;

  layout = 'custom-aside';

  queryParams = {
    archived: {
      refreshModel: true
    },
    category: {
      refreshModel: true,
    },
    page: {
      refreshModel: true
    },
    query: {
      replace: true,
      refreshModel: true
    }
  }

  modelType = 'blank-form';
  routeRegex = /authenticated.kases.manage.edit/;

  redirect() {
    if (this.session.currentClient.can_view_form_in_tfe) {
      this.router.transitionTo('authenticated.kases.manage.edit.tfe.library');
    }
  }

  model(params) {
    const kase = this.modelFor('authenticated.kases.manage');

    return this.store.query(this.modelType, {
      archived: params.archived || this.defaultArchived,
      category: params.category || this.defaultCategory,
      current_client: true,
      kase_id: kase.id,
      page: params.page || this.defaultPage,
      query: params.query || this.defaultQuery
    });
  }

  setupController(controller) {
    super.setupController(...arguments);

    const kase = this.modelFor('authenticated.kases.manage');

    controller.set('inquiries',
      this.store.findAll('inquiry', { kase: kase.id })
    );

    controller.set('kase', kase);
    controller.set('kase_id', kase.get('id'));
    controller.set('events', this.store.query('event', { kase_id: kase.id }));
    controller.set('inquiries', kase.inquiries);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.store.unloadAll('blankForm');
    }
  }
}
