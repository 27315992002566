import { readOnly } from '@ember/object/computed';
import Component from '@glimmer/component';
import { set } from '@ember/object';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { idClassBinding, idClass } from 'crakn/utils/component-toolbelt';
import { inject as service } from '@ember/service';
export default class SelectionPackageComponent extends Component {
  @readOnly('args.model.id') idKey;

  classNameBindings = [idClassBinding];
  classNames = ['list__item', 'list__item--wrap']
  tagName = 'li'

  @tracked isEditing = false;
  @tracked showDialog = false;
  @tracked selectionPackageSlotProducts;

  @service store;

  constructor(owner, args) {
    super(owner, args);
    this.store.query('v2/selection-package-slot-product', {
      selection_package_id: this.args.model.id
    }).then((selectionPackageSlotProducts) => {
      this.selectionPackageSlotProducts = selectionPackageSlotProducts;
    });
  }

  get idClass() {
    return idClass(this.idKey);
  }

  @action
  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  @action
  removePackage() {
    this.args.removePackage(this.args.model, 'packages');
  }

  @action
  revertPackage() {
    set(this, 'isEditing', false);
    this.args.revertPackage(this.args.model);
  }

  @action
  savePackage(collapseEdit) {
    if (collapseEdit) {
      set(this, 'isEditing', false);
    }

    return this.args.savePackage(this.args.model);
  }
}
