import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';


export default class AdminFinancialConfigurationsEditInterestsRoute extends Route {
  @service router;
  @service session;

  beforeModel() {
    if (this.session.currentUser.isSuperuser || this.session.currentClient.can_view_financial_config_interests) {
      return true;
    }

    // Unauthorized users are redirected to the dashboard
    this.router.transitionTo('authenticated.dashboard');
  }

  model() {
    const coa = this.modelFor(
      'authenticated.admin.financial-configurations.edit'
    );

    return this.store.query('v2/interestType', {
      chart_of_accounts_id: coa.get('id'),
      per_page: 0,
      archived: false,
    });
  }

  setupController(controller) {
    const coa = this.modelFor(
      'authenticated.admin.financial-configurations.edit'
    );

    controller.set(
      'chartOfAccounts',
      this.store.findRecord('v2/chartOfAccounts', coa.get('id'))
    );

    const accounts = this.store.query('v2/account', {
      chart_of_accounts_id: coa.get('id'),
      per_page: 0,
    });

    controller.set('accounts', accounts);
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
