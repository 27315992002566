import Controller from '@ember/controller';
import config from 'crakn/config/environment';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { run } from '@ember/runloop';
import { tracked } from '@glimmer/tracking'

export default class AuthenticatedKasesManageEditObituaryController extends Controller {
  @service api;
  @service browser;
  @service flashes;
  @tracked setText = null;

  @computed('model.id')
  get printObituaryUrl() {
    return `${
      config.host
    }/api/v2/obituaries/${this.model.id}.docx`;
  }

  autoSaveDraft() {
    this.model.save();
    this.flashes.addSuccess('A copy of your draft was autosaved!');
  }

  obituaryDraftRevert() {
    this.model.set('draftText', this.model.text);
    this.model.set('formattedDraftText', this.model.formattedText);
    this.setObitEditorText(this.model.formattedText)

    this.model.save();
  }

  @computed('model.{text,draftText}')
  get editing() {
    const obit = this.model;
    return obit.text !== obit.draftText;
  }

  checkModelGenerateObit() {
    if (this.model.isNew) {
      this.model.save().then(() => this.generateObit());
    } else {
      this.generateObit();
    }
  }

  generateObit() {
    this.api
    .json.post(
      `v2/obituaries/${this.model.id}/generate`,
      {}
    )
    .then(response => {
      if (response.ok) {
        const attributes = response.parsedJson.data.attributes
        this.model.text = attributes.text;
        this.model.draftText = attributes['draft-text'];
        this.model.formattedText = attributes['formatted-text'];
        this.model.formattedDraftText = attributes['formatted-draft-text'];
        this.setObitEditorText(this.model.formattedText);
        this.flashes.addSuccess('You successfully generated the obituary!');
      } else {
        this.flashes.addError('Oops! There was an error generating the obituary.');
      }
    });
  }

  @action
  setObituary(e) {
    this.model.set('draftText', e.raw);
    this.model.set('formattedDraftText', e.html);
    run.debounce(this, this.autoSaveDraft, 60000);
  }

  @action
  setDefaultFormattedText(html) {
    this.model.formattedDraftText = html;
  }

  @action
  searchNewspapers(query) {
    return this.store
                .query('v2/newspaper', {
                  query: query,
                  archived: false
                })
                .then((newspapers) => {
                  return newspapers.map(paper => paper.newspaperTitle);
                });
  }

  @action
  saveObituary(event) {
    if (!this.browser.isSafari) {
      event.submitter.disabled = true; // avoid double-click issues
    }

    event.preventDefault(); // Stop form redirect
    this.model.set('text', this.model.draftText);
    this.model.set('formattedText', this.model.formattedDraftText);

    this.model.save().then(() => {
      this.flashes.addSuccess('The obituary was saved!');
    }).finally(() => {
      if (!this.browser.isSafari) {
        event.submitter.disabled = false;
      }
    });
  }

  @action
  setObitEditorText(text) {
    this.set('setText', text);
  }

  @action
  revertDraft() {
    this.obituaryDraftRevert();
  }

  @action
  newspaperToggle() {
    this.toggleProperty('obituary.newspaper');
  }

  @action
  addNewspaper() {
    const obituary = this.model;
    obituary.save().then(() => {
      this.store.createRecord('v2/newspaper', { obituary });
    });
  }

  @action
  saveNewspaper(newspaper) {
    if (isEmpty(newspaper.newspaperTitle)) {
      this.flashes.addError('Newspaper title requires a title!');
    }
    else {
      newspaper
        .save()
        .then(() => {
          this.flashes.addSuccess('Great job! You updated the newspaper information!');
        });
    }
  }

  @action
  deleteNewspaper(newspaper) {
    if (newspaper.isNew === true) {
      newspaper.rollbackAttributes();
      this.flashes.addSuccess('Newspaper successfully removed');
    } else {
      newspaper
        .destroyRecord()
        .then(() => {
          this.flashes.addSuccess('Newspaper successfully removed');
        }).catch(() => {
          this.flashes.addError('An error has occured');
        });
    }
  }

  @action
  confirmObitGeneration() {
    const currentObitText = this.model.text;

    if (
      (currentObitText &&
      confirm(
        'Generating a new obituary will override the existing obituary, are you sure you want to continue?'
      )) ||
      isEmpty(currentObitText)
    ) {
      this.checkModelGenerateObit();
    }
  }
}
