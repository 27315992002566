import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminCustomFieldNewController extends Controller {
  @service flashes;
  @service router;
  @service session;
  @service store;
  @service location;
  @service intl;


  get locationOptions() {
    const allLocations = this.location.all;
    const configLocationIds = this.customFieldConfigs
      .filter((config) => !config.get('isNew'))
      .map((config) => {
        return config.get('location.id');
      });

    const filteredLocations = allLocations.filter((location) => {
      return !configLocationIds.includes(location.id);
    });

    const options = filteredLocations.map((location) => {
      return { value: location.id, name: location.name };
    });

    if (configLocationIds.includes(undefined)) {
      return options;
    } else {
      return [{ value: undefined, name: 'All Locations' }, ...options];
    }
  }

  @action
  async selectLocation(locationId) {
    if (locationId) {
      const selectedLocation = await this.store.findRecord(
        'v2/location',
        locationId
      );
      this.customFieldConfig.set('location', selectedLocation);
    }
  }

  @action
  removeCustomField(field) {
    this.customFieldConfig.customFields.removeObject(field);
  }

  @action
  addCustomField() {
    this.store.createRecord('v2/custom-field', {
      customFieldConfig: this.customFieldConfig
    });
  }

  @action
  cancelEdit(field) {
    if (field.isNew) {
      this.removeCustomField(field);
    } else {
      this.send('refreshModel');
    }
  }

  @action
  close() {
    if (
      this.intl.t('authenticated.admin.custom-fields.new.actions.close')
    ) {
      this.router.transitionTo('authenticated.admin.manage.custom-fields');
    }
  }

  @action
  saveAndClose() {
    this.customFieldConfig
      .save()
      .then(() => {
        this.flashes.addSuccess(this.intl.t('authenticated.admin.custom-fields.new.actions.save'));
        this.router.transitionTo('authenticated.admin.manage.custom-fields');
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.custom-fields.new.actions.error'));
      });
  }

  @action
  save() {
    const client = this.store.findRecord(
      'v2/client',
      this.session.currentClient.id
    );
    this.customFieldConfig.set('client', client);
    this.customFieldConfig
      .save()
      .then((config) => {
        this.router.transitionTo(
          'authenticated.admin.manage.custom-fields.edit',
          config.id
        );
        this.flashes.addSuccess(this.intl.t('authenticated.admin.custom-fields.new.actions.save'));
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.custom-fields.new.actions.error'));
      });
  }
}
