import Mixin from '@ember/object/mixin';

export default Mixin.create({
  customAction(action, method, data) {
    const adapter = this.store.adapterFor(this.constructor.modelName);
    return adapter.ajax(this.getActionUrl(action, adapter), method, { data });
  },

  getActionUrl(action, adapter) {
    return `${adapter.buildURL(this.constructor.modelName, this.id)}/${action}`;
  }
});
