import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default class InsurancePolicyModel extends Model {
  @attr('boolean', { defaultValue: false }) assignedToPayment;
  @attr('string') ageAtPurchase;
  @attr('string') currentFaceValue;
  @attr('string') initialFaceValue;
  @attr('string') insuranceCompany;
  @attr('string') policyBenefits;
  @attr('string') policyNumber;
  @attr('string') policyStatus;
  @attr('string') policyTerm;
  @attr('moment-date') establishedAt;
  @attr('moment-date') lastStatusChangeAt;
  @attr('moment-datetime') archivedAt;

  @belongsTo('person', { async: true }) policyTransferredTo;
  @belongsTo('preneed', { async: true }) preneed;

  @hasMany('beneficiary', { async: true }) beneficiaries;

  isEditing = false;

  get name() {
    const company = this.insuranceCompany;
    const number = this.policyNumber;
    const beneficiaries = this.beneficiaries;

    if (company && number && beneficiaries && beneficiaries.length > 0) {
      return `${company} - ${number} ${beneficiaries
        .map((benefit) => {
          return `- ${benefit.get('personName')}`;
        })
        .join('')}`;
    } else if ((company || number) && beneficiaries && beneficiaries.length > 0) {
      return `${company || number} ${beneficiaries
        .map((benefit) => {
          return `- ${benefit.get('personName')}`;
        })
        .join('')}`;
    } else if (company && number) {
      return `${company} - ${number}`;
    } else if (company) {
      return company;
    } else if (number) {
      return number;
    } else {
      return 'Policy';
    }
  }
}
