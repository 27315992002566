import { and } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';
import { get, computed } from '@ember/object';
import { isString } from 'lodash-es/lang';

export default Mixin.create({
  classNameBindings: ['validationVisible:input--invalid'],
  qaClass: null,

  value: null,
  label: null,

  validationVisible: and('showValidation', 'validationMessage'),

  validationMessage: computed('validation', function() {
    const validation = this.validation;

    if (!validation) {
      return;
    }

    return isString(validation) ? validation : get(validation, 'firstObject');
  })
});
