import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AdminGeneralPriceListsEditCostUpdatesController extends SearchController {
  @service flashes;
  @service intl;

  @tracked archived = false;
  @tracked category_id = null;
  @tracked page = 1;

  chartOfAccounts = null;
  general_price_list_id = null;
  generalPriceList = null;
  gplProductCategories = null;
  order = 'ASC';
  queryParams = ['sort_by', 'order', 'general_price_list_id'];
  sortBy = null;

  get products() {
    const archived = this.archived;

    return this.model.filter(function (item) {
      return (item.archivedAt === null) !== archived;
    });
  }

  handleSaveSuccess() {
    this.flashes.addSuccess(this.intl.t('authenticated.admin.general-price-lists.edit.cost-updates.messages.success'));
  }

  handleSaveError() {
    this.flashes.addError(this.intl.t('authenticated.admin.general-price-lists.edit.cost-updates.messages.error'));
  }

  @action
  sortBy(sortProperty) {
    if (this.sort_by === sortProperty) {
      this.order = this.order === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.sort_by = sortProperty;
      this.order = 'ASC';
    }
  }

  @action
  saveCostUpdates() {
    const save = (x) => x.save();
    const products = this.model.filterBy('hasDirtyAttributes');
    const savePromises = products.map(save);
    Promise.all(savePromises)
      .then(() => this.handleSaveSuccess())
      .catch(() => this.handleSaveError());
  }

  @action
  cancelCostUpdates() {
    this.model.forEach((product) => {
      product.rollbackAttributes();
    });
  }

  @action
  setProductCategory(categoryId) {
    this.category_id = categoryId;
    this.resetPagination();
  }

  @action
  resetPagination() {
    this.page = 1;
  }
}
