import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KasesEditKaseFormsRoute extends Route {
  @service session;
  @service router;

  layout= 'custom-aside';

  queryParams = {
    page: {
      refreshModel: true
    }
  };

  redirect() {
    if (this.session.currentClient.can_view_form_in_tfe) {
      this.router.transitionTo('authenticated.kases.manage.edit.tfe.filled');
    }
  }

  model(params) {
    const kase = this.modelFor('authenticated.kases.manage');
    return this.store.query('kaseForm', {
      kase_id: kase.get('id'),
      page: params.page
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('kase', this.modelFor('authenticated.kases.manage'));
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
