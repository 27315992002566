import { sort } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';

export default class InvoiceCategoriesService extends Service {
  @service store;
  invoiceCategories = null;

  get all() {
    if (this.invoiceCategories == null) {
      this.invoiceCategories = this.store.findAll('invoiceCategory');
    }

    return this.invoiceCategories;
  }

  sortBy = ['sortOrder'];

  @sort('all', 'sortBy') allSorted;
}
