import SearchController from 'crakn/controllers/search';
import { sort } from '@ember/object/computed';
import { bind } from '@ember/runloop';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class AuthenticatedContactsIndexController extends SearchController {
  @service names;

  queryParams = ['query', 'page', 'perPage', 'archived', 'sortBy'];

  @tracked sortProperty = ['name'];
  @tracked sortOrder = 'asc';
  @tracked sortBy = null;
  @tracked order = 'ASC';

  @sort('model', 'sortArray') sortedContacts;

  @computed('sortProperty', 'sortOrder')
  get sortArray() {
    return [`${this.sortProperty}:${this.sortOrder}`];
  }

  @action
  doAddNew() {
    const names = this.names.splitNameIntoFirstAndLastName(this.query);

    this.transitionToRoute('authenticated.contacts.new').then(
      bind(this, function(newRoute) {
        newRoute.set('controller.model.firstName', names.firstName);
        newRoute.set('controller.model.lastName', names.lastName);
      })
    );
  }

  @action
  toggleArchived(contact) {
    if (contact.get('archivedAt')) {
      contact.set('archivedAt', null);
    } else {
      contact.set('archivedAt', moment());
    }

    contact.save().then(() => {
      this.send('refreshModel');
    });
  }
}
