export default [
  {
    name: 'England',
    id: 'GB-ENG'
  },
  {
    name: 'Northern Ireland',
    id: 'GB-NIR'
  },
  {
    name: 'Scotland',
    id: 'GB-SCT'
  },
  {
    name: 'Wales',
    id: 'GB-WLS'
  },
];
