import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  calendarDay: service(),

  afterModel(model) {
    this.calendarDay.fetchDate(model.get('startsAt'));
  },

  actions: {
    willTransition(transition) {
      const model = this.currentModel;
      const modelIsDirty =
        !transition.to.queryParams.noDirtyCheck &&
        (model.get('hasDirtyAttributes') ||
          model
            .get('eventStaffMembers')
            .filterBy('hasDirtyAttributes', true)
            .get('length') ||
          model
            .get('eventAttendees')
            .filterBy('hasDirtyAttributes', true)
            .get('length'));

      if (modelIsDirty && !confirm('You have unsaved changes. Are you sure?')) {
        transition.abort();
      } else {
        return true;
      }
    }
  }
});
