import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li class=\"list__content flex flex--xs--j--sb\">\n  <div class=\"c--xs--9 m--sm--y\">\n    <span class=\"list__title text--md h--md\">\n      {{#if @payment.selection.isMiscSale }}\n        {{@payment.selection.contractName}}\n      {{else}}\n        {{@payment.selection.contractName}} - {{@payment.selection.name}}\n      {{/if}}\n    </span>\n  </div>\n\n  <div class=\"c--xs--1 m--sm--t\">\n    <span class=\"list__details strong text--md h--md m--lg--r\">{{show-price @payment.amount}}</span>\n  </div>\n\n  <div class=\"c--xs--2 text--r\">\n    <button\n        type=\"button\"\n        class=\"btn btn--link red\"\n        {{on \"click\" this.toggleShowDialog}}>\n      <MdIcon @icon=\"close\" @size=\"md\"/>\n    </button>\n  </div>\n\n  <DeleteDialog\n      @showDialog={{this.showDialog}}\n      @onDelete={{fn this.removeSplitPayment @payment}}/>\n</li>\n", {"contents":"<li class=\"list__content flex flex--xs--j--sb\">\n  <div class=\"c--xs--9 m--sm--y\">\n    <span class=\"list__title text--md h--md\">\n      {{#if @payment.selection.isMiscSale }}\n        {{@payment.selection.contractName}}\n      {{else}}\n        {{@payment.selection.contractName}} - {{@payment.selection.name}}\n      {{/if}}\n    </span>\n  </div>\n\n  <div class=\"c--xs--1 m--sm--t\">\n    <span class=\"list__details strong text--md h--md m--lg--r\">{{show-price @payment.amount}}</span>\n  </div>\n\n  <div class=\"c--xs--2 text--r\">\n    <button\n        type=\"button\"\n        class=\"btn btn--link red\"\n        {{on \"click\" this.toggleShowDialog}}>\n      <MdIcon @icon=\"close\" @size=\"md\"/>\n    </button>\n  </div>\n\n  <DeleteDialog\n      @showDialog={{this.showDialog}}\n      @onDelete={{fn this.removeSplitPayment @payment}}/>\n</li>\n","moduleName":"crakn/components/g/batch-payments/payments.hbs","parseOptions":{"srcName":"crakn/components/g/batch-payments/payments.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class GCraknBatchPaymentsPaymentsComponent extends Component {
  @tracked showDialog = false;

  @action
  toggleShowDialog() {
    this.showDialog = !this.showDialog;
  }

  @action
  removeSplitPayment(payment) {
    this.args.changeset.payments = this.args.changeset.payments.filter(p => p !== payment);

    const paymentId = this.args.editMode ? payment.get('id') : payment.get('selection.id');
    if (this.args.setPaymentsToDestroy) {
      this.args.setPaymentsToDestroy(payment);
    }

    payment.destroyRecord().then(() => {
      delete(this.args.totalSplitPayments[paymentId]);
      this.args.setRemainingBatchPaymentAmount(0);
      this.showDialog = false;
    });
  }
}
