import Route from '@ember/routing/route';

export default class AuthenticatedContactsEditRoute extends Route {
  model(params) {
    return this.store.findRecord('v2/person', params.contact_id, {
      adapterOptions: { include: 'birthplace,funeral-decisions-person' },
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        model: null,
        redirectPath: '',
        redirectId: '',
      });
    }
  }

  refreshModel() {
    this.refresh();
  }
}
