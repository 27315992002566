import Model, { attr, belongsTo } from '@ember-data/model';

export default class PrintableModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('string') uuid;
  @attr('string') category;
  @attr('string') subcategory;

  @belongsTo('v2/client', { async: true }) client;
}
