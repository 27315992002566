import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2OnlinePlannerConfigModel extends Model {
  @attr('boolean') default;
  @attr('boolean') defaultExists;
  @attr('boolean') isPackageSelectionRequired;

  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') updatedAt;

  @attr('object') config;

  @attr('string', { defaultValue: 'left' }) logoAlignment
  @attr('string', { defaultValue: 'small' }) logoSize
  @attr('string') name;
  @attr('string') urlPrefix;

  @belongsTo('v2/client', { async: true }) client;
  @belongsTo('v2/general-price-list', { async: true }) generalPriceList;
  @belongsTo('v2/location', { async: true }) defaultTaxLocation;
  @belongsTo('v2/logo', { async: true }) logo;
  @belongsTo('v2/payment-type', { async: true }) paymentType;

  @hasMany('v2/location-online-planner-config', { async: true}) locationOnlinePlannerConfigs;
  @hasMany('v2/online-planner-config-user-notification', { async: true }) onlinePlannerConfigUserNotifications;
}
