import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknFormsNewRoute extends Route {
  @service store;

  model() {
    const form = this.store.createRecord('blankForm');

    return RSVP.hash({
      form
    });
  }

  setupController(controller, model, _transition) {
    super.setupController(...arguments);
    controller.setProperties(model);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      controller.setProperties({
        form: null,
        formFile: null,
        showValidation: false
      });
    }
  }
}
 