import Route from '@ember/routing/route'
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminUsersEditFeatureConfigurationRoute extends Route {
  @service router;
  @service session;
  @service userConfig;

  model() {
    return this.modelFor('authenticated.admin.users.edit');
  }

  setupController(controller, model) {
    super.setupController(...arguments)

    controller.set('user', model);
  }

  @action
  resetController(controller) {
    controller.set('searchValue', null);
    // Reset userConfig filter results when transitioning from page
    this.userConfig.filterFeatures('All');
  }

  @action
  willTransition(transition) {
    if (this.currentModel.get('hasDirtyAttributes')) {
      if (confirm('You have unsaved changes. Are you sure?')) {
        this.currentModel.rollbackAttributes();
        return true;
      } else {
        transition.abort();
      }
    }
  }
}
