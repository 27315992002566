import Controller from '@ember/controller';
import { action, computed, get } from '@ember/object';
import { imageExtensions } from 'crakn/utils/data-helpers';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

const Validations = buildValidations({
  'product.name': validator('presence', true),
  'product.productCategory.id': validator('presence', true),
  'product.invoiceCategory.id': validator('presence', true),
  'product.price': validator('presence', true),
});

export default class AdminGeneralPriceListsEditProductsEditController extends Controller.extend(
  Validations
) {
  @service flashes;
  @service router;
  @service session;
  @service store;

  @tracked additionalInfo = false;
  @tracked showValidations = false;
  @tracked specialTaxToggle = true;
  @tracked inventoryType = 'Global';
  @tracked inventoryLocationId;
  @tracked location;

  queryParams = ['redirectId'];

  generalPriceList = null;
  productCategories = null;
  redirectId = null;
  redirectPath = null;
  showInventory = false;

  @computed('product.specialTaxId')
  get currentSpecialTax() {
    return this.product.specialTaxId
      ? this.store.findRecord('specialTax', this.product.specialTaxId)
      : null;
  }

  get chartOfAccountsId() {
    return this.generalPriceList.get('chartOfAccounts.id')
      ? this.generalPriceList.get('chartOfAccounts.id')
      : '';
  }

  @computed('chartOfAccountsId')
  get allSpecialTaxes() {
    const coa = this.chartOfAccountsId;
    return this.store.query('specialTax', {
      chart_of_accounts_id: coa,
    });
  }

  get imageExtensions() {
    return imageExtensions;
  }

  get hasPackages() {
    return this.packages.length > 0;
  }

  get disabled() {
    if (this.product.tracking && this.inventory) {
      if (this.inventory.quantity === 0 || this.inventory.quantity === null) {
        return false;
      } else {
        return true;
      }
    } else if (this.hasPackages === true) {
      return true;
    } else {
      return false;
    }
  }

  @computed('product.{tracking,archivedAt}')
  get canViewInventoryLink() {
    if (this.product.tracking && !this.product.archivedAt) {
      return true;
    } else {
      return false;
    }
  }

  @computed('additionalInfo', 'product.{interior,manufacturer,material,model}')
  get showAdditionalProductInfo() {
    if (
      this.product.manufacturer ||
      this.product.model ||
      this.product.material ||
      this.product.interior ||
      this.additionalInfo
    ) {
      return true;
    } else {
      return false;
    }
  }

  set showAdditionalProductInfo(value) {
    this.additionalInfo = value;
  }

  handleSaveSuccess(shouldTransition) {
    this.flashes.addSuccess('Yay! You saved a product.');

    if (shouldTransition) {
      this.router.transitionTo(
        'authenticated.admin.general-price-lists.edit.products'
      );
    }
  }

  handleSaveError() {
    this.flashes.addError('Oops! There was an error saving this product.');
  }

  saveProductCategory(shouldTransition) {
    this.product.productCategory.content.save().then(() => {
      this.saveProduct(shouldTransition);
    });
  }

  @action
  selectInventory() {
    const inventoryId = document.querySelector('#existingInventory select').value;
    
    if (inventoryId && inventoryId != '') {
      const inventory = this.store.peekRecord('inventory', inventoryId);
      this.product.tracking = true;
      this.store.createRecord('productInventory', {
        inventory,
        product: this.product
      })
        .save()
        .then(() => {
          this.set('inventory', inventory);
          this.product.save().then(() => {
            this.flashes.addSuccess('Product is now inventoried.');
          })
        })
    } else {
      this.flashes.addError('You must select an inventory!');
    }
  }

  @action
  save(shouldTransition = true) {
    if (this.product.productCategory.get('isNew')) {
      this.saveProductCategory(shouldTransition);
    } else {
      this.validate().then(({ validations }) => {
        if (validations.isValid) {
          this.showValidations = false;
          this.product
            .save()
            .then(() => {
              this.handleSaveSuccess(shouldTransition);
            })
            .catch(() => {
              this.handleSaveError();
            });
        } else {
          this.showValidations = true;
        }
      });
    }
  }

  @action
  close() {
    this.router.transitionTo(
      'authenticated.admin.general-price-lists.edit.products',
      this.generalPriceList
    );
  }

  @action
  addImage(response) {
    this.product.imageUrl = response.url;
  }

  @action
  removeImage() {
    this.product.imageUrl = null;
  }

  @action
  defaultTax() {
    this.product.isTaxed = !this.product.isTaxed;
    if (this.product.isTaxed == true) {
      this.product.stateTax = true;
      this.product.localTax = true;
    } else if (this.product.isTaxed == false) {
      this.product.stateTax = false;
      this.product.localTax = false;
      this.product.specialTax = false;
    }
  }

  @action
  toggleSpecialTaxEdit() {
    this.specialTaxToggle = !this.specialTaxToggle;
  }

  @action
  toggleArchived(product) {
    product.set('archivedAt', product.get('archivedAt') ? null : moment());

    product.save();

    this.flashes.addSuccess(`${get(product, 'name')} has been updated!`);
  }

  @action
  onWillDestroy() {
    this.inventoryLocationId = null;
    this.inventoryType = 'Global';
  }
}
