import { readOnly, sort } from '@ember/object/computed';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import PersonSmartEntry from 'crakn/mixins/person-smart-entry';
import PaymentValidations from 'crakn/utils/payment-validations';

export default Controller.extend(PersonSmartEntry, PaymentValidations, {
  flashes: service(),
  queryParams: ['token'],
  token: null,
  usePurchaserInfo: false,
  altPayer: false,
  kase: null,
  selection_id: null,

  paymentType: readOnly('newPayment.paymentType.id'),

  smartEntryPersonMeta: {
    payer: {
      key: 'newPayment.person',
      label: 'Payer',
      srcOptions: ['mother', 'father', 'spouse', 'purchaser', 'informant']
    },
    mother: { key: 'kase.mother', label: 'Mother' },
    father: { key: 'kase.father', label: 'Father' },
    spouse: { key: 'kase.spouse', label: 'Spouse' },
    purchaser: { key: 'kase.purchaser', label: 'Responsible Party' },
    informant: { key: 'kase.informant', label: 'Informant' }
  },

  showValidation: false,

  disablePayment: computed('model.card.cc_number', function () {
    if (get(this, 'model.card.cc_number')) {
      const ccNumber = get(this, 'model.card.cc_number').replace(/\s/g, '');
      return ccNumber.length >= 12 ? false : true;
    } else {
      return true;
    }
  }),

  sortedPayments: sort('selection.payments', function (a, b) {
    const collectedDiff = b.get('collectedAt') - a.get('collectedAt');
    const enteredDiff = b.get('createdAt') - a.get('createdAt');
    return collectedDiff || enteredDiff;
  }),

  actions: {
    validatePayment(newPayment, selection) {
      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          this.send('saveNewPayment', newPayment, selection);
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    togglePayer() {
      this.toggleProperty('altPayer');
    }
  }
});
