import Model, { attr, belongsTo } from '@ember-data/model';

export default class BlankContractModel extends Model {
  @attr('boolean') esignatureEnabled;
  @attr('array') esignatureFieldNames;
  @attr('string') name;

  /** Just for sending the params on creation/update */
  @attr('boolean') buyer;
  @attr('boolean') cosign;
  @attr('boolean') funeralHome;
  @attr('boolean') secondary;

  @belongsTo('client') client;
}
