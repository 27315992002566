import Component from '@ember/component';

export default Component.extend({
  isExpanded: false,

  actions: {
    expandPanel() {
      this.toggleProperty('isExpanded');
      this.sendAction('onPanelClick');
    }
  }
});
