import Controller from '@ember/controller';
import { action } from '@ember/object';
import { filterBy, mapBy } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CraknClientsEditSfgsConfigController extends Controller {
  @controller('authenticated/crakn/clients/edit') edit;

  @service flashes;
  @service session;

  @tracked showValidation = false;

  @filterBy('contractTemplates', 'isNew', false) savedContractTemplates;
  @mapBy('systemSfgsTemplates', 'name') systemSfgsTemplateNames;

  get contractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice ? 
    `Invoice` : `Contract`;
  }

  @action
  buildContractTemplate() {
    this.store.createRecord('v2/contractTemplate', {
      client: this.client, // client is required since this can be a TMS client
      isEditing: true
    });
  }

  @action
  saveContractTemplate(template, event) {
    event.preventDefault();

    template.validate().then(({ validations }) => {
      if (validations.get('isValid')) {
        this.showValidation = false;

        template
          .save()
          .then(() => {
            this.flashes.addSuccess(`${this.contractLabel} Template saved!`);
            template.set('isEditing', false);
          })
          .catch(() => {
            this.flashes.addError(
              `Oops! There was an error saving this ${this.contractLabel} Template.`
            );
          });
      } else {
        this.showValidation = true;
      }
    });
  }

  @action
  cancelContractTemplate(template) {
    template.set('isEditing', false);
    template.rollbackAttributes();
  }

  @action
  deleteContractTemplate(template) {
    template
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess(`${this.contractLabel} Template deleted!`);
      })
      .catch(() => {
        this.flashes.addError(
          `Oops! There was an error deleting this ${this.contractLabel} Template.`
        );
      });
  }
}
