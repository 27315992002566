import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2OnlinePlannerSessionPaymentModel extends Model {
  @attr('price') amount;
  @attr('boolean') processorPending;
  @attr('string') achAccountNumberLastFour;
  @attr('string') cardType;
  @attr('string') ccLastFour;
  @attr('string') payer;
  @attr('string') returnUrl;
  @attr('string') stripeCheckoutUrl;
  @attr('string') tpsChargeId;

  @belongsTo('v2/online-planner-session', { async: true }) onlinePlannerSession;
}
