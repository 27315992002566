import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminGeneralPriceListsEditPackagesEditManageProductsRoute extends Route {
  @service store;

  queryParams = {
    query: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
  }

  async model(params) {
    const packageSlot = await this.store.findRecord('v2/package-slot', params.package_slot_id);

    return this.store.query('v2/product', {
      product_category_id: packageSlot.productCategory.get('id'),
      query: params.query,
      page: params.page,
      per_page: 10
    });
  }

  setupController(controller) {
    super.setupController(...arguments);

    const packageSlot = this.store.peekRecord('v2/package-slot', this.paramsFor(this.routeName).package_slot_id);

    controller.set('packageSlot', packageSlot);
    controller.set('packageId', packageSlot.get('package.id'));
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
