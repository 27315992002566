import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class FuneraloneIntegrationRoute extends Route {
  @service store;

  model() {
    return this.store.query('integrationService', {
      name: 4, // funeralone
      not_category: 'accounting',
      per_page: 1000
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
