import Model, { belongsTo, attr } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default class SelectionPackageProductModel extends Model {
  @attr('string') price;
  @attr('string') notes;

  @belongsTo('selectionPackage', { async: true }) selectionPackage;
  @belongsTo('product', { async: true }) product;

  @alias('product.isTaxed') isTaxed;
}
