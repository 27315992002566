import { helper as buildHelper } from '@ember/component/helper';
import { formatFloat } from 'crakn/utils/data-helpers';

export function priceCompare(array) {
  let [current, change, nullToZero] = array;
  if (nullToZero) {
    if (current === null) current = 0;
    if (change === null) change = 0;
  }

  return formatFloat(current, true, true, 2, true) === formatFloat(change, true, true, 2, true);
}

export default buildHelper(priceCompare);
