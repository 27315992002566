import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class CaseNumberingComponent extends Component {
  @tracked isShowingExamples = false;
  @tracked isShowingAdvanced = false;

  @action
  toggleAdvanced() {
    this.isShowingAdvanced = !this.isShowingAdvanced;
  }

  @action
  toggleExamples() {
    this.isShowingExamples = !this.isShowingExamples;
  }

  @action
  fill(string) {
    this.args.model.set('kaseNumberingFormat', string);
  }
}
