import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { ActiveModelSerializer } from 'active-model-adapter';

export default ActiveModelSerializer.extend(EmbeddedRecordsMixin, {
  modelNameFromPayloadKey(key) {
    if (key == 'charts_of_accounts' || key == 'chart_of_accounts') {
      return 'chart-of-accounts';
    }
    return this._super(...arguments);
  }
});
