import Route from '@ember/routing/route';

export default class KasesManageEditInquiriesRoute extends Route {
  layout = 'custom-aside';

  queryParams = {
    page: {
      refreshModel: true
    }
  };

  model(params) {
    const kaseId = this.modelFor('authenticated.kases.manage').get('id');
    return this.store.query('inquiry', {
      kase: kaseId,
      page: params.page,
      per_page: 10
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('kase', this.modelFor('authenticated.kases.manage'));
  }
}
