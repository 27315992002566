import Model, { belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  displayName: validator('presence', {
    presence: true,
    ignoreBlank: true,
    message: 'The display name should not be empty or consist only of spaces'
  })
});

export default class V2ContractTemplateModel extends Model.extend(Validations) {
  @attr('string') displayName;
  @attr('string') templateName;
  @attr('boolean', { defaultValue: true }) hideDiscount;
  @attr('boolean', { defaultValue: false }) showCashAdvanceVerbiage;
  @attr('boolean', { defaultValue: false }) showDateOfService;
  @attr('boolean', { defaultValue: true }) showProductNotes;
  @attr('boolean', { defaultValue: true }) showLocation;
  @attr('boolean', { defaultValue: false }) showPurchaserSSN;
  @attr('boolean', { defaultValue: false }) showFuneralDirectorLicenseNumber;
  @attr('boolean', { defaultValue: false }) isCustom;

  @belongsTo('v2/client') client;

  // eSign fields
  @attr('boolean') esignatureEnabled;
  @attr('array') esignatureFieldNames;
  @attr('boolean', { defaultValue: false }) hasBuyerEsign;
  @attr('boolean', { defaultValue: false }) hasCosignEsign;
  @attr('boolean', { defaultValue: false }) hasSecondaryCosignEsign;
  @attr('boolean', { defaultValue: false }) hasFuneralHomeEsign;
  
  @attr('moment-datetime') archivedAt;
}
