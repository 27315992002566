import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import config from 'crakn/config/environment';
import { v4 as uuidv4 } from 'uuid';

export default Component.extend({
  didReceiveAttrs() {
    if (this.isDestroyed) {
      return;
    }

    this.store
      .queryRecord('selection', {
        is_contract: true,
        kase_id: get(this, 'kase.id'),
        type: 'KaseSelection'
      })
      .then(selection => {
        set(this, 'selection', selection);
      });
  },

  classNames: ['card', 'card--nudged'],

  api: service(),
  store: service(),
  session: service(),
  invoiceCategories: service(),

  isDiscountOpen: false,
  isPackagesOpen: false,

  hostUrl: `${config.host}`,

  kase: null,

  selection: null,
  packages: readOnly('selection.packages'),
  products: readOnly('selection.products'),

  taxRate: readOnly('kase.location.stateSalesTaxRate'),
  customProducts: readOnly('selection.customProducts'),

  get contractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice ? 
    `Invoice` : `Contract`;
  },

  actions: {
    togglePackages() {
      this.toggleProperty('isPackagesOpen');
    },

    saveAsPDF() {
      const kase = this.kase;
      const selection = this.selection;
      const newUuid = uuidv4();
      const contractTemplateId = selection.get('lastUsedContractTemplate.id') || kase.get('location.defaultContractTemplate.id');

      this.api.json
          .get(
            'kase_statements',
            {
              params: {
                kase_id: get(kase, 'id'),
                uuid: newUuid,
                contract_template_id: contractTemplateId
              }
            }
          )
          .then(response => {
            if (!response.ok) {
              this.send('flashError', 'Oops, something went wrong! Please try again.');
            }
          });

        set(selection, 'uuid', newUuid);
    }
  }
});
