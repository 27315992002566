import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import Toolbelt from 'crakn/mixins/component-toolbelt';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';

export default Component.extend(Toolbelt, {
  classNames: ['list__item'],
  tagName: 'li',

  intl: service(),
  flashes: service(),
  session: service(),

  refund: null,

  idKey: readOnly('discount.id'),

  actions: {
    setDialog(discount) {
      if (dateInCoaLockedPeriod(discount.get('selection'), this.session, discount.get('collectedAt'))) {
        this.flashes.addError(this.intl.t('components.list.discount-summary.errors.paymentPostingPeriod'));
        return;
      }
      set(this, 'showDialog', true);
    },

    removeDiscount(discount) {
      this.attrs.onRemove(discount);
    }
  }
});
