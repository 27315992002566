import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n    class=\"textarea--lg crakn-quill-container\"\n    ...attributes\n    {{did-insert this.setup}}\n    {{did-update this.setEditorText @setText}}>\n</div>\n\n{{#if this.showHyperlinkModal}}\n  <CraknModal @overlayClassNames=\"modal__overlay obit_terms_modal\">\n    <G::Crakn::Field::Quill::HyperlinkTermsModal\n        @onAccept={{this.acceptHyperlinkTerms}}\n        @onDecline={{this.declineHyperlinkTerms}}/>\n  </CraknModal>\n{{/if}}\n", {"contents":"<div\n    class=\"textarea--lg crakn-quill-container\"\n    ...attributes\n    {{did-insert this.setup}}\n    {{did-update this.setEditorText @setText}}>\n</div>\n\n{{#if this.showHyperlinkModal}}\n  <CraknModal @overlayClassNames=\"modal__overlay obit_terms_modal\">\n    <G::Crakn::Field::Quill::HyperlinkTermsModal\n        @onAccept={{this.acceptHyperlinkTerms}}\n        @onDecline={{this.declineHyperlinkTerms}}/>\n  </CraknModal>\n{{/if}}\n","moduleName":"crakn/components/g/crakn/field/quill/index.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/field/quill/index.hbs"}});
import Component from '@glimmer/component';
import Quill from 'quill';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking'
const Bold = Quill.import('formats/bold');
const Italic = Quill.import('formats/italic');
const Align = Quill.import('attributors/style/align');

export default class GCraknFieldQuill extends Component {
  @service flashes;
  @service intl;
  @service session;
  @tracked hyperlinkTermsButton = null;
  @tracked linkButton = null;
  @tracked showHyperlinkModal = false;

  quill;

  @action
  setup(element, options) {
    Bold.tagName = 'B';
    Italic.tagName = 'I';
    Quill.register({
      Bold,
      Italic
    }, true);

    Quill.register(Align, true);

    options = {
      modules: {
        toolbar: {
          container: [
            ['bold', 'italic'],
            [{ align: ['', 'center', 'right'] }],
            ['link', 'termsLink']
          ],
          handlers: {
            'termsLink': () => { this.openHyperlinkModal(); }
          }
        }
      },
      placeholder: this.args.placeholder,
      theme: 'snow',
      bounds: element
    };
    this.quill = new Quill(element, options);
    this.quill.on('text-change', this.change);
    this.setEditorText();
    this.setupHyperlinkButtons();
    this.setHyperlinkDisplay();
  }

  @action
  setupHyperlinkButtons() {
    this.linkButton = document.querySelector('.ql-link');
    this.hyperlinkTermsButton = document.querySelector('.ql-termsLink');
    this.hyperlinkTermsButton.appendChild(this.linkButton.firstChild.cloneNode(true));
  }

  @action
  openHyperlinkModal() {
    this.showHyperlinkModal = true;
  }

  @action
  change() {
    this.args.onChange({
      raw: this.quill.getText(),
      html: this.quill.root.innerHTML
    });
  }

  @action
  setEditorText() {
    if (!this.args.setText) {
      return;
    }
    const delta = this.quill.clipboard.convert(this.args.setText);
    this.quill.setContents(delta, 'silent');
    this.args.setFormattedText(this.quill.root.innerHTML);
  }

  @action
  setHyperlinkDisplay() {
    if (this.session.currentUser.agreedToHyperlinkTermsOfUse) {
      this.hyperlinkTermsButton.style.display = 'none';
      this.linkButton.style.display = 'inline-block';
    } else {
      this.hyperlinkTermsButton.style.display = 'inline-block';
      this.linkButton.style.display = 'none';
    }
  }

  @action
  acceptHyperlinkTerms() {
    this.showHyperlinkModal = false;
    this.session.currentUser.agreedToHyperlinkTermsOfUse = true;
    this.session.currentUser.save()
      .then(() => {
        this.setHyperlinkDisplay();
    }).catch(() => {
      this.session.currentUser.agreedToHyperlinkTermsOfUse = false;
      this.flashes.addError(this.intl.t('components.g.crakn.field.quill.hyperlink.termsOfUse.error'));
    });
  }

  @action
  declineHyperlinkTerms() {
    this.showHyperlinkModal = false;
    this.setHyperlinkDisplay();
  }
}
