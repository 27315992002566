import SearchController from 'crakn/controllers/search';
import { sort } from '@ember/object/computed';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'newGroup.name': validator('presence', true),
});

export default class AdminGroupsController extends SearchController.extend(Validations) {
  @service flashes;
  @service session;
  @service store;

  @tracked deletingGroup = null;
  @tracked newGroup = null;
  @tracked showValidations = false;

  showValidation = false;

  sorting = ['name:asc'];
  @sort('model', 'sorting') sortedContent;

  @action
  addNewGroup() {
    this.newGroup = this.store.createRecord('group', {
      clientId: this.session.currentClient.id,
    });
  }

  @action
  saveNewGroup() {
    this.validate().then(() => {
      if (this.validations.isValid) {
        this.showValidations = false;
        this.newGroup
          .save()
          .then(() => {
            this.newGroup = null;
            this.send('refreshModel');
            this.flashes.addSuccess('Successfully Saved!');
          })
          .catch(() => {
            this.flashes.addError('Oh No! There was an error.');
          });
      } else {
        this.showValidations = true;
      }
    });
  }

  @action
  cancelNewGroup() {
    this.newGroup.rollbackAttributes();
    this.newGroup = null;
  }

  @action
  selectGroupForDelete(group) {
    this.deletingGroup = group;
  }

  @action
  clearDeletingGroup() {
    this.deletingGroup = null;
  }

  @action
  confirmDeletingGroup(group) {
    group
      .destroyRecord()
      .then(() => {
        this.clearDeletingGroup();
        this.flashes.addSuccess('Successfully Removed.');
      })
      .catch(() => {
        this.flashes.addError('Oh No! There was an error.');
      });
  }
}
