import { readOnly, sort, or, not } from '@ember/object/computed';
import Component from '@ember/component';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),
  session: service(),

  currentUserId: readOnly('session.currentUser.id'),
  notes: [],
  noteMode: null,
  expandNotes: true,

  didReceieveAttrs() {
    this.fetchNotes();
  },

  fetchNotes() {
    this.store
      .query('inquiryNote', { inquiry_id: get(this, 'inquiry.id') })
      .then(notes => {
        set(this, 'notes', notes);
      });
  },

  sortedNotes: sort('notes.[]', function(a, b) {
    if (a.get('updatedAt') > b.get('updatedAt')) {
      return -1;
    } else if (a.get('updatedAt') < b.get('updatedAt')) {
      return 1;
    }

    return 0;
  }),

  noteText: null,
  requiresFollowUp: false,

  isEditable: false,

  isEditing: or('isEditable', 'isEditingNote'),

  isEditingDisabled: not('isEditing'),

  resetText() {
    set(this, 'noteText', '');
    set(this, 'requiresFollowUp', false);
  },

  saveNote(inquiry) {
    const currentUserId = this.currentUserId;
    const noteText = this.noteText;

    this.store.findRecord('user', currentUserId).then(currentUser => {
      const newNote = this.store.createRecord('inquiryNote', {
        content: noteText,
        user: currentUser,
        inquiry
      });

      newNote.save().then(() => {
        this.resetText();
        this.fetchNotes();
      });
    });
  },

  actions: {
    addNote() {
      const inquiry = this.inquiry;
      if (!get(inquiry, 'id')) {
        inquiry.save().then(inquiry => {
          this.saveNote(inquiry);
        });
      } else {
        this.saveNote(inquiry);
      }
    },

    toggleEdit(id) {
      const notes = this.notes;

      const note = notes.find(x => x.id === id);
      set(this, 'noteText', get(note, 'content'));

      if (this.isEditing) {
        this.attrs.saveInquiry();
      }

      if (!this.isEditable) {
        this.toggleProperty('isEditingNote');
      }
    },

    toggleExpand() {
      this.fetchNotes();
      this.toggleProperty('expandNotes');
    }
  }
});
