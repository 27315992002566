import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class PayNowPaidRoute extends Route {
  @service api;

  layout = 'fullscreen';
  isFullscreenActive = true;

  async model(params) {
    const { parsedJson } = await this.api.json.get(`v2/pay_now?jwt=${params.jwt}`);

    return parsedJson.data.attributes;
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    controller.set('successType', transition.to.params.success_type);
  }
}
