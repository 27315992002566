import Model, { belongsTo, attr } from '@ember-data/model';

export default class DispositionModel extends Model {
  @attr('string') block;
  @attr('string') burialPermitNumber;
  @attr('string') crypt;
  @attr('string') dispositionMethod;
  @attr('string') lot;
  @attr('string') mausoleum;
  @attr('string') monumentNotes;
  @attr('string') niche;
  @attr('string') notes;
  @attr('string') plot;
  @attr('string') section;
  @attr('string') tier;

  /** Cremation attributes */
  @attr('boolean') crematedRemainsPresentForService;
  @attr('boolean') isGraveUnknown;
  @attr('boolean') isHeadstoneDesired;
  @attr('moment-date') remainsBackByDate;
  @attr('moment-date') remainsPickupDate;
  @attr('moment-date') dispositionDate;
  @attr('moment-date') disintermentDate;
  @attr('moment-date') cremationDate;
  @attr('string') cremationTime;
  @attr('string') discNumber;
  @attr('boolean') cremainsMailed;
  @attr('string') containerDescription;
  @attr('string') secondaryContainerDescription;
  @attr('string') cremationPermitNumber;
  @attr('string') cremationTagNumber;

  @belongsTo('kase', { async: true }) kase;
  @belongsTo('person') graveOwner;

  /** FOR THE FOLLOWING TWO ATTRIBUTES:
   * The disposition page uses the crakn-places-selector typeahead.
   * Typeaheads don't like async: true. */
  @belongsTo('place', { async: false }) place;
  @belongsTo('place', { async: false }) cremationPlace;
  @belongsTo('user', { async: true }) cremationDirector;
}
