import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import SelectBox from './select-box';

export default SelectBox.extend({
  layoutName: 'components/form/select-box',

  vehicle: service(),

  placeholder: 'All Vehicles',

  vehicleList: 'active',

  options: computed('vehicleList', {
    get() {
      return get(this, `vehicle.${this.vehicleList}`);
    }
  })
});
