import Model, { attr } from '@ember-data/model';

export default class V2AddressModel extends Model {
  @attr('string') name;
  @attr('string') latitude;
  @attr('string') line1;
  @attr('string') line2;
  @attr('string') longitude;
  @attr('string') city;
  @attr('string') state;
  @attr('string') zipCode;
  @attr('string') county;
  @attr('string', { defaultValue: 'US' }) country;

  get firstLineAddress() {
    return [this.line1, this.line2].filter(Boolean).join(', ');
  }

  get secondLineAddress() {
    if (!this.city && !this.state && !this.zipCode) {
      return null;
    }

    const secondLine = [this.state, this.zipCode].filter(Boolean).join(' ');

    if (this.city && secondLine) {
      return `${this.city}, ${secondLine}`;
    } else {
      return this.city || secondLine;
    }
  }

  get singleLineAddress() {
    if (!this.line1 && !this.line2 && !this.city && !this.state && !this.zipCode) {
      return null;
    }

    return [this.line1, this.line2, this.city, this.state, this.zipCode]
      .filter(Boolean)
      .join('+');
  }
}
