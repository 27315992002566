import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AuthenticatedAdminDataEventNamesRoute extends Route {
  queryParams = {
    page: {
      refreshModel: true
    }
  };

  model(params) {
    return this.store.query('event-name', {
      archived: false,
      page: params.page,
      per_page: 10
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
