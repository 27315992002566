import { inject as service } from '@ember/service';
import { set, get } from '@ember/object';
import RSVP from 'rsvp';
import { run } from '@ember/runloop';
import Route from '@ember/routing/route';

export default Route.extend({
  session: service(),

  model() {
    const store = this.store;
    return RSVP.hash({
      allAccounts: store.query('account', { per_page: 5000 }),
      newAccount: store.createRecord('account'),
      clientSetting: store.findRecord(
        'clientSetting',
        get(this, 'session.currentClient.id')
      )
    });
  },

  setupController(controller, models) {
    this._super(...arguments);
    controller.setProperties(models);
    const isChildRouteSpecified = !(
      window.location.hash === '#/admin/accounting'
    );

    if (!isChildRouteSpecified) {
      if (get(this, 'session.currentClient.can_view_quickbooks')) {
        this.transitionTo('authenticated.admin.manage.accounting.account-basic');
      } else {
        this.transitionTo('authenticated.admin.manage.accounting.payments');
      }
    }
  },

  handleSaveNewSuccess(account) {
    this.send('flashSuccess', 'Account successfully saved!');
    set(this, 'currentModel.newAccount', this.store.createRecord('account'));
    get(this, 'currentModel.allAccounts').pushObject(account);
  },

  handleSaveClientSettingSuccess(clientSetting) {
    this.send('flashSuccess', 'Accounts successfully saved!');
    // Need to clear out any records that were newly created to avoid duplicates
    clientSetting
      .get('paymentTypes')
      .filterBy('id', null)
      .invoke('deleteRecord');
    clientSetting
      .get('adjustmentTypes')
      .filterBy('id', null)
      .invoke('deleteRecord');
  },

  handleSaveSuccess() {
    this.send('flashSuccess', 'Account successfully saved!');
  },

  handleRemoveSuccess() {
    this.send('flashSuccess', 'Account successfully removed.');
  },

  handleError() {
    this.send('flashError', 'Sorry, something went wrong. Please try again.');
  },

  actions: {
    saveAccount(account) {
      return account
        .save()
        .then(
          run.bind(this, 'handleSaveSuccess'),
          run.bind(this, 'handleError')
        );
    },

    removeAccount(account) {
      return account
        .destroyRecord()
        .then(
          run.bind(this, 'handleRemoveSuccess'),
          run.bind(this, 'handleError')
        );
    },

    saveNewAccount(newAccount) {
      return newAccount
        .save()
        .then(
          run.bind(this, 'handleSaveNewSuccess'),
          run.bind(this, 'handleError')
        );
    },

    saveClientSetting(clientSetting) {
      clientSetting
        .save()
        .then(
          run.bind(this, 'handleSaveClientSettingSuccess'),
          run.bind(this, 'handleError')
        );
    }
  }
});
