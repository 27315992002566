import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { jsonExtensions } from 'crakn/utils/data-helpers';

const fileNames = [
  'locations',
  'staff',
  'addresses',
  'places',
  'rooms',
  'people',
  'kases',
  'memorial_contributions',
  'dispositions',
  'kase_staff_members',
  'contracts',
  'obituaries',
  'events',
  'veteran_info',
  'insurance_policies',
  'trusts',
  'documents'
]

export default class AuthenticatedCraknMigrationsIndexController extends Controller {
  @service api;
  @service flashes;
  @service intl;
  @service session;

  page = 1;
  per_page = 10;
  queryParams = ['page', 'per_page', 'query'];

  get startedImportMigrations() {
    return this.model.filter((ii) => {
      return ii.data.status !== 'created';
    });
  }

  get nonStartedImportMigrations() {
    return this.model.filter((ii) => {
      return ii.data.status == 'created';
    });
  }
  
  get jsonExtensions() {
    return jsonExtensions;
  }

  @action
  restartMigration(migration) {
   migration.data.status = 'restart';

   migration.save()
   .catch(() =>
     this.flashes.addError(this.intl.t('components.g.migrations.new-migration.messages.restartMigrationFailed'))
   );
  }

  @action
  async addMigration() {
    const integrationService = await this.deathSpecIntegrationService();
    const fileData = this.setupFileData();

    this.store
      .createRecord('v2/integrationImport', {
        uuid: null,
        integrationService: integrationService,
        serviceName: null,
        data: {
          name: null,
          status: 'created',
          auto_post_contracts: true,
          auto_post_cutoff_date: null,
          migration_params: null,
          file_data: fileData,
          location_mappings: null,
          notification_user: this.session.currentUser.id,
          import_type: 'Data Only'
        }
      })
      .save()
      .then(() => {
        this.send('refreshModel');
      })
      .catch(() =>
        this.flashes.addError('Error occurred creating migration')
      );
  }

  async deathSpecIntegrationService() {
    let integrationService = await this.store.query('v2/integrationService', {
      name: 'death_spec',
      category: 'data_migration'
    });

    if (integrationService && integrationService.length > 0) {
      return await integrationService.get('firstObject');
    } else {
      integrationService = this.store.createRecord('v2/integrationService', {
        name: 'death_spec',
        category: 'data_migration'
      });
      await integrationService.save();
      return integrationService;
    }
  }

  setupFileData() {
    const fileData = {};

    fileNames.forEach(fileName => {
      fileData[fileName] = {
        step: '',
        status: 'pending',
        info: [],
        errors: []
      };
    });

    return fileData;
  }
}
