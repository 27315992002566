import { capitalize } from '@ember/string';
import { isString } from 'lodash-es/lang';
import { isEmpty } from '@ember/utils';

export const maxSize = 20000000;

export function relativeSize(size) {
  if (size > 1000000) {
    size = `${Math.round((size / 1000000) * 100) / 100} MB`;
  } else {
    size = `${Math.round(size / 1000)} KB`;
  }

  return size;
}

export function sanitizeDocumentName(name) {
  let sanitizedName = name.substr(0, name.lastIndexOf('.'));

  sanitizedName = capitalize(sanitizedName.replace(/[_-]/g, ' ')).trim();

  return isEmpty(sanitizedName) ? 'Unknown' : sanitizedName;
}

export const blockedMediaTypes = [
  'application/x-msdownload',
  'application/x-msdos-program',
  'application/x-msdos-windows',
  'application/x-download',
  'application/bat',
  'application/x-bat',
  'application/com',
  'application/x-com',
  'application/exe',
  'application/x-exe',
  'application/x-winexe',
  'application/x-winhlp',
  'application/x-winhelp',
  'application/x-javascript',
  'application/hta',
  'application/x-ms-shortcut',
  'application/octet-stream',
  'text/javascript',
  'text/scriptlet'
];

export const imageMediaTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif'
];

export const blockedExtensions = ['exe', 'rb', 'js', 'bat', 'cmd', 'app'];

export const documentExtentions = [
  'txt',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'odt',
  'ppt',
  'pptx',
  'pps',
  'ppsx',
  'csv'
];

export const iifExtensions = ['iif'];

export const jsonExtensions = ['json', 'zip'];

export const imageExtensions = ['jpg', 'jpeg', 'gif', 'png'];

export const videoExtensions = ['mp4'];

export const genders = ['Unknown', 'Male', 'Female'];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const emailFormat = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/i;

export function formatFloat(
  price = 0,
  prependCurrency = false,
  allowNegative = true,
  decimalPlaces = 2,
  showCommas = true
) {
  let dollars;

  if (price === null) {
    return price;
  }

  const isNegative = price < 0;

  // Strip non numerical characters
  price = price.toString().replace(/[^\d.-]/g, '');

  // Limit the number to the set decimal places
  price = parseFloat(price).toFixed(decimalPlaces);

  // Split price apart
  price = String(price).split('.');
  dollars = parseInt(price[0]);
  const cents = price[1];

  // Determine if number is negative
  if (dollars < 0) {
    dollars = Math.abs(dollars);
  }

  if (showCommas) {
    // Add commas to dollars
    dollars = dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // Put price back together
  price = `${dollars}.${cents}`;

  return (
    (allowNegative && isNegative ? '-' : '') +
    (prependCurrency ? '$' : '') +
    price
  );
}

export function cleanPrice(price, decimalPlaces = 2) {
  if (price === null || Number.isNaN(price) || price === undefined) {
    return null;
  }
  if (isString(price)) {
    price = price.replace(/[^0-9$.-]/g, '');
  }

  price = parseFloat(price);

  const decimalPow = Math.pow(10, decimalPlaces);
  const priceFixed = +(decimalPlaces ? price * decimalPow : price).toFixed(8);
  const priceWhole = Math.floor(priceFixed);
  const priceDecimal = priceFixed - priceWhole;
  const exponential = 1e-8;
  const priceRounded =
    priceDecimal > 0.5 - exponential && priceDecimal < 0.5 + exponential
      ? priceWhole % 2 === 0
        ? priceWhole
        : priceWhole + 1
      : Math.round(priceFixed);

  return decimalPlaces ? priceRounded / decimalPow : priceRounded;
}

export function isValidPhone(phone) {
  phone = cleanPhone(phone);

  if (!phone) {
    return true;
  }

  // Remove preceding `1` if present in an 11 digit number
  if (phone.length === 11 && phone[0] === 1) {
    phone = phone.shift();
  }

  return phone.length === 7 || phone.length === 10;
}

export function cleanPhone(phone) {
  return phone ? phone.replace(/\D/g, '') : null;
}

export function formatPhone(phone) {
  let reg, subst;

  if (!phone) {
    return;
  }

  phone = cleanPhone(phone);

  if (phone.length > 7) {
    reg = /\(?(\d{3})\)?(\d{3})?(\d{4})/g;
    subst = '($1) $2-$3';
  } else {
    reg = /\(?(\d{3})?(\d{4})/g;
    subst = '$1-$2';
  }

  return phone.replace(reg, subst);
}

export function toCamelCase(value) {
  const isObject = (item) => {return item && typeof item === 'object' ? true : false;}

  if (Array.isArray(value)) {
    return value.map(toCamelCase);
  }

  if (!isObject(value)) {
    return value;
  }

  const obj = {};

  Object.keys(value).forEach(key => {
    obj[key.camelize()] = isObject(value[key]) ? toCamelCase(value[key]) : value[key];
  });

  return obj;
}
