import Model, { belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: validator('presence', true),
  'chartOfAccounts.id': validator('presence', true),
});

export default class GeneralPriceListModel extends Model.extend(Validations) {
  @attr('moment-datetime') archivedAt;
  @attr('moment-date') casketPriceListDate;
  @attr('moment-date') generalPriceListDate;
  @attr('moment-datetime') outerBurialPriceListDate;
  @attr('moment-datetime') updatedAt;
  
  @attr('string') name;

  @belongsTo('chart-of-accounts', { async: true }) chartOfAccounts;
}
