import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class KasesManageEditDispositionRoute extends Route {
  layout = 'custom-aside';

  model() {
    const kase = this.modelFor('authenticated.kases.manage');

    return this.store
      .queryRecord('disposition', { kase_id: kase.get('id') })
      .then(result => {
        return result || this.store.createRecord('disposition', { kase });
      });
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('kase', this.modelFor('authenticated.kases.manage'));
  }

  @action
  willTransition() {
    this.currentModel.save();
  }
}
