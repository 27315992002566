import Service, { inject as service } from '@ember/service';

export default class V2StaffService extends Service {
  @service store;
  
  allStaff = null;
  activeStaff = null;
  notifiableStaff = null;

  get all() {
    if (this.allStaff === null) {
      this.allStaff = this.store.query('v2/user', { per_page: 1000 });
    }

    return this.allStaff;
  }

  unloadAll() {
    this.allStaff = null;
  }
}
