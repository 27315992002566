import Controller from '@ember/controller';
import { computed, get } from '@ember/object';
import moment from 'moment';

export default Controller.extend({
  formattedCFSExportedAt: computed('cfsExport.updatedAt', function() {
    return moment(get(this, 'cfsExport.updatedAt')).format('LLL');
  }),

  invalidDeathDate: computed('model.deceased.profileDeathDate', function() {
    return get(this, 'model.kase.deceased.profileDeathDate') > moment();
  }),

  websiteNameWarning: computed('websiteName', function() {
    const websiteName = this.websiteName;
    if (websiteName === null) {
      return false;
    } else {
      const index = websiteName.indexOf(' ');
      const text = websiteName.substr(index + 1);
      return text.length > 0 && index > 1 ? true : false;
    }
  })
});
