import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2OnlinePlannerProductModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('price') price;
  @attr('string') imageUrl;
  @attr('moment-datetime') archivedAt;
  @belongsTo('v2/product') product;
}
