import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'crakn/config/environment';

export default class AuthenticatedCraknFormsEditController extends Controller {
  @service api;
  @service flashes;
  @service router;
  @service store;

  @tracked filterClient = null;
  @tracked showDialog = false;

  get formNameMessage() {
    if (this.model && this.model.name) {
      return null;
    } else {
      return 'This field can\'t be blank';
    }
  }

  get blankFormLink() {
    return `${config.host}/api/blank_forms/${this.model.id}/download`;
  }

  get testFormLink() {
    return `${config.host}/api/blank_forms/${this.model.id}/download_test`;
  }

  putNewForm() {
    const formData = new FormData();
    formData.append('blank_form[name]', this.model.name);
    formData.append('blank_form[searchable]', this.model.searchable);
    formData.append('blank_form[kase_contact_dropdown]',this.model.kaseContactDropdown);
    formData.append('blank_form[event_dropdown]', this.model.eventDropdown);
    formData.append('blank_form[location_dropdown]', this.model.locationDropdown);
    formData.append('blank_form[inquiry_dropdown]', this.model.inquiryDropdown);

    if (this.formFile) {
      formData.append('form_file', this.formFile);
    }

    const that = this;

    this.api
        .put(
          `blank_forms/${that.model.id}`,
          {
            body: formData
          }
        )
        .then(async response => {
          const json = await response.json();

          if (response.ok) {
            that.flashes.addSuccess(
              'Successfully created form.'
            );
            that.router.transitionTo('authenticated.crakn.manage.forms');
          } else {
            let msg;
            if (json && json.errors) {
              msg = json.errors.join(', ');
            } else {
              msg = 'An error occurred.';
            }

            that.flashes.addError(
              msg
            );
          }
        });
  }


  validate() {
    if (this.formNameMessage) {
      return false;
    } else {
      return true;
    }
  }

  @action
  updateBlankForm(e) {
    e.preventDefault();

    const isValid = this.validate();

    if (isValid) {
      this.showValidation = false;
      this.putNewForm();
    } else {
      this.showValidation = true;
    }
  }

  @action
  typeaheadSearch(value) {
    return this.store
                .query('client', {
                  can_view_form_in_tfe: 'false',
                  query: value
                })
  }

  @action
  selectClient(client) {
    if (client === undefined) {
      return;
    }

    const clientId = client.id;
    const formId = this.model.id;

    const that = this;

    this.api.json
        .post(
          `blank_forms/${formId}/add_form_to_client`,
          {
            body: { client_id: clientId }
          }
        )
        .then(response => {
          if (response.ok) {
            that.flashes.addSuccess(
              'Successfully added form to client.'
            );
            that.send('refreshModel');
          } else {
            let msg;
            if (response.parsedJson && response.parsedJson.errors) {
              msg = response.parsedJson.errors.join(', ');
            } else {
              msg = 'An error occurred.';
            }

            that.flashes.addError(
              msg
            );
          }
        });
  }

  @action
  removeClient(clientName) {
    const formId = this.model.id;
    const that = this;

    this.api.json
        .delete(
          `blank_forms/${formId}/remove_client_from_form`,
          {
            body: { client_name: clientName }
          }
        )
        .then(response => {
          if (response.ok) {
            that.flashes.addSuccess(
              'Successfully removed form from client.'
            );
            that.showDialog = false;
            that.send('refreshModel');
          } else {
            let msg;
            if (response.parsedJson && response.parsedJson.errors) {
              msg = response.parsedJson.errors.join(', ');
            } else {
              msg = 'An error occurred.';
            }

            that.flashes.addError(
              msg
            );
          }
        });
  }
}
