import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Autocomplete::Base\n    {{! template-lint-disable attribute-indentation }}\n    @placeholder={{@placeholder}}\n    @debounce={{this.debounce}}\n    @value={{this.value}}\n    @disabled={{@disabled}}\n    @icon={{@icon}}\n    @results={{this.results}}\n    @onSearch={{this.search}}\n    @onSelect={{this.select}}\n    @onInput={{this.input}}\n    @onIconClick={{@onIconClick}}\n    @onFocusOut={{this.focusOut}}\n    @onDirtyFocusOut={{@onDirtyFocusOut}}\n    as |item|>\n\n  {{#if (has-block)}}\n    {{yield item}}\n  {{else}}\n    {{item}}\n  {{/if}}\n\n</G::Crakn::Autocomplete::Base>\n", {"contents":"<G::Crakn::Autocomplete::Base\n    {{! template-lint-disable attribute-indentation }}\n    @placeholder={{@placeholder}}\n    @debounce={{this.debounce}}\n    @value={{this.value}}\n    @disabled={{@disabled}}\n    @icon={{@icon}}\n    @results={{this.results}}\n    @onSearch={{this.search}}\n    @onSelect={{this.select}}\n    @onInput={{this.input}}\n    @onIconClick={{@onIconClick}}\n    @onFocusOut={{this.focusOut}}\n    @onDirtyFocusOut={{@onDirtyFocusOut}}\n    as |item|>\n\n  {{#if (has-block)}}\n    {{yield item}}\n  {{else}}\n    {{item}}\n  {{/if}}\n\n</G::Crakn::Autocomplete::Base>\n","moduleName":"crakn/components/g/crakn/autocomplete/static.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/autocomplete/static.hbs"}});
import AutocompleteDynamic from './dynamic'
import { action } from '@ember/object'

/**
 * An autocomplete input that draws from a static array of strings
 * 
 * ## Arguments
 * - **placeholder** - *optional* the label for the autocomplete
 * - **value** - *optional* *default empty* the input value
 * - **disabled** - *optional* *default false* boolean for disabling the input
 * - **list** - the list of items to be searched. only used at construction
 * - **count** - *optional* *default: 5* the number of results to display in the dropdown
 * - **icon** - *optional* the icon to display in the right of the autocoomplete.  will not display
 *              without args.onIconClick
 * - **debounce** - *optional* *default 100* the delay in milliseconds between finish typing and the 
 *                  search function occuring
 * - **onSelect** - the function to run when an item in the dropdown is selected.  If this 
 * 									function returns a value, it will be assigned to this.value
 * - **onInput** - *optional* the function to run when the input is typed into. if this is not provided 
 * 									the autocomplete is considered strict and will revert any value that that wasn't 
 * 									click on the in dropdown back to the last clicked value or the original value
 * - **onIconClick** - *optional* the function to run when the icon is clicked.  will not work without
 *                    args.icon
 * - **onDirtyFocusOut** - *optional* the function to run when the input has been interacted with 
 * 										(meaning the autocomplete is open), and the user clicked outside the provided 
 * 										dropdown
 * 
 * ## Useage
 * 
 * ### Non-Strict Mode
 * Anything typed into the input will be sent up through the onInput function, which 
 * should ultimately save the value
 * 
 * ```handlebars
 * <G::Crakn::Autocomplete::Static
 * 		 @placeholder="Title"
 *     @value={{model.title}}
 *     @list={{['Mr', 'Mrs.', 'Ms.', 'Miss', 'Bhagwan']}}
 * 		 @count={{5}}
 * 		 @onSelect={{set model "title"}}
 * 		 @onInput={{set model "title"}}
 * 		 @onDirtyFocusOut={{this.toggleCreateNew}} />
 * 
 * ```
 * 
 * ### Strict Mode
 * Anything typed into the input will be ignored.  Only values selected in the dropdown will be 
 * considered valid.  Navigating away from the input with an invalid value will revert the value to the 
 * last valid value or the original value
 * 
 * ```handlebars
 * <G::Crakn::Autocomplete::Static
 * 		 @placeholder="Title"
 *     @value={{model.title}}
 *     @list={{['Mr', 'Mrs.', 'Ms.', 'Miss', 'Bhagwan']}}
 * 		 @count={{5}}
 * 		 @onSelect={{set model "title"}}
 * 		 @onDirtyFocusOut={{this.toggleCreateNew}} />
 * 
 * ```
 * 
 * @class GCraknAutocompleteStatic
 * @extends GCraknAutocompleteDynamic
 * @public
 */

export default class GCraknAutocompleteStatic extends AutocompleteDynamic {
	/**
	 * The static list of items to search
	 * @type {string[]}
	 */
	list = [];

	/**
	 * The number of results to display
	 * @type {number}
	 */
	get count() {
		return this.args.count || 5;
	}

	/**
	 * Creats an instance of the Component
	 * 
	 * ## Functional Explaination
	 * Caches a local value for args.value rather than directly operating on the argument, making 
	 * this one-way bound
	 * 
	 * Creates an optimized search array from the provided argument args.list which is used in the 
	 * search() function storing the optimized value in [0] and the real value in [1]
	 * 
	 * @param {*} owner - supplied via Emberjs
	 * @param {*} args  - The arguments passed to the component. supplied via Emberjs
	 */	
	constructor(owner, args) {
		super(owner, args);

		this.list = args.list.map((v) => [v.toLowerCase(), v] )
	}

	/**
	 * The function used to search the optimized list.
	 * 
	 * @param {*} val - the search query
	 */
	@action
	search(val) {
		val = val ? val.toLowerCase() : '';
		this.results = this.list.filter((v) => v[0].includes(val))
														.slice(0, this.count)
														.map((v) => v[1])
	}
}
