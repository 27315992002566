import Model, { belongsTo, attr } from '@ember-data/model';

export default class V2FamilyPortalInviteModel extends Model {
  @attr('moment-datetime') createdAt;
  @attr('boolean') expired;
  @attr('moment-datetime') submittedAt;
  @attr('moment-datetime') updatedAt;
  @attr('string') url;
  @attr('string') uuid;

  @belongsTo('v2/integrationImport') integrationImport;
  @belongsTo('v2/kase') kase;
  @belongsTo('v2/person', { async: true }) recipient;
}
