import Controller from '@ember/controller';
import { action } from '@ember/object'
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AdminGeneralPriceListsEditPreneedCategoriesController extends Controller {
  @service flashes;

  @tracked showValidations = false;
  
  changeset = null;
  generalPriceList = null;

  @action
  addPreneedCategory() {
    this.changeset.validate().then(() => {
      if (this.changeset.isValid) {
        this.set('showValidations', false);
        this.changeset.set('generalPriceList', this.generalPriceList);
        this.changeset.save().then(() => {
          this.flashes.addSuccess('Preneed Category successfully created!');
          this.send('refreshModel');
        }).catch(() => {
          this.flashes.addError('Something went wrong.');
        });
      } else {
        this.set('showValidations', true);
        this.flashes.addError('Make sure all of the information is correct and/or inputted.');
      }
    })
  }
}
