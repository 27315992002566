import Model, { belongsTo } from '@ember-data/model';
// eslint-disable-next-line ember/no-mixins
import CustomAction from 'crakn/mixins/custom-action';

/** COMMENT: Still using mixins */
export default class OrganizationLocationModel extends Model.extend(
  CustomAction
) {
  @belongsTo('organization', { async: true }) organization;
  @belongsTo('location', { async: true }) location;
}
