import Serializer from './application';

export default class V2IntegrationExportSerializer extends Serializer {
  normalize(_, hash) {
    const ret = super.normalize(...arguments);
    ret.data.attributes.content = hash.attributes.data;

    return ret;
  }

  serialize() {
    const payload = super.serialize(...arguments);
    delete payload.data.attributes.content;

    return payload;
  }
}
