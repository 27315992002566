import Model, { belongsTo, attr } from '@ember-data/model';

export default class TrustModel extends Model {
  @attr('string') initialInvestment;
  @attr('string') finalDeathBenefit;
  @attr('string') trustCompany;
  @attr('string') trustNumber;
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') establishedAt;

  @belongsTo('preneed', { async: true }) preneed;

  get name() {
    if (this.trustCompany && this.trustNumber) {
      return `${this.trustCompany} - ${this.trustNumber}`;
    } else if (this.trustCompany) {
      return this.trustCompany;
    } else if (this.trustNumber) {
      return this.trustNumber;
    } else {
      return 'Trust';
    }
  }
}
