/* eslint-disable ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AdminGeneralPriceListsEditOverviewController extends Controller {
  @alias('model') generalPriceList;

  @service flashes;
  @service intl;
  @service store;
  @service router;
  @service session;

  @tracked export_uuid;

  get csvLink() {
    return `#/generated_document/${this.export_uuid}`;
  }

  @computed('export_uuid', 'generalPriceList.id')
  get reportLink() {
    const params = new URLSearchParams({
      uuid: this.export_uuid,
      general_price_list_id: this.generalPriceList?.id,

    }).toString();
    return `/report/gpl_export?${params}`;
  }

  @action
  cancel(generalPriceList) {
    generalPriceList.rollbackAttributes();
    this.router.transitionTo('authenticated.admin.manage.general-price-lists');
  }

  @action
  save(generalPriceList) {
    generalPriceList.validate().then(({ validations }) => {
      if (validations.isValid) {
        set(this, 'showValidation', false);
        generalPriceList
          .save()
          .then(() => {
            this.flashes.addSuccess(this.intl.t('authenticated.admin.general-price-lists.edit.overview.messages.save.success'));
          })
          .catch(() => {
            this.flashes.addError(this.intl.t('authenticated.admin.general-price-lists.edit.overview.messages.save.error'));
          });
      } else {
        set(this, 'showValidation', true);
      }
    });
  }
}
