import Controller from '@ember/controller';
import moment from 'moment';
import { action } from '@ember/object';
import { get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KasesIntegrationFrontrunnerController extends Controller {
  @service api;
  @service flashes;
  frontRunnerExport = null;
  kase = null;
  validationErrors = [];
  
  get formattedfrontRunnerExportedAt() {
    return moment(get(this, 'frontRunnerExport.updatedAt')).format('LLL');
  }

  get invalidDeathDate() {
    const dod = get(this, 'kase.deceased.profileDeathDate');
    return !dod ? true : dod > moment();
  }

  get showValidations() {
    const errors = [];
    const deceased = this.kase.get('deceased');
    const firstName = deceased.get('firstName');
    const lastName = deceased.get('lastName');

    if (this.nullOrBlank(firstName)) {
      errors.push('Case must have a first name.');
    }

    if (this.nullOrBlank(lastName)) {
      errors.push('Case must have a last name.');
    }

    if (this.invalidDeathDate) {
      errors.push('Case must have a valid date of death.');
    }

    this.set('validationErrors', errors);

    return errors.length > 0;
  }

  nullOrBlank(string) {
    return !string || string === '' ? true : false;
  }

  @action
  export(e) {
    e.target.disabled = true; // avoid double-click issues
    const kaseId = this.kase.id;
    const exportId = this.frontRunnerExport?.get('id') || null;

    this.api.json.put(
      'frontrunner/kases',
      { 
        params: { export_id: exportId },
        body: { id: kaseId }
      }
    ).then(response => {

      if (response.ok) {
        this.flashes.addSuccess('Export Successful!');
        this.send('refreshModel');
      } else {
        this.flashes.addError('Oops, something went wrong! Please try again.');
      }

      e.target.disabled = false; // avoid double-click issues
    });
  }

}
