import Model, { belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  url: [
    validator('presence', {
      presence: true,
      message: 'URL cannot be blank',
    }),
    validator('format', {
      regex: '^https?://(.*)',
      message: 'URL must start with http:// or https://',
    }),
  ],
});

export default class V2KaseLinkModel extends Model.extend(Validations) {
  @attr('string') description;
  @attr('string') name;
  @attr('string') url;

  @belongsTo('v2/user') createdByUser;
  @belongsTo('v2/kase') kase;
}
