import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class KasesEditDeceasedRoute extends Route {
  layout = 'custom-aside';

  model() {
    const kase = this.modelFor('authenticated.kases.manage');
    return kase.get('deceased').then(result => {
      return result || this.store.createRecord('deceased', { kase });
    });
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('kase', this.modelFor('authenticated.kases.manage'));
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        saveToPlaces: false,
        searchedForPlaceOfDeath: null
      });
    }
  }

  @action
  willTransition() {
    const kase = this.modelFor('authenticated.kases.manage');
    kase
      .get('deceased')
      .then(result => {
        if (result) {
          return new Promise(function (resolve) {
            resolve(result);
          });
        } else {
          return this.store.createRecord('deceased', { kase });
        }
      })
      .then(result => {
        result.save().then(() => {
          kase.save().then(() => {
            this.controller.set('model.personGovernmentIdentifier', null);
          });
        });
      });
  }
}
