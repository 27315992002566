import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminManageWhiteboardController extends Controller {
  @service intl;

  get message() {
    const token = this.get('model.token');

    if (token) {
      const formattedToken = `${token.substr(0, 4)}-${token.substr(4, 8)}`;

      return `${formattedToken}`;
    } else {
      return this.intl.t('authenticated.admin.manage.whiteboard.information.instructions');
    }
  }

  @action
  generatePin() {
    this.store
      .createRecord('authToken')
      .save()
      .then(record => {
        set(this, 'model', record);
      });
  }
}
