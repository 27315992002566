import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking'

export default class LogoutRoute extends Route {
  @service session;
  @service location;
  @service organization;
  @service staff;

  layout = 'fullscreen';
  @tracked isFullscreenActive = true;

  beforeModel() {
    if (window.Intercom) {
      window.Intercom('shutdown');
    }

    // Clear out the store
    try {
      this.store.unloadAll();
    } catch(e) {
      this.store.unloadAll();
    }

    // Clear out services
    this.location.unloadAll();
    this.organization.unloadAll();
    this.staff.unloadAll();

    if (!this.session.isAuthenticated) {
      this.transitionTo('login');
    } else {
      this.session.invalidate().then(() => {
        location.reload();
      });
    }
  }
}
