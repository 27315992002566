import { sort, reads, not } from '@ember/object/computed';
import Controller from '@ember/controller';
import { set, computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'newRefund.name': validator('presence', true),
  'newRefund.amount': [
    validator('presence', {
      presence: true
    }),
    validator('number', {
      allowString: true,
      gt: 0,
      message: 'Must be greater than zero',
      positive: true
    })
  ],
  'newRefund.collectedAt': validator('presence', {
    presence: true,
    message:'Date can\'t be empty or invalid'
  }),
  'newRefund.collectedBy': validator('presence', true),
  'newRefund.refundTo': validator('presence', {
    presence: true,
    disabled: not('model.isRefundToNewPerson')
  }),
  'newRefund.person': validator('presence', {
    presence: true,
    disabled: reads('model.isRefundToNewPerson')
  })
});

export default Controller.extend(Validations, {
  flashes: service(),
  intl: service(),
  session: service(),
  staff: service(),

  isRefundToNewPerson: false,
  kase: null,
  selections: null,
  newRefund: null,
  disabled: false,
  selection_id: null,

  selection: computed('selection_id', 'selections', function() {
    return this.selections.findBy('id', this.selection_id);
  }),

  sortedRefunds: sort('selection.refunds', function(a, b) {
    const collectedDiff = b.get('collectedAt') - a.get('collectedAt');
    const enteredDiff = b.get('createdAt') - a.get('createdAt');
    return collectedDiff || enteredDiff;
  }),

  showValidation: false,

  get contractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice ?
    `Choose an Invoice or Add-on` : `Choose a Contract or Add-on`;
  },

  get unpostedLabel() {
    if (!this.selection.isContract) {
      return "Add-on";
    }
    return this.session.currentClient.relabel_contract_to_invoice ?
    `Invoice` : `Contract`;
  },

  get refundLockLabel() {
    return `Refunds can only be created or removed when
    ${this.session.currentClient.relabel_contract_to_invoice ? 'an invoice' : 'a contract'}
     or add-on is posted`;
  },

  attachSelection() {
    this.set('newRefund.selection', this.selection);
  },

  actions: {
    savePerson() {
      this.newRefund.get('person').content.save();
      this.newRefund.refundTo = this.newRefund.get('person.name') || '';
    },

    setRefundPerson(person) {
      this.newRefund.set('person', person);
      this.newRefund.refundTo = person?.get('name') || '';
    },

    toggleRefundTo(value) {
      if(value) {
        this.newRefund.set('refundTo', undefined);

        if (this.get('newRefund.person')) {
          this.newRefund.set('person', undefined);
        }
      } else {
        const person = this.selection.purchaser;

        if(person) {
          this.send("setRefundPerson", person);
        }
      }

      this.set('isRefundToNewPerson', !this.isRefundToNewPerson);
    },

    setCollectedBy(value) {
      const staffMember = get(this, 'staff.active').findBy('id', value);
      set(this, 'newRefund.collectedBy', staffMember);
    },

    async addRefund() {
      if (dateInCoaLockedPeriod(this.selection, this.session, this.newRefund.get('collectedAt'))) {
        this.flashes.addError(this.intl.t('authenticated.kases.manage.financials.refunds.errors.postingPeriodAdd'));
        return;
      }

      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          this.attachSelection();
          set(this, 'showValidation', false);
          this.send('saveNewRefund', this.newRefund);
          this.set('isRefundToNewPerson', undefined);
        } else {
          set(this, 'showValidation', true);
        }
      });
    }
  }
});
