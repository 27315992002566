import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  router: service(),
  session: service(),

  queryParams: {
    page: {
      refreshModel: true
    },
    query: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    }
  },

  beforeModel() {
    if (this.session.currentUser.isSuperuser || this.session.currentClient.can_view_financial_config_tax_items) {
      return true;
    }

    // Unauthorized users are redirected to the dashboard
    this.router.transitionTo('authenticated.dashboard');
  },

  model(params) {
    const coa = this.modelFor('authenticated.admin.financial-configurations.edit');
    return this.store.query('taxItem', {
      chart_of_accounts_id: coa.get('id'),
      query: params.query,
      per_page: params.per_page,
      page: params.page
    });
  },

  setupController(controller) {
    this._super(...arguments);
    const coa = this.modelFor('authenticated.admin.financial-configurations.edit');
    controller.set('chartOfAccounts', coa);
  },

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = ![
        'authenticated.admin.financial-configurations.index',
        'authenticated.admin.financial-configurations.edit'
      ].includes(transition.targetName);

      if (resetParams) {
        controller.setProperties({
          page: 1,
          query: null
        });
      }
    }
  }
});
