import Controller from '@ember/controller';
import { action, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'crakn/config/environment';
import { tracked } from '@glimmer/tracking';
import { v4 as uuidv4 } from 'uuid';

export default class AuthenticatedAdminFinancialConfigurationsEditSyncController extends Controller {
  @service api;
  @service flashes;
  @tracked export_uuid = uuidv4();

  chartOfAccounts = null; // Set up in the route.
  hostUrl = `${config.host}`;
  saving = false;

  get headers() {
    const headers = {};
    if (localStorage.getItem('sso_token') === null) {
      headers['X-CSRF-Token'] = this.session.data.authenticated.token;
    } else {
      headers['Authorization'] = localStorage.getItem('sso_token');
    }
    return headers;
  }

  @computed('export_uuid', 'chartOfAccounts.id')
  get documentLink() {
    const params = new URLSearchParams({
      uuid: this.export_uuid
    }).toString();
    return `${this.hostUrl}/quick_books_web_connector/config/${this.chartOfAccounts.id}?${params}`;
  }

  get downloadLink() {
    return `#/generated_document/${this.export_uuid}`;
  }

  @action
  generateDownload() {
    fetch(this.documentLink, { method: 'GET', credentials: 'include', headers: this.headers})
    .then((response) => {
      if (!response.ok) {
        this.flashes.addError(
          'Oops, something went wrong! Please try again.'
        );
      }
      this.export_uuid = uuidv4();
    });
  }


  @action
  save() {
    set(this, 'saving', true);
    this.chartOfAccounts.save().then(() => {
      this.flashes.addSuccess('Configuration saved!');
      set(this, 'saving', false);
    });
  }

  @action
  generate() {
    this.store
      .createRecord('programmaticUser', {
        chartOfAccounts: this.chartOfAccounts
      })
      .save();
  }

  @action
  regenerate() {
    const coa = this.chartOfAccounts;
    coa.get('programmaticUser').then(user => {
      user.save();
    });
  }
}
