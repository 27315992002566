import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknMigrationsIndexRoute extends Route {
  @service session;

  queryParams = {
    page: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    type: {
      refreshModel: true
    }
  };

  model(params) {
    return this.store.query('v2/integrationImport', {
      page: params.page,
      per_page: params.per_page,
      type: 'death_spec'
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('model', model);
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
