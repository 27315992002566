import Route from '@ember/routing/route';

export default class KasesEditPreneedRoute extends Route {
  layout = 'custom-aside';

  model() {
    const kase = this.modelFor('authenticated.kases.manage');
    return this.store
      .queryRecord('preneed', { kase_id: kase.get('id') })
      .then(result => {
        return result || this.store.createRecord('preneed', { kase });
      });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.set('kase', this.modelFor('authenticated.kases.manage'));

    this.store.query('insurance-policy', { preneed_id: model.id, archived: false })
    this.store.query('trust', { preneed_id: model.id, archived: false })
  }
}