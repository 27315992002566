import Serializer from './application';

export default class V2KaseCustomFieldSerializer extends Serializer {
  normalizeResponse(store, _primaryModelClass, payload, id, requestType) {
    if (requestType === 'query' && payload.data.length > 0) {
      const customFieldAttrsJson = {
        data: payload.data.firstObject.attributes['custom-fields'].map(
          (field) => {
            return {
              attributes: { ...field },
              id: field.id,
              type: 'v2/custom-field',
              relationships: {
                kase: {
                  data: {
                    id: payload.data.firstObject.relationships.kase.data.id,
                    type: 'v2/kase'
                  }
                },
                kaseCustomFields: {
                  data: {
                    id: payload.data.firstObject.id,
                    type: 'v2/kase-custom-field'
                  }
                }
              }
            };
          }
        )
      };

      store.push(customFieldAttrsJson);
    }

    const resp = super.normalizeResponse(...arguments);
    return resp;
  }

  serialize(snapshot, _options) {
    const json = super.serialize(...arguments);
    if (snapshot._hasManyRelationships.customFields) {
      json.data.attributes = {
        'custom-fields': snapshot._hasManyRelationships.customFields.map(
          (record) => {
            const newRecord = record._attributes;
            newRecord.id = record.id
            return newRecord;
          }
        )
      };
    }

    return json;
  }
}
