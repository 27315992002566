import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import SearchController from 'crakn/controllers/search';

export default class AdminFinancialConfigurationsEditPostingPeriodController extends SearchController {
  @service flashes;
  @service session;
  @service store;

  queryParams = [
    'query',
    'archived',
    'starts_at',
    'ends_at',
  ]

  @tracked archived = false;
  @tracked ends_at = null;
  @tracked isCreatingNewPP = false;
  @tracked lockedAt = null;
  @tracked selectedPostingPeriodId = null;
  @tracked showDialog = false;
  @tracked starts_at = null;

  @computed('selectedPostingPeriodId')
  get selectedPostingPeriod() {
    return this.store.peekRecord('v2/postingPeriod', this.selectedPostingPeriodId);
  }

  @action
  saveNewPostingPeriod() {
    const newPostingPeriod = this.store.createRecord('v2/posting-period', {
      chartOfAccounts: this.chartOfAccounts,
      lockedAt: this.lockedAt,
    });

    newPostingPeriod
      .save()
      .then(() => {
        this.flashes.addSuccess('Success! The posting period was added successfully.');

        this.toggleIsCreatingNewPP();
        this.lockedAt = null;
        this.send('refreshModel');
      })
      .catch(() => {
        this.flashes.addError('Oops! There was an error when attempting to add the posting period.');
      })
  }

  @action
  setDate(attr, date) {
    this.set(attr, date.toISOString());
  }

  @action
  toggleIsCreatingNewPP() {
    this.isCreatingNewPP = !this.isCreatingNewPP;
  }

  @action
  toggleArchivedAt() {
    if (this.selectedPostingPeriod.archivedAt) {
      this.selectedPostingPeriod.archivedAt = null;
      this.selectedPostingPeriod.save().then(() => {
        this.flashes.addSuccess('Success! The posting period was archived successfully.');
        this.toggleShowDialog();
        this.send('refreshModel');
      });
    } else {
      this.selectedPostingPeriod.destroyRecord().then(() => {
        this.flashes.addSuccess('Success! The posting period was unarchived successfully.');
        this.toggleShowDialog();
        this.send('refreshModel');
      });
    }
  }

  @action
  toggleShowDialog(id = null) {
    this.selectedPostingPeriodId = id;
    this.showDialog = !this.showDialog;
  }

  @action
  clear() {
    this.starts_at = null;
    this.ends_at = null;
    this.archived = false;
    this.lockedAt = null;
  }
}
