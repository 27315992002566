import { v4 as uuidv4 } from 'uuid';
import Model, { belongsTo, attr, hasMany } from '@ember-data/model';
import moment from 'moment';

export default class PaymentModel extends Model {
  @attr('price') amount;
  @attr('price') paymentFee;

  @attr('string') achAccountNumberLastFour;
  @attr('string') achAccountType;
  @attr('string') achRoutingNumber;
  @attr('string') bankName;
  @attr('string') billing;
  @attr('string') cardType;
  @attr('string') ccLastFour;
  @attr('string') checkNumber;
  @attr('string') city;
  @attr('string') coaIntegrationName;
  @attr('string', { defaultValue: 'US' }) country;
  @attr('string') effectiveAmount;
  @attr('string') internalReceiptUuid;
  @attr('string') line1;
  @attr('string') line2;
  @attr('string') name;
  @attr('string') netsuiteId;
  @attr('string') notes;
  @attr('string') payer;
  @attr('string') paymentProcessorStatus;
  @attr('string') source;
  @attr('string') state;
  @attr('string') stripeCheckoutUrl;
  @attr('string') tpsChargeId;
  @attr('string') transactionId;
  @attr('string') transnationalAuthorizationCode;
  @attr('string') transnationalDateString;
  @attr('string') transnationalResponseText;
  @attr('string') transnationalStatus;
  @attr('string') zipCode;

  @attr('moment-datetime', { defaultValue: () => moment() }) collectedAt;
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;
  @attr('moment-datetime', { defaultValue: () => moment() }) exportedAt;
  @attr('moment-datetime') transnationalDateTimestamp;

  @attr('boolean', { defaultValue: false }) loadingPayment; /** Used to display loading-dots in payment/edit template */
  @attr('boolean', { defaultValue: false }) markedPending;
  @attr('boolean') exported;
  @attr('boolean') processorPending;
  @attr('boolean') refundable;

  @belongsTo('v2/generalPayment') generalPayment;
  @belongsTo('insurancePolicy', { async: true }) insurancePolicy;
  @belongsTo('interest', { async: true }) interest;
  @belongsTo('user', { async: true }) collectedBy;
  @belongsTo('user', { async: true }) enteredBy;
  @belongsTo('user', { async: true }) exportedBy;
  @belongsTo('paymentType') paymentType;
  @belongsTo('person', { async: true }) person;
  @belongsTo('selection', { polymorphic: true }) selection;

  @hasMany('refund', { async: true }) refunds;

  get receiptUuid() {
    if (this.internalReceiptUuid) {
      return this.internalReceiptUuid;
    } else {
      this.internalReceiptUuid = uuidv4();
      return this.internalReceiptUuid;
    }
  }
  set receiptUuid(uuid) {
    this.internalReceiptUuid = uuid;
  }
}
