import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AuthenticatedKasesManageEditTransportationRoute extends Route {
  layout = 'custom-aside';

  model() {
    return this.modelFor('authenticated.kases.manage');
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);
    controller.set(
      'transportToLocationId',
      model.get('transportToLocation.id')
    );
  }

  @action
  willTransition() {
    this.currentModel.save();
  }
}
