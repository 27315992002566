import { inject as service } from '@ember/service';
import { computed, set, get } from '@ember/object';
import ListClientData from 'crakn/components/list/client-data';

export default ListClientData.extend({
  session: service(),
  store: service(),
  
  accountsSrc: computed.reads('record'),
  
  generalPriceList: null,

  preneedCategories: computed('generalPriceList.id', function() {
    return this.store.query('v2/preneedCategory', {
      archived: false,
      gpl_id: this.generalPriceList.id
    })
  }),

  allAccounts: computed('generalPriceList.chartOfAccounts.id', function() {
    return this.store.query('account', {
      per_page: 5000,
      chart_of_accounts_id: this.get('generalPriceList.chartOfAccounts.id')
    });
  }),

  actions: {
    setPreneedCategory(id) {
      const preneedCategory = this.store.peekRecord('v2/preneedCategory', id);
      this.record.set('preneedCategory', preneedCategory); 
    },
    /**
     * Save the record
     * @return {Undefined}
     */
    saveRecord() {
      const record = this.record;
      record.save();

      if (this.selectedTagId != null) {
        this.accountsSrc
          .save({
            adapterOptions: { include: 'account,asset-account,cogs-account' }
          });
      }

      this.attrs.saveRecord(get(record, 'id')).then(() => {
        set(this, 'isEditing', false);
        this.send('setAccountsSrc', null);
      });
    },

    /**
     * Revert the record
     * @return {Undefined}
     */
    revertRecord() {
      set(this, 'isEditing', false);
      this.record.rollbackAttributes();
      this.send('setAccountsSrc', null);
    },

    /**
     * Set the record the accounts should be taken from.
     *
     * The record will be either the ProductCategory or a TagProductCategory.
     *
     * @return {Undefined}
     */
    setAccountsSrc(tagId) {
      this.set('selectedTagId', tagId);

      if (!tagId) {
        // Use the ProductCategory accounts when no Tag is selected
        this.set('accountsSrc', this.record);
        return;
      }

      const tag = this.store.peekRecord('v2/tag', tagId);

      // Use a new or existing TagProductCategory when a Tag is selected
      this.store
        .query('v2/tagProductCategory', {
          tag_id: tagId,
          product_category_id: this.record.id,
          include: 'account,asset-account,cogs-account'
        })
        .then((tagProductCategories) => {
          if (tagProductCategories.length == 0) {
            const newTagProductCategory =
              this.store.createRecord('v2/tagProductCategory', {
                tag,
                productCategory: this.record
              });

            this.set('accountsSrc', newTagProductCategory);
          } else {
            this.set('accountsSrc', tagProductCategories.firstObject);
          }
        });
    }
  }
});
