import { bool } from '@ember/object/computed';
import Component from '@ember/component';

export default Component.extend({
  options: null,
  selectedOption: null,
  label: 'Tax Item Name',

  classNames: ['input', 'input--standard'],
  classNameBindings: ['hasSelection:input--has-value', 'styleClass'],

  hasSelection: bool('selectedOption'),

  actions: {
    handleChange(taxItemId) {
      const selectedTaxItem = this.options.findBy('id', taxItemId);
      this.sendAction('onchange', selectedTaxItem);
    }
  }
});
