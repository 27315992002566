import SearchController from 'crakn/controllers/search';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KasesManageEditEnvelopesController extends SearchController {
  @tracked category = null;
  @tracked printableFilterOptions = {};
  @tracked query = null;
  @tracked subcategory = null;

  @service intl;
  @service session;

  queryParams = [
  'category',
  'page',
  'per_page',
  'query',
  'subcategory',
  ];

  get categoryOptions() {
    if (this.printableFilterOptions) {
      return Object.keys(this.printableFilterOptions);
    } else {
      return [];
    }
  }

  get subcategoryOptions() {
    if (this.printableFilterOptions) {
      const subcategories = this.printableFilterOptions[this.category];
      return subcategories?.filter(subcategory => subcategory !== null);
    } else {
      return [];
    }
  }

  @action
  setCategory(value) {
    this.category = value;
    this.subcategory = null;
  }
}
