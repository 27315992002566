import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class UserSettingsSignatureController extends Controller {
    @tracked signaturePad;
    @service flashes;
    @service store;
    @tracked editSignature = false;
    @tracked showCapture = true;

    get showSignaturePad() {
      if (this.model.signature && !this.editSignature) {
        return false;
      } else {
        return true;
      }
    }

    @action
    canUpdateSignature() {
      this.editSignature = true;
      this.showCapture = true;
    }

    @action
    cancel() {
      this.editSignature = false;
      this.showCapture = false;
    }

    @action
    error() {
      this.flashes.addError('An error has occured.');
    }

    @action
    success(response) {
      if (response) {
        this.store.push(response);
        this.flashes.addSuccess('Signature Successfully uploaded!');
        this.cancel();
        this.send('refreshModel');
      }
    }
     
    @action
    save() {
      if (this.model.signature) {
        return new Promise((resolve, reject) => {
          this.store.findRecord('v2/signature', this.model.signature.id).then(existingSignature => {
            existingSignature.save()
                             .then(signature => resolve(signature))
                             .catch(() => reject());
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          this.store.createRecord('v2/signature')
          .save()
          .then(signature => resolve(signature))
          .catch(() => reject());
        });
      }
    }
}
