import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedPlacesEditRoute extends Route {
  @service location;

  model(params) {
    return this.store.findRecord('v2/place', params.place_id);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        model: null,
        redirectPath: '',
        redirectId: '',
      });
    }
  }

  setupController(controller) {
    super.setupController(...arguments);    
    this.location.all.then((locations) => {      
      controller.set('canViewHomeUnderHospice', locations.filterBy('state', 'MI').length > 0)
    });
  }
  
  @action
  refreshModel() {
    this.refresh();
  }
}
