import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default Component.extend({
  session: service(),
  selectionCardOpen: false,
  collectsTax: false,

  get contractText() {
    if (!this.selection.isContract) {
      return "add-on";
    }
    return this.session.currentClient.relabel_contract_to_invoice ? 
    `invoice` : `contract`;
  },

  get contractDateText() {
    if (!this.selection.isContract) {
      return "Sale Date:";
    }
    return this.session.currentClient.relabel_contract_to_invoice ? 
    `Invoice Date:` : `Contract Date:`;
  },

  actions: {
    toggleSelectionCard() {
      this.toggleProperty('selectionCardOpen');
    }
  }
});
