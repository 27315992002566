import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class WebsitesRoute extends Route {
  @service store;

  model() {
    return this.store
      .query('integrationService', {
        name: 44, // tribute-website
        archived: false,
        not_category: 'accounting',
        per_page: 1000
      })
      .then(res => res.toArray());
  }

  setupController(controller, model) {
    controller.set('model', model);
  }
}
