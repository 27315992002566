import Serializer from './application';
import { toCamelCase } from 'crakn/utils/data-helpers';

export default class V2OnlinePlanneConfigSerializer extends Serializer {
  serialize(snapshot, _options) {
    const json = super.serialize(...arguments);
    const changed = toCamelCase(snapshot.record.config);
    json.data.attributes.config = changed;
    return json;
  }

  normalizeResponse(store, _primaryModelClass, payload, id, requestType) {
    const resp = super.normalizeResponse(...arguments);

    if (requestType === 'query' || requestType === 'findAll') {
      payload.data.forEach(planner => {
        planner.attributes.config = toCamelCase(payload?.data?.attributes?.config);
      });
    } else {
      resp.data.attributes.config = toCamelCase(payload?.data?.attributes?.config);
    }

    return resp;
  }
}
