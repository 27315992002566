export default [
  {
    name: 'International Date Line West',
    id: 'International Date Line West'
  },
  {
    name: 'Midway Island',
    id: 'Midway Island'
  },
  {
    name: 'American Samoa',
    id: 'American Samoa'
  },
  {
    name: 'Tijuana',
    id: 'Tijuana'
  },
  {
    name: 'Chihuahua',
    id: 'Chihuahua'
  },
  {
    name: 'Mazatlan',
    id: 'Mazatlan'
  },
  {
    name: 'Saskatchewan',
    id: 'Saskatchewan'
  },
  {
    name: 'Guadalajara',
    id: 'Guadalajara'
  },
  {
    name: 'Mexico City',
    id: 'Mexico City'
  },
  {
    name: 'Monterrey',
    id: 'Monterrey'
  },
  {
    name: 'Central America',
    id: 'Central America'
  },
  {
    name: 'Bogota',
    id: 'Bogota'
  },
  {
    name: 'Lima',
    id: 'Lima'
  },
  {
    name: 'Quito',
    id: 'Quito'
  },
  {
    name: 'Atlantic Time (Canada)',
    id: 'Atlantic Time (Canada)'
  },
  {
    name: 'Caracas',
    id: 'Caracas'
  },
  {
    name: 'La Paz',
    id: 'La Paz'
  },
  {
    name: 'Santiago',
    id: 'Santiago'
  },
  {
    name: 'Newfoundland',
    id: 'Newfoundland'
  },
  {
    name: 'Brasilia',
    id: 'Brasilia'
  },
  {
    name: 'Buenos Aires',
    id: 'Buenos Aires'
  },
  {
    name: 'Montevideo',
    id: 'Montevideo'
  },
  {
    name: 'Georgetown',
    id: 'Georgetown'
  },
  {
    name: 'Greenland',
    id: 'Greenland'
  },
  {
    name: 'Mid-Atlantic',
    id: 'Mid-Atlantic'
  },
  {
    name: 'Azores',
    id: 'Azores'
  },
  {
    name: 'Cape Verde Is.',
    id: 'Cape Verde Is.'
  },
  {
    name: 'Dublin',
    id: 'Dublin'
  },
  {
    name: 'Edinburgh',
    id: 'Edinburgh'
  },
  {
    name: 'Lisbon',
    id: 'Lisbon'
  },
  {
    name: 'London',
    id: 'London'
  },
  {
    name: 'Casablanca',
    id: 'Casablanca'
  },
  {
    name: 'Monrovia',
    id: 'Monrovia'
  },
  {
    name: 'UTC',
    id: 'UTC'
  },
  {
    name: 'Belgrade',
    id: 'Belgrade'
  },
  {
    name: 'Bratislava',
    id: 'Bratislava'
  },
  {
    name: 'Budapest',
    id: 'Budapest'
  },
  {
    name: 'Ljubljana',
    id: 'Ljubljana'
  },
  {
    name: 'Prague',
    id: 'Prague'
  },
  {
    name: 'Sarajevo',
    id: 'Sarajevo'
  },
  {
    name: 'Skopje',
    id: 'Skopje'
  },
  {
    name: 'Warsaw',
    id: 'Warsaw'
  },
  {
    name: 'Zagreb',
    id: 'Zagreb'
  },
  {
    name: 'Brussels',
    id: 'Brussels'
  },
  {
    name: 'Copenhagen',
    id: 'Copenhagen'
  },
  {
    name: 'Madrid',
    id: 'Madrid'
  },
  {
    name: 'Paris',
    id: 'Paris'
  },
  {
    name: 'Amsterdam',
    id: 'Amsterdam'
  },
  {
    name: 'Berlin',
    id: 'Berlin'
  },
  {
    name: 'Bern',
    id: 'Bern'
  },
  {
    name: 'Zurich',
    id: 'Zurich'
  },
  {
    name: 'Rome',
    id: 'Rome'
  },
  {
    name: 'Stockholm',
    id: 'Stockholm'
  },
  {
    name: 'Vienna',
    id: 'Vienna'
  },
  {
    name: 'West Central Africa',
    id: 'West Central Africa'
  },
  {
    name: 'Bucharest',
    id: 'Bucharest'
  },
  {
    name: 'Cairo',
    id: 'Cairo'
  },
  {
    name: 'Helsinki',
    id: 'Helsinki'
  },
  {
    name: 'Kyiv',
    id: 'Kyiv'
  },
  {
    name: 'Riga',
    id: 'Riga'
  },
  {
    name: 'Sofia',
    id: 'Sofia'
  },
  {
    name: 'Tallinn',
    id: 'Tallinn'
  },
  {
    name: 'Vilnius',
    id: 'Vilnius'
  },
  {
    name: 'Athens',
    id: 'Athens'
  },
  {
    name: 'Istanbul',
    id: 'Istanbul'
  },
  {
    name: 'Minsk',
    id: 'Minsk'
  },
  {
    name: 'Jerusalem',
    id: 'Jerusalem'
  },
  {
    name: 'Harare',
    id: 'Harare'
  },
  {
    name: 'Pretoria',
    id: 'Pretoria'
  },
  {
    name: 'Kaliningrad',
    id: 'Kaliningrad'
  },
  {
    name: 'Moscow',
    id: 'Moscow'
  },
  {
    name: 'St. Petersburg',
    id: 'St. Petersburg'
  },
  {
    name: 'Volgograd',
    id: 'Volgograd'
  },
  {
    name: 'Samara',
    id: 'Samara'
  },
  {
    name: 'Kuwait',
    id: 'Kuwait'
  },
  {
    name: 'Riyadh',
    id: 'Riyadh'
  },
  {
    name: 'Nairobi',
    id: 'Nairobi'
  },
  {
    name: 'Baghdad',
    id: 'Baghdad'
  },
  {
    name: 'Tehran',
    id: 'Tehran'
  },
  {
    name: 'Abu Dhabi',
    id: 'Abu Dhabi'
  },
  {
    name: 'Muscat',
    id: 'Muscat'
  },
  {
    name: 'Baku',
    id: 'Baku'
  },
  {
    name: 'Tbilisi',
    id: 'Tbilisi'
  },
  {
    name: 'Yerevan',
    id: 'Yerevan'
  },
  {
    name: 'Kabul',
    id: 'Kabul'
  },
  {
    name: 'Ekaterinburg',
    id: 'Ekaterinburg'
  },
  {
    name: 'Islamabad',
    id: 'Islamabad'
  },
  {
    name: 'Karachi',
    id: 'Karachi'
  },
  {
    name: 'Tashkent',
    id: 'Tashkent'
  },
  {
    name: 'Chennai',
    id: 'Chennai'
  },
  {
    name: 'Kolkata',
    id: 'Kolkata'
  },
  {
    name: 'Mumbai',
    id: 'Mumbai'
  },
  {
    name: 'New Delhi',
    id: 'New Delhi'
  },
  {
    name: 'Kathmandu',
    id: 'Kathmandu'
  },
  {
    name: 'Astana',
    id: 'Astana'
  },
  {
    name: 'Dhaka',
    id: 'Dhaka'
  },
  {
    name: 'Sri Jayawardenepura',
    id: 'Sri Jayawardenepura'
  },
  {
    name: 'Almaty',
    id: 'Almaty'
  },
  {
    name: 'Novosibirsk',
    id: 'Novosibirsk'
  },
  {
    name: 'Rangoon',
    id: 'Rangoon'
  },
  {
    name: 'Bangkok',
    id: 'Bangkok'
  },
  {
    name: 'Hanoi',
    id: 'Hanoi'
  },
  {
    name: 'Jakarta',
    id: 'Jakarta'
  },
  {
    name: 'Krasnoyarsk',
    id: 'Krasnoyarsk'
  },
  {
    name: 'Beijing',
    id: 'Beijing'
  },
  {
    name: 'Chongqing',
    id: 'Chongqing'
  },
  {
    name: 'Hong Kong',
    id: 'Hong Kong'
  },
  {
    name: 'Urumqi',
    id: 'Urumqi'
  },
  {
    name: 'Kuala Lumpur',
    id: 'Kuala Lumpur'
  },
  {
    name: 'Singapore',
    id: 'Singapore'
  },
  {
    name: 'Taipei',
    id: 'Taipei'
  },
  {
    name: 'Perth',
    id: 'Perth'
  },
  {
    name: 'Irkutsk',
    id: 'Irkutsk'
  },
  {
    name: 'Ulaanbaatar',
    id: 'Ulaanbaatar'
  },
  {
    name: 'Seoul',
    id: 'Seoul'
  },
  {
    name: 'Osaka',
    id: 'Osaka'
  },
  {
    name: 'Sapporo',
    id: 'Sapporo'
  },
  {
    name: 'Tokyo',
    id: 'Tokyo'
  },
  {
    name: 'Yakutsk',
    id: 'Yakutsk'
  },
  {
    name: 'Darwin',
    id: 'Darwin'
  },
  {
    name: 'Adelaide',
    id: 'Adelaide'
  },
  {
    name: 'Canberra',
    id: 'Canberra'
  },
  {
    name: 'Melbourne',
    id: 'Melbourne'
  },
  {
    name: 'Sydney',
    id: 'Sydney'
  },
  {
    name: 'Brisbane',
    id: 'Brisbane'
  },
  {
    name: 'Hobart',
    id: 'Hobart'
  },
  {
    name: 'Vladivostok',
    id: 'Vladivostok'
  },
  {
    name: 'Guam',
    id: 'Guam'
  },
  {
    name: 'Port Moresby',
    id: 'Port Moresby'
  },
  {
    name: 'Magadan',
    id: 'Magadan'
  },
  {
    name: 'Srednekolymsk',
    id: 'Srednekolymsk'
  },
  {
    name: 'Solomon Is.',
    id: 'Solomon Is.'
  },
  {
    name: 'New Caledonia',
    id: 'New Caledonia'
  },
  {
    name: 'Fiji',
    id: 'Fiji'
  },
  {
    name: 'Kamchatka',
    id: 'Kamchatka'
  },
  {
    name: 'Marshall Is.',
    id: 'Marshall Is.'
  },
  {
    name: 'Auckland',
    id: 'Auckland'
  },
  {
    name: 'Wellington',
    id: 'Wellington'
  },
  {
    name: 'Nuku\'alofa',
    id: 'Nuku\'alofa'
  },
  {
    name: 'Tokelau Is.',
    id: 'Tokelau Is.'
  },
  {
    name: 'Chatham Is.',
    id: 'Chatham Is.'
  },
  {
    name: 'Samoa',
    id: 'Samoa'
  }
];
