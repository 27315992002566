import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['ci-icon'],
  classNameBindings: ['sizeClass', 'spin:ci-icon--spin'],
  tagName: 'i',

  spin: false,
  size: null,

  iconTemplatePath: computed('iconName', function() {
    return `components/icon/${this.iconName}`;
  }),

  sizeClass: computed('size', function() {
    const size = this.size;

    return size ? `ci-icon--${size}` : '';
  })
});
