import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminFinancialConfigurationsEditSyncRoute extends Route {
  @service router;
  @service session;

  beforeModel() {
    if (this.session.currentUser.isSuperuser || this.session.currentClient.can_view_financial_config_sync) {
      return true;
    }

    // Unauthorized users are redirected to the dashboard
    this.router.transitionTo('authenticated.dashboard');
  }

  model() {
    return this.modelFor('authenticated.admin.financial-configurations.edit');
  }

  afterModel(model, transition) {
    if (!model.get('isQBD')) {
      transition.abort();
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('chartOfAccounts', model);
  }

  @action
  willTransition() {
    this.controller.get('chartOfAccounts').rollbackAttributes();
  }
}
