import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class V2OnlinePlannerJsonModel extends Fragment {
  @attr('string') version;
  @attr('number') plannerConfigId;
  @fragmentArray('v2/online-planner-page') pages;
  @attr('object') theme;
}
