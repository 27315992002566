import { oneWay } from '@ember/object/computed';
import Component from '@ember/component';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  didReceiveAttrs() {
    this._super(...arguments);
    this.store
      .query('specialTax', {
        chart_of_accounts_id: get(this, 'chartOfAccounts.id')
      })
      .then(specialTaxes => {
        set(this, 'specialTaxes', specialTaxes);
      });
  },
  classNames: ['quick-add-product'],
  flashes: service(),
  session: service(),
  store: service(),
  canViewSpecialTaxes: oneWay('session.currentClient.can_view_special_taxes'),

  /**
   * Product model that is passed in to component
   * @type {Object}
   */
  model: null,

  /**
   * statementCategories class passed to the `conditional-select-box`
   * @type {Object}
   */
  statementCategories: null,

  /**
   * statementSubcategories class passed to the `conditional-select-box`
   * @type {Object}
   */
  statementSubcategories: null,

  /**
   * General Price List associated to created model and Product Category
   * @type {Object}
   */
  generalPriceList: null,

  /**
   * Whether or not to show validations
   * @type {Boolean}
   */
  showValidation: false,

  actions: {
    save() {
      const product = this.model;

      product.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);

          this.attrs
            .saveProduct()
            .then(() => {
              this.flashes.addSuccess('Product saved! Let\'s add another.');

              this.element.querySelector('.name-focus-selector input').focus();
            })
            .catch(() => {
              this.flashes.addError(
                'Something went wrong. If errors continue, please contact support@tributetms.com.'
              );
            });
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    defaultTax() {
      this.toggleProperty('model.isTaxed');
      if (get(this, 'model.isTaxed') == true) {
        set(this, 'model.stateTax', true);
        set(this, 'model.localTax', true);
      } else if (get(this, 'model.isTaxed') == false) {
        set(this, 'model.stateTax', false);
        set(this, 'model.localTax', false);
        set(this, 'model.specialTax', false);
      }
    }
  }
});
