import Route from '@ember/routing/route';

export default class KasesManageDocumentsRoute extends Route {
  model() {
    return this.modelFor('authenticated.kases.manage');
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('kase', model)
  }
}
