import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class FormBodyDonationComponent extends Component {
  @service store;

  @action
  updateBodyDonationStaffMember(kase, id) {
    const prop = 'bodyDonationStaffMember';

    if (!id || id === '') {
      kase.set(prop, null);
      return;
    }

    this.store.findRecord('user', id).then(user => {
      kase.set(prop, user);
    });
  }
}
