import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';


export default class AdminFinancialConfigurationsEditDiscountsRoute extends Route {
  @service router;
  @service session;

  beforeModel() {
    if (this.session.currentUser.isSuperuser || this.session.currentClient.can_view_financial_config_discounts) {
      return true;
    }

    // Unauthorized users are redirected to the dashboard
    this.router.transitionTo('authenticated.dashboard');
  }

  model() {
    const coa = this.modelFor(
      'authenticated.admin.financial-configurations.edit'
    );
    return this.store
      .query('discountType', {
        archived: false,
        per_page: 0,
        chart_of_accounts_id: coa.get('id')
      })
      .then((res) => res.toArray());
  }

  setupController(controller) {
    super.setupController(...arguments);
    const coa = this.modelFor(
      'authenticated.admin.financial-configurations.edit'
    );
    const store = this.store;

    controller.set('chartOfAccounts', coa);
    controller.set(
      'accounts',
      store.query('account', {
        per_page: 5000,
        chart_of_accounts_id: coa.get('id')
      })
    );
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
