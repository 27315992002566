import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AdminOrganizationsEditRoute extends Route {
  @service router;
  @service session;
  @service store;

  beforeModel() {
    if (!this.session.currentUser.canEditLocations) {
      this.router.transitionTo('authenticated.admin.manage.organizations.index');
    }
  }

  model(params) {
    return this.store.findRecord('organization', params.organization_id);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.model.rollbackAttributes();
    }
  }
}
