import { get } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  session: service(),

  beforeModel() {
    if (get(this, 'session.currentUser.permissions') > 2) {
      return true;
    }

    // Unauthorized users are redirected to the dashboad
    this.transitionTo('authenticated.dashboard');
  }
});
