import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';
import { sort } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

const Validations = buildValidations({
  'model.name': validator('presence', true),
});

export default class AdminGroupsEditController extends Controller.extend(Validations) {
  @service flashes;

  @tracked newGroupUser = null;
  @tracked showValidations = false;

  userSorting = ['user.name:asc'];
  @sort('model.groupUsers', 'userSorting') users;

  @action
  handleError(message = 'Oh No! There was an error.') {
    this.flashes.addError(message);
  }

  @action
  handleSuccess() {
    this.flashes.addSuccess('Successfully saved!');
  }

  @action
  save() {
    this.validate().then(() => {
      if (this.validations.isValid) {
        this.showValidations = false;
        this.model
          .save()
          .then(() => {
            this.handleSuccess();
            this.transitionToRoute('authenticated.admin.manage.groups');
          })
          .catch(() => {
            this.handleError();
          });
      } else {
        this.showValidations = true;
      }
    });
  }

  @action
  async addGroupUser() {
    const usedGroupUsers = await Promise.all(
      this.model.get('groupUsers').map((gu) => gu.user)
    );

    if (usedGroupUsers.includes(this.newGroupUser)) {
      return this.handleError(
        'Whoops! That user is already assigned to this group.'
      );
    } else {
      this.store
        .createRecord('groupUser', {
          group: this.model,
          user: this.newGroupUser,
        })
        .save()
        .then(this.handleSuccess)
        .catch(this.handleError);
    }
  }

  @action
  removeGroupUser(groupUsers) {
    groupUsers.destroyRecord().then(this.handleSuccess).catch(this.handleError);
  }

  @action
  cancelEdit() {
    if (this.model.hasDirtyAttributes) {
      this.model.rollbackAttributes();
    }
  }
}
