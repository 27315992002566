import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class V2OnlinePlannerContentModel extends Fragment {
  @attr('string') type;
  @attr('string') style;
  @attr('string') label;
  @attr('string') name;
  @attr('object') validations;
  @attr('string') value;
  @attr('boolean') hide;
  @fragmentArray('v2/online-planner-dropdown-option') options;
}
