import Adapter from './application';

export default class V2SelectionPackageAdapter extends Adapter {
  get include() {
    const stringArray = [
      'package',
      'package.name',
      'selection-package-slots',
      'selection-package-slots.package-slot',
      'selection-package-slots.package-slot.package-slot-products',
      'selection-package-slots.package-slot.package-slot-products.product',
      'selection-package-slots.package-slot.package-slot-products.product.product-category',
      'selection-package-slots.selection-package-slot-products',
      'selection-package-slots.selection-package-slot-products.product',
      'selection-package-slots.selection-package-slot-products.product.product-category',
      'selection-package-slots.selection-package-slot-products.selection-package-slot',
      'selection-package-slot-products',
      'selection-package-slot-products.product',
      'selection-package-slot-products.product.product-category'
    ]

    return stringArray.join(',');
  }
}
