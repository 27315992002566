import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';

export default class AdminOrganizationsRoute extends SearchRoute {
  modelType = 'organization';
  routeRegex = /authenticated.admin.manage.organization/;

  @action
  refreshModel() {
    this.refresh();
  }
}
