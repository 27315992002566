import Adapter from './application';
import { BuildURLMixin } from '@ember-data/adapter';

export default class TfeDocumentAdapter extends Adapter.extend(BuildURLMixin) {
  findRecord(store, type, id, snapshot) {
    const currentURL = window.location.href;
    const match = currentURL.match(/\/cases\/(\d+)/);
    let url = this.buildURL(type.modelName, id, snapshot, 'findRecord');

    if (match) {
      const kaseID = match[1];
      url = `${url}?kase_id=${kaseID}`;
    }

    return this.ajax(url, 'GET');
  }
}
