import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedOnlinePlannerPlansEditRoute extends Route {
  @service session;
  @service store;
  @service onlinePlanner;
  @service intl;

  async model(params) {
    await this.onlinePlanner.unloadFragments();
    return this.store.findRecord('v2/online-planner-session', params.id, {
      include: [
        'online-planner-config',
        'online-planner-session-products',
        'online-planner-session-packages',
        'online-planner-session-payments'
      ].join(',')
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const emberSession = this.store.createRecord(
      'v2/online-planner-session-fragment',
      model.data
    );

    const oq = emberSession.pages.find((x) => x.name === 'overview-questions');
    const pf = oq.pages.find((x) => x.name === 'planning-for');
    const planningFor = pf?.fields?.find((x) => x.name === 'disposition.type')?.value === 'self';

    controller.set('askConvert', false);
    controller.set('askLocation', false);
    controller.set('emberSession', emberSession);
    controller.set('planningForSelf', planningFor);

    this.store
      .findRecord(
        'v2/OnlinePlannerConfig',
        model.get('onlinePlannerConfig.id'),
        {
          reload: true,
          include: [
            'location-online-planner-configs',
            'location-online-planner-configs.location',
            'logo',
            'general-price-list'
          ].join(',')
        }
      );

    this.store.findAll('v2/location', { archived: false }).then((locations) => {
      controller.set('locations', locations);
    });

    this.store
      .query('v2/olp-upload', {
        online_planner_session_uuid: model.uuid
      })
      .then((docs) => {
        controller.set('uploadedDocuments', docs);
      });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
