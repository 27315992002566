import Route from '@ember/routing/route';

export default class AuthenticatedFinancialDetailRoute extends Route {
  queryParams = {
    query: {
      replace: true,
    },
    page: {
      refreshModel: true,
    },
    starts_at: {
      refreshModel: true,
    },
    ends_at: {
      refreshModel: true,
    },
    sort_by: {
      refreshModel: true,
    },
    order: {
      refreshModel: true,
    },
    filter_method: {
      refreshModel: true,
      replace: true,
    },
    per_page: {
      refreshModel: true,
    },
    organization_location_id: {
      refreshModel: true,
    },
    include: {
      refreshModel: true,
    }
  };

  model(params) {
    return this.store.query('v2/selection', params);
  }
}
