import Controller, { inject as controller } from '@ember/controller';
import { debounce, cancel, later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import config from 'crakn/config/environment';
import moment from 'moment';

export default class AuthenticatedWhiteboardController extends Controller {
  @controller application;
  
  @service browser;
  @service store;

  @tracked descending = true;
  @tracked has_events = 'true';
  @tracked organization_location_id = null;
  @tracked mode = 'paginate';
  @tracked page = 1;
  @tracked paginateInterval = 600;
  @tracked paginateMetrinome = null;
  @tracked per_page = 6;
  @tracked queryParams = [
    'page',
    'per_page',
    'organization_location_id',
    'has_events',
    'sort_by',
    'whiteboard_starts_at',
    'whiteboard_ends_at',
    'user_id',
    'status',
    'show_pending',
    'mode',
    'refreshInterval',
    'paginateInterval'
  ]; 

  @tracked refreshInterval = 20;
  @tracked refreshMetrinome = null;
  @tracked organizationOrLocation = null;
  @tracked show_pending = 'show';
  @tracked sort_by = 'events';
  @tracked user_id = null;
  @tracked status = 'active';
  @tracked timestamp = null;
  @tracked whiteboard_starts_at = moment()
    .startOf('day')
    .toISOString();
  @tracked whiteboard_ends_at = moment()
    .endOf('day')
    .add(7, 'day')
    .toISOString();

  get isTest() {
    return config.environment === 'test'
  }

  shouldAppRefresh() {
    if (this.refreshThreshold < moment().valueOf()) {
      // On auto paginate, only refresh after last page
      if (this.mode === 'paginate' && this.page === this.model.get('meta.pages')) {
        // Reset page variable
        window.location = location.href.replace(`&page=${this.page}`, '');
        location.reload();
        return true;

        // Otherwise, just refresh
      } else if (this.mode !== 'paginate') {
        location.reload();
        return true;
      }
    } else {
      return false;
    }
  }

  resetMetrinomes() {
    // Determine if the browser should be refreshed
    if (this.shouldAppRefresh()) {
      return true;
    }

    if (this.per_page === 0) {
      set(this, 'page', 1);
    }

    set(this, 'per_page', this.mode === 'show-all' ? 10000 : 6);

    if (this.mode !== 'refresh' && this.refreshMetrinome) {
      cancel(this.refreshMetrinome);
      set(this, 'refreshMetrinome', null);
    }

    this.startRefreshMetrinome();

    if (this.mode !== 'paginate' && this.paginateMetrinome) {
      cancel(this.paginateMetrinome);
      set(this, 'paginateMetrinome', null);
    }

    this.startPaginateMetrinome();
  }

  startRefreshMetrinome() {
    cancel(this.refreshMetrinome);

    if (this.mode !== 'refresh' || this.refreshInterval < 1) {
      return;
    }

    set(
      this,
      'refreshMetrinome',
      later(this, this.resetRefreshMetrinome, this.refreshInterval * 60000)
    );
  }

  resetRefreshMetrinome() {
    this.send('refreshModel');

    this.startRefreshMetrinome();
  }

  startPaginateMetrinome() {
    cancel(this.paginateMetrinome);
    set(this, 'paginateMetrinome', null);

    if (this.mode !== 'paginate' || this.paginateInterval < 30) {
      return;
    }

    set(
      this,
      'paginateMetrinome',
      later(this, this.resetPaginateMetrinome, this.paginateInterval * 1000)
    );
  }

  resetPaginateMetrinome() {
    const pages = this.model.meta.pages;

    // Determine if the browser should be refreshed
    if (this.shouldAppRefresh()) {
      return true;
    }

    if (this.mode !== 'paginate') {
      return;
    }

    // If only one page of results, refresh the model
    if (pages === 1) {
      this.refreshModel();
      this.send('refreshModel');

      // If on the last possible page, reset to first page
    } else if (this.page >= pages) {
      set(this, 'page', 1);

      // Otherwise, increment the page number
    } else {
      this.incrementProperty('page');
    }

    this.startPaginateMetrinome();
  }

  updateDateFilter() {
    const startsAt = moment(this.whiteboard_starts_at)
      .add(1, 'day')
      .toISOString();
    
      const endsAt = moment(this.whiteboard_ends_at)
      .add(1, 'day')
      .toISOString();
    
    set(this, 'whiteboard_starts_at', startsAt);
    set(this, 'whiteboard_ends_at', endsAt);
    this.resetAtMidnight.perform();
  }

  @action
  openWhiteboardModal() {
    this.send('showModal', 'g/whiteboard/modal/whiteboard-details', this);
  }

  @action
  openEventModal(event) {
    this.send('showModal', 'g/whiteboard/modal/event-details', event);
  }

  @action
  toggleFullscreen() {
    this.application.isFullscreenActive = !this.application.isFullscreenActive;
  }

  @action
  modalSettingsChanged() {
    debounce(this, this.resetMetrinomes, 1000);
  }

  @task({ drop: true })
  *resetAtMidnight() {
    if (this.isTest) {
      return;
    }

    const timeNow = moment();
    const refreshTime = moment().endOf('day');
    const timeToMidnight = moment.duration(refreshTime.diff(timeNow));
    while(true) {
      yield timeout(timeToMidnight._milliseconds);
      this.updateDateFilter();
    }
  }
}
