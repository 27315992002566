import Route from '@ember/routing/route';

export default class KasesManageTaskCompleteRoute extends Route {
  model() {
    return this.modelFor('authenticated.kases.manage.tasks');
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const currentFilterName = 'complete';
    const currentFilter = model.filters[currentFilterName];

    controller.setProperties({
      kase: model.kase,
      tasks: model.tasks.filter(currentFilter),
      currentFilterName: currentFilterName,
      filters: model.filters
    });
  }
}