import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';

export default class AdminVehiclesRoute extends SearchRoute {
  modelType = 'vehicle';
  routeRegex = /authenticated.admin.manage.vehicles/;

  @action
  refreshModel() {
    this.refresh();
  }
}
