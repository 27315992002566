import { alias, readOnly, filter } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import PersonSmartEntry from 'crakn/mixins/person-smart-entry';
import config from 'crakn/config/environment';
import { validator, buildValidations } from 'ember-cp-validations';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';
import { tracked } from '@glimmer/tracking';

const Validations = buildValidations({
  'selection.contractDate': [validator('presence', true)]
});


export default Controller.extend(PersonSmartEntry, Validations, {
  smartEntryPersonMeta: {
    mother: { key: 'kase.mother', label: 'Mother' },
    father: { key: 'kase.father', label: 'Father' },
    spouse: { key: 'kase.spouse', label: 'Spouse' },
    informant: { key: 'kase.informant', label: 'Informant' },
    purchaser: {
      key: 'selection.purchaser',
      label: 'Purchaser',
      srcOptions: ['informant', 'mother', 'father', 'spouse']
    }
  },

  store: service(),
  api: service(),
  session: service(),
  flashes: service(),
  intl: service(),
  customProduct: null,
  selection_id: null,
  generalPriceListId: null,
  currentUserId: alias('session.currentUser.id'),
  purchaserId: readOnly('selection.purchaser.id'), // for validation
  sortBy: ['createdAt'],
  hostUrl: `${config.host}`,
  saveFirst: false,
  isExportDetailsOpen: false,
  unpostingMode: false,
  originalIsBadDebt: tracked({ value: false }),

  sortedPackages: computed('selection.packages.length', function() {
    const selectionPackages = this.get('selection.packages');

    if (isEmpty(selectionPackages)) {
      return [];
    } else {
      return selectionPackages.sortBy('createdAt');
    }
  }),

  sortedCustomProducts: computed('selection.customProducts.length', function() {
    const selectionCustomProducts = this.get('selection.customProducts');

    if (isEmpty(selectionCustomProducts)) {
      return [];
    } else {
      return selectionCustomProducts.sortBy('createdAt');
    }
  }),

  sortedProducts: computed('selection.products.length', function() {
    const selectionProducts = this.get('selection.products');

    if (isEmpty(selectionProducts)) {
      return [];
    } else {
      return selectionProducts.sortBy('createdAt');
    }
  }),

  canViewClientLogos: computed(
    'sfgsLogos',
    'selectedContractTemplate.isCustom',
    'session.currentClient.can_use_custom_sfgs',
    'selection.isContract',
    function() {
      const logos = this.sfgsLogos;
      const canUseCustomSFGS = this.get('selectedContractTemplate.isCustom');
      const isContract = get(this, 'selection.isContract');

      return !isEmpty(logos) && !canUseCustomSFGS && isContract;
    }
  ),

  allLogos: alias('clientInfo.logos'),

  sfgsLogos: filter('allLogos', function(logo) {
    return logo.get('archivedAt') == null && logo.get('sfgs') == true;
  }),

  canAddSelections: computed('selection.locked', 'selection_id', function() {
    return this.get('selection.locked') || !this.selection_id;
  }),

  canEditFinancials: computed('kase.status', function() {
    return ['archived', 'lost'].indexOf(get(this, 'kase.status')) === -1;
  }),

  canUnpostSelection: computed(
    'selection.{posted,exported,adjustments,payments}',
    'session.currentUser.canUnpostContractsAddOns',
    'canEditFinancials',
    function() {
      const canEditFinancials = this.canEditFinancials;
      const canUnpostContractsAddOns = get(
        this,
        'session.currentUser.canUnpostContractsAddOns'
      );
      const exported = get(this, 'selection.exported');
      const posted = get(this, 'selection.posted');
      const noAdjustments = get(this, 'selection.adjustments.length') === 0;
      const noExportedPayments =
        get(this, 'selection.payments').findBy('exported', true) === undefined;
      const noRefunds = get(this, 'selection.refunds.length') === 0;

      return (
        canEditFinancials &&
        canUnpostContractsAddOns &&
        !exported &
        posted &&
        noExportedPayments &&
        noAdjustments &&
        noRefunds
      );
    }
  ),

  canPostSelection: computed(
    'selection.posted',
    'canEditFinancials',
    'session.currentUser.canPostContractsAddOns',
    function() {
      const posted = get(this, 'selection.posted');
      const canEditFinancials = this.canEditFinancials;
      const canPostContractsAddOns = get(
        this,
        'session.currentUser.canPostContractsAddOns'
      );

      return !posted && canEditFinancials && canPostContractsAddOns;
    }
  ),

  showContractEsignControls: false,
  editingEsignedContractId: null,
  viewingAuditTrailEsignedContractId: null,

  isCreatingEsignedContract: computed(
    'selection.esignedContracts.@each.id',
    function() {
      return get(this, 'selection.esignedContracts').any(doc => !doc.get('id'));
    }
  ),

  esignatureStatusClass: computed(
    'selection.contractEsignatureStatus',
    function() {
      switch (get(this, 'selection.contractEsignatureStatus')) {
        case 'pending':
          return 'yellow';
        case 'complete':
          return 'green';
        case 'declined':
          return 'red';
        default:
          return '';
      }
    }
  ),

  selectedContractTemplate: computed('selectedContractTemplateId', function() {
    return this.store.peekRecord('v2/contractTemplate', this.selectedContractTemplateId);
  }),

  auditLogAction: null,

  get canUnExport() {
    return this.session.currentUser.hasDoNotExportPermission ||
    this.session.currentUser.isSuperuser;
  },

  get contractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice ?
    `Choose an Invoice or Add-on` : `Choose a Contract or Add-on`;
  },

  get addOnWording() {
    if (!this.selection.isContract) {
      return "Add-on";
    }
    return this.contractWording;
  },

  get contractWording() {
    return this.session.currentClient.relabel_contract_to_invoice ?
    `Invoice` : `Contract`;
  },

  get makeSelectionLabel() {
    const swap = this.session.currentClient.relabel_contract_to_invoice;
    return `Select ${swap ? 'an invoice' : 'a contract'} or add-on from above to add selections.`;
  },

  get isExportedToNetSuite() {
    return this.selection.netsuiteId && this.selection.netsuiteId !== '';
  },

  get isExportedToQbd() {
    return this.selection.qbdId && this.selection.qbdId !== '';
  },

  get isExportedToQbo() {
    return this.selection.qboId && this.selection.qboId !== '';
  },

  actions: {
    unpost() {
      if (dateInCoaLockedPeriod(this.selection, this.session, this.selection.get('contractDate'))) {
        this.flashes.addError(
          this.intl.t(
            'authenticated.kases.manage.financials.selection.errors.unlockContractPostingPeriod',
            { name: this.addOnWording.toLowerCase() }
          )
        );
        return;
      }

      let confirmation = true;
      if (get(this.selection, 'hasActiveEsignContracts')) {
        confirmation = confirm(
          `Unposting this selection will invalidate all ${this.contractText}s out for signature.  Are you sure you want to continue?`
        );
      }

      if (!confirmation) {
        return;
      }

      if(this.session.currentClient.require_reason_for_unposting && !this.unpostingMode) {
        set(this, 'unpostingMode', true);
        return;
      } else {
        this.send('saveUnpostReason');
      }
    },
    saveUnpostReason() {
      const selection = this.selection;

      if(this.unpostingMode && this.session.currentClient.require_reason_for_unposting) {
        const reasonForUnposting = selection.reasonForUnposting || '';

        const trimmed_reasonForUnposting = reasonForUnposting.trim();

        if (trimmed_reasonForUnposting.length == 0) {
          alert(this.intl.t('authenticated.kases.manage.financials.selection.errors.missingReason'));
          return;
        } else {
          set(selection, 'reasonForUnposting', trimmed_reasonForUnposting);
        }
      }

      set(selection, 'posted', false);
      selection.save()
        .then(() => {
          this.flashes.addSuccess(
            this.intl.t('authenticated.kases.manage.financials.selection.successful.unposted')
          );
          this.send('refreshKaseFinancial');
        })
        .catch(() => {
          this.flashes.addError();
          selection.rollbackAttributes();
        });

      set(this, 'unpostingMode', false);
    },
    cancelUnpost() {
      set(this, 'unpostingMode', false);
      this.selection.rollbackAttributes();
    },
    setSelectedContractTemplateId(templateId) {
      this.set('selectedContractTemplateId', templateId);

      set(this, 'showContractEsignControls', false);

      if (this.isCreatingEsignedContract) {
        this.selection.esignedContracts
          .filter(doc => !doc.get('id'))
          .forEach(doc => doc.rollbackAttributes());
      }
    },

    searchPurchaseReason(query) {
      return this.store
                 .query('purchaseReason', {
                   query: query
                 })
                 .then((results) => {
                   return results.map((reason) => reason.name);
                 });
    },

    searchEmbalmReason(query) {
      return this.store
                 .query('embalmReason', {
                   query: query
                 })
                 .then((results) => {
                   return results.map((reason) => reason.name);
                 });
    },

    createCustomProduct() {
      set(
        this,
        'customProduct',
        this.store.createRecord('selectionCustomProduct')
      );
    },

    validateAndSaveSelection(posting) {
      if (posting && dateInCoaLockedPeriod(this.selection, this.session, this.selection.get('contractDate'))) {
        this.flashes.addError(
          this.intl.t(
            'authenticated.kases.manage.financials.selection.errors.postingPeriodLockedContract',
            { name: this.addOnWording.toLowerCase() }
          )
        );
        return;
      }

      this.selection.validate().then(({ validations }) => {
        const errors = validations.get('errors');

        if (errors.length > 0) {
          set(this, 'saveFirst', true);
          set(this, 'showValidation', true);
        } else {
          set(this, 'saveFirst', false);
          set(this, 'showValidation', false);
          set(this, 'showContractEsignControls', false);
          this.send('saveSelection', posting);
        }
      });
    },

    setSelectedLogoAndSave(logoId) {
      this.selection.logoId = logoId;
      this.send('validateAndSaveSelection', false);
    },

    toggleContractEsignControls() {
      if (this.showContractEsignControls) {
        set(this, 'showContractEsignControls', false);
      } else {
        const selection = this.selection;

        selection.validate().then(({ validations }) => {
          const errors = validations.get('errors');

          if (!this.saveFirst) {
            set(this, 'saveFirst', get(selection, 'hasDirtyAttributes'));
          }

          if (errors.length > 0) {
            set(this, 'showValidation', true);
          } else {
            set(this, 'showValidation', false);

            if (!get(this, 'selection.esignedContracts.length')) {
              this.send('buildNewEsignedContract');
            }

            set(this, 'showContractEsignControls', true);
          }
        });
      }
    },

    buildNewEsignedContract() {
      const esignedContract = this.store.createRecord('esignedContract', {
        selection: this.selection,
        contractTemplate: this.selectedContractTemplate,
        remainingSignatureFieldNames: get(
          this,
          'selectedContractTemplate.esignatureFieldNames'
        )
      });

      this.send('editEsignedContract', esignedContract.get('id'));
    },

    editEsignedContract(id) {
      const isClosingCurrentEditing = id === this.editingEsignedContractId;

      const closeCurrentEditing = () =>
        set(this, 'editingEsignedContractId', null);
      const openNewEditing = () => set(this, 'editingEsignedContractId', id);

      isClosingCurrentEditing ? closeCurrentEditing() : openNewEditing();
      set(this, 'viewingAuditTrailEsignedContractId', null);
    },

    showAuditTrail(id) {
      const isClosingCurrentAuditTrail =
        id === this.viewingAuditTrailEsignedContractId;

      const closeCurrentAuditTrail = () =>
        set(this, 'viewingAuditTrailEsignedContractId', null);
      const openNewAuditTrail = () =>
        set(this, 'viewingAuditTrailEsignedContractId', id);

      isClosingCurrentAuditTrail
        ? closeCurrentAuditTrail()
        : openNewAuditTrail();
      set(this, 'editingEsignedContractId', null);
    },

    setPurchaserRelationship(relationship) {
      set(this, 'selection.purchaserRelationship', relationship);
      this.selection.save();
    },

    smartEntryPurchaser(srcPersonRole, destPersonRole) {
      set(this, 'saveFirst', true);
      this.send('smartEnterPerson', srcPersonRole, destPersonRole);
    },

    setContractDate(date) {
      set(this, 'saveFirst', true);
      const selection = this.selection;
      selection.set('contractDate', date);
      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
      selection.save()
        .then(() => {
          this.flashes.addSuccess(
            `"${get(selection, 'name')}" has been updated!`
          );
        })
        .catch(() => {
          this.flashes.addError();
        });
      } else {
        set(this, 'showValidation', true);
      }
    });
    },

    async resendToBeaconfp() {
      await this.api.json.post(`selections/${this.selection.id}/resend_to_beaconfp`, {});
    }
  }
});
