import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2CustomFieldModel extends Model {
  @attr('string') text;
  @attr('string') type;
  @attr('array',  { defaultValue: () => [] }) options;
  @attr('string') value;

  @belongsTo('v2/custom-field-config') customFieldConfig;
  @belongsTo('v2/kase-custom-field') kaseCustomFields;
  @belongsTo('v2/kase') kase;
}
