import JSONAPISerializer from '@ember-data/serializer/json-api';
import { get } from '@ember/object';
import { cloneDeep } from 'lodash-es/lang';

/**
 * The purpose of this file is to serialize ember models correctly into
 * jsonapi spec. It uses the snapshot of the model and outputs (serializes)
 * the snapshot into json.
 */
export default class ApplicationSerializer extends JSONAPISerializer {
  includedRecords = {};
  parentRecord;

  // Add V2 to the payload model name if it is not already there
  modelNameFromPayloadKey() {
    const modelName = super.modelNameFromPayloadKey(...arguments);

    if (new RegExp('^v2/.+').test(modelName)) {
      return modelName;
    } else {
      return `v2/${modelName}`;
    }
  }

  // Inject the meta into the data object so it can be used
  normalize(_, hash) {
    const ret = super.normalize(...arguments);
    ret.data.attributes.meta = hash.meta;

    return ret;
  }

  // Every Ember Data model and snapshot uses an interal clientId to reference within itself
  // this will get exposed publicly with RFC 403 https://emberjs.github.io/rfcs/0403-ember-data-identifiers.html
  getMappingId(dataElement) {
    return dataElement._internalModel.clientId;
  }

  setCachedModel(snapshot, data, overwrite = false) {
    const cache = this.fetchCachedModel(snapshot)

    if (!cache || (cache && overwrite)) {
      this.includedRecords[this.getMappingId(snapshot)] = cloneDeep(data, true);
    }
  }

  fetchCachedModel(snapshot) {
    return this.includedRecords[this.getMappingId(snapshot)];
  }

  buildDataObject(snapshot) {
    if (snapshot.id) {
      return { data: { id: snapshot.id, type: this.payloadKeyFromModelName(snapshot.modelName) } }
    } else {
      return { data: { 'local:id': this.getMappingId(snapshot), type: this.payloadKeyFromModelName(snapshot.modelName) } }
    }
  }

  // Called from ember when object needs to be serialized and sent in a request
  serialize(snapshot, { isRelationship = false, included = {}, parentRecord = null } = {}) {
    this.includedRecords = included;
    this.parentRecord = parentRecord;

    // Do not process the record if it has already been serialized
    if (this.fetchCachedModel(snapshot)) {
      return this.fetchCachedModel(snapshot);
    }

    if (!this.parentRecord) {
      this.parentRecord = this.getMappingId(snapshot);
    }

    // Mark the record as been serialized
    this.setCachedModel(snapshot, this.buildDataObject(snapshot));

    const serializedData = super.serialize(...arguments);
    
    if (isRelationship) {
      return serializedData
    } else {
      // Finalize data
      return this.injectIncluded(serializedData);
    }
  }

  // Called from ember when an object has many relationships that needs serialized
  serializeHasMany() {
    super.serializeHasMany(...arguments);
    this.serializeRelationship(...arguments);
  }

  // Called from ember when an object has a relationship that needs serialized
  serializeBelongsTo() {
    super.serializeBelongsTo(...arguments);
    this.serializeRelationship(...arguments);
  }

  serializeRelationship(snapshot, data, { kind, key }) {
    const serializer = snapshot.record.store.serializerFor(snapshot.modelName);

    if (!data) {
      return null;
    }

    data.relationships = data.relationships || {};
    const attribute = this.keyForRelationship(key, kind, 'serialize');

    data.relationships[attribute] = data.relationships[attribute] || {};
    const relationship = data.relationships[attribute];

    // Serialize based on hasMany vs belongsTo
    if (kind === 'belongsTo') {
      relationship.data = this.serializeRecord(snapshot.belongsTo(key), serializer, key);
    } else if (kind === 'hasMany') {
      relationship.data = []; // provide a default empty value

      const hasMany = snapshot.hasMany(key);

      if (hasMany !== undefined) {
        relationship.data = hasMany.map(x => this.serializeRecord(x, serializer, key));
      }
    }
  }

  // Serialize object recursively based on included objects
  serializeRecord(snapshot, serializer, relKey) {
    if (!snapshot || this.parentRecord === this.getMappingId(snapshot)) {
      return null;
    }

    // check to see if the model has already been processed
    let serialized = cloneDeep(this.fetchCachedModel(snapshot));

    if (serialized) {
      return serialized.data
    } else {
      serialized = snapshot.serialize({
        isRelationship: true,
        included: this.includedRecords,
        parentRecord: this.parentRecord
      });

      if (!serialized || !serialized.data) {
        return null;
      } else if (!serialized.data.attributes) {
        serialized.data.attributes = {};
      }

      if (snapshot.id) {
        serialized.data.id = snapshot.id;
      } else {
        serialized.data['local:id'] = this.getMappingId(snapshot);
      }

      const deepSerialize = get(serializer, `attrs.${relKey}.serialize`);

      if (deepSerialize) {
        this.setCachedModel(snapshot, serialized, true);
      }

      // Clean up data object
      if (serialized.data.relationships) {
        delete serialized.data.relationships;
      }
      delete serialized.data.attributes;

      if (!deepSerialize) {
        this.setCachedModel(snapshot, serialized, false);
      }

      return serialized.data;
    }
  }

  // Transform array of included records to hash on the main serialized object
  injectIncluded(serializedHash) {
    if (this.includedRecords === {}) {
      return serializedHash;
    }

    serializedHash['included'] = Object.keys(this.includedRecords).map((key) => {
      return this.includedRecords[key] ? this.includedRecords[key].data : null;
    }).filter(x => x && x.attributes);

    return serializedHash;
  }

  normalizeSaveResponse(store, _baseModel, { data }, _id, requestType) {
    if (requestType == 'createRecord' || requestType == 'updateRecord') {
      const mapping = get(data, 'meta.local-ids') || {};
      for (const modelName of Object.keys(mapping)) {
        for (const id of Object.keys(mapping[modelName])) {
          this.associateRelationshipId(store, modelName, id, mapping[modelName][id])
        }
      }
    }

    return super.normalizeSaveResponse(...arguments);
  }

  associateRelationshipId(store, modelName, id, clientId) {
    const record = store
                     .peekAll(this.modelNameFromPayloadKey(modelName))
                     .filterBy('currentState.stateName', 'root.loaded.created.uncommitted')
                     .findBy('_internalModel.clientId', clientId);
    if (record) {
      record.id = id;
    }
  }
}
