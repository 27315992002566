import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import bestWishesCardUrls from 'crakn/utils/best-wishes-urls';
import moment from 'moment';

export default class KasesIntegrationsBestWishesController extends Controller {
  @service api;
  @service flashes;
  @tracked requestType = null;
  @tracked showDisclaimers = false;
  @tracked showCardPreviews = false;
  @tracked recipient = null;
  @tracked informant = null;
  @tracked nextOfKin = null;
  @tracked recipientNextOfKin = null;
  @tracked recipientInformant = null;
  bestWishesCardUrlArray = bestWishesCardUrls; 

  get formattedBestWishesExportedAt() {
    return moment(this.model.bestWishesExport.updatedAt).format('LLL');
  }

  get showRecipient() {
    if (this.informant || this.nextOfKin) {
      return this.informant.content !== null || this.nextOfKin.content !== null;
    }
    else {
      return false;
    }
  }

  @action
  recipientCheck(value) {
    switch (value) {
      case 'toggleNextOfKin':
        if (this.recipient != null) {
          this.toggleProperty('recipientNextOfKin');
          this.recipient = null;
        } else {
          this.toggleProperty('recipientNextOfKin');
          this.recipient = 'next_of_kin';
          this.recipientInformant = false;
        }
        break;

      case 'toggleInformant':
        if (this.recipient != null) {
          this.toggleProperty('recipientInformant');
          this.recipient = null;
        } else {
          this.toggleProperty('recipientInformant');
          this.recipient = 'informant';
          this.recipientNextOfKin = false;
        }
        break;
    }
  }

  @action
  bestWishes(e) {
    e.target.disabled = true; // double click
    if (this.recipient) {
      this.api.json
        .post('best_wishes/export', {
          body: {
            recipient: this.recipient,
            request_type: this.requestType,
            kase_id: this.model.kase.id,
            integration_service_id: this.model.integrationService.id,
          },
        })
        .then((response) => {
          if (response.ok) {
            this.flashes.addSuccess('Case successfully scheduled for export!');
            this.send('refreshModel');
          } else {
            this.flashes.addError(
              'Oops, something went wrong! Please try again.'
            );
          }
          e.target.disabled = false;
        });
    } else {
      this.flashes.addError('You must select a recipient. Please try again');
      e.target.disabled = false;
    }
  }

  @action
  generatePdf(cardType, uuid) {
    const kaseId = this.model.kase.id;

    this.api.json
      .get('best_wishes/pdf_generate', {
        params: {
          kase_id: kaseId,
          uuid: uuid,
          card_type: cardType
        },
      })
      .then((response) => {
        if (!response.ok) {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
        }
      });

    this.send('refreshModel');
  }

  @action
  onWillDestroy() {
    this.recipientNextOfKin = null;
    this.recipientInformant = null;
    this.requestType = null;
    this.showDisclaimers = false;
  }

  @action
  toggleDisclaimers() {
    this.toggleProperty('showDisclaimers');
  }

  @action
  toggleCardPreviews() {
    this.toggleProperty('showCardPreviews');
  }

  @action
  toggleDateToSend() {
    this.toggleProperty('showDateToSend');
  }
}
