import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminGeneralPriceListsEditPackagesNewRoute extends Route {
  @service store;

  async model() {
    const v2Gpl = await this.store.findRecord('v2/general-price-list', this.modelFor('authenticated.admin.general-price-lists.edit').get('id'));
    return this.store.createRecord('v2/package', {
      generalPriceList: v2Gpl
    });
  }
}
