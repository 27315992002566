import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';
import moment from 'moment';

export default class AuthenticatedPlacesManageController extends Controller {
  @service api;
  @service flashes;
  @service router;

  @tracked substitutePlace = null;

  @tracked showDeleteDialog = false;
  @tracked showReplaceDialog = false;
  @tracked showArchiveDialog = false;

  @computed('deleteInfo', 'substitutePlace')
  get isReplaceDisabled() {
    return !this.deleteInfo || !this.substitutePlace;
  }

  get prompt() {
    if (this.model.archivedAt === null) {
      return 'This place will no longer be available for selection. Are you sure you want to archive?';
    } else {
      return 'Are you sure you want to unarchive this place?';
    }
  }

  handleDeleteSuccess() {
    this.substitutePlace = null;
    this.flashes.addSuccess('Yay! You deleted a place.');
    this.router.transitionTo('authenticated.places');
  }

  deleteOrReplacePlace(place, substitutePlace) {
    this.showDeleteDialog = false;
    this.showReplaceDialog = false;

    // Substitute place can be nil > see place v2 model
    place
      .replace(substitutePlace)
      .then(() => this.handleDeleteSuccess())
      .catch(() =>
        this.flashes.addError('Oops! There was an error deleting this place.')
      );
  }

  @action
  onSelectPlace(place) {
    this.substitutePlace = place;
  }

  @action
  onRemovePlace() {
    this.substitutePlace = null;
  }

  @action
  verifyDelete() {
    this.showDeleteDialog = true;
  }

  @action
  verifyReplace() {
    this.showReplaceDialog = true;
  }

  @action
  verifyArchive() {
    this.showArchiveDialog = true;
  }

  @action
  justDelete() {
   this.model.destroyRecord()
    .then(() => {
      this.handleDeleteSuccess();
      this.send('refreshModel');
    })
    .catch(() => {
      this.flashes.addError('Oops! There was an error deleting this place.')
    });  }

  @action
  deleteAndReplace() {
    if (this.model.id !== this.substitutePlace.id) {
      this.deleteOrReplacePlace(this.model, this.substitutePlace);
    }
  }

  @action
  toggleArchived(place) {
    this.showArchiveDialog = false;
    if (place.archivedAt) {
      place.archivedAt = null;
    } else {
      place.archivedAt = moment();
    }

    place.save().then(() => {
      this.send('refreshModel');
    });
  }
}
