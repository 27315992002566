import { reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import Component from '@ember/component';

const MailToComponent = Component.extend({
  tagName: 'a',
  attributeBindings: ['href'],
  email: null,

  href: computed('email', function() {
    return `mailto:${this.email}`;
  }),

  label: reads('email')
});

MailToComponent.reopenClass({
  positionalParams: ['email', 'label']
});

export default MailToComponent;
