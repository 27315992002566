import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class PayNowRoute extends Route {
  @service api;
  @service router;

  layout = 'fullscreen';
  isFullscreenActive = true;

  async model(params, transition) {
    const token = transition.to.queryParams.jwt;
    const response = await this.api.json.get(`v2/pay_now?jwt=${token}`);
    const data = response.parsedJson.data;

    if (response.status === 401 && response.parsedJson.error?.includes('expired')) {
      const isTMSClient = data.attributes.referenceId ? true : false;

      this.router.transitionTo('pay-now.expired', transition.to.queryParams.location_uuid, isTMSClient);

      return;
    }

    return {
      ...data.attributes,
      id: data.id
    }
  }

  afterModel(model, transition) {
    const jwt = transition.to.queryParams.jwt;

    if (transition.to.queryParams.success === 'true') {
      this.router.transitionTo('pay-now.success', 'successful-payment', jwt);
    }

    if (!model.referenceId) {
      return;
    }

    if (model['amount-due'] <= 0) {
      this.router.transitionTo('pay-now.success', 'balance-paid', jwt);
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    if (model.reference_id) {      
      controller.setProperties({
        balance: model.amount_due,
      });
    }

    controller.setProperties({
      firstName: model.person.first_name,
      lastName: model.person.last_name,
      areNameFieldsDisabled: true,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        firstName: null,
        lastName: null,
        amount: null,
        balance: null,
        jwt: null,
        showValidations: false,
        isNextButtonDisabled: false,
      })
    }
  }
}
