import SearchController from 'crakn/controllers/search'
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default class AdminLocationsIndexController extends SearchController {
  @service flashes;
  @service store;
  @service session;

  @tracked location = null;

  @action
  toggleAdding() {
    if (this.location) {
      this.location.get('address')
                   .then((address) => {
                     address.rollbackAttributes()
                     this.location.rollbackAttributes();
                     this.location = null;
                   });
    } else {
      this.location = this.store.createRecord('v2/location',{
        address: this.store.createRecord('v2/address', {
          country: this.defaultCountry()
        })
      });
    }
  }

  @action
  create() {
    this.location.save({
                   adapterOptions: {
                     include: 'address'
                   }
                 })
                 .then((location) => {
                   this.location = null;
                   this.flashes.addSuccess('Location successfully created!');
                   this.transitionToRoute('authenticated.admin.locations.edit', location.id);
                 })
                 .catch(() => {
                   this.flashes.addError('An error has occured')
                 })
  }

  @action
  async toggleArchived(location) {
    if (location.get('archivedAt')) {
      location.set('archivedAt', null);
      await this.toggleLocationMetaArchived(location, false);
    } else {
      location.set('archivedAt', moment());
      await this.toggleLocationMetaArchived(location, true);
    }

    location.save().then(() => {
      this.send('refreshModel');
    });
  }

  async locationMetas(location) {
    const metas = await this.store.query('v2/location-meta', {
      client_id: this.session.currentClient.id,
      location_id: location.id
    });

    return metas;
  }

  async toggleLocationMetaArchived(location, shouldArchive) {
    const locationMetas = await this.locationMetas(location);
    if (locationMetas.length === 0) {
      return;
    }

    locationMetas.forEach(locationMeta => {
      locationMeta.set('archivedAt', shouldArchive ? moment() : null);
      locationMeta.save();
    });
  }

  @action
  setAddress(address) {
    this.location.address.setProperties(address);
  }

  defaultCountry() {
    const country = this.session.currentClient.operating_country;
    if (country && country !== 'US') {
      return country;
    } else {
      return 'US';
    }
  }
}
