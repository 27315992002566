import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex m--md--l\">\n  <h4 class=\"m--sm--b strong\">\n    {{t \"components.g.tfe.filled-forms-list.esignature-requests.text.title\"}}\n  </h4>\n  <button\n      class=\"btn btn--link\"\n      type=\"button\"\n      {{on \"click\" @toggleView}}>\n    {{t \"components.g.tfe.filled-forms-list.esignature-requests.button.hide\"}}\n  </button>\n</div>\n\n{{#each this.generatedDocuments as |generatedDocument|}}\n  <G::Tfe::Forms::EsignatureRequestListItem\n      @generatedDocument={{generatedDocument}}/>\n{{/each}}\n", {"contents":"<div class=\"flex m--md--l\">\n  <h4 class=\"m--sm--b strong\">\n    {{t \"components.g.tfe.filled-forms-list.esignature-requests.text.title\"}}\n  </h4>\n  <button\n      class=\"btn btn--link\"\n      type=\"button\"\n      {{on \"click\" @toggleView}}>\n    {{t \"components.g.tfe.filled-forms-list.esignature-requests.button.hide\"}}\n  </button>\n</div>\n\n{{#each this.generatedDocuments as |generatedDocument|}}\n  <G::Tfe::Forms::EsignatureRequestListItem\n      @generatedDocument={{generatedDocument}}/>\n{{/each}}\n","moduleName":"crakn/components/g/tfe/forms/esignature-requests.hbs","parseOptions":{"srcName":"crakn/components/g/tfe/forms/esignature-requests.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class GCraknTfeFormsEsignatureRequests extends Component {
  @service store;
  @service intl;

  @tracked generatedDocuments = [];

  constructor() {
    super(...arguments);
    this.loadGeneratedDocuments();
  }

  @action
  async loadGeneratedDocuments() {
    const generatedDocument = this.args.generatedDocument;

    const params = {
      current_client: true,
      document_id: generatedDocument.belongsTo('document').id(),
      include_archived: true,
      include: 'signers',
      per_page: 0,
      remote_id: generatedDocument.remoteId,
      signing_status: ['pending', 'completed', 'canceled']
    };

    this.generatedDocuments = await this.store.query('tfe/generated-document', params).catch(() => {
      this.generatedDocuments = [];
    });
  }
}
