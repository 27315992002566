import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import RSVP from 'rsvp';

export default class BestWishesIntegrationRoute extends Route {
  @service store;
  @service session;

  model() {
    const integrationServices = this.store.query('integrationService', {
      name: 25, // best_wishes
      not_category: 'accounting',
      per_page: 1000
    });
    const logos = this.store.query('v2/logo', {
      clientInfoId: this.session.currentClient.client_info_id,
      archived: false
    });
    const currentUserId = this.session.currentUser.id;
    const signatures = this.store
    .query('v2/signature', {
      userId: currentUserId
    })
    .then(signatures => signatures.firstObject);

    return RSVP.hash({
      integrationServices,
      logos,
      signatures
    })
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
