import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { get, set } from '@ember/object';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  password: {
    description: 'Password',
    validators: [
      validator('presence', true),
      validator('length', { min: 5 })
    ]
  },
  passwordConfirmation: validator('confirmation', {
    on: 'password',
    message: 'must match password'
  })
});

export default Controller.extend(Validations, {
  api: service(),
  flashes: service(),
  password: null,
  passwordConfirmation: null,
  error: null,
  showValidation: false,
  loginSuccess: false,
  validToken: false,
  queryParams: ['token'],
  token: null,

  handleSuccess(email) {
    this.flashes.addSuccess('Your password was successfully updated!');
    set(this, 'loginSuccess', true);
    this.session.authenticate('authenticator:application', {
      email,
      password: this.password
    }).then(() => {
      this.transitionToRoute('authenticated.dashboard');
    });
  },

  handleError() {
    this.flashes.addError('Something went wrong. Please try again later. The password reset may be expired. If errors continue, please contact support@tributetms.com.');
  },

  actions: {
    resetPassword(password) {
      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);

          this.api.json.post(
            'password_resets/submit_new_password',
            { body: { password, token: this.token } }
          ).then(response => {
            if (response.ok) {
              this.handleSuccess(response.parsedJson.email);
            } else {
              this.handleError();
            }
          });
        } else {
          set(this, 'showValidation', true);
        }
      });
    }
  }
});
