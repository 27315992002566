import SearchRoute from 'crakn/routes/search';

export default class BatchPaymentsIndexRoute extends SearchRoute {
  queryParams = {
    query: {
      replace: true,
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
    per_page: {
      refreshModel: true,
    },
    sort_by: {
      refreshModel: true,
    },
    order: {
      refreshModel: true,
    },
    starts_at: {
      refreshModel: true,
    },
    ends_at: {
      refreshModel: true,
    },
    organization_location_id: {
      refreshModel: true,
    },
  };

  modelType = 'v2/generalPayment';

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = ![
        'authenticated.payments.batch-payments.index',
        'authenticated.payments.batch-payments.new',
      ].includes(transition.targetName);

      if (resetParams) {
        controller.setProperties({
          page: 1,
          query: null,
          sortBy: null,
          archived: false,
          order: null,
        });
      }
    }
  }
}
