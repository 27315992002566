import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller'

export default class AuthenticatedAdminManageLogosController extends Controller {
  @service flashes;
  @service store;

  @action
  success(response) {
    if (response) {
      this.store.push(response);
      this.flashes.addSuccess('Logo Successfully uploaded!');
    }
  }

  @action
  updateCremAuthHeader(logo, response) {
    this.model.defaultCremAuthHeader = logo;
    this.success(response);
  }

  @action
  updateReceiptLogo(logo, response) {
    this.model.defaultReceiptLogo = logo;
    this.success(response);
  }

  @action
  updateSfgsLogo(logo, response) {
    this.model.defaultSfgsLogo = logo;
    this.success(response);
  }

  @action
  updateSoaLogo(logo, response) {
    this.model.defaultSoaLogo = logo;
    this.success(response);
  }

  @action
  create({ sfgs, soa, receipt, cremauth }) {
    return new Promise((resolve, reject) => {
      this.store.createRecord('v2/logo', { clientInfo: this.model, sfgs, soa, receipt, cremauth })
        .save()
        .then(logo => { resolve(logo) })
        .catch(() => reject());
    });
  }

  @action
  destroy(logo) {
    logo.destroyRecord()
      .then(() => {
        this.flashes.addSuccess('Logo Successfully Archived!');
      })
      .catch(() => {
        this.flashes.addError('An error has occurred.');
      });
  }

  @action
  saveLogos() {
    this.model
      .save()
      .then(() => {
        this.flashes.addSuccess('Your logos have been saved!');
      })
      .catch(() => {
        this.flashes.addError('Oops, something went wrong! Please try again.');
      });
  }
}
