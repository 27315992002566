import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class V2ProductModel extends Model {
  @service store;

  @attr('boolean') isTaxed;
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('price') cost;
  @attr('price') price;
  @attr('string') description;
  @attr('string') name;

  @belongsTo('v2/account') account;
  @belongsTo('v2/account') assetAccount;
  @belongsTo('client') client;
  @belongsTo('v2/account') cogsAccount;
  @belongsTo('v2/generalPriceList') generalPriceList;
  @belongsTo('v2/preneedCategory') preneedCategory;
  @belongsTo('v2/productCategory') productCategory;
  @belongsTo('v2/statement-category') statementCategory;

  @hasMany('v2/package', { async: true }) packages;
  @hasMany('v2/package-slot', { async: true }) packageSlots;
  @hasMany('v2/package-slot-product', { async: true }) packageSlotProducts;
}
