import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminFinancialConfigurationsEditBankAccountsRoute extends Route {
  @service router;
  @service session;
  model() {
    return [];
  }
  beforeModel() {
    if (
      !this.session.currentUser.isSuperuser &&
      !this.session.currentClient.can_view_financial_config_bank_accounts
    ) {
      this.router.transitionTo('authenticated.dashboard');
    }
  }
}
