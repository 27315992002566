import moment from 'moment';

export function dateInCoaLockedPeriod(selection, session, date) {
  if (!session?.currentClient?.enforce_posting_period_locks) {
    return false;
  }

  const lockedDate = moment(selection?.get('latestPostingPeriodLockedAt'));
  const dateToCheck = moment(date);
  return lockedDate.isSame(dateToCheck, 'day') || lockedDate?.isAfter(dateToCheck, 'day');
}
