import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';
import { action } from '@ember/object';
import { genders } from 'crakn/utils/data-helpers';
import moment from 'moment';

export default class AuthenticatedContactsEditController extends Controller {
  @service api;
  @service names;
  @service router;
  @service session;

  queryParams = ['redirectPath', 'redirectId'];
  genderOptions = genders;

  get canViewHistory() {
    return this.session.currentClient.can_view_kases ||
        this.session.currentClient.can_view_inquiries_global
  }

  handleSaveSuccess(person) {
    const redirectPath = this.redirectPath;
    const redirectId = this.redirectId;

    this.send('flashSuccess', 'Yay! You saved a contact.');

    // TODO: Remove findRecord once V1 is replaced
    if (redirectPath && redirectId) {
      this.store.findRecord('person', person.id).then(() => {
				this.router.transitionTo(redirectPath, redirectId);
			});
    } else {
      this.router.transitionTo('authenticated.contacts');
    }
  }

  handleSaveError() {
    this.send('flashError', 'Oops! There was an error saving this contact.');
  }

  handleExportFDSuccess() {
    this.send('refreshModel');
    this.send('flashSuccess', 'Yay! You successfully exported a contact.');
  }

  handleExportFDError() {
    this.send('flashError', 'Oops, something went wrong! Please try again.');
  }

  @action
  createBirthplace() {
    this.model.birthplace = this.store.createRecord('v2/address', {
      country: this.session.currentClient.operating_country || 'US'
    });
  }

  @action
  save() {
    this.model.validate().then(({ validations }) => {
      if (validations.isValid) {
        this.showValidations = false;
        this.model
          .save({
            adapterOptions: { include: 'birthplace,funeral-decisions-person' },
          })
          .then(
            run.bind(this, 'handleSaveSuccess'),
            run.bind(this, 'handleSaveError')
          )
          .finally(() => {
            this.model.personGovernmentIdentifier = null;
          });
      } else {
        this.showValidations = true;
      }
    });
  }

  @action
  exportToFD(e) {
    e.target.disabled = true; // avoid double-click issues
    this.model
      .exportToFuneralDecisions()
      .then(
        run.bind(this, 'handleExportFDSuccess'),
        run.bind(this, 'handleExportFDError'),
        () => (e.target.disabled = false)
      );
  }

  @action
  setBirthDate(value) {
    this.model.birthDate = moment(value);
  }
}
