import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { preventFormDefault } from 'ember-cli-prevent-form-default';
import Controller from '@ember/controller';

export default class KasesEditReferralInfoController extends Controller {
  @service clientRouting;
  @service flashes;
  @service router;
  kase = null;
  model = null;
  types = [
    'Advertisement',
    'Caregiver Referral',
    'Cemetery',
    'Chaplain/Clergy',
    'Deceased Wishes',
    'Event',
    'Family Friend',
    'Funded Pre-Need',
    'Hospice',
    'Hospital Employee',
    'Internet Search',
    'Other/Note',
    'Personal Recommendation',
    'Pets',
    'Previously Served',
    'Referral Coroner',
    'Staff Relationship',
    'Trade Call',
    'Unfunded Pre-Need',
    'Veterinarian'
  ];

  handleSaveSuccess() {
    this.flashes.addSuccess('The referrer has been saved!');
  }

  handleSaveError() {
    this.flashes.addError('Oops! There was an error saving!');
  }


  @action
  saveContact() {
    const model = this.model;
    
    model.get('person').then(person => {
      person
        .save()
        .then(() => {
          model
            .save()
            .then(() => this.handleSaveSuccess())
            .catch(() => this.handleSaveError());
        })
        .catch(() => this.handleSaveError());
    });
  }

  @action
  setReferringPlace(place) {
    this.store.findRecord('place', place.id)
        .then((place) => {
          this.model.set('place', place);
        });
  }

  @action
  removeReferringPlace() { 
    this.model.set('place', null);
  }

  @action
  @preventFormDefault
  saveReferralInfo() {
    this.model.save()
              .then(() => {
                this.flashes.addSuccess('Great job! You updated the case information');
                const route = this.clientRouting.manageRouteTransition(
                  'authenticated.kases.manage.edit.referral-info'
                );
                this.router.transitionTo(route);
              }).catch(() => {
                this.handleSaveError();
              });
  }
}
