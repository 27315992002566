import { helper as buildHelper } from '@ember/component/helper';

/**
 * Almost equal helpers
 * @param  {Array} obj Array of params
 * @return {Number} Whether or not the passed params equal value but perhaps not type
 */
export function get_object_key_count(params) {
  return Object.keys(params[0]).length;
}

export default buildHelper(get_object_key_count);
