import { init } from '@fullstory/browser';
import config from '../config/environment';

export function initialize() {
  if (config.fullstoryOrgId) {
    init({ orgId: config.fullstoryOrgId });
  }
}

export default {
  initialize
};
