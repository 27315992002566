import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2SpecialTaxModel extends Model {  
  @attr('moment-datetime') startsAt;
  @attr('moment-datetime') endsAt;
  @attr('moment-datetime', { defaultValue: null }) archivedAt;

  @attr('number') rate;

  @attr('string') name;

  @belongsTo('v2/account', { async: true }) liabilityAccount;
  @belongsTo('v2/taxItem', { async: true }) taxItem;
  @belongsTo('v2/chartOfAccounts', { async: true }) chartOfAccounts;

  @hasMany('v2/locations', { async: true }) locations;
}