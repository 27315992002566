import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminGeneralPriceListsEditPackagesEditManageProductsController extends Controller {
  @service api;
  @service store;

  queryParams = ['query', 'page'];

  get packageSlotProducts() {
    return this.packageSlot.packageSlotProducts.toArray();
  }

  get productIds() {
    return this.model.map((p) => p.get('id'));
  }

  @action
  productIsInPackageSlot(id) {
    return this.packageSlotProducts.some((psp) => psp.product.get('id') === id);
  }

  @action
  async selectAllOnPage() {
    await this.api.json.post('v2/package_slot_products/bulk_add', {
      body: {
        package_slot_id: this.packageSlot.id,
        product_category_id: this.packageSlot.productCategory.get('id'),
        product_ids: this.productIds,
      }
    });

    this.send('refreshModel');
  }

  @action
  async selectAllOnCategory() {
    await this.api.json.post('v2/package_slot_products/bulk_add', {
      body: {
        package_slot_id: this.packageSlot.id,
        product_category_id: this.packageSlot.productCategory.get('id')
      }
    });

    this.send('refreshModel');
  }

  @action
  async clearAllSelections() {
    await this.api.json.post('v2/package_slot_products/bulk_remove_by_product_category', {
      body: {
        package_slot_id: this.packageSlot.id,
        product_category_id: this.packageSlot.productCategory.get('id')
      }
    });

    this.send('refreshModel');
  }

  @action
  async addProduct(id) {
    if (this.productIsInPackageSlot(id)) {
      await this.packageSlotProducts.find((psp) => psp.product.get('id') === id).destroyRecord();
    } else {
      await this.store.createRecord('v2/package-slot-product', {
        product: this.store.peekRecord('v2/product', id),
        packageSlot: this.packageSlot,
      }).save();
    }
  }
}
