import { notEmpty, lt, gt, and } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: 'span',

  phoneNumber: null,

  cleanNumber: computed('phoneNumber', function() {
    if (this.phoneNumber) {
      return this.phoneNumber.replace(/[^0-9]/g, '');
    }
    return null;
  }),

  doesNumberExist: notEmpty('cleanNumber'),
  isUnderMaxLength: lt('cleanNumber.length', 11),
  isAboveMinimumLength: gt('cleanNumber.length', 6),
  isLinkable: and('doesNumberExist', 'isUnderMaxLength', 'isAboveMinimumLength')
});
