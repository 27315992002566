import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AdminManageGeneralPriceListsNewController extends Controller {
  @service flashes;
  @service intl;
  @service router;
  @service store;

  gpl = null;
  chartId = null;
  chartsOfAccounts = null;

  @tracked showValidations = false;

  handleSaveSuccess(gpl) {
    this.flashes.addSuccess(this.intl.t('authenticated.admin.manage.general-price-lists.new.messages.success'));
    this.router.transitionTo(
      'authenticated.admin.general-price-lists.edit',
      gpl
    );
  }

  handleSaveError() {
    this.flashes.addError(this.intl.t('authenticated.admin.manage.general-price-lists.new.messages.error'));
  }

  @action
  save(gpl) {
    gpl.validate().then(({ validations }) => {
      if (validations.isValid) {
        set(this, 'showValidations', false);

        gpl
          .save()
          .then((gpl) => {
            this.handleSaveSuccess(gpl);
          })
          .catch(() => {
            this.handleSaveError();
          });
      } else {
        set(this, 'showValidations', true);
      }
    });
  }

  @action
  updateCoa(coaId) {
    this.store
      .findRecord('chart-of-accounts', coaId, {
        backgroundReload: false
      })
      .then((coa) => {
        set(this.gpl, 'chartOfAccounts', coa);
      });
  }

  @action
  cancel() {
    this.router.transitionTo('authenticated.admin.manage.general-price-lists');
    set(this, 'showValidations', false);
  }
}
