import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';

export default class OnlinePlannerGplEditCategoryRoute extends SearchRoute {
  modelType = 'v2/product';
  routeRegex = /authenticated.admin.online-planner.config.category.edit/;
  olpProductCategory;
  configId;
  controller;

  queryParams = {
    page: {
      refreshModel: true
    },
    query: {
      replace: true,
      refreshModel: true
    }
  }

  async model(params, transition) {
    this.configId = transition.to.parent.params.id;
    this.olpProductCategory = await this.store.findRecord('v2/online-planner-product-category', params.olp_category_id);
    
    const products = await this.store.query(this.modelType, {
      include_archived: false,
      page: params.page,
      per_page: 10,
      product_category_id: this.olpProductCategory.categoryId,
      query: params.query,
      sort_by: 'products.price'
    });

    const olpProducts = !products.toArray().length ? [] : await this.store.query('v2/online-planner-product', {
        include_archived: true,
        online_planner_config_id: this.configId,
        product_ids: products.map(product => product.id)
    });

    return { products, onlinePlannerProducts: olpProducts, meta: products.meta }
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('olpProductCategory', this.olpProductCategory);
    controller.set('configId', this.configId);
    this.controller = controller;
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.setProperties({
        query: null,
        model: null,
        olpProductCategory: null,
        configId: null,
        pendingAddProducts: [],
        pendingRemoveProducts: []
      });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
