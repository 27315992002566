import Serializer from './application';
import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';

export default class TfeRevision extends Serializer.extend(EmbeddedRecordsMixin) {
  serialize(snapshot, _options) {
    const json = super.serialize(...arguments);
    json.metadata = snapshot.record.metadata;
    return json;
  }

  normalize(_modelClass, resourceHash) {
    const metadata = resourceHash.metadata;
    const keys = Object.keys(metadata);
    keys.forEach((key) => {
      metadata[key] = JSON.parse(metadata[key]);
    });
    resourceHash.metadata = metadata;
    return super.normalize(...arguments);
  }
}
