import Component from '@ember/component';
import { set } from '@ember/object';

export default Component.extend({
  classNames: ['delete-dialog'],
  classNameBindings: [
    'showDialog:delete-dialog--in',
    'hideDialog:delete-dialog--out',
    'leftJustified:left-justified'
  ],

  showDialog: true,
  hideDialog: false,
  eventListener: null,

  prompt: 'Are you sure?',

  actions: {
    delete() {
      this.sendAction('onDelete');
    },

    cancel() {
      // Set hideDialog to false once animation completes
      this.eventListener = this.element.addEventListener('animationend', () => {
        if (this.eventListener) {
          this.element.removeEventListener('animationend', this.eventListener);
        }

        set(this, 'hideDialog', false);
      });

      set(this, 'showDialog', false);
      set(this, 'hideDialog', true);      
    }
  }
});
