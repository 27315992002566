import EmberObject, { computed } from '@ember/object';
import moment from 'moment';

export default EmberObject.extend({
  duration: 60,

  hour: computed('startsAt', function() {
    return this.startsAt.hours();
  }),

  endsAt: computed('startsAt', 'duration', function() {
    return moment(this.startsAt).add(this.duration, 'minutes');
  })
});
