import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminDataCauseOfDeathController extends Controller {
  @service store;
  @service flashes;

  @tracked newCauseOfDeath;
  @tracked page = 1;

  queryParams = ['page'];

  @action
  create() {
    if (!this.newCauseOfDeath) {
      return
    }

    const record = this.store.createRecord('v2/cause-of-death', { name: this.newCauseOfDeath } )
    this.newCauseOfDeath = null;

    record.save()
          .then(() => {
            this.flashes.addSuccess('Cause of Death successfully saved!');
            this.send('refreshModel');
          });
  }

  @action
  save(record) {
    record.save()
          .then(() => {
            this.flashes.addSuccess('Cause of Death successfully saved!');
            this.send('refreshModel');
          })
          .catch(() => {
            this.error();
          });
  }

  @action
  delete(record) {
    record.destroyRecord()
          .then(() => {
            this.flashes.addSuccess('Cause of Death successfully destroyed!');
            this.send('refreshModel');
          })
          .catch(() => {
            this.error();
          });
  }

  @action
  cancel(record) {
    record.rollbackAttributes();
  }

  @action
  error() {
    this.flashes.addError('An error occurred while saving.');
  }
}
