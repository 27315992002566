import Model, { attr, belongsTo } from '@ember-data/model';

export default class InterestModel extends Model {
  @attr('number') amount;
  @attr('moment-datetime') createdAt;
  @attr('string') netsuiteId;
  @attr('string') note;
  @attr('moment-datetime') updatedAt;

  @belongsTo('user') enteredBy;
  @belongsTo('v2/interestType') interestType;
  @belongsTo('payment') payment;
  @belongsTo('selection') selection;
}
