import Component from '@glimmer/component';
import { computed } from '@ember/object';

export default class ChainOfCustodyHeader extends Component {
  @computed('args.model.custodyAudits')
  get lastAudit() {
    return this.args.model.custodyAudits
      .filter((audit) => audit.id)
      .sort((a, b) => a.createdAt > b.createdAt ? 1 : -1)
      .get('lastObject');
  }

  get showQrCode() {
    return (
      this.args.model.chainOfCustodyQrCodeUrl &&
      this.args.model.hasChainOfCustody
    );
  }

  @computed('lastAudit')
  get lastLocation() {
    if (!this.lastAudit) {
      return null;
    }

    if (this.lastAudit.get('endRoom.name')) {
      return `${this.lastAudit.get(
        'endPlace.address.name'
      )}: ${this.lastAudit.get('endRoom.name')}`;
    }
    return this.lastAudit.get('endPlace.address.name');
  }

  get kase() {
    return this.args.model;
  }

  get qrCodeDownloadName() {
    const downloadItems = [
      this.kase.get('deceased.firstLastName'),
      this.kase.customId,
      'chain-of-custody',
    ];
    return downloadItems
      .filter((i) => i)
      .join('-')
      .concat('.png');
  }
}
