import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';

export default class KasesManageEditEnvelopesRoute extends SearchRoute {
  @service api;
  
  queryParams = {
    category: {
      refreshModel: true
    },
    subcategory: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    query: {
      refreshModel: true
    },
  };

  modelType = 'v2/printable';
  layout = 'custom-aside';

  async setupController(controller, model) {
    const kase = this.modelFor('authenticated.kases.manage');
    const envelopeSubjects = await kase.people();
    const categoryFilterOptionsResp = await this.api.json.get('v2/printables/category_filter_options');
    const categoryFilterOptions = categoryFilterOptionsResp.parsedJson;

    controller.set('printables', model);
    controller.set('meta', model.meta);
    controller.set('printableFilterOptions', categoryFilterOptions);
    controller.set('kase', kase);
    controller.set('envelopeSubjects', envelopeSubjects?.people);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        page: 1,
        query: null,
        category: null,
        subcategory: null
      });
    }
  }
}
