import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class KasesEditServiceInfoRoute extends Route {
  layout = 'custom-aside';

  async model() {
    const kase = this.modelFor('authenticated.kases.manage');

    const serviceInfo = await this.store.query('v2/serviceInfo', {
      kase_id: kase.id,
      include: [
        'kase',
        'florist',
        'memorial-contributions',
        'memorial-contributions.place',
        'memorial-contributions.place.address',
        'memorial-contributions.service-info'
      ].join(',')
    });

    if (serviceInfo && serviceInfo.length > 0) {
      return serviceInfo.firstObject;
    } else {
      const v2Kase = await this.store.findRecord('v2/kase', kase.id);
      const serviceInfo = this.store.createRecord('v2/serviceInfo', {
        kase: v2Kase
      });
      await serviceInfo.save();
      return serviceInfo;
    }
  }

  async setupController(controller, model) {
    const kase = this.modelFor('authenticated.kases.manage');
    const v2Kase = this.store.peekRecord('v2/kase', kase.id);

    controller.set('model', model);
    controller.set('kase', kase);
    controller.set('v2Kase', v2Kase);
  }

  @action
  refreshModel() {
    this.refresh();
  }

  @action
  willTransition(transition) {
    if (transition.to.name === 'authenticated.kases.manage.edit.service-info') {
      return;
    }

    const dirtyContributions = this.currentModel.memorialContributions.filter(
      (contribution) => contribution.get('hasDirtyAttributes')
    );

    if (
      this.currentModel.get('hasDirtyAttributes') ||
      dirtyContributions.length >= 1
    ) {
      if (!confirm('You have unsaved changes. Are you sure?')) {
        transition.abort();
      } else {
        this.currentModel.rollbackAttributes();
        dirtyContributions.forEach((contribution) =>
          contribution.isNew
            ? contribution.destroyRecord()
            : contribution.rollbackAttributes()
        );
        return true;
      }
    }
  }
}
