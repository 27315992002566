import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"input input--standard {{if this.hasValue \"input--has-value\"}}\">\n  <input\n      class=\"input__field\"\n      type=\"time\"\n      value={{this.value}}\n      {{on \"blur\" this.onSelection}}>\n  <label class=\"input__label\">{{@placeholder}}</label>\n  <span class=\"input__border\"></span>\n</div>\n", {"contents":"<div class=\"input input--standard {{if this.hasValue \"input--has-value\"}}\">\n  <input\n      class=\"input__field\"\n      type=\"time\"\n      value={{this.value}}\n      {{on \"blur\" this.onSelection}}>\n  <label class=\"input__label\">{{@placeholder}}</label>\n  <span class=\"input__border\"></span>\n</div>\n","moduleName":"crakn/components/g/crakn/native-timepicker.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/native-timepicker.hbs"}});
import Component from '@glimmer/component';
import { notEmpty } from '@ember/object/computed';
import { action } from '@ember/object';
import moment from 'moment';

export default class GCraknNativeTimepickerComponent extends Component {
  @notEmpty('args.model') hasValue;

  get value() {
    const time = this.args.model;

    if (time !== null) {
      return moment(time).format('HH:mm');
    } else {
      return null;
    }
  }

  @action
  onSelection(event) {
    const timeSplit = event.target.value.split(':', 2);
      
    timeSplit[0] = parseInt(timeSplit[0]);
    timeSplit[1] = parseInt(timeSplit[1]);
  
    const time = {
      hours: timeSplit[0],
      minutes: timeSplit[1],
      meridiem: timeSplit[0] >= 12 ? 'PM' : 'AM'
    };
    this.args.onBlur(time);
  }
}
