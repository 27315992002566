import Component from '@ember/component';
import moment from 'moment';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class RecordNameComponent extends Component {
  @tracked showDeleteDialog = false;
  @tracked editing = false;
  @tracked showValidation = false;
  @tracked record;

  async valid() {
    const {validations} = await this.record.validate();
    const valid = validations.get('isValid');
    this.showValidation = !valid;
    return valid;
  }

  @action
  toggleEditing() {
    this.editing = !this.editing;
  }

  @action
  async save() {
    const valid = await this.valid();
    if (valid) {
      this.record.save();
      this.editing = false;
    }
  }

  @action
  remove() {
    this.record.archivedAt = moment();
    this.showDeleteDialog = false;
    this.record.save().then(_record => {
      if (this.onRemove) {
        this.onRemove(this.record);
      }
    });
  }
}