import { attr, belongsTo, hasMany } from '@ember-data/model';
import EsignedDocumentModel from './esigned-document';

export default class EsignedContractModel extends EsignedDocumentModel {
  @attr('moment-datetime') invalidatedAt;

  @belongsTo('selection', { polymorphic: true }) selection;
  @belongsTo('v2/contractTemplate') contractTemplate;

  // Both of these relationships exist on `EsignedDocumentModel`, but if we
  // don't override them, the associated records aren't loaded correctly for
  // some reason.
  @hasMany('esignatures', { inverse: null }) esignatures;
  @hasMany('esignatureActions', { inverse: null }) esignatureActions;
}
