import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class CfsSetupController extends Controller {
  showAvatarOverrideField = true;
  showKeyField = true;
  keyPlaceholder = 'Uidsite';


  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
