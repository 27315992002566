import SearchController from 'crakn/controllers/search';
import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AuthenticatedOnlinePlannerPlansController extends SearchController {
  @tracked archived = false;
  @tracked config_id;
  @tracked debounce = 600;
  @tracked order;
  @tracked page = 1;
  @tracked per_page = 10;
  @tracked planners;
  @tracked search;
  @tracked sort_by;
  @tracked status;
  @tracked submitted;
  @tracked type;
  @service api;
  @service flashes;
  @service intl;
  @service router;

  @tracked askConvert = undefined;
  @tracked askLocation = false;
  
  queryParams = [
    'archived',
    'config_id',
    'order',
    'page',
    'per_page',
    'query',
    'sort_by',
    'status',
    'submitted',
    'type',
  ];

  get plannerOptions() {
    return this.planners?.map(planner => {
      return {
        id: `${planner.id}`,
        name: planner.name
      };
    });
  }

  @computed('model', 'askConvert', 'locations', 'askLocation')
  get locationsAllowed() {
    const currentSession = this.model.find(session => session.id === this.askConvert);
    const gplId = currentSession?.get('onlinePlannerConfig.generalPriceList.id');
    const configLocations = currentSession?.get('onlinePlannerConfig.locationOnlinePlannerConfigs');
    const locationIds = configLocations ? configLocations.map(x => x.get('location.id')) : [];
    const hasLocations = configLocations?.length > 0;
    const hasGpl = gplId > 0;

    if (hasLocations) {
      return this.locations.filter(location => locationIds.includes(location.id));
    }
    
    if (hasGpl) {
      return this.locations.filter(location => location.generalPriceListId == gplId);
    }

    return this.locations;
  }

  @action
  resetConvertToCaseParams() {
    this.askLocation = false;
    this.askConvert = undefined;
    this.selectedLocationID = undefined;
  }

  @action
  onArchive(session) {
    if (!session) return;
    session.destroyRecord().then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.online-planner-plans.actions.archive.success'));
      this.send('refreshModel');
    }).catch(() => {
      this.flashes.addError(this.intl.t('authenticated.online-planner-plans.actions.archive.error'));
    });
  }

  @action
  onUnArchive(session) {
    if (!session) return;
    if (session.status === "Case") return;
    session.archivedAt = null;
    session.save().then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.online-planner-plans.actions.unarchive.success'));
      this.send('refreshModel');
    }).catch(() => {
      session.rollbackAttributes();
      this.flashes.addError(this.intl.t('authenticated.online-planner-plans.actions.unarchive.error'));
    });
  }

  @action
  editSession(session) {
    if (!session) return;
    this.transitionToRoute('authenticated.online-planner-plans.edit', session.id);
  }

  @action
  typeChanged(type) {
    this.type = type === 'All' || type === '' ? undefined : type;
  }

  @action
  statusChanged(status) {
    this.status = status;
    this.submitted = undefined;
    switch (status) {
      case 'Submitted':
        this.submitted = true;
        break;
      case 'In Progress':
        this.submitted = false;
        break;
    
      default:
        break;
    }
  }

  @action
  resetSearch() {
    this.status = undefined;
    this.submitted = undefined;
    this.type = undefined;
    this.config_id = undefined;
    this.archived = false;
    this.search = '';
    this.page = 1;
    this.sort_by = undefined;
    this.order = undefined;
    this.queryDidChange();
  }

  @action
  askToConvert(sessionID) {
    this.askConvert = sessionID;
  }

  @action
  onAskLocation() {
    this.askLocation = true;
  }

  @action
  onConvertToCase(session) {
    const selectedLocationID = this.selectedLocationID;
    this.resetConvertToCaseParams();
    if (!selectedLocationID || !session) return;

    this.api.json
    .post(`v2/online_planner_sessions/${session.id}/promote_to_kase`, {
      body: {
        location_id: selectedLocationID
      }
    })
    .then((resp) => {
      if (resp.ok) {
        this.flashes.addSuccess(
          this.intl.t('authenticated.online-planner-plans.promote.success')
        );
        this.queryDidChange();
      } else {
        this.flashes.addError(
          this.intl.t('authenticated.online-planner-plans.promote.error')
        );
      }
    })
    .catch(() => {
      this.flashes.addError(
        this.intl.t('authenticated.online-planner-plans.promote.error')
      );
    });

  }
}
