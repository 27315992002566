import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class FrontRunnerSetupController extends Controller {
  keyPlaceholder = 'Customer ID';
  showHostnameField = true;
  showKeyField = true;
  
  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
