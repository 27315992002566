import { gt } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['pagination', 'd--xs--flex', 'd--xs--c'],
  page: 1,

  nextPage: computed('page', function() {
    return this.page + 1;
  }),

  previousPage: computed('page', function() {
    return this.page - 1;
  }),

  hasPrevious: gt('page', 1),

  hasNext: computed('page', 'pages', function() {
    return this.page < this.pages;
  })
});
