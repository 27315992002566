import Component from '@ember/component';
import { get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Toolbelt from 'crakn/mixins/component-toolbelt';
import config from 'crakn/config/environment';

export default Component.extend(Toolbelt, {
  tagName: 'li',
  classNames: ['list__item'],

  store: service(),

  name: null,
  description: null,
  kase: null,
  path: null,
  subjects: null,

  is_open: false,
  subject: null,
  envelopeSubjects: null,
  url: computed('path', 'subject', function() {
    return `${
      config.host
    }/api/envelopes/${this.path}/${get(this, 'kase.id')}?person_id=${this.subject}`;
  }),

  actions: {
    fill() {
      const that = this;
      this.kase.people().then(function(response) {
        set(that, 'envelopeSubjects', response[get(that, 'subjects')]);
        set(that, 'is_open', true);
      });
    },

    cancel() {
      set(this, 'is_open', false);
      set(this, 'subject', null);
    }
  }
});
