import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';

export default class V2OnlinePlannerPageMediaModel extends Fragment {
  @attr('string') altText;
  @attr('string') description;
  @attr('string') type;
  @attr('string') uploadedByUserId;

  @attr('object') data;

  @attr('moment-datetime', { defaultValue: null }) uploadedAt;
}
