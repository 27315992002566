import Service, { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';

export default class V2LocationsService extends Service {
  @service store;
  @service session;

  allLocations = null;

  locationNameSort = ['name:asc'];
  @sort('all', 'locationNameSort') allSorted;

  get all() {
    if (this.allLocations === null) {
      this.allLocations = this.store.query('v2/location', { archived: false, per_page: 2000 });
    }

    return this.allLocations;
  }

  unloadAll() {
    this.allLocations = null;
  }
}
