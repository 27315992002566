import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminGeneralPriceListPackagesEditRoute extends Route {
  @service store;

  model(params) {
    return this.store.findRecord('v2/package', params.package_id);
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');

    controller.setProperties({
      generalPriceList: gpl,
      package: model,
      packageSlots: model.get('packageSlots'),
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      if (controller.newPackageSlot?.hasDirtyAttributes) {
        controller.newPackageSlot.rollbackAttributes();
      }

      controller.setProperties({
        displayAddItemWell: false,
        generalPriceList: null,
        newPackageSlot: null,
        package: null,
        packageSlots: null,
      });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
