import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import SelectBox from './select-box';

export default SelectBox.extend({
  store: service(),

  layoutName: 'components/form/select-box',

  locationId: null,

  vehicles: computed('locationId', function() {
    return this.store.query('vehicle', {
      per_page: 1000,
      location_id: this.locationId,
      archived_at: null
    });
  }),

  placeholder: 'All Vehicles',

  options: computed('vehicles', {
    get() {
      return this.vehicles;
    }
  })
});
