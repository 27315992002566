import Route from '@ember/routing/route';
import RSVP from 'rsvp';

export default Route.extend({
  layout: 'custom-aside',

  model() {
    const model = this.modelFor('authenticated.kases.manage.integrations');

    return RSVP.hash({
      model,
      kase: model,
      merrickExport: this.store.queryRecord('integrationExport', {
        kase_id: model.id,
        type: 'merrick',
        most_recent_export: true
      })
    });
  },

  setupController(controller, model, transition) {
    this._super(controller, model, transition);
    controller.setProperties(model);
    this.setDefaultMerrickParams(controller);
  },

  resetController(controller, isExiting, _transition) {
    if (isExiting) {
      this.setDefaultMerrickParams(controller);
    }
  },

  actions: {
    refresh() {
      this.refresh();
    }
  },

  setDefaultMerrickParams(controller) {
    controller.set('merrickParams', {
      instructions: 'Use picture as is',
      otherInstructions: '',
      frameSelection: 'None',
      registerBookType: 'None',
      relativeFriendsBookType: 'None',
      shippingMethod: 'Ground',
      productQuantities: {},
      neededBy: null
    });
  }
});
