import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminDataEmbalmReasonsController extends Controller {
  @service store;
  @service flashes;

  @tracked newEmbalmReason;
  @tracked page = 1;

  queryParams = ['page'];

  @action
  create() {
    if (!this.newEmbalmReason) {
      return 
    }
    
    const record = this.store.createRecord('embalm-reason', { name: this.newEmbalmReason } )
    this.newEmbalmReason = null;

    record.save()
          .then(() => {
            this.flashes.addSuccess('Embalm Reason successfully saved!');
            this.send('refreshModel');
          });
  }

  @action 
  save(record) {
    record.save()
          .then(() => {
            this.flashes.addSuccess('Embalm Reason successfully saved!');
            this.send('refreshModel');
          })
          .catch(() => {
            this.error();
          });
  }

  @action 
  delete(record) {
    record.destroyRecord()
          .then(() => {
            this.flashes.addSuccess('Embalm Reason successfully destroyed!');
            this.send('refreshModel');
          })
          .catch(() => {
            this.error();
          });
  }

  @action 
  cancel(record) {
    record.rollbackAttributes();
  }

  @action 
  error() {
    this.flashes.addError('An error occured while saving.');
  }
}
