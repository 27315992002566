import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { readOnly } from '@ember/object/computed';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import CustomAction from 'crakn/mixins/custom-action';
import { cleanPrice } from 'crakn/utils/data-helpers';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  contractDate: validator('presence', {
    presence: true,
    message:'Date can\'t be empty or invalid'
  }),
  purchaser: validator('presence', true)
});

export default Model.extend(CustomAction, Validations, {
  session: service(),

  adjustedTax: attr('price', { defaultValue: 0 }),
  adjustmentsTotal: attr('price', { defaultValue: 0 }),
  adjustmentsWithoutTax: attr('price', { defaultValue: 0 }),
  archivedAt: attr('moment-datetime'),
  balance: attr('price', { defaultValue: 0 }),
  canCreateContractCopy: attr('boolean', { defaultValue: true }),
  cashAdvanceTotal: attr('price'),
  contractDate: attr('moment-datetime'),
  contractEsignatureStatus: attr('string'),
  createdAt: attr('moment-datetime'),
  creditRefundsTotal: attr('price'),
  customId: attr('string'),
  deletable: attr('boolean', { defaultValue: false }),
  discountsTotal: attr('price'),
  doNotExport: attr('boolean'),
  dueDate: attr('moment-date'),
  exported: attr('boolean'),
  exportedAt: attr('moment-datetime'),
  gplLocked: attr('boolean'),
  hasActiveEsignContracts: attr('boolean'),
  isBadDebt: attr("boolean"),
  badDebtAt: attr('moment-datetime'),
  interestsTotal: attr('price'),
  isContract: attr('boolean'),
  isMigrated: attr('boolean'),
  latestPostingPeriodLockedAt: attr('moment-datetime'),
  locationLocked: attr('boolean'),
  lockedReason: attr('string'),
  locked: attr('boolean'),
  logoId: attr('string'),
  merchandiseTotal: attr('price'),
  name: attr('string'),
  netsuiteId: attr('string'),
  nonTaxableTotal: attr('price'),
  nonTaxDiscountsTotal: attr('price'),
  overpaymentRefundsTotal: attr('price'),
  packageDiscount: attr('price'),
  paymentsTotal: attr('price', { defaultValue: 0 }),
  postableSelectionValidationErrors: attr(),
  posted: attr('boolean'),
  postedAt: attr('moment-datetime'),
  purchaserRelationship: attr('string'),
  qbdId: attr('string'),
  qboId: attr('string'),
  reasonForUnposting: attr('string'),
  refundsTotal: attr('price'),
  requiredPurchaseReason: attr('string'),
  salesTax: attr('price', { defaultValue: 0 }),
  serviceTotal: attr('price'),
  sfgsLastGenerated: attr('moment-datetime'),
  shippingTotal: attr('price'),
  soaNote: attr('string'),
  specialChargeTotal: attr('price'),
  specialTax: attr('price', { defaultValue: 0 }),
  taxTotal: attr('price', { defaultValue: 0 }),
  statementLastGenerated: attr('moment-datetime'),
  subtotal: attr('price'),
  subtotalAfterDiscounts: attr('price'),
  taxableTotal: attr('price'),
  taxDiscountsTotal: attr('price'),
  total: attr('price'),
  type: attr('string'),
  unpostedAt: attr('moment-datetime'),
  uuid: attr('string'),
  coaIntegrationName: attr('string'),

  salesTaxRate: readOnly('kase.location.stateSalesTaxRate'),

  selectionPurchaser: null,
  savedChanges: false,

  blankContract: belongsTo('blankContract'),
  exportedBy: belongsTo('user', { async: true }),
  kase: belongsTo('kase', { async: true }),
  kaseFinancial: belongsTo('kase-financial'),
  location: belongsTo('location'),
  postedBy: belongsTo('user', { async: true }),
  purchaser: belongsTo('person', { async: true }),
  staffMember: belongsTo('user', { async: true }),
  unpostedBy: belongsTo('user', { async: true }),
  lastUsedContractTemplate: belongsTo('v2/contractTemplate'),
  badDebtBy: belongsTo('user', { async: true }),

  adjustments: hasMany('adjustment', { async: true, inverse: 'selection' }),
  customProducts: hasMany('selectionCustomProduct', { inverse: 'selection' }),
  discounts: hasMany('discount', { async: true, inverse: 'selection' }),
  esignedContracts: hasMany('esignedContract'),
  interests: hasMany('v2/interests', { async: true }),
  packages: hasMany('selectionPackage', { inverse: 'selection' }),
  payments: hasMany('payment', { async: true, inverse: 'selection' }),
  products: hasMany('selectionProduct', { inverse: 'selection' }),
  refunds: hasMany('refund', { async: true, inverse: 'selection' }),
  selectionPackageSlotProducts: hasMany('v2/selection-package-slot-product', { async: true }),

  isMiscSale: computed('type', function() {
    const type = this.type;

    return type === 'misc-selection' || type === 'misc_selection' || type === 'MiscSelection'
  }),

  isKaseSelection: computed('type', function() {
    const type = this.type;

    return type === 'kase-selection' || type === 'kase_selection' || type === 'KaseSelection'
  }),

  isEmptySelection: computed('products.length', 'packages.length', function() {
    return (
      get(this, 'products.length') === 0 && get(this, 'packages.length') === 0
    );
  }),

  balanceColor: computed('balance', function() {
    const balance = this.balance;

    return balance >= 0 ? 'green' : 'red';
  }),

  // Slim category data on product for use in invoice-category-summary component
  invoiceCategoryData: computed(
    'products.length',
    'packages.length',
    'customProducts.length',
    function() {
      const invoiceCategoryData = [];

      this.packages.forEach(function(selectionPackage) {
        const packageName = get(selectionPackage, 'package.name');
        const quantity = get(selectionPackage, 'quantity');

        get(selectionPackage, 'products').forEach(function(packageProduct) {
          const price = cleanPrice(get(packageProduct, 'price'));

          const productTotal = price * quantity;
          const invoiceCategoryId = get(packageProduct, 'invoiceCategory.id');

          // Make sure invoiceCategory key exists
          if (!invoiceCategoryData[invoiceCategoryId]) {
            invoiceCategoryData[invoiceCategoryId] = [];
          }

          // Push a slim product to the invoiceCategory
          invoiceCategoryData[invoiceCategoryId].push({
            id: get(packageProduct, 'id'),
            name: get(packageProduct, 'name'),
            price,
            quantity,
            total: productTotal,
            packageName
          });
        });
      });

      this.products.forEach(function(selectionProduct) {
        let selectionProductPrice = get(selectionProduct, 'price');
        selectionProductPrice = cleanPrice(selectionProductPrice);

        const quantity = get(selectionProduct, 'quantity');
        const selectionProductTotal = selectionProductPrice * quantity;
        const invoiceCategoryId = get(
          selectionProduct,
          'product.invoiceCategory.id'
        );

        // Make sure invoiceCategory key exists
        if (!invoiceCategoryData[invoiceCategoryId]) {
          invoiceCategoryData[invoiceCategoryId] = [];
        }

        // Push a slim product to the invoiceCategory
        invoiceCategoryData[invoiceCategoryId].push({
          id: get(selectionProduct, 'id'),
          name: get(selectionProduct, 'product.name'),
          price: selectionProductPrice,
          total: selectionProductTotal,
          quantity
        });
      });

      this.customProducts.forEach(function(customProduct) {
        let customProductPrice = get(customProduct, 'price');
        customProductPrice = cleanPrice(customProductPrice);
        const quantity = get(customProduct, 'quantity');
        const customProductTotal = customProductPrice * quantity;
        const invoiceCategoryId = get(customProduct, 'invoiceCategory.id');

        // Make sure invoiceCategory key exists
        if (!invoiceCategoryData[invoiceCategoryId]) {
          invoiceCategoryData[invoiceCategoryId] = [];
        }

        // Push a slim product to the invoiceCategory
        invoiceCategoryData[invoiceCategoryId].push({
          id: get(customProduct, 'id'),
          name: get(customProduct, 'name'),
          price: customProductPrice,
          total: customProductTotal,
          quantity
        });
      });

      return invoiceCategoryData;
    }
  ),

  invalidate() {
    return this.customAction('invalidate', 'POST', {});
  }
});
