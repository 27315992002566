import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';

export default Component.extend({
  session: service(),
  store: service(),
  pastDueKasePage: 1,
  model: null,
  perPage: 5,

  actions: {
    loadMorePastDueKases() {
      const page = this.incrementProperty('pastDueKasePage');
      this.store
        .query('overdueKase', {
          per_page: this.perPage,
          page,
          past_due: true,
          dashboard: true
        })
        .then(results => {
          get(this, 'model.content').pushObjects(results.get('content'));
        });
    }
  }
});
