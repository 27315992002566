import Fragment from 'ember-data-model-fragments/fragment';
import { attr } from '@ember-data/model';
import {
  fragmentArray,
  fragment,
  fragmentOwner
} from 'ember-data-model-fragments/attributes';

export default class V2OnlinePlannerPageModel extends Fragment {
  @attr('string') title;
  @attr('string') name;
  @attr('boolean') hide;
  @fragment('v2/online-planner-forms') forms;
  @fragment('v2/online-planner-page-media') media;
  @fragmentArray('v2/online-planner-page') pages;
  @fragmentArray('v2/online-planner-section') sections;
  @fragmentOwner() parent;

  get fields() {
    return this.sections
      .map((section) => {
        return section.content.filter((element) => {
          return ['input', 'select', 'date', 'checkbox', 'text-area', 'img', 'radio'].includes(
            element.type
          );
        });
      })
      .flat();
  }

  get reviewSections() {
    const types = ['input', 'select', 'date', 'checkbox', 'text-area', 'img', 'radio'];
    return this.sections
      .map((section) => {
        return section.content.filter((element) => {
          return types.includes(element.type) || element.style === 'sub-header';
        });
      })
      .flat()
      .filter((x) => x.hide === false);
  }
}
