import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class={{this.status.color}}>\n  {{this.status.text}}\n</span>\n", {"contents":"<span class={{this.status.color}}>\n  {{this.status.text}}\n</span>\n","moduleName":"crakn/components/g/crakn/integrations/card/item/status-content.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/integrations/card/item/status-content.hbs"}});
import Component from '@glimmer/component';
import moment from 'moment';

export default class GCraknIntegrationCardItemStatusContent extends Component {

  get status() {
    if (!this.args.integration.exportedAt) {
      return {color: 'yellow', text: 'Not Exported'};
    }

    switch (this.args.integration.name) {
      case 'precoa':
        return {
          color: this.args.integration.exportStatus === 'Pending' ? 'blue' : 'green',
          text: `Updated at: ${moment(this.args.integration.exportUpdatedAt).format('LLL')}`
        };
      case 'tribute-website':
        return {
          color: 'green',
          text: `Updated at: ${moment(this.args.integration.exportUpdatedAt).format('LLL')}`
        };
      default:
        return {
          color: 'green',
          text: `Exported at: ${moment(this.args.integration.exportedAt).format('LLL')}`
        };
    }
  }
}
