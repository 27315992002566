import Route from '@ember/routing/route';

export default class AdminVehiclesEditRoute extends Route {
  model(params) {
    return this.store.findRecord('vehicle', params.vehicle_id);
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const events = this.store.query('event', {
      vehicle_id: model.get('id'),
      per_page: 10,
      order: 'DESC'
    });

    controller.set('events', events);
  }
}
