import SelectBox from './select-box';

// The sole purpose of this component is to have a select-box that doesn't
// trigger a "change" event when it's rendered. The select-box's
// `didInsertElement` was added to fix an issue, and then produced a bunch of
// other issues, but we were already committed, so this is basically the
// original select-box, which we'll use instead of the other whenever it
// misbehaves. When there's time, all the other code can be moved here, and
// do-si-do the names, to end up with the version that triggers the event only
// where necessary.

export default SelectBox.extend({
  layoutName: 'components/form/select-box',

  didInsertElement() {
    // Override select-box's hook
  }
});
