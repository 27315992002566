import Component from '@ember/component';

export default Component.extend({
  classNames: ['crakn-task-edit'],
  task: null,
  showDialog: false,

  actions: {
    updateOrderAndSave(order) {
      this.task.taskOrder = order;
      this.sendAction('save', this.task);
    },

    save() {
      this.sendAction('save', this.task);
    },

    delete() {
      this.sendAction('delete', this.task);
    }
  }
});
