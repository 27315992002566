import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service'
import { preventFormDefault } from 'ember-cli-prevent-form-default';
import moment from 'moment';

export default class KasesEditPreneedController extends Controller {
  @service router;
  @service flashes;

  flashSavedPolicy(type) {
    this.flashes.addSuccess(`Great job! You updated the ${type}!`);
  }

  @action
  addInsurancePolicy() {
    const preneed = this.model;

    preneed.save().then(() => {
      this.flashes.addSuccess('Preneed information saved!');
      this.store.createRecord('insurance-policy', { preneed });
    });
  }
  
  @action
  setEstablishedAt(trust, value) {
    trust.set('establishedAt', moment(value));
  }

  @action
  addTrust() {
    const preneed = this.model;
    
    preneed.save().then(() => {
      this.flashes.addSuccess('Preneed information saved!');
      this.store.createRecord('trust', { preneed });
    });
  }

  @action
  deletePolicy(policy) {
    if (policy.get('isNew')) {
      policy.deleteRecord();
    } else {
      policy.archivedAt = moment();
      policy.save();
    }
  }

  @action
  deleteTrust(trust) {
    if (trust.get('isNew')) {
      trust.deleteRecord();
    } else {
      trust.archivedAt = moment();
      trust.save();
    }
  }

  @action
  rollbackPolicy(policy) {
    policy.rollbackAttributes();
  }

  @action
  rollbackTrust(trust) {
    trust.rollbackAttributes();
  }

  // returns a promise to properly chain with beneficiaries
  @action
  savePolicy(policy) {
    return policy.save()
                 .then(() => this.flashSavedPolicy('insurance policies'))
                 .catch(() => {
                    this.flashes.addError('Oops.  Something went wrong.')
                 });
  }

  @action
  @preventFormDefault
  savePreneed() {
    const preneed = this.model;

    preneed.save()
           .then(() => {
             this.flashes.addSuccess('Great job! You updated the preneed information');

             if (this.kase.status == 'preneed') {
              this.router.transitionTo('authenticated.kases.manage.edit.personal');
             } else {
               this.router.transitionTo('authenticated.kases.manage.edit.deceased');
             }
           });
  }

  @action
  saveTrust(trust) {
    trust.save()
         .then(() => this.flashSavedPolicy('trusts'))
         .catch(() => {
           this.flashes.addError('Oops.  Something went wrong.')
         });
  }
}
