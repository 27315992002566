import Component from '@ember/component';
import { run } from '@ember/runloop';
import { observer, computed, set, get } from '@ember/object';
import FormInput from 'crakn/mixins/form-input';
import { isString } from 'lodash-es/lang';

export default Component.extend(FormInput, {
  classNames: ['input'],
  classNameBindings: ['hasValue:input--has-value', 'styleClass'],
  value: null,

  placeholder: null,
  style: 'standard',
  placeholderLabel: 'Select One',
  placeholderValue: '',

  options: null,

  selection: null,

  isFlatArray: null,

  key: 'id',

  sortBy: 'name',

  hasValue: computed('value', function() {
    return !!this.value;
  }),

  styleClass: computed('style', function() {
    const style = this.style;

    return `input--${style}`;
  }),

  setValue() {
    let selection = this.selection;
    const key = this.key;
    const value = this.value;
    const firstObject = get(this, 'options.firstObject');
    let hasSelection;

    if (selection !== null && selection !== undefined) {
      selection = get(selection, 'content') || selection;
      hasSelection = !!get(this, `selection.${key}`);
    }

    if (!value && hasSelection) {
      set(this, 'value', get(selection, key));
    } else if (
      !value &&
      !hasSelection &&
      !this.placeholderLabel &&
      firstObject
    ) {
      set(this, 'value', get(firstObject, key));
    }

    set(this, 'isFlatArray', isString(firstObject));
  },

  willInsertElement() {
    this._super(...arguments);

    run.once(this, this.setValue);
  },

  optionsDidUpdate: observer('options.length', function() {
    run.once(this, this.setValue);
  }),

  // TODO: REMOVE THIS ASAP. PLEASE!
  // This hack was added because we counted on that event being triggered
  // on render. That was the behavior prior to the Ember upgrade.
  // Without this, the location selector will display the first location as if
  // it were set, but it won't actually be set.
  didInsertElement() {
    this
      .element
      .querySelector('select')
      .dispatchEvent(new Event('change', { bubbles: true }));
  },

  getSelection() {
    const value = this.value;
    const key = this.key;
    const options = this.options;
    const selection = options ? options.findBy(key, value) : '';

    if (options && get(options, 'length')) {
      set(this, 'selection', selection);
    }
  },

  actions: {
    selectionDidChange(value) {
      set(this, 'value', value);
      run.once(this, 'getSelection');
      this.sendAction('resetPagination');
      this.sendAction('onChange', value);
      this.sendAction('update', value);
    }
  }
});
