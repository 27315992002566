import Model, { belongsTo, attr } from '@ember-data/model';
import { alias } from '@ember/object/computed';
import { cleanPrice } from 'crakn/utils/data-helpers';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  quantity: validator('presence', true),
});

export default class SelectionProductModel extends Model.extend(
  Validations
) {
  @attr('price') price;
  @attr('number', { defaultValue: 1 }) quantity;
  @attr('boolean') exported;
  @attr('string') notes;
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') updatedAt;
  
  /** Additional info */
  @attr('string') manufacturer;
  @attr('string') model;
  @attr('string') material;
  @attr('string') interior;

  @attr('boolean', { defaultValue: true } ) pullFromInventory;
  @attr('boolean', { defaultValue: false } ) pullFromBeaconfpInventory;
  @attr('boolean', { defaultValue: false } ) has_inventory;

  @belongsTo('product', { async: true }) product;
  @belongsTo('selection', { async: true, polymorphic: true }) selection;

  @alias('product.name') name;
  @alias('product.isTaxed') isTaxed;

  get multipliedPrice() {
    const price = cleanPrice(this.price);

    return cleanPrice(this.quantity * price);
  }
}
