import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default Route.extend({
  session: service(),
  flashes: service(),
  location: service(),

  model() {
    const kase = this.modelFor('authenticated.kases.manage');
    const kaseFinancial = get(kase, 'kaseFinancial');
    const clientInfo = get(
      this,
      'session.session.authenticated.client.client_info_id'
    );
    return RSVP.hash({
      kase,
      kaseFinancial,
      clientInfo: this.store.findRecord('clientInfo', clientInfo),
      selections: this.store.query('selection', {
        kase_id: get(kase, 'id'),
        per_page: 1000,
        type: 'KaseSelection'
      })
    });
  },

  actions: {
    refreshKaseFinancial() {
      // Updating the kase will update kase financial (async model relationship) and kase location locks
      this.store.findRecord('kase', this.currentModel.kase.id);
      this.refresh();
    },

    createNewAddon(e) {
      e.target.disabled = true; // avoid double-click issues

      this.store
        .createRecord('selection', {
          name: `Add-on ${get(this.currentModel, 'selections.length')}`,
          kaseFinancial: get(this.currentModel, 'kaseFinancial'),
          type: 'KaseSelection',
          contractDate: moment()
        })
        .save()
        .then(addon => {
          this.refresh();
          this.flashes.addSuccess('Add-on created!');
          this.transitionTo('authenticated.kases.manage.financials.selection', {
            queryParams: { selection_id: get(addon, 'id') }
          }).then(() => {
            e.target.disabled = false;
          });
        })
        .catch(() => {
          this.flashes.addError(
            'Something went wrong, please refresh your browser.'
          );
          e.target.disabled = false;
        });
    },

    updateLocation(locationId) {
      const location = get(this, 'location.all').findBy('id', locationId);
      const kase = this.currentModel.kase;

      set(kase, 'location', location);
      kase.save().then(() => this.refresh());
    }
  }
});
