import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'crakn/config/environment';

export default class AuthenticatedKasesManageIntegrationsTributeVideoController extends Controller {
  @service api;
  @service flashes;
  @service intl;
  @service store;
  @service browser;

  @tracked kase = null; // Set up in the route.
  @tracked albumId = null;

  get missingLocationEmailAndStateMessage() {
    return this.intl.t('authenticated.kases.manage.integrations.tribute-video.instructions.missingData.location', {
      location: this.kase.get('location.name'),
    });
  }

  @computed('kase.id')
  get albums() {
    return this.store.query('v2/album', {
      include: 'cover-photo',
      kase_id: this.kase.id,
    });
  }

  get kaseLocationHasAddressAndEmail() {
    return this.kase.get('location.email') && this.kase.get('location.hasBasicAddressData') ? true : false;
  }

  get albumCoverPhoto() {
    if (this.albumId) {
      const album = this.store.peekRecord('v2/album', this.albumId);
      return album.get('coverPhoto');
    } else {
      return null;
    }
  }

  get tributeVideoStatus() {
    const status = this.model.get('tributeVideoStatus');

    return status || this.intl.t('authenticated.kases.manage.integrations.tribute-video.statuses.video.listOfStatuses.noStatus');
  }

  refreshRoute() {
    this.send('refreshModel');
  }

  postRequestFormBuildAndSubmission(payload) {
    const { callbacks, funeral_home, obituary, token, user } = payload;
    const action = 'EDIT_VIDEO';

    const form = document.createElement('form');
    form.action = config.tributeVideoApiUrl;
    form.method = 'POST';

    if (!this.browser.isIOS) {
      form.target = '_blank';
    }

    const input = document.createElement('input');
    input.name = 'token';
    input.type = 'hidden';
    input.setAttribute('value', token);

    const textarea = document.createElement('textarea');
    textarea.name = 'payload';
    textarea.style.display = 'none';
    textarea.innerHTML = JSON.stringify({
      action,
      callbacks,
      funeral_home,
      obituary,
      user,
    });

    form.appendChild(input);
    form.appendChild(textarea);
    document.body.appendChild(form);

    form.submit();
    form.remove();
  }

  @action
  routeToTributeVideo() {
    const params = { id: this.kase.id };

    if (this.albumId && this.albumId !== 'no-album') {
      params.album_id = this.albumId ;
    }

    this.api.json
        .get('/tribute_video/export', { params })
        .then((response) => {
          this.postRequestFormBuildAndSubmission(response.parsedJson.payload);
          this.refreshRoute();
        })
        .catch(() => {
          this.flashes.addError('Oops! There was an error when attempting to export the album.');
        });
  }

  @action
  async getVideoStatus() {
    const status = await this.api.json
      .get('/tribute_video/request_status', {params: { id: this.kase.id }})
      .catch(() => {
        this.flashes.addError('Oops! Something went wrong.');
        return;
      });

    if (status?.parsedJson?.video_status === 'Published') {
      const kase = await this.store
        .findRecord('kase', this.kase.id)
        .catch(() => {
          this.flashes.addError('Oops! Something went wrong.');
          return;
        });

      this.kase = kase;
    }

    this.flashes.addSuccess('Video status updated!');
    this.refreshRoute();
  }

  @action
  editVideo() {
    this.api.json
      .get('/tribute_video/edit_video', {
        params: { id: this.kase.id }
      })
      .then((response) => {
        this.postRequestFormBuildAndSubmission(response.parsedJson.payload);
        this.refreshRoute();
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.kases.manage.integrations.tribute-video.links.editVideo.errorMessage'));
      });
  }
}
