import Model, { attr, belongsTo } from '@ember-data/model';
import moment from 'moment';

export default class V2EventModel extends Model {
  @attr('boolean') noEndTime;
  @attr('number', { defaultValue: 60 }) duration;
  @attr('string') name;
  @attr('moment-datetime') startsAt;

  @belongsTo('v2/place') place;

  get endsAt() {
    if (!this.startsAt) {
      return null;
    }
    return moment(this.startsAt).add(this.duration, 'minutes');
  }
}
