import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class KasesManageTasksController extends Controller {
  queryParams = ['redirectPath'];
  redirectPath = 'authenticated.kases.manage.edit.info';

  @action
  scrollToTop() {
    window.scrollTo(0, 0);
  }  
}
