import Component from '@ember/component';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';

export default Component.extend({
  classNames: ['checkbox'],
  classNameBindings: [
    'hasValue:checkbox--has-value',
    'styleClass',
    'disabled:checkbox--disabled'
  ],
  tagName: 'label',
  style: 'standard',

  disabled: null,
  value: null,
  label: null,

  // Optional actions
  update() {},

  hasValue: computed('value', function() {
    return !!this.value;
  }),

  styleClass: computed('style', function() {
    const style = this.style;

    return `checkbox--${style}`;
  }),

  qaClass: computed('label', 'placeholder', function() {
    const label = this.label;

    return dasherize(`qa-${label}`);
  })
});
