import Route from '@ember/routing/route';

export default class CraknClientsEditSoaConfigRoute extends Route {
  model() {
    return this.modelFor('authenticated.crakn.clients.edit');
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.set('client', model);
  }
}
