import Controller from '@ember/controller';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-mixins
import PersonSmartEntry from 'crakn/mixins/person-smart-entry';
import { preventFormDefault } from 'ember-cli-prevent-form-default';

export default class KasesManageEditInfoController extends Controller.extend(PersonSmartEntry) {
  @service clientRouting;
  @service flashes;
  @service location;
  @service router;
  @service session;
  @service staff;
  @service store;
  
  smartEntryPersonMeta = {
    mother: { key: 'model.mother', label: 'Mother' },
    father: { key: 'model.father', label: 'Father' },
    spouse: { key: 'model.spouse', label: 'Spouse' },
    purchaser: { key: 'model.purchaser', label: 'Purchaser' },
    informant: {
      key: 'model.informant',
      label: 'Informant',
      srcOptions: ['purchaser', 'mother', 'father', 'spouse'],
    },
  }

  get staffMemberStaffList() {
    return this.session.currentClient.only_funeral_directors_can_be_lead_staff_members ? 'funeralDirectors' : 'active';
  }

  get displayNotFuneralDirectorValidation() {
    return this.model.staffMember.isTruthy
           && !this.model.staffMember.get('isFuneralDirector')
           && this.session.currentClient.only_funeral_directors_can_be_lead_staff_members;
  }

  get disableCustomId() {
    return this.session.currentClient.force_auto_kase_numbering && !this.session.currentUser.canEditKaseCustomId;
  }

  get showGenerate() {
    return this.session.currentClient.auto_kase_numbering &&
             (!this.session.currentClient.force_auto_kase_numbering ||
              !this.model.customId);
  }

  get unlockCaseDisabled() {
    return (
      this.model.asdExportedAt !== null ||
      this.model.hasPostedSelections()
    );
  }

  get addKaseTagDisabled() {
    if (
        (this.session.currentClient.can_use_single_kase_tag && this.model.kaseTags.length >= 1)
        ||
        this.model.tagsLocked) {
      return true;
    } else {
      return false;
    }
  }

  get contractText() {
    return this.session.currentClient.relabel_contract_to_invoice ? 
    'invoice' : 'contract';
  }

  @action
  addKaseTag(tag) {
    const duplicate = this.model.kaseTags.reduce((acc, i) => {
      return (
        acc ||
        (i.kase.get('id') === this.model.get('id') &&
          i.tag.get('id') === tag.id)
      );
    }, false);
    
    if (duplicate) {
      return;
    }

    const newTag = this.store
    .createRecord('kase-tag', {
      kase: this.model,
      tag,
    });
    
    newTag
      .save()
      .then(() => {
        this.flashes.addSuccess('Case tag added')
      })
      .catch(() => {
        newTag.destroyRecord()
        this.flashes.addError('Something went wrong creating this case tag')
      });
  }

  @action
  addVendor(place) { 
    this.store.findRecord('place', place.id)
        .then((place) => {
          this.model.vendor = place;
          this.model.save()
                    .then(() => {
                      this.flashes.addSuccess('Saved! Successfully added Vendor.')
                    })
                    .catch(() => {
                      this.flashes.addError('Something went wrong. Please try again.')
                    });
        })
  }

  @action
  identify() {
    this.model.identify().then((resp) => {
      this.model.customId = resp.kase.custom_id;
    });
  }

  @action
  removeKaseTag(kaseTag) {
    kaseTag.destroyRecord();
  }

  @action
  removeVendor() {
    this.model.vendor = null;
    this.model.save()
              .then(() => {
                this.flashes.addSuccess('Saved! Vendor removed.')
              })
              .catch(() => {
                this.flashes.addError('Something went wrong. Please try again.')
              });
  }

  @action
  @preventFormDefault
  saveInfo() {
    this.model.save().then(() => {
      this.flashes.addSuccess('Great job! You updated the case information')
    
      const route = this.clientRouting.manageRouteTransition(
        'authenticated.kases.manage.edit.info'
      );
      
      this.router.transitionTo(route, this.model);
    });
  }

  @action
  unlockCase() {
    if (
      confirm(
        `Are you sure you want to unlock this case? This will clear all products, packages, payments, and adjustments in the ${this.contractText} and add-ons.`
      )
    ) {
      this.model
        .unlock()
        .then(() => {
          this.flashes.addSuccess('Your case has been unlocked');
          window.location.reload(true);
        })
        .catch(() => {
          this.send(
            'flashError',
            'Oops, we were unable to unlock this case.'
          );
        });
    }
  }

  @action
  updateKaseLocation(location) {
    if (isEmpty(location)) {
      return;
    } else {
      this.model.location = location;
      this.model.save();
    }
  }

  @action
  saveContact(contactType) {
    this.model
      .get(contactType)
      .then(contact => {
        contact.save().then(() => {
          this.model
            .save()
            .then(() => this.flashes.addSuccess('Contact has been saved!'));
        });
      });
  }

  @action
  removeInformant(role) {
    const kasePerson = this.model.get('kasePeople').find(kasePerson => {
      return (
        kasePerson.get('role') === role.replace(/-/, '_')
      );
    });

    if (kasePerson) {
      kasePerson.destroyRecord();
      this.model
        .save()
        .then(() => this.flashes.addSuccess('Case has been updated!'))
        .catch(() =>
          this.flashes.addError('There was a problem saving this case!')
        );
    }
  }
}
