import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminTributePayEmailTemplatesEditRoute extends Route {
  @service store;

  model(params) {
    return this.store.findRecord('v2/email-template', params.id)
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.setProperties({
      bodyRaw: model.body.replace( /(<([^>]+)>)/ig, ''),
      setText: model.body
    });
  }
}
