import Route from '@ember/routing/route';
import RSVP from 'rsvp';

export default Route.extend({
  layout: 'blank',
  isFullscreenActive: true,

  model(params) {
    const id = params.esignature_id;
    const opts = {
      adapterOptions: { token: params.token }
    };

    const esignature = this.store
      .adapterFor('esignatures')
      .findRecord(this.store, 'esignature', id, opts);

    return RSVP.hash({
      esignature
    });
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.setProperties(model);
  }
});
