import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { set } from '@ember/object';
import Toolbelt from 'crakn/mixins/component-toolbelt';

export default Component.extend(Toolbelt, {
  classNames: ['list__item', 'list__item--wrap', 'bg--custom'],
  tagName: 'li',

  idKey: readOnly('model.id'),

  model: null,
  isEditing: false,
  collectsTax: false,

  actions: {
    removeCustomProduct() {
      this.attrs.removeCustomProduct(this.model);
    },

    revertCustomProduct() {
      set(this, 'isEditing', false);
      this.attrs.revertCustomProduct(this.model);
    },

    saveCustomProduct() {
      this.attrs.saveCustomProduct(this.model).then(() => {
        set(this, 'isEditing', false);
      });
    }
  }
});
