import Serializer from './application';
import { v4 as uuidv4 } from 'uuid';

export default class V2CustomFieldConfigSerializer extends Serializer {
  normalizeResponse(store, _primaryModelClass, payload, id, requestType) {

    const fieldMap = (field, configID) => {
      return {
        data: field.map((field) => {
          return {
            attributes: { ...field },
            id: field.id,
            type: 'v2/custom-field',
            relationships: {
              customFieldConfig: {
                data: {
                  id: configID,
                  type: 'v2/custom-field-config'
                }
              }
            }
          };
        })
      }
    };

    if (requestType === 'findRecord') {
      const customFieldAttrsJson = fieldMap(payload.data.attributes['custom-fields'], payload.data.id);
      store.push(customFieldAttrsJson);
    } else if (requestType === 'findAll' || requestType === 'query') {
      payload.data.forEach(config => {
        const customFieldAttrsJson = fieldMap(config.attributes['custom-fields'], config.id);
        store.push(customFieldAttrsJson);
      });
    }

    const resp = super.normalizeResponse(...arguments);
    return resp;
  }

  serialize(snapshot, _options) {
    const json = super.serialize(...arguments);

    json.data.attributes = {
      'custom-fields': snapshot._hasManyRelationships.customFields.map(
        (record) => {
          const recordAttrs = record._attributes;

          if (record.id) {
            recordAttrs.id = record.id
            return recordAttrs;
          } else {
            recordAttrs.id = uuidv4();
            return recordAttrs;
          }
        }
      )
    };

    return json;
  }
}
