import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Field::Select\n    @placeholder={{t\n        \"authenticated.admin.online-planner.edit.page.fields.defaultValueField.label\"}}\n    @placeholderLabel={{t\n        \"authenticated.admin.online-planner.edit.page.fields.defaultValueField.placeholder\"}}\n    @placeholderValue=\"\"\n    @value={{this.field.value}}\n    @onChange={{this.onChange}}\n    @validationMessages={{t\n        \"authenticated.admin.online-planner.edit.page.fields.defaultValueField.warning\"}}\n    @showValidations={{eq this.field.value \"\"}}>\n  {{#each this.field.options as |item|}}\n    <option selected={{eq item.value this.field.value}} value={{item.value}}>{{item.label}}</option>\n  {{/each}}\n</G::Crakn::Field::Select>\n", {"contents":"<G::Crakn::Field::Select\n    @placeholder={{t\n        \"authenticated.admin.online-planner.edit.page.fields.defaultValueField.label\"}}\n    @placeholderLabel={{t\n        \"authenticated.admin.online-planner.edit.page.fields.defaultValueField.placeholder\"}}\n    @placeholderValue=\"\"\n    @value={{this.field.value}}\n    @onChange={{this.onChange}}\n    @validationMessages={{t\n        \"authenticated.admin.online-planner.edit.page.fields.defaultValueField.warning\"}}\n    @showValidations={{eq this.field.value \"\"}}>\n  {{#each this.field.options as |item|}}\n    <option selected={{eq item.value this.field.value}} value={{item.value}}>{{item.label}}</option>\n  {{/each}}\n</G::Crakn::Field::Select>\n","moduleName":"crakn/components/g/online-planner/default-value-field.hbs","parseOptions":{"srcName":"crakn/components/g/online-planner/default-value-field.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';


export default class GOnlinePlannerDefaultValueField extends Component {
  @service flashes;
  @service store;
  @service session;
  @service intl;

  get page() {
    return this.args.page;
  }

  get field() {
    switch (this.page.name) {
      case 'plan-type':
        return this.page.fields.find(field => field.name === 'plan.type');
      case 'disposition-type':
        return this.page.fields.find(field => field.name === 'disposition.type');
      case 'planning-for':
        return this.page.fields.find(field => field.name === 'plan_for');
      default:
        return undefined;
    }
  }

  @action
  onChange(value) {
    if (this.page.name === 'plan-type' && this.field.name === 'plan.type') {
      const planForPage = this.page.parent.pages.content[2];
      const planForField = planForPage.fields.find(field => field.name === 'plan_for');

      if (value === 'immediate') {
        this.field.set('value', value);
        planForField.set('value', 'other');
        return;
      }
    }

    this.field.set('value', value);
  }
}
