import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default class AdminFormsRoute extends Route {
  @service session;
  @service router;

  queryParams = {
    page: {
      refreshModel: true
    }
  };

  beforeModel() {
    if (this.session.currentClient.can_view_form_in_tfe) {
      this.router.transitionTo('authenticated.admin.manage.tfe.forms.index');
    }
  }

  model(params) {
    return this.store.query('blankForm', {
      current_client: true,
      page: params.page
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'page', 1);
    }
  }
}
