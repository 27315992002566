import Model, { belongsTo, attr, hasMany } from '@ember-data/model';

export default class V2VeteranInfoModel extends Model {
  @attr('boolean') airForceCross;
  @attr('boolean') awardsReceived;
  @attr('boolean') awardsReceived;
  @attr('boolean') bronzeStar;
  @attr('boolean') dstFlyingCross;
  @attr('boolean') dstServiceCross;
  @attr('boolean') federalFormsComplete;
  @attr('boolean') generalInfoComplete;
  @attr('boolean') hasGovernmentOrEmployerBurialContribution;
  @attr('boolean') hasIncurredBurialExpenses;
  @attr('boolean') hasIncurredExpensesForRemainsTransportation;
  @attr('boolean') hasPreviouslyReceivedAllowance;
  @attr('boolean') headstoneInfoComplete;
  @attr('boolean') isBuriedInNationalCemetery;
  @attr('boolean') isFlag;
  @attr('boolean') isMilitaryHonors;
  @attr('boolean') isRemainsNotBuried;
  @attr('boolean') isRequestedPresence;
  @attr('boolean') isSeekingNonServiceConnectedBenefits;
  @attr('boolean') isSelectedMonument;
  @attr('boolean') isServiceConnectedDeath;
  @attr('boolean') isTemporaryMarker;
  @attr('boolean') medalOfHonor;
  @attr('boolean') navyCross;
  @attr('boolean') purpleHeart;
  @attr('boolean') serviceRecordsComplete;
  @attr('boolean') silverStar;

  @attr('moment-date') dateHeadstoneOrdered;
  @attr('moment-datetime') honorsTime;

  @attr('string') actualBurialCost;
  @attr('string') branchPresenceRequested;
  @attr('string') burialContributionAmount;
  @attr('string') flagInstructions;
  @attr('string') headstoneEmblem;
  @attr('string') headstoneEngraving;
  @attr('string') headstoneMaterial;
  @attr('string') incurredExpensesForRemainsTransportationAmount;
  @attr('string') marker;
  @attr('string') militaryOrganizations;
  @attr('string') serviceNumber;
  @attr('string') vaNumber;
  @attr('string') xcNumber;

  @belongsTo('v2/kase', { async: true }) kase;
  @belongsTo('v2/place', { async: true }) honorsPlace;

  @hasMany('v2/us-service-record', { async: true }) usServiceRecords;
}
