import Quill from 'quill';
const Link = Quill.import('formats/link');
const allowedProtocols = ['http', 'https', 'mailto'];
Link.PROTOCOL_WHITELIST = allowedProtocols;

export function initialize() {
  class CustomLinkSanitizer extends Link {
    static sanitize(url) {
      const sanitizedUrl = super.sanitize(url);

      if (!sanitizedUrl) {
        return sanitizedUrl;
      }

      const hasWhitelistedProtocol = this.PROTOCOL_WHITELIST
        .some(protocol => sanitizedUrl.startsWith(protocol));

      return hasWhitelistedProtocol ? sanitizedUrl : `https://${sanitizedUrl}`;
    }
  }
  Quill.register(CustomLinkSanitizer, true);
}

export default {
  name: 'quill-link-sanitizer',
  initialize
};
