import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  layoutName: computed('icon', function() {
    return `components/icon/${this.icon}`;
  }),
  classNames: ['icon'],
  classNameBindings: ['icon'],

  icon: null
});
