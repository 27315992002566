import Model, { attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: validator('presence', true),
});

export default class V2CauseOfDeathModel extends Model.extend(Validations) {
  @attr('string') name;
  @attr('moment-datetime') archivedAt;
}
