import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { get } from '@ember/object';
import RSVP from 'rsvp';

export default class UserSignatureRoute extends Route {
  model() {
    const model = this.modelFor('authenticated.settings');
    const signature = this.store
      .query('v2/signature', {
        user_id: get(model, 'id')
      })
      .then(signatures => signatures.firstObject);

    return RSVP.hash({
      model,
      signature
    })
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      controller.setProperties({
        signaturePad: null
      });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
