import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default class FontSettingsService extends Service {
  @service intl;

  get systemFonts() {
    return [
      { id: 'Arial:sans-serif', name: 'Arial' },
      { id: 'Courier New:monospace', name: 'Courier New' },
      { id: 'Georgia:serif', name: 'Georgia' },
      { id: 'Times New Roman:serif', name: 'Times New Roman' },
      { id: 'Tahoma:sans-serif', name: 'Tahoma' },
      { id: 'Trebuchet MS:sans-serif', name: 'Trebuchet MS' },
      { id: 'System Sans:sans-serif', name: 'System Sans' },
      { id: 'System Serif:serif', name: 'System Serif' },
      { id: 'Verdana:sans-serif', name: 'Verdana' }
    ];
  }

  get fontTypes() {
    return [
      { id: 'system', name: this.intl.t('authenticated.admin.online-planner.edit.overview.fields.select.fontType.systemFonts'), default: 'System Sans:sans-serif' },
      { id: 'web', name: this.intl.t('authenticated.admin.online-planner.edit.overview.fields.select.fontType.webFonts'), default: 'Open Sans:sans-serif'}
    ];
  }
}
