import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class FrontRunnerSetupController extends Controller {
  showKeyField = true;
  keyPlaceholder = 'Customer ID';
  
  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
