import { v4 as uuidv4 } from 'uuid';
import Model, { attr, belongsTo } from '@ember-data/model';
import moment from 'moment';

export default class V2PaymentModel extends Model {
  @attr('boolean') exported;
  @attr('boolean') markedPending;
  @attr('boolean') processorPending;
  @attr('boolean') refundable;

  @attr('object') customFieldOne;
  @attr('object') customFieldTwo;

  @attr('price') amount;
  @attr('price') paymentFee;

  @attr('string') achAccountNumberLastFour;
  @attr('string') achAccountType;
  @attr('string') achRoutingNumber;
  @attr('string') bankName;
  @attr('string') checkNumber;
  @attr('string') internalReceiptUuid;
  @attr('string') notes;
  @attr('string') payer;
  @attr('string') paymentProcessorStatus;
  @attr('string') source;
  @attr('string') tpsChargeId;

  @belongsTo('user') enteredBy;
  @belongsTo('v2/generalPayment') generalPayment;
  @belongsTo('v2/paymentType') paymentType;
  @belongsTo('v2/person') person;
  @belongsTo('v2/selection') selection;
  @belongsTo('v2/user') collectedBy;

  @attr('moment-datetime', { defaultValue: () => moment() }) collectedAt;

  get receiptUuid() {
    if (this.internalReceiptUuid) {
      return this.internalReceiptUuid;
    } else {
      this.internalReceiptUuid = uuidv4();
      return this.internalReceiptUuid;
    }
  }
  set receiptUuid(uuid) {
    this.internalReceiptUuid = uuid;
  }
}
