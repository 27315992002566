import { tracked } from '@glimmer/tracking';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class AuthenticatedContactsManageController extends Controller {
  @service api;

  @tracked substituteContact = null;
  @tracked peopleQuery = [];
  @tracked query = null;

  @tracked showDeleteDialog = false;
  @tracked showReplaceDialog = false;
  @tracked showArchiveDialog = false;

  @computed('model.kaseId')
  get isDeceased() {
    return !!this.model.kaseId;
  }

  @computed('peopleQuery.length', 'query.length')
  get showPeopleReplaceTable() {
    return (
      !!this.query &&
      this.query.length > 0 &&
      !!this.peopleQuery &&
      this.peopleQuery.length > 0
    );
  }

  @computed('model.{archivedAt,name}')
  get archivePrompt() {
    if (this.model.archivedAt === null) {
      return `${this.model.name} will no longer be available for selection. Are you sure you want to archive?`;
    } else {
      return `Are you sure you want to unarchive ${this.model.name}?`;
    }
  }

  @computed('model.name')
  get deletePrompt() {
    return `This will permanently delete ${this.model.name} from all associated records. Are you sure?`;
  }

  handleDuplicateContactError() {
    this.send(
      'flashError',
      'These records are already associated to this Contact, there is nothing to replace.'
    );
  }

  handleDeleteSuccess() {
    this.substituteContact = null;
    this.send('flashSuccess', 'Yay! You deleted a Contact.');
    this.transitionToRoute('authenticated.contacts');
  }

  handleDeleteError() {
    this.send('flashError', 'Oops! There was an error deleting this Contact.');
  }

  @action
  updatePeopleQuery(query) {
    this.query = query;
    if ((query || '').length === 0) {
      return [];
    }
    this.store
      .query('v2/person', {
        query: this.query,
        exclude_id: this.model.id,
        include_dependents: true,
      })
      .then((res) => (this.peopleQuery = res));
  }

  @action
  deleteContact() {
    this.model
      .destroyRecord()
      .then(() => this.handleDeleteSuccess())
      .catch(() => this.handleDeleteError);
  }

  @action
  deleteAndReplace(contactToMerge) {
    if (this.model.id === contactToMerge.id) {
      this.handleDuplicateContactError();
    } else {
      contactToMerge
        .replace(this.model)
        .then(() => this.handleDeleteSuccess())
        .catch(() => this.handleDeleteError());
    }
    this.showReplaceDialog = false;
  }

  @action
  saveContact() {
    this.substituteContact.save();
  }
}
