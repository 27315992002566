import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n  <LinkTo\n      @route=\"authenticated.kases.manage.chain-of-custody.audit-log\"\n      @model={{@kase}}>\n    <h2 class=\"m--lg--t\">Audit Log</h2>\n  </LinkTo>\n\n  <ul class=\"list\">\n    {{#each this.custodyAudits as |custodyAudit|}}\n      <List::CustodyAuditSummary\n          @kase={{@kase}}\n          @custodyAudit={{custodyAudit}}\n          @noButton={{true}}\n          @class=\"custody-audit-summary\"/>\n    {{/each}}\n  </ul>\n</div>\n", {"contents":"<div>\n  <LinkTo\n      @route=\"authenticated.kases.manage.chain-of-custody.audit-log\"\n      @model={{@kase}}>\n    <h2 class=\"m--lg--t\">Audit Log</h2>\n  </LinkTo>\n\n  <ul class=\"list\">\n    {{#each this.custodyAudits as |custodyAudit|}}\n      <List::CustodyAuditSummary\n          @kase={{@kase}}\n          @custodyAudit={{custodyAudit}}\n          @noButton={{true}}\n          @class=\"custody-audit-summary\"/>\n    {{/each}}\n  </ul>\n</div>\n","moduleName":"crakn/components/g/chain-of-custody/details/audit-log.hbs","parseOptions":{"srcName":"crakn/components/g/chain-of-custody/details/audit-log.hbs"}});
import Component from '@glimmer/component';

export default class ChainOfCustodyDetailsAuditLogComponent extends Component {
  get custodyAudits() {
    return this.args.kase.custodyAudits.filter(ca => {
      return !ca.isNew || ca.isSaving;
    }).sort((a, b) => b.createdAt - a.createdAt);
  }
}
