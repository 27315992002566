import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class CustodyAuditSummary extends Component {
  @tracked isOpen = false;

  get custodyAudit() {
    return this.args.custodyAudit;
  }

  get kase() {
    return this.args.kase;
  }

  @action
  toggleDrawer() {
    this.isOpen = !this.isOpen;
  }
}
