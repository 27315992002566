import SearchController from 'crakn/controllers/search';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import moment from 'moment';
import { tracked } from '@glimmer/tracking';

export default class KasesIndexController extends SearchController {
  @service router;

  queryParams = [
    'date_created',
    'organization_location_id',
    'page',
    'per_page',
    'query',
    'status',
    'user_id'
  ]

  @tracked date_created = null;
  @tracked organization_location_id = null;
  @tracked status = null;
  @tracked user_id = null;

  get formattedDateCreated() {
    if (!this.date_created) {
      return null;
    }

    return moment(Number(this.date_created));
  }

  @action
  resetPage() {
    this.page = 1;
  }

  @action
  setDateCreated(date) {
    this.resetPage();
    this.date_created = date ? moment(date).utc().valueOf() : null;
  }

  @action 
  startNewCase() {
    this.router.transitionTo('authenticated.kases.create');
  }

  @action
  updateStatus(kase, status) {
    kase.markStatus(status).then(() => {
      this.send('refreshModel');
    });
  }
}
