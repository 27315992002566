export function validate(field) {
  let typeValid = true;
  let textValid = true;

    if (!field.type) {
      typeValid = false;
    }

    if (!field.text) {
      textValid = false;
    }

   return {isValid: (typeValid && textValid), type: typeValid, text: textValid};
}
