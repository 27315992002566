import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesManageFinancialsSelectionEditPackageController extends Controller {
  @service flashes;
  @service session;
  @service store;
  @service intl;
  @service router;
  @service browser;

  @tracked product;
  @tracked generalPriceListId = null;
  @tracked isAddingItem = false;
  @tracked showSaveConfirmDialog = false;
  @tracked saveClicked = false;

  get queryParams() {
    return { include_archived: false, general_price_list_id: this.generalPriceListId };
  }

  get items() {
    return this.model.selectionPackageSlots.filter((sps) => {
      return !sps.packageSlot.content || sps.packageSlot.get('packageSlotType') === 'item'
    });
  }

  get flexibleItems() {
    return this.model.selectionPackageSlots.filter((sps) => sps.packageSlot.get('packageSlotType') === 'flexible_items');
  }

  get canViewPackageSlotAllowances() {
    return this.session.currentClient.can_view_package_slot_allowances;
  }

  get savePrompt() {
    if (this.model.hasFlexibleProductsSelected) {
      return this.intl.t('components.g.kases.manage.financials.selection.package.page.backToSelection.selectedPrompt');
    } else {
      return this.intl.t('components.g.kases.manage.financials.selection.package.page.backToSelection.notSelectedPrompt');
    }
  }

  @action
  goToSelection() {
    this.send('redirectToSelection', this.model);
    set(this, 'showSaveConfirmDialog', false);
  }

  @action
  updateSaveClicked(value) {
    this.saveClicked = value;
  }

  @action
  async addProduct(product) {
    const sps = this.store.createRecord('v2/selection-package-slot', {
      selectionPackage: this.model
    });

    await sps.save();

    await this.store.createRecord('v2/selection-package-slot-product', {
      selectionPackageSlot: sps,
      product,
      price: product.get('price')
    }).save().then(() => {
      this.isAddingItem = false;
      this.showSaveConfirmDialog = false;
      this.model.reload();
    });
  }
}
