import { alias } from '@ember/object/computed';
import { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import SelectBox from './select-box';

export default SelectBox.extend({
  layoutName: 'components/form/select-box',

  location: service(),

  placeholder: 'Location',

  // generalPriceListLocked allows changing locations that share the same
  // GPL after an item is added to a kase selection
  generalPriceListLocked: false,
  locationLocked: false,
  archived: true,
  disabled: alias('locationLocked'),

  placeholderLabel: computed(
    'generalPriceListLocked',
    'locationLocked',
    'selection',
    function() {
      const selectedLoc = this.selection;
      if (
        (this.generalPriceListLocked || this.locationLocked) &&
        selectedLoc &&
        get(selectedLoc, 'id')
      ) {
        return null;
      } else {
        return 'Select One';
      }
    }
  ),

  generalPriceList: alias('selection.generalPriceList'),

  options: computed(
    'archived',
    'generalPriceList',
    'generalPriceListLocked',
    'location.{allSorted,archivedSorted}',
    function() {
      const locations = this.archived
        ? get(this, 'location.archivedSorted')
        : get(this, 'location.allSorted');

      return locations.filter(location => {
        const generalPriceList = this.generalPriceList;
        if (this.generalPriceListLocked) {
          return (
            !generalPriceList ||
            get(location, 'generalPriceList.id') === get(generalPriceList, 'id')
          );
        } else {
          return true;
        }
      });
    }
  ),

  didRender() {
    this._super(...arguments);

    const selection = this.selection;
    if (selection == null || selection == undefined) {
      set(this, 'hasValue', false);
    } else {
      const sel = get(selection, 'content') || selection;
      set(this, 'hasValue', true);
      set(this, 'value', sel.id)
    }
  }
});
