import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class TransnationalSetupController extends Controller {
  showSecretField = true;
  secretPlaceholder = 'API Key';
  showKeyField = true;
  keyPlaceholder = 'Password';
  showUsernameField = true;
  usernamePlaceholder = 'Username'

  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
