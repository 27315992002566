import { filterBy } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
export default Component.extend({
  kase: null,
  store: service(),

  tasks: computed('model', {
    get() {
      const kaseId = get(this, 'kase.id');
      return this.store.query('kaseTask', { kase_id: kaseId });
    }
  }),

  allOpenTasks: filterBy('tasks', 'status', 'yes'),
  openNotCompleteTasks: filterBy('allOpenTasks', 'complete', false)
});
