import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class VeteranInfoRoute extends Route {
  @service clientRouting;
  layout = 'custom-aside';

  async model() {
    const kase = this.modelFor('authenticated.kases.manage');

    const v2Kase = await this.store.findRecord('v2/kase', kase.id);

    return this.store
      .query('v2/veteranInfo', { kaseId: kase.id })
      .then((result) => {
        return (
          result.firstObject ||
          this.store.createRecord('v2/veteranInfo', { kase: v2Kase }).save()
        );
      });
  }

  async setupController(controller, model) {
    super.setupController(...arguments);
    const kase = this.modelFor('authenticated.kases.manage');
    const v2Kase = this.store.peekRecord('v2/kase', kase.id);

    controller.set('model', model);
    controller.set('kase', kase);
    controller.set('v2Kase', v2Kase);

    this.store.query('v2/us-service-record', {
      veteranInfoId: model.id
    });

    await this.setupDisposition(controller, v2Kase);
  }

  // Disposition is required for the isHeadstoneDesired checkbox to be on both pages
  async setupDisposition(controller, v2Kase) {
    const disposition = await this.store
      .query('v2/disposition', { kaseId: v2Kase.id })
      .then((result) => {
        return (
          result.firstObject ||
          this.store.createRecord('v2/disposition', { kase: v2Kase })
        );
      });

    controller.set('disposition', disposition);
  }

  @action
  willTransition() {
    this.currentModel.save();
  }
}
