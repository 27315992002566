import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AdminOnlinePlannerEditRoute extends Route {
  @service session;
  @service router;

  model(params) {
    return this.store.findRecord('v2/OnlinePlannerConfig', params.id, {
      reload: true,
      include: [
        'location-online-planner-configs',
        'location-online-planner-configs.location',
        'logo',
        'general-price-list',
        'payment-type',
        'payment_type.integration-service',
        'default-tax-location'
      ].join(',')
    });
  }
}
