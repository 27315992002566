import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: [
    'card',
    'card--nudged',
    'card--border-editing',
    'qa-notes-inquiry'
  ],

  session: service(),
  activeInquiry: service(),

  contact: alias('activeInquiry.model.contact'),
  kase: alias('activeInquiry.model.kase'),
  inquiry: alias('activeInquiry.model'),

  isInquiryRoute: false
});
