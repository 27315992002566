import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Field::Input\n    @value={{this.value}}\n    @placeholder={{@placeholder}}\n    @disabled={{@disabled}}\n    @type=\"number\"\n    @min={{this.min}}\n    @max={{this.max}}\n    @validationMessages={{@validationMessages}}\n    @showValidations={{@showValidations}}\n    @onFocus={{@onFocus}}\n    @onBlur={{@onBlur}}\n    @onChange={{this.change}}/>\n", {"contents":"<G::Crakn::Field::Input\n    @value={{this.value}}\n    @placeholder={{@placeholder}}\n    @disabled={{@disabled}}\n    @type=\"number\"\n    @min={{this.min}}\n    @max={{this.max}}\n    @validationMessages={{@validationMessages}}\n    @showValidations={{@showValidations}}\n    @onFocus={{@onFocus}}\n    @onBlur={{@onBlur}}\n    @onChange={{this.change}}/>\n","moduleName":"crakn/components/g/crakn/field/percentage.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/field/percentage.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class GCraknFieldPercentageComponent extends Component {
  @tracked min = 0.0;
  @tracked max = 100.0;
  @tracked value = 0.0;

  constructor(_owner, args) {
		super(...arguments);

    this.value = args.value;
  }

  get precision() {
    return this.args.precision || 3;
  }

  @action
  change(value) {
    if (value == null || value < this.min) {
      this.value = this.min;
    } else if (value > this.max) {
      this.value = this.max;
    } else {
      this.value = value
    }

    this.value = Number(this.value).toFixed(this.precision);

    if (this.value == 0) {
      this.args.onChange(0);
    } else {
      this.args.onChange(this.value);
    }
  }
}
