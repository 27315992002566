import Component from '@glimmer/component';
import moment from 'moment';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class BeaconMonthlyReconciliationReport extends Component {
  @service flashes;
  @service intl;

  @tracked startsAt = moment().subtract(30, 'day').toISOString();
  @tracked endsAt = moment().toISOString();
  @tracked organization_location_id = null;

  @tracked showAdditionalPostedDateFilters = false;
  @tracked postedDateStartsAt = moment().subtract(30, 'day').toISOString();
  @tracked postedDateEndsAt = moment().toISOString();

  get reportLink() {
    const params = new URLSearchParams({
      organization_location_id: this.organization_location_id,
      starts_at: this.startsAt,
      ends_at: this.endsAt,
      location_id: this.location_id,
      uuid: this.args.uuid,
      posted_date_starts_at: this.showAdditionalPostedDateFilters ? this.postedDateStartsAt : null,
      posted_date_ends_at: this.showAdditionalPostedDateFilters ? this.postedDateEndsAt : null
    }).toString();

    return `/report/beacon_monthly_reconciliation_report.xlsx?${params}`;
  }

  get fileUrl() {
    return `#/generated_document/${this.args.uuid}`;
  }

  @action
  setDate(attr, value) {
    set(this, attr, value);
  }

  @action
  generateReport(e) {
    if (this.startsAt === null) {
      e.preventDefault();
      this.flashes.addError(this.intl.t('components.reports.beacon-monthly-reconciliation-report.messages.missingStartDate'));
      return false;
    }

    if (this.endsAt === null) {
      e.preventDefault();
      this.flashes.addError(this.intl.t('components.reports.beacon-monthly-reconciliation-report.messages.missingEndDate'));
      return false;
    }

    if(this.showAdditionalPostedDateFilters) {
      if (this.postedDateStartsAt === null) {
        e.preventDefault();
        this.flashes.addError(this.intl.t('components.reports.beacon-monthly-reconciliation-report.messages.missingPostedStartDate'));
        return false;
      }
  
      if (this.postedDateEndsAt === null) {
        e.preventDefault();
        this.flashes.addError(this.intl.t('components.reports.beacon-monthly-reconciliation-report.messages.missingPostedEndDate'));
        return false;
      }
    }

    this.args.onGenerateReport(this.reportLink);
  }
}
