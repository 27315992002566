import Component from '@ember/component';
import { filter, alias } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Component.extend({
  classNames: ['list__content'],

  kase: null,
  accountingExportSelections: null,
  accountingExportSelectionsCount: 0, // This is here to trigger a change
  selections: alias('kase.kaseFinancial.selections'),
  exportableSelections: filter('selections', function(selection) {
    return (
      selection.get('hasUnexportedAccounting') && !selection.get('doNotExport')
    );
  }),
  selected: computed(
    'accountingExportSelections',
    'accountingExportSelectionsCount',
    'exportableSelections',
    function() {
      const accountingExportSelections = this.accountingExportSelections.toArray();
      const exportableSelections = this.exportableSelections.toArray();
      // Check to see if all the exportable selections are in the accounting export.
      // If all are present, then return true
      return exportableSelections.reduce((current, selection) => {
        return current && accountingExportSelections.includes(selection);
      }, true);
    }
  ),

  actions: {
    toggleKase() {
      const exportableSelections = this.exportableSelections;
      const selected = this.selected;
      if (selected) {
        exportableSelections.forEach(selection => {
          this.sendAction('removeSelection', selection);
        });
      } else {
        exportableSelections.forEach(selection => {
          this.sendAction('addSelection', selection);
        });
      }
    },
    addSelection(selection) {
      this.sendAction('addSelection', selection);
    },
    removeSelection(selection) {
      this.sendAction('removeSelection', selection);
    }
  }
});
