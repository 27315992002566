import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads, not } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';
import config from 'crakn/config/environment';

const Validations = buildValidations({
  firstName: validator('presence', true),
  lastName: validator('presence', true),
  amount: [
    validator('presence', true),
    validator('number', {
      disabled: not('model.balance'),
      allowString: true,
      lte: reads('model.balance'),
      gte: 0.50,
      messageKey: 'pay-now.errors.payment.numberValidation.withBalance'
    }),
    validator('number', {
      disabled: reads('model.balance'),
      allowString: true,
      gte: 0.50,
      messageKey: 'pay-now.errors.payment.numberValidation.withoutBalance'
    }),
  ]
})

export default class PayNowController extends Controller.extend(Validations) {
  @service api;
  @service browser;
  @service flashes;
  @service router;

  queryParams = ['jwt', 'message', 'success'];

  areNameFieldsDisabled; // Set up in the route.
  balance; // Set up in the route.
  @tracked jwt; // Set up in the route.
  @tracked message; // Set up in the route.
  @tracked success; // Set up in the route.

  @tracked amount;
  @tracked firstName;
  @tracked isNextButtonDisabled = false;
  @tracked lastName;
  @tracked showValidations = false;

  async fetchAmountDue() {
    const { parsedJson } = await this.api.json.get(`v2/pay_now?jwt=${this.jwt}`);

    return parsedJson.data.attributes['amount-due'];
  }

  @action
  next() {
    set(this, 'isNextButtonDisabled', true);
    set(this, 'showValidations', false);
    this.validate().then(async ({ validations }) => {
      if (!validations.isValid) {
        set(this, 'isNextButtonDisabled', false);
        set(this, 'showValidations', true);
        return;
      }

      const amountDue = await this.fetchAmountDue();

      if (amountDue <= 0 && this.model.reference_id) {
        this.router.transitionTo('pay-now.balance-paid', this.jwt);
        return;
      }

      const host = config.host.replace('api.', '');

      this.api.json.post(`v2/collection_attempts/${this.model.id}/pay?jwt=${this.jwt}`, {
        body: {
          first_name: this.firstName,
          last_name: this.lastName,
          amount: this.amount,
          redirect_url: `${host}/#/pay-now?jwt=${this.jwt}`
        }
      }).then((res) => {
        if (res.ok) {
          const { 'stripe-checkout-url': url } = res.parsedJson.data.attributes;
          window.location.replace(url);
        } else {
          set(this, 'isNextButtonDisabled', false);
          this.flashes.addError('Something went wrong.');
        }
      });
    })
  }
}
