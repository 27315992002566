import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default class KaseFinancialModel extends Model {
  @attr('price') adjustedTax;
  @attr('price') adjustmentsTotal;
  @attr('price') adjustmentsWithoutTax;
  @attr('price') balance;
  @attr('price') cashAdvanceTotal;
  @attr('moment-date') contractDate;
  @attr('price') discountsTotal;
  @attr('boolean') hasNoSelections;
  @attr('price') interestsTotal;
  @attr('price') merchandiseTotal;
  @attr('price') nonTaxableDiscount;
  @attr('price') nonTaxableTotal;
  @attr('price') packageDiscount;
  @attr('price') paymentsTotal;
  @attr('price') refundsTotal;
  @attr('price') salesTax;
  @attr('price') serviceTotal;
  @attr('price') specialChargeTotal;
  @attr('price') specialTax;
  @attr('price') subtotal;
  @attr('price') subtotalAfterDiscounts;
  @attr('price') taxableTotal;
  @attr('price') total;


  @belongsTo('kase', { async: true }) kase;

  @hasMany('selection', { async: false }) selections;

  get balanceColor() {
    return this.balance >= 0 ? 'green' : 'red';
  }
}
