import { alias, bool, and, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object'

export default class AuthenticatedDashboardController extends Controller {
  @service store;
  @service session;

  queryParams = ['organization_location_id'];

  @alias('session.currentUser.id') userId;

  @and(
    'session.currentClient.can_view_tasks',
    'session.currentClient.can_view_dash_tasks',
    'session.currentUser.showDashTasks'
  ) viewTasks;

  perPage = null;

  kasePage = 1;

  @bool('model.userKases.length') hasKases;

  @bool('model.userEvents.length') hasEvents;

  @bool('model.userKases.kaseTasks.length') hasTasks;

  @bool('model.pastDueKases.length') hasPastDueKases;

  @and('hasEvents', 'hasPastDueKases') hasEventAndPast;

  @gt('session.currentUser.permissions', 2) permissionOverdueCases;

  @and(
    'session.currentClient.can_view_dash_chain_custody',
    'session.currentUser.showDashChainCustody'
  ) showDashChainCustody;

  @and(
    'session.currentClient.can_view_dash_nav',
    'session.currentUser.showDashNav'
  ) showDashNav;

  @and(
    'hasEvents',
    'session.currentClient.can_view_dash_events',
    'session.currentUser.showDashEvents'
  ) showEventsWidget;

  @and(
    'hasKases',
    'session.currentClient.can_view_dash_kases',
    'session.currentUser.showDashKases'
  ) showKasesWidget;

  @and(
    'permissionOverdueCases',
    'hasPastDueKases',
    'session.currentClient.can_view_dash_past_due',
    'session.currentUser.showDashPastDue'
  ) showPastDueCases;

  @and('hasKases', 'viewTasks') kasesAndTasks;

  @action
  loadMoreKases() {
    this.kasePage += 1;
    const page = this.kasePage;
    const userId = this.userId;

    this.store
      .query('kase', {
        status: 'active',
        per_page: this.perPage,
        page,
        user_id: userId,
        dashboard: true
      })
      .then(results => {
        this.model.userKases.content.pushObjects(
          results.content
        );
      });
  }

  @action
  loadMoreEvents() {
    this.eventPage += 1;
    const page = this.eventPage;
    const userId = this.userId;

    this.store
      .query('event', {
        filter_future: true,
        per_page: this.perPage,
        page,
        user_id: userId,
        dashboard: true
      })
      .then(results => {
        this.model.userEvents.content.pushObjects(
          results.content
        );
      });
  }
}
