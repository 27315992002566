import Component from '@ember/component';
import { computed, set } from '@ember/object';

export default Component.extend({
  classNames: ['sortable'],
  classNameBindings: ['icon'],
  tagName: 'th',

  name: null,
  sortByName: null,
  sortBy: null,
  order: null,

  icon: computed('order', 'sortBy', 'sortByName', {
    get() {
      const sortBy = this.sortBy;
      const order = this.order;
      const sortByName = this.sortByName;

      if (sortBy === sortByName) {
        return order === 'ASC' ? 'arrow_drop_down' : 'arrow_drop_up';
      }
    }
  }),

  click() {
    const sortBy = this.sortBy;
    const sortByName = this.sortByName;

    if (sortBy === sortByName) {
      set(this, 'order', this.order === 'DESC' ? 'ASC' : 'DESC');
    } else {
      set(this, 'sortBy', sortByName);
      set(this, 'order', 'ASC');
    }

    if (this.onClick) {
      this.onClick();
    }
  }
});
