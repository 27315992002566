import { helper as buildHelper } from '@ember/component/helper';

/**
 * Almost equal helpers
 * @param  {Array} params Array of params
 * @return {Boolean} Whether or not the passed params equal value but perhaps not type
 */
export function aeq(params) {
  return params[0] == params[1];
}

export default buildHelper(aeq);
