import { or } from '@ember/object/computed';
import Component from '@ember/component';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNames: ['card-nav'],
  classNameBindings: ['isDesktopOrNavOpen:card-nav--open'],

  browser: service(),

  label: 'Navigation',

  isDesktopOrNavOpen: or('browser.isDesktopWidth', 'isNavOpen'),

  init() {
    this._super(...arguments);

    set(this, 'isNavOpen', get(this, 'browser.isDesktopWidth'));
  },

  isNavOpen: null,

  actions: {
    toggleNav(skipBrowserCheck = false) {
      const destroyed = this.get('isDestroyed') || this.get('isDestroying');
      if (destroyed) {
        return;
      }

      if (skipBrowserCheck || !get(this, 'browser.isDesktopWidth')) {
        this.toggleProperty('isNavOpen');
      }
    }
  }
});
