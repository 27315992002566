import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminManageInfoController extends Controller {
  @service flashes;
  @service intl;

  @action
  saveInfo() {
    this.model
      .save()
      .then(() => {
        this.flashes.addSuccess(this.intl.t('authenticated.admin.manage.info.button.save.successMessage'));
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.manage.info.button.save.errorMessage'));
      });
  }
}
