import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class AdminManageController extends Controller {
  @service session;

  get canViewTributeVideoAdminLink() {
    if (
      this.session.currentClient.can_view_tribute_video &&
      this.session.currentUser.canViewTributeVideo &&
      this.session.currentClient.can_view_tribute_video_admin
    ) {
      return true;
    } else {
      return false;
    }
  }      

  get canViewFinancialConfigLink() {
    if (
      this.session.currentClient.can_view_financial_configurations &&
      this.session.currentUser.hasFinancialPermission &&
      this.session.currentUser.canViewFinancialConfigurations
    ) {
      return true;
    } else {
      return false;
    }
  }

  get canViewVehiclesLink() {
    if (
      (this.session.currentClient.can_view_vehicles &&
        this.session.currentUser.canViewVehicles)
    ) {
      return true;
    } else {
      return false;
    }
  }

  get canSetupTributePay() {
    if (
      this.session.currentClient.can_view_tribute_pay &&
      (this.session.currentUser.canSetupTributePay &&
        this.session.currentUser.isAdmin)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
