import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2OnlinePlannerSessionPackageModel extends Model {
  @attr('string') uuid;
  @attr('number') quantity;
  @attr('price') price;
  @attr('price') packageTotal;
  @belongsTo('v2/online-planner-package', { async: true }) onlinePlannerPackage;
  @belongsTo('v2/online-planner-session', { async: true }) onlinePlannerSession;
}
