import Model, { attr, hasMany } from '@ember-data/model';

export default class V2IntegrationServiceModel extends Model {
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') exportedAt;
  @attr('moment-datetime') exportUpdatedAt;
  @attr('string') category;
  @attr('string') client_id;
  @attr('string') description;
  @attr('string') display_name;
  @attr('string') domain_id;
  @attr('string') exportStatus;
  @attr('string') name;

  // ASD
  @attr('boolean') autoKaseSync;
  @attr('boolean') disableImport;
  @attr('string') importAfterDate;
  
  /** The following attributes are just for sending the params on creation */
  @attr('array') bestWishesCardTypes;
  @attr('boolean') avatar_override;
  @attr('boolean') locationBased;
  @attr('number') logo_id;
  @attr('number') tributeVideoUserId;
  @attr('string') cemsites_blog_id;
  @attr('string') cemsites_client_uuid;
  @attr('string') cemsites_place_name;
  @attr('string') email;
  @attr('string') firm_id;
  @attr('string') hostname;
  @attr('string') key;
  @attr('string') locationId;
  @attr('string') logoName;
  @attr('string') new_contact_date;
  @attr('string') secret;
  @attr('string') tukios_funeral_home_location_id;
  @attr('string') tukios_funeral_home_location_name;
  @attr('string') username;
  @attr('string') website_url;

  @hasMany('locationIntegrationService', { async: true }) locationIntegrationServices;
  @hasMany('location', { async: true }) locations;
}
