import { helper as buildHelper } from '@ember/component/helper';
import { getDropDownOptions } from '@tributetech/ember-jsonb';
import moment from 'moment';

export function olpReviewValue(array) {
  const [field] = array;

  if (field.type === 'select') {
    const selected = getDropDownOptions(field)?.find(
      (option) => option.value === field.value
    );

    return selected?.label;
  }

  if (field.type === 'checkbox') {
    return field?.value ? 'Yes' : 'No';
  }

  if (field.type === 'date') {
    return field?.value ? moment(field?.value).format('MM/DD/YYYY') : '';
  }

  return field.value
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
}

export default buildHelper(olpReviewValue);
