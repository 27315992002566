import SearchController from 'crakn/controllers/search';
import { tracked } from '@glimmer/tracking';

export default class KasesCreateController extends SearchController {
  @tracked query = null;

  queryParams = ['query', 'birthDate', 'deathDate'];

  model = null;
  birthDate = null;
  deathDate = null;

  get duplicate() {
    const content = this.query;
    const kases = this.model.content;

    if (kases.length === 0 || content === null || content === '') {
      return false;
    }
    return true;
  }
}
