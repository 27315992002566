import Service, { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { cancel, later } from '@ember/runloop';
import config from 'crakn/config/environment';

export default class JwtService extends Service {
  @service router;
  @service api;

  get token() {
    return localStorage.getItem('sso_token');
  }

  get refreshToken() {
    return localStorage.getItem('sso_refresh_token');
  }

  get expiresAt() {
    return localStorage.getItem('sso_expires_at');
  }

  get refreshExpiresAt() {
    return localStorage.getItem('sso_refresh_expires_at');
  }

  get refreshLeeway() {
    return localStorage.getItem('sso_refresh_leeway');
  }

  get now() {
    return Math.floor((new Date()).getTime());
  }

  get client() {
    return 'tms';
  }

  isSso() {
    return !this.isLocalStorageEmpty(this.token);
  }

  removeSsoTokens() {
    localStorage.removeItem('sso_token');
    localStorage.removeItem('sso_expires_at');
    localStorage.removeItem('sso_refresh_token');
    localStorage.removeItem('sso_refresh_expires_at');
    localStorage.removeItem('sso_refresh_leeway');
  }

  async getSsoToken(code, state) {
    return await this.api.json.post( 'v2/sso/token', {
        body: {
          code: code,
          state: state,
          redirect_uri: config.url
        }}
    ).catch((error) => {
      console.log(error);
    });
  }

  getNewAccessToken(){
    return new Promise((resolve, reject) => {
        this.api.json
            .post('v2/sso/token/refresh', { body: {
                refresh_token: this.refreshToken
              } })
            .then(response => {

        this.handleRefreshTokenResponse(response);
        resolve(response);

      }).catch(error => {
        this.removeSsoTokens();
        this.router.transitionTo('login');
        reject(error);
      });
    });
  }

  handleRefreshTokenResponse(ssoTokenResp) {
    const options = this.calcSsoTokenOptions(ssoTokenResp);
    this.setSsoOptions(options);
    this.scheduleAccessTokenRefresh(options.expiresAt, options.refreshToken);
  }

  calcSsoTokenOptions(ssoTokenResp) {
    const now = this.now;
    const expiresIn = ssoTokenResp?.parsedJson?.expires_in;
    const expiresAt = expiresIn * 1000 + now;
    const refreshExpiresIn = ssoTokenResp?.parsedJson?.refresh_expires_in;
    const refreshExpiresAt = refreshExpiresIn * 1000 + now;
    const accessToken = ssoTokenResp?.parsedJson?.access_token;
    const refreshToken = ssoTokenResp?.parsedJson?.refresh_token;
    var refreshLeeway = expiresIn * 100 //10% of token lifespan;
    if (refreshLeeway < 1000) refreshLeeway = 1000  // minimum 1 second
    if (refreshLeeway > 300000) refreshLeeway = 300000  // maximum 5 minutes
    return {
        accessToken: accessToken,
        refreshToken: refreshToken,
        expiresAt: expiresAt,
        refreshExpiresAt: refreshExpiresAt,
        refreshLeeway: refreshLeeway
    }
  }

  setSsoOptions(options){

    localStorage.setItem('sso_token', options.accessToken);
    localStorage.setItem('sso_refresh_token', options.refreshToken);
    localStorage.setItem('sso_expires_at', options.expiresAt);
    localStorage.setItem('sso_refresh_expires_at', options.refreshExpiresAt);
    localStorage.setItem('sso_refresh_leeway', options.refreshLeeway);

  }

  scheduleAccessTokenRefresh(){
    const wait = (this.expiresAt - this.now - this.refreshLeeway);

    if (!this.isLocalStorageEmpty(this.refreshToken) && !this.isLocalStorageEmpty(this.expiresAt)) {
      if (wait <= 0) return
      cancel(this._refreshTokenTimeout);
      delete this._refreshTokenTimeout;
      this._refreshTokenTimeout = later(this, this.getNewAccessToken, this.refreshToken, 0, wait);
    }
  }

  isLocalStorageEmpty(value) {
    const badValues = ['undefined', 'null', 'NaN', '0', 0, NaN, null, undefined];
    return isEmpty(value) || badValues.includes(value);
  }

  logout(url) {
    return fetch(url, {
      method: 'POST',
      headers:{
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
          'client_id': `${this.client}`,
          'refresh_token': `${this.refreshToken}`
      })
    });
  }

}
