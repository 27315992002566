import { helper as buildHelper } from '@ember/component/helper';
import { formatFloat } from 'crakn/utils/data-helpers';

export function showPrice(array) {
  let [price, prependCurrency] = array;

  if (prependCurrency === undefined) {
    prependCurrency = true;
  }

  if (Number.isNaN(parseInt(price))) {
    price = 0;
  }

  return formatFloat(price, prependCurrency, true, 3);
}

export default buildHelper(showPrice);
