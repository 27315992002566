import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { isNumber } from 'lodash-es/lang';

export default class CraknClientsEditCallConfigController extends Controller {
  @controller('authenticated/crakn/clients/edit') edit;

  @tracked showValidation = false;

  get callVolumeMessage() {
    const callVolume = this.client.callVolume;
    const isValidCallVolume = isNumber(parseInt(callVolume)) || callVolume == null;

    if (isValidCallVolume) {
      return null;
    } else {
      return 'This field must be a number';
    }
  }

  validate() {
    return !this.callVolumeMessage;
  }

  @action
  save(event) {
    const isValid = this.validate();
    if (isValid) {
      this.showValidation = false;
      this.edit.save(event);
    } else {
      this.showValidation = true;
    }
  }
}
