import ActiveModelAdapter from 'active-model-adapter';
import { inject as service } from '@ember/service';
import config from '../../config/environment';

const ApplicationAdapter = ActiveModelAdapter.extend({
  host: `${config.host}`,
  authorizer: 'authorizer:application',
  namespace: 'api',
  session: service(),
  useFetch: true,

  // This is actually fetch options.
  // If for any reason the application starts throwing authentication exceptions for everything
  // this is probably the culprit
  ajaxOptions() {
    const options = this._super(...arguments)
    options['credentials'] = 'include';

    return options
  },

  get headers() {
    if (localStorage.getItem('sso_token') === null) {
      return {
        'X-CSRF-Token': this.session.data.authenticated.token,
      };
    } else {
      return {
        'Authorization': localStorage.getItem('sso_token'),
      };
    }
  },

  /**
   * Whether or not a findAll should make a API request each time
   * @return {Boolean} Whether or not to make request
   */
  shouldReloadAll() {
    return true;
  },

  /**
   * Whether or not a record should be reloaded in the background
   * @return {Boolean} Whether or not to reload
   */
  shouldBackgroundReloadRecord() {
    return true;
  },

  pathForType(type) {
    const typePathMap = {
      'tfe/document': 'tfe/documents',
      'tfe/generated_document': 'tfe/generated_documents',
      'tfe/revision': 'tfe/revisions'
    };

    return typePathMap[type] || this._super(type).replace(/-/g, '_');
  },

  urlForFindRecord(id, modelName, snapshot) {
    let url = this._super(id, modelName, snapshot);
    if (snapshot.include) {
      url += `?include=${snapshot.include}`;
    }
    return url;
  }
});

export default ApplicationAdapter;
