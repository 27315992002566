import Model, { attr, belongsTo  } from '@ember-data/model';

export default class V2PostingPeriodsModel extends Model {
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') lockedAt;
  @attr('moment-datetime') createdAt;

  @attr('string') lockedByName;

  @belongsTo('v2/chart-of-accounts') chartOfAccounts;
  @belongsTo('v2/user') createdBy;
}
