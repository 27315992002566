import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default class TukiosSetupController extends Controller {
  showSecretField = true;
  secretPlaceholder = 'API Key';
  showKeyField = true;
  keyPlaceholder = 'fhid';
  @tracked tukiosFuneralHomeLocations = null;
  @tracked showFuneralHomeLocationsResults = false;
  @tracked tukiosFhid = null;
  @service api;
  @service flashes;

  handleAddSuccess() {
    this.flashes.addSuccess('Integration Added to location.');
    this.craknLocationId = null;
    this.send('refreshModel');
  }

  handleAddError(error) {
    this.flashes.addError(error);
  }

  @computed('tukiosFuneralHomeLocations', 'showFuneralHomeLocationsResults')
  get canViewTukiosFuneralHomeLocations() {
    if (this.tukiosFuneralHomeLocations && this.showFuneralHomeLocationsResults) {
      return true;
    }
    return false;
  } 


  @action
  requestTukiosFuneralHomeLocations() {
    this.api.json
      .get('tukios/funeral_home_locations', {
        params: { fhid: this.tukiosFhid },
      })
      .then((response) => {
        this.tukiosFuneralHomeLocations = response.parsedJson.tukios_funeral_home_locations;
        this.showFuneralHomeLocationsResults = true;
      });
  }

  @action
  linkFuneralHomeLocation(tukiosFuneralHomeId, tukiosFuneralHomeName) {
    this.store
      .createRecord('integrationService', {
        name: 'tukios',
        key: this.tukiosFhid,
        tukios_funeral_home_location_id: tukiosFuneralHomeId,
        tukios_funeral_home_location_name: tukiosFuneralHomeName
      })
      .save()
      .then((response) => {
        const locationId = this.craknLocationId;
        const integrationServiceId = response.id;
        if (locationId && integrationServiceId) {
          this.store
          .createRecord('locationIntegrationService', {
            locationId,
            integrationServiceId
          })
          .save()
          .then(() => {
            this.handleAddSuccess();
          })
          .catch((error) => {
            if (error.errors && error.errors[0]['detail']) {
              this.handleAddError(error.errors[0]['detail']);
            } else {
              this.handleAddError('Something went wrong adding the location.');
            }
          });
        }
      });
  }

  @action
  close() {
    this.tukiosFuneralHomeLocations = null;
    this.showFuneralHomeLocationsResults = false;
    this.craknLocationId = null;
    this.tukiosFhid = null;
  }

  @action
  onWillDestroy() {
    this.tukiosFhid = null;
  }
}
