import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  activeInquiry: service(),
  calendarDay: service(),
  layout: 'custom-aside',

  model(params) {
    return this.store.findRecord('inquiry', params.inquiry_id);
  },

  afterModel(model) {
    this.calendarDay.fetchDate(get(model, 'startsAt'));
  },

  resetController(controller, isExiting) {
    set(controller, 'isAddingContact', false);

    if (isExiting) {
      const model = this.currentModel;
      const isFacilityInquiry = get(model, 'inquiryType') === 'Facility';
      set(controller, 'inquiryStaffMemberId', null);

      get(model, 'events').forEach(function(event) {
        if (isFacilityInquiry) {
          if (get(event, 'isDirty') || get(event, 'isNew')) {
            event.rollbackAttributes();
          }

          if (get(event, 'isEditing')) {
            set(event, 'isEditing', undefined);
          }

          controller.notifyPropertyChange('isInquiryLockedTrigger');
        } else {
          event.destroyRecord();
        }
      });
    }
  },

  setupController(controller, model) {
    this._super(...arguments);

    set(this, 'activeInquiry.model', model);
  },

  actions: {
    saveNewContact() {
      return this.currentModel.get('contact.content').save();
    },

    saveNewKase(kase) {
      if (!kase) {
        return;
      }

      set(kase, 'location', get(this.currentModel, 'location'));

      // Create and save a new kase
      return kase
        .get('content')
        .save()
        .then(kase => {
          // Assign this new kase to the model
          set(this.currentModel, 'kase', kase);

          // Save the deceased record
          return get(kase, 'deceased.content').save();
        });
    }
  }
});
