import { reads } from '@ember/object/computed';
import { htmlSafe } from '@ember/template';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { run } from '@ember/runloop';
import { set, get, computed } from '@ember/object';
import EmberUploader from 'ember-uploader';

export default Component.extend({
  session: service(),
  store: service(),
  model: null,
  size: 'large',
  errors: [],
  uploadProgress: null,

  init() {
    this._super(...arguments);

    const headers = {};

    if (localStorage.getItem('sso_token') === null) {
      headers['X-CSRF-Token'] = this.session.data.authenticated.token;
    } else {
      headers['Authorization'] = localStorage.getItem('sso_token');
    }


    const uploader = EmberUploader.Uploader.extend({
      ajaxSettings: {
        headers: headers,
        xhrFields: { withCredentials: true }
      }
    }).create({
      url: this.uploadUrl,
      method: 'PUT',
      paramNamespace: this.modelType,
      paramName: 'avatar'
    });

    uploader.on('progress', progress => {
      set(this, 'uploadProgress', htmlSafe(`width: ${progress.percent}%;`));
    });

    set(this, 'uploader', uploader);
  },

  src: computed('model.avatar', 'size', function() {
    return get(this, `model.avatar.${this.size}Url`);
  }),

  modelType: reads('model.constructor.modelName'),

  uploadUrl: computed('model', 'modelType', function() {
    const adapter = this.store.adapterFor(this.modelType);
    return adapter.buildURL(this.modelType, get(this, 'model.id'));
  }),

  handleUploadSuccess(payload) {
    set(this, 'uploadProgress', null);
    this.store.pushPayload(this.modelType, payload);
  },

  handleUploadError(response) {
    set(this, 'uploadProgress', null);
    const errors = get(response, 'responseJSON.errors.avatar') || [
      'Your image failed to upload. Please try again.'
    ];
    set(this, 'errors', errors);
  },

  actions: {
    uploadFile(file) {
      set(this, 'errors', []);
      this.uploader
        .upload(file)
        .then(
          run.bind(this, 'handleUploadSuccess'),
          run.bind(this, 'handleUploadError')
        );
    }
  }
});
