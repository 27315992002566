import OneWayInputComponent from 'ember-one-way-controls/components/one-way-input';

export default OneWayInputComponent.extend({
  type: 'tel',
  maxlength: 10,

  allowedKeys: [9, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57],

  keyPress(evt) {
    const value = this.value;
    const keyPressArray = this.allowedKeys;
    const key = evt.which;

    // If backspace, arrow, ctrl, or command keys are pressed let the commands through
    if ([0, 8].includes(key) || evt.metaKey || evt.ctrlKey) {
      return;
    }

    if (value) {
      // Make sure value is not more than nine digits
      if (value.length >= 10) {
        evt.preventDefault();
      }
    }

    // Make sure zero is not being set as value
    if (!value && key === 48) {
      evt.preventDefault();
    }

    if (!keyPressArray.includes(key)) {
      evt.preventDefault();
    }
  }
});
