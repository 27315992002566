import { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { underscore } from '@ember/string';
import { singularize } from 'ember-inflector';
import { ActiveModelSerializer } from 'active-model-adapter';

export default ActiveModelSerializer.extend(EmbeddedRecordsMixin, {
  keyForRelationship(key, relationship) {
    if (relationship === 'hasMany') {
      return `${singularize(key)}_ids`;
    } else {
      return underscore(key);
    }
  },

  attrs: {
    logos: { serialize: 'ids', deserialize: 'records' }
  }
});
