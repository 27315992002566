import EsignedDocumentModel from './esigned-document';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { alias } from '@ember/object/computed';

export default class EsignedCremationAuthorizationModel extends EsignedDocumentModel {
  /** Only for POST; to be saved in data_snapshot */
  @attr('string') authorityReason;
  @attr('string') othersWithAuthority;
  @attr('string') signerRelationship;

  @belongsTo('v2/cremationAuthorization') cremationAuthorization;

  // Both of these relationships exist on `EsignedDocumentModel`, but if we
  // don't override them, the associated records aren't loaded correctly for
  // some reason.
  @hasMany('esignatures', { inverse: null }) esignatures;
  @hasMany('esignatureActions', { inverse: null }) esignatureActions;

  @alias('esignatures.firstObject') esignature;

  get isFinalized() {
    return (
      this.esignatureStatus === 'complete' ||
      this.esignatureStatus === 'declined'
    );
  }
}
