import { ActiveModelSerializer } from 'active-model-adapter';
import { isEmpty } from 'lodash-es/lang';

export default class IntegrationServiceSerializer extends ActiveModelSerializer {
    serialize(snapshot) {
        let payload = super.serialize(...arguments);
        
        if (!this.attributeChanged(snapshot, 'key')) {
            delete payload['key'];
        }
        
        if (!this.attributeChanged(snapshot, 'secret')) {
            delete payload['secret'];
        }

        return payload;
    }

    /**
     * Function to check if an attribute has been changed from its original value
     * @param {*} snapshot - The ember snapshot
     * @param {string} key - The string key of the attribute to check
     * @return {boolean} response - A boolean description of whether the attribute has changed or not.
     */
    attributeChanged(snapshot, key) {
        const changedAttributes = snapshot.changedAttributes();

        if (Object.keys(changedAttributes).includes(key)) {
            // Check to see if both the original value and the new values are empty.
            return !(isEmpty(changedAttributes[key][0]) && isEmpty(changedAttributes[key][1]));
        }

        return false;
    }
}
