import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),

  selection: null,
  accountingExportSelections: null,
  accountingExportSelectionsCount: 0, // This is here to trigger a change
  isLoading: false,
  isLoaded: false,
  isOpen: false,

  selected: computed(
    'accountingExportSelections',
    'accountingExportSelectionsCount',
    'selection',
    function() {
      const selection = this.selection;
      const accountingExportSelections = this.accountingExportSelections.toArray();
      return accountingExportSelections.includes(selection);
    }
  ),

  actions: {
    toggleSelection() {
      const selected = this.selected;
      const selection = this.selection;
      if (selected) {
        this.sendAction('removeSelection', selection);
      } else {
        this.sendAction('addSelection', selection);
      }
    },

    toggleDrawer() {
      set(this, 'isOpen', !this.isOpen);
      if (!this.isLoaded || this.isLoading) {
        set(this, 'isLoading', true);
        const selection = this.selection;
        const store = this.store;

        store
          .findRecord('v2/selection', get(selection, 'id'), {
            reload: true
          })
          .then(() => {
            set(this, 'isLoading', false);
            set(this, 'isLoaded', true);
          });
      }
    }
  }
});
