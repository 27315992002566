import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex\">\n  <div class=\"c--xs--12\">\n    <G::Crakn::Pikaday\n        @placeholder=\"Birth Date\"\n        @value={{@model.birthDate}}\n        @onChange={{this.setBirthDate}}/>\n  </div>\n</div>\n\n<G::Crakn::Form::Address\n    @showAddressLines={{false}}\n    @city={{@model.birthplaceAddressCity}}\n    @state={{@model.birthplaceAddressState}}\n    @zipCode={{@model.birthplaceAddressZipCode}}\n    @county={{@model.birthplaceAddressCounty}}\n    @township={{@model.birthplaceAddressTownship}}\n    @country={{@model.birthplaceAddressCountry}}\n    @showCountry={{true}}\n    @showCounty={{true}}/>\n", {"contents":"<div class=\"flex\">\n  <div class=\"c--xs--12\">\n    <G::Crakn::Pikaday\n        @placeholder=\"Birth Date\"\n        @value={{@model.birthDate}}\n        @onChange={{this.setBirthDate}}/>\n  </div>\n</div>\n\n<G::Crakn::Form::Address\n    @showAddressLines={{false}}\n    @city={{@model.birthplaceAddressCity}}\n    @state={{@model.birthplaceAddressState}}\n    @zipCode={{@model.birthplaceAddressZipCode}}\n    @county={{@model.birthplaceAddressCounty}}\n    @township={{@model.birthplaceAddressTownship}}\n    @country={{@model.birthplaceAddressCountry}}\n    @showCountry={{true}}\n    @showCounty={{true}}/>\n","moduleName":"crakn/components/g/kases/manage/edit/deceased/birth-info.hbs","parseOptions":{"srcName":"crakn/components/g/kases/manage/edit/deceased/birth-info.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment';

export default class CraknKasesManageEditDeceasedBirthInfoComponent extends Component {
  @action
  setBirthDate(value) {
    this.args.model.birthDate = moment(value);
  }
}