const wars = [
  {
    label: 'Civil War',
    value: 'civil_war'
  },
  {
    label: 'Spanish-American War',
    value: 'spanish_american_war'
  },
  {
    label: 'Mexican Border Period',
    value: 'mexican_border_period'
  },
  {
    label: 'World War I',
    value: 'world_war_one'
  },
  {
    label: 'World War II',
    value: 'world_war_two'
  },
  {
    label: 'Korean War',
    value: 'korean_war'
  },
  {
    label: 'Vietnam War',
    value: 'vietnam_war'
  },
  {
    label: 'Gulf War',
    value: 'gulf_war'
  },
  {
    label: 'War in Afghanistan',
    value: 'war_in_afghanistan'
  },
  {
    label: 'Iraq War',
    value: 'iraq_war'
  },
  {
    label: 'Other War',
    value: 'other_war'
  }
];

export { wars };
