import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class AuthenticatedAgreeToLegalRoute extends Route {
  @service session;

  layout = 'legal-fullscreen';
  isFullscreenActive = true;

  model() {
    return this.session.currentUser;
  }
}
