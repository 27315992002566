import Controller from '@ember/controller'
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import { tracked } from '@glimmer/tracking'

export default class AdminLocationsEditSpecialTaxesController extends Controller {
  @service flashes;
  @service store;

  @controller('authenticated/admin/locations/edit') edit;

  @tracked addingTax;
  @tracked selectWrapper;

  @action
  selectAddTax(taxId) {
    this.addingTax = this.store.findRecord('v2/specialTax', taxId);
  }
  
  @action
  async addTax() {
    const taxId = this.addingTax.get('id');
    const specialTaxes = await this.model.specialTaxes;

    if (specialTaxes.map((v) => v.get('id')).includes(taxId)){
      this.flashes.addError(`Tax is already assigned to ${this.model.get('address.name')}`);
      return;
    }

    specialTaxes.pushObject(await this.addingTax);

    this.selectWrapper.querySelector('select').selectedIndex = 0;
    this.addingTax = null;
  }

  @action
  async removeTax(taxId) {
    const taxes = await this.model.specialTaxes;

    const tax = await this.store.findRecord('v2/specialTax', taxId);
    taxes.removeObject(tax);
  }

  @action
  save(event) {
    this.edit.save(event);
  }
}