import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import Bugsnag from '@bugsnag/js';

export default class NotFoundRoute extends Route {
  @service session;
  @service router;

  beforeModel(err) {
    if (this.session.isAuthenticated) {
      return Bugsnag.notify(
        new Error(`Authenticated user visited a route that does not exist: ${err.intent.url}`),
        function (event) {
          event.severity = 'info';
        }
      ),
      this.router.transitionTo('authenticated.dashboard');
    } else {
      this.router.transitionTo('login');
    }
  }
}
