import Model, { hasMany, belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: validator('presence', true),
});

export default class OrganizationModel extends Model.extend(Validations) {
  @attr('string') name;
  @attr('string') addressCity;
  @attr('string') addressCountry;
  @attr('string') addressCounty;
  @attr('string') addressLine1;
  @attr('string') addressLine2;
  @attr('string') addressName;
  @attr('string') addressState;
  @attr('string') addressTownship;
  @attr('string') addressZipCode;
  @attr('string') clientId;
  @attr('string') name;
  @attr('string') website;

  @belongsTo('user', { async: true }) contactPerson;

  @hasMany('organizationLocation', { async: true }) organizationLocations;
  @hasMany('v2/locations', { async: true }) locations;
}
