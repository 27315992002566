import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isChecked}}\n  <button\n      type=\"button\"\n      class=\"btn btn--icon-only teal p--0\"\n      {{on \"click\" this.remove}}>\n    <MdIcon @size=\"sm\" @icon=\"check_box\"/>\n  </button>\n{{else}}\n  <button\n      type=\"button\"\n      class=\"btn btn--icon-only p--0\"\n      {{on \"click\" this.add}}>\n    <MdIcon @size=\"sm\" @icon=\"check_box_outline_blank\" />\n  </button>\n{{/if}}\n", {"contents":"{{#if this.isChecked}}\n  <button\n      type=\"button\"\n      class=\"btn btn--icon-only teal p--0\"\n      {{on \"click\" this.remove}}>\n    <MdIcon @size=\"sm\" @icon=\"check_box\"/>\n  </button>\n{{else}}\n  <button\n      type=\"button\"\n      class=\"btn btn--icon-only p--0\"\n      {{on \"click\" this.add}}>\n    <MdIcon @size=\"sm\" @icon=\"check_box_outline_blank\" />\n  </button>\n{{/if}}\n","moduleName":"crakn/components/g/online-planner/product-checkbox.hbs","parseOptions":{"srcName":"crakn/components/g/online-planner/product-checkbox.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class GOnlinePlannerProductCheckbox extends Component {
  get isChecked() {
    return this.args.checkedProducts.includes(this.args.product.id);
  }

  @action
  add() {
    this.args.onAdd(this.args.product);
  }

  @action
  remove() {
    this.args.onRemove(this.args.product);
  }
}
