import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import Validations from 'crakn/validations/authenticated/batch-payments/new';

export default class AuthenticatedBatchPaymentsNewRoute extends Route {
  @service session;

  model() {
    return this.store.createRecord('v2/generalPayment', {
      collectedAt: null,
      isBatchPayment: this.session.currentClient.can_view_general_payments ? false : true
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('changeset', Changeset(model, lookupValidator(Validations), Validations));
  }
}
