import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#unless @hide}}\n  {{#if @href}}\n    <a\n        aria-disabled={{@disabled}}\n        class=\"btn btn--icon-only grid-link-btn {{@class}}\"\n        data-tt={{@tooltip}}\n        href={{if @disabled \"\" @href}}\n        target={{@target}}>\n      <MdIcon\n          class=\"{{@iconClass}}\"\n          @icon={{@icon}}\n          @size=\"md\"/>\n    </a>\n  {{else}}\n    <button\n        class=\"btn btn--icon-only {{@class}}\"\n        data-tt={{@tooltip}}\n        disabled={{@disabled}}\n        type=\"button\"\n        {{on \"click\" this.onClick}}>\n      <MdIcon\n          @icon={{@icon}}\n          @size=\"md\"\n          class=\"{{@iconClass}}\"/>\n    </button>\n  {{/if}}\n{{/unless}}\n", {"contents":"{{#unless @hide}}\n  {{#if @href}}\n    <a\n        aria-disabled={{@disabled}}\n        class=\"btn btn--icon-only grid-link-btn {{@class}}\"\n        data-tt={{@tooltip}}\n        href={{if @disabled \"\" @href}}\n        target={{@target}}>\n      <MdIcon\n          class=\"{{@iconClass}}\"\n          @icon={{@icon}}\n          @size=\"md\"/>\n    </a>\n  {{else}}\n    <button\n        class=\"btn btn--icon-only {{@class}}\"\n        data-tt={{@tooltip}}\n        disabled={{@disabled}}\n        type=\"button\"\n        {{on \"click\" this.onClick}}>\n      <MdIcon\n          @icon={{@icon}}\n          @size=\"md\"\n          class=\"{{@iconClass}}\"/>\n    </button>\n  {{/if}}\n{{/unless}}\n","moduleName":"crakn/components/g/crakn/photo-grid/photo-button.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/photo-grid/photo-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class GCraknPhotoButton extends Component {
  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick()
    }
  }
}
