import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import moment from 'moment';
import { tracked } from '@glimmer/tracking';

export default class ArAgingByKaseReportComponent extends Component {
  @tracked asOfDate = moment().toISOString();
  @tracked organizationLocationId = null;
  @tracked reportKase = null;
  @tracked funeralDirectorId = '';
  @tracked showAdditionalDateFilters = false;
  @tracked startsAt = moment().subtract(30, 'day').toISOString();
  @tracked endsAt = moment().toISOString();

  get reportLink() {
    const params = new URLSearchParams({
      as_of_date: this.asOfDate,
      starts_at: this.showAdditionalDateFilters ? this.startsAt : null,
      ends_at: this.showAdditionalDateFilters ? this.endsAt : null,
      organization_location_id: this.organizationLocationId,
      kase_ids: [this.reportKase?.id],
      uuid: this.args.uuid,
      funeral_director_id: this.funeralDirectorId,
    }).toString();
    return `/report/ar_aging_by_kase?${params}`;
  }

  get fileUrl() {
    const params = new URLSearchParams({
      bigReport: true
    }).toString();

    return `#/generated_document/${this.args.uuid}?${params}`;
  }

  @action
  selectKase(kase) {
    if (kase === undefined) {
      return;
    }
    set(this, 'reportKase', kase);
  }

  @action
  setDate(attr, value) {
    set(this, attr, value);
  }

  @action
  clearKase() {
    set(this, 'reportKase', null);
  }
}
