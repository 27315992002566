import InquiryEditRoute from './edit';
import { inject as service } from '@ember/service';

export default InquiryEditRoute.extend({
  controllerName: 'authenticated.inquiries.edit',
  templateName: 'authenticated.inquiries.edit',
  location: service(),

  model() {
    return this.location.archived.then(() => {
      return this.store.createRecord('inquiry', { status: 'New' });
    });
  }
});
