import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedAdminFinancialConfigurationsEditAdditionalSettingsRoute extends Route {
  @service intl;

  model() {
    return this.modelFor('authenticated.admin.financial-configurations.edit');
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const locationConfiguration = model.locationConfiguration || [];
    controller.set('locationConfiguration', locationConfiguration);
  }

  @action
  willTransition(transition) {
    if (this.currentModel.get('hasDirtyAttributes')) {
      if (confirm(this.intl.t('authenticated.admin.financial-configurations.edit.additional-settings.messages.unsavedChanges'))) {
        this.currentModel.rollbackAttributes();
        return true;
      } else {
        transition.abort();
      }
    }
  }
}
