import Controller from '@ember/controller'
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminLocationsLogosController extends Controller {
  @service store;
  @service flashes;

  @controller('authenticated/admin/locations/edit') edit;

  @action
  updateSfgsLogo(logo, response) {
    this.model.defaultSfgsLogo = logo;
    this.success(response);
  }

  @action
  updateReceiptLogo(logo, response) {
    this.model.defaultReceiptLogo = logo;
    this.success(response);
  }

  @action
  updateSoaLogo(logo, response) {
    this.model.defaultSoaLogo = logo;
    this.success(response);
  }
  
  @action
  create({ sfgs, soa, receipt }) {
    return new Promise((resolve, reject) => {
      this.store.createRecord('v2/logo', { location: this.model, sfgs, soa, receipt })
      .save()
      .then(logo => resolve(logo))
      .catch(() => reject());
    });
  }
  
  @action
  success(response) {
    if (response) {
      this.store.push(response);
      this.flashes.addSuccess('Logo Successfully uploaded!');
    }
  }

  @action
  destroy(logo) {
    logo.destroyRecord()
        .then(() => {
          this.flashes.addSuccess('Logo Successfully Archived!');
        })
        .catch(() => {
          this.error();
        });
  }

  @action
  error() {
    this.flashes.addError('An error has occured.');
  }

  @action
  save(event) {
    this.edit.save(event);
  }
}