import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SidebarService extends Service {
  @service browser;

  @tracked isSidebarOpen = this.browser.isDesktop ? true : false;

  toggle() {
    set(this, 'isSidebarOpen', !this.isSidebarOpen);
  }

  setInitalState(state) {
    set(this, 'isSidebarOpen', state);
  }
}
