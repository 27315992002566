import SearchController from 'crakn/controllers/search';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class BatchPaymentsIndexController extends SearchController {
  @service router;
  @service session;

  queryParams = [
    'archived',
    'ends_at',
    'organization_location_id',
    'page',
    'perPage',
    'query',
    'sortBy',
    'starts_at',
  ];

  @tracked ends_at = moment().toISOString();
  @tracked order = 'DESC';
  @tracked organization_location_id = null;
  @tracked sortBy = null;
  @tracked sortOrder = 'asc';
  @tracked sortProperty = ['name'];
  @tracked starts_at = moment().subtract(30, 'day').toISOString();

  @sort('model', 'sortArray') sortedContacts;

  @computed('sortProperty', 'sortOrder')
  get sortArray() {
    return [`${this.sortProperty}:${this.sortOrder}`];
  }

  @action
  doAddNew() {
    this.router.transitionTo('authenticated.payments.batch-payments.new');
  }

  @action
  resetPagination() {
    this.set('page', 1);
  }

  @action
  setDate(attr, date) {
    this.set(attr, date.toISOString());

    this.resetPagination();
  }

  @action
  clear() {
    this.queryDidChange(null);
  }
}
