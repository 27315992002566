import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';

export default class CraknClientsController extends SearchController {
  @service api;
  @service flashes;
  @service router;

  sorting = ['name:asc'];
  @sort('model', 'sorting') sortedContent;

  @action
  async switchClient(client) {
    const resp = await this.api.json.post('clients/switch', { body: { id: client.id } })

    if (!resp.ok) {
      this.flashes.addError('Oh No! There was an error.');
      return;
    }

    await this.router.transitionTo('authenticated.dashboard');
    window.location.reload(true);
  }

}
