import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminFinancialConfigurationsEditAccountsRoute extends SearchRoute {
  @service router;
  @service session;

  modelType = 'account';
  queryParams = {
    archived: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    query: {
      refreshModel: true
    },
  };

  beforeModel() {
    if (this.session.currentUser.isSuperuser || this.session.currentClient.can_view_financial_config_accounts) {
      return true;
    }

    // Unauthorized users are redirected to the dashboard
    this.router.transitionTo('authenticated.dashboard');
  }

  model(params) {
    const coa = this.modelFor('authenticated.admin.financial-configurations.edit');
    return this.store.query('account', {
      chart_of_accounts_id: coa.get('id'),
      ...params,
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    const coa = this.modelFor('authenticated.admin.financial-configurations.edit');

    controller.set('chartOfAccounts', coa);
  }

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = ![
        'authenticated.admin.financial-configurations.index',
        'authenticated.admin.financial-configurations.edit'
      ].includes(transition.targetName);

      if (resetParams) {
        controller.setProperties({
          page: 1,
          query: null
        });
      }
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
