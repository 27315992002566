import Component from '@ember/component';
import { set } from '@ember/object';

export default Component.extend({
  first_name: null,
  last_name: null,
  address1: null,
  address2: null,
  city: null,
  state: null,
  postal: null,
  country: null,

  card: null,

  dispatchCardImageEvent() {
    const event = document.createEvent('HTMLEvents');
    setTimeout(function() {
      event.initEvent('change', false, true);
      document
        .getElementsByClassName('qa-name-on-credit-card')[0]
        .dispatchEvent(event);
    }, 500);
  },

  actions: {
    updateNameOnCard(firstName) {
      set(this, 'first_name', firstName);
      const lastName = this.last_name;
      const cardName = `${firstName} ${lastName}`;
      set(this.card, 'cc_name', cardName);
      this.dispatchCardImageEvent();
    },

    updateLastNameOnCard(lastName) {
      set(this, 'last_name', lastName);
      const firstName = this.first_name;
      const cardName = `${firstName} ${lastName}`;
      set(this.card, 'cc_name', cardName);
      this.dispatchCardImageEvent();
    }
  }
});
