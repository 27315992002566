import Service from '@ember/service';
import { inject as service } from '@ember/service';

export default class AdminNavigationService extends Service {
  @service session;

  get firstAvailableAdminRoute() {
    if (this.session.currentClient.can_view_users && this.session.currentUser.canViewUsers) {
      return 'authenticated.admin.manage.users';
    } else if (this.session.currentClient.can_view_groups && this.session.currentUser.canViewGroups) {
      return 'authenticated.admin.manage.groups';
    } else if (this.session.currentClient.can_view_tasks && (this.session.currentClient.can_view_tasks_admin && this.session.currentUser.canViewMiscTasks)) {
      return 'authenticated.admin.manage.tasks';
    } else if (this.session.currentClient.can_view_organizations && this.session.currentUser.canViewOrganizations) {
      return 'authenticated.admin.manage.organizations';
    } else if (this.session.currentClient.can_view_locations && this.session.currentUser.canViewLocations) {
      return 'authenticated.admin.manage.locations';
    } else if (this.session.currentClient.can_view_vehicles && this.session.currentUser.canViewVechicles) {
      return 'authenticated.admin.manage.vehicles';
    } else if (this.session.currentClient.can_view_financial_configurations && this.session.currentUser.hasFinancialPermission) {
      return 'authenticated.admin.manage.financial-configurations'
    } else if (this.session.currentClient.can_view_general_price_lists && this.session.currentUser.canViewGeneralPriceLists) {
      return 'authenticated.admin.manage.general-price-lists'
    } else if (this.session.currentClient.can_view_forms && this.session.currentUser.canViewForms) {
      return 'authenticated.admin.manage.forms'
    } else if (this.session.currentClient.can_view_data && this.session.currentUser.canViewData) {
      return 'authenticated.admin.data'
    } else if (this.session.currentClient.can_view_info && this.session.currentUser.canViewInfo) {
      return 'authenticated.admin.manage.info'
    } else if (this.session.currentClient.can_view_obit_generator && this.session.currentUser.canViewObituaryGenerator) {
      return 'authenticated.admin.manage.obituary-generator'
    } else if (this.session.currentClient.auto_kase_numbering && this.session.currentUser.canSetKaseNumbering) {
      return 'authenticated.admin.manage.case-numbering'
    } else if (this.session.currentClient.can_view_tribute_video && this.currentUser.canViewTributeVideo) {
      return 'authenticated.admin.manage.tribute-video'
    } else if (this.session.currentClient.can_view_cemsites) {
      return 'authenticated.admin.manage.cemsites'
    } else {
      return 'authenticated.dashboard'
    }
  }
}
