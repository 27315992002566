import Model, { belongsTo, attr } from '@ember-data/model';

export default class ReferralInfoModel extends Model {
  @attr('string') notes;
  @attr('string') referralType;

  @belongsTo('kase', { async: true }) kase;
  @belongsTo('person', { async: true }) person;
  @belongsTo('place', { async: false }) place;
}
