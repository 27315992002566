import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AuthenticatedAdminDataEmbalmReasonsRoute extends Route {
  queryParams = {
    page: {
      refreshModel: true
    }
  };

  model(params) {
    return this.store.query('v2/tag', {
      include: 'locations,locations.address',
      page: params.page,
      per_page: 10
    });
  }

  saveSuccess

  setupController(controller) {
    super.setupController(...arguments);

    controller.set('locations', this.store.query('v2/location', { include: 'address', perPage: 0 }));
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
