import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, action } from '@ember/object';

export default class SettingsCalendarController extends Controller {
  @service browser;
  @service flashes;
  @service store;

  @action
  generateCalendarUrl() {
    this.store
      .createRecord('secureToken')
      .save()
      .then((result) => {
        set(this, 'secureToken', result);
      });
  }

  @action
  regenerateCalendarUrl() {
    this.store
      .queryRecord('secureToken', { user_id: this.model.id })
      .then((record) => {
        record.destroyRecord().then(this.send('generateCalendarUrl'));
      });
  }

  @action
  destroyCalendarUrl() {
    this.store
      .queryRecord('secureToken', { user_id: this.model.id })
      .then((record) => {
        record.destroyRecord().then(set(this, 'secureToken', null));
      });
  }

  @action
  alertAfterCopy() {
    this.flashes.addSuccess(
      'Your calendar URL was successfully copied to your clipboard!'
    );
  }

  @action
  saveUser(event) {
    event.preventDefault();
    if (event.submitter) {
      event.submitter.disabled = true;
    }
    this.model
      .save()
      .then(() => {
        this.flashes.addSuccess('You successfully updated your settings!');
        this.transitionToRoute('authenticated.settings.info');
      })

      .catch(() => {
        this.flashes.addError('There was an error saving your preferences.');
      });
    if (event.submitter) {
      event.submitter.disabled = false;
    }
    return false;
  }
}
