import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex--sm--nowrap flex--xs--a--c\">\n  <div class=\"c--xs--12\">\n    {{!-- template-lint-disable attribute-indentation --}}\n    <G::Crakn::Autocomplete::Dynamic\n        @onSearch={{this.search}}\n        @onSelect={{@onSelect}}\n        @placeholder={{@placeholder}}\n        @value={{this.formQuery}}\n        @dataTest=\"enabled-forms-add-search\"\n        as |form|>\n\n      {{form.name}}\n\n      {{#if form.isEnabled}}\n        <small>{{t \"authenticated.admin.manage.tfe.forms.text.alreadySelected\"}}</small>\n      {{/if}}\n    </G::Crakn::Autocomplete::Dynamic>\n  </div>\n</div>\n", {"contents":"<div class=\"flex flex--sm--nowrap flex--xs--a--c\">\n  <div class=\"c--xs--12\">\n    {{!-- template-lint-disable attribute-indentation --}}\n    <G::Crakn::Autocomplete::Dynamic\n        @onSearch={{this.search}}\n        @onSelect={{@onSelect}}\n        @placeholder={{@placeholder}}\n        @value={{this.formQuery}}\n        @dataTest=\"enabled-forms-add-search\"\n        as |form|>\n\n      {{form.name}}\n\n      {{#if form.isEnabled}}\n        <small>{{t \"authenticated.admin.manage.tfe.forms.text.alreadySelected\"}}</small>\n      {{/if}}\n    </G::Crakn::Autocomplete::Dynamic>\n  </div>\n</div>\n","moduleName":"crakn/components/g/tfe/forms/autocomplete-enabled-forms.hbs","parseOptions":{"srcName":"crakn/components/g/tfe/forms/autocomplete-enabled-forms.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class GTfeFormsAutocompleteEnabledForms extends Component {
  @service session;
  @service store;

  @tracked formQuery = null;

  @action
  search(query) {
    if (this.args.onSearch) {
      return this.args.onSearch(query);
    }

    return this.store.query('tfe/document', {
      current_client: true,
      is_public: true,
      include: 'latest_revision',
      query
    });
  }
}
