import Component from '@glimmer/component';

export default class KaseTasksSection extends Component {
  get tasks(){
    return this.args.tasks;
  }

  get filteredTasks() {
    return this.args.tasks.filter(
      this.args.filters[this.args.currentFilterName]
    )
  }
}
