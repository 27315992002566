import { helper as buildHelper } from '@ember/component/helper';
import { provinces } from '@tributetech/ember-jsonb';

export function reviewLabel(array) {
  const [field, country] = array;

  const isState = (field) => {
    const parts = field?.name?.includes('_')
      ? field?.name?.split('_') || []
      : field?.name?.split('.') || [];
    const listName = parts[parts.length - 1];
    return listName === 'state';
  };

  const isZip = (field) => { return field?.label === 'Zip'; }

  if (field.type === 'select' && isState(field)) {
    const isProvince = provinces.find((x) => x.value === field.value);
    const useProvince = (!field.value && country === 'CA') || isProvince;
    return useProvince
      ? field?.label?.replace('State', 'Province')
      : field.label;
  }

  if (field.type === 'input' && country === 'CA' && isZip(field)) {
    return field?.label?.replace('Zip', 'Postal Code');
  }

  return field.label;
}

export default buildHelper(reviewLabel);
