import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  isMenuOpen: false,

  menuToggledClass: computed('isMenuOpen', function() {
    return this.isMenuOpen ? 'menu' : 'hideMenu';
  }),

  actions: {
    onClick() {
      this.toggleProperty('isMenuOpen');
    },

    selectSrcPerson(srcPersonRole) {
      this.toggleProperty('isMenuOpen');
      this.sendAction('smartEntry', srcPersonRole, this.destPersonRole);
    }
  }
});
