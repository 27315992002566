import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2ObituaryModel extends Model {
  @attr('string') draftText;
  @attr('string') formattedDraftText;
  @attr('string') formattedText;
  @attr('string') text;

  @belongsTo('v2/kase', { async: true }) kase;

  @hasMany('v2/newspaper', { async: true }) newspapers;
}
