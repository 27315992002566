import Model, { belongsTo, attr } from '@ember-data/model';

export default class NotificationModel extends Model {
  @attr('moment-datetime') sentAt;
  @attr('string') notificationMethod;

  @belongsTo('user', { async: true }) notified;
  @belongsTo('user', { async: true }) sender;
  @belongsTo('inquiry', { async: true }) inquiry;
  @belongsTo('kase', { async: true }) kase;

  get methodIcon() {
    let icon;

    switch (this.notificationMethod) {
      case 'email':
        icon = 'mail_outline';
        break;
      case 'sms':
        icon = 'chat_bubble_outline';
        break;
    }

    return icon;
  }
}
