import { run } from '@ember/runloop';
import RSVP from 'rsvp';
import { set, get } from '@ember/object';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default Route.extend({
  queryParams: {
    selection_id: {
      refreshModel: true
    }
  },
  session: service(),

  model(params) {
    const model = this.modelFor('authenticated.kases.manage.financials');

    return RSVP.hash({
      selectionParams: params,
      kase: get(model, 'kase'),
      kaseFinancial: get(model, 'kaseFinancial'),
      selection: params.selection_id
        ? this.store.findRecord('selection', params.selection_id)
        : '',
      selections: get(model, 'selections'),
      clientInfo: get(model, 'clientInfo'),
      discountTypes: this.store.query('discountType', {
        archived: false,
        gpl_id: get(model, 'kase.location.generalPriceList.id'),
        per_page: 1000
      }),
      newDiscount: this.store.createRecord('discount', {
        enteredBy: get(this, 'session.currentUser'),
        collectedBy: get(this, 'session.currentUser')
      })
    }).then(models => {
      // If selection_id is null display the selection with oldest positive balance
      const selectionParams = get(models, 'selectionParams');
      if (isEmpty(get(selectionParams, 'selection_id'))) {
        const oldestBalanceSelection = get(models, 'selections').filter(
          selection => {
            return get(selection, 'balance') > 0;
          }
        );

        if (isEmpty(oldestBalanceSelection)) {
          set(models, 'selection', get(models, 'selections.firstObject'));
        } else {
          set(models, 'selection', get(oldestBalanceSelection, 'firstObject'));
        }
      }

      return models;
    });
  },

  setupController(controller, models) {
    this._super(...arguments);
    // Selection_id is set here to handle setting routes to selection with oldest positive balance if empty
    if (get(models, 'selection')) {
      controller.set('selection_id', get(models, 'selection.id'));
    }
    controller.setProperties(models);
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('selection_id', null);
      controller.set('showValidation', false);
    }
  },

  handleDiscountSaveSuccess() {
    this.refresh();
    this.send('refreshKaseFinancial');
    this.send('flashSuccess', 'Discount successfully saved!');
  },

  handleDiscountRemoveSuccess() {
    this.refresh();
    this.send('refreshKaseFinancial');
    this.send('flashSuccess', 'Discount successfully removed.');
  },

  handleError() {
    this.send(
      'flashError',
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
    );
  },

  actions: {
    cancelNewDiscount() {
      if (confirm('This Discount will be cancelled. Are you sure?')) {
        set(this.controller, 'showValidation', false);
        this.refresh();
      }
    },

    saveNewDiscount(newDiscount) {
      newDiscount.setProperties({
        selection: this.currentModel.selection,
        enteredBy: get(this, 'session.currentUser')
      });

      newDiscount
        .save()
        .then(
          run.bind(this, 'handleDiscountSaveSuccess'),
          run.bind(this, 'handleError')
        );
    },

    removeDiscount(discount) {
      discount
        .destroyRecord()
        .then(
          run.bind(this, 'handleDiscountRemoveSuccess'),
          run.bind(this, 'handleError')
        );
    },

    setDiscountType(discount, discountTypeId) {
      const discountType = this.currentModel.discountTypes.findBy(
        'id',
        discountTypeId
      );
      set(discount, 'discountType', discountType);
    }
  }
});
