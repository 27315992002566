import Model, { belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class V2KaseCustomFieldModel extends Model {
  @service api;
  @hasMany('v2/custom-field', { async: false }) customFields;

  @belongsTo('v2/client') client;
  @belongsTo('v2/kase', { async: true }) kase;

  async sync() {
    return new Promise((resolve, reject) =>
      this.api.json
        .get(`v2/kase_custom_fields/${this.id}/sync`)
        .then((response) => {
          if (!response) {
            reject();
            return;
          }
          resolve(true);
        })
    );
  }
}
