import { cloneDeep } from 'lodash-es/lang';

export const validStatusTypes = [
  { id: 'preneed', name: 'Preneed' },
  { id: 'pending', name: 'Pending' },
  { id: 'in_progress', name: 'In Progress' },
  { id: 'completed', name: 'Completed' },
  { id: 'lost', name: 'Lost' },
  { id: 'archived', name: 'Archived', icon: 'archive' }
];

export function validStatusSortTypes() {
  const sortTypes = cloneDeep(validStatusTypes);

  sortTypes.unshift({ id: 'all', name: 'All' });
  sortTypes.unshift({ id: 'active', name: 'Active' });

  return sortTypes;
}
