import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2AccountModel extends Model {
  @attr('string') name;
  @attr('string') remoteId;
  @attr('string') accountType;
  
  @attr('number') qboProductServiceId;
  @attr('string') generalLedgerId;

  @belongsTo('v2/chartOfAccounts') chartOfAccounts;
}