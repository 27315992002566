import Model, { belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'account.id': validator('presence', {
    presence: true,
    message: 'The Account field can\'t be blank'
  }),
  'name': validator('presence', {
    presence: true,
    message: 'The Name field can\'t be blank'
  }),
});

export default class PaymentTypeModel extends Model.extend(Validations)  {
  @attr('string') name;
  @attr('moment-datetime', { defaultValue: null }) archivedAt;
  @attr('boolean') associateInsurancePolicy;
  @attr('boolean') processCreditCard;
  @attr('boolean') collectCheckNumber;
  @attr('boolean') collectCcLastFour;
  @attr('boolean') isTributePay;

  @belongsTo('account') account;
  @belongsTo('chart-of-accounts') chartOfAccounts;
  @belongsTo('integration-service') integrationService;
}
