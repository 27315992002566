import Controller from '@ember/controller';
import { action } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default class KasesManageDocumentsSecureController extends Controller {
  @service flashes;

  kase = null;

  documentSorting = ['createdAtUnix:desc'];
  @sort('documents', 'documentSorting') sortedAllDocuments;

  @action
  createDocument(documentParams) {
    documentParams.kase = this.kase;
    this.store
      .createRecord('document', documentParams)
      .save()
      .then(() => {
        this.flashes.addSuccess('Document successfully uploaded!');
        this.send('refreshModel');
      })
      .catch(() =>
        this.flashes.addError('Oops, something went wrong! Please try again.')
      );
  }
}
