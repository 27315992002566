import { bool } from '@ember/object/computed';
import Controller from '@ember/controller';

export default Controller.extend({
  queryParams: ['page', 'per_page', 'type'],

  type: 'ohio_edrs',
  page: 1,
  per_page: 15,

  modelLength: bool('model.length')
});
