import config from 'crakn/config/environment';
import Service, { inject as service } from '@ember/service';

export default class ApiService extends Service {
  @service session;
  @service('api/json') json;

  get token() {
    return this.session.data.authenticated.token;
  }

  get host() {
    return config.host;
  }

  get headers() {
    if (localStorage.getItem('sso_token') === null) {
      return {
        'X-CSRF-Token': this.session.data.authenticated.token,
      };
    } else {
      return {
        'Authorization': localStorage.getItem('sso_token'),
      };
    }
  }

  url(url, params) {
    if (url.match(/^\//)) {
      url = url.substring(1);
    }

    if (params) {
      return `${this.host}/api/${url}?${this.toSearchParams(params)}`;
    } else {
      return `${this.host}/api/${url}`;
    }
  }

  toSearchParams(params) {
    return new URLSearchParams(Object.entries(params));
  }

  async request(method, url, { params, body } = {}) {
    const headers = this.headers;

    return fetch(
      this.url(url, params),
      {
        method: method,
        credentials: 'include',
        headers: headers,
        body: body
      }
    );
  }

  async get(url, options = {}) {
    return this.request('GET', url, options);
  }

  async post(url, options = {}) {
    return this.request('POST', url, options);
  }

  async put(url, options = {}) {
    return this.request('PUT', url, options);
  }

  async delete(url, options = {}) {
    return this.request('DELETE', url, options);
  }
}
