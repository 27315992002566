import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2ArWriteoffModel extends Model {
  @belongsTo('v2/client') client;
  @belongsTo('v2/location') location;
  // @hasMany('v2/adjustment') adjustments;
  @attr('string') note;
  @attr('string') status;
  @attr('array') errors;
  @attr('string') requestedByName;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') endDate;
  @attr('moment-datetime') startDate;
  @attr('moment-datetime') updatedAt;
  @belongsTo('v2/adjustment-type') gainAdjustmentType;
  @belongsTo('v2/adjustment-type') lossAdjustmentType;
  @attr('boolean') reversed;
  @belongsTo('v2/user') requestedBy;
  @belongsTo('v2/user') createdBy;
  @belongsTo('v2/user') archivedBy;
}
