import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class KasesManageEditInfoRoute extends Route {
  layout = 'custom-aside';

  model() {
    return this.modelFor('authenticated.kases.manage');
  }

  @action
  willTransition() {
    this.currentModel.save();
  }
}
