import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class CiclopsSetupController extends Controller {
  showSecretField = true;
  secretPlaceholder = 'GUID';

  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
