import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Autocomplete::Dynamic\n    {{! template-lint-disable attribute-indentation }}\n    @placeholder={{@placeholder}}\n    @debounce={{@debounce}}\n    @value={{this.value}}\n    @disabled={{@disabled}}\n    @icon={{@icon}}\n    @onSearch={{this.search}}\n    @onSelect={{this.select}}\n    @onIconClick={{@onIconClick}}\n    @onDirtyFocusOut={{@onDirtyFocusOut}}\n    @searcherMode={{@searcherMode}}\n    as |item|>\n\n  {{#if (has-block)}}\n    {{yield item}}\n  {{else}}\n    <div class=\"package-info\">\n      <span class=\"package-info__name\">\n        {{item.name}}\n      </span>\n    </div>\n  {{/if}}\n\n</G::Crakn::Autocomplete::Dynamic>\n", {"contents":"<G::Crakn::Autocomplete::Dynamic\n    {{! template-lint-disable attribute-indentation }}\n    @placeholder={{@placeholder}}\n    @debounce={{@debounce}}\n    @value={{this.value}}\n    @disabled={{@disabled}}\n    @icon={{@icon}}\n    @onSearch={{this.search}}\n    @onSelect={{this.select}}\n    @onIconClick={{@onIconClick}}\n    @onDirtyFocusOut={{@onDirtyFocusOut}}\n    @searcherMode={{@searcherMode}}\n    as |item|>\n\n  {{#if (has-block)}}\n    {{yield item}}\n  {{else}}\n    <div class=\"package-info\">\n      <span class=\"package-info__name\">\n        {{item.name}}\n      </span>\n    </div>\n  {{/if}}\n\n</G::Crakn::Autocomplete::Dynamic>\n","moduleName":"crakn/components/g/crakn/autocomplete/package.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/autocomplete/package.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object'
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking'

/**
 * An autocomplete input that draws from deceased to find a kase
 * 
 * ## Arguments
 * - **placeholder** - *optional* the label for the autocomplete
 * - **disabled** - *optional* *default false* boolean for disabling the input
 * - **icon** - *optional* the icon to display in the right of the autocoomplete.  will not display
 *              without args.onIconClick
 * - **debounce** - *optional* *default 300* the delay in milliseconds between finish typing and the 
 *                  search function occuring
 * - **queryParams** - *optional* *default {}* additional params to send along with the 
 *                     query to the server
 * - **onSelect** - the function to run when an item in the dropdown is selected.  If this 
 * 									function returns a value, it will be assigned to this.value
 * - **onIconClick** - *optional* the function to run when the icon is clicked.  will not work without
 *                    args.icon
 * - **onDirtyFocusOut** - *optional* the function to run when the input has been interacted with 
 * 										(meaning the autocomplete is open), and the user clicked outside the provided 
 * 										dropdown
 * 
 * ## Useage
 * 
 * ### Non-Strict Mode
 * This Autocomplete only allow strict mode
 * 
 * ### Strict Mode
 * Anything typed into the input will be ignored.  Only values selected in the dropdown will be 
 * considered valid.  Navigating away from the input with an invalid value will revert the value to the 
 * last valid value or the original value
 * 
 * ```handlebars
 * <G::Crakn::Autocomplete::Package
 * 		 @placeholder="Select A Package"
 *     @value={{model.package}}
 * 		 @onSelect={{set model "package"}}
 * 		 @onDirtyFocusOut={{this.toggleCreateNew}} />
 * 
 * ```
 * 
 * @class GCraknAutocompleteProduct
 * @public
 */

export default class GCraknAutocompletePackage extends Component {
  /**
   * Ember data store
   * @private
   */
  @service store;
  /**
   * Ember simple auth session
   * @private
   */
  @service session;

  /**
   * The query used to search as well as the display in the input
   * @type {string}
   */
  @tracked value = '';
  
  /**
   * The additional parameters used on the query
   * @type {object}
   */
  get queryParams() {
    return this.args.queryParams || {};
  }

  /**
   * The function used to search the optimized list.
   * 
   * @param {*} query - the search query
   */
  @action
  async search(query) {
    if (this.args.onSearch) {
      return this.args.onSearch(query);
    }

    return this.store
               .query('v2/package', {
                 query,
                 ...this.queryParams
               });
  }

  /**
   * The function that runs when a dropdown item has been selected.  It calls out to args.onSelect 
   * and clears the input
   * 
   * @param {object} item - the item to assign value to
   */
  @action
  select(item) {
    this.args.onSelect(item);
    this.value = '';
    
    return this.args.searcherMode ? item.name : '';
  }
}
