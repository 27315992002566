import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { inject as service } from '@ember/service';
import config from '../../config/environment';

export default JSONAPIAdapter.extend({
  host: `${config.host}`,
  authorizer: 'authorizer:application',
  namespace: 'api',
  session: service(),
  include: null,
  useFetch: true,

  get headers() {
    if (localStorage.getItem('sso_token') === null) {
      return {
        'X-CSRF-Token': this.session.data.authenticated.token,
      };
    } else {
      return {
        'Authorization': localStorage.getItem('sso_token'),
      };
    }
  },

  // This is actually fetch options.
  // If for any reason the application starts throwing authentication exceptions for everything
  // this is probably the culprit
  ajaxOptions() {
    const options = this._super(...arguments)
    options['credentials'] = 'include';

    return options
  },

  /** Appends query parameters to the requests send out from this model
    * By default, this will attach the `include` param if set on the model's
    * adapter. This can be overidden in the save or update request through adapterOptions
    * e.g model.save({ adapterOptions: { include: 'relationship' } })
    */
  buildURL(modelName, id, snapshot, requestType, query) {
    let url = this._super(modelName, id, snapshot, requestType, query);
    const overrideInclude = snapshot && snapshot.adapterOptions && snapshot.adapterOptions.include;
    if (this.include || overrideInclude) {
      const include = overrideInclude ? snapshot.adapterOptions.include : this.include;
      url += `?include=${include}`;
    }
    return url;
  },

  plurals: {
    chartOfAccounts: 'chartsOfAccounts',
    familyPortalInvite: 'familyPortalInvites',
    'v2/chartOfAccounts': 'v2/chartsOfAccounts',
    'v2/familyPortalInvite': 'v2/familyPortalInvites'
  },

  /**
   * Whether or not a findAll should make a API request each time
   * @return {Boolean} Whether or not to make request
   */
  shouldReloadAll() {
    return true;
  },

  /**
   * Whether or not a record should be reloaded in the background
   * @return {Boolean} Whether or not to reload
   */
  shouldBackgroundReloadRecord() {
    return true;
  },

  pathForType(type) {
    if (type === 'v2/deceased') {
      return 'v2/deceased';
    } else if (type === 'v2/chart-of-accounts') {
      return 'v2/charts_of_accounts';
    } else if (type === 'v2/family-portal-invite') {
      return 'v2/family_portal_invites';
    } else if (type === 'v2/place-integration-service') {
      return 'v2/place_integration_services';
    } else if (type === 'v2/cause-of-death') {
      return 'v2/cause_of_death';
    } else if (type === 'v2/kase-cause-of-death') {
      return 'v2/kase_cause_of_death';
    } else {
      return this._super(type).replace(/-/g, '_');
    }
  }
});
