import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class ViewlogiesSetupController extends Controller {
  showSecretField = true;
  secretPlaceholder = 'Password';
  showUsernameField = true;
  usernamePlaceholder = 'Username'

  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
