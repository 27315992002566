import { run } from '@ember/runloop';
import RSVP from 'rsvp';
import { set, get } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),

  model() {
    const params = this.paramsFor('authenticated.misc-sales.edit');
    const clientInfoId = get(
      this,
      'session.session.authenticated.client.client_info_id'
    );

    return RSVP.hash({
      selection: this.store.findRecord('selection', params.selection_id),
      clientInfo: this.store.findRecord('clientInfo', clientInfoId),
      newDiscount: this.store.createRecord('discount', {
        enteredBy: get(this, 'session.currentUser'),
        collectedBy: get(this, 'session.currentUser')
      })
    }).then(model => {
      return RSVP.hash({
        ...model,

        discountTypes: this.store.query('discountType', {
          archived: false,
          gpl_id: get(model, 'selection.location.generalPriceList.id'),
          per_page: 1000
        })
      });
    });
  },

  setupController(controller, models) {
    this._super(...arguments);
    controller.setProperties(models);
  },

  handleDiscountSaveSuccess() {
    this.refresh();
    this.send('flashSuccess', 'Discount successfully saved!');
  },

  handleDiscountRemoveSuccess() {
    this.refresh();
    this.send('flashSuccess', 'Discount successfully removed.');
  },

  handleError() {
    this.send(
      'flashError',
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
    );
  },

  actions: {
    cancelNewDiscount() {
      if (confirm('This Discount will be cancelled. Are you sure?')) {
        set(this.controller, 'showValidation', false);
        this.refresh();
      }
    },

    saveNewDiscount(newDiscount) {
      newDiscount.setProperties({
        selection: this.currentModel.selection,
        enteredBy: get(this, 'session.currentUser')
      });

      newDiscount
        .save()
        .then(
          run.bind(this, 'handleDiscountSaveSuccess'),
          run.bind(this, 'handleError')
        );
    },

    removeDiscount(discount) {
      discount
        .destroyRecord()
        .then(
          run.bind(this, 'handleDiscountRemoveSuccess'),
          run.bind(this, 'handleError')
        );
    },

    setDiscountType(discount, discountTypeId) {
      const discountType = this.currentModel.discountTypes.findBy(
        'id',
        discountTypeId
      );
      set(discount, 'discountType', discountType);
    }
  }
});
