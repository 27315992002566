import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li class=\"list__item\">\n  <div class=\"list__content\">\n    <div class=\"list__title\">\n      {{@onlinePlannerConfigUserNotification.user.name}}\n    </div>\n\n    <div class=\"list__subtitle\">\n      {{@onlinePlannerConfigUserNotification.user.email}}\n    </div>\n  </div>\n\n  <div class=\"list__actions\">\n    <button\n        type=\"button\"\n        class=\"btn btn--link red\"\n        data-tl={{t\n            \"authenticated.admin.online-planner.edit.notification-settings.buttons.deleteRecipient\"}}\n        data-test=\"online-planner-config-notification-settings-delete-recipient\"\n        {{on \"click\" (set this \"showDialog\" true)}}>\n      <MdIcon @icon=\"close\"/>\n    </button>\n  </div>\n  <DeleteDialog\n      @showDialog={{this.showDialog}}\n      @onDelete={{this.deleteRecipient}}/>\n</li>\n", {"contents":"<li class=\"list__item\">\n  <div class=\"list__content\">\n    <div class=\"list__title\">\n      {{@onlinePlannerConfigUserNotification.user.name}}\n    </div>\n\n    <div class=\"list__subtitle\">\n      {{@onlinePlannerConfigUserNotification.user.email}}\n    </div>\n  </div>\n\n  <div class=\"list__actions\">\n    <button\n        type=\"button\"\n        class=\"btn btn--link red\"\n        data-tl={{t\n            \"authenticated.admin.online-planner.edit.notification-settings.buttons.deleteRecipient\"}}\n        data-test=\"online-planner-config-notification-settings-delete-recipient\"\n        {{on \"click\" (set this \"showDialog\" true)}}>\n      <MdIcon @icon=\"close\"/>\n    </button>\n  </div>\n  <DeleteDialog\n      @showDialog={{this.showDialog}}\n      @onDelete={{this.deleteRecipient}}/>\n</li>\n","moduleName":"crakn/components/g/admin/online-planner/user-notification-list.hbs","parseOptions":{"srcName":"crakn/components/g/admin/online-planner/user-notification-list.hbs"}});
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class GAdminOnlinePlannerNotificationSettingsUserListComponent extends Component {
  @service flashes;
  @service intl;
  @service store;

  @tracked showDialog = false;

  @action
  deleteRecipient() {
    this.args.onlinePlannerConfigUserNotification.destroyRecord().then(() => {
      this.flashes.addSuccess(this.intl.t('components.g.admin.online-planner.user-notification-list.alerts.remove.success'));

      this.args.refreshModel();
    }).catch(() => {
      this.flashes.addError(this.intl.t('components.g.admin.online-planner.user-notification-list.alerts.remove.error'));
      })
    set(this, 'showDialog', false);
  }
}
