import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    const store = this.store;
    return store.findAll('auth-token').then(results => {
      return results.get('firstObject');
    });
  }
});
