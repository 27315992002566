import Model, { hasMany, attr } from '@ember-data/model';

export default class EsignedDocumentModel extends Model {
  @attr('moment-datetime') createdAt;
  @attr('string') esignatureStatus;
  @attr('array') remainingSignatureFieldNames;

  @hasMany('esignatureAction') esignatureActions;
  @hasMany('esignature') esignatures;
}
