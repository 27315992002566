import Controller from '@ember/controller';
import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking';
import { inject as service} from '@ember/service'

export default class KasesEditSettingsController extends Controller {
  @service flashes;
  @service session;
  @service store;
  @service intl;
  @service api;
  @tracked kase;
  @tracked tributeWebsiteKaseIntegration;
  @tracked tributeWebsiteId;
  queryAttemtps = 4;
  timeout = 2000;
  nextDelay = 1000;
  reportConfig = this.store.queryRecord('report-config', {
    client_id: this.session.currentClient.id
  });

  async queryForTributeWebsiteKaseId() {
    do {
      await new Promise(resolve => setTimeout(resolve, this.nextDelay));
      this.kase = await this.store.findRecord('v2/kase', this.kase.get('id'), { reload: true });
      this.tributeWebsiteId = this.tributeWebsiteKaseIntegration.get('remoteId');
      this.queryAttemtps = this.queryAttemtps - 1;
      this.nextDelay = this.nextDelay + this.timeout;
    } while (!this.tributeWebsiteKaseIntegration.get('remoteId') && this.queryAttemtps > 0);

    if (!this.tributeWebsiteKaseIntegration.get('remoteId')) return;

    this.queryAttemtps = 4;
    this.timeout = 2000;
    this.nextDelay = 1000;
    this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.settings.caseSent'));
  }

  @action
  async sendToTributeWebsite() {
    await this.api.json.post(`tribute_website/kases/${this.kase.get('id')}/send_case`).then(resp => {
      if (!resp.ok) {
        this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.settings.resendError'))
        return;
      }

      this.queryForTributeWebsiteKaseId();
    });
  }

  @action
  async mergeFields() {
    const response = await this.store.query('v2/kaseCustomField', { kase_id: this.kase.id });

    if (!response) {
      return;
    }

    const customFields = response.toArray();

    if (response.length === 0) {
      return;
    }

    await customFields[0].sync().catch(() => {
      this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.settings.addError'));
      return;
    });

    const allFields = await this.store.peekAll('v2/customField')
    allFields?.forEach(field => {
      field.unloadRecord();
    });

    const kaseCustomFields = await this.store.peekAll('v2/kase-custom-field')
    kaseCustomFields?.forEach(field => {
      field.unloadRecord();
    });

    this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.settings.addSuccess'));
  }

  @action
  saveTributeWebsiteId() {
    this.tributeWebsiteKaseIntegration.set('remoteId', this.tributeWebsiteId);
    this.tributeWebsiteKaseIntegration.save().then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.settings.idSuccess'));
    });
  }

  @action
  saveIncludeInVirginiaVitalStatsReport(value) {
    this.kase.includeInVirginiaVitalStatsReport = value;
    this.saveKase();
  }

  @action
  saveJcgReportSendSurveyOptOut(value) {
    this.kase.jcgReportSendSurveyOptOut = value;
    this.saveKase();
  }

  @action
  saveKase() {
    this.kase
      .save()
      .then(() => this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.settings.saveKaseSuccess')))
      .catch(() =>
        this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.settings.saveKaseError'))
      );
  }

  @action
  async deleteDuplicateContract(e) {
    e.target.disabled = true; // avoid double-click issues

    const response = await this.api.json.delete('v2/selections/remove_duplicate_contract', { body: { kase_id: this.kase.id } })

    if (!response.ok) {
      this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.settings.deleteDuplicateContractError'));
      e.target.disabled = false;
      return;
    }

    const message = response.parsedJson.message;

    this.flashes.addSuccess(message);

    e.target.disabled = false;
  }

  @action
  async unlockChainOfCustody() {
    const response = await this.api.json.post('v2/custody_audits/reset_kase_chain_of_custody', { body: { kase_id: this.kase.id } });
    if (response?.parsedJson?.message === 'success') {
      this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.settings.resetCocSuccess'));
    } else {
      this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.settings.resetCocError'));
    }
  }
}
