import { readOnly, sort, oneWay, alias } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { next } from '@ember/runloop';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

const Validations = buildValidations({
  date: validator('presence', {
    presence: true,
    message: 'A date and time must be entered before saving'
  })
});

export default Component.extend(Validations, {
  api: service(),
  calendarDay: service(),
  store: service(),
  names: service(),
  staff: service(),
  flashes: service(),
  session: service(),
  browser: service(),
  location: service(),
  intl: service(),

  canEditEvents: readOnly('session.currentUser.canEditEvents'),
  canEditVehicles: readOnly('session.currentUser.isStaff'),

  isValid: alias('validations.isValid'),
  showFamilyArrivalTime: computed('model', function(){
    const model = this.get('model');
    return model.get('familyArrivalTime') != null
  }),
  showEmployeeArrivalTime: computed('model', function(){
    const model = this.get('model');
    return model.get('employeeArrivalTime') != null
  }),

  deceased: null,
  isCalendar: null,
  inlineEditing: true,
  showEditIcon: true,
  showRemoveIcon: true,
  showValidation: false,

  date: alias('model.startsAt'),
  filteredVehicles: alias('model.eventVehicles'),
  vehicleSorting: ['vehicle.name:asc'],
  vehicles: sort('filteredVehicles', 'vehicleSorting'),

  excludeId: computed('model.eventAttendees', {
    get(){
    return this.model.eventAttendees.map((x) => x.person.get('id'));
  }}),

  eventVehicles: computed('model', {
    get() {
      const event = this.model;
      return this.store.query('eventVehicle', {
        event: event.get('id')
      });
    }
  }),

  cfsPlaceValidation: computed('model', {
    get() {
      const event = this.model;
      const place = event.place;
      if (place) {
        if (
          place.line1 === null ||
          place.city === null ||
          place.state === null ||
          place.zipCode === null
        ) {
          return true;
        }
      }
    },
  }),

  tributeWebsiteExportable: computed('model.eventTypeName', function() {
    if (this.model.eventTypeName) {
      return true;
    } else {
      return false;
    }
  }),

  invalidTributeWebsiteName: computed('model.name', function() {
    if (!this.model.name) {
      return true;
    } else if (this.model.name.length < 2 || this.model.name.length > 100) {
      return true;
    } else {
      return false;
    }
  }),

  emptyPlaceName: computed('model.place', function() {
    if (!this.model.place) {
      return true;
    } else if (!this.model.place.name) {
      return true;
    } else {
      return false;
    }
  }),

  tributeWebsiteErrors: computed('model.{eventTypeName,name,place}', function() {
    const errors = [];

    if (!this.tributeWebsiteExportable) { errors.push(this.intl.t('authenticated.kases.events.edit.warnings.tributeWebsiteEventTypeEmpty')); }
    if (this.invalidTributeWebsiteName) { errors.push(this.intl.t('authenticated.kases.events.edit.warnings.tributeWebsiteNameInvalid')); }
    if (this.emptyPlaceName) { errors.push(this.intl.t('authenticated.kases.events.edit.warnings.tributeWebsiteEmptyPlaceName')); }

    return errors;
  }),

  hasMessenger: computed('model.{location,location.hasMessengerIntegration}', 'session.currentClient.can_view_messenger', function() {
    if (this.model.location) {
      return this.model.location.hasMessengerIntegration;
    }

    return this.session.currentClient.can_view_messenger;
  }),

  hasBassMollett: computed('model.{location,location.hasBassMolletIntegration}', 'session.currentClient.can_view_bass_mollett', function() {
    if (this.model.location) {
      return this.model.location.hasBassMollettIntegration;
    }

    return this.session.currentClient.can_view_bass_mollett;
  }),

  confirmOnDirty: null,
  preventRollback: false,
  model: null,
  allStaffMembers: null,
  newEventStaffMember: null,
  newEventStaffMemberId: null,
  pendingEventAttendee: null,
  asdServiceTypes: [],
  eventTypes: null,
  messengerEventTypes: ['Prayer Service', 'Mass', 'Service', 'Visitation'],
  ciclopsEventNames: [
    'Celebration of Life',
    'Funeral Service',
    'Graveside',
    'Interment',
    'Inurnment',
    'Life Celebration',
    'Mass',
    'Mass of Christian Burial',
    'Memorial Mass',
    'Memorial Service',
    'Memorial Visitation',
    'Order of Service',
    'Visitation',
    'Other',
    'Final Resting Place',
    'Place of Rest'
  ],
  tributePrintEventNames: [
    'Celebration of Life',
    'Funeral Service',
    'Graveside',
    'Interment',
    'Inurnment',
    'Life Celebration',
    'Mass',
    'Mass of Christian Burial',
    'Memorial Mass',
    'Memorial Service',
    'Memorial Visitation',
    'Order of Service',
    'Visitation',
    'Other',
    'Final Resting Place',
    'Place of Rest'
  ],
  viewlogiesEventTypes: ['MemorialService', 'Celebration of Life', 'Vigil'],
  hasFrazer: oneWay('session.currentClient.can_view_frazer'),
  hasFuneralone: oneWay('session.currentClient.can_view_funeralone'),
  hasCiclops: oneWay('session.currentClient.can_view_ciclops'),
  hasTributePrint: oneWay(
    'session.currentClient.can_view_tribute_print'
  ),
  hasASD: oneWay('session.currentClient.can_view_asd'),
  hasBatesville: oneWay('session.currentClient.can_view_batesville'),
  hasCFS: oneWay('session.currentClient.can_view_cfs'),
  hasTCO: oneWay('session.currentClient.can_view_tribute_website'),
  hasTCOV2: oneWay('session.currentClient.can_view_tribute_website'),
  hasTukios: oneWay('session.currentClient.can_view_tukios'),
  hasViewlogies: oneWay('session.currentClient.can_view_viewlogies'),
  hasFamilyArrival: oneWay(
    'session.currentClient.can_view_family_arrival_time'
  ),
  hasfrontrunner: oneWay('session.currentClient.can_view_frontrunner'),
  hasfuneralTech: oneWay('session.currentClient.can_view_funeraltech'),
  hasEmployeeArrival: oneWay(
    'session.currentClient.can_view_employee_arrival_time'
  ),

  init() {
    this._super(...arguments);
    if (this.hasASD) {
      this.populateASDServiceTypes();
    }
    this.createStaffMember();
    this.populateEventTypes();
  },

  willDestroyElement() {
    this._super(...arguments);

    if (!this.preventRollback) {
      this.rollbackEvent();
    }
  },

  handleSaveVehicleSuccess() {
    this.flashes.addSuccess('Vehicle successfully added to this event!');
  },

  handleSaveVehicleError() {
    this.flashes.addError('Oops! There was an error adding this vehicle!');
  },

  handleRemoveVehicleSuccess() {
    this.flashes.addSuccess('Vehicle successfully removed!');
  },

  handleRemoveVehicleError() {
    this.flashes.addError('Oops! There was an error removing this vehicle!');
  },

  createStaffMember() {
    this.setProperties({
      newEventStaffMember: this.store.createRecord('eventStaffMember'),
      newEventStaffMemberId: null
    });
  },

  createAttendee(person) {
    this.store.createRecord('eventAttendee', {
      event: this.model,
      person
    });
  },

  createEventVehicle() {
    this.setProperties({
      newEventVehicle: this.store.createRecord('eventVehicle'),
      newEventVehicleId: null
    });
  },

  rollbackEvent(event) {
    event = event || this.model;

    event.rollbackAttributes();

    get(event, 'eventStaffMembers').forEach(function(staffMember) {
      if (get(staffMember, 'hasDirtyAttributes')) {
        staffMember.rollbackAttributes();
      }
    });
    get(event, 'eventAttendees').forEach(function(attendee) {
      if (get(attendee, 'hasDirtyAttributes')) {
        attendee.rollbackAttributes();
      }
    });
  },

  notifyStaffMember(staffMember) {
    if (get(staffMember, 'notifyStaffMember')) {
      set(staffMember, 'notifyStaffMember', undefined);
      staffMember.notify();
    }
  },

  populateASDServiceTypes() {
    const context = this;

    this.api.json
        .get('asd/service_types')
        .then(response => {
          if (response.ok) {
            const serviceTypes = response.parsedJson['service_types'].map((type) => type.serviceType).sort()
            set(context, 'asdServiceTypes', serviceTypes);
          }
        });
  },

  populateEventTypes() {
    this.store.query('eventType', {}).then(results => {
      set(this, 'eventTypes', results);
    });
  },

  actions: {
    setStartsAt(dateTime) {
      set(this, 'model.startsAt', dateTime);
      this.send('updateCalendarDate', dateTime);
    },

    roleSearch(query) {
      const queryRecord = this.store.createRecord('eventStaffMember');
      return queryRecord.roles({ query: query })
                        .then((resp => {
                          queryRecord.destroyRecord();
                          return resp.event_staff_members.map(e => e.role);
                        }));
    },

    searchEventName(query) {
      return this.store
                 .query('eventName', {
                   query: query
                 }).then((results) => {
                   return results.map((eventName) => eventName.name);
                 });
    },

    deleteEvent() {
      this.sendAction('onDeleteEvent', this.model);
    },

    cancelEvent() {
      const event = this.model;
      const hasOnCancelEvent = this.onCancelEvent;
      const eventIsDirty =
        this.confirmOnDirty &&
        (get(event, 'hasDirtyAttributes') ||
          get(event, 'eventStaffMembers')
            .filterBy('hasDirtyAttributes', true)
            .get('length') ||
          get(event, 'eventAttendees')
            .filterBy('hasDirtyAttributes', true)
            .get('length'));

      if (eventIsDirty && confirm('You have unsaved changes. Are you sure?')) {
        this.rollbackEvent(event);

        if (hasOnCancelEvent) {
          this.sendAction('onCancelEvent', event);
        }
      } else if (!eventIsDirty) {
        if (hasOnCancelEvent) {
          this.sendAction('onCancelEvent', event);
        }
      }
    },

    saveEvent() {
      const event = this.model;

      set(this, 'preventRollback', true);

      if (this.isValid) {
        set(this, 'showValidation', false);
      } else {
        set(this, 'showValidation', true);
        return;
      }

      event.save().then(result => {
        get(result, 'eventStaffMembers').forEach(staffMember => {
          if (get(staffMember, 'hasDirtyAttributes')) {
            staffMember.save().then(() => {
              this.notifyStaffMember(staffMember);
            });
          } else {
            this.notifyStaffMember(staffMember);
          }
        });

        get(result, 'eventAttendees').forEach(function(attendee) {
          if (get(attendee, 'hasDirtyAttributes')) {
            attendee.save();
          }
        });

        if (this.onSaveEvent) {
          this.sendAction('onSaveEvent', event);
        }
      });
    },

    saveAndAddAnotherEvent() {
      const event = this.model;

      set(this, 'preventRollback', true);

      if (this.isValid) {
        set(this, 'showValidation', false);
      } else {
        set(this, 'showValidation', true);
        return;
      }

      event.save().then(result => {
        get(result, 'eventStaffMembers').forEach(staffMember => {
          if (get(staffMember, 'hasDirtyAttributes')) {
            staffMember.save().then(() => {
              this.notifyStaffMember(staffMember);
            });
          } else {
            this.notifyStaffMember(staffMember);
          }
        });

        get(result, 'eventAttendees').forEach(function(attendee) {
          if (get(attendee, 'hasDirtyAttributes')) {
            attendee.save();
          }
        });

        if (this.onSaveAddAnotherEvent) {
          this.sendAction('onSaveAddAnotherEvent', event);
        }
      });
    },

    onSelectEvent(event) {
      this.sendAction('onSelectEvent', event);
    },

    onSelectKase(kase) {
      if (kase) {
        set(this.model, 'location', kase.location);
        set(this.model, 'kase', kase);
      } else {
        set(this.model, 'location', null);
        set(this.model, 'kase', null);
      }
    },

    updateCalendarDate(date) {
      if (!date || !date.isValid()) {
        return;
      }
      this.calendarDay.fetchDate(date);
    },

    updateFamilyTime(date) {
      const model = this.model;
      const eventDate = model.get('startsAt');
      if (eventDate !== null && eventDate !== undefined) {
        const updatedTime = moment([
          eventDate.year(),
          eventDate.month(),
          eventDate.date(),
          date.hours,
          date.minutes
        ]);
        next(() => {
          model.set('familyArrivalTime', updatedTime);
        });
      }
    },

    updateEmployeeTime(date) {
      const model = this.model;
      const eventDate = model.get('startsAt');
      if (eventDate !== null && eventDate !== undefined) {
        const updatedTime = moment([
          eventDate.year(),
          eventDate.month(),
          eventDate.date(),
          date.hours,
          date.minutes
        ]);
        next(() => {
          model.set('employeeArrivalTime', updatedTime);
        });
      }
    },

    async setEventPlace(v2Place) {
      await this.store.findRecord('place', v2Place.id).then(v1Place => {
        this.model.set('place', v1Place);
      });
    },

    addEventVehicle(newEventVehicle) {
      const model = this.model;
      const eventVehicles = get(model, 'eventVehicles');

      if (!newEventVehicle) {
        return this.flashes.addError(
          'Looks like you forgot to select a vehicle.'
        );
      }

      if (eventVehicles.mapBy('vehicle.id').includes(newEventVehicle.id)) {
        return this.flashes.addError(
          'Whoops! That vehicle is already assigned to this event.'
        );
      }

      this.store
        .createRecord('eventVehicle', {
          vehicle: newEventVehicle,
          event: this.model
        })
        .save()
        .then(() => {
          this.handleSaveVehicleSuccess();
          this.createEventVehicle();
        })
        .catch(() => {
          this.handleSaveVehicleError();
        });
    },

    removeEventVehicle(eventVehicle) {
      eventVehicle
        .destroyRecord()
        .then(() => {
          this.handleRemoveVehicleSuccess();
        })
        .catch(() => {
          this.handleRemoveVehicleError();
        });
    },

    selectRoom(roomId) {
      const event = this.model;

      if (!roomId) {
        event.set('room', null);
        return;
      }

      this.store.find('room', roomId).then(function(room) {
        event.set('room', room);
      });
    },

    assignStaffMember(eventStaffMember) {
      const user = get(eventStaffMember, 'user.id');

      if (!user) {
        return this.flashes.addError(
          'Looks like you forgot to select a staff member.'
        );
      }

      if (get(this, 'model.eventStaffMembers').findBy('user.id', user)) {
        return this.flashes.addError(
          'Whoops! That staff member is already assigned to this event.'
        );
      }

      set(eventStaffMember, 'event', this.model);
      this.createStaffMember();
    },

    removeStaffMember(eventStaffMember) {
      eventStaffMember.destroyRecord();
    },

    toggleNotifyStaffMember(eventStaffMember) {
      eventStaffMember.toggleProperty('notifyStaffMember');
    },

    addEventAttendee(person) {
      if (person === null || (person && get(person, 'isNew'))) {
        return set(this, 'pendingEventAttendee', person);
      }

      this.createAttendee(person);
    },

    saveNewEventAttendee() {
      return this.pendingEventAttendee.save().then(result => {
        set(this, 'pendingEventAttendee', null);

        return this.createAttendee(result);
      });
    },

    removeEventAttendee(eventAttendee) {
      eventAttendee.destroyRecord();
    },

    toggleFamilyArrivalTime() {
      const newShowFamilyArrivalTimeState = !this.get('showFamilyArrivalTime');
      const model = this.get('model');
      const startsAt = model.get('startsAt');
      if (newShowFamilyArrivalTimeState && startsAt) {
        set(model, 'familyArrivalTime', moment(startsAt));
      } else {
        set(model, 'familyArrivalTime', null);
      }
      set(this, 'showFamilyArrivalTime', newShowFamilyArrivalTimeState);
    },

    toggleEmployeeArrivalTime() {
      const newShowEmployeeArrivalTimeState = !this.get('showEmployeeArrivalTime');
      const model = this.get('model');
      const startsAt = model.get('startsAt');
      if (newShowEmployeeArrivalTimeState && startsAt) {
        set(model, 'employeeArrivalTime', moment(startsAt).subtract(1, 'hours'));
      } else {
        set(model, 'employeeArrivalTime', null);
      }
      set(this, 'showEmployeeArrivalTime', newShowEmployeeArrivalTimeState);
    }
  }
});
