import Route from '@ember/routing/route';

export default class CemsitesNewRoute extends Route {
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('cemsitesClientUuid', null);
      controller.set('showCemsitesResults', false);
      controller.set('cemsitesPlaces', null);
      controller.set('craknCemsitesPlace', null);
    }
  }
}