import Component from '@ember/component';
import { get, computed } from '@ember/object';

export default Component.extend({
  classNameBindings: ['qaClass'],
  model: null,

  qaClass: computed('model.id', function() {
    return `qa-event-summary-${get(this, 'model.id')}`;
  })
});
