import Model, { belongsTo, attr } from '@ember-data/model';
import { startCase } from 'lodash-es/string';
import { camelize } from '@ember/string';

export default class KaseStaffMemberModel extends Model {
  @attr('string') role;

  @belongsTo('kase', { async: true }) kase;
  @belongsTo('user', { async: true }) user;

  get formattedRole() {
    return startCase(camelize(this.role));
  }
}
