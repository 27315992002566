import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { validate } from 'crakn/utils/custom-fields-helpers';

export default class AdminCustomFieldEditController extends Controller {
  @service flashes;
  @service router;
  @service session;
  @service store;
  @service intl;

  get locationNameLabel() {
    return (
      this.customFieldConfig.location.get('name') ||
      this.intl.t('authenticated.admin.custom-fields.edit.allLocationsLabel')
    );
  }

  @action
  removeCustomField(field) {
    this.customFieldConfig.customFields.removeObject(field);
  }

  @action
  addCustomField() {
    this.store.createRecord('v2/custom-field', {
      customFieldConfig: this.customFieldConfig
    });
  }

  @action
  cancelEdit(field) {
    if (field.hasDirtyAttributes)  {
      field.rollbackAttributes();
    }
  }

  @action
  close() {
    const customFieldDirtyAttributes = this.customFieldConfig.customFields.any(
      (field) => field.get('hasDirtyAttributes')
    );

    if (customFieldDirtyAttributes) {
      const confirmLeave = confirm(
        this.intl.t('authenticated.admin.custom-fields.edit.actions.close')
      );

      if (confirmLeave) {
        this.router.transitionTo('authenticated.admin.manage.custom-fields');
      }
    } else {
      this.router.transitionTo('authenticated.admin.manage.custom-fields');
    }
  }

  @action
  validateFields() {
    let isValid = true;
    this.customFieldConfig.get('customFields').forEach(field => {
      const result = validate(field)
      if (!result.isValid) {
        isValid = false;    
      }
    });
    return isValid;
  }

  @action
  saveAndClose() {
    if (!this.validateFields()) {
      this.flashes.addError(this.intl.t('authenticated.admin.custom-fields.edit.actions.validate'));
      return;
    }

    this.customFieldConfig
      .save()
      .then(() => {
        this.flashes.addSuccess(this.intl.t('authenticated.admin.custom-fields.edit.actions.save'));
        this.router.transitionTo('authenticated.admin.manage.custom-fields');
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.custom-fields.edit.actions.error'));
      });
  }

  @action
  save() {
    this.customFieldConfig
      .save()
      .then(() => {
        // When the model is refreshed all records will be duplicated due
        // to how they are pushed to the store in the customFieldConfig serializer
        this.store.unloadAll('v2/custom-field');
        this.send('refreshModel');
        this.flashes.addSuccess(this.intl.t('authenticated.admin.custom-fields.edit.actions.save'));
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.custom-fields.edit.actions.error'));
      });
  }
}
