import { alias, match } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import InquiryActions from 'crakn/mixins/inquiry-actions';
import { routeMatchRegex } from 'crakn/utils/regex-helpers';

export default Controller.extend(InquiryActions, {
  queryParams: ['redirectPath'],
  application: controller(),
  selectionContract: null,

  currentRoute: alias('application.currentRouteName'),

  isNotesEditable: true,

  isDeceasedRouteActive: match('currentRoute', routeMatchRegex('deceased')),

  isSelectionRouteActive: match('currentRoute', routeMatchRegex('selection')),

  isIntegrationRouteActive: match(
    'currentRoute',
    routeMatchRegex('integrations')
  ),
  
  isVeteranInfoRouteActive: match('currentRoute', routeMatchRegex('veteran-info')),
});
