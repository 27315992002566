import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminTributePayPaymentsAccountsNewRoute extends Route {
  @service store;

  model() {
    return this.store.createRecord('integration-service', { name: 'tribute_pay_account' });
  }

  setupController(controller) {
    super.setupController(...arguments);

    this.store.query('location', { archived: false, per_page: 1000, exclude_integration: 'tribute_pay_account' }).then((locations) => {
      controller.set('locations', locations.toArray());
    });
  }
}
