import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AuthenticatedKasesManageEditObituaryRoute extends Route {
  layout = 'custom-aside';

  async model() {
    const kaseId = this.modelFor('authenticated.kases.manage').get('id');
    const kase = await this.store.findRecord('v2/kase', kaseId);

    const obituary = await this
                           .store
                           .query('v2/obituary', {
                             kase_id: kaseId,
                             include: 'newspapers,newspapers.obituary'
                           })

    if (obituary && obituary.length > 0) {
      return obituary.get('firstObject');
    } else {
      return this.store.createRecord('v2/obituary', { kase: kase });
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const v1Kase = this.modelFor('authenticated.kases.manage');

    controller.setProperties({
      v1Kase: v1Kase,
      kase: model.kase,
      setText: model.formattedDraftText || model.formattedText
    });
  }

  @action
  willTransition() {
    const obit = this.controller.get('model');
    if (Object.keys(obit.changedAttributes() || {}).length > 0) {
      obit.save(); // This will preserve draft text if they navigate away
    }
    const kase = this.modelFor('authenticated.kases.manage');
    if (kase.hasDirtyAttributes) {
      kase.save();
    }
  }

  @action
  refresh() {
    this.refresh();
  }
}
