import Model, { hasMany, attr } from '@ember-data/model';

export default class ClientInfoModel extends Model {
  @attr('string') clientId;
  @attr('string') warranties;
  @attr('string') terms;
  @attr('string') disclosures;
  @attr('string') acknowledgementAndAgreement
  @attr('string') obituaryTemplate;
  @attr('string') obituaryTemplateFormatted;
  @attr('string', { defaultValue: 'Included' }) packageItemFormat;
  @attr('string', { defaultValue: 'Default' }) sfgsFontSize;
  @attr('string') defaultReceiptLogoId;
  @attr('string') defaultReceiptLogoUrl;
  @attr('string') defaultSfgsLogoId;
  @attr('string') defaultSfgsLogoUrl;
  @attr('string') defaultSoaLogoId;
  @attr('string') defaultSoaLogoUrl;
  @attr('string') defaultCremAuthHeaderId;
  @attr('string') defaultCremAuthHeaderUrl;
  @attr('boolean') locationBasedLogos;
  @attr('boolean') organizationBasedLogos;
  @attr('boolean') locationBasedKaseNumbering;
  @attr('string') kaseNumberingFormat;
  @attr('boolean') kaseNumberingResetsYearly;
  @attr('boolean') kaseNumberingResetsMonthly;
  @attr('number') kaseNumberingStartingNumber;

  @hasMany('logo', { async: true }) logos;

  get fontSize() {
    switch (this.sfgsFontSize) {
      case 'Larger':
        return 'large-font-size';
      case 'Automatic':
        return 'automatic-font-size';
      default:
        return 'default-font-size';
    }
  }
}
