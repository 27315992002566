import RSVP from 'rsvp';
import { get, set } from '@ember/object';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { v4 as uuidv4 } from 'uuid';

export default Route.extend({
  api: service(),
  flashes: service(),
  session: service(),
  store: service(),
  intl: service(),

  queryParams: {
    selection_id: {
      refreshModel: true,
      per_page: 1000
    }
  },

  get contractText() {
    return this.session.currentClient.relabel_contract_to_invoice ?
    `invoice` : `contract`;
  },

  async model(params) {
    const model = this.modelFor('authenticated.kases.manage.financials');
    const kase = get(model, 'kase');
    const models = await RSVP.hash({
      selectionParams: params,
      kase,
      kaseFinancial: get(model, 'kaseFinancial'),
      selection: params.selection_id
        ? this.store.findRecord('selection', params.selection_id)
        : '',
      selections: get(model, 'selections'),
      clientInfo: get(model, 'clientInfo'),
      contractTemplates: this.store.query('v2/contractTemplate', {
        client_id: this.session.currentClient.id,
        selection_id: params.selection_id
      })
    });
    // If selection_id is null display the selection with oldest positive balance
    const selectionParams = get(models, 'selectionParams');
    if (isEmpty(get(selectionParams, 'selection_id'))) {
      const oldestBalanceSelection = get(models, 'selections').filter(
        selection => {
          return get(selection, 'balance') > 0;
        }
      );

      if (isEmpty(oldestBalanceSelection)) {
        set(models, 'selection', get(models, 'selections.firstObject'));
      } else {
        set(models, 'selection', get(oldestBalanceSelection, 'firstObject'));
      }
    }
    return models;
  },

  async setupController(controller, models) {
    this._super(...arguments);

    const customSFGS = get(this, 'session.currentClient.can_use_custom_sfgs');
    const canViewStatementCategories = get(this, 'session.currentClient.can_view_statement_categories');
    const gpl_id = get(models, 'kase.location.generalPriceListId');

    if (customSFGS && canViewStatementCategories) {
      controller.setProperties({
        statementCategories: this.store.query('v2/statementCategory', { gpl_id, archived: false, per_page: 100 }),
        statementSubcategories: this.store.findAll('statementSubcategory'),
      });
    } else {
      controller.setProperties({
        statementCategories: null,
        statementSubcategories: null
      });
    }

    // Selection_id is set here to handle setting routes to selection with oldest positive balance if empty
    if (get(models, 'selection')) {
      controller.set('selection_id', get(models, 'selection.id'));
      controller.set('originalIsBadDebt', get(models, 'selection.isBadDebt'));
      if (get(models, 'selection.purchaser')) {
        set(
          models,
          'selection.selectionPurchaser',
          get(models, 'selection.purchaser')
        );
        set(models, 'selection.savedChanges', false);
      }
    }
    controller.set(
      'generalPriceListId',
      get(models, 'kase.location.generalPriceList.id')
    );
    controller.set('selectedContractTemplateId',
      models.selection.get('lastUsedContractTemplate.id') ||
      models.kase.get('location.defaultContractTemplate.id'));

    controller.setProperties(models);
  },

  handleSaveSuccess() {
    this.refresh();
    this.send('refreshKaseFinancial');
  },

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('generalPriceListId', null);
      controller.set('showValidation', false);
      controller.set('selection', null);
      controller.set('showDialog', null);
      controller.set('showContractEsignControls', false);
    }
  },

  resetPurchaser(selection) {
    set(this.controller, 'saveFirst', true);
    set(
      selection,
      'purchaser.content',
      get(selection, 'selectionPurchaser.content')
    );
  },

  actions: {
    willTransition(transition) {
      if (this.currentModel.selection.get('hasDirtyAttributes')) {
        if (confirm(this.intl.t('authenticated.kases.manage.financials.selection.errors.unsavedChanges'))) {
          if(this.controller.unpostingMode) {
            set(this.controller, 'unpostingMode', false);
          }
          this.currentModel.selection.rollbackAttributes();
          return true;
        } else {
          transition.abort();
        }
      }
    },
    saveContact(contactType) {
      set(this.controller, 'saveFirst', true);
      const selection = this.currentModel.selection;
      selection
        .get(`${contactType}.content`)
        .save()
        .then(() => {
          selection.save().then(() => {
            this.flashes.addSuccess(
              `"${get(selection, 'name')}" has been updated!`
            );
          });
        });
    },

    selectPurchaser(purchaser) {
      const selection = this.currentModel.selection;
      set(this.controller, 'saveFirst', true);
      set(selection, 'purchaser', purchaser);
      selection
        .save()
        .then(() => {
          this.flashes.addSuccess(
            `"${get(selection, 'name')}" has been updated!`
          );
        })
        .catch(() => {
          this.flashes.addError(
            this.intl.t('authenticated.kases.manage.financials.selection.errors.saving')
          );
        });
    },

    removePurchaserRelationship() {
      const selection = this.currentModel.selection;
      set(selection, 'purchaserRelationship', null);
      this.flashes.addSuccess(
        `"${get(selection, 'name')}" has been updated!`
      );
    },

    addItem(item) {
      if (!item || !get(item, 'id')) {
        return;
      }

      const selection = this.currentModel.selection;
      // Check if item is a product
      if (get(item, 'productCategory.id') || get(item, 'product_category_id')) {
        // Fetch full product
        this.store.findRecord('product', get(item, 'id')).then(product => {
          this.store
            .createRecord('selectionProduct', {
              price: get(item, 'price'),
              manufacturer: get(item, 'manufacturer'),
              model: get(item, 'model'),
              material: get(item, 'material'),
              interior: get(item, 'interior'),
              quantity: 1,
              product,
              selection
            })
            .save()
            .then(selectionProduct => {
              this.handleSaveSuccess();
              this.flashes.addSuccess(
                `"${get(selectionProduct, 'product.name')}" added to selection!`
              );
            });
        });

        // Or package
      } else {
        // Fetch full package
        this.store.findRecord('package', get(item, 'id')).then(pkg => {
          // Create new selectionPackage
          this.store
            .createRecord('selectionPackage', {
              pricingAmount: get(pkg, 'pricingAmount'),
              pricingMethod: get(pkg, 'pricingMethod'),
              products: get(pkg, 'products.content'),
              quantity: 1,
              package: pkg,
              selection
            })
            .save()
            .then(result => {
              this.handleSaveSuccess();
              this.flashes.addSuccess(
                `"${get(result, 'package.name')}" added to selection!`
              );
            })
            .catch(() => {
              this.flashes.addError();
            });
        });
      }

      this.refresh();
    },

    generateStatement(selection) {
      set(selection, 'statementLastGenerated', moment());
      selection.save();

      this.api.json.get(
        'statement_of_accounts',
        {
          params: {
            selection_id: get(selection, 'id'),
            uuid: get(selection, 'uuid')
          }
        }
      ).then(response => {
        if (!response.ok) {
          this.flashes.addError();
        }
      });

      this.refresh(); // get new uuid
    },

    generateContract(selection, esignedDocument, contractTemplateId, _e) {
      const kase = this.currentModel.kase;
      const newUuid = uuidv4();

      let url;
      if (esignedDocument) {
        url = `esigned_contracts/${get(esignedDocument, 'id')}/download`;
      } else {
        url = 'kase_statements';
      }

      this.api.json
        .get(
          url,
          {
            params: {
              kase_id: get(kase, 'id'),
              uuid: newUuid,
              contract_template_id: contractTemplateId
            }
          }
        )
        .then(response => {
          if (!response.ok) {
            this.flashes.addError();
          }
        });

      set(selection, 'uuid', newUuid);
    },

    saveItem(item) {
      return item
        .save()
        .then(() => {
          this.handleSaveSuccess();
          this.flashes.addSuccess(
            `${this.currentModel.selection.get('name')} has been updated`
          );
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    togglePullFromInventory(selectionProduct, checked) {
      selectionProduct.set('pullFromInventory', checked);
      selectionProduct.save().then(() => {
        if (checked) {
          this.flashes.addSuccess('Product pulled from Inventory!');
        } else {
          this.flashes.addSuccess('Product returned to Inventory!');
        }
      })
      .catch(() => {
        this.flashes.addError();
      })
    },

    togglePullFromBeaconfpInventory(selectionProduct, checked) {
      selectionProduct.set('pullFromBeaconfpInventory', checked);
      selectionProduct.save().then(() => {
        if (checked) {
          this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.financials.selection.successful.beaconfpPullFromInventoryChecked'));
        } else {
          this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.financials.selection.successful.beaconfpPullFromInventoryNotChecked'));
        }
      })
      .catch(() => {
        this.flashes.addError();
      })
    },

    revertItem(product) {
      product.rollbackAttributes();
    },

    revertPackage(packageRecord) {
      packageRecord.rollbackAttributes();

      // We resync with the API because Ember doesn't retain the hasMany relationships
      this.store.findRecord('selectionPackage', get(packageRecord, 'id'));
    },

    removeItem(item) {
      return item
        .destroyRecord()
        .then(() => {
          this.handleSaveSuccess();
          this.flashes.addSuccess(
            `${this.currentModel.selection.get('name')} has been updated`
          );
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    removePackageProduct(packageRecord, product) {
      if (!packageRecord || !product) {
        return;
      }

      get(packageRecord, 'products').removeObject(product);
      this.handleSaveSuccess();
    },

    saveSelection(post = false) {
      const selection = this.currentModel.selection;
      const kase = this.currentModel.kase;
      const selectionType = get(selection, 'isContract') ? this.contractText : 'add-on';
      const aOrAn = selectionType === 'contract' ? 'a' : 'an';

      if (post) {
        if (
          confirm(
            `Once ${aOrAn} ${selectionType} is posted, you can no longer edit ${selectionType} product selections/details. Are you sure?`
          )
        ) {
          set(selection, 'posted', true);
        } else {
          return;
        }
      }
      selection
        .save()
        .then(selection => {
          this.controller.set('originalIsBadDebt', selection.isBadDebt);
          set(this, 'currentModel.selection', selection);
          set(selection, 'savedChanges', true);
          // Save kase properties on selections view
          kase.save().then(() => {
            this.flashes.addSuccess(
              `Selection successfully ${post ? 'posted' : 'saved'}!`
            );
          });
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    saveCustomProduct(customProduct) {
      if (!customProduct) {
        return;
      }

      set(customProduct, 'selection', this.currentModel.selection);

      return customProduct
        .save()
        .then(() => {
          this.handleSaveSuccess();
          this.flashes.addSuccess(
            'Custom product successfully saved!'
          );
        })
        .catch(() => {
          this.flashes.addError();
        });
    },
    invalidate() {
      const selection = this.currentModel.selection;

      if (!get(selection, 'hasActiveEsignContracts')) {
        return;
      }

      let confirmation = true;
      if (get(selection, 'hasActiveEsignContracts')) {
        confirmation = confirm(
          `This will invalidate all ${this.contractText}s out for signature.  Are you sure you want to continue?`
        );
      }

      if (!confirmation) {
        return;
      }

      selection
        .invalidate()
        .then(() => {
          this.handleSaveSuccess();
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    deleteSelection() {
      const selection = this.currentModel.selection;

      selection
        .destroyRecord()
        .then(() => {
          this.flashes.addSuccess(
            'Add-on successfully deleted!'
          );
          this.send('refreshKaseFinancial');
          this.transitionTo('authenticated.kases.manage.financials.overview');
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    reload() {
      // TODO: can this be just the selection (w/ esigned contracts)?
      this.refresh();
    }
  }
});
