import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';
import Toolbelt from 'crakn/mixins/component-toolbelt';

export default Component.extend(Toolbelt, {
  classNames: ['list__item'],
  classNameBindings: ['isInPackage::red'],
  tagName: 'li',

  model: null,
  packageProducts: null,
  showDialog: false,

  idKey: readOnly('model.id'),

  // Determines whether or not this product appears in it's parent package
  // Note: This is only for kase selections
  isInPackage: computed('model', 'packageProducts', function() {
    const packageProducts = this.packageProducts;
    const model = this.model;

    if (packageProducts === undefined) {
      return true;
    }

    return packageProducts.includes(model);
  }),

  actions: {
    removeProduct() {
      this.attrs.onRemoveProduct(this.model);
    }
  }
});
