import { helper as buildHelper } from '@ember/component/helper';
import { formatDate } from 'crakn/utils/date-helpers';

export function craknDate(args) {
  if (args[0]) {
    return formatDate(...args);
  } else {
    return '';
  }
}

export default buildHelper(craknDate);
