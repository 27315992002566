import Component from '@ember/component';
import { computed } from '@ember/object';
import { dasherize } from '@ember/string';

export default Component.extend({
  classNames: ['card-tab'],
  classNameBindings: ['isActive:card-tab--active', 'qaClass'],
  tagName: 'li',

  iconType: 'md-icon',
  label: null,
  activeTab: null,

  qaClass: computed('label', function() {
    return `qa-card-tab-${dasherize(this.label)}`;
  }),

  isActive: computed('activeTab', 'label', function() {
    return this.label === this.activeTab;
  }),

  click() {
    this.attrs.onSelect(this.label);
  }
});
