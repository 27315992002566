import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  classNameBindings: ['qaClass'],

  qaClass: null,

  isSingleColumn: false,

  showCountry: true,
  showCounty: true,
  showAddressLines: true,

  session: service(),

  showTownship: computed('township', function() {
    if (
      get(this, 'kase.location.state') === 'MI' ||
      get(this, 'kase.location.state') === 'PA'
    ) {
      return true;
    } else {
      return false;
    }
  }),

  zipCodeLabel: computed('country', function() {
    const country = get(this, 'country');
    if (country && country !== 'US') {
      return 'Postal Code';
    } else {
      return 'Zip Code';
    }
  }),

  addressLine1: null,
  addressLine2: null,
  city: null,
  state: null,
  zipCode: null,
  country: null,
  county: null
});
