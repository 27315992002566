import { bool } from '@ember/object/computed';
import Component from '@ember/component';
import { get, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  session: service(),
  store: service(),

  model: null,
  searchResults: [],

  isSingleColumn: false,

  hasSelection: bool('model'),
  isEditing: false,
  inlineEditing: true,
  showEditIcon: true,
  showRemoveIcon: true,

  redirect: null,
  redirectId: null,

  placeholder: 'Place',

  actions: {
    typeaheadSearch(query) {
      return this.store
                 .query('place', {
                   query,
                   archived: false
                 });
    },

    onClearSelection() {
      this.sendAction('onClearSelection', this.model);
      set(this, 'model', null);
    },

    onSavePlace() {
      const place = this.model;
      const savingNewPlace = place.get('isNew');

      place.save().then(() => {
        set(this, 'isEditing', false);

        if (savingNewPlace) {
          this.sendAction('onAddNewPlace', place);
        }
      });
    },

    onEditPlace() {
      set(this, 'isEditing', true);
    },

    onCancelEditPlace() {
      set(this, 'isEditing', false);

      if (get(this, 'model.isNew')) {
        this.model.deleteRecord();
        set(this, 'model', null);
      }

      if (get(this, 'model.hasDirtyAttributes')) {
        this.model.rollbackAttributes();
      }
    },

    onAddNewPlace(value) {
      set(
        this,
        'model',
        this.store.createRecord('place', {
          name: value
        })
      );
      set(this, 'isEditing', true);
    },

    onSelectPlace(place) {
      set(this, 'model', place);
      this.sendAction('onSelectPlace', place);
    }
  }
});
