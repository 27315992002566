import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';

export default class CraknClientsEditDashboardConfigController extends Controller {
  @controller('authenticated/crakn/clients/edit') edit;

  @action
  save(event) {
    this.edit.save(event);
  }
}
