import Model, { attr } from '@ember-data/model';
import moment from 'moment';

export default class V2BeaconfpResendLogModel extends Model {
  
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;

  @attr('string') referenceType;
  @attr('string') referenceId;
  @attr('string') createdByType;
  @attr('string') createdById;
  @attr('string') createdByName;
 
}
