import { filter } from '@ember/object/computed';
import { later, cancel } from '@ember/runloop';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed, set, get } from '@ember/object';
import config from 'crakn/config/environment';

export default Component.extend({
  classNames: ['list__item', 'list__item--wrap'],
  classNameBindings: ['qaClass'],
  tagName: 'li',
  browser: service(),
  store: service(),
  session: service(),

  form: null,
  showEsignControls: false,

  editingEsignedFormId: null,
  viewingAuditTrailEsignedFormId: null,

  qaClass: computed('form.id', function() {
    return `qa-kase-form-${get(this, 'form.id')}`;
  }),

  timeout1: null,
  timeout2: null,

  formLink: computed('form.id', function() {
    return `${config.host}/api/kase_forms/${get(this, 'form.id')}/download`;
  }),

  isCreatingEsignedForm: computed('form.esignedForms.@each.id', function() {
    return get(this, 'form.esignedForms').any(doc => !doc.get('id'));
  }),

  hasPersistedEsignedForms: filter('form.esignedForms.@each.isNew', function(
    item
  ) {
    return !item.get('isNew');
  }),

  esignatureStatusClass: computed('form.esignatureStatus', function() {
    switch (get(this, 'form.esignatureStatus')) {
      case 'pending':
        return 'yellow';
      case 'complete':
        return 'green';
      case 'declined':
        return 'red';
      default:
        return '';
    }
  }),

  init() {
    this._super(...arguments);
    this.checkIfCompleted();
  },

  checkIfCompleted() {
    if (get(this, 'form.status') === 'in_progress') {
      set(this, 'timeout1', later(this, this.sendToRoute, 4000));
    }
  },

  sendToRoute() {
    this.sendAction('checkForUploadCompleted', this.form);
    set(this, 'timeout2', later(this, this.checkIfCompleted, 4000));
  },

  willDestroyElement() {
    cancel(this.timeout1);
    cancel(this.timeout2);
  },

  actions: {
    deleteForm() {
      this.attrs.deleteForm(this.form);
    },

    prepareToRefillForm() {
      this.prepareToRefillForm(this.form);
      this.checkIfCompleted();
    },

    toggleEsignControls() {
      if (!get(this, 'form.esignedForms.length')) {
        this.send('buildNewEsignedForm');
      }

      this.toggleProperty('showEsignControls');
    },

    buildNewEsignedForm() {
      const esignedForm = this.store.createRecord('esignedForm', {
        kaseForm: this.form,
        remainingSignatureFieldNames: get(this, 'form.esignatureFieldNames')
      });

      this.send('editEsignedForm', esignedForm.get('id'));
    },

    editEsignedForm(id) {
      const isClosingCurrentEditing = id === this.editingEsignedFormId;

      const idToSet = isClosingCurrentEditing ? null : id;
      set(this, 'editingEsignedFormId', idToSet);
      set(this, 'viewingAuditTrailEsignedFormId', null);
    },

    showAuditTrail(id) {
      const isClosingCurrentViewing =
        id === this.viewingAuditTrailEsignedFormId;

      const idToSet = isClosingCurrentViewing ? null : id;
      set(this, 'viewingAuditTrailEsignedFormId', idToSet);
      set(this, 'editingEsignedFormId', null);
    }
  }
});
