import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import Component from '@ember/component';

export default Component.extend({
  selectedOption: null,
  label: 'Location',

  location: service(),

  placeholder: 'All Locations',

  classNames: ['input', 'input--standard'],
  classNameBindings: ['hasValue:input--has-value', 'styleClass'],

  hasValue: null,
  showPlaceholder: true,
  archived: false,

  options: computed(
    'archived',
    'location.{allSorted,archivedSorted}',
    function() {
      return this.archived
        ? this.location.archivedSorted
        : this.location.allSorted;
    }
  ),

  actions: {
    handleChange(locationId) {
      const selectedLocation = this.options.findBy('id', locationId);
      this.sendAction('onchange', selectedLocation);
    }
  }
});
