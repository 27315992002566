import Model, { attr } from '@ember-data/model';

export default class NewClientRequestModel extends Model {
  @attr('string') callsPerYear;
  @attr('string') companyCity;
  @attr('string') companyLine1;
  @attr('string') companyLine2;
  @attr('string') companyName;
  @attr('string') companyState;
  @attr('string') companyUrl;
  @attr('string') companyZipCode;
  @attr('string') email;
  @attr('string') firstName;
  @attr('string') jobTitle;
  @attr('string') lastName;
  @attr('string') namePrefix;
  @attr('string') numberOfStaffMembers;
  @attr('phone') phone;
}
