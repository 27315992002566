import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesManageEditTransportationController extends Controller {
  @service api;
  @service clientRouting;
  @service flashes;
  @service names;
  @service router;
  @service store;
  @service staff

  kase = null;
  newKaseStaffMember = null;
  newKaseStaffMemberId = null;
  tradeCallFirstNameQuery = null;
  tradeCallLastNameQuery = null;

  shipoutTransportationTypes = ['Train', 'Car', 'Airplane', 'Other'];
  tradeCallTypeOptions = ['Ship Out', 'Ship In', 'Transport', 'Cremation'];

  constructor(owner, args) {
    super(owner, args);
  
    this.createStaffMember();
  }

  createStaffMember() {
    this.setProperties({
      newKaseStaffMember: this.store.createRecord('kaseStaffMember'),
      newKaseStaffMemberId: null
    });
  }

  requestAddRemoveSecondaryRemovalUser(method, userId) {
    const kaseId = this.model.id;

    this.api.json
        .request(
          method,
          'kase_staff_members',
          {
            body: {
              kase_staff_member: {
                user_id: userId,
                kase_id: kaseId,
                role: 'removal_secondary_staff'
              }
            }
          }
        );
  }

  get isShipIn() {  
    return this.model.tradeCallType === 'Ship In';
  }

  get tradeCallPlaceholer() {
    if (this.model.tradeCallType === 'Ship Out') {
      return 'Ship Out Transportation Type';
    } else {
      return 'Transportation Type';
    }
  }

  @action
  saveTransportation() {
    this.model.save().then(() => {
      this.flashes.addSuccess('Way to go! Transportation information is in motion!');

      const route = this.clientRouting.manageRouteTransition('authenticated.kases.manage.edit.transportation');
      
      this.router.transitionTo(route);
    });
  }

  @action
  onShipOutChange(value) {
    this.model.shipoutDateTimePickup = value;
  }

  @action
  onShipInChange(value) {
    this.model.shipInDateTimePickup = value;
  }

  @action
  onRemovalChange(value) {
    this.model.removalPickupTime = value;
  }

  @action
  onCrematoryTransportChange(value) {
    this.model.crematoryTransportTime = value;
  }

  @action
  removeStaffMember(user) {
    this.requestAddRemoveSecondaryRemovalUser('DELETE', user.id);
    
    this.model.removalSecondaryStaffMember.removeObject(user);
  }

  @action
  setUser(prop, id) {
    if (!id || id === '') {
      this.set(prop, null);
    } else {
      this.store.findRecord('user', id).then(user => {
        this.set(prop, user);
      });
    }
  }
  
  @action
  updatePrimaryMember(id) {
    this.setUser('model.removalStaffMember', id);

    const secondaryStaff = this.model.removalSecondaryStaffMember;
    
    if (secondaryStaff.findBy('id', id)) {
      this.requestAddRemoveSecondaryRemovalUser('DELETE', id);
      
      this.store.findRecord('user', id).then(function(user) {
        secondaryStaff.removeObject(user);
      });
    }
  }
  
  @action
  assignStaffMember(kaseStaffMember) {
    const userId = kaseStaffMember.get('user.id');

    if (!userId) {
      return this.flashes.addError('Looks like you forgot to select a staff member.');
    }

    if (
      this.model.removalSecondaryStaffMember.findBy('id', userId) ||
      this.model.removalStaffMember.get('id') === userId
    ) {
      this.createStaffMember();
      
      return this.flashes.addError('Whoops! That staff member is already assigned to this case.');
    }

    this.requestAddRemoveSecondaryRemovalUser('POST', userId);
    
    const staff = this.model.removalSecondaryStaffMember;
    
    this.store.find('user', userId).then(function(user) {
      staff.pushObject(user);
    });
    
    this.createStaffMember();
  }

  @action
  saveContact(contactType) {
    this.model
      .get(contactType)
      .then(contact => {
        contact.save().then(() => {
          this.model.save();
        });
      });
  }

  @action
  setLocation(locationId) {
    this.model.transportToLocation = this.store.peekRecord('location', locationId);
  }

  // Update setTradeCallPlace actions once V2 place model is complete
  @action
  setTradeCallPlace(v2Place) {
    this.store.findRecord('place', v2Place.id).then(v1Place => {
      this.model.set('tradeCallPlace', v1Place);
    });
  }

  // Update setTradeCallPlace actions once V2 place model is complete
  @action
  setBodyDonationPlace(v2Place) {
    this.store.findRecord('place', v2Place.id).then(v1Place => {
      this.model.set('bodyDonationPlace', v1Place);
    });
  }

  @action
  removeTradeCallPlace() {
    this.model.set('tradeCallPlace', null);
  }

  @action
  removeBodyDonationPlace() {
    this.model.set('bodyDonationPlace', null);
  }
}
