import Model, { belongsTo, attr } from '@ember-data/model';
import moment from 'moment';

export default class DiscountModel extends Model {
  @attr('string') coaIntegrationName;
  @attr('string') name;
  @attr('string') notes;

  @attr('moment-datetime', { defaultValue: () => moment() }) collectedAt;
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;
  @attr('moment-datetime') exportedAt;

  @attr('price') amount;
  
  @attr('boolean') exported;

  @belongsTo('client') client;
  @belongsTo('user', { async: true }) collectedBy;
  @belongsTo('discountType', { async: true }) discountType;
  @belongsTo('user', { async: true }) enteredBy;
  @belongsTo('user', { async: true }) exportedBy;
  @belongsTo('selection', { async: true }) selection;
}
