import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import SelectBox from './select-box';

export default SelectBox.extend({
  layoutName: 'components/form/select-box',

  productCategories: service(),
  generalPriceListId: null,

  categories: computed('generalPriceListId', function() {
    const categories = this.productCategories.getProductCategories(this.generalPriceListId);

    // Set first result as default each time categories are
    // fetched from the api.
    categories.then((categories) => {
      this.set('selection', categories.get('firstObject'));
    });

    return categories;
  }),

  placeholder: 'Item Category',

  productCategoryNameSort: ['name:asc'],
  options: sort('categories', 'productCategoryNameSort')
});
