import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminGeneralPriceListsEditCostUpdatesRoute extends SearchRoute {
  @service productCategories;

  queryParams = {
    query: {
      replace: true,
      refreshModel: true
    },
    general_price_list_id: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    sort_by: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    },
    category_id: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    }
  };

  model(params) {
    const generalPriceList = this.modelFor(
      'authenticated.admin.general-price-lists.edit'
    );
    params.general_price_list_id = generalPriceList.get('id');
    return this.store.query('product', params);
  }

  setupController(controller) {
    super.setupController(...arguments);
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');
    const store = this.store;
    controller.set('general_price_list_id', gpl.id);
    controller.set('chartOfAccounts', gpl.chartOfAccounts);
    controller.set(
      'gplProductCategories',
      this.productCategories.getProductCategories(gpl.id)
    );

    controller.set('generalPriceList', gpl);

    controller.set('statementCategories', store.findAll('statementCategory'));
    controller.set(
      'statementSubcategories',
      store.findAll('statementSubcategory')
    );
  }

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams =
        transition.targetName ===
        'authenticated.admin.general-price-lists.edit.cost-updates';

      if (resetParams) {
        controller.setProperties({
          page: 1,
          query: null,
          sort_by: null,
          archived: false,
          order: 'ASC'
        });
      }
    }
  }

  @action
  willTransition() {
    const products = this.currentModel.filterBy('hasDirtyAttributes');
    products.forEach((product) => {
      product.rollbackAttributes();
    });
  }
}
