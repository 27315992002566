import Route from '@ember/routing/route';
import moment from 'moment';

export default Route.extend({
  queryParams: {
    query: {
      replace: true,
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    sort_by: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    }
  },

  model(params) {
    return this.store.query('user', {
      query: params.query,
      page: params.page,
      sort_by: params.sort_by,
      archived: params.archived,
      order: params.order
    });
  },

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = !['authenticated.admin.manage.users.index', 'authenticated.admin.manage.users.edit'].includes(
        transition.targetName
      );

      if (resetParams) {
        controller.setProperties({
          page: 1,
          query: null,
          sort_by: null,
          archived: false,
          order: 'ASC',
          addMode: false
        });
      }
    }
  },

  actions: {
    toggleArchived(user) {
      if (user.get('archivedAt')) {
        user.set('archivedAt', null);
      } else {
        user.set('archivedAt', moment());
      }

      user.save().then(() => {
        this.refresh();
      });
    }
  }
});
