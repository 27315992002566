import Route from '@ember/routing/route';

export default Route.extend({
  renderTemplate(_controller, _model) {
    this.render('authenticated/misc-sales/edit/adjustments', {
      into: 'authenticated/misc-sales/create'
    });
  },

  setupController(controller, model) {
    this._super(...arguments);
    controller.setProperties(model);
  }
});
