import Controller from '@ember/controller';
import { action } from '@ember/object';
import { imageExtensions } from 'crakn/utils/data-helpers';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';

export default class KasesManageDocumentsPhotosController extends Controller {
  @service flashes;

  kase = null;
  user = null;

  photoSorting = ['createdAtUnix:desc'];
  @sort('photos', 'photoSorting') sortedAllPhotos;

  get imageExtensions() {
    return imageExtensions;
  }

  @action
  createPhoto(photoParams) {
    const photo = this.store.createRecord('v2/photo', photoParams);
    photo.kase = this.kase;
    photo.createdByUser = this.user;

    photo
      .save()
      .then(() => {
        this.flashes.addSuccess('Photo successfully uploaded!');
        this.send('refreshModel');
      })
      .catch(() =>
        this.flashes.addError('Oops, something went wrong! Please try again.')
      );
  }
}
