import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AdminGeneralPriceListsEditStatementCategoriesRoute extends Route {
  queryParams = {
    page: {
      refreshModel: true
    }
  };

  model(params) {
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');

    return this.store.query('v2/statementCategory', {
      archived: false,
      gpl_id: gpl.id,
      page: params.page,
      per_page: 10,
    });
  }

  setupController(controller) {
    super.setupController(...arguments);

    const v1GPL = this.modelFor('authenticated.admin.general-price-lists.edit');
    const generalPriceList = this.store.findRecord('v2/generalPriceList', v1GPL.id);

    controller.set('generalPriceList', generalPriceList);
    controller.set('newStatementCategory', this.store.createRecord('v2/statementCategory'));
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
