export const saveChanges = (controller, changes, rawImport) => {
  if (
    changes.length > 1 &&
    !confirm(
      'Are you sure you want to copy this section? This cannot be undone.'
    )
  ) {
    return null;
  }
  const modelActions = createModelActions(changes, rawImport);
  const actions = modelActions.map(modelAction =>
    updateOrCreateModelFromAction(controller, modelAction, modelActions)
  );
  return actions;
};

const createModelActions = (changes, data, root) => {
  if (data === null || !data.relationships) {
    return [];
  }
  const relationships = Object.keys(data.relationships);
  return relationships
    .filter(key => {
      return data.relationships[key] !== null;
    })
    .flatMap(key => {
      const obj = data.relationships[key];
      let path = key;
      if (root) {
        path = `${root}.${key}`;
      }
      const relevantAttributes = changes
        .filter(change => {
          return (
            obj.id === change.id &&
            obj.type === change.type &&
            path === change.path
          );
        })
        .reduce((acc, next) => {
          acc[next.attrName] = next.value;
          return acc;
        }, {});
      const newModel = obj.id.length > 15;
      return {
        id: newModel ? null : obj.id,
        type: obj.type,
        path,
        attributes: relevantAttributes,
        relationships: createModelActions(
          changes,
          data.relationships[key],
          path
        )
      };
    });
};

const lastKeyForPath = path => {
  const paths = path.split('.');
  return paths[paths.length - 1];
};

const createModelFromAction = async (
  controller,
  modelAction,
  parentModel,
  parentModelAction
) => {
  const attrs = modelAction.attributes;
  const model = await controller.store.createRecord(
    `v2/${modelAction.type}`,
    attrs
  );

  if (parentModel && parentModelAction) {
    const parentKey = lastKeyForPath(parentModelAction.path);
    if (Object.keys(model.toJSON()).includes(parentKey)) {
      model.set(parentKey, parentModel);
    }
  }

  try {
    await model.save();

    const relationKey = lastKeyForPath(modelAction.path);
    if (
      parentModel &&
      Object.keys(parentModel.toJSON()).includes(relationKey)
    ) {
      parentModel.set(relationKey, model);
      await parentModel.save();
    }
    return model;
  } catch (_) {
    alert(
      'This field cannot be copied over individually. Please copy the entire section.'
    );
    return null;
  }
};

const findModelActionRecord = async (
  controller,
  modelAction,
  parentModel,
  parentModelAction
) => {
  const relationKey = lastKeyForPath(modelAction.path);
  if (modelAction.id !== null) {
    try {
      return await controller.store.findRecord(
        `v2/${modelAction.type}`,
        modelAction.id
      );
    } catch (_) {
      return null;
    }
  } else if (
    parentModel &&
    parentModelAction &&
    Object.keys(parentModel.toJSON()).includes(relationKey)
  ) {
    return parentModel.get(relationKey);
  } else if (parentModelAction && parentModelAction.id) {
    const parentKey = lastKeyForPath(parentModelAction.path);
    try {
      const records = await controller.store.query(`v2/${modelAction.type}`, {
        [`${parentKey}_id`]: parentModelAction.id
      });
      return records.get('firstObject');
    } catch (e) {
      return null;
    }
  }
};

const rootOrChildrenHaveChanges = modelAction => {
  if (
    Object.keys(modelAction.attributes).length === 0 &&
    modelAction.relationships.length === 0
  ) {
    return false;
  }
  return (
    Object.keys(modelAction.attributes).length > 0 ||
    modelAction.relationships.reduce((acc, next) => {
      return acc || rootOrChildrenHaveChanges(next);
    }, false)
  );
};

const updateOrCreateModelFromAction = async (
  controller,
  modelAction,
  parentModel,
  parentModelAction
) => {
  if (!rootOrChildrenHaveChanges(modelAction)) {
    return true;
  }
  let model = await findModelActionRecord(
    controller,
    modelAction,
    parentModel,
    parentModelAction
  );
  if (
    modelAction.id === null ||
    Object.keys(modelAction.attributes).length > 0
  ) {
    if (!model) {
      model = await createModelFromAction(
        controller,
        modelAction,
        parentModel,
        parentModelAction
      );
      if (!model) {
        return false;
      }
    } else {
      model.setProperties(modelAction.attributes);
      await model.save();
    }
  }
  return await modelAction.relationships.reduce(async (acc, next) => {
    return (
      acc &&
      (await updateOrCreateModelFromAction(
        controller,
        next,
        model,
        modelAction
      ))
    );
  }, true);
};
