import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class V2OnlinePlannerUserModel extends Model {
  @attr('string') email;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') updatedAt;
  @attr('moment-datetime') accessedAt;

  @belongsTo('v2/client', { async: true }) client;
  @belongsTo('v2/online-planner-config', { async: true }) onlinePlannerConfig;
  @hasMany('v2/location-online-planner-config', { async: true}) locationOnlinePlannerConfigs;
  @hasMany('v2/online-planner-session', { async: true}) onlinePlannerSessions;
}
