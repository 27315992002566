import Model, { belongsTo, attr } from '@ember-data/model';

export default class VeteranInfoModel extends Model {
  @attr('string') airForceCross;
  @attr('string') branchRequestedPresence;
  @attr('string') bronzeStar;
  @attr('string') description;
  @attr('string') dischargeType;
  @attr('string') dstServiceCross;
  @attr('string') flagInstructions;
  @attr('string') marker;
  @attr('string') medalOfHonor;
  @attr('string') militaryOrganizations;
  @attr('string') navyCross;
  @attr('string') placeDischarged;
  @attr('string') placeEntered;
  @attr('string') purpleHeart;
  @attr('string') serviceNumber;
  @attr('string') silverStar;
  @attr('string') vaNumber;
  @attr('string') warsFought;
  @attr('string') xcNumber;

  @attr('boolean') awardsReceived;
  @attr('boolean') dischargePapersReceived;
  @attr('boolean') isFlag;
  @attr('boolean') isMilitaryHonors;
  @attr('boolean') isRequestedPresence;

  @attr('moment-datetime') honorsTime;

  @belongsTo('place', { async: false }) honorsPlace;
  @belongsTo('kase', { async: true }) kase;
}
