import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminDataEventNamesController extends Controller {
  @service store;
  @service flashes;

  @tracked newEventName;
  @tracked page = 1;

  queryParams = ['page'];

  @action
  create() {
    if (!this.newEventName) {
      return 
    }
    
    const record = this.store.createRecord('event-name', { name: this.newEventName } )
    this.newEventName = null;

    record.save()
          .then(() => {
            this.flashes.addSuccess('Event Name successfully saved!');
            this.send('refreshModel');
          });
  }

  @action 
  save(record) {
    record.save()
          .then(() => {
            this.flashes.addSuccess('Event Name successfully saved!');
            this.send('refreshModel');
          })
          .catch(() => {
            this.error();
          });
  }

  @action 
  delete(record) {
    record.destroyRecord()
          .then(() => {
            this.flashes.addSuccess('Event Name successfully destroyed!');
            this.send('refreshModel');
          })
          .catch(() => {
            this.error();
          });
  }

  @action 
  cancel(record) {
    record.rollbackAttributes();
  }

  @action 
  error() {
    this.flashes.addError('An error occured while saving.');
  }
}
