import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n  <label\n      class=\"checkbox checkbox--{{@style}} {{if (and @checked (not @disabled)) \"checkbox--has-value\"}} {{if @disabled \"checkbox--disabled\"}}\">\n    <MdIcon @icon={{if @checked \"check_box\" \"check_box_outline_blank\"}} />\n\n    {{@label}}\n\n    <Input\n        data-test={{this.formatDataTest}}\n        id={{this.inputId}}\n        @type=\"checkbox\"\n        @checked={{@checked}}\n        @disabled={{@disabled}}\n        {{on \"change\" this.change}}/>\n  </label>\n</div>\n", {"contents":"<div ...attributes>\n  <label\n      class=\"checkbox checkbox--{{@style}} {{if (and @checked (not @disabled)) \"checkbox--has-value\"}} {{if @disabled \"checkbox--disabled\"}}\">\n    <MdIcon @icon={{if @checked \"check_box\" \"check_box_outline_blank\"}} />\n\n    {{@label}}\n\n    <Input\n        data-test={{this.formatDataTest}}\n        id={{this.inputId}}\n        @type=\"checkbox\"\n        @checked={{@checked}}\n        @disabled={{@disabled}}\n        {{on \"change\" this.change}}/>\n  </label>\n</div>\n","moduleName":"crakn/components/g/crakn/field/checkbox.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/field/checkbox.hbs"}});
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object'

export default class GCraknFieldCheckboxComponent extends Component {
  get inputId() {
    return this.args.id || 'field-checkbox-' + guidFor(this);
  }

  get formatDataTest() {
    return this.args.dataTest
    ? this.args.dataTest.toLowerCase()
                        .replace(/[?/]/g, '')
                        .replace(/\s+/g, '-')
    : '';
  }

  @action
  change() {
    if (this.args.onChange) {
      this.args.onChange(document.getElementById(this.inputId).checked);
    }
  }
}
