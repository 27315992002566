import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking'

export default class AcceptNewClientRoute extends Route {
  @service api;
  @service router;

  layout = 'fullscreen';
  @tracked isFullscreenActive = true;

  model(_params, transition) {
    this.api.json
      .post('users/accept_new_client', {
        body: { token: transition.to.queryParams.token }
      })
      .then((resp) => {
        if (resp.ok) {
          this.router.transitionTo('login', { queryParams: { acceptNewClient: true } });
        } else {
          this.router.transitionTo('not-found');
        }
      });
  }
}
