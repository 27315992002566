import Model, { belongsTo, hasMany, attr } from '@ember-data/model';

export default class InventoryModel extends Model {
  @attr('boolean') global;
  @attr('boolean', { defaultValue: false }) initialQuantitySet;

  @attr('number') cost;
  @attr('number', { defaultValue: 0 }) beginningBalanceQuantity;
  @attr('number') price;
  @attr('number', { defaultValue: 0 }) quantity;
  @attr('number') totalCost;

  @attr('moment-datetime') beginningBalanceDate;
  @attr('moment-datetime') archivedAt;

  @attr('string') name;
  @attr('string') vendorNotes;
  
  @belongsTo('v2/location') location;

  @hasMany('v2/inventoryTransaction', { async: true }) inventoryTransactions;
}
