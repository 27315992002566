import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2GeneralPriceList extends Model {
  @attr('moment-datetime') archivedAt;
  @attr('moment-date') casketPriceListDate;
  @attr('moment-date') generalPriceListDate;
  @attr('moment-datetime') outerBurialPriceListDate;
  @attr('moment-datetime') updatedAt;
  
  @attr('string') name;

  @belongsTo('v2/chartOfAccounts', { async: true }) chartOfAccounts;
}
