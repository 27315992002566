import { computed, set } from '@ember/object';
import { debounce } from '@ember/runloop';
import { or, not } from '@ember/object/computed';
import Service from '@ember/service';

export default Service.extend({
  init() {
    this._super(...arguments);

    this.environmentCheck();

    window.addEventListener('resize', () => debounce(this, this.environmentCheck, 1000));
  },

  isPrivate: computed(function() {
    let nativeStorage;

    // Safari's private tabs do not allow for the use of localStorage or sessionStorage
    // This script checks to see if either service is available
    // If not, it indicates that the user is in a private tab
    try {
      nativeStorage = localStorage || sessionStorage;
      nativeStorage.setItem('storageCheck', true);
      nativeStorage.removeItem('storageCheck');
    } catch (e) {
      nativeStorage = false;
    }

    // A deliberate true or false must be returned otherwise the result is buggy
    return nativeStorage ? false : true;
  }),

  isAndroid: computed(function() {
    return !!navigator.userAgent.match(/Android/i);
  }),

  isIOS: computed(function() {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
  }),

  isWindows: computed(function() {
    return (
      !!navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  }),

  isSafari: computed(function() {
    return (
      !!navigator.userAgent.match(/Safari/i) &&
      !navigator.userAgent.match(/Chrome/i)
    );
  }),

  isMobile: or('isAndroid', 'isIOS', 'isWindows'),

  isDesktop: not('isMobile'),

  isDesktopWidth: true,

  isMobileWidth: not('isDesktopWidth'),

  environmentCheck() {
    const desktopSize = 1200;

    set(this, 'isDesktopWidth', window.innerWidth >= desktopSize);
  }
});
