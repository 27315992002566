import Route from '@ember/routing/route';
import { set, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default Route.extend({
  session: service(),
  layout: 'custom-aside',

  model() {
    return this.modelFor('authenticated.kases.manage');
  },

  setupController(controller) {
    this._super(...arguments);

    const clientInfoId = get(
      this,
      'session.session.authenticated.client.client_info_id'
    );

    if (clientInfoId) {
      set(
        controller,
        'clientInfo',
        this.store.findRecord('clientInfo', clientInfoId)
      );
    }
  },

  actions: {
    save() {
      this.currentModel.save();
    }
  }
});
