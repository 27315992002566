import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class KaseTasksHeader extends Component {
  @tracked
  manualOpen = false;

  //Start Open or Close
  get showTasks() {
    return this.showCompleteAll || this.manualOpen;
  }

  get showHideLabel() {
    return this.isShowStateActive ? 'Hide' : 'Show';
  }

  get showHideIcon() {
    return this.isShowStateActive ? 'visibility_off' : 'visibility';
  }

  get isComplete() {
    return !this.showCompleteAll;
  }

  //All items complete
  get showCompleteAll() {
    return this.args.tasks.length && 
           this.args.tasks.some((task) => !this.args.filters['complete'](task))
  }

  get showYesToAll() {
    return this.args.tasks.length && 
           this.args.tasks.some((task) => !this.args.filters['enabled'](task))
  }

  get showNoToAll() {
    return this.args.tasks.length && 
    this.args.tasks.some((task) => !this.args.filters['disabled'](task))
  }

  get showUncheckAll() {
    return this.args.tasks.length && 
           this.args.tasks.some(this.args.filters['assigned'])
  }

  @action
  toggleManualOpen() {
    this.manualOpen = !this.manualOpen;
  }

  @action
  completeAll() {
    this.args.listAction('complete', this.args.label);
    this.manualOpen = false;
  }

  @action
  yesToAll() {
    this.args.listAction('yes', this.args.label);
  }

  @action
  noToAll() {
    this.args.listAction('no', this.args.label);
  }

  @action
  uncheckAll() {
    this.args.listAction('uncheck', this.args.label);
  }
}
