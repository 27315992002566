import Route from '@ember/routing/route';

export default class KasesEditEventsRoute extends Route {
  layout = 'custom-aside';

  model() {
    const kase = this.modelFor('authenticated.kases.manage');
    return this.store.query('event', { kase_id: kase.get('id') });
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.set('events', model);
    controller.set('kase', this.modelFor('authenticated.kases.manage'));
  }
}
