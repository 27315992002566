import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class PayNowExpiredRoute extends Route {
  @service api;

  layout = 'fullscreen';
  isFullscreenActive = true;

  async model(params) {
    const { parsedJson } = await this.api.json.get(`v2/locations/client_by_location_uuid?client_by_location_uuid=${params.uuid}`);

    return parsedJson.data.attributes;
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    controller.set('isTMSClient', transition.to.params.is_tms_client === 'true');
  }
}
