import Component from '@ember/component';
import { set, get } from '@ember/object';

export default Component.extend({
  classNames: ['meta-data'],

  label: null,
  location: null,
  user: null,
  createdAt: null,
  isEditing: false,

  init() {
    this._super(...arguments);

    const location = this.location;

    set(this, 'isEditing', !location || !get(location, 'id'));
  },

  actions: {
    toggleEdit() {
      this.toggleProperty('isEditing');
    }
  }
});
