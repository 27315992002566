import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default class SpecialTaxModel extends Model {
  @attr('moment-datetime', { defaultValue: null }) archivedAt;
  @attr('moment-datetime') endsAt;
  @attr('string') name;
  @attr('number') rate;
  @attr('moment-datetime') startsAt;

  @belongsTo('chartOfAccounts') chartOfAccounts;
  @belongsTo('account') liabilityAccount;
  @belongsTo('taxItem') taxItem;

  @hasMany('location', { async: true }) locations;
}
