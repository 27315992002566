import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking'
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknFormsTfeIndexController extends SearchController {
  queryParams = ['page', 'per_page', 'query'];

  @service api;
  @service flashes;
  @service intl;

  @tracked page = 1;
  @tracked per_page = 10;
  @tracked query = null;

  @action
  clearSearch() {
    this.clearQuery();
  }


  @action
  async downloadTest(blankForm) {
    const button = event.target;
    button.disabled = true;

    try {
      const response = await this.api.get(`tfe/documents/${blankForm.id}/download_test`);

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = `${blankForm.name}-test`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.tfe.forms.index.downloadTestForm.messages.failure'));
      }
    } catch (error) {
      this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.tfe.forms.index.downloadTestForm.messages.failure'));
    } finally {
      button.disabled = false;
    }
  }
}
