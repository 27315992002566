// This is no longer used: the file is being kept unless it needs to be used again ~ 08/26/2020

import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class KasesManageChainOfCustodyDeclineCustodyController extends Controller {
  @action
  setCustodyEnabled(hasChainOfCustody) {
    this.model.hasChainOfCustody = hasChainOfCustody;
    this.model.save();
  }
}
