import { get } from '@ember/object';
import { camelize } from '@ember/string';

function normalizeKey(key, prefix) {
  return camelize(prefix + key);
}

export function fullName(person, prefix = '', showMaiden = true, fullMiddleName = false) {
  if (!person) {
    return null;
  }
  
  let ret = null;

  const namePrefixKey = normalizeKey('NamePrefix', prefix);
  const firstNameKey = normalizeKey('FirstName', prefix);
  const lastNameKey = normalizeKey('LastName', prefix);
  const middleNameKey = normalizeKey('MiddleName', prefix);
  const nameSuffixKey = normalizeKey('NameSuffix', prefix);

  let namePrefix = get(person, namePrefixKey);
  const firstName = get(person, firstNameKey);
  const lastName = get(person, lastNameKey);
  const middleName = get(person, middleNameKey);
  const nameSuffix = get(person, nameSuffixKey);
  const formattedMiddleName = middleName ? `${middleName.charAt(0)}.` : '';

  if (/mr|mister|miss|ms|mrs|mx/i.test(namePrefix)) {
    namePrefix = '';
  }

  ret = [namePrefix, firstName, (fullMiddleName ? middleName : formattedMiddleName), lastName].filter(Boolean).join(' ') || null;

  if (showMaiden) {
    const maidenNameKey = normalizeKey('MaidenName', prefix);
    const maidenName = get(person, maidenNameKey);
    const formattedMaidenName = maidenName ? ` (${maidenName})` : '';
    if (firstName && lastName) {
      ret += formattedMaidenName;
    }
  }

  if (nameSuffix) {
    if (!ret) {
      ret = nameSuffix;
    } else {
      ret += ` ${nameSuffix}`;
    }
  }

  return ret;
}

export function phoneNumber(person, prefix = '') {
  let ret = null;

  if (person) {
    let primaryPhoneKey, secondaryPhoneKey;

    if (prefix) {
      primaryPhoneKey = `${prefix}PhonePrimary`;
      secondaryPhoneKey = `${prefix}PhoneSecondary`;
    } else {
      primaryPhoneKey = 'phonePrimary';
      secondaryPhoneKey = 'phoneSecondary';
    }

    ret =
      get(person, primaryPhoneKey) || get(person, secondaryPhoneKey) || null;
  }

  return ret;
}
