const airForceRanks = [
  {
    label: 'Airman Basic (E-1)',
    value: 'Airman Basic'
  },
  {
    label: 'Airman (E-2)',
    value: 'Airman'
  },
  {
    label: 'Airman First Class (E-3)',
    value: 'Airman First Class'
  },
  {
    label: 'Senior Airman (E-4)',
    value: 'Senior Airman'
  },
  {
    label: 'Staff Sergeant (E-5)',
    value: 'Staff Sergeant'
  },
  {
    label: 'Technical Sergeant (E-6)',
    value: 'Technical Sergeant'
  },
  {
    label: 'Master Sergeant (E-7)',
    value: 'Master Sergeant'
  },
  {
    label: 'Senior Master Sergeant (E-8)',
    value: 'Senior Master Sergeant'
  },
  {
    label: 'Chief Master Sergeant (E-9)',
    value: 'Chief Master Sergeant'
  },
  {
    label: 'Command Chief Master Sergeant (E-9)',
    value: 'Command Chief Master Sergeant'
  },
  {
    label: 'Chief master sergeant of the Air Force (E-9)',
    value: 'Chief master sergeant of the Air Force'
  },
  {
    label: 'Second Lieutenant (O-1)',
    value: 'Second Lieutenant'
  },
  {
    label: 'First Lieutenant (O-2)',
    value: 'First Lieutenant'
  },
  {
    label: 'Captain (O-3)',
    value: 'Captain'
  },
  {
    label: 'Major (O-4)',
    value: 'Major'
  },
  {
    label: 'Lieutenant Colonel (O-5)',
    value: 'Lieutenant Colonel'
  },
  {
    label: 'Colonel (O-6)',
    value: 'Colonel'
  },
  {
    label: 'Brigadier General (O-7)',
    value: 'Brigadier General'
  },
  {
    label: 'Major General (O-8)',
    value: 'Major General'
  },
  {
    label: 'Lieutenant General (O-9)',
    value: 'Lieutenant General'
  },
  {
    label: 'General (O-10)',
    value: 'General'
  },
  {
    label: 'General of the Air Force',
    value: 'General of the Air Force'
  }
];

const armyRanks = [
  {
    label: 'Private (E-1)',
    value: 'Private'
  },
  {
    label: 'Private 2nd Class (E-2)',
    value: 'Private 2nd Class'
  },
  {
    label: 'Private First Class (E-3)',
    value: 'Private First Class'
  },
  {
    label: 'Army Specialist (E-4)',
    value: 'Army Specialist'
  },
  {
    label: 'Corporal (E-4)',
    value: 'Corporal'
  },
  {
    label: 'Technician (E-?)',
    value: 'Technician'
  },
  {
    label: 'Sergeant (E-5)',
    value: 'Sergeant'
  },
  {
    label: 'Staff Sergeant (E-6)',
    value: 'Staff Sergeant'
  },
  {
    label: 'Technical Sergeant',
    value: 'Technical Sergeant'
  },
  {
    label: 'Sergeant First Class (E-7)',
    value: 'Sergeant First Class'
  },
  {
    label: 'Master Sergeant (E-8)',
    value: 'Master Sergeant'
  },
  {
    label: 'First Sergeant (E-8)',
    value: 'First Sergeant'
  },
  {
    label: 'Sergeant Major (E-9)',
    value: 'Sergeant Major'
  },
  {
    label: 'Command Sergeant Major (E-9)',
    value: 'Command Sergeant Major'
  },
  {
    label: 'Sergeant Major of the Army (E-9)',
    value: 'Sergeant Major of the Army'
  },
  {
    label: 'Warrant Officer 1 (W-1)',
    value: 'Warrant Officer 1'
  },
  {
    label: 'Chief Warrant Officer (W-2)',
    value: 'Chief Warrant Officer 2'
  },
  {
    label: 'Chief Warrant Officer (W-3)',
    value: 'Chief Warrant Officer 3'
  },
  {
    label: 'Chief Warrant Officer (W-4)',
    value: 'Chief Warrant Officer 4'
  },
  {
    label: 'Chief Warrant Officer (W-5)',
    value: 'Chief Warrant Officer 5'
  },
  {
    label: 'Second Lieutenant (O-1)',
    value: 'Second Lieutenant'
  },
  {
    label: 'First Lieutenant (O-2)',
    value: 'First Lieutenant'
  },
  {
    label: 'Captain (O-3)',
    value: 'Captain'
  },
  {
    label: 'Major (O-4)',
    value: 'Major'
  },
  {
    label: 'Lieutenant Colonel (O-5)',
    value: 'Lieutenant Colonel'
  },
  {
    label: 'Colonel (O-6)',
    value: 'Colonel'
  },
  {
    label: 'Brigadier General (O-7)',
    value: 'Brigadier General'
  },
  {
    label: 'Major General (O-8)',
    value: 'Major General'
  },
  {
    label: 'Lieutenant General (O-9)',
    value: 'Lieutenant General'
  },
  {
    label: 'General (O-10)',
    value: 'General'
  },
  {
    label: 'General of the Army',
    value: 'General of the Army'
  }
];

const marineRanks = [
  {
    label: 'Private (E-1)',
    value: 'Private'
  },
  {
    label: 'Private First Class (E-2)',
    value: 'Private First Class'
  },
  {
    label: 'Lance Corporal (E-3)',
    value: 'Lance Corporal'
  },
  {
    label: 'Corporal (E-4)',
    value: 'Corporal'
  },
  {
    label: 'Sergeant (E-5)',
    value: 'Sergeant'
  },
  {
    label: 'Staff Sergeant (E-6)',
    value: 'Staff Sergeant'
  },
  {
    label: 'Gunnery Sergeant (E-7)',
    value: 'Gunnery Sergeant'
  },
  {
    label: 'Master Sergeant (E-8)',
    value: 'Master Sergeant'
  },
  {
    label: 'First Sergeant (E-8)',
    value: 'First Sergeant'
  },
  {
    label: 'Master Gunnery Sergeant (E-9)',
    value: 'Master Gunnery Sergeant'
  },
  {
    label: 'Sergeant Major (E-9)',
    value: 'Sergeant Major'
  },
  {
    label: 'Warrant Officer 1 (W-1)',
    value: 'Warrant Officer 1'
  },
  {
    label: 'Chief Warrant Officer (W-2)',
    value: 'Chief Warrant Officer 2'
  },
  {
    label: 'Chief Warrant Officer (W-3)',
    value: 'Chief Warrant Officer 3'
  },
  {
    label: 'Chief Warrant Officer (W-4)',
    value: 'Chief Warrant Officer 4'
  },
  {
    label: 'Chief Warrant Officer (W-5)',
    value: 'Chief Warrant Officer 5'
  },
  {
    label: 'Second Lieutenant (O-1)',
    value: 'Second Lieutenant'
  },
  {
    label: 'First Lieutenant (O-2)',
    value: 'First Lieutenant'
  },
  {
    label: 'Captain (O-3)',
    value: 'Captain'
  },
  {
    label: 'Major (O-4)',
    value: 'Major'
  },
  {
    label: 'Lieutenant Colonel (O-5)',
    value: 'Lieutenant Colonel'
  },
  {
    label: 'Colonel (O-6)',
    value: 'Colonel'
  },
  {
    label: 'Brigadier General (O-7)',
    value: 'Brigadier General'
  },
  {
    label: 'Major General (O-8)',
    value: 'Major General'
  },
  {
    label: 'Lieutenant General (O-9)',
    value: 'Lieutenant General'
  },
  {
    label: 'General (O-10)',
    value: 'General'
  }
];

const navyRanks = [
  {
    label: 'Seaman Recruit (E-1)',
    value: 'Seaman Recruit'
  },
  {
    label: 'Seaman Apprentice (E-2)',
    value: 'Seaman Apprentice'
  },
  {
    label: 'Seaman (E-3)',
    value: 'Seaman'
  },
  {
    label: 'Seaman First Class (E-3)',
    value: 'Seaman First Class'
  },
  {
    label: 'Petty Officer Third Class (E-4)',
    value: 'Petty Officer Third Class'
  },
  {
    label: 'Petty Officer Second Class (E-5)',
    value: 'Petty Officer Second Class'
  },
  {
    label: 'Petty Officer First Class (E-6)',
    value: 'Petty Officer First Class'
  },
  {
    label: 'Chief Petty Officer (E-7)',
    value: 'Chief Petty Officer'
  },
  {
    label: 'Senior Chief Petty Officer (E-8)',
    value: 'Senior Chief Petty Officer'
  },
  {
    label: 'Master Chief Petty Officer (E-9)',
    value: 'Master Chief Petty Officer'
  },
  {
    label: 'Warrant Officer One (W-1)',
    value: 'Warrant Officer One'
  },
  {
    label: 'Chief Warrant Officer (W-2)',
    value: 'Chief Warrant Officer Two'
  },
  {
    label: 'Chief Warrant Officer (W-3)',
    value: 'Chief Warrant Officer Three'
  },
  {
    label: 'Chief Warrant Officer (W-4)',
    value: 'Chief Warrant Officer Four'
  },
  {
    label: 'Chief Warrant Officer (W-5)',
    value: 'Chief Warrant Officer Five'
  },
  {
    label: 'Midshipman',
    value: 'Midshipman'
  },
  {
    label: 'Ensign (O-1)',
    value: 'Ensign'
  },
  {
    label: 'Lieutenant, Junior Grade (O-2)',
    value: 'Lieutenant, Junior Grade'
  },
  {
    label: 'Lieutenant (O-3)',
    value: 'Lieutenant'
  },
  {
    label: 'First Lieutenant',
    value: 'First Lieutenant'
  },
  {
    label: 'Lieutenant Commander (O-4)',
    value: 'Lieutenant Commander'
  },
  {
    label: 'Commander (O-5)',
    value: 'Commander'
  },
  {
    label: 'Captain (O-6)',
    value: 'Captain'
  },
  {
    label: 'Rear Admiral Lower Half (O-7)',
    value: 'Rear Admiral Lower Half'
  },
  {
    label: 'Rear Admiral Upper Half (O-8)',
    value: 'Rear Admiral Upper Half'
  },
  {
    label: 'Vice Admiral (O-9)',
    value: 'Vice Admiral'
  },
  {
    label: 'Admiral (O-10)',
    value: 'Admiral'
  },
  {
    label: 'Fleet Admiral',
    value: 'Fleet Admiral'
  },
  {
    label: 'Admiral of the Navy',
    value: 'Admiral of the Navy'
  }
];

export { airForceRanks, armyRanks, marineRanks, navyRanks };
