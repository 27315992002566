import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class KasesEditPersonalRoute extends Route {
  layout = 'custom-aside';

  model() {
    const kase = this.modelFor('authenticated.kases.manage');
    return kase.get('deceased').then(result => {
      return result || this.store.createRecord('deceased', { kase });
    });
  }

  setupController(controller, model) {
    controller.set('model', model);
    controller.set('kase', this.modelFor('authenticated.kases.manage'));
  }

  @action
  willTransition() {
    this.modelFor('authenticated.kases.manage').save();
  }
}
