import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminFinancialConfigurationsEditPostingPeriodRoute extends SearchRoute {
   @service router;
   @service session;

   queryParams = {
      query: {
         replace: true,
         refreshModel: true
      },
      archived: {
         refreshModel: true
      },
      starts_at: {
         refreshModel: true
      },
      ends_at: {
         refreshModel: true
      }
   }

   beforeModel() {
      if (this.session.currentUser.isSuperuser || this.session.currentClient.can_view_financial_config_posting_periods) {
        return true;
      }
  
      // Unauthorized users are redirected to the dashboard
      this.router.transitionTo('authenticated.dashboard');
    }
   modelType = 'v2/posting-period';

   model(params) {
      const coa = this.modelFor('authenticated.admin.financial-configurations.edit');
      return this.store.query(this.modelType, {
         chart_of_accounts_id: coa.get('id'),
         ...params,
      });
   }

   setupController(controller) {
      super.setupController(...arguments);

      const chartOfAccounts = this.modelFor('authenticated.admin.financial-configurations.edit');

      controller.set('chartOfAccounts', this.store.findRecord('v2/chartOfAccounts', chartOfAccounts.id));
   }

   @action
   refreshModel() {
      this.refresh();
   }
}
