import { collect } from '@ember/object/computed';
import Component from '@ember/component';
import { set } from '@ember/object';

export default Component.extend({
  classNames: ['well', 'p--0'],

  /**
   * Label of first tab
   * @type {String}
   */
  firstLabel: null,

  /**
   * Label of second tab
   * @type {String}
   */
  secondLabel: null,

  /**
   * Label of third tab
   * @type {String}
   */
  thirdLabel: null,

  /**
   * Label of third tab
   * @type {String}
   */
  forthLabel: null,

  /**
   * Name of selected label
   * @type {String}
   */
  selectedLabel: null,

  /**
   * Collect values of labels into an array
   * @return {Array} Array of labels
   */
  labels: collect('firstLabel', 'secondLabel', 'thirdLabel', 'forthLabel'),

  init() {
    this._super(...arguments);

    // Make sure `selectedLabel` has a value
    if (!this.selectedLabel) {
      set(this, 'selectedLabel', this.firstLabel);
    }
  }
});
