import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import moment from 'moment';

export default class GeneralPaymentModel extends Model {
  @attr('boolean') isBatchPayment;
  @attr('boolean') processorPending;

  @attr('moment-datetime', { defaultValue: () => moment() }) collectedAt;
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;
  @attr('moment-datetime') transnationalDateTimestamp;

  @attr('object') customFieldOne;
  @attr('object') customFieldTwo;

  @attr('price') amount;

  @attr('string', { defaultValue: 'US' }) country;
  @attr('string') billing;
  @attr('string') cardType;
  @attr('string') ccLastFour;
  @attr('string') checkNumber;
  @attr('string') city;
  @attr('string') coaIntegrationName;
  @attr('string') collectedById;
  @attr('string') line1;
  @attr('string') line2;
  @attr('string') name;
  @attr('string') notes;
  @attr('string') payer;
  @attr('string') paymentForFirstName;
  @attr('string') paymentForLastName;
  @attr('string') paymentProcessorStatus;
  @attr('string') state;
  @attr('string') transactionId;
  @attr('string') transnationalAuthorizationCode;
  @attr('string') transnationalDateString;
  @attr('string') transnationalResponseText;
  @attr('string') transnationalStatus;
  @attr('string') uuid;
  @attr('string') zipCode;

  @belongsTo('v2/location', { async: true }) location;
  @belongsTo('v2/paymentType') paymentType;
  @belongsTo('v2/person', { async: true }) person;
  @belongsTo('v2/user', { async: true }) collectedBy;
  @belongsTo('v2/user', { async: true }) enteredBy;

  @hasMany('refunds', { async: true }) refunds;
  @hasMany('v2/payment') payments;

  get paymentForFullName() {
    return [this.paymentForFirstName, this.paymentForLastName].filter(Boolean).join(' ');
  }
}
