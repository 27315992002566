import Service from '@ember/service';

export default class NamesService extends Service {
  splitNameIntoFirstAndLastName(name) {
    const splitNames = {};

    if (name) {
      const names = name.split(' ');
      const [firstName] = names;
      const lastName = names.slice(1).join(' ');

      if (firstName) {
        splitNames.firstName = firstName;
      }

      if (lastName) {
        splitNames.lastName = lastName;
      }
    }

    return splitNames;
  }

  standardTitles = [
    'Ms.',
    'Miss',
    'Mr.',
    'Mrs.',
    'Master',
    'Reverend',
    'Father',
    'Doctor',
    'Attorney',
    'Professor',
    'Honorable',
    'President',
    'Governor',
    'Coach',
    'Officer',
    'Monsignor',
    'Sister',
    'Brother',
    'Superintendent',
    'Representative',
    'Senator',
    'Ambassador',
    'Treasurer',
    'Secretary',
    'Private',
    'Corporal',
    'Sargent',
    'Administrative',
    'Major',
    'Captain',
    'Commander',
    'Lieutenant',
    'Lieutenant Colonel',
    'Colonel',
    'General'
  ];

  standardSuffixes = [
    'B.V.M.',
    'CFRE',
    'CLU',
    'CPA',
    'C.S.C.',
    'C.S.J.',
    'D.C.',
    'D.D.',
    'D.D.S.',
    'D.M.D.',
    'D.O.',
    'D.V.M.',
    'Ed.D.',
    'Esq.',
    'II',
    'III',
    'IV',
    'Inc.',
    'J.D.',
    'Jr.',
    'LL.D.',
    'Ltd.',
    'M.D.',
    'O.D.',
    'O.S.B.',
    'P.C.',
    'P.E.',
    'Ph.D.',
    'Ret.',
    'R.G.S',
    'R.N.',
    'R.N.C.',
    'S.H.C.J.',
    'S.J.',
    'S.N.J.M.',
    'Sr.',
    'S.S.M.O.',
    'USA',
    'USAF',
    'USAFR',
    'USAR',
    'USCG',
    'USMC',
    'USMCR',
    'USN',
    'USNR'
  ];
}
