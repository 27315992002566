import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';
import moment from 'moment';

const Validations = buildValidations({
  'newPackage.name': validator('presence', true),
  'newPackage.invoiceCategoryId': validator('presence', true),
});

export default class AdminGeneralPriceListsEditPackagesIndexController extends SearchController.extend(
  Validations
) {
  @service flashes;
  @service invoiceCategories;
  @service router;
  @service session;

  @tracked archived = false;
  @tracked invoice_id = null;
  @tracked newPackage = null;
  @tracked showValidations = false;

  @alias('invoiceCategories.allSorted') invoiceOptions;

  @readOnly('session.currentUser.canEditProducts') canEditPackages;

  @tracked page = 1;

  @tracked sortBy = null;
  @tracked order = 'ASC';

  queryParams = [
    'query',
    'page',
    'perPage',
    'archived',
    'general_price_list_id',
    'sortBy',
    'order',
    'invoice_id',
  ];

  get productQueryParams() {
    return {
      general_price_list_id: this.generalPriceList.get('id'),
      archived: false,
    };
  }

  @action
  addPackage() {
    this.router.transitionTo('authenticated.admin.general-price-lists.edit.packages.new');
  }

  @action
  cancelNewPackage() {
    this.newPackage.destroyRecord().then(() => {
      this.newPackage = null;
    });
  }

  @action
  resetPagination() {
    this.page = 1;
  }

  @action
  toggleArchived(packageObj) {
    const isArchived = Boolean(packageObj.get('archivedAt'));
    packageObj.set('archivedAt', isArchived ? null : moment());

    packageObj
      .save()
      .then(() => {
        this.flashes.addSuccess(`Package ${isArchived ? 'unarchived' : 'archived'}!`);
        this.send('refreshModel');
      })
      .catch(() => {
        this.flashes.addError('Something went wrong. Please try again.');
      });
  }

  @action
  addPackageProduct(product) {
    this.newPackage.products.addObject(product);
  }

  @action
  removePackageProduct(product) {
    this.newPackage.products.removeObject(product);
  }

  @action
  savePackage() {
    this.validate().then(({ validations }) => {
      if (validations.get('isValid')) {
        this.showValidations = false;
        this.newPackage
          .save()
          .then(() => {
            this.flashes.addSuccess('Package saved! Lets create another one.');
            this.addPackage();
            this.send('refreshModel');
          })
          .catch(() => {
            this.flashes.addError('Something went wrong. Please try again.');
          });
      } else {
        this.showValidations = true;
      }
    });
  }

  @action
  setInvoiceCategory(invoiceId) {
    this.invoice_id = invoiceId;
    this.resetPagination();
  }

  @action
  clearSearch() {
    this.clearQuery();
    this.invoice_id = null;
  }
}
