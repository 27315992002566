import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class KasesManageEditTfeLibraryController extends SearchController {
  @service api;
  @service flashes;
  @service intl;
  @service session;
  @service store;

  @tracked page = 1;

  queryParams = [
    'page',
    'query',
    'archived',
    'favorited',
    'current_client',
    'kase_id',
    'sort_by'
  ];

  archived = false;
  current_client = true;
  favorited = null;
  kase_id = null;
  query = null;
  sort_by = 'favorited';

  handleFillSuccess(formId) {
    this.flashes.addSuccess(
      this.intl.t(
        'authenticated.kases.manage.edit.tfe.forms.index.messages.success'
      )
    );
    const document = this.store.peekRecord('tfe/documents', formId);

    if (document) {
      document.set('hasGeneratedDocuments', true);
    }
  }

  handleError() {
    this.flashes.addError(
      this.intl.t(
        'authenticated.kases.manage.edit.tfe.forms.index.messages.error'
      )
    );
  }

  @action
  async fillForm(formId, kaseContactId, locationId, eventId, inquiryId, revisionId) {
    const response = await this.api.json.post(
      `tfe/documents/${formId}/generate`,
      {
        body: {
          kase_id: this.kase_id,
          revision_id: revisionId,
          form_data: {
            event_id: eventId,
            inquiry_id: inquiryId,
            kase_contact_id: kaseContactId,
            location_id: locationId,
            client_id: this.session.currentClient.id
          }
        }
      }
    );

    if (response.status === 200) {
      this.handleFillSuccess(formId);
    } else {
      this.handleError();
    }
  }

  @action
  openIntercom() {
    window.Intercom('show');
  }
}
