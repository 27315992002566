import Model, { hasMany, attr } from '@ember-data/model';
// eslint-disable-next-line ember/no-mixins
import CustomAction from 'crakn/mixins/custom-action';

/** COMMENT: Still using mixins */
export default class KaseFormModel extends Model.extend(CustomAction) {
  @attr('string') name;
  @attr('string') kaseId;
  @attr('string') blankFormId;
  @attr('moment-datetime') updatedAt;
  @attr('string') description;
  @attr('string') key;
  @attr('string') kaseContactId;
  @attr('string') locationId;
  @attr('string') eventId;
  @attr('string') inquiryId;
  @attr('string') status;
  @attr('boolean') esignatureEnabled;
  @attr('array') esignatureFieldNames;
  @attr('string') esignatureStatus;

  @hasMany('esignedForm') esignedForms;

  refill() {
    const type = this.constructor.modelName;

    return this.customAction('refill_one', 'POST').then((result) => {
      this.store.pushPayload(type, result);
    });
  }
}
