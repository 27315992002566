import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking'
import Route from '@ember/routing/route';

export default class AuthenticatedGenerateStatementRoute extends Route {
  @service api;
  layout = 'fullscreen';
  @tracked isFullscreenActive = true;
  bigReport = false;
  timeout = 4000;

  model(params, transition) {
    if (transition.to.queryParams.bigReport) {
      this.bigReport = true;
    }
    return params.uuid;
  }

  attemptRequest(attempt, url) {
    later(() => {
      fetch(url, { method: 'GET' })
        .then(response => {
          if (response.ok) {
            set(
              this,
              'controller.message',
              'Document successfully generated, you may now close this tab.'
            );
            window.location = url;
          } else {
            this.documentTimeout(++attempt, url);
          }
        });
    }, this.timeout);
  }

  documentTimeout(attempt, url) {
    if (attempt > 50) {
      set(
        this,
        'controller.message',
        'Looks like something went wrong. Please try again.'
      );
      throw 'Document failed to generate';
    }

    if (this.bigReport && attempt > 10) {
      if (attempt > 30) {
        this.timeout = 110000;
      } else if (attempt > 20) {
        this.timeout = 90000;
      } else if (attempt > 10) {
        this.timeout = 45000;
      }
    } else {
      if (attempt > 10) {
        this.timeout = 20000;
      } else if (attempt > 5) {
        this.timeout = 10000;
      } else if (attempt > 2) {
        this.timeout = 7000;
      }
    }

    this.attemptRequest(attempt, url);
  }

  getDocumentUrl(attempt, uuid) {
    if (attempt > 5) {
      set(
        this,
        'controller.message',
        'Looks like something went wrong. Please try again.'
      );
      throw 'Document failed to generate';
    } else {
      later(() => {
        this.api.json.get(
          'temporaryUploads/show',
          { params: { uuid } }
        ).then(response => {
          if (response.ok) {
            this.documentTimeout(0, response.parsedJson.url);
          } else {
            this.getDocumentUrl(++attempt, uuid);
          }
        });
      }, this.timeout);
    }
  }

  @action
  didTransition() {
    const uuid = this.currentModel;

    this.getDocumentUrl(0, uuid);
  }
}
