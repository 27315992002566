import { and } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { get, computed } from '@ember/object';

export default Component.extend({
  classNames: ['actionable-list__item'],
  classNameBindings: ['qaClassName'],

  flashes: service(),
  browser: service(),

  content: null,
  isCondensed: false,
  isStaffEnabled: false,
  isDeleteEnabled: false,
  isEditEnabled: false,
  route: 'authenticated.kases.events.edit',

  isStaffVisible: and('isStaffEnabled', 'content.eventStaffMembers.length'),

  qaClassName: computed('content.id', {
    get() {
      return `qa-event-info-${get(this, 'content.id')}`;
    }
  }),

  actions: {
    selectEvent() {
      this.sendAction('onSelectEvent', this.content);
    },

    deleteEvent() {
      this.content
        .destroyRecord()
        .then(() =>
          this.flashes.addSuccess('The event was successfully was deleted.')
        );
    }
  }
});
