import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminGeneralPriceListsEditProductsRoute extends Route {
  @service productCategories;

  queryParams = {
    query: {
      replace: true,
      refreshModel: true
    },
    general_price_list_id: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    sort_by: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    },
    category_id: {
      refreshModel: true
    },
    invoice_id: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    }
  };

  model(params) {
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');
    params.general_price_list_id = gpl.id;
    return this.store.query('product', params);
  }

  setupController(controller) {
    super.setupController(...arguments);
    const store = this.store;
    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');
    controller.set('generalPriceList', gpl);
    controller.set('statementCategories', store.findAll('statementCategory'));
    controller.set(
      'statementSubcategories',
      store.findAll('statementSubcategory')
    );
    controller.set(
      'gplProductCategories',
      this.productCategories.getProductCategories(gpl.id)
    );
    controller.set('general_price_list_id', gpl.id);
    controller.set('chartOfAccounts', gpl.chartOfAccounts);
  }

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = ![
        'authenticated.admin.general-price-lists.edit.products.index',
        'authenticated.admin.general-price-lists.edit.products.edit'
      ].includes(transition.targetName);

      const newProduct = controller.newProduct;

      if (newProduct) {
        newProduct.destroyRecord();
        this.controller.newProduct = null;
      }

      if (resetParams) {
        controller.setProperties({
          page: 1,
          query: null,
          sort_by: null,
          archived: false,
          order: 'ASC'
        });
      }
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
