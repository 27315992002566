import Controller from '@ember/controller';
import { get, computed } from '@ember/object';
import moment from 'moment';

export default Controller.extend({
  formattedTributePrintExportedAt: computed(
    'model.tributePrintExport',
    function() {
      return moment(
        get(this, 'model.tributePrintExport.updatedAt')
      ).format('LLL');
    }
  ),

  invalidDeathDate: computed(
    'model.kase.deceased.profileDeathDate',
    function() {
      return get(this, 'model.kase.deceased.profileDeathDate') > moment();
    }
  )
});
