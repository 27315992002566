import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { startCase } from 'lodash-es/string';

export default Component.extend({
  classNameBindings: ['qaClass'],
  model: null,
  session: service(),
  currentUserId: readOnly('session.currentUser.id'),

  isLeadFuneralDirector: computed(
    'currentUserId',
    'model.staffMember.id',
    function() {
      return get(this, 'model.staffMember.id') === this.currentUserId;
    }
  ),

  isEmbalmer: computed('currentUserId', 'model.embalmer.id', function() {
    return get(this, 'model.embalmer.id') === this.currentUserId;
  }),

  isRemovalStaffMember: computed(
    'currentUserId',
    'model.removalStaffMember.id',
    function() {
      return get(this, 'model.removalStaffMember.id') === this.currentUserId;
    }
  ),

  startCaseStatus: computed('model.status', function() {
    return startCase(get(this, 'model.status'));
  }),

  qaClass: computed('model.id', function() {
    return `qa-kase-summary-${get(this, 'model.id')}`;
  })
});
