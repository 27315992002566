import Component from '@ember/component';
import { set } from '@ember/object';
import Clipboard from 'clipboard';

export default Component.extend({
  target: null,
  text: null,
  attributeBindings: ['title'],
  title: 'Copy to clipboard',

  didInsertElement() {
    const clipboard = new Clipboard('.btn');
    clipboard.on('success', () => {
      this.alertAfterCopy();
    });
    set(this, 'craknClipboard', clipboard);
  },

  willDestroyElement() {
    this.craknClipboard.destroy();
  },

  alertAfterCopy() {
    this.sendAction('onAfterCopy');
  }
});
