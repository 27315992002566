import { set } from '@ember/object';
import { run } from '@ember/runloop';

export const searchQueryParams = ['query', 'page', 'archived'];
export const page = 1;
export const query = null;
export const archived = false;

/**
   * Updates the query value after debounce and resets page number
   * @param {Object} obj The object to modify with `set` 
   * @param  {String} value String to update `query` to
   * @return {Undefined}
   */
export function updateQuery(obj, value) {
  set(obj, 'query', value);
  set(obj, 'page', 1);
}

/*==== THE FOLLOWING FUNCTIONS SHOULD BE SET UP AS ACTIONS ====*/
/**
   * Updates the query value after a debounce
   * @param {Object} obj The target of `run.debounce`'s method, as well as where said method is located
   * @param  {String} value Value to update `query` to
   * @return {Undefined}
   */
export const queryDidChange = (obj, value) => {
  run.debounce(obj, obj.updateQuery, value, 50, true);
}

/**
   * Toggles the archived value and resets page number
   * @param {Object} obj The object to modify with `set` 
   * @param  {Boolean} value Whether or not archived is selected
   * @return {Undefined}
   */
export const archivedDidChange = (obj, value) => {
  set(obj, 'archived', value);
  set(obj, 'page', 1);
}