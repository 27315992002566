/* eslint-disable ember/no-computed-properties-in-native-classes */
import SearchController from 'crakn/controllers/search';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import { htmlSafe } from '@ember/template';

export default class AdminGeneralPriceListsEditPricingImportsController extends SearchController {
  @service flashes;
  @service intl;
  @service api;

  @tracked archived = false;
  @tracked page = 1;
  @tracked gpl = undefined;
  @tracked activeProgress = undefined;
  @tracked timeout = 4000;

  @tracked export_uuid;

  get csvLink() {
    return `#/generated_document/${this.export_uuid}`;
  }

  @computed('export_uuid', 'gpl.id')
  get reportLink() {
    const params = new URLSearchParams({
      uuid: this.export_uuid,
      general_price_list_id: this.gpl?.id,

    }).toString();
    return `/report/gpl_export?${params}`;
  }

  get active() {
    const today = new Date();
    const found = this.model.find(item => {
      return (item.data.status === 'created' || item.data.status === 'in_progress') && item.createdAt?.isSame(today, 'day');
    });

    return found
  }

  get percentComplete() {
    return this.activeProgress?.percent_complete || 0;
  }

  get precentBarWidth() {
    return htmlSafe(`width: ${this.percentComplete}%;`);
  }


  @action
  onActiveDetected() {
    this.attemptProgressRequest(1);
  }

  @action
  attemptProgressRequest(attempt) {
    if (!this.active) return;
    later(() => {
      this.api.json.get(`v2/integration_imports/${this.active.id}/gpl_progress`)
        .then(response => {

          if (!response.ok) {
            this.progressTimeout(++attempt);
            return;
          }

          this.activeProgress = response?.parsedJson?.import;
          const isFinished = ['completed', 'failed'].includes(this.activeProgress?.status) || this.activeProgress?.percent_complete == 100;

          if (!isFinished) {
            this.progressTimeout(++attempt);
            return;
          }

          this.activeProgress = undefined;
          this.send('refreshModel');
        });
    }, this.timeout);
  }

  @action
  progressTimeout(attempt) {
    if (attempt > 50) return;

    if (attempt > 10) {
      if (attempt > 30) {
        this.timeout = 110000;
      } else if (attempt > 20) {
        this.timeout = 90000;
      } else if (attempt > 10) {
        this.timeout = 45000;
      }
    } else {
      if (attempt > 10) {
        this.timeout = 20000;
      } else if (attempt > 5) {
        this.timeout = 10000;
      } else if (attempt > 2) {
        this.timeout = 7000;
      }
    }

    this.attemptProgressRequest(attempt);
  }

  @action
  onImport() {
    this.send('refreshModel');
  }
}
