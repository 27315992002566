import Component from '@glimmer/component';
import moment from 'moment';
import { action, set } from '@ember/object';

export default class GraystoneSurveyReport extends Component {
  startsAt =  moment()
    .subtract(30, 'day')
    .toISOString();
  endsAt = moment().toISOString();

  organization_location_id = null

  get reportLink() {
    const params = new URLSearchParams({
      organization_location_id: this.organization_location_id,
      starts_at: this.startsAt,
      ends_at: this.endsAt,
      uuid: this.args.uuid
    }).toString();

    return `/report/graystone_survey_report?${params}`;
  }

  get fileUrl() {
    return `#/generated_document/${this.args.uuid}`;
  }

  @action
  generateReport() {
    this.args.onGenerateReport(this.reportLink);
  }

  @action
  setDate(attr, date) {
    set(this, attr, date);
  }
}
