import Route from '@ember/routing/route';
import { inject as service } from '@ember/service'

export default class AuthenticatedAdminDataIndexRoute extends Route {
  @service session;

  model() {
    return this.session.currentClient;
  }

  afterModel(model, transition) {
    // transition if the route is headed to data.index
    if (/data\.index$/.test(transition.to.name)) {
      if (model.can_view_kase_tags && model.can_view_kase_tags_admin) {
        this.transitionTo('authenticated.admin.data.case-tags')
      } else {
        this.transitionTo('authenticated.admin.data.embalm-reasons')
      }
    }
  }
}