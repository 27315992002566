import Controller from '@ember/controller';
import config from 'crakn/config/environment';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';

export default class KasesEditKaseFormsController extends Controller {
  @service api;
  @service flashes;
  hostUrl = `${config.host}`;
  page = 1;
  queryParams = ['page'];
  refillAllEnabled = true;


  @computed('model.@each.status')
  get kaseFormsPresent() {
    return this.model.length > 0;
  }

  @computed('model.@each.status')
  get formInProgress() {
    const inProgressForms = this.model.filterBy('status', 'in_progress');
    return inProgressForms.length > 0;
  }

  handleError() {
    this.flashes.addError('Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.');
  }

  handleDeleteSuccess(form) {
    this.model.removeObject(form._internalModel);
    this.flashes.addSuccess('Form successfully deleted!');
    this.setUnfilled(form);
  }

  setUnfilled(form) {
    this.store.queryRecord('blankForm', {
      id: form.get('blankFormId'),
      kase_id: this.kase.get('id')
    })
    .then((blankForm) => {
      blankForm.set('filled', false);
      this.send('refreshModel');
    })
    .catch(() => {
      this.handleError();
    });
  }

  refillForm(form) {
    const model = this.model.get('content');
    model.removeObject(form._internalModel);
    form.refill().then(() => {
      this.send('refreshModel');
    }).catch(() => {
      this.handleError();
    });
  }

  toggleRefillAllEnabled() {
    this.toggleProperty('refillAllEnabled');
  }

  @action
  checkForUploadCompleted(form) {
    this.store.queryRecord('kaseForm', { id: form.get('id') }).catch(() => {
      this.handleError();
    });
  }

  @action
  prepareToRefillForm(form) {
    if (this.kase.get('hasDirtyAttributes')) {
      this.kase
        .save()
        .then(() => {
          this.refillForm(form);
        })
        .catch(() => {
          this.handleError();
        });
    } else {
      this.refillForm(form);
    }
  }

  @action
  refillAll() {
    this.toggleRefillAllEnabled();
    this.api.json
        .post(
          'kase_forms/refill_all',
          {
            params: {
              kase_id: this.kase.get('id')
            }
          }
        )
        .then(response => {
          if (response.ok) {
            this.set('page', 1);
            later(this, this.toggleRefillAllEnabled, 4000);
            this.send('refreshModel');
          } else {
            this.handleError();
          }
        });
  }

  @action
  deleteForm(form) {
    form
      .destroyRecord()
      .then(() => {
        this.send('refreshModel');
        this.handleDeleteSuccess(form);
      })
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        this.kase.set('hasKaseForms', this.model.length > 0)
      });
  }

}
