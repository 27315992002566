import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  model: null,
  store: service(),
  session: service(),

  relatedInquiries: computed('model', {
    get() {
      const contact = this.model;
      return this.store.query('inquiry', {
        contact: contact.get('id')
      });
    }
  }),

  relatedKaseContacts: computed('model', {
    get() {
      const contactId = this.model.get('id');
      return this.store.query('kaseContact', {
        person: contactId
      });
    }
  }),

  relatedKasePeople: computed('model', {
    get() {
      const contactId = this.model.get('id');
      return this.store.query('kasePerson', {
        person: contactId
      });
    }
  })
});
