import Model, { belongsTo, attr } from '@ember-data/model';

export default class LogoModel extends Model {
  @attr('moment-datetime') archivedAt;
  @attr('string') name;
  @attr('boolean') receipt;
  @attr('boolean') sfgs;
  @attr('boolean') soa;
  @attr('boolean') cremauth;
  @attr('string') url;

  @belongsTo('client-info') clientInfo;
}
