import { sort, mapBy, max, filter } from '@ember/object/computed';
import Component from '@ember/component';
import { get } from '@ember/object';
import { overlaps } from 'crakn/utils/date-helpers';

export default Component.extend({
  tagName: 'tr',

  eventSorting: ['startsAtUnix', 'duration:desc'],
  sortedApplicableEvents: sort('applicableEvents', 'eventSorting'),

  applicableEndsAt: mapBy('applicableEvents', 'endsAtUnix'),
  maxApplicableEndsAt: max('applicableEndsAt'),

  applicableEvents: filter('events', function(event) {
    return event.get('hour') === get(this, 'timeslot.hour');
  }),

  laterEvents: filter('events', function(event) {
    return event.get('hour') > get(this, 'timeslot.hour');
  }),

  overlappingEvents: filter('events', function(event) {
    if (!get(this, 'timeslot.startsAt') || !event.get('startsAt')) {
      return false;
    }
    return overlaps(this.timeslot, event);
  }),

  parentEvents: filter('events', function(event) {
    const timeslot = get(this, 'timeslot.startsAt').unix();

    return (
      event.get('startsAtUnix') < timeslot && event.get('endsAtUnix') > timeslot
    );
  }),

  childEvents: filter('laterEvents', function(event) {
    const maxApplicableEndsAt = this.maxApplicableEndsAt;

    return event.get('startsAtUnix') < maxApplicableEndsAt;
  }),

  actions: {
    selectEvent(event) {
      this.sendAction('onSelectEvent', event);
    }
  }
});
