import Controller from '@ember/controller';
import moment from 'moment';
export default class PrecoaController extends Controller {
  get formattedPreocaExportedAt() {
    return moment(this.model.precoaExport.updatedAt).format('LLL');
  }

  get invalidDeathDate() {
    return this.model.kase.deceased.get('profileDeathDate') > moment();
  }

  get displayContactsDisclaimer() {
    if (!this.model.precoaExport) {
      return true;
    } else {
      return false;
    }
  }

  get canExport() {
    if (this.model.kase.deceased.get('profileDeathDate')) {
      return true;
    } else {
      return false;
    }
  }
}
