import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { v4 as uuidv4 } from 'uuid';

export default class AdminGeneralPriceListsEditPreneedCategoriesRoute extends Route {
  @service api;
  @tracked export_uuid;

  cont;

  model() {
    return this.modelFor(
      'authenticated.admin.general-price-lists.edit'
    );
  }

  setupController(controller, model) {
    this.cont = controller;
    this.export_uuid = uuidv4();
    this.cont.set('export_uuid', this.export_uuid)
    this.cont.set('generalPriceList', model)
  }

  @action
  generateReport(reportLink) {
    this.api.json
      .get(reportLink)
      .then(response => {
        if (!response.ok) {
          this.send('flashError', 'Oops, something went wrong! Please try again.');
        }
        this.export_uuid = uuidv4();
        this.cont.set('export_uuid', this.export_uuid);
      });
  }
}
