import Model, { belongsTo, attr } from '@ember-data/model';

export default class IntegrationImportModel extends Model {
  @attr('string') serviceName;
  @attr('moment-datetime') updatedAt;
  @attr('moment-datetime') createdAt;
  @attr('string') deceasedName;

  @belongsTo('client') client;
  @belongsTo('kase') kase;
}
