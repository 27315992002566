import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminCustomFieldNewRoute extends Route {
  @service session;

  model() {
    return this.store.createRecord('v2/custom-field-config', {
      customFields: [],
      location: null
    });
  }

  setupController(controller, model) {
    const customFieldConfigs = this.store.findAll(
      'v2/customFieldConfig',
      this.session.get('session.authenticated.client.id')
    );

    controller.set('customFieldConfigs', customFieldConfigs);
    controller.set('customFieldConfig', model);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      this.store.unloadAll('v2/custom-field');
      controller.setProperties({
        customFieldConfigs: null,
        customFieldConfig: null
      });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
