import Model, { belongsTo, attr } from '@ember-data/model';
import { relativeSize, documentExtentions } from 'crakn/utils/data-helpers';
import config from 'crakn/config/environment';
import moment from 'moment';

export default class DocumentModel extends Model {
  @attr('string') name;
  @attr('number') size;
  @attr('string') description;
  @attr('string') key;
  @attr('moment-datetime') createdAt;
  @attr('string') extension;
  @attr('string') mediaType;

  @belongsTo('kase', { async: true }) kase;
  @belongsTo('user', { async: true }) createdByUser;

  get file() {
    return `${config.host}/api/documents/${this.id}/download`;
  }

  get createdAtUnix() {
    return this.createdAt ? this.createdAt.unix() : moment().unix();
  }

  get relativeSize() {
    return relativeSize(this.size);
  }

  get icon() {
    let type;

    if (!this.mediaType) {
      return undefined;
    }

    if (this.mediaType.includes('image/')) {
      type = 'image';
    } else if (documentExtentions.includes(this.extension)) {
      type = 'document';
    } else if (this.mediaType.includes('audio/')) {
      type = 'music';
    } else if (this.mediaType.includes('video/')) {
      type = 'video';
    }

    return `file-${type}`;
  }
}
