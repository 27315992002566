import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CalendarNewEventController extends Controller {
  @service flashes;
  @service intl;
  @service router;

  handleSaveSuccess() {
    this.flashes.addSuccess(this.intl.t('authenticated.calendar.new-event.messages.save.success'));
    this.router.transitionTo('authenticated.calendar.index');
  }

  handleSaveError() {
    this.flashes.addError(this.intl.t('authenticated.calendar.new-event.messages.save.error'));
  }

  @action
  deleteEvent(event) {
    event
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess(this.intl.t('authenticated.calendar.new-event.messages.delete.success'));
        this.router.transitionTo('authenticated.calendar.index');
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.calendar.new-event.messages.delete.error'));
      });
  }

  @action
  cancelEvent() {
    this.router.transitionTo('authenticated.calendar.index');
  }

  @action
  saveEvent() {
    this.handleSaveSuccess();
  }

  @action
  saveAndAddAnotherEvent() {
    this.flashes.addSuccess(this.intl.t('authenticated.calendar.new-event.messages.save.success'));
    this.store
      .createRecord('event')
      .save()
      .then((newEvent) => {
        this.router.transitionTo('authenticated.calendar.new-event', newEvent);
      });
  }

  @action
  openEventModal(event) {
    event.set(
      'isCurrentEvent',
      event.get('isNew') || event.get('id') === this.model.id
    );
    this.send('showModal', 'g/whiteboard/modal/event-details', event);
  }
}
