import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class DebugController extends Controller {
  @service session;
  @service flashes;
  @service browser;
  @service intl;

  get browserUserAgent() {
    return navigator.userAgent;
  }

  @action
  alertAfterCopy() {
    const msg = (this.intl.t('debug.copy.message'));
    this.flashes.addSuccess(msg);
  }
}
