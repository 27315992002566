import Component from '@ember/component';
import { set, computed } from '@ember/object';

export default Component.extend({
  tagName: 'tr',
  person: null,
  showReplaceDialog: false,

  mergePrompt: computed('person.name', function() {
    return `This will permanently delete ${this.person.name} and merge their associated records into the contact you are managing. Are you sure?`;
  }),

  actions: {
    viewReplaceDialog() {
      set(this, 'showReplaceDialog', true);
    },

    deleteAndReplace(person) {
      this.sendAction('deleteAndReplace', person);
    }
  }
});
