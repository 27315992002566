import Route from '@ember/routing/route';
import RSVP from 'rsvp';

export default Route.extend({
  layout: 'custom-aside',

  model() {
    const model = this.modelFor('authenticated.kases.manage.integrations');

    return RSVP.hash({
      model,
      kase: model,
      precoaExport: this.store.queryRecord('integrationExport', {
        kase_id: model.id,
        type: 'precoa',
        status,
      }),
      integrationService: this.store.queryRecord('integrationService', {
        kase_id: model.id,
        name: 20,
        not_category: 'accounting',
      }),
    });
  },

  setupController(controller, model, transition) {
    this._super(controller, model, transition);
  },

  actions: {
    precoa(e) {
      const kase = this.currentModel.kase;
      const integrationService = this.currentModel.integrationService;

      e.target.disabled = true; // double click
      this.store
        .createRecord('integrationExport', {
          kase,
          integrationServiceId: integrationService.id,
          status: 'pending',
        })
        .save()
        .then(() => {
          this.send('flashSuccess', 'Case successfully scheduled for export!');
          this.refresh();
          e.target.disabled = false;
        })
        .catch(() => {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false;
        });
    },
  },
});
