import { filterBy } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';

export default class VehicleService extends Service {
  @service store;
  allVehicles = null;

  get all() {
    if (this.allVehicles == null) {
      this.allVehicles = this.store.query('vehicle', { per_page: 1000 });
    }

    return this.allVehicles;
  }

  unloadAll() {
    this.allVehicles = null;
  }

  @filterBy('all', 'archivedAt', null) active;
}
