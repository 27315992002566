import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknFormsEditRoute extends Route {
  @service store;

  model(params) {
    return this.store.findRecord('blankForm', params.form_id, { adapterOptions: { can_view_form_in_tfe: false } });
  }

  setupController(controller, model, _transition) {
    super.setupController(...arguments);
    controller.setProperties(model);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.model.rollbackAttributes();

      controller.setProperties({
        form: null,
        formFile: null,
        showValidation: false
      });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
