import Component from '@ember/component';
import { set, computed } from '@ember/object';

export default Component.extend({
  classNames: ['crakn-read-more'],

  isReadMore: false,

  isTooLong: computed('content', 'maxLength', function() {
    const content = this.content;
    if (content) {
      return content.length > this.maxLength;
    } else {
      return false;
    }
  }),

  isTruncated: computed('isTooLong', 'isReadMore', function() {
    return this.isTooLong && !this.isReadMore;
  }),

  truncatedContent: computed('content', 'isTruncated', 'maxLength', function() {
    const content = this.content;

    if (this.isTruncated) {
      return `${content.slice(0, this.maxLength)}...`;
    } else {
      return content;
    }
  }),

  actions: {
    toggleReadMore() {
      set(this, 'isReadMore', !this.isReadMore);
    }
  }
});
