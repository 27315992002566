import { inject as service } from '@ember/service';
import Component from '@ember/component';

export default Component.extend({
  classNames: ['flashes'],
  flashes: service(),

  actions: {
    dismissFlash(flash) {
      this.flashes.remove(flash);
    }
  }
});
