import Model, { belongsTo, attr } from '@ember-data/model';
import moment from 'moment';
import { set } from '@ember/object';
import { v4 as uuidv4 } from 'uuid';

export default class RefundModel extends Model {
  @attr('string') coaIntegrationName;
  @attr('string') internalReceiptUuid;
  @attr('string') name;
  @attr('string') netsuiteId;
  @attr('string') notes;
  @attr('string') refundTo;
  @attr('string') tpsRefundId;

  @attr('moment-datetime', { defaultValue: () => moment() }) collectedAt;
  @attr('moment-datetime', { defaultValue: () => moment() }) createdAt;
  @attr('moment-datetime') exportedAt;

  @attr('price') amount;

  @attr('boolean') exported;

  @belongsTo('client') client;
  @belongsTo('user', { async: true }) collectedBy;
  @belongsTo('user', { async: true }) enteredBy;
  @belongsTo('user', { async: true }) exportedBy;
  @belongsTo('payment', { async: true }) payment;
  @belongsTo('v2/generalPayment', { async: true }) generalPayment;
  @belongsTo('person') person;
  @belongsTo('selection', { async: true }) selection;

  get receiptUuid() {
    if (this.internalReceiptUuid) {
      return this.internalReceiptUuid;
    } else {
      set(this, 'internalReceiptUuid', uuidv4())
      return this.internalReceiptUuid;
    }
  }
  set receiptUuid(uuid) {
    set(this, 'internalReceiptUuid', uuid);
  }
}
