  
import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    const kase_id = this.modelFor('authenticated.kases.manage').get('id');
    return this.store.findRecord('v2/kase', kase_id, {
      include: 'custody-audits.entered-by,\
custody-audits.personal-effects,\
custody-audits.start-place.address,\
custody-audits.end-place.address,\
custody-audits.start-room,\
custody-audits.end-room,\
custody-audits.kase,\
personal-effects,deceased',
    });
  },
});
