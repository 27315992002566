import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class BassMollettSetupController extends Controller {
  showEmailField = true;
  showSecretField = true;
  secretPlaceholder = 'Password';

  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
