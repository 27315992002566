import Controller from '@ember/controller'
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AdminLocationsGeneralPriceListController extends Controller {
  @service store;

  @controller('authenticated/admin/locations/edit') edit;

  @tracked gplSwitchDisabled = false;

  @action
  setGeneralPriceList(gplId) {
    this.model.generalPriceList = this.store.peekRecord('v2/generalPriceList', gplId);
  }

  @action
  save(event) {
    this.edit.save(event);
    this.model.get('generalPriceList')
              .then((gpl) => {
                if (gpl) {
                  this.gplSwitchDisabled = true;
                }
              })
  }
}