import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex\">\n  <div class=\"c--xs--12 {{if @isInquiry \"c--iti--6\" \"c--ti--6\"}}\">\n    <G::Crakn::Pikaday\n        @placeholder={{@dateLabel}}\n        @value={{@value}}\n        @onChange={{this.changeDate}}/>\n  </div>\n  <div class=\"c--xs--12 {{if @isInquiry \"c--iti--6\" \"c--ti--6\"}}\">\n    <G::Crakn::Timepicker\n        data-test-timepicker\n        @value={{@value}}\n        @placeholder={{@timeLabel}}\n        @onBlur={{this.change}}/>\n  </div>\n</div>\n", {"contents":"<div class=\"flex\">\n  <div class=\"c--xs--12 {{if @isInquiry \"c--iti--6\" \"c--ti--6\"}}\">\n    <G::Crakn::Pikaday\n        @placeholder={{@dateLabel}}\n        @value={{@value}}\n        @onChange={{this.changeDate}}/>\n  </div>\n  <div class=\"c--xs--12 {{if @isInquiry \"c--iti--6\" \"c--ti--6\"}}\">\n    <G::Crakn::Timepicker\n        data-test-timepicker\n        @value={{@value}}\n        @placeholder={{@timeLabel}}\n        @onBlur={{this.change}}/>\n  </div>\n</div>\n","moduleName":"crakn/components/g/crakn/pikaday-datetime.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/pikaday-datetime.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment';
import { nearestHour } from '../../../utils/nearest-hour';

export default class GCraknPikadayDatetimeComponent extends Component {
  @action
  change(dateTime) {
    this.args.onBlur(dateTime);
  }

  @action
  changeDate(date) {
    const originalDateTime = moment(this.args.value);
    
    const newDateTime = moment(date);

    if(!newDateTime.isValid()) { 
      this.change(null); 
      return; 
    }    
    
    const isMidnight = (originalDateTime.hours() === 0 && originalDateTime.minutes() === 0);

    if(!originalDateTime.isValid() || isMidnight) {
      this.setDateTime(date, nearestHour(moment()), 0);
    } else {
      this.setDateTime(date, originalDateTime.hours(), originalDateTime.minutes())
    }
  }

  setDateTime(dateString, hours, minutes) {
    const newDateTime = moment(dateString).hours(hours).minutes(minutes);

    this.change(newDateTime);
  }
}
