import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminOnlinePlannerEditController extends Controller {
  @service router;

  get pageName() {
    const start = this.router.currentURL.lastIndexOf('/') + 1;
    return this.router.currentURL.substring(start);
  }

  @action
  navigate(page, e) {
    e.preventDefault();
    this.router.transitionTo(`authenticated.admin.online-planner.edit.page`, this.model.id, page);
  }
}
