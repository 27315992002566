import { sort } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Toolbelt from 'crakn/mixins/component-toolbelt';
import Search from 'crakn/mixins/search';

export default Component.extend(Toolbelt, Search, {
  store: service(),

  /**
   * Product Category to query from
   * @type {Object}
   */
  productCategory: null,
  generalPriceListId: null,

  /**
   * Returns a list of product based off `productCategory` and `query`
   * @return {Array} List of products
   */
  products: computed(
    'generalPriceListId',
    'productCategory',
    'query',
    function() {
      const productCategory = this.productCategory;
      const query = this.query;

      // Make sure a product category is selected
      if (!productCategory) {
        return;
      }

      return this.store.query('product', {
        category_id: get(productCategory, 'id'),
        general_price_list_id: this.generalPriceListId,
        archived: false,
        per_page: 5000,
        query
      });
    }
  ),

  /**
   * Params for sorting
   * @type {Array}
   */
  sortProps: ['name:asc'],

  /**
   * Sorts the list of products
   * @return {Array} Sorted list of products
   */
  sortedProducts: sort('products', 'sortProps')
});
