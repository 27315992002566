import { sort } from '@ember/object/computed';
import Service, { inject as service } from '@ember/service';

export default class LocationService extends Service {
  @service store;
  allLocations = null;
  archivedLocations = null;

  get all() {
    if (this.allLocations == null) {
      this.allLocations = this.store.query('location', { per_page: 1000 });
    }

    return this.allLocations;
  }

  unloadAll() {
    this.allLocations = null;
    this.archivedLocations = null;
  }

  get archived() {
    if (this.archivedLocations == null) {
      this.archivedLocations = this.store.query('location', {
                                                  per_page: 1000,
                                                  archived: false
                                                });
    }

    return this.archivedLocations;
  }

  locationNameSort = ['name:asc'];
  @sort('all', 'locationNameSort') allSorted;
  @sort('archived', 'locationNameSort') archivedSorted;
}
