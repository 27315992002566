export default [
  'Body Donation',
  'Burial',
  'Cremation',
  'Disinterment',
  'Entombment',
  'Removal from State',
  'Resomation',
  'Other',
];
