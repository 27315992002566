import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { setProperties } from '@ember/object';
import { action } from '@ember/object';

export default class ApplicationRoute extends Route {
  @service flashes;
  @service intl;
  @service session;
  @service router;

  beforeModel() {
    this.intl.setLocale(['en-us']);
    this.updateSsoPasswordRedirect();
    this.ssoLoginRedirect();
  }

  updateSsoPasswordRedirect(){ 
    const parsed = new URL(window.location.href);
    const session_state = parsed?.searchParams?.get('session_state');
    const kc_action_status = parsed?.searchParams?.get('kc_action_status');
    const hasSessionState = session_state
    const hasActionStatus= kc_action_status
    if (hasSessionState && hasActionStatus) {
      history.pushState({}, '', '/#/settings/info');
      this.router.transitionTo('authenticated.settings.info');
    }


  }

  title(tokens) {
    if (this.session.currentUser && this.session.currentUser.isSuperuser) {
      tokens.push(this.session.currentClient.name);
    }
    tokens.push(this.intl.t('appTitle.acronym'));
    return tokens.join(' | ');
  }

  ssoLoginRedirect() {
    const parsed = new URL(window.location.href);
    const state = parsed?.searchParams?.get('state');
    const code = parsed?.searchParams?.get('code');
    const hasState = state && state != '';
    const hasCode = code && code != '';
    if (hasState && hasCode) {
      history.pushState({}, '', '/#/login');
      this.router.transitionTo('login', { queryParams: { state: state, code: code } });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }

  @action
  flashSuccess(message) {
    this.flashes.addSuccess(message);
  }

  @action
  flashError(message) {
    this.flashes.addError(message);
  }

  @action
  flashInfo(message) {
    this.flashes.addInfo(message);
  }

  @action
  showModal(modalDialogName, modalContext) {
    const applicationController = this.controller;

    setProperties(applicationController, {
      modalDialogName,
      modalContext,
      isModalVisible: true
    });
  }
}
