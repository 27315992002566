import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import config from 'crakn/config/environment';

export default Component.extend({
  classNames: ['list__item', 'list__item--wrap'],
  classNameBindings: ['qaClass'],
  tagName: 'li',
  browser: service(),
  store: service(),
  session: service(),
  kase: null,
  toBeFilled: false,
  requiresKaseContact: false,
  requiresLocation: false,
  requiresEvent: false,
  requiresInquiry: false,
  kaseContactId: null,
  locationId: null,
  eventId: null,
  inquiryId: null,
  events: null,

  formLink: computed('form.id', function() {
    return `${config.host}/api/blank_forms/${get(this, 'form.id')}/download`;
  }),

  qaClass: computed('form.id', function() {
    return `qa-blank-form-${get(this, 'form.id')}`;
  }),

  actions: {
    prepareToFillForm(form) {
      set(this, 'toBeFilled', false);
      if (get(form, 'kaseContactDropdown') === true) {
        set(this, 'toBeFilled', true);
        set(this, 'requiresKaseContact', true);
      }
      if (get(form, 'locationDropdown') === true) {
        set(this, 'toBeFilled', true);
        set(this, 'requiresLocation', true);
        set(this, 'locationId', get(this, 'kase.location.id'));
      }
      if (get(form, 'eventDropdown') === true) {
        set(this, 'toBeFilled', true);
        set(this, 'requiresEvent', true);
      }
      if (get(form, 'inquiryDropdown') === true) {
        set(this, 'toBeFilled', true);
        set(this, 'requiresInquiry', true);
        set(this, 'inquiryId', get(this, 'kase.inquiry.id'));
      }
      this.sendAction('saveKaseIfNecessary');

      if (
        !form.get('kaseContactDropdown') &&
        !form.get('eventDropdown') &&
        !form.get('locationDropdown') &&
        !form.get('inquiryDropdown')
      ) {
        this.send('fillForm', form);
      }
    },

    fillForm(form, kaseContactId, locationId, eventId, inquiryId) {
      this.fillForm(
        form.get('id'),
        kaseContactId,
        locationId,
        eventId,
        inquiryId
      );
      set(this, 'toBeFilled', false);
      set(form, 'filled', true);
    },

    favoriteForm() {
      this.store.createRecord('userFavoriteBlankForm', {
        userId: this.session.currentUser.id,
        blankFormId: this.form.id
      }).save();

      set(this, 'form.favorited', true);
    },

    unfavoriteForm() {
      this.store.queryRecord('userFavoriteBlankForm', {
        blank_form_id: this.form.id
      }).then(userFavoriteBlankForm => {
        userFavoriteBlankForm.destroyRecord();
      });

      set(this, 'form.favorited', false);
    },

    cancel() {
      this.toggleProperty('toBeFilled');
    }
  }
});
