import { isEmpty } from '@ember/utils';
import EmberUploader from 'ember-uploader';

export default EmberUploader.FileField.extend({
  filesDidChange(fileList) {
    if (!isEmpty(fileList)) {
      this.sendAction('onSelectFile', fileList[0]);
    }
  }
});
