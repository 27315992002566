import Model, { belongsTo, attr } from '@ember-data/model';

/** The KaseSummary model is used as a stand-in for Kase when only
 *  a limited subset of the kase data is necessary. It's id maps
 *  to the underlying kase id. */

export default class KaseSummaryModel extends Model {
  @attr('string') adjustmentsTotal;
  @attr('string') balance;
  @attr('string') cashAdvanceTotal;
  @attr('string') contractAmountTotal;
  @attr('string') customId;
  @attr('string') discountsTotal;
  @attr('string') merchandiseTotal;
  @attr('string') name;
  @attr('string') paymentsTotal;
  @attr('string') selectionTotal;
  @attr('string') serviceTotal;
  @attr('string') specialChargeTotal;
  @attr('string') subtotal;
  @attr('string') taxTotal;

  @attr('moment-date') contractDate;
  @attr('moment-datetime') lastExportedDatetime;

  @belongsTo('user', { async: true }) lastExportedPerson;
}
