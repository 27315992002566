import { hasMany, belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import { cleanPrice } from 'crakn/utils/data-helpers';
import BasePackage from 'crakn/models/base-package';

const Validations = buildValidations({
  'invoiceCategoryId.id': validator('presence', true),
});

export default class SelectionPackageModel extends BasePackage.extend(Validations) {
  @attr('boolean') exported;
  @attr('boolean') hasFlexibleItemPackageSlots;
  @attr('boolean') selectionsRequired;

  @attr('moment-datetime') createdAt;

  @attr('number', { defaultValue: 1 }) quantity;

  @attr('price') total;

  @attr('string') notes;

  @belongsTo('package', { async: true }) package;
  @belongsTo('selection', { async: true, polymorphic: true }) selection;
  @belongsTo('invoiceCategory', { async: true }) invoiceCategoryId;

  @hasMany('v2/selection-package-slot', { async: true }) selectionPackageSlots;
  @hasMany('v2/selection-package-slot-product') selectionPackageSlotProducts;

  get multipliedTotal() {
    return cleanPrice(this.quantity * this.total);
  }
}
