import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<li class=\"list__item\">\n  <div class=\"list__avatar d--xs--none d--sm--inline\">\n    <CiIcon @icon=\"file-pdf\" @size=\"md\" />\n  </div>\n\n  <div class=\"list__content\">\n    <div class=\"list__title\">{{@model.name}}</div>\n\n    {{#if @model.description}}\n      <div class=\"list__details\">{{@model.description}}</div>\n    {{/if}}\n  </div>\n\n  <div class=\"list__misc\">\n    <button\n        type=\"button\"\n        class=\"btn btn--link red qa-delete\"\n        {{on \"click\" this.toggleShowDialog}}>\n      <MdIcon @icon=\"close\"/>\n    </button>\n  </div>\n\n  <DeleteDialog @showDialog={{this.showDialog}} @onDelete={{@onDelete}} />\n</li>\n", {"contents":"<li class=\"list__item\">\n  <div class=\"list__avatar d--xs--none d--sm--inline\">\n    <CiIcon @icon=\"file-pdf\" @size=\"md\" />\n  </div>\n\n  <div class=\"list__content\">\n    <div class=\"list__title\">{{@model.name}}</div>\n\n    {{#if @model.description}}\n      <div class=\"list__details\">{{@model.description}}</div>\n    {{/if}}\n  </div>\n\n  <div class=\"list__misc\">\n    <button\n        type=\"button\"\n        class=\"btn btn--link red qa-delete\"\n        {{on \"click\" this.toggleShowDialog}}>\n      <MdIcon @icon=\"close\"/>\n    </button>\n  </div>\n\n  <DeleteDialog @showDialog={{this.showDialog}} @onDelete={{@onDelete}} />\n</li>\n","moduleName":"crakn/components/g/admin/forms/list-item.hbs","parseOptions":{"srcName":"crakn/components/g/admin/forms/list-item.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class GAdminFormsListItem extends Component {
  @tracked showDialog = false;

  @action
  toggleShowDialog() {
    this.showDialog = !this.showDialog;
  }
}
