import Quill from 'quill';
const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

export function initialize() {
  class PlainClipboard extends Clipboard {
    onPaste(e) {
      e.preventDefault();
      const range = this.quill.getSelection();
      const text = e.clipboardData.getData('text/plain');
      const delta = new Delta()
        .retain(range.index)
        .delete(range.length)
        .insert(text);
      const index = text.length + range.index;
      this.quill.updateContents(delta);
      this.quill.setSelection(index, 0);
      this.quill.scrollIntoView();
    }
  }
  Quill.register('modules/clipboard', PlainClipboard, true);
}

export default {
  name: 'quill-plain-clipboard',
  initialize
};
