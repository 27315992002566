import SearchController from 'crakn/controllers/search';
import { sort } from '@ember/object/computed';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { Changeset } from 'ember-changeset';
import config from 'crakn/config/environment';
import lookupValidator from 'ember-changeset-validations';
import Validations from 'crakn/validations/authenticated/admin/online-planner/create';

export default class AdminOnlinePlannerIndexController extends SearchController {
  @service browser;
  @service flashes;
  @service intl;
  @service router;
  @service store;

  menuPlacement = ['top', 'bottom'];
  sorting = ['name:asc'];
  @sort('model', 'sorting') sortedContent;

  @tracked addNewOnlinePlannerConfig = false;
  @tracked archived = false;
  @tracked plannerToDelete = undefined;

  @action
  createNewOnlinePlanner() {
    const planner = this.store.createRecord('v2/online-planner-config');
    this.newOnlinePlanner = Changeset(
      planner,
      lookupValidator(Validations),
      Validations
    );

    this.addNewOnlinePlannerConfig = true;
  }

  @action
  cancelNewOnlinePlanner() {
    this.addNewOnlinePlannerConfig = false;
    this.newOnlinePlanner = null;
  }

  @action
  copyUrl(prefix) {
    if (!prefix) {
      return;
    }
    const url = `${config.onlinePlannerUrl}${prefix}`
    navigator.clipboard.writeText(url);
    this.flashes.addSuccess(this.intl.t('authenticated.admin.online-planner.edit.overview.save.copySuccess'));
  }

  @action
  async archivePlanner() {
    if (!this.plannerToDelete) return;

    this.plannerToDelete.destroyRecord().then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.admin.manage.online-planner.index.newPlanner.actions.archive.success'));
      this.plannerToDelete = null;
    }).catch(() => {
      this.flashes.addError(this.intl.t('authenticated.admin.manage.online-planner.index.newPlanner.actions.archive.error'));
    });
  }

  @action
  unarchivePlanner(planner) {
    planner.set('archivedAt', null);
    planner.save().then(() => {
      this.send('refreshModel');
    }).catch(() => {
      this.flashes.addError(this.intl.t('authenticated.admin.manage.online-planner.index.newPlanner.actions.unArchive.error'));
    });
  }

  @action
  saveNewOnlinePlanner(config) {
    config
      .save()
      .then((result) => {
        this.flashes.addSuccess(
          this.intl.t(
            'authenticated.admin.manage.online-planner.index.newPlanner.actions.save.success'
          )
        );
        this.router.transitionTo(
          'authenticated.admin.online-planner.edit.overview',
          result.id
        );
      })
      .catch(() =>
        this.flashes.addError(
          this.intl.t(
            'authenticated.admin.manage.online-planner.index.newPlanner.actions.save.error'
          )
        )
      );
  }
}
