import Model, { attr, hasMany, belongsTo } from '@ember-data/model';

export default class V2ClientInfoModel extends Model {
  @attr('boolean') kaseNumberingResetsMonthly;
  @attr('boolean') kaseNumberingResetsYearly;
  @attr('boolean') locationBasedKaseNumbering;
  @attr('boolean') locationBasedLogos;
  @attr('boolean') organizationBasedLogos;

  @attr('number') kaseNumberingStartingNumber;

  @attr('string') kaseNumberingFormat;
  @attr('string') obituaryTemplate;
  @attr('string') obituaryTemplateFormatted;
  @attr('string', { defaultValue: 'Included' }) packageItemFormat;
  @attr('string', { defaultValue: 'Default' }) sfgsFontSize;
  @attr('string') terms;
  @attr('string') warranties;
  @attr('string') disclosures;
  @attr('string') acknowledgementAndAgreement;

  @belongsTo('v2/logo', { async: true }) defaultCremAuthHeader;
  @belongsTo('v2/logo', { async: true }) defaultReceiptLogo;
  @belongsTo('v2/logo', { async: true }) defaultSfgsLogo;
  @belongsTo('v2/logo', { async: true }) defaultSoaLogo;

  @hasMany('v2/logo', { async: true }) logos;

  get fontSize() {
    switch (this.sfgsFontSize) {
      case 'Larger':
        return 'large-font-size';
      case 'Automatic':
        return 'automatic-font-size';
      default:
        return 'default-font-size';
    }
  }
}
