import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class MerchandiseProfitReportComponent extends Component {
  @tracked displayProductNotes = false;
  @tracked displayProductCategory = false;
  @tracked location_id = null;
  @tracked organization_location_id = null
  @tracked startsAt = moment().subtract(30, 'day').toISOString();
  @tracked endsAt = moment().toISOString();

  get reportLink() {
    const params = new URLSearchParams({
      starts_at: this.startsAt,
      ends_at: this.endsAt,
      display_product_notes: this.displayProductNotes,
      display_product_category: this.displayProductCategory,
      location_id: this.location_id,
      organization_location_id: this.organization_location_id,
      uuid: this.args.uuid
    }).toString();
    return `/report/merchandise_profit?${params}`;
  }

  get fileUrl() {
    return `#/generated_document/${this.args.uuid}`;
  }

  @action
  setDate(attr, value) {
    set(this, attr, value);
  }
}
