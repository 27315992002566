import SelectBox from './select-box';
import countries from 'crakn/utils/countries';

export default SelectBox.extend({
  layoutName: 'components/form/select-box',
  disabled: false,
  placeholder: 'Country',
  options: countries,

  validation: null,
  showValidation: null,

  didInsertElement() {
    // This prevents the select-box's `didInsertElement` from triggering, which
    // would reset the address-field's state to null as soon as it's rendered.
  }
});
