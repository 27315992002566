import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KaseTasksInput extends Component {
  @service session;

  get enabled() {
    return this.args.filters['enabled'](this.args.task);
  }

  get disabled() {
    return this.args.filters['disabled'](this.args.task);
  }
  
  get complete() {
    return this.args.filters['complete'](this.args.task);
  }

  @action
  toggleEnabled(event) {
    this.args.task.status = event.target.checked ? 'yes' : null;
    this.save();
  }

  @action  
  toggleDisabled(event) {
    this.args.task.status = event.target.checked ? 'no' : null;
    this.save();
    
  }
  
  @action
  toggleComplete(event) {
    this.args.task.complete = event.target.checked ? true : false;
    this.args.task.initials = event.target.checked ? this.session.currentUser.initials : null;
    this.save()
  }
  
  @action
  save() {
     this.args.task.save();
  }
}
