import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { preventFormDefault } from 'ember-cli-prevent-form-default';
import moment from 'moment';
import dispositionMethods from 'crakn/utils/disposition-methods';

export default class KasesManageEditDispositionController extends Controller {
  @service clientRouting;
  @service flashes;
  @service router;
  @service store;
  @tracked pendingAuthorizedPickupPerson;

  get dispositionMethods() {
    return dispositionMethods;
  }

  get isCremation() {
    return this.model.dispositionMethod === 'Cremation';
  }
  
  get isDisinterment() {
    return this.model.dispositionMethod === 'Disinterment';
  }

  get excludedIds() {
    const excludedPickupPerson = this.kase.authorizedForPickup.map((x) => x.person.get('id'));
    excludedPickupPerson.push(this.kase.deceased.get('id'));
    return excludedPickupPerson;
  }

  handleSaveSuccess() {
    this.flashes.addSuccess('Disposition information updated successfully!');

    // TODO: Remove once this is migrated to v2
    // this model uses disposition and the kase uses a v2 disposition, so we
    // have to refresh the v2 for ember to pick the changes in order to show
    // the cremation auth navigation without have to refresh the whole page.
    this.store.findRecord('v2/disposition', this.model.id);

    const route = this.clientRouting.manageRouteTransition(
      'authenticated.kases.manage.edit.disposition'
    );
    this.router.transitionTo(route);
  }

  handleCreateAuthPickupSuccess() {
    this.flashes.addSuccess(
      'Congrats! A new authorized pickup person was added.'
    );
  }

  handleCreateAuthPickupError() {
    this.flashes.addError('Oops! There was an error adding this person.');
  }

  handleRemoveAuthPickupSuccess() {
    this.flashes.addSuccess(
      'This person was successfully removed as an authorized pickup person.'
    );
  }

  handleRemoveAuthPickupError() {
    this.flashes.addError('Oops! There was an error removing this person.');
  }

  createAuthorizedForPickup(person, relationship) {
    const role = 'authorized_for_pickup';

    this.store
      .createRecord('kasePerson', {
        kase: this.kase,
        role,
        relationship,
        person,
      })
      .save()
      .then(() => {
        this.handleCreateAuthPickupSuccess();
      })
      .catch(() => {
        this.handleCreateAuthPickupError();
      });
  }

  @action
  @preventFormDefault
  saveDisposition() {
    this.model.save().then(() => {
      this.handleSaveSuccess();
      this.send('refreshModel');
    });
  }

  @action
  addAuthorizedPickupPerson(person) {
    if (!person || (person && person.isNew)) {
      return (this.pendingAuthorizedPickupPerson = person);
    }
    return this.createAuthorizedForPickup(person);
  }

  @action
  removeAuthorizedPickupPerson(authorizedForPickup) {
    authorizedForPickup
      .destroyRecord()
      .then(() => {
        this.handleRemoveAuthPickupSuccess();
      })
      .catch(() => {
        this.handleRemoveAuthPickupError();
      });
  }

  @action
  saveNewAuthorizedPickupPerson() {
    return this.pendingAuthorizedPickupPerson.save().then((result) => {
      this.pendingAuthorizedPickupPerson = null;
      this.createAuthorizedForPickup(result);
    });
  }

  @action
  setDate(attr, value) {
    this.model.set(attr, moment(value));
  }

  @action
  setPlace(place) {
    this.store.findRecord('place', place.id).then(v1Place => {
      this.model.set('place', v1Place);
    });
  }

  @action
  removePlace() {
    this.model.set('place', null);
  }

  @action
  setCremationPlace(place) {
    this.store.findRecord('place', place.id).then(v1Place => {
      this.model.set('cremationPlace', v1Place);
    });
  }

  @action
  removeCremationPlace() {
    this.model.set('cremationPlace', null);
  }

  @action
  saveGraveOwner(contactType) {
    this.model
      .get(contactType)
      .then(contact => {
        contact.save().then(() => {
          this.model.save();
        });
      });
  }

  @action
  setUser(prop, id) {
    if (!id || id === '') {
      this.set(prop, null);
    } else {
      this.store.findRecord('user', id).then(user => {
        this.set(prop, user);
      });
    }
  }

  @action
  setDispositionMethod(method) {
    if (method === 'Select One') {
      this.model.set('dispositionMethod', null);
    } else {
      this.model.set('dispositionMethod', method);
    }
  }
}
