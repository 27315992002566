import Transform from '@ember-data/serializer/transform';
import moment from 'moment';

export default class MomentDateTransform extends Transform {
  deserialize(serialized) {
    if (!serialized) {
      return null;
    }

    const m = moment(serialized);
    return m.isValid() ? m : null;
  }

  serialize(deserialized) {
    if (!deserialized) {
      return null;
    }

    const m = moment(deserialized);
    return m.isValid() ? m.format('YYYY-MM-DD') : null;
  }
}
