import Service, { inject as service } from '@ember/service';

export default class ApiJsonService extends Service {
  @service api

  get headers() {
    return { 'Content-Type': 'application/json' };
  }

  async request(method, url, { params, body } = {}) {
    const headers = {
      ...this.headers,
      ...this.api.headers
    }

    return fetch(
      this.api.url(url, params),
      {
        method: method,
        credentials: 'include',
        headers: headers,
        body: body ? JSON.stringify(body) : null
      }
    ).then(async (response) => {
      const text = await response.text();

      response.parsedJson = text ? JSON.parse(text) : {};

      return response;
    });
  }

  async get(url, options = {}) {
    return this.request('GET', url, options);
  }

  async post(url, options = {}) {
    return this.request('POST', url, options);
  }

  async put(url, options = {}) {
    return this.request('PUT', url, options);
  }

  async delete(url, options = {}) {
    return this.request('DELETE', url, options);
  }
}