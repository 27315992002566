import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';
import { tracked } from '@glimmer/tracking';

const Validations = buildValidations({
  'model.name': validator('presence', true),
});

export default class AdminOrganizationsEditController extends Controller.extend(Validations) {
  @service intl;
  @service flashes;
  @service store;

  @tracked newLocation = null;
  @tracked showValidations = false;

  @action
  handleError(message = (this.intl.t('authenticated.admin.manage.organizations.edit.buttons.errorMessage'))) {
    this.flashes.addError(message);
  }

  @action
  handleSuccess() {
    this.flashes.addSuccess(this.intl.t('authenticated.admin.manage.organizations.edit.buttons.successMessage'));
  }

  @action
  setContactPerson(id) {
    if (!id) {
      set(this.model, 'contactPerson', null);
    }

    set(this.model, 'contactPerson', this.store.peekRecord('user', id));
  }

  @action
  save() {
    this.validate().then(() => {
      if (this.validations.isValid) {
        this.showValidations = false;

        this.model
          .save()
          .then(() => {
            this.handleSuccess();
            this.transitionToRoute('authenticated.admin.manage.organizations');
          })
          .catch(() => {
            this.handleError();
          });
      } else {
        this.showValidations = true;
      }
    });
  }

  @action
  setLocation(locationId) {
    if (locationId) {
      this.newLocation = this.store.peekRecord('location', locationId);
    } else {
      return (this.newLocation = null);
    }
  }

  @action
  async addLocation() {
    const usedLocations = await Promise.all(
      this.model.get('organizationLocations').map((org) => org.location)
    );

    if (usedLocations.includes(this.newLocation)) {
      return this.handleError(this.intl.t('authenticated.admin.manage.organizations.edit.assignOrganizationToLocation.addLocation.errorMessage'));
    } else {
      this.store
        .createRecord('organizationLocation', {
          organization: this.model,
          location: this.newLocation,
        })
        .save()
        .then(this.handleSuccess)
        .catch(this.handleError);
    }
  }

  @action
  removeLocation(locationOrganizations) {
    locationOrganizations
      .destroyRecord()
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  @action
  rollbackModelAttributes(){
    if (this.model.hasDirtyAttributes) {
      this.model.rollbackAttributes();
    }
  }

  @action
  cancelEdit() {
    this.rollbackModelAttributes();
    this.transitionToRoute('authenticated.admin.manage.organizations');
  }
}
