import Controller from '@ember/controller';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { preventFormDefault } from 'ember-cli-prevent-form-default';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';


export default class KasesManageEditPreparationsController extends Controller {
  @service api;
  @service clientRouting;
  @service flashes;
  @service router;
  @service store;
  @tracked newEmbalmReason;
  @alias('kase.declineEmbalming') embalmingStatus;
  kase = null;
  newKaseStaffMember = null;
  newKaseStaffMemberId = null;
  newEmbalmingStaffMember = null;
  newEmbalmingStaffMemberId = null;

  identificationNatureOptions =[
    'Hospital',
    'Nursing Home',
    'Medical Examiner or Coroner\'s Office',
    'Other'
  ];

  identificationMethodOptions = [
    'Viewed in person',
    'Photograph',
    'Identifying mark, scar, or tattoo (Specify)'
  ];

  pacemakerOptions = [
    { value: 'unknown', text: 'Unknown'},
    { value: 'no', text: 'No' },
    { value: 'yes', text: 'Yes'},
    { value: 'yes_removed', text: 'Yes - Removed'},
    { value: 'yes_not_removed', text: 'Yes - Not Removed'}
  ];

  photographyReasonOptions = ['Identification', 'Other'];
  photographySubjectOptions = ['Entire remains', 'Only face and head', 'Other'];
  fingerprintReasonOptions = ['Memorial', 'Identification', 'Other'];


  get casketingAndCosmeticsSecondaryStaff() {
    return this.kase.casketingAndCosmeticsSecondaryStaff;
  }

  get embalmingSecondaryStaff() {
    return this.kase.embalmingSecondaryStaff;
  }

  handleSaveSuccess() {
    this.flashes.addSuccess('Preparations Saved!');
    const route = this.clientRouting.manageRouteTransition(
      'authenticated.kases.manage.edit.preparations'
    );
    this.router.transitionTo(route);
  }

  requestAddRemoveCasketingSecondaryUser(method, userId) {
    this.api.json
        .request(
          method,
          'kase_staff_members', {
            body: {
              kase_staff_member: {
                user_id: userId,
                kase_id: this.kase.id,
                role: 'casketing_and_cosmetics_secondary_staff'
              }
            }
        });
  }

  requestAddRemoveEmbalmingSecondaryUser(method, userId) {
    this.api.json
        .request(
          method,
          'kase_staff_members', {
            body: {
              kase_staff_member: {
                user_id: userId,
                kase_id: this.kase.id,
                role: 'embalmer_secondary_staff'
              }
            }
        });
  }

  createStaffMember() {
    this.setProperties({
      newKaseStaffMember: this.store.createRecord('kaseStaffMember'),
      newKaseStaffMemberId: null
    });
  }

  createEmbalmingStaffMember() {
    this.setProperties({
      newEmbalmingStaffMember: this.store.createRecord('kaseStaffMember'),
      newEmbalmingStaffMemberId: null
    });
  }

  @action
  setDate(attr, value) {
    this.kase.set(attr, moment(value));
  }

  @action
  searchEmbalmReason(query) {
    return this.store
                .query('embalmReason', {
                  query: query
                }).then((results) => {
                  return results.map((reason) => reason.name);
                });
  }

  // Update setIdentificationPlace action once V2 place model is complete
  @action
  setIdentificationPlace(v2Place) {
    this.store.findRecord('place', v2Place.id).then(v1Place => {
      this.model.set('identificationPlace', v1Place);
    });
  }

  @action
  removeIdentificationPlace() {
    this.model.set('identificationPlace', null);
  }

  @action
  setEmbalmingPlace(place) {
    this.store.findRecord('place', place.id).then(v1Place => {
      this.model.set('embalmingPlace', v1Place);
    });
  }

  @action
  removeEmbalmingPlace() {
    this.model.set('embalmingPlace', null);
  }

  @action
  @preventFormDefault
  savePreparations() {
    this.kase.save().then(() => {
      this.handleSaveSuccess();
    });
  }

  @action
  removeStaffMember(user) {
    this.requestAddRemoveCasketingSecondaryUser('DELETE', user.id);
    this.kase.casketingAndCosmeticsSecondaryStaff.removeObject(user);
  }

  @action
  updatePrimaryMember(userId) {
    const secondaryStaff = this.kase.casketingAndCosmeticsSecondaryStaff;
    if (secondaryStaff.findBy('id', userId)) {
      this.requestAddRemoveCasketingSecondaryUser('DELETE', userId);
      this.store.find('user', userId).then(function(user) {
        secondaryStaff.removeObject(user);
      });
    }
  }

  @action
  assignStaffMember(kaseStaffMember) {
    const userId = kaseStaffMember.user.get('id')

    if (!userId) {
      this.flashes.addError('Looks like you forgot to select a staff member');
      return;
    }

    const staffAssigned = this.kase.casketingAndCosmeticsSecondaryStaff.findBy('id', userId) || this.kase.casketingAndCosmeticsStaff.get('id')=== userId;
    if (staffAssigned) {
      this.createStaffMember();
      this.flashes.addError('Whoops! That staff member is already assigned to this case.');
      return;
    }

    this.requestAddRemoveCasketingSecondaryUser('POST', userId);
    const staff = this.kase.casketingAndCosmeticsSecondaryStaff;

    this.store.find('user', userId).then((user) => {
      staff.pushObject(user);
    });

    this.createStaffMember();
  }

  @action
  removeEmbalmingStaffMember(user) {
    this.requestAddRemoveEmbalmingSecondaryUser('DELETE', user.id);
    this.kase.embalmingSecondaryStaff.removeObject(user);
  }

  @action
  updateEmbalmingPrimaryMember(userId) {
    const secondaryStaff = this.kase.embalmingSecondaryStaff;
    if (secondaryStaff.findBy('id', userId)) {
      this.requestAddRemoveEmbalmingSecondaryUser('DELETE', userId);
      this.store.find('user', userId).then(function(user) {
        secondaryStaff.removeObject(user);
      });
    }
  }

  @action
  assignEmbalmingStaffMember(kaseStaffMember) {
    const userId = kaseStaffMember.user.get('id')

    if (!userId) {
      this.flashes.addError('Looks like you forgot to select a staff member');
      return;
    }

    const staffAssigned = this.kase.embalmingSecondaryStaff.findBy('id', userId) || this.kase.embalmer.get('id')=== userId;
    if (staffAssigned) {
      this.createEmbalmingStaffMember();
      this.flashes.addError('Whoops! That staff member is already assigned to this case.');
      return;
    }

    this.requestAddRemoveEmbalmingSecondaryUser('POST', userId);
    const staff = this.kase.embalmingSecondaryStaff;

    this.store.find('user', userId).then((user) => {
      staff.pushObject(user);
    });

    this.createEmbalmingStaffMember();
  }

  @action
  embalmingCheck(value) {
    switch (value) {
      case 'toggleDecline':
        this.toggleProperty('kase.declineEmbalming');
        if (
          this.kase.declineEmbalming === true &&
          this.kase.hasPermissionToEmbalm === true
        ) {
          this.kase.hasPermissionToEmbalm = false;
        }
        break;

      case 'togglePermission':
        this.toggleProperty('kase.hasPermissionToEmbalm');
        if (
          this.kase.hasPermissionToEmbalm === true &&
          this.kase.declineEmbalming === true
        ) {
          this.kase.declineEmbalming = false;
        }
        break;
    }
  }

  @action
  saveContact(contactType) {
    this.kase
      .get(contactType)
      .then(contact => {
        contact.save().then(() => {
          this.kase.save();
        });
      });
  }

  // Update setBodyDonationPlace action once V2 place model is complete
  @action
  setBodyDonationPlace(v2Place) {
    this.store.findRecord('place', v2Place.id).then(v1Place => {
      this.model.set('bodyDonationPlace', v1Place);
    })
  }

  @action
  removeBodyDonationPlace() {
    this.model.set('bodyDonationPlace', null);
  }
}
