import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';

export default class AuthenticatedEsignaturesRoute extends SearchRoute {
  @service session;

  routeRegex = /authenticated.esignatures/;

  queryParams = {
    has_pending_esignatures: {
      refreshModel: true
    },
    kase_query: {
      refreshModel: true
    },
    signer_query: {
      refreshModel: true
    },
    location_id: {
      refreshModel: true
    }
  };

  model(params) {
    return this.store.query('v2/tfe-kase', {
      client_id: this.session.currentClient.id,
      has_pending_esignatures: true,
      kase_query: params.kase_query || null,
      signer_query: params.signer_query || null,
      location_id: params.location_id || null,
      include: 'deceased,deceased.profile,location,location.address'
    })
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.clearQueryParamValues();
    }
  }
}
