import OneWayInputComponent from 'ember-one-way-controls/components/one-way-input';

export default OneWayInputComponent.extend({
  type: 'number',
  maxlength: 12,

  decimalPlaces: 2,
  allowedKeys: [9, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 110, 190],

  keyPress(evt) {
    const value = this.value;
    const keyPressArray = this.allowedKeys;
    const key = evt.which;

    // If backspace, arrow, ctrl, or command keys are pressed let the commands through
    if ([0, 8].includes(key) || evt.metaKey || evt.ctrlKey) {
      return;
    }

    if (value) {
      const [dollars, cents] = String(value).split('.');
      const cursorPos = evt.target.selectionStart;

      // If the cursor position is within the dollar zone
      if (cursorPos <= dollars.length) {
        // Make sure dollars is not more than nine digits
        if (dollars.length >= 9 && key !== 46) {
          evt.preventDefault();
        }

        // Or cent zone
      } else {
        const decimalPlaces = this.decimalPlaces;

        // Make sure cents is no greater than two decimal points
        if (cents.length >= decimalPlaces) {
          evt.preventDefault();
        }
      }
    }

    if (!keyPressArray.includes(key)) {
      evt.preventDefault();
    }
  }
});
