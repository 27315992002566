import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2InterestModel extends Model {
  @attr('number') amount;
  @attr('moment-datetime') createdAt;
  @attr('string') netsuiteId;
  @attr('string') note;
  @attr('moment-datetime') updatedAt;

  @belongsTo('v2/user') enteredBy;
  @belongsTo('v2/interestType') interestType;
  @belongsTo('v2/payment') payment;
  @belongsTo('v2/selection') selection;
}
