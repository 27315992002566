const usMilitaryHeadstoneEmblems = [
  {
    label: 'Aaronic Order Church',
    value: 'Aaronic Order Church'
  },
  {
    label: 'African Ancestral Traditionalist (Nyame Ye Ohene)',
    value: 'African Ancestral Traditionalist (Nyame Ye Ohene)'
  },
  {
    label: 'African Methodist Episcopal',
    value: 'African Methodist Episcopal'
  },
  {
    label: 'Armenian Cross',
    value: 'Armenian Cross'
  },
  {
    label: 'Atheist',
    value: 'Atheist'
  },
  {
    label: 'Bahai (9-Pointed Star)',
    value: 'Bahai (9-Pointed Star)'
  },
  {
    label: 'Buddhist (Wheel of Righteousness)',
    value: 'Buddhist (Wheel of Righteousness)'
  },
  {
    label: 'Catholic Celtic Cross',
    value: 'Catholic Celtic Cross'
  },
  {
    label: 'Celtic Cross',
    value: 'Celtic Cross'
  },
  {
    value: 'Christian & Missionary Alliance',
    label: 'Christian & Missionary Alliance'
  },
  {
    value: 'Christian Church',
    label: 'Christian Church'
  },
  {
    label: 'Christian Reformed Church',
    value: 'Christian Reformed Church'
  },
  {
    label: 'Church of God',
    value: 'Church of God'
  },
  {
    label: 'Church of Nazarene',
    value: 'Church of Nazarene'
  },
  {
    label: 'Community of Christ',
    value: 'Community of Christ'
  },
  {
    label: 'Dove of Peace',
    value: 'Dove of Peace'
  },
  {
    label: 'Druid (Awen)',
    value: 'Druid (Awen)'
  },
  {
    label: 'Druze',
    value: 'Druze'
  },
  {
    label: 'Eckankar',
    value: 'Eckankar'
  },
  {
    label: 'Episcopal Cross',
    value: 'Episcopal Cross'
  },
  {
    label: 'Evangelical Lutheran Church',
    value: 'Evangelical Lutheran Church'
  },
  {
    label: 'Faith and Prayer',
    value: 'Faith and Prayer'
  },
  {
    label: 'Farohar',
    value: 'Farohar'
  },
  {
    label: 'First Church of Christ, Scientist (Cross & Crown)',
    value: 'First Church of Christ, Scientist (Cross & Crown)'
  },
  {
    label: 'Four Directions',
    value: 'Four Directions'
  },
  {
    label: 'Greek Cross',
    value: 'Greek Cross'
  },
  {
    label: 'Guardian Angel',
    value: 'Guardian Angel'
  },
  {
    label: 'Hammer of Thor',
    value: 'Hammer of Thor'
  },
  {
    label: 'Heart',
    value: 'Heart'
  },
  {
    label: 'Hebrew (Star of David)',
    value: 'Hebrew (Star of David)'
  },
  {
    label: 'Hindu',
    value: 'Hindu'
  },
  {
    label: 'Humanist Emblem of Spirit',
    value: 'Humanist Emblem of Spirit'
  },
  {
    label: 'Ichthys',
    value: 'Ichthys'
  },
  {
    label: 'Infinity',
    value: 'Infinity'
  },
  {
    label: 'Izumo Taishakyo Mission of Hawaii',
    value: 'Izumo Taishakyo Mission of Hawaii'
  },
  {
    label: 'Kingian Faith',
    value: 'Kingian Faith'
  },
  {
    label: 'Konko-Kyo Faith',
    value: 'Konko-Kyo Faith'
  },
  {
    label: 'Kohen Hands',
    value: 'Kohen Hands'
  },
  {
    label: 'Landing Eagle',
    value: 'Landing Eagle'
  },
  {
    label: 'Latin (Christian) Cross',
    value: 'Latin (Christian) Cross'
  },
  {
    label: 'Luther Rose',
    value: 'Luther Rose'
  },
  {
    label: 'Lutheran Church Missouri Synod',
    value: 'Lutheran Church Missouri Synod'
  },
  {
    label: 'Lutheran Cross',
    value: 'Lutheran Cross'
  },
  {
    label: 'Maltese Cross',
    value: 'Maltese Cross'
  },
  {
    label: 'Medicine Wheel',
    value: 'Medicine Wheel'
  },
  {
    label: 'Messianic Jewish',
    value: 'Messianic Jewish'
  },
  {
    label: 'Messianic',
    value: 'Messianic'
  },
  {
    label: 'Mormon (Angel Moroni)',
    value: 'Mormon (Angel Moroni)'
  },
  {
    label: 'Muslim (Crescent and Star)',
    value: 'Muslim (Crescent and Star)'
  },
  {
    label: 'Native American Church of North America',
    value: 'Native American Church of North America'
  },
  {
    label: 'New Apostolic',
    value: 'New Apostolic'
  },
  {
    label: 'Nichiren Shoshu Temple',
    value: 'Nichiren Shoshu Temple'
  },
  {
    label: 'Polish National Catholic Church',
    value: 'Polish National Catholic Church'
  },
  {
    label: 'Pomegranate',
    value: 'Pomegranate'
  },
  {
    label: 'Presbyterian Church (USA)',
    value: 'Presbyterian Church (USA)'
  },
  {
    label: 'Presbyterian Cross',
    value: 'Presbyterian Cross'
  },
  {
    label: 'Russian Orthodox Cross',
    value: 'Russian Orthodox Cross'
  },
  {
    label: 'Sacred Heart',
    value: 'Sacred Heart'
  },
  {
    label: 'Sandhill Crane',
    value: 'Sandhill Crane'
  },
  {
    label: 'Seicho-No-Ie',
    value: 'Seicho-No-Ie'
  },
  {
    label: 'Serbian Orthodox',
    value: 'Serbian Orthodox'
  },
  {
    label: 'Seventh Day Adventist Church',
    value: 'Seventh Day Adventist Church'
  },
  {
    label: 'Shepherd and Flag',
    value: 'Shepherd and Flag'
  },
  {
    label: 'Shinto',
    value: 'Shinto'
  },
  {
    label: 'Sikh (Khanda)',
    value: 'Sikh (Khanda)'
  },
  {
    label: 'Soka Gakkai International (USA)',
    value: 'Soka Gakkai International (USA)'
  },
  {
    label: 'Sufism Reoriented',
    value: 'Sufism Reoriented'
  },
  {
    label: 'Tenrikyo Church',
    value: 'Tenrikyo Church'
  },
  {
    label: 'The Church of World Messianity',
    value: 'The Church of World Messianity'
  },
  {
    label: 'Unification Church',
    value: 'Unification Church'
  },
  {
    label: 'Unitarian Church (Flaming Chalice)',
    value: 'Unitarian Church (Flaming Chalice)'
  },
  {
    label: 'United Church of Christ',
    value: 'United Church of Christ'
  },
  {
    label: 'United Church of Religious Science',
    value: 'United Church of Religious Science'
  },
  {
    label: 'United Methodist Church',
    value: 'United Methodist Church'
  },
  {
    label: 'United Moravian Church',
    value: 'United Moravian Church'
  },
  {
    label: 'Universalist Cross',
    value: 'Universalist Cross'
  },
  {
    label: 'Wicca (Pentacle)',
    value: 'Wicca (Pentacle)'
  },
  {
    label: 'Wisconsin Evangelical Lutheran Synod',
    value: 'Wisconsin Evangelical Lutheran Synod'
  }
];

export { usMilitaryHeadstoneEmblems };
