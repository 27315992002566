import { bool } from '@ember/object/computed';
import { bind } from '@ember/runloop';
import Controller from '@ember/controller';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default Controller.extend({
  queryParams: ['page'],

  kase: null,
  names: service(),
  clientRouting: service(),
  session: service(),
  isEditMode: bool('currentlyEditingContact'),
  showValidation: false,
  page: 1,

  isPrimaryAndNokSame: computed('kase.{primaryContact,nextOfKin}', {
    get() {
      return (
        get(this, 'kase.primaryContact.id') === get(this, 'kase.nextOfKin.id')
      );
    }
  }),

  currentlyEditingContact: null,

  otherFamilyAndFriends: computed('kase.sortedKaseContacts.[]', {
    get() {
      return get(this, 'kase.sortedKaseContacts').filter(item => {
        return !(get(item, 'isPrimaryContact') || get(item, 'isNextOfKin'));
      });
    }
  }),

  _teardownContactEdit(rollbackChanges = false) {
    if (rollbackChanges) {
      const person = get(this, 'currentlyEditingContact.person');
      if (person) {
        if (get(person, 'isNew')) {
          person.deleteRecord();
        } else {
          person.rollbackAttributes();
        }
      }

      const contact = this.currentlyEditingContact;
      if (contact) {
        if (get(contact, 'isNew')) {
          contact.deleteRecord();
        } else {
          contact.rollbackAttributes();
        }
      }
    }
    set(this, 'currentlyEditingContact', null);
  },

  handleSaveContactSuccess(response) {
    this._teardownContactEdit();
    return response;
  },

  handleSaveContactError(error) {
    this.send('flashError', 'Family or friend could not be saved');
    set(this, 'currentlyEditingContact.kase', null);
    return error;
  },

  ensureDeceased() {
    if (!get(this, 'kase.deceased.content')) {
      this.store
        .createRecord('deceased', {
          kase: this.kase,
          firstName: 'Unknown'
        })
        .save();
    }
  },

  actions: {
    setPersonBirthDate(value) {
      this.currentlyEditingContact.personBirthDate = moment(value);
    },

    searchPerson(value) {
      return this.store
            .query('person', {
              query: value
            });
    },

    searchRelationship(value) {
      return this.store
            .query('relationship', {
              query: value,
              archived: false
            })
            .then((results) => {
              return results.map((rel) => rel.name)
            });
    },

    save() {
      const route = this.clientRouting.manageRouteTransition(
        'authenticated.kases.manage.edit.family-and-friends'
      );
      this.transitionToRoute(route);
    },

    addContact(contact) {
      this.ensureDeceased();

      const splitNames = this.names.splitNameIntoFirstAndLastName(contact);
      const kaseContact = this.store.createRecord('kaseContact');

      kaseContact.selectPerson(splitNames);
      set(this, 'currentlyEditingContact', kaseContact);
    },

    selectContact(contact) {
      this.ensureDeceased();

      if (contact === null) {
        return set(this, 'currentlyEditingContact', null);
      }

      const kaseContact = this.store.createRecord('kaseContact');

      kaseContact.selectPerson(contact);
      set(this, 'currentlyEditingContact', kaseContact);

      this.store
        .query('relative', {
          between: `${contact.id},${get(this, 'kase.deceased.id')}`
        })
        .then(result => {
          if (get(result, 'length') > 0) {
            set(
              this,
              'currentlyEditingContact.relationshipName',
              get(result, 'firstObject.relationshipName')
            );
          }
        });
    },

    saveContact() {
      const currentlyEditingContact = this.currentlyEditingContact;
      const defaultCountry = this.session.currentClient.operating_country || 'US';

      currentlyEditingContact.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          set(currentlyEditingContact, 'kase', this.kase);
          set(currentlyEditingContact, 'country', defaultCountry);
          currentlyEditingContact.save()
                      .then(
                        bind(this, 'handleSaveContactSuccess'),
                        bind(this, 'handleSaveContactError')
                      );
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    cancelSaveContact() {
      this._teardownContactEdit(true);
    },

    removeContact(kaseContact) {
      kaseContact.destroyRecord();
    },

    removeNextOfKin() {
      const kase = this.kase;
      set(kase, 'nextOfKin', null);
      kase.save();
    },

    removePrimaryContact() {
      const kase = this.kase;
      set(kase, 'primaryContact', null);
      kase.save();
    },

    removePrimaryAndNok() {
      const kase = this.kase;
      set(kase, 'nextOfKin', null);
      set(kase, 'primaryContact', null);
      kase.save();
    },

    makePrimaryContact(kaseContact) {
      const kase = this.kase;
      set(kase, 'primaryContact', kaseContact);
      kase.save();
    },

    makeNextOfKin(kaseContact) {
      const kase = this.kase;
      set(kase, 'nextOfKin', kaseContact);
      kase.save();
    }
  }
});
