import { sort, not, reads } from '@ember/object/computed';
import Controller from '@ember/controller';
import { validator, buildValidations } from 'ember-cp-validations';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';

const Validations = buildValidations({
  'newPayment.paymentType.id': validator('presence', true),
  'newPayment.amount': [
    validator('presence', {
      presence: true
    }),
    validator('number', {
      allowString: true,
      gt: 0,
      message: 'Must be greater than zero',
      positive: true,
      disabled: reads('model.newPayment.paymentType.isTributePay')
    }),
    validator('number', {
      allowString: true,
      gte: 0.50,
      message: 'A payment cannot be less than $0.50.',
      positive: true,
      disabled: not('model.newPayment.paymentType.isTributePay')
    })
  ],
  'newPayment.createdAt': validator('presence', true),
  'newPayment.collectedAt': validator('presence', {
    presence: true,
    message: 'Date can\'t be empty or invalid'
  }),
  'newPayment.payer': validator('presence', {
    presence: true,
    disabled: not('model.altPayer')
  }),
  'newPayment.person': validator('presence', {
    presence: true,
    disabled: reads('model.altPayer')
  })
});

export default Controller.extend(Validations, {
  flashes: service(),
  intl: service(),
  session: service(),
  staff: service(),
  queryParams: ['token'],
  token: null,
  usePurchaserInfo: false,
  altPayer: false,

  showValidation: false,

  disablePayment: computed('model.card.cc_number', function () {
    if (get(this, 'model.card.cc_number')) {
      const ccNumber = get(this, 'model.card.cc_number').replace(/\s/g, '');
      return ccNumber.length >= 12 ? false : true;
    } else {
      return true;
    }
  }),

  sortedPayments: sort('selection.payments', function (a, b) {
    const collectedDiff = b.get('collectedAt') - a.get('collectedAt');
    const enteredDiff = b.get('createdAt') - a.get('createdAt');
    return collectedDiff || enteredDiff;
  }),

  actions: {
    validatePayment(newPayment, billing) {
      if (dateInCoaLockedPeriod(this.selection, this.session, newPayment.get('collectedAt'))) {
        this.flashes.addError(this.intl.t('authenticated.kases.manage.financials.payments.edit.errors.postingPeriodAdd'));
        return;
      }
      
      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          if (get(this, 'paymentSteps.isProcessingCreditCard')) {
            this.send('authorizePayment', newPayment, billing);
          } else {
            this.send('saveNewPayment', newPayment);
          }
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    removeContact() {
      set(this, 'newPayment.person', null);
    },

    togglePayer(value) {
      this.toggleProperty('altPayer');
      if(!value) {
        this.newPayment.set("payer", undefined);
      }
    },

    setCollectedBy(selection) {
      const staffMember = get(this, 'staff.active').findBy('id', selection);
      set(this, 'newPayment.collectedBy', staffMember);
    },

    setPurchaserOnCreditCard(value, purchaser) {
      set(this, 'usePurchaserInfo', !value);
      const billingInfo = this.billing;
      const creditCardInfo = this.card;
      if (this.usePurchaserInfo) {
        if (purchaser !== null) {
          purchaser.then(person => {
            const firstName = get(person, 'firstName');
            const lastName = get(person, 'lastName');
            const firstLast = [firstName, lastName].filter(Boolean).join(' ');

            set(billingInfo, 'first_name', firstName);
            set(billingInfo, 'last_name', lastName);
            set(billingInfo, 'address1', get(person, 'addressLine1'));
            set(billingInfo, 'address2', get(person, 'addressLine2'));
            set(billingInfo, 'city', get(person, 'city'));
            set(billingInfo, 'state', get(person, 'state'));
            set(billingInfo, 'postal', get(person, 'zipCode'));
            set(billingInfo, 'country', get(person, 'country'));
            set(creditCardInfo, 'cc_name', firstLast);
            const event = document.createEvent('HTMLEvents');
            setTimeout(function () {
              event.initEvent('change', false, true);
              document
                .getElementsByClassName('qa-name-on-credit-card')[0]
                .dispatchEvent(event);
            }, 500);
          });
        }
      } else {
        set(billingInfo, 'first_name', '');
        set(billingInfo, 'last_name', '');
        set(billingInfo, 'address1', '');
        set(billingInfo, 'address2', '');
        set(billingInfo, 'city', '');
        set(billingInfo, 'state', '');
        set(billingInfo, 'postal', '');
        set(billingInfo, 'country', '');
        set(creditCardInfo, 'cc_name', '');
      }
    }
  }
});
