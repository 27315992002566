import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: 'li',
  classNames: ['list__item'],
  classNameBindings: ['qaClass'],

  qaClass: computed('clientName', function() {
    return `qa-client-list-${this.clientName}`;
  }),

  showDialog: false,

  actions: {
    removeClient() {
      this.attrs.removeClient(this.clientName);
    }
  }
});
