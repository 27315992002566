import Transform from '@ember-data/serializer/transform';

import { cleanPrice } from 'crakn/utils/data-helpers';

export default Transform.extend({
  deserialize(serialized) {
    serialized = serialized === null ? 0 : serialized;
    return serialized ? cleanPrice(serialized) : serialized;
  },

  serialize(deserialized) {
    if (deserialized === undefined) {
      return null;
    }

    return deserialized ? cleanPrice(deserialized) : deserialized;
  }
});
