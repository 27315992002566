import { computed } from '@ember/object';
import Component from '@glimmer/component';
import moment from 'moment';

export default class VaMonthlyVital extends Component {
  timeFrame = moment();
  locationId = null;

  @computed('args.uuid', 'timeFrame', 'locationId')
  get reportLink() {
    const params = new URLSearchParams({
      month: this.timeFrame.month() + 1,
      year: this.timeFrame.year(),
      location_id: this.locationId,
      uuid: this.args.uuid,
      report_type: 'va_monthly_vital'
    }).toString();

    return `/reports?${params}`;
  }

  @computed('args.uuid')
  get fileUrl() {
    return `#/generated_document/${this.args.uuid}`;
  }
}
