import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { preventFormDefault } from 'ember-cli-prevent-form-default';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class KasesEditServiceInfoController extends Controller {
  @service api;
  @service clientRouting;
  @service flashes;
  @service intl;
  @service router;

  @tracked addingContribution = false;

  get excludeIds() {
    const pallbearers = this.kase.pallbearers
      .map((x) => x.person.get('id'))
      .concat(this.kase.honoraryPallbearers.map((x) => x.person.get('id')));
    const excluded = pallbearers;
    excluded.push(this.kase.deceased.get('id'));
    return excluded;
  }

  get excludedMemorialContributionPlaceIds() {
    return this.model.memorialContributions.map((contribution) => {
      return contribution.get('place.id');
    });
  }

  createPallbearer(person, isHonorary) {
    const role = isHonorary ? 'honorary_pallbearer' : 'pallbearer';

    this.store
      .createRecord('kasePerson', {
        kase: this.kase,
        role,
        person
      })
      .save()
      .then(() => {
        this.model.save();
        this.kase.reload();
      });
  }

  savePallbearer(isHonorary) {
    const pendingName = isHonorary
      ? 'pendingHonoraryPallbearer'
      : 'pendingPallbearer';

    return this.get(pendingName)
      .save()
      .then((result) => {
        this.set(pendingName, null);

        return this.createPallbearer(result, isHonorary);
      });
  }

  selectPallbearer(person, isHonorary) {
    const pendingName = isHonorary
      ? 'pendingHonoraryPallbearer'
      : 'pendingPallbearer';

    if (person === null || (person && person.isNew)) {
      return this.set(pendingName, person);
    }

    this.createPallbearer(person, isHonorary);
  }

  @action
  setDatePhotosReturned(value) {
    this.model.datePhotosReturned = moment(value);
  }

  @action
  addNewContribution() {
    this.store.createRecord('v2/memorialContribution', {
      serviceInfo: this.model,
      kase: this.v2Kase
    });
    this.addingContribution = true;
  }

  @action
  saveContribution(contribution) {
    contribution
      .save()
      .then(() => {
        this.flashes.addSuccess('Memorial contribution successfully saved!');
        this.model.save();
        this.kase.reload();
      })
      .catch(() => {
        contribution.rollbackAttributes();
        this.flashes.addError(
          'Oops! There was an error saving the memorial contribution.'
        );
      });

    this.addingContribution = false;
  }

  @action
  onMemorialSelectPlace(contribution, place) {
    contribution.place = place;
  }

  @action
  onClearMemorialContribution(contribution) {
    contribution
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess('Memorial contribution successfully deleted!');
      })
      .catch(() => {
        this.flashes.addError(
          'Oops! There was an error removing this memorial contribution.'
        );
      });

    this.addingContribution = false;
  }

  @action
  cancelContribution(contribution) {
    contribution.rollbackAttributes();
    this.addingContribution = false;
  }

  @action
  addPallbearer(person) {
    const isHonorary = false;
    this.selectPallbearer(person, isHonorary);
  }

  @action
  addHonoraryPallbearer(person) {
    const isHonorary = true;
    this.selectPallbearer(person, isHonorary);
  }

  @action
  removePallbearer(pallbearer) {
    pallbearer.destroyRecord();
  }

  @action
  removeFlorist() {
    this.model.set('florist', null);
    this.api.json
      .post(`v2/service_infos/${this.model.id}/remove_florist`)
      .then((response) => {
        if (response.ok) {
          this.flashes.addSuccess('Florist successfully removed.');
        } else {
          this.flashes.addError('Something went wrong, please try again.');
        }
      });
  }

  @action
  saveNewPallbearer() {
    const isHonorary = false;
    return this.savePallbearer(isHonorary);
  }

  @action
  saveNewHonoraryPallbearer() {
    const isHonorary = true;
    return this.savePallbearer(isHonorary);
  }

  @action
  @preventFormDefault
  saveService() {
    const service = this.model;
    service.kase = this.v2Kase;

    service.save().then(() => {
      this.flashes.addSuccess('Great job! You updated the case information');
      // we must get the updated version of the Kase after saving
      // to avoid duplicate service-infos being created if they re-enter the
      // route without reloading authenticated.kases.manage
      this.kase.reload();
      const route = this.clientRouting.manageRouteTransition(
        'authenticated.kases.manage.edit.service-info'
      );
      this.router.transitionTo(route);
    });
  }
}
