import Component from '@ember/component';
import { inject as service } from '@ember/service';

export default class NavIconComponent extends Component {
  @service sidebar;
  
  click() {
    this.sidebar.toggleSidebar();
  }
}
