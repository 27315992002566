import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class FrazerSetupController extends Controller {
  showHostnameField = true;
  showUsernameField = true;
  showSecretField = true;
  secretPlaceholder = 'Password';
  usernamePlaceholder = 'Username';

  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
