import Controller from '@ember/controller';
import { action, set, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';
import { buildUrl } from 'crakn/utils/url-builder';
import config from 'crakn/config/environment';
import { v4 as uuidv4 } from 'uuid';
import { inject as service } from '@ember/service';

export default class AuthenticatedPaymentsPayoutsController extends Controller {
  @service flashes;
  per_page = 10;
  queryParams = ['integration_service_id', 'max_amount', 'min_amount', 'end_date', 'start_date', 'sort_by', 'order', 'page', 'per_page']

  @tracked integration_service_id = '';
  @tracked max_amount = '';
  @tracked min_amount = '';
  @tracked end_date = '';
  @tracked start_date = '';

  @tracked order = 'DESC';
  @tracked sort_by = 'created_at';
  @tracked page = 1;
  @tracked uuid = uuidv4();

  get headers() {
    const headers = {};
    if (localStorage.getItem('sso_token') === null) {
      headers['X-CSRF-Token'] = this.session.data.authenticated.token;
    } else {
      headers['Authorization'] = localStorage.getItem('sso_token');
    }
    return headers;
  }

  @computed('uuid', 'integration_service_id', 'max_amount', 'min_amount', 'end_date', 'start_date', 'sort_by', 'order')
  get excelReportLink() {
    const params = {
      integration_service_id: this.integration_service_id,
      start_date: this.start_date,
      end_date: this.end_date,
      order: this.order,
      page: 1,
      per_page: 500000,
      report_uuid: this.uuid
    };

    return buildUrl(`${config.host}/api/tribute_pay/v1/generate_payout_report`, params);
  }

  get downloadLink() {
    return `#/generated_document/${this.uuid}`;
  }

  @action
  generateDownload() {
    fetch(this.excelReportLink, { method: 'GET', credentials: 'include', headers: this.headers})
    .then((response) => {
      if (!response.ok) {
        this.flashes.addError(
          'Oops, something went wrong! Please try again.'
        );
      }
      this.uuid = uuidv4();
    });
  }

  @action
  setDate(attr, value) {
    if (value) {
      set(this, attr,  moment(value).toISOString(true));
    } else {
      set(this, attr, '');
    }
  }
}
