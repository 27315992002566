import SearchController from 'crakn/controllers/search';
import { sort } from '@ember/object/computed';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'newOrganization.name': validator('presence', true),
});

export default class AdminOrganizationController extends SearchController.extend(Validations) {
  @service intl;
  @service flashes;
  @service session;
  @service store;

  @tracked deletingOrganization = null;
  @tracked newOrganization = null;
  @tracked showValidations = false;

  showValidation = false;

  sorting = ['name:asc'];
  @sort('model', 'sorting') sortedContent;

  @action
  addNewOrganization() {
    this.newOrganization = this.store.createRecord('v2/organization');
  }

  @action
  saveNewOrganization() {
    this.validate()
        .then(() => {
          if (this.validations.isValid) {
            this.showValidations = false;
            this.newOrganization.save()
                           .then(() => {
                             this.newOrganization = null;
                             this.send('refreshModel');
                             this.flashes.addSuccess(this.intl.t('authenticated.admin.manage.organizations.index.addNewOrganization.buttons.messages.success'));
                           })
                           .catch(() => {
                             this.flashes.addError(this.intl.t('authenticated.admin.manage.organizations.index.addNewOrganization.buttons.messages.error'));
                           });
          } else {
            this.showValidations = true;
          }
        });
  }

  @action
  cancelNewOrganization() {
    this.newOrganization.rollbackAttributes();
    this.newOrganization = null;
  }

  @action
  selectOrganizationForDelete(organization) {
    this.deletingOrganization = organization;
  }

  @action
  clearDeletingOrganization() {
    this.deletingOrganization = null;
  }

  @action
  confirmDeletingOrganization(organization) {
    organization
      .destroyRecord()
      .then(() => {
        this.clearDeletingOrganization();
        this.flashes.addSuccess(this.intl.t('authenticated.admin.manage.organizations.index.table.links.messages.success'));
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.admin.manage.organizations.index.table.links.messages.error'));
      });
  }
}
