import { sort, filterBy } from '@ember/object/computed';
import Controller from '@ember/controller';
import { get } from '@ember/object';
import InquiryActions from 'crakn/mixins/inquiry-actions';
import { sortKaseContact } from 'crakn/utils/kase-contact-sorter';
import { tracked } from '@glimmer/tracking';

export default Controller.extend(InquiryActions, {
  eventSortingDesc: ['startsAtUnix'],

  sortedEvents: sort('events', 'eventSortingDesc'),

  kaseContacts: tracked(),

  get sortedKaseContacts() {
    return sortKaseContact(this.kaseContacts);
  },

  newInquiries: filterBy('inquiries', 'status', 'New'),
  urgentInquiries: filterBy('inquiries', 'status', 'Urgent'),
  outgoingInquiries: filterBy('inquiries', 'status', 'Outgoing'),
  inProgressInquiries: filterBy('inquiries', 'status', 'In Progress'),
  waitingInquiries: filterBy('inquiries', 'status', 'Waiting for Information'),
  closedInquiries: filterBy('inquiries', 'status', 'Closed'),

  actions: {
    onSelectContribution() {
      this.transitionToRoute(
        'authenticated.kases.manage.edit.service-info',
        get(this, 'model.id')
      );
    }
  }
});
