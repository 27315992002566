import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Field::Select\n    @placeholder={{@placeholder}}\n    @placeholderLabel={{this.placeholderLabel}}\n    @placeholderValue={{this.placeholderValue}}\n    @disabled={{@disabled}}\n    @value={{@value}}\n    @onChange={{@onChange}}\n    ...attributes>\n  {{#each this.activeUsers as |staff|}}\n    <option\n        value={{staff.id}}\n        selected={{eq (if @value.id @value.id @value) staff.id}}>\n      {{ concat staff.name ( if @showEmail (concat \" (\" staff.email \")\") ) }}\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n", {"contents":"<G::Crakn::Field::Select\n    @placeholder={{@placeholder}}\n    @placeholderLabel={{this.placeholderLabel}}\n    @placeholderValue={{this.placeholderValue}}\n    @disabled={{@disabled}}\n    @value={{@value}}\n    @onChange={{@onChange}}\n    ...attributes>\n  {{#each this.activeUsers as |staff|}}\n    <option\n        value={{staff.id}}\n        selected={{eq (if @value.id @value.id @value) staff.id}}>\n      {{ concat staff.name ( if @showEmail (concat \" (\" staff.email \")\") ) }}\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n","moduleName":"crakn/components/g/crakn/selector/staff.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/selector/staff.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class GCraknSelectorStaffComponent extends Component {
  @service staff;

  get placeholderLabel() {
    return this.args.placeholderLabel || 'Select One';
  }

  get placeholderValue() {
    return this.args.placeholderValue || '';
  }

  get activeUsers() {
    if (this.args.filteredByAttributePresence) {
      return this.staff.activeFilteredByAttributePresence(this.args.filteredByAttributePresence);
    } else {
      return this.staff.active;
    }
  }
}
