import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminManageCaseNumberingController extends Controller {
  @service flashes;
  
  @action
  save() {
    this.model
      .save()
      .then(() => {
        this.flashes.addSuccess('Your Info has been saved!');
      })
      .catch(error => {
        if (error.errors[0]['detail']) {
          this.flashes.addError(error.errors[0]['detail']);
        } else {
          this.flashes.addError('Oops, something went wrong! Please try again.');
        }
      });
  }
}
