/* To use this mixin:
 *   1. add it to a controller
 *   2. add a `smartEntryPersonMeta` object to the controller, with the format:
 *      smartEntryPersonMeta: {
 *        'mother': {
 *          key: 'kase.mother',
 *          label: 'Mother',
 *          srcOptions: ['informant', 'purchaser']
 *        },
 *        ...
 *      }
 *
 * The mixin will generate
 *  - the `smartEnterPerson` action, which just sets the target person with the
 *    source person
 *
 *  - computed properties for the options displayed by the smart entry
 *    component, based on the meta object. Computed properties will only be
 *    generated if the role has a `srcOptions` key, which can be omitted on
 *    controllers where that role will not be the target of smart entry. They
 *    will be named `${personRole}SmartEntryOptions`, e.g.
 *    `motherSmartEntryOptions` for the example above. The computed properties
 *    will return with the following format:
 *
 *    [
 *      {
 *        role: 'informant',
 *        key: 'kase.informant',
 *        label: 'Informant',
 *        srcOptions: [...]
 *      },
 *      {
 *        role: 'purchaser',
 *        key: 'kase.purchaser',
 *        label: 'Responsible Party',
 *        srcOptions: [...]
 *      },
 *      ...
 *    ]
 */

import Mixin from '@ember/object/mixin';

import { computed, set, defineProperty } from '@ember/object';

export default Mixin.create({
  init() {
    this._super();
    this.setUpSmartEntryOptionComputers();
  },

  setUpSmartEntryOptionComputers() {
    const meta = this.smartEntryPersonMeta;

    Object.keys(meta)
      .filter(destPersonRole => meta[destPersonRole].srcOptions)
      .forEach(destPersonRole => {
        const destPersonMeta = meta[destPersonRole];
        const srcPeopleIdKeys = destPersonMeta.srcOptions.map(
          srcPersonRole => `${meta[srcPersonRole].key}.id`
        );

        const srcOptionsComputer = computed(...srcPeopleIdKeys, function() {
          return this.generateSmartEntryOptions(destPersonRole);
        });

        defineProperty(
          this,
          `${destPersonRole}SmartEntryOptions`,
          srcOptionsComputer
        );
      });
  },

  generateSmartEntryOptions(destPersonRole) {
    const options = [];
    const meta = this.smartEntryPersonMeta;

    meta[destPersonRole].srcOptions.forEach(srcPersonRole => {
      const srcPersonMeta = meta[srcPersonRole];
      const srcPersonId = this.get(`${srcPersonMeta.key}.id`);

      // Just because `{{#each-in}}` isn't in the current ember version.
      // We could use an options object instead of an array if it were.
      const srcPersonMetaClone = Object.assign(
        { role: srcPersonRole },
        srcPersonMeta
      );

      if (srcPersonId) {
        options.push(srcPersonMetaClone);
      }
    });

    return options;
  },

  actions: {
    smartEnterPerson(srcPersonRole, destPersonRole) {
      const meta = this.smartEntryPersonMeta;
      const destPersonKey = meta[destPersonRole].key;
      const srcPersonKey = meta[srcPersonRole].key;
      set(this, destPersonKey, this.get(srcPersonKey));
    }
  }
});
