import { readOnly, filterBy } from '@ember/object/computed';
import { computed, get, set } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';
import PersonSmartEntry from 'crakn/mixins/person-smart-entry';
import PaymentValidations from 'crakn/utils/payment-validations';
import moment from 'moment';

export default Controller.extend(PersonSmartEntry, PaymentValidations, {
  flashes: service(),
  intl: service(),
  session: service(),
  staff: service(),
  store: service(),

  kase: null,
  altPayer: false,
  usePurchaserInfo: false,
  paymentTypes: null,
  selections: null,
  newCollectionAttempt: null,
  newCollectionAttemptPeople: [],
  newCollectionAttemptPeopleIds: [],
  previousCollectionAttemptPeople: [],
  collectionAttemptPaymentType: false,
  newPayment: null,
  card: null,
  billing: null,
  disabled: false,
  paymentSteps: null,
  selection_id: null,
  internalExpirationTest: false,
  isPaymentDisabled: false,
  toggleCreateInterest: false,
  toggleEmailPaymentLink: false,
  newInterest: null,
  'newPayment.amount': null,
  disablePaymentLabel: null,
  emailLinkSortOrder: 'DESC',

  selection: computed('selection_id', 'selections', function() {
    return this.selections.findBy('id', this.selection_id);
  }),

  tributePayPaymentTypes: filterBy('paymentTypes', 'isTributePay'),

  paymentType: readOnly('newPayment.paymentType.id'),
  payerId: readOnly('newPayment.person.id'),

  smartEntryPersonMeta: {
    payer: {
      key: 'newPayment.person',
      label: 'Payer',
      srcOptions: ['mother', 'father', 'spouse', 'purchaser', 'informant']
    },
    mother: { key: 'kase.mother', label: 'Mother' },
    father: { key: 'kase.father', label: 'Father' },
    spouse: { key: 'kase.spouse', label: 'Spouse' },
    purchaser: { key: 'kase.purchaser', label: 'Purchaser' },
    informant: { key: 'kase.informant', label: 'Informant' }
  },

  showValidation: false,
  showCollectionAttemptPaymentTypeValidation: false,
  showCollectionAttemptPeopleValidation: false,

  disablePayment: computed('model.card.cc_number', function() {
    if (get(this, 'model.card.cc_number')) {
      const ccNumber = get(this, 'model.card.cc_number').replace(/\s/g, '');
      return ccNumber.length >= 12 ? false : true;
    } else {
      return true;
    }
  }),

  disableEmailPaymentLink: computed('newCollectionAttemptPeople', function() {
    if (this.newCollectionAttemptPeople.length === 0) {
      return true;
    }

    return this.newCollectionAttemptPeople.some((person) => !person.email);
  }),

  get contractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice ?
    `Choose an Invoice or Add-on` : `Choose a Contract or Add-on`;
  },

  get unpostedLabel() {
    if (!this.selection.isContract) {
      return "Add-on";
    }
    return this.session.currentClient.relabel_contract_to_invoice ?
    `Invoice` : `Contract`;
  },

  get collectionAttemptPaymentTypeValidationMessage() {
    return this.collectionAttemptPaymentType ? null : this.intl.t('authenticated.kases.manage.financials.payments.validations.collectionAttempt.paymentTypeRequired');
  },

  get collectionAttemptPeopleValidationMessage() {
    return this.newCollectionAttemptPeople.length > 0 ? null : this.intl.t('authenticated.kases.manage.financials.payments.validations.collectionAttempt.peopleRequired');
  },

  get canViewEmailPaymentLink() {
    return this.session.currentClient.has_tribute_pay_account &&
      get(this, 'kase.location.hasApprovedTributePayAccount') &&
      this.tributePayPaymentTypes.length;
  },

  validateCollectionAttempt() {
    const isValid = !(this.collectionAttemptPaymentTypeValidationMessage || this.collectionAttemptPeopleValidationMessage);
    return isValid;
  },

  actions: {
    toggleOtherPaymentSource(value) {
      if(!value) {
        this.newPayment.set("payer", undefined);
      }
    },

    setPaymentType(payment, paymentTypeId) {
      if (paymentTypeId === '') {
        set(this, 'paymentSteps.isProcessingCreditCard', false);
        set(this, 'card.isProcessingCreditCard', false);
        set(this, 'billing.isProcessingCreditCard', false);
        set(this, 'disabled', false);

        set(payment, 'paymentType', null);
        return;
      } else {
        const paymentType = get(this, 'paymentTypes').findBy(
          'id',
          paymentTypeId
        );
        if (get(paymentType, 'processCreditCard')) {
          set(this, 'paymentSteps.isProcessingCreditCard', true);
          set(this, 'card.isProcessingCreditCard', true);
          set(this, 'billing.isProcessingCreditCard', true);
          set(this, 'disabled', true);
        } else if (get(paymentType, 'isTributePay')){
          payment.set('processorPending', true);
          set(this.newPayment, 'collectedAt', moment());
        } else {
          set(this, 'paymentSteps.isProcessingCreditCard', false);
          set(this, 'card.isProcessingCreditCard', false);
          set(this, 'billing.isProcessingCreditCard', false);
          set(this, 'disabled', false);
          payment.set('processorPending', false);
        }
        payment.set('paymentType', paymentType);
      }
    },

    setCollectedAt(value) {
      this.newPayment.collectedAt = moment(value);
    },

    async validatePayment(newPayment, billing) {
      if (dateInCoaLockedPeriod(this.selection, this.session, newPayment.get('collectedAt'))) {
        this.flashes.addError(this.intl.t('authenticated.kases.manage.financials.payments.edit.errors.postingPeriodAdd'));
        return;
      }

      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          if (get(this, 'paymentSteps.isProcessingCreditCard')) {
            this.send('authorizePayment', newPayment, billing);
          } else {
            this.send('saveNewPayment', newPayment);
          }
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    removeContact(role, personId) {
      const kase = this.kase;
      const kasePerson = get(kase, 'kasePeople').find(function(kasePerson) {
        return get(kasePerson, 'person.id') === personId &&
        get(kasePerson, 'role') === role.replace(/-/, '_');
      });
      if (kasePerson) {
        kasePerson.destroyRecord();
      }
    },

    setCollectedBy(selection) {
      const staffMember = get(this, 'staff.active').findBy('id', selection);
      set(this, 'newPayment.collectedBy', staffMember);
    },

    setPurchaserOnCreditCard(value, purchaser) {
      set(this, 'usePurchaserInfo', !value);
      const billingInfo = this.billing;
      const creditCardInfo = this.card;
      if (this.usePurchaserInfo) {
        if (purchaser !== null) {
          purchaser.then(person => {
            const firstName = get(person, 'firstName');
            const lastName = get(person, 'lastName');
            const firstLast = [firstName, lastName].filter(Boolean).join(' ');

            set(billingInfo, 'first_name', firstName);
            set(billingInfo, 'last_name', lastName);
            set(billingInfo, 'address1', get(person, 'addressLine1'));
            set(billingInfo, 'address2', get(person, 'addressLine2'));
            set(billingInfo, 'city', get(person, 'city'));
            set(billingInfo, 'state', get(person, 'state'));
            set(billingInfo, 'postal', get(person, 'zipCode'));
            set(billingInfo, 'country', get(person, 'country'));
            set(creditCardInfo, 'cc_name', firstLast);
            const event = document.createEvent('HTMLEvents');
            setTimeout(function() {
              event.initEvent('change', false, true);
              document
                .getElementsByClassName('qa-name-on-credit-card')[0]
                .dispatchEvent(event);
            }, 500);
          });
        }
      } else {
        set(billingInfo, 'first_name', '');
        set(billingInfo, 'last_name', '');
        set(billingInfo, 'address1', '');
        set(billingInfo, 'address2', '');
        set(billingInfo, 'city', '');
        set(billingInfo, 'state', '');
        set(billingInfo, 'postal', '');
        set(billingInfo, 'country', '');
        set(creditCardInfo, 'cc_name', '');
      }
    },

    setInsurancePolicy(policyId) {
      if (policyId == '') {
        this.newPayment.insurancePolicy = null;
      } else {
        this.newPayment.insurancePolicy = this.store.peekRecord('insurancePolicy', policyId)
      }
    },

    setInterestType(interestTypeId) {
      this.newInterest.interestType = this.store.peekRecord('v2/interestType', interestTypeId)
    },

    async setCollectionAttemptPaymentType(paymentTypeId) {
      const v2PaymentType = await this.store.findRecord('v2/payment-type', paymentTypeId);
      set(this, 'newCollectionAttempt.paymentType', v2PaymentType);
      set(this, 'collectionAttemptPaymentType', true);
      set(this, 'showCollectionAttemptPaymentTypeValidation', false);
    },

    togglePaymentLinkDetails() {
      set(this, 'toggleEmailPaymentLink', !get(this, 'toggleEmailPaymentLink'));
      set(this, 'newCollectionAttemptPeopleIds', []);
    },

    setNewCollectionAttemptPerson(contact) {
      set(this, 'newCollectionAttemptPeople', [...this.newCollectionAttemptPeople, contact]);
      set(this, 'newCollectionAttemptPeopleIds', [...this.newCollectionAttemptPeopleIds, contact.id]);
      set(this, 'showCollectionAttemptPeopleValidation', false)
    },

    removeCollectionAttemptContact(contact) {
      set(this, 'newCollectionAttemptPeople', this.newCollectionAttemptPeople.filter((person) => person.id !== contact.id))
      set(this, 'newCollectionAttemptPeopleIds', this.newCollectionAttemptPeopleIds.filter((id) => id !== contact.id))
   },

    async sendPaymentLinkEmail() {
      const isValid = this.validateCollectionAttempt();

      const emailTemplate = await this.store.query('v2/email-template', {
        purpose: 'payment'
      })

      if (emailTemplate.length === 0) {
        const newEmailTemplate = this.store.createRecord('v2/email-template', {
          purpose: 'payment',
          name: this.intl.t('authenticated.admin.tribute-pay.email-templates.defaultData.email.name'),
          subject: this.intl.t('authenticated.admin.tribute-pay.email-templates.defaultData.email.subject'),
          body: this.intl.t('authenticated.admin.tribute-pay.email-templates.defaultData.email.body')
        })

        newEmailTemplate.save();
      }

      if (!isValid) {
        if (this.collectionAttemptPaymentTypeValidationMessage) {
          set(this, 'showCollectionAttemptPaymentTypeValidation', true);
        }
        if (this.collectionAttemptPeopleValidationMessage) {
          set(this, 'showCollectionAttemptPeopleValidation', true);
        }

        return;
      }

      if (this.internalExpirationTest) {
        this.newCollectionAttempt.internalExpirationTest = true;
        this.newCollectionAttempt.expiredAt = moment();
      }

      this.newCollectionAttempt.maximumAmount = this.selection.balance;
      this.newCollectionAttempt.people = this.newCollectionAttemptPeople;
      this.newCollectionAttempt.referenceId = this.selection.id;
      this.newCollectionAttempt.referenceType = 'Selection';

      this.newCollectionAttempt.save().then(async () => {
        
        set(this, 'showCollectionAttemptPaymentTypeValidation', false);
        set(this, 'showCollectionAttemptPeopleValidation', false);
        set(this, 'collectionAttemptPaymentType', false);
        set(this, 'newCollectionAttempt.paymentType', null);
        set(this, 'newCollectionAttemptPeople', []);

        this.send('fetchPreviousCollectionAttemptPeople');

        const nextCollectionAttempt = this.store.createRecord('v2/collection-attempt');
        set(this, 'newCollectionAttempt', nextCollectionAttempt);
        set(this, 'newCollectionAttemptPeopleIds', []);

        this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.financials.payments.messages.paymentLink.success'));
      }).catch(() => {
        this.flashes.addError(this.intl.t('authenticated.kases.manage.financials.payments.messages.paymentLink.error'));
      });
    },

    onWillDestroy() {
      set(this, 'toggleEmailPaymentLink', false);
      set(this, 'newCollectionAttempt.paymentType', null);
      set(this, 'showCollectionAttemptPeopleValidation', false);
      set(this, 'showCollectionAttemptPaymentTypeValidation', false);
      set(this, 'newCollectionAttemptPeople', []);
      set(this, 'internalExpirationTest', false);
    },

    fetchPreviousCollectionAttemptPeople() {
      this.store.query('v2/collection-attempt', {
        reference_id: this.selection_id,
        reference_type: 'Selection',
        sort_by: 'created_at',
        order: this.emailLinkSortOrder
      }).then((collectionAttempts) => {
        let peopleArray = [];
        collectionAttempts.forEach((collectionAttempt) => {
          let people = collectionAttempt.people.toArray();
          people = people.map(({ id, name, email }) => ({
            id,
            name,
            email,
            createdAt: collectionAttempt.createdAt,
            expiredAt: collectionAttempt.expiredAt
          }));
          peopleArray = [...peopleArray, ...people];
        });
        set(this, 'previousCollectionAttemptPeople', peopleArray);
        set(this, 'internalExpirationTest', false);
      });
    },

    isEmailLinkValid(date, expiredAt) {
      if (expiredAt) {
        return false;
      }
      const datePlus30Days = moment(date).add(30, 'd');
      const currentDate = moment();

      return currentDate.isSameOrBefore(datePlus30Days);
    }
  },
});
