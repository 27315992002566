import { debounce } from '@ember/runloop';
import { observer } from '@ember/object';
import InputField from 'crakn/components/form/input-field';

export default InputField.extend({
  layoutName: 'components/form/input-field',
  classNameBindings: ['showInvalid:input--invalid'],

  placeholder: 'Search',
  style: 'single',

  onUpdate: null,

  _valueDidChange: observer('value', function() {
    debounce(this, '_notifyValueUpdate', 50, true);
  }),

  _notifyValueUpdate() {
    if (this.onUpdate) {
      this.sendAction('onUpdate');
    }
  }
});
