import Model, { hasMany, belongsTo, attr } from '@ember-data/model';

export default class V2AccountingExportModel extends Model {
  @attr('moment-datetime') createdAt;
  @attr('string') downloadUrl;
  @attr('moment-datetime') updatedAt;

  @belongsTo('v2/chartOfAccounts', { async: true }) chartOfAccounts;
  @belongsTo('v2/user', { async: true }) exportedBy;

  @hasMany('v2/integrationExport', { async: true }) integrationExports;
  @hasMany('v2/kase', { async: true }) kases;
  @hasMany('v2/selection', { async: true }) selections;
}
