import Route from '@ember/routing/route';
import { action } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default class AuthenticatedContactsRoute extends Route {
  queryParams= {
    query: {
      refreshModel: false
    }
  };

  @service api;

  model(params) {
    return this.store.findRecord('v2/person', params.contact_id);
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('showArchiveDialog', false);
    controller.set('query', null);
    controller.set('people', null);
    this.api.json.get(`v2/people/${model.id}/dependents`).then((resp) => {
      controller.set('deleteInfo', resp.parsedJson.dependents);
    });

  }

  resetController(controller, isExiting, transition) {
    if (isExiting || transition) {
      controller.setProperties({
        query: null
      });
    }
  }

  @action
  toggleArchived(contact) {
    this.controller.set('showArchiveDialog', false);
    if (contact.archivedAt) {
      contact.archivedAt = null;
    } else {
      contact.archivedAt = moment();
    }

    contact.save().then(() => {
      this.refresh();
    });
  }
}
