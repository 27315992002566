import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"well\" data-width=\"{{this.width}}\"\n    {{did-insert this.setup}}>\n  {{#if this.width}}\n    <canvas\n        height={{@height}}\n        width={{this.width}}\n        {{did-insert this.rendered}}>\n    </canvas>\n  {{/if}}\n</div>\n\n<button\n    class=\"btn btn--default\"\n    {{on \"click\" this.reset}}>\n  Reset\n</button>\n<button\n    class=\"btn btn--primary\"\n    {{on \"click\" this.save}}>\n  Save\n</button>\n{{#if @editSignature}}\n  <button\n      type=\"button\"\n      class=\"btn btn--info\"\n      {{on \"click\" this.cancel}}>\n    Cancel\n  </button>\n{{/if}}\n", {"contents":"<div class=\"well\" data-width=\"{{this.width}}\"\n    {{did-insert this.setup}}>\n  {{#if this.width}}\n    <canvas\n        height={{@height}}\n        width={{this.width}}\n        {{did-insert this.rendered}}>\n    </canvas>\n  {{/if}}\n</div>\n\n<button\n    class=\"btn btn--default\"\n    {{on \"click\" this.reset}}>\n  Reset\n</button>\n<button\n    class=\"btn btn--primary\"\n    {{on \"click\" this.save}}>\n  Save\n</button>\n{{#if @editSignature}}\n  <button\n      type=\"button\"\n      class=\"btn btn--info\"\n      {{on \"click\" this.cancel}}>\n    Cancel\n  </button>\n{{/if}}\n","moduleName":"crakn/components/g/crakn/signature-pad.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/signature-pad.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import SignaturePad from 'signature_pad';
import { inject as service } from '@ember/service';

export default class GCraknSignaturePadComponent extends Component {
  @tracked element;
  @tracked parentWidth;
  @service api;

  get width() {
    return this.args.width || this.parentWidth;
  }

  @action
  setup(element) {
    this.parentWidth = element.parentElement.offsetWidth;
  }

  @action
  rendered(element) {
    this.element = new SignaturePad(element);
  }

  @action
  reset() {
    this.element = new SignaturePad(this.element.canvas);
  }

  @action
  cancel() {
    this.args.onCancel();
  }

  @action
  async save() {
    const pngDataUrl = this.element.canvas.toDataURL();
    if (pngDataUrl) {
      this.args.onSave(this).then(signature => {
        this.api.json
          .post(`v2/signatures/${signature.get('id')}/upload`, {
            body: { file: pngDataUrl },
          })
          .then(async (response) => {
            if (response.ok) {
              const json = await response.parsedJson;
              json.data.type = 'v2/' + json.data.type;
              this.args.onSuccess(json);
            } else {
              this.args.onError(response);
            }
          });
      });
    }
  }
}
