import Component from '@ember/component';

export default Component.extend({
  showDeleteConfirm: false,
  type: null,

  blockButtons: true,

  actions: {
    confirmDelete() {
      this.sendAction('onConfirmDelete');
    }
  }
});
