import Model, { attr, belongsTo } from '@ember-data/model';
import {
  airForceRanks,
  armyRanks,
  marineRanks,
  navyRanks
} from 'crakn/utils/us/military-ranks';
import { militaryBranches } from 'crakn/utils/us/military-branches';
import { formatDateRange } from 'crakn/utils/date-helpers';


export default class V2UsServiceRecordsModel extends Model {
  @attr('boolean') dischargePapersReceived;

  @attr('moment-date') dateDischarged;
  @attr('moment-date') dateEntered;

  @attr('string') description;
  @attr('string') dischargeType;
  @attr('string') militaryBranch;
  @attr('string') militaryBranchOther;
  @attr('string') militaryRank;
  @attr('string') notes;
  @attr('string') placeDischarged;
  @attr('string') placeEntered;
  @attr('string') warsFought;
  @attr('string') warsFoughtOther;

  @belongsTo('v2/veteran-info', { async: true }) veteranInfo;

  get branchLabel() {
    const matchedBranch = militaryBranches.find(branch => branch.value === this.militaryBranch);
    return matchedBranch ? matchedBranch.label : '';
  }

  get branchOptions() {
    switch (this.militaryBranch) {
      case 'air_force_and_air_force_reserve':
        return airForceRanks;
      case 'army_and_army_reserve':
      case 'national_guard':
        return armyRanks;
      case 'marine_corps_and_marine_corps_reserve':
        return marineRanks;
      case 'navy_and_navy_reserve':
      case 'coast_guard_and_coast_guard_reserve':
        return navyRanks;
      default:
        return [];
    }
  }

  get listItemHeader() {
    const dates = formatDateRange(this.dateEntered, this.dateDischarged);

    const headerItems = [
      dates,
      this.militaryRank,
      this.branchLabel
    ].filter(Boolean);

    if (headerItems && headerItems.length > 0) {
      return headerItems.join(' - ');
    } else {
      return 'Unknown';
    }
  }
}
