import Controller from '@ember/controller';
import { action, computed } from '@ember/object';
import { filter, not, setDiff } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';
import { inject as service } from '@ember/service';

const Validations = buildValidations({
  // Crematory
  'disposition.cremationPlace.id': validator('presence', true),

  // Pacemakers and Implants
  'cremAuth.implantsDescription': validator('presence', {
    presence: true,
    disabled: not('model.cremAuth.hasImplants')
  }),

  'kase.removedImplantsRecipient.id': validator('presence', {
    presence: true,
    disabled: computed('model.cremAuth.{hasImplants,disposeImplants}', function() {
      const validate = this.model.cremAuth.hasImplants && !this.model.cremAuth.disposeImplants;
      return !validate;
    })
  }),

  // Radioactive Treatments
  'cremAuth.radioactivesDescription': validator('presence', {
    presence: true,
    disabled: not('model.cremAuth.hasRadioactives')
  }),

  // Casket
  'kase.casket': validator('presence', true),

  // Urn
  'disposition.containerDescription': validator('presence', true),

  // Final Disposition
  'cremAuth.daysToHoldRemains': [
    validator('presence', {
      presence: true
    }),
    validator('number', {
      allowString: true,
      integer: true,
      gte: 0,
      message: 'This field must be a valid number of days'
    })
  ],

  'disposition.place.id': validator('presence', {
    presence: true,
    message: 'Cemetery can\'t be blank',
    disabled: computed('model.cremAuth.remainsDeliveryMethod', function() {
      return this.model.cremAuth.remainsDeliveryMethod != 'cemetery';
    })
  }),

  'kase.authorizedForPickup': validator('length', {
    min: 1,
    message: 'There must be at least one person authorized to pick up remains',
    disabled: computed('model.cremAuth.remainsDeliveryMethod', function() {
      return this.model.cremAuth.remainsDeliveryMethod != 'designated';
    })
  }),

  'kase.crematedRemainsMailRecipient': [
    validator('presence', {
      presence: true,
      message: 'There must be a recipient for the remains',
      disabled: computed('model.cremAuth.remainsDeliveryMethod', function() {
        return this.model.cremAuth.remainsDeliveryMethod != 'usps';
      })
    }),

    validator('inline', {
      validate(value, _options, _model, _attribute) {
        return value.get('addressCheck') || 'The recipient must have an address';
      },
      disabled: computed('model.cremAuth.remainsDeliveryMethod', function() {
        return this.model.cremAuth.remainsDeliveryMethod != 'usps';
      })
    })
  ],

  // Personal Property
  'cremAuth.personalPropertyDescription': validator('presence', {
    presence: true,
    disabled: computed('model.cremAuth.personalPropertyTreatment', function() {
      return this.model.cremAuth.personalPropertyTreatment == 'discard';
    })
  }),

  'kase.personalPropertyRecipient': validator('presence', {
    presence: true,
    message: 'There must be a recipient for the personal property',
    disabled: computed('model.cremAuth.personalPropertyTreatment', function() {
      return this.model.cremAuth.personalPropertyTreatment != 'designated';
    })
  }),

  // Staff
  'kase.staffMember': validator('presence', true),

  // eSign
  'newEsignedAuth.esignature.signer.id': validator('presence', {
    presence: true,
    message: 'There must be an Authorizing Agent'
  }),

  'newEsignedAuth.esignature.signer.email': validator('presence', {
    presence: true,
    message: 'The Authorizing Agent must have an e-mail address'
  }),

  'newEsignedAuth.signerRelationship': validator('presence', true),

  'newEsignedAuth.authorityReason': validator('inclusion', {
    in: ['superior', 'written', 'unreachable', 'refusal'],
    message: 'This field can\'t be blank'
  }),

  'newEsignedAuth.othersWithAuthority': validator('presence', {
    presence: true,
    disabled: computed(
      'model.newEsignedAuth.authorityReason',
      function() {
        return this.model.newEsignedAuth.authorityReason == 'superior';
      })
  })
});

export default class CremationAuthorizationController extends Controller.extend(Validations) {
  deliveryMethodOptions = {
    cemetery: 'Deliver remains to Cemetery',
    designated: 'Release remains to designated recipient(s)',
    usps: 'Mail remains using USPS Priority Express',
    other: 'Other'
  };

  personalPropertyOptions = {
    discard: 'Discard at Crematory\'s discretion',
    funeralhome: 'Deliver to Funeral Home',
    designated: 'Deliver to other person (specify)'
  };

  esignStatusClasses = {
    pending: 'yellow',
    complete: 'green',
    declined: 'red'
  };

  isSavingEsign = false;
  showValidation = false;
  newEsignedAuth = null;
  esignedAuthShowingAuditTrail = null;

  @service('api') api;

  /**
   * `isNew` esigned documents are filtered out so the one being created
   *   currently doesn't show up as an extra empty row in the list.
   *
   * `isDestroying` esigned documents are filtered out so no manually unloaded
   *   esigned documents (e.g. if an error occurred while saving an esignature)
   *   show up in the list. `isDestroyed` doesn't work because the computed
   *   property triggers before that.
   */
  @filter('cremAuth.esignedCremationAuthorizations.@each.{isNew,isDestroying}', function(esignedAuth, _i, _esignedAuths) {
    return !esignedAuth.isNew && !esignedAuth.isDestroying;
  })
  persistedEsignedAuths;

  @setDiff('events', 'cremAuth.events') unselectedEvents;

  @computed('persistedEsignedAuths', function() {
    return this.persistedEsignedAuths
      .filter(auth => auth.esignature.status == 'requested')
      .length;
  }) pendingEsignCount;

  @computed('persistedEsignedAuths', function() {
    return this.persistedEsignedAuths
      .filter(auth => auth.esignature.status == 'signed')
      .length;
  }) completedEsignCount;

  @computed('persistedEsignedAuths', function() {
    return this.persistedEsignedAuths
      .filter(auth => auth.esignature.status == 'declined')
      .length;
  }) declinedEsignCount;

  @action
  buildNewEsignedAuth(esignedAuthParams = {}, esignatureParams = {}) {
    const esignedAuth = this.store
      .createRecord('esigned-cremation-authorization', {
        ...esignedAuthParams,
        cremationAuthorization: this.cremAuth
      });

    this.store.createRecord('esignature', {
      ...esignatureParams,
      esignedDocument: esignedAuth
    });

    this.set('newEsignedAuth', esignedAuth);
  }

  @action
  onSubmit(e) {
    e.preventDefault();
    this.save();
  }

  @action
  selectEvent(eventId) {
    const event = this.store.peekRecord('v2/event', eventId);
    this.cremAuth.events.addObject(event);
  }

  @action
  unselectEvent(event) {
    this.cremAuth.events.removeObject(event);
  }

  // `KasePerson`s are "calculated" and attached to the kase on the BE, so the
  // kase needs to be reloaded after actions through the `AddEditKasePerson`
  // component for the changes to show up on the Ember kase, which is necessary
  // for validation.
  @action
  reloadKase() {
    this.kase.reload();
  }

  @action
  requestEsignature(e) {
    e.preventDefault();
    if (e.submitter) {
      e.submitter.disabled = true;
    }

    this.set('isSavingEsign', true); // disable Save/Cancel

    this.validate().then(async ({ validations }) => {
      if (validations.isValid) {
        this.set('showValidation', false);

        try {
          await this.cremAuth.save();
          await this.newEsignedAuth.save();
          await this.newEsignedAuth.esignature.save();

          this.send('flashSuccess', 'E-Signature request sent!');
          this.set('newEsignedAuth', null);
          return false;
        } catch {
          this.handleSaveError();
          this.rebuildFailedEsignRequest();
          return false;
        } finally {
          this.set('isSavingEsign', false);

          if (e.submitter) {
            e.submitter.disabled = false;
          }
        }
      } else {
        this.set('showValidation', true);
        this.set('isSavingEsign', false);

        if (e.submitter) {
          e.submitter.disabled = false;
        }
        return false;
      }
    });
  }

  @action
  cancelNewRequest() {
    this.newEsignedAuth.esignature.unloadRecord();
    this.newEsignedAuth.unloadRecord();
    this.set('newEsignedAuth', null);
  }

  @action
  toggleAuditTrail(esignedAuth) {
    if (esignedAuth.id === this.esignedAuthShowingAuditTrail) {
      this.set('esignedAuthShowingAuditTrail', null);
    } else {
      this.set('esignedAuthShowingAuditTrail', esignedAuth.id);
    }
  }

  @action
  saveSigner() {
    this.newEsignedAuth.esignature.signer.content
      .save()
      .then(() => this.handleSaveSuccess())
      .catch(() => this.handleSaveError());
  }

  @action
  saveSignerEmail() {
    this.newEsignedAuth
      .esignature
      .signer
      .content
      .set('email', this.signerEmail);

    this.saveSigner();
    this.set('signerEmail', null);
  }

  @action
  generateContract(esignedAuth, _e) {
    const url = `esigned_cremation_authorizations/${esignedAuth.id}/download`;
    const params = {
      kase_id: this.kase.id,
      uuid: this.cremAuth.uuid
    };

    this.api.json
      .get(url, { params })
      .then(response => {
        if (!response.ok) {
          this.send('flashError', 'Oops, something went wrong! Please try again.');
        }
      });

    this.send('reload'); // get new uuid
  }

  @action
  cancelRequest(esignedAuth) {
    esignedAuth.esignature.destroyRecord()
      .then(() => {
        esignedAuth.unloadRecord();

        if (!this.persistedEsignedAuths.length) {
          this.buildNewEsignedAuth();
        }

        this.handleSaveSuccess();
      })
      .catch(() => this.handleSaveError());
  }

  @action
  onSelectSigner(signer) {
    this.set('newEsignedAuth.esignature.signer', signer);

    // This hook is also called with `null` when removing a signer
    if (signer) {
      const relativeQuery = { between: `${signer.id},${this.kase.get('deceased.id')}` };

      this.store
        .queryRecord('relative', relativeQuery)
        .then((rel) => {
          const relationship = rel ? rel.relationshipName : '';
          this.set('newEsignedAuth.signerRelationship', relationship);
        });
    }
  }

  save() {
    this.cremAuth
      .save()
      .then(() => this.handleSaveSuccess())
      .catch(() => this.handleSaveError());
  }

  rebuildFailedEsignRequest() {
    const esignedAuthParams = {
      signerRelationship: this.newEsignedAuth.signerRelationship,
      authorityReason: this.newEsignedAuth.authorityReason,
      othersWithAuthority: this.newEsignedAuth.othersWithAuthority
    };
    const esignatureParams = {
      signer: this.newEsignedAuth.esignature.signer
    };

    this.cancelNewRequest();
    this.buildNewEsignedAuth(esignedAuthParams, esignatureParams);
  }

  handleSaveSuccess() {
    this.send('flashSuccess', 'Saved successfully!');
  }

  handleSaveError() {
    this.send('flashError', 'Sorry, but there was an error while saving.');
  }
}
