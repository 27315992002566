import Mixin from '@ember/object/mixin';
import { set } from '@ember/object';
import { run } from '@ember/runloop';

export default Mixin.create({
  queryParams: ['query', 'page', 'archived'],

  page: 1,
  query: null,
  archived: false,

  /**
   * Updates the query value after debounce and resets page number
   * @param  {String} value String to update `query` to
   * @return {Undefined}
   */
  updateQuery(value) {
    set(this, 'query', value);
    set(this, 'page', 1);
  },

  actions: {
    /**
     * Updates the query value after a debounce
     * @param  {String} value Value to update `query` to
     * @return {Undefined}
     */
    queryDidChange(value) {
      // Debounce to keep API queries to a minimum
      run.debounce(this, this.updateQuery, value, 50, true);
    },

    /**
     * Toggles the archived value and resets page number
     * @param  {Boolean} value Whether or not archived is selected
     * @return {Undefined}
     */
    archivedDidChange(value) {
      set(this, 'archived', value);
      set(this, 'page', 1);
    }
  }
});
