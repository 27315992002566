import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedCraknFormsTfeNewController extends Controller {
  @service api;
  @service flashes;
  @service intl;
  @service router;
  
  @tracked showValidation = false;
  @tracked kaseContactDropdown = false;
  @tracked eventDropdown = false;
  @tracked locationDropdown = false;
  @tracked inquiryDropdown = false;
  @tracked esignEnabled = false;

  @action
  createTfeDocument(e) {
    e.preventDefault();
    e.target.disabled = true; // avoid double-click issues

    const isValid = this.validate();
    this.showValidation = !isValid;

    if (isValid) {
      this.postNewDocument();
    } else {
      e.target.disabled = false; // avoid double-click issues
    }
  }

  get documentNameMessage() {
    if (this.document && this.document.name) {
      return null;
    } else {
      return this.intl.t('authenticated.crakn.clients.edit.tfe.new.messages.fieldValidation');
    }
  }

  get documentFileMessage() {
    if (this.documentFile) {
      return null;
    } else {
      return this.intl.t('authenticated.crakn.clients.edit.tfe.new.messages.fieldValidation');
    }
  }

  validate() {
    return !(this.documentNameMessage || this.documentFileMessage);
  }

  async postNewDocument() {
    const formData = new FormData();
    formData.append('document[name]', this.document.name);
    formData.append('document[description]', this.document.description || '');
    formData.append('document[is_public]', this.document.isPublic);
    formData.append('document[kase_contact_dropdown]', this.kaseContactDropdown || false);
    formData.append('document[event_dropdown]', this.eventDropdown || false);
    formData.append('document[location_dropdown]', this.locationDropdown || false);
    formData.append('document[inquiry_dropdown]', this.inquiryDropdown || false);
    formData.append('document[esign_enabled]', this.esignEnabled || false);
    formData.append('documentFile', this.documentFile);

    const response = await this.api.post('tfe/documents', { body: formData });
    const json = await response.json();

    if (response.ok) {
      this.flashes.addSuccess(this.intl.t('authenticated.crakn.clients.edit.tfe.new.messages.success'));
      this.router.transitionTo('authenticated.crakn.manage.forms.tfe.edit', json.revision.document_id);
    } else {
      let msg;
      if (json && json.errors) {
        msg = json.errors.join(', ');
      } else {
        msg = this.intl.t('authenticated.crakn.clients.edit.tfe.new.messages.failure');
      }

      this.flashes.addError(msg);
    }
  }
}
