import Model, { attr } from '@ember-data/model';
//import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2LogoModel extends Model {
  @attr('boolean') receipt;
  @attr('boolean') sfgs;
  @attr('boolean') soa;
  @attr('boolean') cremauth;

  @attr('moment-datetime') archivedAt;

  @attr('string') name;
  @attr('string') url;

  // @belongsTo('v2/clientInfo', { async: true }) clientInfo;
}
