import Component from '@glimmer/component';
import moment from 'moment';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class InventoryAsOfReport extends Component {
  @tracked date = moment().toISOString();

  organization_location_id = null;

  get reportLink() {
    const params = new URLSearchParams({
      as_of_date: this.date,
      organization_location_id: this.organization_location_id,
      uuid: this.args.uuid,
    }).toString();

    return `/report/inventory_as_of.xlsx?${params}`;
  }

  get fileUrl() {
    return `#/generated_document/${this.args.uuid}`;
  }

  @action
  generateReport() {
    this.args.onGenerateReport(this.reportLink);
  }

  @action
  setDate(attr, value) {
    set(this, attr, value);
  }
}
