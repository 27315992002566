import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default class AdminFinancialConfigurationsEditPaymentsController extends Controller {
  @service flashes;
  @service store;
  @service session;

  accounts = null;
  chartOfAccounts = null;
  showValidation = false;

  @action
  addPaymentType() {
    this.set('showValidation', false);
    const paymentType = this.store.createRecord('paymentType', {
      chartOfAccounts: this.chartOfAccounts
    });
    this.model.pushObject(paymentType);
  }

  @action
  toggleFields(paymentType, value) {
    switch (value) {
      case 'check':
        paymentType.toggleProperty('collectCheckNumber');
        if (paymentType.collectCheckNumber === true) {
          paymentType.set('processCreditCard', false);
          if (paymentType.collectCcLastFour === true) {
            paymentType.set('collectCcLastFour', false);
          }
        }
        break;

      case 'credit':
        paymentType.toggleProperty('collectCcLastFour');
        if (paymentType.collectCcLastFour === true) {
          paymentType.set('collectCheckNumber', false);
          paymentType.set('associateInsurancePolicy', false);
        }
        break;
      case 'transnational':
        paymentType.toggleProperty('processCreditCard');
        if (paymentType.processCreditCard === true) {
          paymentType.set('collectCheckNumber', false);
          paymentType.set('collectCcLastFour', false);
          paymentType.set('associateInsurancePolicy', false);
        }
        break;
      case 'insurance':
        paymentType.toggleProperty('associateInsurancePolicy');
        if (paymentType.associateInsurancePolicy === true) {
          paymentType.set('collectCcLastFour', false);
          paymentType.set('processCreditCard', false);
        }
        break;
    }
  }

  @action
  deletePaymentType(type) {
    if (type.get('isNew')) {
      type.destroyRecord();
      this.model.removeObject(type);
      this.flashes.addSuccess('Record has been removed!');
    } else {
      type.set('archivedAt', moment());
      type
        .save()
        .then(() => {
          this.flashes.addSuccess('Record has been removed!');
          this.send('refreshModel');
        })
        .catch(() => {
          this.flashes.addError('There was an error removing this record.');
        });
    }
  }

  @action
  rollbackPaymentType(type) {
    type.rollbackAttributes();
    this.send('refreshModel');
  }

  @action
  savePaymentType(type) {
    type.validate().then(({ validations }) => {
      const errors = validations.get('errors');

      if (errors.length > 0) {
        this.set('showValidation', true);
        const errorOutput = errors.map((m) => m.message).join(', ');
        this.flashes.addError(errorOutput);
        this.send('refreshModel');
      } else {
        this.set('showValidation', false);

        type
          .save()
          .then(() => {
            this.flashes.addSuccess('Payment type successfully saved!');
          })
          .catch(() => {
            this.flashes.addError(
              'Sorry, something went wrong. Please try again.'
            );
          });
      }
    });
  }

  @action
  setAccount(type, accountId) {
    type.account = this.store.peekRecord('account', accountId);
  }
}
