import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedIndexRoute extends Route {
  @service session;
  @service router;

  redirect() {
    const hasAcceptedTerms = this.session.currentUser.agreedToTermsOfUse;
    const hasAcceptedPrivacy = this.session.currentUser.agreedToPrivacy;

    if (hasAcceptedTerms && hasAcceptedPrivacy) {
      this.router.transitionTo('authenticated.dashboard');
    } else {
      this.router.transitionTo('authenticated.agree-to-legal');
    }
  }
}
