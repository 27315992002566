import Controller from '@ember/controller'
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking'

export default class AdminLocationsTaxesController extends Controller {
  @service flashes;
  @service session;
  @service store;

  @controller('authenticated/admin/locations/edit') edit;

  @tracked isUpdating = false;
  @tracked newLocalRate = null;
  @tracked newStateRate = null;

  @action
  open(){
    this.newLocalRate = this.model.localSalesTaxRate || 0;
    this.newStateRate = this.model.stateSalesTaxRate || 0;
    this.isUpdating = true;
  } 

  @action
  cancel(){
    this.newLocalRate = null;
    this.newStateRate = null;
    this.isUpdating = false;
  } 

  @action
  async update(event){
    const localRate = parseFloat(this.newLocalRate);
    const stateRate = parseFloat(this.newStateRate);
    const rates = await this.model.salesTaxRates;

    if (localRate != parseFloat(this.model.localSalesTaxRate)) {
      await this.store.createRecord(
        'v2/salesTaxRate', {
          rate: localRate,
          taxType: 'local',
          location: this.model
        }
      )
      .save()
      .then((newLocalRecord) => {
        rates.pushObject(newLocalRecord);
        this.model.localSalesTaxRate = localRate;
      })
      .catch(() => {
        this.flashes.addError('An error has occured.');
      });
    }

    if (stateRate != parseFloat(this.model.stateSalesTaxRate)) {
      await this.store.createRecord(
        'v2/salesTaxRate', {
          rate: stateRate,
          taxType: 'state',
          location: this.model
        }
      )
      .save()
      .then((newStateRecord) => {
        rates.pushObject(newStateRecord);
        this.model.stateSalesTaxRate = stateRate;
      })
      .catch(() => {
        this.flashes.addError('An error has occured.');
      });
    }

    this.save(event);
    this.isUpdating = false;
  }

  @action
  save(event) {
    this.edit.save(event);
  }
}