import { set } from '@ember/object';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default class KasesRoute extends Route {
  @service api;

  @action
    generateDetailedStatement(kase) {

      this.api.json
          .get(
            'detailed_statement_of_accounts',
            {
              params: {
                kase_id: kase.id,
                uuid: kase.uuid
              }
            }
          )
          .then(response => {
            if (!response.ok) {
              this.send('flashError', 'Oops, something went wrong! Please try again.');
            }
          });

      this.refresh(); // get new uuid
    }

    @action
    generatePrintKase(kase) {
      this.api.json
          .get(
            `record_of_funerals/${kase.id}.docx`,
            {
              params: {
                uuid: kase.uuid
              }
            }
          )
          .then(response => {
            if (!response.ok) {
              this.send('flashError', 'Oops, something went wrong! Please try again.');
            }
          });

      this.refresh(); // get new uuid
    }

    @action
    generateStatement(selection) {
      set(selection, 'statementLastGenerated', moment());
      selection.save();

      this.api.json
          .get(
            'statement_of_accounts',
            {
              params: {
                selection_id: selection.id,
                uuid: selection.uuid
              }
            }
          )
          .then(response => {
            if (!response.ok) {
              this.send('flashError', 'Oops, something went wrong! Please try again.');
            }
          })

      this.refresh(); // get new uuid
    }
}
