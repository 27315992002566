import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class KasesManageChainOfCustodyPersonalEffects extends Controller {
  @service flashes;
  @service store;
  @tracked showValidation = false;
  @tracked newPersonalEffect;

  get kase() {
    return this.model;
  }

  get personalEffects() {
    return this.kase.personalEffects.filter(pe => {
      return !pe.isNew;
    });
  }

  get lastAudit() {
    return this.kase.custodyAudits.lastObject;
  }

  handleRemoveEffectSuccess() {
    this.flashes.addSuccess('Personal Effect was successfully removed.');
  }

  handleSaveSuccess() {
    this.flashes.addSuccess('Personal Effect was successfully saved.');
  }

  handleError() {
    this.flashes.addError(
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com'
    );
  }

  resetNewPersonalEffect() {
    this.set(
      'newPersonalEffect',
      this.store.createRecord('v2/personal-effect', {
        kase: this.kase,
      })
    );
  }

  @action
  createPersonalEffect(personalEffect) {
    this.send('savePersonalEffect', personalEffect);
    this.resetNewPersonalEffect();
  }

  @action
  savePersonalEffect(personalEffect) {
    personalEffect
        .save()
        .then(() => {
          this.handleSaveSuccess();
        })
        .catch(() => {
          this.handleError();
        });
  }

  @action
  removePersonalEffect(personalEffect) {
    personalEffect.destroyRecord();
  }

  @action
  revertPersonalEffect(personalEffect) {
    personalEffect.rollbackAttributes();
  }

  @action
  revertNewPersonalEffect(personalEffect) {
    this.send('revertPersonalEffect', personalEffect);
    this.resetNewPersonalEffect();
  }
}
