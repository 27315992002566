import Route from '@ember/routing/route';
import { action } from '@ember/object';
import RSVP from 'rsvp';

export default class AuthenticatedKasesManageEditCremationAuthorizationRoute extends Route {
  layout = 'custom-aside';

  model() {
    const kase = this.modelFor('authenticated.kases.manage');

    const disposition = this.store
      .query('v2/disposition', {
        kase_id: kase.id,
        include: 'cremation-place,cremation-place.address,place,place.address'
      })
      .then(dispositions => dispositions.firstObject);

    const cremAuth = this.store
      .query('v2/cremation-authorization', {
        kase_id: kase.id,
        include: 'events,events.place,events.place.address'
      })
      .then(cremAuths => {
        if (cremAuths && cremAuths.length) {
          return Promise.resolve(cremAuths.firstObject);
        } else {
          return this.store.findRecord('v2/kase', kase.id).then((v2kase) => {
            return this.store
              .createRecord('v2/cremation-authorization', { kase: v2kase })
              .save();
          });
        }
      });

    // Once esigned-cremation-authorization is on v2, these can be included in
    // the call to cremation-authorization above
    const esignedAuths = cremAuth.then(cremAuth => {
      return this.store
        .query('esigned-cremation-authorization', {
          cremation_authorization_id: cremAuth.id
        });
    });

    const events = this.store.query('v2/event', { kase_id: kase.id });

    return RSVP.hash({
      kase,
      disposition,
      cremAuth,
      events,
      esignedAuths
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setProperties(model);

    if (!model.esignedAuths.length) {
      controller.buildNewEsignedAuth();
    }
  }

  @action
  willTransition() {
    if (this.controller.cremAuth.get('hasDirtyAttributes')) {
      this.controller.save();
    }
  }

  @action
  reload() {
    this.refresh();
  }
}
