import Component from '@ember/component';
import { computed, set } from '@ember/object';
import moment from 'moment';

export default Component.extend({
  startsAt: moment()
    .subtract(30, 'day')
    .toISOString(),
  endsAt: moment().toISOString(),

  organization_location_id: null,

  reportLink: computed(
    'startsAt',
    'endsAt',
    'organization_location_id',
    'uuid',
    {
      get() {
        const params = new URLSearchParams({
          organization_location_id: this.organization_location_id,
          starts_at: this.startsAt,
          ends_at: this.endsAt,
          uuid: this.uuid
        }).toString();
        return `/report/detailed_taxable_items?${params}`;
      }
    }
  ),

  fileUrl: computed('uuid', function() {
    return `#/generated_document/${this.uuid}`;
  }),

  actions: {
    setDate(attr, value) {
      set(this, attr, value);
    }
  }
});
