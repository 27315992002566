import { equal } from '@ember/object/computed';
import Model, { belongsTo, attr } from '@ember-data/model';
import { modelAction, resourceAction } from 'ember-custom-actions';

export default Model.extend({
  user: belongsTo('user', { async: true }),
  event: belongsTo('event', { async: true }),
  role: attr('string'),
  notificationExpiredAt: attr('moment-datetime'),
  notifiedAt: attr('moment-datetime'),
  respondedAt: attr('moment-datetime'),
  status: attr('string'),

  manuallyAssigned: equal('status', 'assigned'),
  inviteIsPending: equal('status', 'pending'),
  inviteAccepted: equal('status', 'accepted'),
  inviteDeclined: equal('status', 'declined'),
  inviteExpired: equal('status', 'expired'),

  notify: modelAction('notify', { method: 'POST', pushToStore: true }),
  roles: resourceAction('search_role', { method: 'GET', pushToStore: false }),

  get statusBadgeColor() {
    switch (this.status) {
      case 'accepted':
        return 'green';
      case 'declined':
      case 'expired':
        return 'red';
      default:
        return 'yellow';
    }
  },
});
