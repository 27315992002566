import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import config from '../../config/environment';
import Controller from '@ember/controller';

const isTesting = config.environment === 'test';

export default class AuthenticatedAgreeToLegalController extends Controller {
  @service router;
  
  @action
  acceptLegal() {
    this.model.save().then(() => {
      this.router.transitionTo('authenticated.dashboard');
    });
  }

  @action
  declineLegal() {
    this.session.invalidate().then(() => {
      if (isTesting) {
        this.router.transitionTo('login');
      }
    });
  }
}
