import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminUsersEditOverviewRoute extends Route {
  @service session;
  @service store;
  @service router;

  model() {
    return this.modelFor('authenticated.admin.users.edit');
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    let userRoles = await this.store.query('v2/userRole', {
      user_id: model.id
    });

    if (userRoles.length === 0) {
      userRoles = this.store.createRecord('v2/userRole', {
        user: await this.store.findRecord('v2/user', model.id)
      });

      await userRoles.save();
    } else {
      userRoles = userRoles.firstObject;
    }

    controller.setProperties({
      user: model,
      userRoles
    });
  }

  @action
  willTransition(transition) {
    if (this.currentModel.get('hasDirtyAttributes')) {
      if (confirm('You have unsaved changes. Are you sure?')) {
        this.currentModel.rollbackAttributes();
        return true;
      } else {
        transition.abort();
      }
    }
  }
}
