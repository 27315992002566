import Component from '@ember/component';
import { isBlank, isPresent } from '@ember/utils';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

export default Component.extend({
	classNameBindings: ['type'],

	store: service(),
	names: service(),
	session: service(),

	kase: null,
	kasePerson: null,

	contact: null,

	queryDeceased: null,
	queryArchived: null,
	clearOnSelect: false,
	showDeceased: false,
	contactLabel: null,
	showMaidenName: false,
	showTitle: false,
	isCondensed: false,
	showValidation: false,

	defaultTitle: null,

	type: null,
	redirect: null,
	redirectId: null,

	init() {
		this._super(...arguments);

		const type = this.type;
		const kase = this.kase;

		get(kase, 'kasePeople').then(kasePeople => {
			const kasePerson = kasePeople.findBy('role', underscore(type));

			if (kasePerson) {
				set(this, 'kasePerson', kasePerson);
				set(this, 'contact', get(kasePerson, 'person'));
			}
		});
	},

	showDeceasedBox: computed(
		'contact.content',
		'contactLabel',
		'showDeceased',
		function() {
			let label, contact, showDeceased;

			label = this.contactLabel;
			contact = this.contact;
			showDeceased = this.showDeceased;

			return isPresent(label) && isPresent(contact) && showDeceased;
		}
	),

	country: computed('contact.country', function() {
		const contact = this.contact;
		const clientCountry = this.session.currentClient.operating_country;
		if (get(contact, 'isNew')) {
			if (clientCountry) {
				return clientCountry;
			} else {
				return 'US';
			}
		} else {
			return contact.country;
		}
	}),

	setTitleDefault(contact) {
		const currentTitle = get(contact, 'namePrefix');
		if (isBlank(currentTitle)) {
			set(contact, 'namePrefix', this.defaultTitle);
		}
	},

	saveKasePerson(person) {
		this.store
			.createRecord('kasePerson', {
				kase: this.kase,
				role: underscore(this.type),
				person
			})
			.save()
			.then(kasePerson => {
				set(this, 'kasePerson', kasePerson);
				set(this, 'contact', person);
				this.sendAction('onContactSelected');
			});
	},

	saveKasePersonNewContact(person) {
		if (get(person, 'isNew')) {
			person.validate().then(({ validations }) => {
				if (get(validations, 'isValid')) {
					set(this, 'showValidation', false);
					set(person, 'country', this.country);
						person.save().then(person => {
						this.saveKasePerson(person);
					});

					this.sendAction('onSelectContact');
				} else {
					set(this, 'showValidation', true);
				}
			});
		} else {
			this.saveKasePerson(person);
			this.sendAction('saveContact');
		}
	},

	actions: {
		typeaheadSearch(value) {
			return this.store
                  .query('person', {
                    query: value,
                    exclude_id: this.excludeId,
                    deceased: this.queryDeceased,
                    archived: this.queryArchived
                  });
		},

		onSelected(item) {
			this.store.findRecord('person', item.id).then(person => {
				this.saveKasePersonNewContact(person);
			});
		},

		createNewContact(value) {
			const splitNames = this.names.splitNameIntoFirstAndLastName(value);
			const newContact = this.store.createRecord('person', splitNames);
			set(this, 'contact', newContact);

			if (this.showTitle) {
				this.setTitleDefault(newContact);
			}

			this.sendAction('onSelectContact', newContact);
		},

		cancelNewContact() {
			let contact = this.contact;

			contact = get(contact, 'content') || contact;

			if (get(contact, 'isNew')) {
				contact.rollbackAttributes();
			}

			set(this, 'kasePerson', null);
			set(this, 'contact', null);

			this.sendAction('onSelectContact', null);
		},

		saveNewContact(person, e) {
			// Method used both for
			// - selecting a contact (does not receive `e` param)
			// - creating a new contact (receives `e param)
			if (e) {
				e.target.disabled = true; // avoid double-click issues
			}

			this.saveKasePersonNewContact(person);
		},

		toggleDeceased(value) {
			let contact = this.contact;
			contact = get(contact, 'content') || contact;
			set(contact, 'deceased', value);
			const existingContact = !get(this, 'contact.isNew');

			if (existingContact) {
				contact.save();
				this.sendAction('saveContact');
			}
		},

		removeContact() {
			const kasePerson = this.kasePerson;
			if (kasePerson) {
				kasePerson.destroyRecord().then(() => {
					set(this, 'kasePerson', null);
					set(this, 'contact', null);

					this.sendAction('onSelectContact', null);
					this.sendAction('removeContact', this.type, get(this, 'contact.id'));
				});
			}
		}
	}
});
