import Controller from '@ember/controller';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';
import config from 'crakn/config/environment';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'esignature.signature': validator('presence', true),
  acknowledged: validator('inclusion', {
    in: [true],
    message: 'You must accept the acknowledgement.'
  })
});

export default Controller.extend(Validations, {
  loaded: false,
  hostUrl: `${config.host}`,
  showValidation: false,
  acknowledged: false,
  showingEsignatureForm: false,
  session: service(),

  // TODO: do something with wrong token 403
  actions: {
    onDocumentLoaded(event) {
      const iframe = event.target;
      const iframeContent = iframe.contentWindow.document;

      const iframeHeight = iframeContent.documentElement.scrollHeight;
      const iframeWidth =  iframeContent.documentElement.scrollWidth;

      iframe.height = iframeHeight;
      iframe.width = iframeWidth;

      // Setup Parent div to allow for decoration
      // width + 70 to account for sticky note
      // height + 40 for common paper margin
      iframe.offsetParent.height = iframeHeight + 25;
      iframe.offsetParent.width = iframeWidth + 70;

      iframeContent.querySelectorAll('.legal-sticky').forEach(el => {
        el.addEventListener('click', () => {
          this.send('viewEsignatureForm');
        });
      });
    },

    saveSignature() {
      this.validate().then(({ validations }) => {
        const errors = validations.get('errors');

        if (errors.length > 0) {
          set(this, 'showValidation', true);
        } else {
          set(this, 'showValidation', false);

          set(this, 'showValidation', false);

          const esignature = this.esignature;
          esignature.set('status', 'signed');

          esignature.save().then(esignature => {
            // The `esignatures.signed` route doesn't call the API, so we
            // just give it the name of the location through the session
            // object.
            this.session.set('locationName', esignature.get('locationName'));
            this.transitionToRoute('esignatures.signed');
          });
        }
      });
    },

    decline() {
      const esignature = this.esignature;
      esignature.set('status', 'declined');

      esignature.save().then(esignature => {
        // The `esignatures.signed` route doesn't call the API, so we
        // just give it the name of the location through the session
        // object.
        this.session.set('locationName', esignature.get('locationName'));
        this.transitionToRoute('esignatures.signed');
      });
    },

    viewEsignatureForm() {
      set(this, 'showingEsignatureForm', true);
    }
  }
});
