import { readOnly } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { isEmpty } from '@ember/utils';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';
import moment from 'moment';
import { tracked } from '@glimmer/tracking';

const Validations = buildValidations({
  purchaserId: validator('presence', true),
  locationId: validator('presence', true),
  'selection.contractDate': [validator('presence', true)]
});

export default Controller.extend(Validations, {
  flashes: service(),
  intl: service(),
  session: service(),
  api: service(),
  originalIsBadDebt: tracked({ value: false }),
  unpostingMode: false,

  sortBy: ['createdAt'],

  sortedPackages: computed('selection.packages.length', function() {
    return this.get('selection.packages').sortBy('createdAt');
  }),

  sortedCustomProducts: computed('selection.customProducts.length', function() {
    return this.get('selection.customProducts').sortBy('createdAt');
  }),

  sortedProducts: computed('selection.products.length', function() {
    return this.get('selection.products').sortBy('createdAt');
  }),

  purchaserId: readOnly('selection.purchaser.id'), // for validation
  locationId: readOnly('selection.location.id'), // for validation

  showDialog: false,
  showValidation: false,

  canPostSelection: computed(
    'selection.posted',
    'session.currentUser.canPostContractsAddOns',
    function() {
      const posted = get(this, 'selection.posted');
      const canPostContractsAddOns = get(
        this,
        'session.currentUser.canPostContractsAddOns'
      );

      return !posted && canPostContractsAddOns;
    }
  ),

  canUnpostSelection: computed(
    'selection.{posted,exported,adjustments,payments}',
    'session.currentUser.canUnpostContractsAddOns',
    function() {
      const canUnpostContractsAddOns = get(
        this,
        'session.currentUser.canUnpostContractsAddOns'
      );
      const exported = get(this, 'selection.exported');
      const posted = get(this, 'selection.posted');
      const noAdjustments = get(this, 'selection.adjustments.length') === 0;
      const noExportedPayments =
        get(this, 'selection.payments').findBy('exported', true) === undefined;
      const noRefunds = get(this, 'selection.refunds.length') === 0;

      return (
        canUnpostContractsAddOns &&
        !exported &&
        posted &&
        noExportedPayments &&
        noAdjustments &&
        noRefunds
      );
    }
  ),

  actions: {
    removePackage() {
      this.attrs.removePackage(this.model, 'packages');
    },

    revertPackage(packageRecord) {
      set(this, 'isEditing', false);
      packageRecord.rollbackAttributes();
    },

    savePackage() {
      set(this, 'isEditing', false);
      this.attrs.savePackage(this.model);
    },

    revertItem(product) {
      product.rollbackAttributes();
    },

    details_unpost() {
      if (dateInCoaLockedPeriod(this.selection, this.session, this.selection.get('contractDate'))) {
        this.flashes.addError(
          this.intl.t(
            'authenticated.kases.manage.financials.selection.errors.unlockContractPostingPeriod',
            { name: 'sale' }
          )
        );
        return;
      }

      if(this.session.currentClient.require_reason_for_unposting && !this.unpostingMode) {
        set(this, 'unpostingMode', true);
        return;
      } else {
        this.send('edit_unpost');
      }
    },
    saveUnpostReason() {
      const selection = this.selection;

      if(this.unpostingMode && this.session.currentClient.require_reason_for_unposting) {
        const reasonForUnposting = selection.reasonForUnposting || '';

        const trimmed_reasonForUnposting = reasonForUnposting.trim();

        if (trimmed_reasonForUnposting.length == 0) {
          alert(this.intl.t('authenticated.kases.manage.financials.selection.errors.missingReason'));
          return;
        } else {
          set(selection, 'reasonForUnposting', trimmed_reasonForUnposting);
        }
      }

      set(selection, 'posted', false);
      
      this.send('edit_unpost', this, function(detailController) {
        set(detailController, 'unpostingMode', false);
      });
    },
    cancelUnpost() {
      set(this, 'unpostingMode', false);
      this.selection.rollbackAttributes();
    },

    removeItem(item) {
      return item
        .destroyRecord()
        .then(() => {
          this.send('refreshModel');
          this.flashes.addSuccess(this.intl.t('authenticated.misc-sales.edit.details.messages.success.miscellaneousSaleUpdated'));
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    togglePullFromInventory(selectionProduct, checked) {
      selectionProduct.set('pullFromInventory', checked);
      selectionProduct.save().then(() => {
        if (checked) {
          this.flashes.addSuccess(this.intl.t('authenticated.misc-sales.edit.details.messages.success.pulledFromInventory'));
        } else {
          this.flashes.addSuccess(this.intl.t('authenticated.misc-sales.edit.details.messages.success.returnedFromInventory'));
        }
      })
      .catch(() => {
        this.flashes.addError();
      })
    },

    togglePullFromBeaconfpInventory(selectionProduct, checked) {
      selectionProduct.set('pullFromBeaconfpInventory', checked);
      selectionProduct.save().then(() => {
        if (checked) {
          this.flashes.addSuccess(this.intl.t('authenticated.misc-sales.edit.details.messages.success.beaconfpPullFromInventoryChecked'));
        } else {
          this.flashes.addSuccess(this.intl.t('authenticated.misc-sales.edit.details.messages.success.beaconfpPullFromInventoryNotChecked'));
        }
      })
      .catch(() => {
        this.flashes.addError();
      })
    },


    saveItem(item) {
      return item
        .save()
        .then(() => {
          this.send('refreshModel');
          this.flashes.addSuccess(this.intl.t('authenticated.misc-sales.edit.details.messages.success.miscellaneousSaleUpdated'));
        })
        .catch(() => {
          this.flashes.addError();
        });
    },

    createCustomProduct() {
      set(
        this,
        'customProduct',
        this.store.createRecord('selectionCustomProduct')
      );
    },

    selectKase(kase) {
      set(this, 'selection.kase', kase);
    },

    removeKase() {
      set(this, 'selection.kase', null);
    },

    validateAndSaveSelection(posting = false) {
      if (posting && dateInCoaLockedPeriod(this.selection, this.session, this.selection.get('contractDate'))) {
        this.flashes.addError(this.intl.t('authenticated.misc-sales.edit.details.errors.postingPeriodLocked'));
        return;
      }

      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          this.send('saveSelection', posting, this);
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    deleteSelection() {
      const selection = this.selection;

      set(selection, 'archivedAt', moment());
      selection
        .save()
        .then(() => {
          set(this, 'showDialog', false);
          this.flashes.addSuccess('Misc Sale successfully deleted!');
          this.transitionToRoute('authenticated.misc-sales.index');
        })
        .catch(() => {
          this.flashes.addError(
            'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
          );
        });
    },

    updateMiscSalesLocation(location) {
      const selection = this.selection;

      if (isEmpty(location)) {
        return;
      } else {
        set(selection, 'location', location);
        selection.save();
      }
    },

    setContractDate(date) {
      const selection = this.selection;

      selection.set('contractDate', date);

      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          selection
            .save()
            .then(() => {
              this.flashes.addSuccess(
                `"${get(selection, 'name')}" has been updated!`
              );
            })
            .catch(() => {
              this.flashes.addError();
            });
        } else {
          set(this, 'showValidation', true);
        }
      });
    },

    async resendToBeaconfp() {
      await this.api.json.post(`selections/${this.selection.id}/resend_to_beaconfp`, {});
    }
  }
});
