import SearchController from 'crakn/controllers/search';
import { action, set, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import config from 'crakn/config/environment';
import { buildUrl } from 'crakn/utils/url-builder';
import { v4 as uuidv4 } from 'uuid';
import { inject as service } from '@ember/service';


export default class AuthenticatedPaymentsTransactionsController extends SearchController {
  @service flashes;
  perPage = 10;
  queryParams = ['query', 'organizationLocationId', 'paymentTypeId', 'startsAt', 'endsAt', 'page', 'perPage'];

  @tracked query;
  @tracked organizationLocationId;
  @tracked paymentTypeId;
  @tracked paymentTypes; // Set in the route.
  @tracked startsAt;
  @tracked endsAt;
  @tracked uuid = uuidv4();

  get headers() {
    const headers = {};
    if (localStorage.getItem('sso_token') === null) {
      headers['X-CSRF-Token'] = this.session.data.authenticated.token;
    } else {
      headers['Authorization'] = localStorage.getItem('sso_token');
    }
    return headers;
  }

  @computed('uuid', 'organizationLocationId', 'paymentTypeId', 'startsAt', 'endsAt', 'query')
  get csvLink() {
    const params = {
      organization_location_id: this.organizationLocationId,
      payment_type_id: this.paymentTypeId,
      starts_at: this.startsAt,
      ends_at: this.endsAt,
      query: this.query,
      page: 1,
      per_page: 500000,
      report_uuid: this.uuid
    };

    return buildUrl(`${config.host}/api/v2/transactions.csv`, params);
  }

  get downloadLink() {
    return `#/generated_document/${this.uuid}`;
  }

  @action
  generateDownload() {
    fetch(this.csvLink, { method: 'GET', credentials: 'include', headers: this.headers})
    .then((response) => {
      if (!response.ok) {
        this.flashes.addError(
          'Oops, something went wrong! Please try again.'
        );
      }
      this.uuid = uuidv4();
    });
  }

  @action
  setDate(attr, value) {
    if (value) {
      set(this, attr, value.toISOString());
    } else {
      set(this, attr, null);
    }
  }
}
