import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AuthenticatedKasesManageFinancialsSelectionEditPackageRoute extends Route {
  model(params) {
    return this.store.findRecord('v2/selection-package', params.selection_package_id);
  }

  async setupController(controller, model) {
    super.setupController(...arguments);
    const adminPackage = await model.package;
    const generalPriceLists = await this.store.query('v2/general-price-list', { package_id: adminPackage.id });

    controller.set('generalPriceListId', generalPriceLists.firstObject.id);
  }

  @action
  refreshModel() {
    this.refresh();
  }

  @action
  async redirectToSelection(model) {
    const kase = this.modelFor('authenticated.kases.manage');
    const kaseFinancial = await kase.kaseFinancial;

    this.transitionTo('authenticated.kases.manage.financials.selection', {
      queryParams: { selection_id: model.selection.get('id') }
    });
    kaseFinancial.reload();
  }
}
