import Model, { belongsTo, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'account.id': validator('presence', {
    presence: true,
    message: 'The Account field can\'t be blank'
  }),
  'name': validator('presence', {
    presence: true,
    message: 'The Name field can\'t be blank'
  }),
});

export default class DiscountTypeModel extends Model.extend(Validations) {
  @attr('moment-datetime', { defaultValue: null }) archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('string') name;
  @attr('string') netsuiteId;
  @attr('boolean', { defaultValue: false }) taxable;

  @belongsTo('account', { async: true }) account;
  @belongsTo('chartOfAccounts', { async: true }) chartOfAccounts;
  @belongsTo('client', { async: true }) client;
}
