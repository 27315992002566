import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknGlobalSettingsIndexRoute extends Route {
  @service session;

  model(params) {
    return this.store.query('v2/tms-setting', {
      page: params.page,
      per_page: params.per_page,
    });
  }
}
