import Route from '@ember/routing/route';
import moment from 'moment';

export default Route.extend({
  queryParams: {
    page: {
      refreshModel: true
    },
    query: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    }
  },

  model(params) {
    return this.store.query('chartOfAccounts', {
      query: params.query,
      page: params.page,
      archived: params.archived
    });
  },

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams =
        'authenticated.admin.manage.financial-configurations.index' === transition.targetName;

      if (resetParams) {
        controller.setProperties({
          page: 1,
          query: null,
          archived: false
        });
      }
    }
  },

  actions: {
    toggleArchived(chartOfAccounts) {
      if (chartOfAccounts.get('archivedAt')) {
        chartOfAccounts.set('archivedAt', null);
      } else {
        chartOfAccounts.set('archivedAt', moment());
      }

      chartOfAccounts.save().then(() => {
        this.refresh();
      });
    }
  }
});
