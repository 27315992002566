import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class KaseTasksList extends Component {
  @service api;
  @service session;
  @service store;

  callListAction(action, list) {
    const id = this.args.kase.id;
    const initials = this.session.currentUser.initials;

    this.api.json
        .post(
          `kase_tasks/${action}`,
          {
            params: {
              kase_id: id,
              list: list,
              initials: initials
            }
          }
        )
        .then(response => {
          this.store.pushPayload('kase-task', response.parsedJson);
        });
  }

  @action
  listAction(nameName, list) {
    if(nameName == 'complete') {
      this.callListAction('complete', list);
    } else if(nameName == 'yes') {
      this.callListAction('enable_list', list);
    } else if(nameName == 'no') {
      this.callListAction('disable_list', list);
    } else if(nameName == 'uncheck') {
      this.callListAction('uncheck_list', list);
    }
  }
}
