import Route from '@ember/routing/route';

/**
 * A base route to include a searchable index
 * 
 * ## Usage
 * This route is meant to be used in conjunction with the Search controller (app/controllers/search).
 * The modelType must be set to properly query.
 * 
 * ### Basic
 * The basic usage of the route allows for automatic querying including `query`, `page`, `perPage`, and `archived`* 
 *
 * ```
 * import SearchRoute from 'crakn/routes/search
 * 
 * export default class ExampleRoute extends SearchRoute {
 *   modelType = 'example';
 *   routeRegex = /authenticated.examples/
 * }
 * ```
 * 
 * ### Additional query params
 * 
 * The query params are overridable in child class, but you will need to include the previous params as well.
 * 
 * **NOTE** You will also have to include these in your controller if you want automatic query updates.
 * 
 * ```
 * import SearchRoute from 'crakn/routes/search
 * 
 * export default class ExampleRoute extends SearchRoute {
 *   queryParams = {
 *     query: {
 *       refreshModel: true
 *     },
 * 
 *     page: {
 *       refreshModel: true
 *     },
 * 
 *     perPage: {
 *       refreshModel: true
 *     },
 * 
 *     archived: {
         type: 'boolean',
         refreshModel: true
 *     },
 * 
 *     locationId: {
 *       refreshModel: true
 *     },
 *   }
 *    
 *   model(params) {
 *     return this.store.query(this.modelType, {
 *       query: params.query || this.defaultQuery,
 *       page: params.page || this.defaultPage,
 *       perPage: params.perPage || this.defaultPerPage,
 *       archived: params.archived || this.defaultArchived,
 *       locationId: params.locationId,
 *     });
 *   }
 * }
 */

export default class SearchRoute extends Route {
  queryParams = {
    query: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    archived: {
      type: 'boolean',
      refreshModel: true
    }
  }

	/**
	 * the name of the model to use. 
   * 
   * Not used if model function is overriden.
	 * @type {string}
   * @private
	 */
  modelType = null;

	/**
	 * the regex of the base route and its children
   * 
   * Used in setupController and resetController.  If a transitioning path does not match this regex
   * then the controller is reset to default querying params
	 * @type {regex}
   * @private
	 */
  routeRegex = null;

	/**
	 * the default value for page
   * 
   * Used in setupController and resetController
	 * @type {number}
   * @private
	 */
  defaultPage = 1;

	/**
	 * the default value for pages
   * 
   * Used in setupController and resetController
	 * @type {number}
   * @private
	 */
  defaultPages = 1;

	/**
	 * the default value for perPage
   * 
   * Used in setupController and resetController
	 * @type {number}
   * @private
	 */
  defaultPerPage = 10;

	/**
	 * the default value for query
   * 
   * Used in setupController and resetController
	 * @type {string}
   * @private
	 */
  defaultQuery = null;

	/**
	 * the default value for archived
   * 
   * Used in setupController and resetController
	 * @type {boolean}
   * @private
	 */
  defaultArchived = false;

	/**
	 * the value to pass along in the param `include` for the index query
   * 
	 * @type {string}
   * @private
	 */
  queryIncluded = null;

  /**
   * set to query the set modelType
   * 
   * @param {*} params - check ember docs
   * @returns 
   */
  model(params = {}) {
    const queryParams = {};
    
    if (params.query ||this.defaultQuery) {
      queryParams.query = params.query || this.defaultQuery;
    }

    if (params.page ||this.defaultPage) {
      queryParams.page = params.page || this.defaultPage;
    }
    
    if (params.perPage ||this.defaultPerPage) {
      queryParams.perPage = params.perPage || this.defaultPerPage;
    }
    
    if (params.archived != undefined ||this.defaultArchived != undefined ) {
      queryParams.archived = params.archived || this.defaultArchived;
    }
    
    if (this.queryIncluded) {
      queryParams.included = this.queryIncluded;
    }

    return this.store.query(this.modelType, { ...params, ...queryParams });
  }
  
  /**
   * sets the default controller params for searching.
   * 
   * @param {*} controller - check ember docs
   */
  setupController(controller, model, { to, from }) {
    super.setupController(...arguments);

    if (!to || !from || to.name != from.name) {
      this.setControllerDefaults(controller, model);
    }
  }

  /**
   * reset controller is set to clear the query controller properties back to a default state
   * 
   * @param {*} controller - check ember docs
   * @param {*} isExiting - check ember docs
   * @param {*} transition - check ember docs
   */
  resetController(controller, isExiting, transition) {
    super.resetController(...arguments);

    if (isExiting && this.routeRegex && !this.routeRegex.test(transition.targetName)) {
      this.setControllerDefaults(controller);
    }
  }

  /**
   * set the default controller properties
   * 
   * @param {*} controller - the current controller object
   * @param {*} model - the current model object
   */
  setControllerDefaults(controller, { meta = {}, query = {} } = {}) {
    controller.setProperties({
      page: meta.page || this.defaultPage,
      pages: meta.pages || this.defaultPages,
      perPage: meta.per || this.defaultPerPage,
      query: query.query || this.defaultQuery,
      internalQuery: query.query || this.defaultQuery,
      archived: query.archived || this.defaultArchived
    });
  }
}