import { helper as buildHelper } from '@ember/component/helper';

function convertCustomFieldType(field) {
  switch (field[0]) {
    case 'input':
      return 'Text'
    case 'select':
      return 'Drop Down'
    case 'boolean':
      return 'Checkbox'
    case 'date':
      return 'Date Picker'
    default:
      break;
  }
}

export default buildHelper(convertCustomFieldType);
