import { alias, lte } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, get } from '@ember/object';
import moment from 'moment';

export default Component.extend({
  classNames: 'calendar__event',
  attributeBindings: ['style'],

  timeslot: null,
  eventKey: null,
  totalEvents: null,
  parentEvents: null,
  childEvents: null,

  deceased: alias('event.kase.deceased'),

  isShortEvent: lte('event.duration', 60),

  style: computed(
    'childEvents',
    'event.{duration,startsAt}',
    'eventKey',
    'parentEvents',
    'totalEvents',
    function() {
      const ret = [];
      const startsAt = get(this, 'event.startsAt');
      const endsAt = get(this, 'event.endsAt');

      const eventKey = this.eventKey;
      const totalEvents = this.totalEvents;
      const parentEvents = this.parentEvents;
      const childEvents = this.childEvents;
      const minutes = startsAt ? startsAt.minutes() : 0;

      // Determine width of event
      let width = (100 / (parentEvents + totalEvents)) * 1.7;

      // Determine left position of event
      const left =
        (parentEvents + eventKey) *
        (100 / (parentEvents + totalEvents + childEvents));

      // Trim event if it is too wide
      if (width + left > 100) {
        width = width - ((width + left) % 100);
      }

      // Trim event if it is too tall
      if (get(this, 'event.duration')) {
        if (endsAt && endsAt.isAfter(startsAt, 'day')) {
          const midnight = moment(get(this, 'timeslot.startsAt'))
            .endOf('day')
            .diff(startsAt, 'minutes');

          ret.push(`height: ${(midnight / 60) * 100}%`);
        } else {
          ret.push(`height: ${(get(this, 'event.duration') / 60) * 100}%`);
        }
      }

      // Add the width, top and left positions
      ret.push(`width: ${width}%`);
      ret.push(`left: ${left}%`);
      ret.push(`top: ${(minutes / 60) * 100}%`);

      return ret.join(';').htmlSafe();
    }
  ),

  click() {
    this.sendAction('onSelect', this.event);
  }
});
