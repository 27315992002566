import { get } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';

export default Route.extend({
  model() {
    const model = this.modelFor('authenticated.kases.manage.financials');

    return RSVP.hash({
      model,
      selections: get(model, 'selections')
    });
  },

  redirect(model) {
    const contract = get(model, 'selections').filterBy('isContract', true)[0];

    if (contract && !get(contract, 'posted') && get(model, 'selections.length') <= 1) {
      this.transitionTo('authenticated.kases.manage.financials.selection', {
        queryParams: { selection_id: get(contract, 'id') }
      });
    }
  },

  setupController(controller, models) {
    const selections = get(models, 'selections');

    controller.set('contract', selections.filterBy('isContract', true)[0]);
    controller.set('addons', selections.filterBy('isContract', false));
    controller.set('kaseFinancial', get(models, 'model.kaseFinancial'));
    controller.setProperties(models);
  }
});
