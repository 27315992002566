import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{!-- template-lint-disable modifier-name-case --}}\n{{#if @singleFile}}\n  <input\n      accept={{@acceptedFileTypes}}\n      class=\"file-input d--xs--none single-file\"\n      id={{this.inputId}}\n      type=\"file\"\n      {{@queue.selectFile}}>\n{{else}}\n  <input\n      accept={{@acceptedFileTypes}}\n      class=\"file-input d--xs--none multi-file\"\n      id={{this.inputId}}\n      multiple\n      type=\"file\"\n      {{@queue.selectFile}}>\n{{/if}}\n", {"contents":"{{!-- template-lint-disable modifier-name-case --}}\n{{#if @singleFile}}\n  <input\n      accept={{@acceptedFileTypes}}\n      class=\"file-input d--xs--none single-file\"\n      id={{this.inputId}}\n      type=\"file\"\n      {{@queue.selectFile}}>\n{{else}}\n  <input\n      accept={{@acceptedFileTypes}}\n      class=\"file-input d--xs--none multi-file\"\n      id={{this.inputId}}\n      multiple\n      type=\"file\"\n      {{@queue.selectFile}}>\n{{/if}}\n","moduleName":"crakn/components/g/crakn/file-upload/file-input.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/file-upload/file-input.hbs"}});
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';

export default class GCraknFileUploadFileInput extends Component {
  get inputId() {
    return this.args.id || 'file-input-' + guidFor(this);
  }

}
