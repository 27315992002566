import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default Route.extend({
  api: service(),
  session: service(),

  model() {
    const store = this.store;
    return store.queryRecord('report-config', {
      client_id: this.session.currentClient.id
    });
  },

  actions: {
    generateReport(reportLink) {
      this.api.json
          .get(reportLink)
          .then(response => {
            if (!response.ok) {
              this.send('flashError', 'Oops, something went wrong! Please try again.');
            }
            this.refresh();
          });
    }
  }
});
