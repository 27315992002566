import { helper as buildHelper } from '@ember/component/helper';

export function kaseStatus(status) {
  if (status[0] === 'preneed') {
    return 'Preneed';
  } else if (status[0] === 'pending') {
    return 'Pending';
  } else if (status[0] === 'in_progress') {
    return 'In Progress';
  } else if (status[0] === 'completed') {
    return 'Completed';
  } else if (status[0] === 'lost') {
    return 'Lost';
  } else {
    return 'Archived';
  }
}

export default buildHelper(kaseStatus);
