import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { set } from '@ember/object';

export default class AdminFinancialConfigurationsNewController extends Controller {
  @service flashes;
  @service intl;
  @service router;

  showValidation = false;

  handleSaveSuccess(model) {
    this.flashes.addSuccess(this.intl.t('authenticated.admin.manage.financial-configurations.new.messages.success'));
    this.router.transitionTo(
      'authenticated.admin.financial-configurations.edit.overview',
      model
    );
  }

  handleSaveError() {
    this.flashes.addError(this.intl.t('authenticated.admin.manage.financial-configurations.new.messages.error'));
  }

  @action
  save(coa) {
    coa.validate().then(({ validations }) => {
      if (validations.isValid) {
        set(this, 'showValidation', false);
        coa
          .save()
          .then(() => {
            this.handleSaveSuccess(coa);
          })
          .catch(() => {
            this.handleSaveError();
          });
      } else {
        set(this, 'showValidation', true);
      }
    });
  }
}
