import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminTributePayPaymentsRoute extends Route {
  @service router;
  @service session;

  beforeModel() {
    if ((!this.session.currentUser.canSetupTributePay && !this.session.currentUser.isAdmin) || !this.session.currentClient.can_view_tribute_pay) {
      this.router.transitionTo('authenticated.dashboard');
    }
  }
}
