import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'crakn/config/environment';

export default class AuthenticatedAdminManageTributeVideoController extends Controller {
  @service api;
  @service flashes;
  @service location;
  @service session;
  @service store;

  @tracked locationId;

  get locations() {
    return this.location.allSorted.filter((location) => {
      const { city, email, hasTributeVideoIntegration, line1, name, state, zipCode } = location;

      return city && email && hasTributeVideoIntegration && line1 && name && state && zipCode;
    });
  }

  postRequestFormBuildAndSubmission(payload) {
    const { callbacks, funeral_home, token, user } = payload;
    const action = 'DASHBOARD';

    const form = document.createElement('form');
    form.target = '_blank';
    form.method = 'POST';
    form.action = config.tributeVideoApiUrl;

    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'token';
    input.setAttribute('value', token);
    
    const textarea = document.createElement('textarea');
    textarea.name = 'payload';
    textarea.style.display = 'none';
    textarea.innerHTML = JSON.stringify({
      action,
      callbacks,
      funeral_home,
      user
    });
    
    form.appendChild(input);
    form.appendChild(textarea);
    document.body.appendChild(form);

    form.submit();
    form.remove();
  }

  @action
  routeToTributeVideoDashboard() {
    this.api.json
        .get('/tribute_video/dashboard', { params: { location_id: this.locationId }})
        .then((response) => {
          this.postRequestFormBuildAndSubmission(response.parsedJson.payload);
        })
        .catch(() => {
          this.flashes.addError('Oops! There was an error when attempting to access Tribute Video.');
        });
  }

  @action
  onWillDestroy() {
    this.locationId = null;
  }
}
