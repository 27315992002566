import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Bugsnag from '@bugsnag/js';

export default class AdminCemsitesNewController extends Controller {
  @service api;
  @service store;
  @service flashes;
  @service intl;
  @service session;
  @service router;
  @tracked cemsitesPlaces = null;
  @tracked showCemsitesResults = false;
  @tracked craknCemsitesPlace = null;

  addBugsnagError(error, name) {
    Bugsnag.notify(error, function (event) {
      event.addMetadata('tempmeta', { source: name })
    });
  }

  @action
  requestCemsitesPlaces() {
    this.api.json
      .post('cemsites/request_places', {
        params: { cemsites_client_uuid: this.cemsitesClientUuid },
      })
      .then((response) => {
        if (!response.ok) {
          this.flashes.addError(this.intl.t('authenticated.admin.manage.cemsites.new.error.uuid'));
          return;
        }

        this.cemsitesPlaces = response.parsedJson;
        this.showCemsitesResults = true;
      });
  }

  @action
  onSelectionChange(place) {
    this.craknCemsitesPlace = place;
  }

  @action
  onClearPlace() {
    this.craknCemsitesPlace = null;
  }

  @action
  async linkCemsitesPlace(blogId, cemsitesPlaceName) {
    try {
      const integrationService = this.store
      .createRecord('integrationService', {
        name: 'cemsites',
        cemsites_client_uuid: this.cemsitesClientUuid,
        cemsites_blog_id: blogId,
        cemsites_place_name: cemsitesPlaceName
      });

      await integrationService.save().catch((error) => {
        this.addBugsnagError(error, 'linkCemsitesPlace().integrationService.save');
      });
      const v2IntergrationService = await this.store.findRecord('v2/integration-service', integrationService.id);

      const placeService = this.store.createRecord('v2/place-integration-service', {
        place: this.craknCemsitesPlace,
        integrationService: v2IntergrationService
      });

      await placeService.save().catch((error) => {
        this.addBugsnagError(error, 'linkCemsitesPlace().placeService.save');
      });

      this.flashes.addSuccess('CemSites place successfully linked!');
      this.router.transitionTo('authenticated.admin.manage.cemsites');
    } catch (error) {
      this.flashes.addError('Something went wrong.');
      this.addBugsnagError(error, 'linkCemsitesPlace()');
    }
  }
}
