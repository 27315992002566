import SearchController from 'crakn/controllers/search';
import { action } from '@ember/object';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { sort } from '@ember/object/computed';

export default class CraknDemosIndexController extends SearchController {
  @service api;
  @service flashes;
  @service store;
  @service session;
  @service router;

  @tracked addMode = false;

  sorting = ['name:asc'];
  @sort('model', 'sorting') sortedContent;

  queryParams = ['sort_by', 'order'];
  order = 'ASC';
  sort_by = null;
  showValidation = false;

  timeFrames = [
    { name: '8 Hours from Now', value: 28800 },
    { name: '1 Day from Now', value: 86400 },
    { name: '3 Days from Now', value: 259200 },
    { name: '7 Days from Now', value: 604800 },
    { name: '30 Days from Now', value: 2592000 },
    { name: '60 Days from Now', value: 5184000 },
    { name: '120 Days from Now', value: 10368000 }
  ];

  @computed('addMode')
  get demoClientRequest() {
    return this.createNewDemoClientRequest();
  }

  createNewDemoClientRequest() {
    const defaultTimeFrame = this.timeFrames[0].value;
    return this.store.createRecord('nonStandard/demoClientRequest', {
      id: Date.now(),
      expiresIn: defaultTimeFrame
    });
  }

  handleSaveSuccess() {
    this.flashes.addSuccess('Client saved!');
    this.transitionToRoute('authenticated.crakn.clients');
  }

  handleSaveError() {
    this.flashes.addError('Oops! There was an error saving this client.');
  }

  @action
  toggle() {
    this.addMode = !this.addMode;
  }

  @action
  requestNew(demoClientRequest) {
    demoClientRequest.validate().then(({ validations }) => {
      if (get(validations, 'isValid')) {
        set(this, 'showValidation', false);
        demoClientRequest.save().then(() => {
          demoClientRequest.unloadRecord();
          set(this, 'demoClientRequest', this.createNewDemoClientRequest());
          set(this, 'addMode', false);
        });
      } else {
        set(this, 'showValidation', true);
      }
    });
  }

  @action
  switchClient(client) {
    this.api.json
      .post('clients/switch', { body: { id: client.id } })
      .then((response) => {
        if (response.ok) {
          this.router.transitionTo('/logout');
        } else {
          this.flashes.addError(
            'Oops, something went wrong! Please try again.'
          );
        }
      });
  }
}
