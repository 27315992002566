// Taken from HumanParser - https://github.com/chovy/humanparser

export function parseName(name) {
  const salutations = [
    'mr',
    'master',
    'mister',
    'mrs',
    'miss',
    'ms',
    'dr',
    'prof',
    'rev',
    'fr',
    'judge',
    'honorable',
    'hon',
    'tuan',
    'sr',
    'srta',
    'br',
    'pr',
    'mx',
    'sra'
  ];
  const suffixes = [
    'i',
    'ii',
    'iii',
    'iv',
    'v',
    'senior',
    'junior',
    'jr',
    'sr',
    'phd',
    'apr',
    'rph',
    'pe',
    'md',
    'ma',
    'dmd',
    'cme'
  ];
  const compound = [
    'vere',
    'von',
    'van',
    'de',
    'del',
    'della',
    'der',
    'di',
    'da',
    'pietro',
    'vanden',
    'du',
    'st.',
    'st',
    'la',
    'lo',
    'ter',
    'bin',
    'ibn',
    'te',
    'ten',
    'op',
    'ben'
  ];

  let parts = name.trim().split(/\s+/);
  const attrs = {};

  if (!parts.length) {
    return attrs;
  }

  if (parts.length === 1) {
    attrs.firstName = parts[0];
  }

  // handle suffix first always, remove trailing comma if there is one
  if (
    parts.length > 1 &&
    suffixes.includes(
      parts
        .slice(-1)[0]
        .toLowerCase()
        .replace(/\./g, '')
    )
  ) {
    attrs.suffix = parts.pop();
    parts[parts.length - 1] = parts.slice(-1)[0].replace(',', '');
  }

  // look for a comma to know we have last name first format
  const firstNameFirstFormat = parts.every(part => {
    return !part.includes(',');
  });

  if (!firstNameFirstFormat) {
    // last name first format
    // assuming salutations are never used in this format

    // tracker variable for where first name begins in parts array
    let firstNameIndex;

    // location of first comma will separate last name from rest
    // join all parts leading to first comma as last name
    const lastName = parts.reduce((lastName, current, index) => {
      if (!Array.isArray(lastName)) {
        return lastName;
      }
      if (!current.includes(',')) {
        lastName.push(current);
        return lastName;
      } else {
        current = current.replace(',', '');

        // handle case where suffix is included in part of last name (ie: 'Hearst Jr., Willian Randolph')
        if (suffixes.includes(current.toLowerCase().replace(/\./g, ''))) {
          attrs.suffix = current;
        } else {
          lastName.push(current);
        }

        firstNameIndex = index + 1;
        return lastName.join(' ');
      }
    }, []);

    attrs.lastName = lastName;

    const remainingParts = parts.slice(firstNameIndex);
    if (remainingParts.length > 1) {
      attrs.firstName = remainingParts.shift();
      attrs.middleName = remainingParts.join(' ');
    } else if (remainingParts.length) {
      attrs.firstName = remainingParts[0];
    }

    // create full name from attrs object
    const nameWords = [];
    if (attrs.firstName) {
      nameWords.push(attrs.firstName);
    }
    if (attrs.middleName) {
      nameWords.push(attrs.middleName);
    }
    nameWords.push(attrs.lastName);
    if (attrs.suffix) {
      nameWords.push(attrs.suffix);
    }
    attrs.fullName = nameWords.join(' ');
  } else {
    // first name first format
    if (
      parts.length > 1 &&
      salutations.includes(parts[0].toLowerCase().replace(/\./g, ''))
    ) {
      attrs.salutation = parts.shift();

      // if we have a salutation assume 2nd part is last name
      if (parts.length === 1) {
        attrs.lastName = parts.shift();
      } else {
        attrs.firstName = parts.shift();
      }
    } else {
      attrs.firstName = parts.shift();
    }

    if (!attrs.lastName) {
      attrs.lastName = parts.length ? parts.pop() : '';
    }

    // test for compound last name, we reverse because middle name is last bit to be defined.
    // We already know lastname, so check next word if its part of a compound last name.
    const revParts = parts.slice(0).reverse();
    const compoundParts = [];

    revParts.every(part => {
      const test = part.toLowerCase().replace(/\./g, '');

      if (compound.includes(test)) {
        compoundParts.push(part);

        return true;
      }

      // break on first non compound word
      return false;
    });

    // join compound parts with known last name
    if (compoundParts.length) {
      attrs.lastName = `${compoundParts.reverse()} ${attrs.lastName}`;

      // Native version of _.difference.
      // From https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore
      const difference = arrays => {
        return arrays.reduce((a, b) => a.filter(c => !b.includes(c)));
      };

      parts = difference([parts, compoundParts]);
    }

    if (parts.length) {
      attrs.middleName = parts.join(' ');
    }

    // remove comma like "<lastName>, Jr."
    if (attrs.lastName) {
      attrs.lastName = attrs.lastName.replace(',', '');
    }

    // save a copy of original
    attrs.fullName = name;
  }
  // console.log('attrs:', JSON.stringify(attrs));
  return attrs;
}

/*
export function parseFullestName(str) {
  let name = str;
  let names = [];

  // find fullname from strings like 'Jon and Sue Doyle'
  if (name.indexOf('&') > -1 || name.toLowerCase().indexOf(' and ') > -1) {
    names = name.split(/\s+(?:and|&)\s+/gi);

    // pluck the name with the most parts (first, middle, last) from the array.
    // will grab 'Sue Doyle' in 'Jon & Sue Anne Doyle'
    if (names.length) {
      name = names.sort((a, b) => {
        return b.split(/\s+/).length - a.split(/\s+/).length;
      })[0];
    }
  }

  return name;
}

export function parseAddress(str) {
  str = str.replace(/\n/gi, ', ');
  // 416 W. Manchester Blvd., Inglewood, CA  90301
  let parts = str.split(/,\s+/).reverse();
  let stateZip, city;
  let address = {};

  stateZip = parts[0].split(/\s+/);
  parts.shift();

  city = parts.shift();

  address.address = parts.reverse().join(', ');
  address.city = city;
  address.state = stateZip[0];
  address.zip = stateZip[1];
  address.fullAddress = str;

  return address;
}
*/
