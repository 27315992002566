import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';

export default class AuthenticatedAdminManageTasksController extends Controller {
  @service flashes;

  addMode = false;

  @action
  toggleAddMode() {
    this.toggleProperty('addMode');
  }

  @action
  saveTask(task) {
    if (task.get('hasDirtyAttributes')) {
      task.save().then(this.handleTaskSaveSuccess(task), this.handleError());
    }
  }

  @action
  saveTaskList(taskList) {
    if (taskList.get('hasDirtyAttributes')) {
      taskList
        .save()
        .then(this.handleTaskListSaveSuccess(taskList), this.handleError());
    }
  }

  @action
  saveNewTaskList(taskList) {
    taskList.save().then(() => {
      this.handleTaskListSaveSuccess(taskList);
      this.send('toggleAddMode');
    }, this.handleError);
  }

  @action
  deleteTask(task) {
    task.destroyRecord().then(() => {
      this.handleTaskDeleteSuccess(task);
    }, this.handleError);
  }

  @action
  deleteTaskList(taskList) {
    taskList.destroyRecord().then(() => {
      this.handleTaskListDeleteSuccess(taskList);
      }, this.handleError);
    }

  handleTaskSaveSuccess() {
    this.flashes.addSuccess('Task saved!');
  }

  handleTaskListSaveSuccess() {
    this.flashes.addSuccess('Task list saved!');
  }

  handleTaskDeleteSuccess() {
    this.flashes.addSuccess('Task deleted.');
  }

  handleTaskListDeleteSuccess() {
    this.flashes.addSuccess('Task list & tasks deleted.');
  }

  handleError() {
    this.flashes.addError(
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
    );
  }
}
