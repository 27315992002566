import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { saveChanges } from 'crakn/utils/jsonapi-saver';
import { tracked } from '@glimmer/tracking'
import { validator, buildValidations } from 'ember-cp-validations';
import Controller from '@ember/controller';

const Validations = buildValidations({
  'newFamilyPortalInvite.kase': validator('presence', true),
  'newFamilyPortalInvite.recipient.email': validator('presence', true)
});

export default class KasesManageEditFamilyPortalController extends Controller.extend(Validations) {
  @service flashes;
  @service intl;

  @tracked recipient = null;
  @tracked showValidation = false;
  familyPortalInvites = null;
  newFamilyPortalInvite = null;
  v2Kase = null;

  fetchV2Recipient(personId) {
    return this.store.findRecord('v2/person', personId);
  }

  createInvite() {
    this.newFamilyPortalInvite
      .save()
      .then(() => {
        this.recipient = null;
        this.resetFamilyPortalInvites();
        this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.family-portal.buttons.createNewInvite.successMessage'));
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.family-portal.buttons.createNewInvite.errorMessage'));
      });
  }

  resetNewFamilyPortalInvite() {
    this.set('newFamilyPortalInvite',
      this.store.createRecord('v2/family-portal-invite', {
        kase: this.v2Kase
      })
    );
  }

  resetFamilyPortalInvites() {
    this.set('familyPortalInvites',
      this.store.query('v2/family-portal-invite', {
        kase_id: this.v2Kase.id,
        include: 'recipient'
      })
    );
  }

  @action
  setRecipient(recipient) {
    this.recipient = recipient;
    this.showValidation = false;
  }

  @action
  createPerson() {
    this.recipient.save();
  }

  @action
  clearRecipient() {
    this.recipient = null;
    this.showValidation = false;
  }

  @action
  async createFamilyPortalInvite() {
    if (!this.recipient) {
      return;
    }

    this.resetNewFamilyPortalInvite();
    const person = await this.fetchV2Recipient(this.recipient.id);
    this.newFamilyPortalInvite.set('recipient', person);
    const invite = await this.validate();
    this.showValidation = !invite.validations.isValid;

    if (!invite.validations.isValid) {
      return;
    }

    this.createInvite();
  }

  @action
  removeFamilyPortalInvite(invite) {
    const confirmation = confirm(this.intl.t('authenticated.kases.manage.edit.family-portal.buttons.removeRecipient.confirmationMessage'));
    if (!confirmation) {
      return;
    }
    invite.destroyRecord().then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.family-portal.buttons.removeRecipient.successMessage'));
      this.resetFamilyPortalInvites();
    });
  }

  @action
  async copyKeyValuesToKase(changes, rawImport) {
    const result = await saveChanges(this, changes, rawImport);
    if (!result) {
      this.flashes.addError(this.intl.t('authenticated.kases.manage.edit.family-portal.buttons.copyInformation.errorMessage'));
      return;
    }
    this.flashes.addSuccess(this.intl.t('authenticated.kases.manage.edit.family-portal.buttons.copyInformation.successMessage'));
  }
}
