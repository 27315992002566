import { helper as buildHelper } from '@ember/component/helper';

export function maintenanceMode(array) {
  const [integrationName, settings] = array;
  const key = integrationName + '_maintenance_mode';
  const setting = settings.find((setting)=>{
    return setting.key === key;
  })
  return setting ? setting.value : false;
}

export default buildHelper(maintenanceMode);
