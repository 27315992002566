import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2LocationMetaModel extends Model {
  @attr('string') source;
  @attr('string') value;
  @attr('string') locationId;
  @attr('string') clientId;

  @attr('moment-datetime') archivedAt;

  @belongsTo('v2/location') location;
}
