import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class GoogleFontsService extends Service {
  @service api;

  @tracked fonts = [];
  @tracked isFetching = false;

  async fetchFonts() {
    if (this.fonts.length > 0) {
      return;
    }

    this.isFetching = true;

    try {
      const fonts = await this.api.json.get('google/google_fonts');
      this.fonts = fonts.parsedJson.fonts;
    } catch (error) {
      console.error('Error fetching Google Fonts:', error);
    } finally {
      this.isFetching = false;
    }
  }
}
