import RSVP from 'rsvp';
import Route from '@ember/routing/route';

export default Route.extend({
  controllerName: 'authenticated.crakn.demos.edit',
  templateName: 'authenticated.crakn.demos.edit',

  model(params) {
    return RSVP.hash({
      client: this.store.createRecord('client', {
        name: params.name
      }),
      reportConfig: this.store.createRecord('reportConfig', {})
    });
  },

  setupController(controller, models) {
    this._super(...arguments);
    controller.setProperties(models);
  }
});
