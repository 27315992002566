import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import Toolbelt from 'crakn/mixins/component-toolbelt';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';
import { inject as service } from '@ember/service';

export default Component.extend(Toolbelt, {
  classNames: ['list__item'],
  tagName: 'li',

  session: service(),
  intl: service(),
  flashes: service(),
  api: service(),

  refund: null,

  idKey: readOnly('refund.id'),

  actions: {
    setDialog(refund) {
      if (dateInCoaLockedPeriod(refund.get('selection'), this.session, refund.get('collectedAt'))) {
        this.flashes.addError(this.intl.t('components.list.refund-summary.errors.postingPeriod'));
        return;
      }
      this.set('showDialog', true);
    },

    removeRefund(refund) {
      this.attrs.onRemove(refund);
    },

    async resendToBeaconfp() {
      await this.api.json.post(`refunds/${this.refund.id}/resend_to_beaconfp`, {});
    }
  }
});
