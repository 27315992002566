import Component from '@ember/component';
import { set, get, computed } from '@ember/object';

export default Component.extend({
  locationType: null,

  lastAddressCheck: false,
  placeOfDeathCheck: false,
  informantCheck: false,

  isMenuOpen: false,
  menuStateClass: computed('isMenuOpen', function() {
    const menu = this.isMenuOpen;
    return menu ? 'menu' : 'hideMenu';
  }),

  options: computed(
    'informantCheck',
    'lastAddressCheck',
    'locationType',
    'placeOfDeathCheck',
    function() {
      const optionChecks = [
        {
          label: 'Last Permanent Address',
          check: 'lastAddressCheck'
        },
        {
          label: 'Place of Death',
          check: 'placeOfDeathCheck'
        },
        {
          label: 'Informant Address',
          check: 'informantCheck'
        }
      ];

      const options = optionChecks
        .filter(opt => get(this, opt.check))
        .map(opt => opt.label)
        .without(this.locationType);

      return options;
    }
  ),

  actions: {
    onClick() {
      this.toggleProperty('isMenuOpen');
    },

    selectOption(srcLocationType) {
      set(this, 'isMenuOpen', false);
      this.sendAction('smartEntry', srcLocationType, this.locationType);
    }
  }
});
