import Model, { hasMany, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: validator('presence', true),
});

export default class EmbalmReasonModel extends Model.extend(Validations) {
  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('string') description;
  @attr('string') name;

  @hasMany('product', { async: true }) products;
}
