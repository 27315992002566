import Component from '@ember/component';
import { computed, get } from '@ember/object';
import Timeslot from 'crakn/utils/timeslot';
import moment from 'moment';

export default Component.extend({
  calendarDay: null,
  cellHeight: 40,
  classNames: ['calendar calendar-day'],

  didInsertElement() {
    this.element.querySelector('.calendar__body').scrollTop = this.cellHeight * 8;
  },

  timeslots: computed('calendarDay.{startHour,endHour}', function() {
    const timeslots = [];
    for (
      let i = get(this, 'calendarDay.startHour');
      i < get(this, 'calendarDay.endHour');
      i++
    ) {
      timeslots.push(
        Timeslot.create({
          startsAt: moment(get(this, 'calendarDay.date'))
            .startOf('day')
            .set({ hour: i })
        })
      );
    }
    return timeslots;
  }),

  actions: {
    onSelectEvent(event) {
      this.sendAction('onSelectEvent', event);
    },

    goToPreviousDay() {
      this.calendarDay.fetchPrevious();
    },

    goToNextDay() {
      this.calendarDay.fetchNext();
    }
  }
});
