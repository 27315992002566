import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { set, get } from '@ember/object';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  email: validator('format', {
    type: 'email',
    allowBlank: false
  })
});

export default Controller.extend(Validations, {
  email: null,
  error: null,
  showValidation: false,
  isEnteringEmail: true,
  api: service(),
  flashes: service(),

  handleSuccess() {
    set(this, 'error', null);
    set(this, 'isEnteringEmail', false);
  },

  handleError(response) {
    set(this, 'error', response ? response.parsedJson.error : null);
  },

  actions: {
    sendPasswordResetEmail(email) {
      this.validate().then(({ validations }) => {
        if (get(validations, 'isValid')) {
          set(this, 'showValidation', false);
          this.api.json
              .post(
                'password_resets',
                { body: { email } }
              )
              .then(response => {
                if (response.ok) {
                  this.handleSuccess();
                } else {
                  this.handleError(response);
                }
              });
        } else {
          set(this, 'showValidation', true);
        }
      });
    }
  }
});
