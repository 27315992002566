import SearchController from 'crakn/controllers/search';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class AuthenticatedKasesManageEditKaseLinkController extends SearchController {
  @service flashes;
  @service store;

  @tracked newKaseLink = null;
  @tracked page = 1;
  @tracked showValidations = false;

  kase; //set in setup controller
  toggleKaseLink = false;

  @action
  toggleLinkFields() {
    this.toggleProperty('toggleKaseLink');
  }

  @action
  createNewKaseLink() {
    this.toggleLinkFields();

    this.newKaseLink = this.store.createRecord('v2/kase-link', {
      kase: this.kase,
    });
  }

  @action
  saveNewKaseLink() {
    this.newKaseLink.validate().then(({ validations }) => {
      if (validations.isValid) {
        this.showValidations = false;

        this.newKaseLink
          .save()
          .then(() => {
            this.flashes.addSuccess('Successfully saved!');
            this.send('refreshModel');
            this.toggleLinkFields();
          })
          .catch(() => {
            this.flashes.addError('Oh No! There was an error.');
          });
      } else {
        this.showValidations = true;
      }
    });
  }

  @action
  removeKaseLink(kaseLink) {
    kaseLink
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess('Successfully deleted!');
        this.send('refreshModel');
      })
      .catch(() => {
        this.flashes.addError('Oh No! There was an error.');
      });
  }

  @action
  cancelNewKaseLink() {
    this.newKaseLink.rollbackAttributes();
    this.newKaseLink = null;
    this.toggleLinkFields();
  }
}
