import {
  validateLength,
  validatePresence
} from 'ember-changeset-validations/validators';
import validateUrlCharacters from 'crakn/validators/url-safe-characters';

export default {
  name: [validatePresence(true), validateLength({ min: 1 })],
  urlPrefix: [validatePresence(true), validateLength({ min: 1 }), validateUrlCharacters()]
};
