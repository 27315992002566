import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default class CalendarNewEventRoute extends Route {
  @service calendarDay;

  afterModel(model) {
    const startsAt = model.get('startsAt');
    if (startsAt === null) {
      const today = moment().startOf('hour');
      model.set('startsAt', today);
    }
    this.calendarDay.fetchDate(model.get('startsAt'));
  }
}
