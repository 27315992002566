import Model, { belongsTo, hasMany, attr } from '@ember-data/model';

export default class ServiceInfoModel extends Model {
  @attr('boolean') hasFlowers;
  @attr('boolean') hasPallbearers;
  @attr('boolean') hasPhotos;
  @attr('boolean') hasServiceNotes;
  @attr('boolean') hasStationery;
  @attr('boolean') hasVideo;
  @attr('boolean') isMemorialContributions;
  @attr('boolean') photosReceived;
  @attr('boolean') photosReturnedToFamily;
  @attr('boolean') showVideoAtService;
  @attr('boolean') showVideoAtVisitation;
  @attr('moment-datetime') datePhotosReturned;
  @attr('string') acknowledgementCardNotes;
  @attr('string') acknowledgementCardQuantity;
  @attr('string') acknowledgementCardSeries;
  @attr('string') flowerNotes;
  @attr('string') flowers;
  @attr('string') folderNotes;
  @attr('string') folderQuantity;
  @attr('string') folderSeries;
  @attr('string') mealNotes;
  @attr('string') memorialCardNotes;
  @attr('string') memorialCardQuantity;
  @attr('string') memorialCardSeries;
  @attr('string') photoNotes;
  @attr('string') photosQuantity;
  @attr('string') poemNotes;
  @attr('string') registerBookNotes;
  @attr('string') registerBookSeries;
  @attr('string') serviceNotes;
  @attr('string') videoNotes;

  @belongsTo('kase') kase;
  @belongsTo('place', { async: false }) florist;

  @hasMany('memorialContributions', { async: true }) memorialContributions;
}
