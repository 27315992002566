import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class KasesEventsEditController extends Controller {
  @service flashes;
  @service intl;
  @service router;

  handleSaveError() {
    this.flashes.addError(this.intl.t('authenticated.kases.events.edit.messages.saveEvent.error'));
  }

  get editing() {
    const place = this.model.place;
    if (place === null) {
      return true;
    }
    return this.model.place.isNew;
  }

  @action
  async deleteEvent(event) {
    const kase = await event.get('kase');

    event
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess(this.intl.t('authenticated.kases.events.edit.messages.deleteEvent.success'));
        this.router.transitionTo(
          'authenticated.kases.manage.edit.events',
          kase
        );
      })
      .catch(() => {
        this.flashes.addError(this.intl.t('authenticated.kases.events.edit.messages.deleteEvent.error'));
      });
  }

  @action
  cancelEvent(event) {
    this.router.transitionTo(
      'authenticated.kases.manage.edit.events',
      event.get('kase')
    );
  }

  @action
  handleSaveSuccess() {
    this.flashes.addSuccess(this.intl.t('authenticated.kases.events.edit.messages.saveEvent.success'));
    this.router.transitionTo(
      'authenticated.kases.manage.edit.events',
      this.model.kase,
      { queryParams: { noDirtyCheck: true } }
    );
  }

  @action
  saveAndAddAnotherEvent() {
    this.flashes.addSuccess(this.intl.t('authenticated.kases.events.edit.messages.saveAnotherEvent.success'))
    this.store
      .createRecord('event', { kase: this.model.kase, place: null })
      .save()
      .then((newEvent) => {
        this.router.transitionTo(
          'authenticated.kases.events.edit',
         newEvent.id,
          {
            queryParams: { noDirtyCheck: true }
          }
        );
      });
  }

  @action
  openEventModal(event) {
    event.set(
      'isCurrentEvent',
      event.get('isNew') || event.get('id') === this.model.id
    );
    this.send('showModal', 'g/whiteboard/modal/event-details', event);
  }
}
