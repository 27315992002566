import { run } from '@ember/runloop';
import RSVP from 'rsvp';
import { action, set } from '@ember/object';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';

export default class KasesManageFinancialsAdjustmentsRoute extends Route {
  @service session;

  queryParams = {
    selection_id: {
      refreshModel: true
    }
  }

  model(params) {
    const model = this.modelFor('authenticated.kases.manage.financials');
    const gain = this.session.currentClient.can_view_adjustment_gains ? null : false;

    return RSVP.hash({
      selectionParams: params,
      kase: model.kase,
      kaseFinancial: model.kaseFinancial,
      selection: params.selection_id
        ? this.store.findRecord('selection', params.selection_id)
        : '',
      selections: model.selections,
      clientInfo: model.clientInfo,
      adjustmentTypes: this.store.query('adjustmentType', {
        archived: false,
        gpl_id: model.kase.get('location.generalPriceList.id'),
        per_page: 1000,
        can_view_gain: gain
      }),
      newAdjustment: this.store.createRecord('adjustment', {
        enteredBy: this.session.currentUser,
        adjustedBy: this.session.currentUser,
        gain
      })
    }).then(models => {
      // If selection_id is null display the selection with oldest positive balance
      const selectionParams = models.selectionParams;
      if (isEmpty(selectionParams.selection_id)) {
        const oldestBalanceSelection = models.selections.filter(
          selection => {
            return selection.get('balance') > 0;
          }
        );

        if (isEmpty(oldestBalanceSelection)) {
          set(models,'selection', models.selections.firstObject);
        } else {
          set(models,'selection', oldestBalanceSelection.get('firstObject'));
        }
      }

      return models;
    });
  }

  setupController(controller, models) {
    super.setupController(...arguments);
    // Selection_id is set here to handle setting routes to selection with oldest positive balance if empty
    if (models.selection) {
      controller.set('selection_id', models.selection.id);
    }
    controller.setProperties(models);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('selection_id', null);
      controller.set('showValidation', false);
    }
  }

  handleAdjustmentSaveSuccess() {
    this.refresh();
    this.send('refreshKaseFinancial');
    this.send('flashSuccess', 'Adjustment successfully saved!');
  }

  handleAdjustmentRemoveSuccess() {
    this.refresh();
    this.send('refreshKaseFinancial');
    this.send('flashSuccess', 'Adjustment successfully removed.');
  }

  handleError() {
    this.send(
      'flashError',
      'Something went wrong. Please try again later. If errors continue, please contact support@tributetms.com.'
    );
  }

  @action
  cancelNewAdjustment() {
    if (confirm('This Adjustment will be cancelled. Are you sure?')) {
      this.controller.set('showValidation', false);
      this.refresh();
    }
  }

  @action
  saveNewAdjustment(newAdjustment) {
    newAdjustment.setProperties({
      selection: this.currentModel.selection,
      enteredBy: this.session.currentUser
    });

    newAdjustment
      .save()
      .then(
        run.bind(this, 'handleAdjustmentSaveSuccess'),
        run.bind(this, 'handleError')
      );
  }

  @action
  removeAdjustment(adjustment) {
    adjustment
      .destroyRecord()
      .then(
        run.bind(this, 'handleAdjustmentRemoveSuccess'),
        run.bind(this, 'handleError')
      );
  }

  @action
  setAdjustmentType(adjustment, adjustmentTypeId) {
    const adjustmentType = this.currentModel.adjustmentTypes.findBy(
      'id',
      adjustmentTypeId
    );
    adjustment.set('adjustmentType', adjustmentType);
  }
}
