import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n    class=\"crakn-fc-full\"\n    id=\"calendar\"\n    {{did-insert this.setup}}\n    {{did-update this.eventsChanged @events}}\n    {{did-update this.render @initialView}}\n    {{did-update this.updateSize this.sidebar.isSidebarOpen}}>\n</div>\n", {"contents":"<div\n    class=\"crakn-fc-full\"\n    id=\"calendar\"\n    {{did-insert this.setup}}\n    {{did-update this.eventsChanged @events}}\n    {{did-update this.render @initialView}}\n    {{did-update this.updateSize this.sidebar.isSidebarOpen}}>\n</div>\n","moduleName":"crakn/components/g/crakn/calendar.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/calendar.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Calendar } from '@fullcalendar/core';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import timeGridPlugin from '@fullcalendar/timegrid';

export default class GCraknCalendarComponent extends Component {
  @service sidebar;
  calendar;

  @action
  render() {
    this.calendar?.changeView(this.args.initialView);
    this.calendar?.render();
  }

  @action
  updateSize() {
    const update = () => this.calendar?.updateSize();

    debounce(update, 300);
  }

  @action
  eventsChanged() {
    const source = this.calendar?.getEventSources()?.firstObject;

    if (!source || !this.calendar) {
      return;
    }

    source.remove();
    this.calendar.addEventSource(this.args.events)
  }

  @action
  setup(domElement) {
    const { buttonText, datesChanged, eventClick, eventDidMount, events, headerToolbar, height, initialView, viewClassNames } = this.args;

    this.calendar = new Calendar(domElement, {
      buttonText,
      datesSet: datesChanged ? data => datesChanged(data) : undefined,
      eventClick,
      eventDidMount,
      eventDisplay: 'block',
      events,
      headerToolbar,
      height,
      initialView,
      plugins: [ momentPlugin, dayGridPlugin, timeGridPlugin, listPlugin ],
      viewClassNames
    });

    this.render();
  }
}
