import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { computed, set, get } from '@ember/object';
import { inject as service } from '@ember/service';
import Toolbelt from 'crakn/mixins/component-toolbelt';

export default Component.extend(Toolbelt, {
  session: service(),
  didReceiveAttrs() {
    const hasCurrentDiscount =
      parseInt(get(this, 'selection.nonTaxableDiscount')) > 0 ? true : false;
    const canViewDiscounts = get(
      this,
      'session.currentClient.can_view_discounts'
    );
    const showDiscount = hasCurrentDiscount || canViewDiscounts;
    set(this, 'showNonTaxableDiscount', showDiscount);
  },

  classNames: ['list'],
  classNameBindings: ['isCondensed:list--condensed'],
  tagName: 'ul',

  selection: null,

  showNotes: false,
  isCondensed: false,
  showNonTaxableDiscount: false,

  packages: readOnly('selection.packages'),
  products: readOnly('selection.products'),
  customProducts: readOnly('selection.customProducts'),

  grandTotalClass: computed('selection.total', function() {
    const grandTotal = get(this, 'selection.total');
    const balance = get(this, 'selection.balance');
    const payments = get(this, 'selection.payments');

    if (payments && get(payments, 'length')) {
      return balance > 0 ? 'green' : 'red';
    } else {
      return grandTotal > 0 ? 'green' : 'red';
    }
  }),

  get contractText() {
    return this.session.currentClient.relabel_contract_to_invoice ? 
    `Invoice` : `Contract`;
  },
});
