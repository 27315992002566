const placeTypes = [
  {
    label: 'Home Under Hospice',
    value: 'home_under_hospice'
  },
  {
    label: 'Hospital',
    value: 'hospital'
  },
  {
    label: 'Hospice Facility',
    value: 'hospice_facility'
  },
  {
    label: 'Nursing Home/Long-term Care Facility',
    value: 'nursing_home_long_term_care_facility'
  },
  {
    label: 'Assisted Living',
    value: 'assisted_living'
  },
  {
    label: 'Decedent\'s Home',
    value: 'decedents_home'
  },
  {
    label: 'VA Medical Center',
    value: 'va_medical_center'
  },
  {
    label: 'State Veterans Home',
    value: 'state_veterans_home'
  },
  {
    label: 'Cemetery',
    value: 'cemetery'
  },
  {
    label: 'Crematory',
    value: 'crematory'
  },
  {
    label: 'Church',
    value: 'church'
  },
  {
    label: 'Mosque',
    value: 'mosque'
  },
  {
    label: 'Synagogue',
    value: 'synagogue'
  },
  {
    label: 'Event/Reception Center',
    value: 'event_reception_center'
  },
  {
    label: 'Morgue',
    value: 'morgue'
  },
  {
    label: 'Funeral Home',
    value: 'funeral_home'
  },
  {
    label: 'School',
    value: 'school'
  },
  {
    label: 'Outdoor Venue/Park',
    value: 'outdoor_venue_park'
  },
  {
    label: 'Other',
    value: 'other'
  }
      
  ];

  export { placeTypes };
