import { computed, get } from '@ember/object';
import { inject as service } from '@ember/service';
import SelectBox from './form/select-box';
export default SelectBox.extend({
  layoutName: 'components/form/select-box',
  staff: service(),
  placeholder: 'All Staff Members',
  removable: true,
  staffList: 'active',
  removeUser: null,
  options: computed('staffList', {
    get() {
      return get(this, `staff.${this.staffList}`);
    }
  }),
  actions: {
    removeUser() {
      this.sendAction('removeUser', this.selection);
    }
  }
});
