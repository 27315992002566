import SearchRoute from 'crakn/routes/search';
import { action } from '@ember/object';

export default class ClientsRoute extends SearchRoute {
  queryParams = {
    query: {
      replace: true,
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    }
  }

  modelType = 'v2/online-planner-config';
  routeRegex = /authenticated.admin.manage.online-planner/;

  model(params = {}) {
    return this.store.query(this.modelType, {
      query: params.query || this.defaultQuery,
      page: params.page || this.defaultPage,
      perPage: params.perPage,
      archived: params.archived || this.defaultArchived
    });
  }

  @action
  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('addNewOnlinePlannerConfig', false);
      controller.set('newOnlinePlanner', null);
      controller.set('query', null);
    }
  }
}
