import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import SearchController from 'crakn/controllers/search';

export default class AuthenticatedAdminArAdjustmentsController extends SearchController {
  @service flashes;
  @service store;
  @service api;
  @service intl;

  @tracked addingAdjustment = false;
  @tracked showConfirmation = false;
  @tracked revertWriteoffId = null;
  @tracked needsRefresh = false;

  get revertStatusList() {
    return ['Queued', 'Revert Queued', 'Revert Failed', 'Failed', 'Completed', 'Revert Completed'];
  }

  get canRevertList() {
    return ['Revert Failed', 'Completed'];
  }

  get cantRevertList() {
    return ['Queued', 'Revert Queued', 'Failed'];
  }

  @action
  setAddingAdjustment(value) {
    this.addingAdjustment = value;
  }

  @action
  setShowConfirmation(value, writeoffId) {
    this.showConfirmation = value;
    this.revertWriteoffId = writeoffId;
  }

  @action
  async revertWriteoff() {
    const revert = await this.api.json.post(`v2/ar_writeoffs/${this.revertWriteoffId}/revert`);

    if (!revert.ok) {
      const error = revert?.parsedJson?.errors ? revert?.parsedJson?.errors : this.intl.t('authenticated.admin.manage.ar-adjustments.revertError');
      this.flashes.addError(error);
      return;
    }
    this.setShowConfirmation(false, null);
    this.refresh();
    this.flashes.addSuccess(this.intl.t('authenticated.admin.manage.ar-adjustments.revertSuccess'));
  }

  @action
  refresh() {
    this.send('refreshModel');
  }

  @action
  triggerRefreshTimer() {
    const wait = 10000;

    if (!this.needsRefresh) return;

    if (this.showConfirmation || this.addingAdjustment) {
      setTimeout(() => {
        this.triggerRefreshTimer();
      }, wait);
      return;
    }

    this.refresh();
  }
}
