import Model, { attr } from '@ember-data/model';

export default class BlankFormModel extends Model {
  @attr('string') clientId; /** Only for adding a form to a client */
  @attr('string') description;
  @attr('string') key;
  @attr('string') name;
  @attr('string') requiredInfo; /** Old column */

  @attr('boolean', { defaultValue: false }) eventDropdown;
  @attr('boolean', { defaultValue: false }) favorited;
  @attr('boolean', { defaultValue: false }) filled;
  @attr('boolean', { defaultValue: false }) inquiryDropdown;
  @attr('boolean', { defaultValue: false }) kaseContactDropdown;
  @attr('boolean', { defaultValue: false }) locationDropdown;
  @attr('boolean', { defaultValue: false }) searchable;

  @attr('array') clientNames;
}
