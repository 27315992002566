import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  name: validator('presence', {
    presence: true,
    ignoreBlank: true,
    message: 'The album name should not be empty or consist only of spaces'
  })
});

export default class V2AlbumModel extends Model.extend(Validations) {
  @attr('moment-datetime') createdAt;
  @attr('string') description;
  @attr('string') name;
  @attr('boolean', { defaultValue: false }) public;

  @belongsTo('v2/client') client;
  @belongsTo('v2/photo') coverPhoto;
  @belongsTo('v2/kase') kase;

  @hasMany('v2/photo', { async: true }) photos;
}
