import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AdminOnlinePlannerPageRoute extends Route {
  @service router;
  @service store;
  @service onlinePlanner;
  params = undefined;

  async beforeModel() {
    if (!this.controller) return;
    this.controller.set('loading', true);
    await this.onlinePlanner.unloadFragments();
  }

  async model(params) {
    const planner = await this.modelFor('authenticated.admin.online-planner.edit');
    this.resetController();
    this.params = params;
    return planner;
  }

  afterModel(model) {
    if (!this.controller) return;
    this.controller.set('loading', false);
    this.setControllerInfo(this.controller, model);
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    this.setControllerInfo(controller, model);
    this.setCustomFieldLocations(controller, model);
  }

  setControllerInfo(controller, model) {
    const emberPlanner = this.onlinePlanner.findOrCreateEmberPlanner(model.id);
    const page = emberPlanner.pages.find((x) => x.name === this.params?.wildcard);
    controller.set('page', page);
    controller.set('id', this.params?.wildcard);
  }

  async setCustomFieldLocations(controller, model) {
    const locationID = await model.get(
      'locationOnlinePlannerConfigs.firstObject.location.id'
    );

    const customFieldConfigs = await this.store.query('v2/customFieldConfig', {
      location_ids: [locationID, 'global']
    });

    let fields = [];
    customFieldConfigs.forEach((config) => {
      fields = fields.concat(config.customFields.toArray());
    });

    controller.set('customFields', fields || []);
  }
}
