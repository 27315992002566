import { A } from '@ember/array';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class AuthenticatedCalendarIndexController extends Controller {
  @service browser;
  @service router;
  craknTeal = '#1C889C';
  currentViewType = null;
  @tracked end_date = null;
  @tracked eventPage = 1;
  @tracked filter_kase_status = true;
  @tracked full_only = true;
  @tracked fullcalendarButtonText = {
    today: 'Today',
    month: 'Month Calendar',
    day: 'Day Agenda',
    week: 'Week Agenda',
    listWeek: 'Week List',
    listYear: 'Year List',
    listDay: 'Day List'
  };
  @tracked headerOpts = {
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridDay,timeGridWeek,listDay,listWeek,listYear'
  };
  @tracked organization_location_id = null;
  @tracked per_page = 1000;
  @tracked queryParams = [
    'end_date',
    'filter_kase_status',
    'full_only',
    'organization_location_id',
    'per_page',
    'start_date',
    'user_id'
  ];
  @tracked start_date = null;
  @tracked user_id = null;


  get isMobile() {
    return this.browser.isMobile === true || this.browser.isMobileWidth === true;
  }

  get isListView() {
    return this.currentViewType?.includes('list');
  }

  get computedView() {
    return this.isMobile ? 'timeGridDay' : 'dayGridMonth';
  }

  get computedHeight() {
    if (!this.isMobile) {
      return null;
    }

    return 800;
  }

  get events() {
    const calendarEvents = this.model;
    let formattedArray = A();

    calendarEvents?.forEach(event => {
      const formattedEvent = this.formatCalendarEvent(event);
      formattedArray.pushObject(formattedEvent);
    });
    return formattedArray;
  }

  formatCalendarEvent(event) {
    let title = event?.name;

    const deceasedLastName = () => {
      const lastName = event?.deceasedLastName;
      return lastName ? `(${lastName}) ` : '';
    };

    const place = () => {
      const placeName = event?.place?.name;
      return placeName ? `― ${placeName}` : '';
    };

    const room = () => {
      const roomName = event?.room?.name;
      return roomName ? `: ${roomName}` : '';
    };

    if (this.isListView || this.currentViewType === 'agendaDay') {
      title = `${deceasedLastName()}${title} ${place()}${room()}`;
    } else {
      title = `${deceasedLastName()}${title}`;
    }
    const color = event.location?.color ? event.location?.color  : this.craknTeal;

    return {
        backgroundColor: color,
        borderColor: 'transparent',
        className: `calendar-event-${event.id} fc-crakn-event`,
        color: color,
        end: event.endsAt.format(),
        id: event.id,
        staff: event.eventStaffMembers,
        start: event.startsAt.format(),
        title,
    };
  }

  setupDayTableHeaders() {
    const dayTableHeader = [];
    const fcListDays = document.querySelectorAll('.fc-list-day');
    fcListDays.forEach((day) => dayTableHeader.push(day.childNodes));
    dayTableHeader.forEach((thNode) => {
      thNode.forEach((th) => th.setAttribute('colspan', 4))
    });
  }

  addStaffBadgeElements(renderInfo) {
    const staff = renderInfo.event.extendedProps.staff.filter(staff => {
      return !staff.inviteDeclinedO;
    });

    if (staff.length == 0) {
      return;
    }

    const div = document.createElement('div');
    div.className = 'fc-list-item fc-list-item-bottom m--sm--t';

    const createSpan = (text, color = 'yellow') => {
      const span = document.createElement('span');
      span.className = `badge badge--${color} fc-calendar-badge fc-crakn-badge`
      span.textContent = `${text}`;

      return span;
    }

    const badgeTextProperty = staff.length === 1 ? 'name' : 'initials';

    staff.forEach(person => {
      const text = person.user.get(badgeTextProperty);
      div.append(createSpan(text, person.statusBadgeColor));
    });

    renderInfo.el.querySelector('.fc-list-event-title').append(div);
  }

  formatDate(date) {
    return moment().set({
      'year': date.getFullYear(),
      'month': date.getMonth(),
      'date': date.getDate()
    });
  }

  @action
  updateEventRender(info) {
    if (!this.isListView) {
      return;
    }

    info.el.className += ' fc-crakn-list-row';
    this.setupDayTableHeaders();
    this.addStaffBadgeElements(info);
  }

  @action
  changeViewRender(info) {
    this.set('currentViewType', info.view.type)
  }

  @action
  datesChanged(info) {
    this.set('start_date', this.formatDate(info.start).format('YYYY-MM-DD'));
    this.set('end_date', this.formatDate(info.end).subtract(1, "days").format('YYYY-MM-DD'));
  }


  @action
  addNewEvent() {
    this.store
      .createRecord('event')
      .save()
      .then(newEvent => {
        this.router.transitionTo('authenticated.calendar.new-event', newEvent);
      });
  }

  @action
  incrementEventPage() {
    this.incrementProperty('eventPage');
  }

  @action
  decrementEventPage() {
    this.decrementProperty('eventPage');
  }

  @action
  eventClick(info) {
    this.router.transitionTo('authenticated.calendar.new-event', info.event.id);
  }
}
