import Controller from '@ember/controller';
import { set } from '@ember/object';
import { run } from '@ember/runloop';
import Search from 'crakn/mixins/search';

export default Controller.extend(Search, {
  queryParams: ['page', 'query', 'archived'],

  page: 1,
  query: null,
  archived: false,

  actions: {
    new() {
      this.transitionToRoute('authenticated.admin.manage.financial-configurations.new').then(
        run.bind(this, function(route) {
          set(route, 'controller.model.name', this.query);
        })
      );
    }
  }
});
