import { get, action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ListPersonalEffectSummary extends Component {
  @tracked isNew = false;
  @tracked isEditing = false;
  @tracked showValidation = false;
  @tracked showDialog = false;

  constructor(owner, args) {
    super(...arguments);
    this.isNew = args.isNew || false;
  }

  get lastAudit() {
    return this.args.lastAudit;
  }

  get kase() {
    return this.args.kase;
  }

  get personalEffect() {
    return this.args.personalEffect;
  }

  get withBody() {
    if (this.lastAudit && !this.isNew) {
      return this.lastAudit.personalEffects.includes(
        this.personalEffect
      );
    }
    return false;
  }

  @action
  toggleEdit(e) {
    e.preventDefault();
    this.isEditing = !this.isEditing;
  }

  @action
  saveEditPersonalEffect() {
    this.personalEffect.validate().then(({ validations }) => {
      if (get(validations, 'isValid')) {
        this.showValidation = false;
        this.isEditing = false;
        this.args.onSave(this.personalEffect);
      } else {
        this.showValidation = true;
      }
    });
  }

  @action
  revertPersonalEffect() {
    this.isEditing = false;
    this.args.onCancel(this.personalEffect);
  }

  @action
  removePersonalEffect() {
    this.args.onRemove(this.personalEffect);
  }
}
