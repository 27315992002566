import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { isBlank, isPresent } from '@ember/utils';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
	contactFirstName: validator('presence', true),
	contactLastName: validator('presence', true)
});

export default Component.extend(Validations, {
	classNameBindings: ['type'],

	store: service(),
	names: service(),
	session: service(),

	contact: null,

	contactFirstName: alias('contact.firstName'),
	contactLastName: alias('contact.lastName'),

	compactView: false,
	queryDeceased: null,
	queryArchived: false,
	clearOnSelect: false,
	showDeceased: true,
	contactLabel: null,
	showMaidenName: false,
	showTitle: false,
	showSuffix: false,
	isCondensed: false,
	removeContact: null,
	removable: true,
	showValidation: false,

	defaultTitle: null,

	type: null,
	redirect: null,
	redirectId: null,

	showSmartEntry: computed('contact.id', 'smartEntryOptions', function() {
		const contactNotSet = !this.get('contact.id');
		const smartEntryOptions = this.smartEntryOptions;
		let haveSmartEntryOptions = false;

		if (smartEntryOptions && smartEntryOptions.length > 0) {
			haveSmartEntryOptions = true;
		}

		return contactNotSet && haveSmartEntryOptions;
	}),

	showDeceasedBox: computed(
		'contact.content',
		'contactLabel',
		'showDeceased',

		function() {
			let label, content, showDeceased;

			label = this.contactLabel;
			content = get(this, 'contact.content');
			showDeceased = this.showDeceased;

			return isPresent(label) && isPresent(content) && showDeceased;
		}
	),

	country: computed('contact.country', function() {
		const contact = this.contact;
		const clientCountry = this.session.currentClient.operating_country;
		if (get(contact, 'isNew')) {
			if (clientCountry) {
				return clientCountry;
			} else {
				return 'US';
			}
		} else {
			return contact.country;
		}
	}),

	setTitleDefault(contact) {
		const currentTitle = get(contact, 'namePrefix');

		if (isBlank(currentTitle)) {
			set(contact, 'namePrefix', this.defaultTitle);
		}
	},

	actions: {
		typeaheadSearch(value) {
			return this.store
						.query('person', {
							query: value,
							exclude_id: this.excludeId,
							exclude_ids: this.excludeIds,
							deceased: this.queryDeceased,
							archived: this.queryArchived
						});
		},

		async onSelected(item) {
				await this.store.findRecord('person', item.id).then(person => {
				this.attrs.onSelectContact(person);
			});
		},

		createNewContact(value) {
			const splitNames = this.names.splitNameIntoFirstAndLastName(value);
			const newContact = this.store.createRecord('person', splitNames);

			if (this.showTitle) {
				this.setTitleDefault(newContact);
			}

			this.attrs.onSelectContact(newContact);
		},

		cancelNewContact() {
			let contact = this.contact;

			contact = get(contact, 'content') || contact;

			if (get(contact, 'isNew')) {
				contact.rollbackAttributes();
			}

			this.attrs.onSelectContact(null);
		},

		saveNewContact(e) {
			e.target.disabled = true; // avoid double-click issues
			const defaultCountry = this.country;

			this.validate().then(({ validations }) => {
				if (get(validations, 'isValid')) {
					set(this, 'showValidation', false);
					set(this, 'contact.country', defaultCountry);
					set(this, 'contact.birthplaceAddressCountry', defaultCountry);
					this.attrs.saveContact();
				} else {
					set(this, 'showValidation', true);
				}

				e.target.disabled = false;
			});
		},

		toggleDeceased(value) {
			set(this, 'contact.deceased', value);

			const existingContact = !get(this, 'contact.isNew');

			if (existingContact) {
				this.attrs.saveContact();
			}
		},

		removeContact() {
			this.attrs.onSelectContact(null);

			if (this.removeContact && this.removable) {
				this.attrs.removeContact(this.type, get(this, 'contact.id'));
			}
		}
	}
});
