import SearchController from 'crakn/controllers/search';
import { sort } from '@ember/object/computed';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  'newVehicle.name': validator('presence', true),
});

export default class AdminVehicleController extends SearchController.extend(Validations) {
  @service store;
  @service flashes;
  @service session;

  @tracked deletingVehicle = null;
  @tracked newVehicle = null;
  @tracked showValidations = false;

  showValidation = false;

  sorting = ['name:asc'];
  @sort('model', 'sorting') sortedContent;

  @action
  addNewVehicle() {
    this.newVehicle = this.store.createRecord('vehicle');
  }

  @action
  saveNewVehicle() {
    this.validate()
        .then(() => {
          if (this.validations.isValid) {
            this.showValidations = false;
            this.newVehicle.save()
                           .then(() => {
                             this.newVehicle = null;
                             this.send('refreshModel');
                             this.flashes.addSuccess('Successfully Saved!');
                           })
                           .catch(() => {
                             this.flashes.addError('Oh No! There was an error.');
                           });
          } else {
            this.showValidations = true;
          }
        });
  }

  @action
  cancelNewVehicle() {
    this.newVehicle.rollbackAttributes();
    this.newVehicle = null;
  }

  @action
  selectVehicleForDelete(vehicle) {
    this.deletingVehicle = vehicle;
  }

  @action
  clearDeletingVehicle() {
    this.deletingVehicle = null;
  }

  @action
  confirmDeletingVehicle(vehicle) {
    vehicle
      .destroyRecord()
      .then(() => {
        this.clearDeletingVehicle();
        this.flashes.addSuccess('Successfully Removed.');
      })
      .catch(() => {
        this.flashes.addError('Oh No! There was an error.');
      });
  }
}
