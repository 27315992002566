import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default class KasesManageDocumentsAlbumsNewController extends Controller {
  @controller('authenticated.kases.manage.documents.albums') albumController;

  @service flashes;
  @service store;

  @action
  createPhoto(photoParams) {
    const photo = this.store.createRecord('v2/photo', photoParams);
    photo.kase = this.kase;
    photo.createdByUser = this.user;

    photo
      .save()
      .then((photo) => {
        this.flashes.addSuccess('Photo successfully uploaded!');
        this.album.photos.addObject(photo);
      })
      .catch(() => {
        this.flashes.addError('Oops, something went wrong! Please try again.');
      });
  }
}
