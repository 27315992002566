import { belongsTo, attr, hasMany } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';
import BasePackage from 'crakn/models/base-package';

const Validations = buildValidations({
  name: validator('presence', true),
  pricingAmount: validator('number', {
    allowBlank: false,
    allowNone: false,
  }),
  total: validator('number', {
    allowString: true,
    allowBlank: false,
    allowNone: false,
  }),
  'invoiceCategoryId.id': validator('presence', true),
});

export default class PackageModel extends BasePackage.extend(Validations) {
  @attr('string') name;
  @attr('string') description;
  @attr('string') netsuiteId;
  @attr('number') customOrder;
  @attr('boolean') isCustomizable;
  @attr('boolean', { defaultValue: false }) isDiscretionary;

  @attr('price') total;
  @attr('price') itemProductsTotal;
  @attr('price') allowanceTotal;

  @belongsTo('generalPriceList', { async: true }) generalPriceListId;
  @belongsTo('invoiceCategory', { async: true }) invoiceCategoryId;

  @hasMany('v2/package-slot', { async: true }) packageSlots;
  @hasMany('v2/package-slot-product', { async: true }) packageSlotProducts;
}
