import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2DispositionModel extends Model {
  @attr('boolean') isGraveUnknown;
  @attr('boolean') isHeadstoneDesired;
  @attr('moment-date') cremationDate;
  @attr('moment-date') disintermentDate;
  @attr('string') block;
  @attr('string') burialPermitNumber;
  @attr('string') containerDescription;
  @attr('string') cremationTime;
  @attr('string') crypt;
  @attr('string') dispositionMethod;
  @attr('string') lot;
  @attr('string') mausoleum;
  @attr('string') niche;
  @attr('string') plot;
  @attr('string') section;
  @attr('string') tier;

  @belongsTo('v2/person') graveOwner;
  @belongsTo('v2/kase') kase;
  @belongsTo('v2/place') cremationPlace;
  @belongsTo('v2/place') place;
  @belongsTo('v2/user') cremationDirector;
}
