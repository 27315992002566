import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class SettingsInfoRoute extends Route {
  @service flashes;

  model() {
    return this.modelFor('authenticated.settings');
  }
}
