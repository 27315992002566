import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default class AuthenticatedPlacesManageRoute extends Route {
  @service api;

  model(params) {
    return this.store.findRecord('v2/place', params.place_id);
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('showArchiveDialog', false);
    this.api.json.get(`v2/places/${model.id}/dependents`).then((resp) => {
      controller.set('deleteInfo', resp.parsedJson.dependents);
    });
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
