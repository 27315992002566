import { bind } from '@ember/runloop';
import LocalStorageStore from 'ember-simple-auth/session-stores/local-storage';
import objectsAreEqual from 'ember-simple-auth/utils/objects-are-equal';
import { create } from 'lodash-es/object';

export default LocalStorageStore.extend({
  /**
   This method compares the old and new session objects returned from the server
   (either by a session get or a new session create). If the two sessions don't match,
   it sets the new session and notifies the app that the session changed.

   @method bindToStorageEvents
   @private
   */
  _handleStorageEvent() {
    window.addEventListener('storage', () => {
      bind(this, function() {
        const data = this.restore();
        if (
          !this._objectsAreEqual(
            data.secure,
            this._lastData && this._lastData.secure
          )
        ) {
          this._lastData = data;
          this.trigger('sessionDataUpdated', data);
        }
      });
    });
  },

  /**
   Compares two session objects to see if they match. Ignores the token in order to
   keep multiple tabs of the app from attacking each other's session in local storage.
   */
  _objectsAreEqual(newData, oldData) {
    return objectsAreEqual(
      create(newData, { token: '' }),
      create(oldData, { token: '' })
    );
  }
});
