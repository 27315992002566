import Component from '@ember/component';
import { lte } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),
  chartOfAccountsId: null,
  model: null,
  selectionsCount: 0,
  showExportAll: lte('selectionsCount', 0),
  page: 1,

  actions: {
    export() {
      this.sendAction('export');
    },
    addSelection(selection) {
      this.sendAction('addSelection', selection);
    },
    removeSelection(selection) {
      this.sendAction('removeSelection', selection);
    }
  }
});
