import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import SelectBox from './select-box';

export default SelectBox.extend({
  layoutName: 'components/form/select-box',
  store: service(),
  placeId: null,
  rooms: null,
  placeholder: 'Select A Room',

  options: computed('rooms', 'placeId', function() {
    if (this.rooms) {
      return this.rooms;
    }
    return this.store.query('room', {
      place_id: this.placeId,
      archived: false,
      per_page: 1000
    });
  }),

  actions: {
    onSelectRoom(room) {
      this.sendAction('selectRoom', room);
    }
  }
});
