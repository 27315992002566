import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminIntegrationsTributeWebsiteRoute extends Route {
  @service store;

  model() {
    return this.store.query('integrationService', {
      name: 44, // tribute-website
      not_category: 'accounting',
      per_page: 1000
    });
  }

  async setupController(controller) {
    super.setupController(...arguments);

    controller.set('isLoading', false);
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
