import Route from '@ember/routing/route';

export default class CraknDemosIndexRoute extends Route {
  queryParams = {
    query: {
      replace: true,
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    sort_by: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    }
  };

  model(params) {
    return this.store.query(
      'client',
      Object.assign({ expiring: true }, params)
    );
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        page: 1,
        query: null,
        sort_by: null,
        order: 'ASC',
        addMode: false
      });
    }
  }
}
