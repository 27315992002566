import Component from '@ember/component';
import { computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default Component.extend({
  session: service(),

  startsAt: moment()
    .subtract(30, 'day')
    .toISOString(),
  endsAt: moment().toISOString(),
  includeAllBalances: false,
  filter_method: 'all',
  location_id: null,
  organization_location_id: null,
  soaLink: computed(
    'startsAt',
    'endsAt',
    'includeAllBalances',
    'location_id',
    'organization_location_id',
    'uuid',
    'filter_method',
    {
      get() {
        const params = new URLSearchParams({
          starts_at: this.startsAt,
          ends_at: this.endsAt,
          include_all_balances: this.includeAllBalances,
          location_id: this.location_id,
          filter_method: this.filter_method,
          organization_location_id: this.organization_location_id,
          uuid: this.uuid
        }).toString();
        return `/batch_print_statement_of_accounts?${params}`;
      }
    }
  ),

  fileUrl: computed('uuid', function() {
    return `#/generated_document/${this.uuid}`;
  }),

  get contractText() {
    return this.session.currentClient.relabel_contract_to_invoice ? 
    `Invoice` : `Contract`;
  },

  get allSoaLabel() {
    return `All SOAs (${this.contractText}s, Add-ons, Misc Sales)`
  },

  actions: {
    setDate(attr, value) {
      set(this, attr, value);
    }
  }
});
