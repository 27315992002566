import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ListChartOfAccountComponent extends Component {
  @service flashes;

  @tracked showDialog = false;

  get deleteDialogPrompt() {
    return `Are you sure you'd like to ${this.args.account.archivedAt ? 'unarchive' : 'archive'} ${this.args.account.name}?`;
  }

  @action
  toggleArchive() {
    const account = this.args.account;

    if (account.archivedAt) {
      account.set('archivedAt', null);
      account.save().then(() => {
        this.flashes.addSuccess('Success! The account was unarchived successfully.');
        this.args.refreshModel();
      })
    } else {
      account.destroyRecord().then(() => {
        this.flashes.addSuccess('Success! The account was archived successfully.');
        this.args.refreshModel();
      });
    }
  }
}
