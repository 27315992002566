import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AdminObituaryGeneratorController extends Controller {
  @service flashes;

  @action
  saveObituaryTemplate() {
    this.model
      .save()
      .then(() => {
        this.flashes.addSuccess('Successfully Saved!');
      })
      .catch(() => {
        this.flashes.addError('Oh No! There was an error.');
      });
  }

  @action
  setObituary(e) {
    this.model.set('obituaryTemplate', e.raw);
    this.model.set('obituaryTemplateFormatted', e.html);
  }

  @action
  setDefaultFormattedText(html) {
    this.model.obituaryTemplateFormatted = html;
  }
}
