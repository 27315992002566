import { action } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default class KasesIntegrationsBestWishesRoute extends Route {
  @service api;
  layout = 'custom-aside';

  model() {
    const model = this.modelFor('authenticated.kases.manage.integrations');

    return RSVP.hash({
      model,
      kase: model,
      bestWishesExport: this.store.queryRecord('integrationExport', {
        kase_id: model.id,
        type: 'best_wishes',
        most_recent_export: true,
        status
      }),
      integrationService: this.store.queryRecord('integrationService', {
        kase_id: model.id,
        name: 25, // best_wishes
        not_category: 'accounting'
      }),
      informant: model.informant,
      nextOfKin: model.get('nextOfKin')
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    const informant = model.informant;
    const nextOfKin = model.nextOfKin;

    if (informant && informant.get('contactAddress') && informant.get('contactCityState')) {
      controller.set('informant', informant);
    }

    if (nextOfKin) {
      this.store.findRecord('person', nextOfKin.get('personId')).then(person => {
        if (person.get('contactAddress') && person.get('contactCityState')) {
          controller.set('nextOfKin', nextOfKin);
        }
      });
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
