import Component from '@ember/component';
import { set } from '@ember/object';
import moment from 'moment';
import { task, timeout } from 'ember-concurrency';
import { standardDate, formatTime } from 'crakn/utils/date-helpers';

export default Component.extend({
  classNames: ['p--0--y', 'm--md--r', 'd--xs--inline'],
  tagName: 'h3',
  currentDateTime: null,
  currentMoment: moment(),

  init() {
    this._super(...arguments);
  },

  formatDateTime() {
    const offset = moment.parseZone(this.currentMoment).utcOffset();
    const now = moment().utcOffset(offset);
    set(this, 'currentDateTime', `${standardDate(now)} – ${formatTime(now)}`);
  },

  updateDateTime: task(function*() {
    while (true) {
      this.formatDateTime();
      yield timeout(60000);
    }
  })
    .on('init')
    .cancelOn('deactivate')
});
