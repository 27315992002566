import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class TmsSettings extends Service {
  @service api;
  @service store;

  @tracked allSettings = [];

  async requestRefresh() {
    //emit a refreshRequested for any listeners to requery their lists of settings and value and
    this.refreshed = true;
  }

  async loadSettings() {
    this.allSettings = await this.store.query('v2/tms-setting', {
      page: 1,
      per_page: 999
    }).catch(()=>{
      return [];
    })
  }
}
