import Controller from '@ember/controller';
import { action } from '@ember/object';
import { alias, filterBy } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { run } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class SpecialTaxesController extends Controller {
  @alias('model') specialTaxes;
  @filterBy('specialTaxes', 'archivedAt', null) filteredSpecialTaxes;
  @tracked accounts = null;
  @tracked chartOfAccounts = null;
  @service flashes;
  @tracked savingSpecialTaxes = false;
  @tracked taxItems = null;

  handleSaveSuccess() {
    this.set('savingSpecialTaxes', false);
    this.send('flashSuccess', 'Special Taxes successfully saved!');
  }

  handleSaveError() {
    this.set('savingSpecialTaxes', false);
    this.send('flashError', 'Sorry, something went wrong. Please try again.');
  }

  @action
  addSpecialTax() {
    const specialTax = this.store.createRecord('specialTax', {
      chartOfAccounts: this.chartOfAccounts
    });
    this.model.pushObject(specialTax);
  }

  @action
  toggleArchivedSpecialTax(specialTax) {
    if (specialTax.get('isNew')) {
      specialTax.destroyRecord();
      this.model.removeObject(specialTax);
      this.flashes.addSuccess('Special Tax has been removed!');
    } else {
      specialTax.set('archivedAt', moment());
      specialTax
        .save()
        .then(() => {
          this.flashes.addSuccess('Special Tax has been removed!');
        })
        .catch(() => {
          this.flashes.addError(
            'There was an error removing this special tax.'
          );
        });
    }
  }

  @action
  saveSpecialTaxes() {
    this.set('savingSpecialTaxes', true);
    const save = x => x.save();
    const savePromises = this.specialTaxes.map(save);
    Promise.all(savePromises).then(
      run.bind(this, 'handleSaveSuccess'),
      run.bind(this, 'handleSaveError')
    );
  }
}
