import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { preventFormDefault } from 'ember-cli-prevent-form-default';
import { tracked } from '@glimmer/tracking';
import PersonSmartEntry from 'crakn/mixins/person-smart-entry';

export default class KasesEditDeceasedController extends Controller.extend(PersonSmartEntry) {
  @service clientRouting;
  @service flashes;
  @service router;
  @service store;

  @tracked showValidation = false;
  @tracked saveToPlaces = false;
  @tracked searchedForPlaceOfDeath;

  kase = null;

  doctorFirstNameQuery = null;
  doctorLastNameQuery = null;

  deathCertifierFirstNameQuery = null;
  deathCertifierLastNameQuery = null;

  informantFirstNameQuery = null;
  informantLastNameQuery = null;

  clergyFirstNameQuery = null;
  clergyLastNameQuery = null;
  pendingClergy = null;

  smartLocationSources = {
    'Place of Death': 'model',
    'Last Permanent Address': 'model',
    'Informant Address': 'kase.informant'
  };

  smartEntryPersonMeta = {
    mother: {
      key: 'kase.mother',
      label: 'Mother',
      srcOptions: ['informant', 'purchaser']
    },
    father: {
      key: 'kase.father',
      label: 'Father',
      srcOptions: ['informant', 'purchaser']
    },
    spouse: {
      key: 'kase.spouse',
      label: 'Spouse',
      srcOptions: ['informant', 'purchaser']
    },
    informant: {
      key: 'kase.informant',
      label: 'Informant',
      srcOptions: ['mother', 'father', 'spouse', 'purchaser']
    },
    purchaser: {
      key: 'kase.purchaser',
      label: 'Responsible Party'
    },
    deathCertifier: {
      key: 'kase.deathCertifier',
      label: 'Death Certifier',
      srcOptions: ['doctor']
    },
    doctor: {
      key: 'kase.doctor',
      label: 'Doctor',
      srcOptions: ['deathCertifier']
    }
  };

  updatePlaceType() {
    const placeOfDeathPlaceType = this.model.profilePlaceOfDeathArea;

    if (placeOfDeathPlaceType !== 'Decedent\'s Home' && placeOfDeathPlaceType !== 'Home Under Hospice') {
      if (placeOfDeathPlaceType.includes('Hospital')) {
        set(this.searchedForPlaceOfDeath, 'placeType', 'hospital');
      } else if (placeOfDeathPlaceType.includes('Nursing Home')) {
        set(this.searchedForPlaceOfDeath, 'placeType', 'nursing_home_long_term_care_facility');
      } else {
        const snakeCasePlaceType = placeOfDeathPlaceType.replace(/["']/g, '').replace(/\//g, ' ').replace(/ /g, '_').toLowerCase();

        set(this.searchedForPlaceOfDeath, 'placeType', snakeCasePlaceType);
      }

      return this.searchedForPlaceOfDeath.save();
    }
  }

  savePlaceOfDeathToPlaces() {
    const newPlace = this.store.createRecord('place', {
      city: this.model.placeOfDeathCity,
      country: this.model.placeOfDeathCountry,
      county: this.model.placeOfDeathCounty,
      line1: this.model.placeOfDeathLine1,
      line2: this.model.placeOfDeathLine2,
      name: this.model.placeOfDeathName,
      state: this.model.placeOfDeathState,
      zipCode: this.model.placeOfDeathZipCode
    });

    return newPlace.save();
  }

  handleSaveSuccess() {
    this.flashes.addSuccess('Great job! You updated the deceased information');
    const route = this.clientRouting.manageRouteTransition(
      'authenticated.kases.manage.edit.deceased'
    );
    this.router.transitionTo(route);
  }

  handleSaveError() {
    this.flashes.addError('Oops! There was an error saving the deceased information.');
  }

  // This method is duplicated on `controllers/kases/manage/edit/personal`
  copyAddress(srcLocationType, destLocationType) {
    const sources = this.smartLocationSources;
    const destName = sources[destLocationType];
    const srcName = sources[srcLocationType];

    const fields = [
      { name: 'addressLine1', podName: 'placeOfDeathLine1' },
      { name: 'addressLine2', podName: 'placeOfDeathLine2' },
      { name: 'city', podName: 'placeOfDeathCity' },
      { name: 'county', podName: 'placeOfDeathCounty' },
      { name: 'state', podName: 'placeOfDeathState' },
      { name: 'zipCode', podName: 'placeOfDeathZipCode' },
      { name: 'country', podName: 'placeOfDeathCountry' }
    ];

    const keyFor = n => (n === 'Place of Death' ? 'podName' : 'name');
    const destKey = keyFor(destLocationType);
    const srcKey = keyFor(srcLocationType);

    let destStr, srcStr;

    fields.forEach(field => {
      destStr = `${destName}.${field[destKey]}`;
      srcStr = `${srcName}.${field[srcKey]}`;
      this.set(destStr, this.get(srcStr));
    });
  }

  @action
  setSaveToPlaces(bool) {
    set(this, 'saveToPlaces', bool);
  }

  @action
  setSearchedForPlaceOfDeath(place) {
    set(this, 'searchedForPlaceOfDeath', place);
  }

  @action
  @preventFormDefault
  saveDeceased() {
    const deceased = this.model;

    deceased.validate().then(({ validations }) => {
      if (validations.isValid) {
        this.showValidation = false;

        deceased.save().then(() => {
          this.kase.save()
            .then(async () => {
              if (this.saveToPlaces) {
                await this.savePlaceOfDeathToPlaces();
              } else {
                if (this.searchedForPlaceOfDeath && !this.searchedForPlaceOfDeath.placeType) {
                  await this.updatePlaceType();
                }
              }

              this.handleSaveSuccess()
            })
            .catch(() => this.handleSaveError());
        });
      } else {
        this.showValidation = true;
      }
    });
  }

  // This method is duplicated on `controllers/kases/manage/edit/personal`
  @action
  requestCopyAddress(srcLocationType, destLocationType) {
    this.copyAddress(srcLocationType, destLocationType);
  }

  @action
  saveContact(contactType) {
    this.kase
      .get(contactType)
      .then(contact => {
        return new Promise(function (resolve) {
          resolve(contact);
        });
      })
      .then(contact => {
        contact.save().then(() => {
          this.kase.save();
        });
      });
  }

  @action
  removeContact(role, personId) {
    const kasePerson = this.kase.get('kasePeople').find(function (kasePerson) {
      return (
        kasePerson.get('person.id') === personId &&
        kasePerson.role === role.replace(/-/, '_')
      );
    });
    if (kasePerson) {
      kasePerson.destroyRecord();
    }
  }
}
