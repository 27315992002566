import Model, { attr, belongsTo } from '@ember-data/model';
import { and } from '@ember/object/computed';

export default class RelativeModel extends Model {
  @attr('string') relationshipName;

  @belongsTo('person', { async: true }) person;
  @belongsTo('person', { async: true }) decedent;

  @and('person', 'decedent') hasAllRequiredData;
  @and('isDirty', 'hasAllRequiredData', 'isValid') shouldSaveModel;
  @and('isDirty', 'hasAllRequiredData') shouldForceSaveModel;
}
