import SearchRoute from 'crakn/routes/search';

export default class OnlinePlannerUserEditRoute extends SearchRoute {
  modelType = 'v2/OnlinePlannerUser';
  routeRegex = /authenticated.admin.online-planner.edit.users/;

  queryParams = {
    page: {
      refreshModel: true
    },
    query: {
      replace: true,
      refreshModel: true
    }
  }

  model(params) {
    const planner = this.modelFor('authenticated.admin.online-planner.edit');

    return this.store.query(this.modelType, {
      onlinePlannerConfig: planner.get('id'),
      page: params.page || this.defaultPage,
      query: params.query || this.defaultQuery
    });
  }
}
