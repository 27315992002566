import Controller from '@ember/controller';
import { set, action } from '@ember/object';
import { alias } from '@ember/object/computed'
import moment from 'moment';
import config from 'crakn/config/environment';
import { tracked } from '@glimmer/tracking';
import { buildUrl } from 'crakn/utils/url-builder';
import { inject as service } from '@ember/service';

export default class AuthenticatedFinancialDetailController extends Controller {
  @service session
  @tracked ends_at = moment().toISOString();
  @tracked order = null;
  @tracked organization_location_id = null;
  @tracked sort_by = null;
  @tracked starts_at = moment()
    .subtract(30, 'day')
    .toISOString();

  filter_method = 'at-need';
  include = 'kase.deceased,location,purchaser';
  include_totals = true;
  per_page = '20';
  queryParams = [
    'page',
    'starts_at',
    'ends_at',
    'query',
    'sort_by',
    'order',
    'filter_method',
    'per_page',
    'organization_location_id',
    'include',
    'include_totals'
  ];

  @alias('model.meta.totals') totals;

  get excelLink() {
    const params = {
      starts_at: this.starts_at,
      ends_at: this.ends_at,
      filter_method: this.filter_method,
      organization_location_id: this.organization_location_id
    };
    
    return buildUrl(`${config.host}/api/financial_details.xlsx`, params);
  }

  get contractText(){
    return this.session.currentClient.relabel_contract_to_invoice ? 
    `Invoice` : `Contract`;
  }

  @action
  resetPagination() {
    set(this, 'page', 1);
  }

  @action
  setDate(attr, date) {
    set(this, attr, date);

    this.resetPagination();
  }
}
