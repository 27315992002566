import Controller from '@ember/controller'
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { set } from '@ember/object';

export default class AdminLocationsOverviewController extends Controller {
  @controller('authenticated/admin/locations/edit') edit;

  @service v2Staff;
  @service session;

  @tracked managingDirectorId;

  get qbdClassName() {
    if (!this.model.quickbooksConfig.firstObject) {
      return null;
    }

    return this.model.quickbooksConfig.firstObject.data.qbd_class_name;
  }

  set qbdClassName(qbd_class_name) {
    const qbdConfig = this.model.quickbooksConfig.firstObject;
    set(qbdConfig, 'data.qbd_class_name', qbd_class_name);
  }

  @action
  save(event) {
    this.edit.save(event);
    const quickbooksConfig = this.model.quickbooksConfig;
    if (quickbooksConfig) {
      quickbooksConfig.save();
    }
  }

  @action
  setDefaultContractTemplate(templateId) {
    const template = this.store.peekRecord('v2/contractTemplate', templateId);
    this.model.location.set('defaultContractTemplate', template);
  }

  @action
  setAddress(address) {
    this.model.location.address.setProperties(address);
  }

  @action
  setManagingDirector(id) {
    if (id === '') {
      this.model.location.set('managingDirector', null);
    } else {
      this.model.location.set('managingDirector', this.store.peekRecord('v2/user', id));
    }
  }
}
