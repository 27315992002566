import { computed } from '@ember/object';
import SelectionExporter from './selection-exporter';
import { inject as service } from '@ember/service';

export default SelectionExporter.extend({
  store: service(),

  hasKases: computed('chartOfAccountsId', 'kases', function() {
    const chartOfAccountsId = this.chartOfAccountsId;

    return this.kases.then((kases) => {
      if (chartOfAccountsId == null || kases == null || kases.length <= 0) {
        return false;
      }
      return true;
    });
  }),
  kases: computed('chartOfAccountsId', 'page', function() {
    const chartOfAccountsId = this.chartOfAccountsId;
    if (chartOfAccountsId == null) {
      return Promise.resolve([]);
    }

    const store = this.store;
    return store.query('v2/kase', {
      chartOfAccountsId,
      perPage: 5,
      page: this.page,
      include: 'deceased,kase-financial,kase-financial.selections',
      exportable: true
    });
  })
});
