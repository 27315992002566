import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';

export default Route.extend({
  session: service(),

  beforeModel() {
    if (this.session.currentUser.isSuperuser) {
      return true;
    }

    // Unauthorized users are redirected to the dashboad
    this.transitionTo('authenticated.dashboard');
  }
});
