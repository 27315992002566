import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { get } from '@ember/object';
import Base from 'ember-simple-auth/authorizers/base';

export default Base.extend({
  session: service(),

  authorize(sessionData, block) {
    const session = get(this, 'session.session');
    const { token } = sessionData;

    if (session.get('isAuthenticated') && !isEmpty(token)) {
      block('X-CSRF-Token', token);
    }
  }
});
