import { alias, match } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { get, computed } from '@ember/object';
import { routeMatchRegex } from 'crakn/utils/regex-helpers';

export default Controller.extend({
  queryParams: {
    redirectPath: {
      refreshModel: true
    }
  },
  application: controller(),
  currentRoute: alias('application.currentRouteName'),
  defaultAccountingRoute: computed(
    'session.currentClient.can_view_quickbooks',
    function() {
      if (get(this, 'session.currentClient.can_view_quickbooks')) {
        return 'authenticated.admin.manage.accounting.account-basic';
      } else {
        return 'authenticated.admin.manage.accounting.payments';
      }
    }
  ),

  isGPLActive: match('currentRoute', routeMatchRegex('general-price-list')),
  isProductsActive: match(
    'currentRoute',
    routeMatchRegex('general-price-list.products.index')
  ),
  isPackagesActive: match(
    'currentRoute',
    routeMatchRegex('general-price-list.packages.index')
  ),

  isGPLRoutesDisplayed: computed(
    'isGPLActive',
    'isProductsActive',
    'isPackagesActive',
    function() {
      if (
        this.isGPLActive === true ||
        this.isProductsActive === true ||
        this.isPackagesActive === true
      ) {
        return true;
      }
    }
  ),

  isAccountRouteActive: computed(
    'isAccountBasicRouteActive',
    'isChartRouteActive',
    'isPaymentRouteActive',
    'isAdjustmentsRouteActive',
    {
      get() {
        if (
          this.isAccountBasicRouteActive === true ||
          this.isChartRouteActive === true ||
          this.isPaymentRouteActive === true ||
          this.isAdjustmentsRouteActive === true
        ) {
          return true;
        }
      }
    }
  ),

  isAccountBasicRouteActive: match(
    'currentRoute',
    routeMatchRegex('account-basic')
  ),
  isChartRouteActive: match(
    'currentRoute',
    routeMatchRegex('financial-configurations')
  ),
  isPaymentRouteActive: match('currentRoute', routeMatchRegex('payments')),
  isAdjustmentsRouteActive: match(
    'currentRoute',
    routeMatchRegex('adjustments')
  ),

  canViewChartOfAccounts: computed('session.currentClient', function() {
    const canViewQB = get(this, 'session.currentClient.can_view_quickbooks');
    const canViewQBO = get(
      this,
      'session.currentClient.can_view_quickbooks_online'
    );
    const canViewSage50 = get(this, 'session.currentClient.can_view_sage50');

    return canViewQB || canViewQBO || canViewSage50;
  })
});
