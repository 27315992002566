import Controller from '@ember/controller';
import { action } from '@ember/object';
export default class ForethoughtSetupController extends Controller {
  showFirmIdField = true;

  @action
  refreshRoute() {
    this.send('refreshModel');
  }
}
