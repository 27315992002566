import { reads, not } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';

export default buildValidations({
  payerId: validator('presence', {
    presence: true,
    disabled: reads('model.altPayer')
  }),
  'newPayment.collectedAt': validator('presence', {
    presence: true,
    message:'Date can\'t be empty or invalid'
  }),
  'newPayment.payer': validator('presence', {
    presence: true,
    disabled: not('model.altPayer')
  }),
  'newPayment.amount': [
    validator('presence', {
      presence: true
    }),
    validator('number', {
      allowString: true,
      gt: 0,
      message: 'Must be greater than zero',
      positive: true,
      disabled: reads('model.newPayment.paymentType.isTributePay'),
    }),
    validator('number', {
      allowString: true,
      lte: reads('model.newPayment.insurancePolicy.currentFaceValue'),
      message: 'Cannot be greater than Insurance Policy\'s Value',
      disabled: not('model.newPayment.paymentType.associateInsurancePolicy')
    }),
    validator('number', {
      allowString: true,
      gte: 0.50,
      message: 'A payment cannot be less than $0.50.',
      positive: true,
      disabled: not('model.newPayment.paymentType.isTributePay'),
    })
  ],
  'newPayment.insurancePolicy': validator('presence', {
    presence: true,
    message: 'Must select an insurance policy',
    disabled: not('model.newPayment.paymentType.associateInsurancePolicy')
  }),
  paymentType: validator('presence', true),
  'newPayment.createdAt': validator('presence', true),
  'card.cc_exp': validator('presence', {
    presence: true,
    disabled: not('model.card.isProcessingCreditCard')
  }),
  'card.cvv': validator('presence', {
    presence: true,
    disabled: not('model.card.isProcessingCreditCard')
  }),
  'card.cc_name': validator('presence', {
    presence: true,
    disabled: not('model.card.isProcessingCreditCard')
  }),
  'newInterest.amount': validator('number', {
    presence: true,
    allowString: true,
    gt: 0,
    disabled: not('model.toggleCreateInterest')
  }),
  'newInterest.interestType': validator('presence', {
    presence: true,
    disabled: not('model.toggleCreateInterest')
  })
});
