import Service, { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { get } from '@ember/object';

export default class StaffService extends Service {
  @service store;

  allStaff = null;
  activeStaff = null;
  funeralDirectorsStaff = null;
  notifiableStaff = null;

  get all() {
    if (this.allStaff === null) {
      this.allStaff = this.store.query('user', { per_page: 1000 });
    }

    return this.allStaff;
  }

  get active() {
    if (this.activeStaff === null) {
      this.activeStaff = this.store.query('user', { per_page: 1000, archived: false });
    }

    return this.activeStaff;
  }

  get funeralDirectors() {
    if (this.funeralDirectorsStaff === null) {
      this.funeralDirectorsStaff = this.store.query('user', {
        per_page: 1000,
        archived: false,
        is_funeral_director: true
      });
    }

    return this.funeralDirectorsStaff;
  }

  get notifiable() {
    if (this.notifiableStaff == null) {
      const notifArray = A();
      this.active.then(all => {
        all.forEach(item => {
          if (item.notifyEnabled) {
            notifArray.pushObject(item);
          }
        });
      });

      this.notifiableStaff = notifArray;
    }

    return this.notifiableStaff;
  }

  activeFilteredByAttributePresence(attr) {
    return this.active.filter((user => get(user, attr)));
  }

  unloadAll() {
    this.productCategories = null;
  }
}
