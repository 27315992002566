import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AccountingExportNewController extends Controller {
  @service flashes;
  queryParams = ['page', 'chartOfAccountsId', 'selectionType'];
  @tracked page = 1;

  @tracked selectionType = 'kaseSelection';
  @computed('selectionType')
  get selectionComponent() {
    const selectionType = this.selectionType;
    if (selectionType == 'kaseSelection') {
      return 'accounting-exports/kase-selection-exporter';
    } else {
      return 'accounting-exports/misc-selection-exporter';
    }
  }

  chartsOfAccounts = null;
  @computed('chartsOfAccounts')
  get chartOfAccountsAvailable() {
    const chartsOfAccounts = this.chartsOfAccounts;
    if (chartsOfAccounts == null || chartsOfAccounts == undefined) {
      return false;
    }
    return true;
  }

  @tracked exporting = false;
  @tracked selectionsCount = 0;

  @tracked showComponent = false;
  
  @action
  setChartOfAccounts(coaId) {
    this.page = 1;
    this.model.selections = [];
    this.selectionsCount = 0;
    if (coaId == null || coaId == undefined || coaId == '') {
      this.chartOfAccountsId = undefined;
      this.model.chartOfAccounts = null;
      return;
    } else if (isNaN(coaId)) {
      return;
    }

    const store = this.store;
    store.findRecord('v2/chart-of-accounts', coaId).then(record => {
      set(this, 'chartOfAccountsId', coaId);
      this.model.chartOfAccounts = record;
      this.showComponent = true;
    });
  }

  @action
  setType(type) {
    this.page = 1;
    this.model.selections = [];
    this.selectionsCount = 0;
    this.selectionType = type;
  }

  @action
  addSelection(selection) {
    const selections = this.model.selections;
    selections.pushObject(selection);
    this.selectionsCount = selections.content.length;
  }

  @action
  removeSelection(selection) {
    const selections = this.model.selections;
    selections.removeObject(selection);
    this.selectionsCount = selections.content.length;
  }

  @action
  export() {
    const warningMessage =
      this.selectionsCount == 0
        ? 'Are you sure you want to export all selections? This cannot be undone.'
        : 'Are you sure you want to export these selections? This cannot be undone.';
    if (confirm(warningMessage)) {
      this.exporting = true;
      const selectionType = this.selectionType;
      this.model
        .save({
          adapterOptions: {
            selectionType
          }
        })
        .then(() => {
          const store = this.store;
          this.model = store.createRecord('v2/accounting-export');
          this.transitionToRoute('authenticated.accounting.exports');
          this.exporting = false;
          this.showComponent = false;
        })
        .catch(ret => {
          ret.errors.forEach(error => {
            if (error.code == 428) {
              const redirectUrl = error['meta']['authorization-link'];
              window.location.replace(redirectUrl);
            } else {
              this.exporting = false;
              this.showComponent = false;
              this.send(
                'flashError',
                'Something went wrong with your export!'
              );
            }
          });
        });
    }
  }
}
