import Transform from '@ember-data/serializer/transform';
import EmberObject from '@ember/object';

// This is used for read-only objects like:
//
// avatar: {
//   small_url: "image.png"
//   medium_url: "image.png"
//   large_url: "image.png"
// }

export default Transform.extend({
  deserialize(serialized) {
    const camelized = {};

    if (!serialized) {
      return;
    }

    Object.keys(serialized).forEach(
      key => (camelized[key.camelize()] = serialized[key])
    );
    return EmberObject.create(camelized);
  },

  serialize(_deserialized) {
    return null;
  }
});
