import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AdminCemsitesIndexController extends Controller {
  @service api;
  @service flashes;
  
  @tracked showDialog = false;
  @tracked deleteFocusedPlaceIntegrationService;

  placeIntegrationServices = null;

  @action
  archiveCemsiteIntegrationServicePlace(placeIntegrationService) {
    placeIntegrationService.destroyRecord().then(() => {
      this.flashes.addSuccess('Successfully unlinked!');
      this.showDialog = false;
    })
  }

  @action
  deletePlaceIntegrationService(placeIntegrationService) {
    this.deleteFocusedPlaceIntegrationService = placeIntegrationService;
  }

  @action
  cancelDelete() {
    this.deleteFocusedPlaceIntegrationService = null;
  }

  @action
  confirmDelete() {
    this.deleteFocusedPlaceIntegrationService
        .destroyRecord()
        .then(() => {
          this.flashes.addSuccess('Successfully unlinked!');
        })
  }

  @action
  requestNew() {
    this.transitionToRoute('authenticated.admin.manage.cemsites.new');
  }
} 