import SelectBox from './select-box';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default class CustomProductCategorySelector extends SelectBox {
  layoutName = 'components/form/select-box';
  productCategoryNameSort = ['name:asc'];
  generalPriceListId = null;

  @service productCategories;
  @sort('categories', 'productCategoryNameSort') options;

  @computed('generalPriceListId')
  get categories() {
    return this.productCategories.getProductCategories(this.generalPriceListId);
  }
}
