import Controller from '@ember/controller';
import { set } from '@ember/object';
import Search from 'crakn/mixins/search';
import moment from 'moment';

export default Controller.extend(Search, {
  archived: false,
  deceased: false,

  queryParams: [
    'page',
    'starts_at',
    'ends_at',
    'status',
    'organization_location_id',
    'purchaser_id',
    'type'
  ],

  starts_at: moment().subtract(30, 'day').toISOString(),
  ends_at: moment().toISOString(),
  organization_location_id: null,
  status: 'all',
  purchaser_id: null,
  type: 'MiscSelection',

  actions: {
    setDate(attr, value) {
      set(this, attr, value);

      this.send('resetPagination');
    },
    
    typeaheadSearch(value) {
      return this.store
                  .query('person', {
                    query: value,
                    deceased: this.deceased,
                    archived: this.archived
                  });
    },

    selectPurchaser(purchaser) {
      if (purchaser === undefined) {
        return;
      }

      set(this, 'purchaser', purchaser);
      set(this, 'purchaser_id', purchaser ? purchaser.get('id') : '');
    },

    resetPagination() {
      set(this, 'page', 1);
    }
  }
});
