import Model, { belongsTo, attr } from '@ember-data/model';

export default class TaxItemModel extends Model {
  @attr('string') name;
  @attr('string') description;
  @attr('moment-datetime', { defaultValue: null }) archivedAt;

  @belongsTo('client') client;
  @belongsTo('chartOfAccounts', { inverse: 'taxItems' }) chartOfAccounts;
}
