import { bind } from '@ember/runloop';
import { equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { set, computed } from '@ember/object';

export default Component.extend({
  staff: service(),
  flashes: service(),
  store: service(),
  model: null,
  viewMode: null,
  staffMemberId: '',
  isNotifyDisabled: equal('staffMemberId', ''),
  isShowingNotifications: false,

  relatedNotifications: computed(
    'isShowingNotifications',
    'model.{constructor.modelName,id}',
    function() {
      if (!this.isShowingNotifications) {
        return false;
      }

      const idProperty = `${this.model.constructor.modelName}_id`;
      const query = {};

      query[idProperty] = this.model.id;

      return this.store.query('notification', query);
    }
  ),

  handleSaveSuccess() {
    this.flashes.addSuccess('Notification was sent!');
    set(this, 'staffMemberId', '');
    this.notifyPropertyChange('isShowingNotifications');
  },

  handleSaveError() {
    this.flashes.addError('Notification was not sent.');
  },

  processNotification(subject) {
    const userId = this.staffMemberId;
    subject
      .notify(userId)
      .then(bind(this, 'handleSaveSuccess'), bind(this, 'handleSaveError'));
  },

  actions: {
    notify() {
      const subject = this.model;
      if (subject.get('hasDirtyAttributes')) {
        subject
          .save()
          .then(
            bind(this, 'processNotification'),
            bind(this, 'handleSaveError')
          );
      } else {
        this.processNotification(subject);
      }
    },

    toggleNotifiedStaffMembers() {
      this.toggleProperty('isShowingNotifications');
    }
  }
});
