import Route from '@ember/routing/route';

export default Route.extend({
  model() {
    return this.modelFor('authenticated.kases.manage.chain-of-custody');
  },

  setupController(controller) {
    this._super(...arguments);
    const kase = this.modelFor('authenticated.kases.manage.chain-of-custody');
    const newCustodyAudit = this.store.createRecord('v2/custody-audit', {
      kase
    });
    controller.set('newCustodyAudit', newCustodyAudit);
  },

  actions: {
    willTransition(transition) {
      if (
        this.controller.get('isEditing') &&
        !confirm('You have unsaved changes. Are you sure?')
      ) {
        transition.abort();
      } else {
        this.currentModel.rollbackAttributes();
        return true;
      }
    }
  }
});
