import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class IntegrationsOverviewRoute extends Route {
  @service store;

  model() {
    return this.store
      .query('integrationService', {
        archived: false,
        not_category: 'accounting',
        per_page: 1000,
      })
      .then(res => res.toArray());
  }
}
