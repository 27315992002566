import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes class=\"pagination d--xs--flex d--xs--c\">\n  {{#if (gt @page 1)}}\n    <LinkTo\n        @query={{hash page=(dec @page)}}\n        class=\"btn btn--info pagination__link\">\n      <MdIcon @icon=\"chevron_left\" />\n      <span class=\"pagination__direction\"> Previous</span>\n    </LinkTo>\n  {{/if}}\n\n  {{#if @pages}}\n    <div class=\"pagination__current-page\">\n      Page <span>{{@page}}</span> of <span>{{@pages}}</span>\n    </div>\n  {{/if}}\n\n  {{#if (lt @page @pages)}}\n    <LinkTo\n        @query={{hash page=(inc @page)}}\n        class=\"btn btn--info pagination__link\">\n      <span class=\"pagination__direction\">Next</span>\n      <MdIcon @icon=\"chevron_right\" />\n    </LinkTo>\n  {{/if}}\n</div>\n", {"contents":"<div ...attributes class=\"pagination d--xs--flex d--xs--c\">\n  {{#if (gt @page 1)}}\n    <LinkTo\n        @query={{hash page=(dec @page)}}\n        class=\"btn btn--info pagination__link\">\n      <MdIcon @icon=\"chevron_left\" />\n      <span class=\"pagination__direction\"> Previous</span>\n    </LinkTo>\n  {{/if}}\n\n  {{#if @pages}}\n    <div class=\"pagination__current-page\">\n      Page <span>{{@page}}</span> of <span>{{@pages}}</span>\n    </div>\n  {{/if}}\n\n  {{#if (lt @page @pages)}}\n    <LinkTo\n        @query={{hash page=(inc @page)}}\n        class=\"btn btn--info pagination__link\">\n      <span class=\"pagination__direction\">Next</span>\n      <MdIcon @icon=\"chevron_right\" />\n    </LinkTo>\n  {{/if}}\n</div>\n","moduleName":"crakn/components/g/crakn/pagination.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/pagination.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class GCraknPaginationComponent extends Component {
  @tracked page = 1;

  constructor(owner, args) {
    super(owner, args);
    this.page = args.page;
  }

  get nextPage() {
    return this.page + 1;
  }

  get previousPage() {
    return this.page - 1;
  }

  get hasPrevious() {
    return this.page > 1;
  }

  get hasNext() {
    return this.page < this.args.pages;
  }
}
