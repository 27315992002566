import Controller from '@ember/controller';
import { computed, action } from '@ember/object';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class KasesIntegrationsBatesvilleController extends Controller {
  @service api;
  @service flashes;
  @tracked events = null;

  @computed('model.batesvilleExport.updatedAt')
  get formattedBatesvilleExportedAt() {
    return this.model.batesvilleExport.updatedAt.format('LLL');
  }

  @computed('model.kase.deceased.profileDeathDate')
  get invalidDeathDate() {
    return this.model.kase.deceased.get('profileDeathDate') > moment();
  }

  @action
  batesville(e) {
    e.target.disabled = true; // avoid double-click issues
    const kase_id = this.model.kase.id;

    this.api.json
      .put('batesville/kases', { body: { id: kase_id } })
      .then((response) => {
        if (response.ok) {
          this.store.pushPayload('kase', response.parsedJson);
          this.flashes.addSuccess('Case successfully exported!');
          this.send('refreshModel');
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.flashes.addError('Oops, something went wrong! Please try again.');
          e.target.disabled = false; // avoid double-click issues
        }
      });
  }

  @action
  removeFromBatesville(e) {
    e.target.disabled = true; // avoid double-click issues
    const kase_id = this.model.kase.id;

    this.api.json
      .delete(`batesville/kases/${kase_id}`)
      .then(response => {
        if (response.ok) {
          this.store.pushPayload('kase', response.parsedJson);
          this.flashes.addSuccess('Case successfully removed!');
          this.events = null;
          this.send('refreshModel');
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.flashes.addError('Oops, something went wrong! Please try again.');
          e.target.disabled = false; // avoid double-click issues
        }
      })
  }

  @action
  avatar(e) {
    e.target.disabled = true; // avoid double-click issues
    const kase_id = this.model.kase.id;

    this.api.json
      .put('batesville/avatar', { body: { id: kase_id } })
      .then((response) => {
        if (response.ok) {
          this.store.pushPayload('kase.avatar', response.parsedJson);
          this.flashes.addSuccess('Case image successfully exported!');
          this.send('refreshModel');
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false; // avoid double-click issues
        }
      });
  }
}
