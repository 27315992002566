import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment';

export default class AdminFinancialConfigurationsEditDiscountsController extends Controller {
  @service flashes;
  @service intl;

  @tracked openDiscountTypes = [];
  @tracked showValidation = false;

  accounts = null;
  chartOfAccounts = null;

  @action
  addDiscountType() {
    this.showValidation = false;
    const discountTypes = this.store.createRecord('discountType', {
      chartOfAccounts: this.chartOfAccounts
    });
    const discount = this.model.pushObject(discountTypes);
    this.setOpenDiscountType(discount);
  }

  @action
  rollbackDiscountType(discountType) {
    if (discountType.isNew) {
      this.removeOpenDiscountType(discountType);
      discountType.destroyRecord();
    } else {
      this.removeOpenDiscountType(discountType);
      discountType.rollbackAttributes();
    }
    this.send('refreshModel');
  }

  @action
  saveDiscountType(discountType) {
    discountType.validate().then(({ validations }) => {
      if (validations.isValid) {
        this.showValidation = false;
        discountType
          .save()
          .then(() => {
            this.removeOpenDiscountType(discountType);
            this.flashes.addSuccess(
              this.intl.t(
                'authenticated.admin.financial-configurations.edit.discounts.messages.save.success'
              )
            );
          })
          .catch(() => {
            this.flashes.addError(
              this.intl.t(
                'authenticated.admin.financial-configurations.edit.discounts.messages.save.error'
              )
            );
          });
      } else {
        this.showValidation = true;
      }
    });
  }

  @action
  setAccount(type, accountId) {
    type.account = this.store.peekRecord('account', accountId);
  }

  @action
  deleteDiscountType(discountType) {
    if (discountType.isNew) {
      this.removeOpenDiscountType(discountType);
      discountType.rollbackAttributes();
      this.send('refreshModel');
      this.flashes.addSuccess(
        this.intl.t(
          'authenticated.admin.financial-configurations.edit.discounts.messages.delete.success'
        )
      );
    } else {
      discountType.rollbackAttributes();
      discountType.set('archivedAt', moment());
      discountType
        .save()
        .then(() => {
          this.removeOpenDiscountType(discountType);
          this.send('refreshModel');
          this.flashes.addSuccess(
            this.intl.t(
              'authenticated.admin.financial-configurations.edit.discounts.messages.delete.success'
            )
          );
        })
        .catch(() => {
          this.flashes.addError(
            this.intl.t(
              'authenticated.admin.financial-configurations.edit.discounts.messages.delete.error'
            )
          );
        });
    }
  }

  @action
  setOpenDiscountType(discountType) {
    const newOpenItems = this.openDiscountTypes.concat(discountType);
    this.openDiscountTypes = newOpenItems;
  }

  @action
  removeOpenDiscountType(discountType) {
    this.openDiscountTypes = this.openDiscountTypes.filter(
      (openDiscountTypes) => {
        return openDiscountTypes != discountType;
      }
    );
  }
}
