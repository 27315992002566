import { or, not } from '@ember/object/computed';
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  classNameBidings: ['isShowingContent::overflow-fix'],
  isComplete: false,
  headingLabel: '',
  isShowStateActive: false,

  isShowingContent: or('isNotComplete', 'isShowStateActive'),
  isNotComplete: not('isComplete'),

  showHideLabel: computed('isShowStateActive', {
    get() {
      return this.isShowStateActive ? 'Hide' : 'Show';
    }
  }),

  showHideIcon: computed('isShowStateActive', {
    get() {
      return this.isShowStateActive ? 'visibility_off' : 'visibility';
    }
  }),

  actions: {
    toggleShow() {
      this.toggleProperty('isShowStateActive');
    },
    sendComplete() {
      const list = this.headingLabel;
      this.sendAction('completeStatus', list);
    }
  }
});
